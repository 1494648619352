// Packages Import
import { memo } from "react"
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { cx } from "@emotion/css";
import { useDispatch, useSelector } from "react-redux";

// Project Components
import InfoTable from "../../UI/Table"
import {formatDate} from "../../Util/Util"
import { mtlEngActions} from "../../../store/MtlEng/MtlEng-slice";


const columnsSettings = [
	{ field: "iCat", headerName: "Commodity Code", type: "text", minWidth: 90, flex: 1.2 },
	{ field: "lCat", headerName: "Legacy", type: "text", minWidth: 60, flex: 0.4 },
	{ field: "iCat_id", headerName: "Oracle ID", type: "text", minWidth: 60, flex: 0.4 },
    { field: "unspsc", headerName: "UNSPSC", type: "text", minWidth: 60, flex: 0.4 },
]



const CommoditiesTable = (props) => {
	const dispatch = useDispatch();
	const row = useSelector((state) => state.mtlEngState.COMMODITIES_TABLE)
	const columnVisibility = useSelector((state) => state.mtlEngState.tableColumnVisibilityCommodities)

	const handleOnRowClick = (params) => {
		dispatch(mtlEngActions.filterTableWhenClicked({table : "CommoditiesTable", icat_id : params.row.iCat_id,}))
	}
	const customCellClass = (params) => {
		 return cx("super-app", { pointerCursor: true })	
	}
	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport 
				printOptions={{
					disableToolbarButton: true,
				}} 
				csvOptions={{
					fileName: `Commodities_${props.org}_${formatDate(new Date(), "")}`
				}}
			/>
		</>
	)
	return <InfoTable 
        columnsSettings={columnsSettings} 
        rows={row}
		maxtableHeight={props.maxtableHeight || 1}
		height={props.height || 319}
		autoTableHeight={typeof props.autoTableHeight === "boolean" ? props.autoTableHeight : true }
		handleOnRowClick = {handleOnRowClick}
		checkboxSelection = {false}
		customCellClass={customCellClass}
		customToolBar={customToolBar}
		ExtraProps = {{
			columnVisibilityModel:columnVisibility,
			onColumnVisibilityModelChange:(newModel) =>{
				dispatch(mtlEngActions.setColumnVisibility({columnVisibility:newModel , table:"Commodities"}));
			}
		}}
		/>
}

export default memo(CommoditiesTable)
