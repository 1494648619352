import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
	val: window.sessionStorage.getItem("daysBack") ?? 28 ,
	popUpState: false
};
const daysBackSlice = createSlice({
	name: "daysBack",
	initialState: InitialState,
	reducers: {
		setDaysBack(state, action) {
			state.val = action.payload;
			window.sessionStorage.setItem("daysBack" , action.payload)
		},
		setPopUpState(state,action){
			state.popUpState = action.payload
		}
	},
});

export const daysBackAction = daysBackSlice.actions;

export default daysBackSlice;
