// React
import { useRef, useMemo, useState, useEffect, useCallback } from "react";
import { findPartAction } from "../../store/common/findPart-slice";
import { SubsetAction } from "../../store/Subset/Subset-slice";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// UI
import {
	Box,
	Button,
	Modal,
	Stack,
	Autocomplete,
	Checkbox,
	FormControlLabel,
	FormControl,
	Typography,
	AppBar,
	Toolbar,
	Tooltip,
} from "@mui/material";
import ColoredTextField from "../UI/ColoredTextField";
import CircularProgress from "@mui/material/CircularProgress";
import FullScreenLoadingIndicator from "../UI/FullScreenLoadingIndicator";
import ErrorBox from "./ErrorBox";
// Packages Import
import { OSAltKey, APIURL, isNumber, dateDiff } from "./Util";
import useHttp from "../../Hooks/use-http";
import { subsetReloadPage } from "../Menu/Subset/CreateSubset";
import { settings } from "../Layout/NavBar";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 550,
	bgcolor: "background.paper",
	boxShadow: 24,
	maxWidth: "90%",
	p: 4,
	pl: 2,
	pb: 2,
};

const FindPart = (props) => {
	const dispatch = useDispatch();
	const partNumberInputRef = useRef();
	const { pathname: urlPathName } = useLocation();

	const [checkboxChoice, setCheckboxChoice] = useState(1);
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const partFound = useSelector((state) => state.findPartState.partFound);
	const showFindPart = useSelector((state) => state.findPartState.showFindPart);

	// BOM
	const daysBack = useSelector((state) => state.daysBackState.val);
	const [days, setDays] = useState(28);
	const maxDay = dateDiff(new Date(new Date().setMonth(new Date().getMonth() - 6)));
	const bomList = useSelector((state) => state.findPartState.bomList);
	const selectedSubsetOrg = useSelector((state) => state.findPartState.selectedSubsetOrg);
	const selectedBom = useSelector((state) => state.findPartState.selectedBom);
	const MTLEngOrgList = useSelector((state) => state.findPartState.MTLEngOrgList);
	const MTLEngList = useSelector((state) => state.findPartState.MTLEngList);
	const selectedMTLEngOrg = useSelector((state) => state.findPartState.selectedMTLEngOrg);
	const selectedMTLEng = useSelector((state) => state.findPartState.selectedMTLEng);
	const toBeDetermined = useSelector((state) => state.findPartState.toBeDetermined);
	const { primaryOrg, secondaryOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);

	const { sendRequest: fetchDataDropDown, isLoading: isLoadingDropDown } = useHttp();
	const { sendRequest: fetchDataFull, isLoading: isLoadingFull } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	// const headers = useMemo(() => ({ "Content-Type": "application/json" }), []);
	const headersToken = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	useEffect(() => {
		setDays(daysBack);
	}, [daysBack]);

	const fetchMLARBOM = () => {
		if (showFindPart) {
			dispatch(findPartAction.resetBomList());
			const body = {
				numberOfBackDays: days,
			};
			fetchDataDropDown(
				{ url: `${APIURL}bom_upload/mlarBomList`, headers: headersToken, method: "POST", body },
				(data) => {
					console.log(data);
					dispatch(findPartAction.setBomList(data));
				},
				(error) => {
					console.log(error);
					setErrorMsg(`ERROR: Unable to Retrieve MLAR BOM, please try again. ${error}`);
					setErrorState(true);
				}
			);
		}
	};

	const findPartHandler = (e) => {
		e.preventDefault();
		if (checkboxChoice === 1) {
			const partNumber = partNumberInputRef.current.value?.toUpperCase().trim();
			if (partNumber) {
				props.changePart(partNumber);

				window.sessionStorage.setItem("subsetLoaded", false);
				const findPartHistories = window.localStorage.getItem("FindPartHistories");
				if (findPartHistories) {
					if (!JSON.parse(findPartHistories).includes(partNumber)) {
						const historiesArray = JSON.parse(findPartHistories);
						historiesArray.push(partNumber);
						window.localStorage.setItem("FindPartHistories", JSON.stringify(historiesArray));
					}
				} else {
					window.localStorage.setItem("FindPartHistories", JSON.stringify([partNumber]));
				}
			}
		} else if (checkboxChoice === 3) {
			if (selectedBom) {
				handleMLARBOMChanged(selectedBom);
			}
		} else if (checkboxChoice === 4) {
			fetchMtlEngRecords();
		}
	};

	const closeFindPartHandler = () => {
		dispatch(findPartAction.setShowFindPart(false));
		dispatch(findPartAction.setPartFound(true));
	};

	const defaultProps = {
		options: JSON.parse(window.localStorage.getItem("FindPartHistories"))?.reverse() || [""],
	};

	let error = !partFound;

	const resetError = () => {
		dispatch(findPartAction.setPartFound(true));
	};

	const handleMLARBOMChanged = (newValue) => {
		if (newValue) {
			const body = { userid: userNTID, bom_ppn: newValue.ppn, bom_prev: newValue.prev, userorg: newValue.org };
			fetchDataFull(
				{ url: `${APIURL}admin/bomFilterRecords`, headers: headersToken, method: "POST", body },
				(data) => {
					dispatch(findPartAction.setSelectedBom(newValue));
					console.log(data);
					dispatch(findPartAction.setFilterRecords({ data, ppn: newValue.ppn }));
					window.sessionStorage.setItem("subsetLoaded", JSON.stringify("MLAR"));
					window.sessionStorage.setItem("filterInfo", JSON.stringify({ bom_prev: newValue.prev, userorg: newValue.org }));
					dispatch(findPartAction.resetFilter());
					subsetReloadPage(urlPathName, dispatch);
				},
				(error) => {
					console.log(error);
					setErrorMsg(`ERROR: Unable to Retrieve MLAR ${newValue.ppn}, please try again. ${error}`);
					setErrorState(true);
				}
			);
		} else {
			// User clicking clear (X) button
			dispatch(findPartAction.setSelectedBom(newValue));
		}
	};

	const handleDaysChanged = (event) => {
		if (isNumber(event.target.value) || event.target.value === "") {
			if (event.target.value > maxDay) {
				setDays(maxDay);
			} else {
				setDays(event.target.value);
			}
		}
	};

	const handleChooseSubset = () => {
		dispatch(SubsetAction.setCurrentTab(settings.SubsetLoadAndExport));
		dispatch(SubsetAction.setIsFilter(true));
		closeFindPartHandler();
	};

	const fetchMtlEngList = useCallback(() => {
		dispatch(findPartAction.resetMTLEngList());
		fetchDataDropDown(
			{
				url: `${APIURL}bom_upload/usersList`,
				headers: headersToken,
				method: "POST",
				body: {
					userOrg: "",
				},
			},
			(data) => {
				console.log(data);
				dispatch(findPartAction.setMtlEngList(data));
			},
			(error) => {
				console.log(error);
			}
		);
	}, [dispatch, fetchDataDropDown, headersToken]);

	useEffect(() => {
		if (showFindPart) {
			fetchMtlEngList();
			const selectedSubsetOrg = JSON.parse(window.sessionStorage.getItem("selectedSubsetOrg"));
			if (selectedSubsetOrg) {
				dispatch(findPartAction.setSelectedSubsetOrg(selectedSubsetOrg));
			} else {
				dispatch(findPartAction.setSelectedSubsetOrg(primaryOrg));
			}
		}
	}, [dispatch, fetchMtlEngList, primaryOrg, showFindPart]);

	const handleMtlEngOrgChanged = (event) => {
		dispatch(findPartAction.setSelectedMTLEngOrg(event.target.textContent));
		dispatch(findPartAction.setSelectedMTLEng(null));
	};

	const handleMtlEngChanged = (newValue) => {
		window.sessionStorage.setItem("filterInfo", JSON.stringify(newValue));
		dispatch(findPartAction.setSelectedMTLEng(newValue));
	};

	const fetchMtlEngRecords = () => {
		const body = { userid: userNTID, mtlengid: selectedMTLEng.userid, mtlenguserorg: selectedMTLEngOrg, tobedet: toBeDetermined ? "1" : "0" };
		fetchDataFull(
			{ url: `${APIURL}admin/mtlengFilterRecords`, headers: headersToken, method: "POST", body },
			(data) => {
				console.log(data);
				if (data.FILTER_RECORDS && data.FILTER_RECORDS.length > 0) {
					dispatch(findPartAction.setFilterRecords({ data, ppn: selectedMTLEng.username }));
					window.sessionStorage.setItem("subsetLoaded", JSON.stringify("MTL_ENG"));
					dispatch(findPartAction.resetFilter());
					subsetReloadPage(urlPathName, dispatch);
				} else {
					setErrorMsg(`No records found for material engineer "${selectedMTLEng.username}"`);
					setErrorState(true);
				}
			},
			(error) => {
				console.log(error);
				setErrorMsg(`ERROR: Unable to retrieve material engineer records, please try again. ${error}`);
				setErrorState(true);
			}
		);
	};

	const LoadingAnimation = () => (
		<Box display={"flex"} sx={{ mx: "auto" }}>
			<CircularProgress color="inherit" size={80} />
		</Box>
	);

	return (
		<Box>
			<FullScreenLoadingIndicator loading={isLoadingFull} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />

			<Modal open={showFindPart} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyO" && e.altKey === true) {
							findPartHandler(e);
						} else if (e.code === "KeyC" && e.altKey === true) {
							e.preventDefault();
							closeFindPartHandler();
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Item Search form"}
							</Typography>
						</Toolbar>
					</AppBar>
					<form style={{ overflowY: "auto" }}>
						<Stack spacing={2} mb={2} mt={7} sx={{ width: { xs: 400, sm: "100%" } }}>
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<Checkbox name="partNumber" checked={checkboxChoice === 1} onChange={() => setCheckboxChoice(1)} />
								<Autocomplete
									disabled={checkboxChoice !== 1}
									{...defaultProps}
									freeSolo
									fullWidth
									onInputChange={resetError}
									renderInput={(params) => (
										<ColoredTextField
											{...params}
											error={error}
											helperText={!partFound && "Part Number not found"}
											inputProps={{ sx: { textTransform: "uppercase" }, ...params.inputProps }}
											inputRef={partNumberInputRef}
											label="Keysight Part Number"
											focused
											autoFocus
										/>
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<Checkbox
									name="subset"
									checked={checkboxChoice === 2}
									onChange={() => {
										setCheckboxChoice(2);
									}}
								/>
								<FormControl focused sx={{ width: 75, mr: 0.5 }}>
									<Autocomplete
										forcePopupIcon={checkboxChoice !== 2 ? false : true}
										disabled={checkboxChoice !== 2}
										options={[primaryOrg, ...secondaryOrg]}
										renderOption={(props, option) => {
											return (
												<li {...props} key={option} style={{ color: primaryOrg && primaryOrg === option && "green" }}>
													{option}
												</li>
											);
										}}
										disableClearable
										onChange={(event) => dispatch(findPartAction.setSelectedSubsetOrg(event.target.textContent))}
										value={selectedSubsetOrg}
										renderInput={(params) => <ColoredTextField label={"Org"} focused {...params} />}
										isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
									/>
								</FormControl>
								<Button disabled={checkboxChoice !== 2} onClick={handleChooseSubset}>
									Choose Subset
								</Button>
							</Box>
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<Checkbox name="BOM" checked={checkboxChoice === 3} onChange={() => setCheckboxChoice(3)} />
								<ColoredTextField
									disabled={checkboxChoice !== 3}
									focused
									sx={{ width: 100, mr: 0.5 }}
									autoComplete="off"
									label={"Days"}
									value={days}
									onChange={handleDaysChanged}
								/>
								<FormControl focused fullWidth>
									<Autocomplete
										disabled={checkboxChoice !== 3}
										loading={isLoadingDropDown}
										loadingText={<LoadingAnimation />}
										onOpen={() => {
											fetchMLARBOM();
										}}
										options={bomList}
										value={selectedBom}
										getOptionLabel={(option) => `${option.ppn}`}
										renderOption={(props, option) => {
											return (
												<li {...props}>
													<Box>
														<Typography align="center" width={{ xs: "50px", sm: "130px" }} variant="body2">{`${option.ppn}`}</Typography>
													</Box>
													<Box borderLeft={"1px Solid"} ml={2}>
														<Typography pl={1.5} minWidth={"35px"} variant="body2">{`${option.prev}`}</Typography>
													</Box>
													<Box borderLeft={"1px Solid"} ml={2}>
														<Typography pl={1.5} variant="body2">{`${option.date}`}</Typography>
													</Box>
													<Box borderLeft={"1px Solid"} ml={2}>
														<Typography pl={1.5} variant="body2">{`${option.org}`}</Typography>
													</Box>
												</li>
											);
										}}
										renderInput={(params) => <ColoredTextField label={"choose MLAR/BOM #"} focused {...params} />}
										isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
										onChange={(event, newValue) => handleMLARBOMChanged(newValue)}
									/>
								</FormControl>
							</Box>
							<Box>
								<Box sx={{ display: "flex", flexDirection: "row" }}>
									<Checkbox name="MTLE" checked={checkboxChoice === 4} onChange={() => setCheckboxChoice(4)} />
									<FormControl focused sx={{ width: 100, mr: 0.5 }}>
										<Autocomplete
											forcePopupIcon={checkboxChoice !== 4 ? false : true}
											disabled={checkboxChoice !== 4}
											options={MTLEngOrgList}
											value={selectedMTLEngOrg}
											onChange={handleMtlEngOrgChanged}
											disableClearable
											renderInput={(params) => <ColoredTextField label={"Org"} focused {...params} />}
											isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
										/>
									</FormControl>
									<FormControl focused fullWidth>
										<Autocomplete
											disabled={checkboxChoice !== 4}
											loading={isLoadingDropDown}
											loadingText={<LoadingAnimation />}
											options={MTLEngList}
											getOptionLabel={(option) => `${option.username}`}
											renderOption={(props, option) => {
												return (
													<li {...props} key={option.userid}>
														{option.username}
													</li>
												);
											}}
											value={selectedMTLEng}
											renderInput={(params) => <ColoredTextField label={"choose Material Engineer name"} focused {...params} />}
											isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
											onChange={(event, newValue) => handleMtlEngChanged(newValue)}
										/>
									</FormControl>
								</Box>
								<FormControlLabel
									disabled={checkboxChoice !== 4}
									sx={{ pl: 5.2, mt: -1, pt: 1 }}
									control={<Checkbox name="ToBeDetermined" checked={toBeDetermined} onChange={() => dispatch(findPartAction.setToBeDetermined())} />}
									label={<Typography variant="caption">include items with unknown categories (_To be determined)</Typography>}
								/>
							</Box>
						</Stack>

						<Box ml={1}>
							<Tooltip title={`For shortcut press ${OSAltKey} + O`}>
								<Button type="submit" onClick={findPartHandler}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>O</u>K
								</Button>
							</Tooltip>
							<Tooltip title={`For shortcut press ${OSAltKey} + O`}>
								<Button onClick={closeFindPartHandler}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
								</Button>
							</Tooltip>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Box>
					</form>
				</Box>
			</Modal>
		</Box>
	);
};

export default FindPart;
