import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const BottomBarIconButton = styled(IconButton)(({ theme }) => ({
	// "&:hover": { backgroundColor: "rgba(233,0,41,0.95)", color:"white" },
	"&:hover": { backgroundColor: "rgba(0, 0, 0, 0.87)" },
	color: "white",
	backgroundColor: "#E90029",
	height: "35px",
	width: "35px",
	borderRadius: 5,
	marginRight: 4,
	[theme.breakpoints.down("sm")]: {
		marginRight: 1,
	},
}));

export default BottomBarIconButton;
