import { useState , useEffect} from "react"
import { FormControl, InputLabel, Select, MenuItem} from "@mui/material"

const DropdownFilter = (props) => {

    const {
        size = "small",
        width = "100%",
        height = "100%",
        label = undefined,
        name = undefined , 
        highlightColor = "green",
        highlightItemList = undefined,
        selectedData,
        required = false,
    } = props
    const selections = props.selections instanceof Array && props.selections.length > 0 ? props.selections : undefined
    const [selected, setSelected] = useState(selectedData ? selectedData : selections ? selections[0] : "")
    const handleSelection = (event) => {
        event.preventDefault()
        setSelected(event.target.value)
        if(typeof props.handleSelection === "function"){
            props.handleSelection(event.target.value,name)
        }
    }
    useEffect(()=>{
        if(selectedData){
            if(selections && selections.includes(selectedData)){
                setSelected(selectedData)
            }
        }
    },[selectedData , selections])
    useEffect(()=>{
        if(selections && selectedData === ""){
            setSelected(selections[0])
        }
    },[selections , selectedData])

    
    return (
        <FormControl  
            focused
            sx={{
                width:width,

            }} 
            required={required}
            {...props.containerProps}
        >
            {
                label ? 
                    <InputLabel 
                        size={size} 
                        {...props.InputLabelProps}
                    >
                        {label}
                    </InputLabel>
                : ""
            }
            <Select
                size={size}
                value={selected}
                label={label}
                displayEmpty
                onChange={handleSelection}
                sx={{
                    "&.MuiOutlinedInput-root":{
                        width: width ,
                        height: height 
                    },
                    color: highlightItemList && highlightItemList.includes(selectedData) && highlightColor
                    
                }}
                MenuProps={{
                    disableScrollLock: true,
                    sx: { maxHeight: "60%" , width: "100%" },
                }}
            >								
                {
                    selections && selections.length > 0 ? selections.map((value, index) => (
                        <MenuItem 
                            key={index} 
                            value={value}
                            sx={{ 
                                color: highlightItemList && highlightItemList.includes(value) && highlightColor
                            }}
                        >
                            {value}
                        </MenuItem>
                    )) : 
                    <MenuItem value={''}>
                        -
                    </MenuItem>
                }
            </Select>
        </FormControl>
    )
}

export default DropdownFilter