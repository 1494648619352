import { Box, Button, Modal, Stack, Tooltip } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const MessageBox = (props) => {
	const { open, message, messageBoxHandler } = props;

	return (
		<Box
			onKeyDown={(e) => {
				if (e.code === "KeyO") {
					messageBoxHandler();
				}
			}}
		>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box sx={style}>
					<Stack mb={2} spacing={2}>
						{message}
					</Stack>
					<Tooltip title={"Press o to close"}>
						<Button onClick={messageBoxHandler}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>O</u>K
						</Button>
					</Tooltip>
				</Box>
			</Modal>
		</Box>
	);
};

export default MessageBox;
