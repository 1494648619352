import { createSlice } from "@reduxjs/toolkit";
import { Admin_User, Mtl_Eng_User } from "../../Components/Util/Util";

const InitialState = {
	accessToken: "",
	userRole: "",
	userNTID: "",
	userName: "",
	userOrg: "",
	sessionExpired: true,
	Non_Mlt_Eng_Access: true,
	Mlar_Admin: false,
	User_Assigned_Orgs: { original: [], primaryOrg: "", secondaryOrg: [], allOrg: [""] },
};

const authenticationSlice = createSlice({
	name: "authentication",
	initialState: InitialState,
	reducers: {
		setAccessToken(state, action) {
			state.accessToken = action.payload;
		},
		setUserInfo(state, action) {
			const arrayPattern = /\[[^\]]*\]/g;
			if (arrayPattern.test(action.payload.userRole)) {
				state.userRole = JSON.parse(action.payload.userRole.replace(/'/g, '"'));
			} else {
				state.userRole = action.payload.userRole;
			}
			state.userNTID = action.payload.userNTID;
			state.userName = action.payload.userName;
			state.userOrg = action.payload.userOrg;
			state.Non_Mlt_Eng_Access = state.userRole && (state.userRole.includes(Mtl_Eng_User) || state.userRole.includes(Admin_User) ? false : true);
			state.Mlar_Admin = state.userRole && state.userRole.includes(Admin_User);
		},
		setSessionExpired(state, action) {
			state.sessionExpired = action.payload;
		},
		setUser_Assigned_Orgs(state, action) {
			const USER_ASSIGNED_ORGS_LIST = action.payload.USER_ASSIGNED_ORGS_LIST;
			state.User_Assigned_Orgs.original = USER_ASSIGNED_ORGS_LIST;
			let primaryOrg;
			let secondaryOrg = [];
			USER_ASSIGNED_ORGS_LIST.forEach((data) => {
				if (data.isprimary === "Y") {
					primaryOrg = data.user_org_c;
				} else {
					secondaryOrg = [...secondaryOrg, data.user_org_c];
				}
			});
			state.User_Assigned_Orgs.primaryOrg = primaryOrg;
			state.User_Assigned_Orgs.secondaryOrg = secondaryOrg;
			state.User_Assigned_Orgs.allOrg = [primaryOrg, ...secondaryOrg];
		},
	},
});

export const authenticationAction = authenticationSlice.actions;

export default authenticationSlice;
