// Packages Import
import { memo, useState, useEffect} from "react"
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
// Project Components
import InfoTable from "../../../UI/Table"
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";
import {formatDate , DateSortingComparator} from "../../../Util/Util"
import { rrActions } from "../../../../store/RR/RR-slice"

const columnsSettings = [
	{ field: "partNum", headerName: "Part Number", type: "text", minWidth: 50, flex: 0.5 },
	{ field: "orderId", headerName: "Order ID", type: "text", minWidth: 70, flex: 0.5 },
	{ field: "orderType", headerName: "Order Type", type: "text", minWidth: 50, flex: 0.5 },
    { field: "line", headerName: "Line", type: "text", minWidth: 50, flex: 0.5 },
    { field: "cmSite", headerName: "CM Site", type: "text", minWidth: 50, flex: 0.5 },
    { field: "orderSite", headerName: "Order Site", type: "text", minWidth: 50, flex: 0.5 },
    { field: "status", headerName: "Status", type: "text", minWidth: 50, flex: 0.5 },
    { field: "quantity", headerName: "Quantity", type: "text", minWidth: 50, flex: 0.5 },
    { field: "dueDate", headerName: "Due Date", type: "text", minWidth: 50, flex: 0.5, sortComparator: DateSortingComparator },

]


const RRSOLinesTable = (props) => {
	const dispatch = useDispatch();
	const [CMForecastAccordian, setCMForecastAccordian] = useState(false);
	const rows = useSelector((state) => state.rrState.RR_SO_LINES_TABLE)
	const partNum = useSelector((state) => state.rrState.PART_INFO.partNum)
	const columnVisibility = useSelector((state) => state.rrState.tableColumnVisibilityRRSOLines)

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport 
				printOptions={{
					disableToolbarButton: true,
				}} 
				csvOptions={{
					fileName: `RR_SO_LINES_KPN${partNum}_${formatDate(new Date(), "")}`
				}}
			/>
		</>
	)
	useEffect(() => {
		setCMForecastAccordian(JSON.parse(localStorage.getItem("RRSOLinesAccordian")));
	}, []);

	// Store Accordion state into browser's local storage
	const accordionClicked = () => {
		window.localStorage.setItem("RRSOLinesAccordian", !CMForecastAccordian);
		setCMForecastAccordian(!CMForecastAccordian);
	};
	return (
		<CustomAccordion onChange={accordionClicked} expanded={CMForecastAccordian}>
			<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Typography sx={{ fontWeight: "bold" }}>RR SO Lines</Typography>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
				<InfoTable 
				columnsSettings={columnsSettings} 
				rows={rows}
				// maxtableHeight={props.maxtableHeight || 1}
				// height={props.height || 319}
				// autoTableHeight={typeof props.autoTableHeight === "boolean" ? props.autoTableHeight : true }
				checkboxSelection = {true}
				customToolBar={customToolBar}
				ExtraProps = {{
					columnVisibilityModel:columnVisibility,
					onColumnVisibilityModelChange:(newModel) =>{
						dispatch(rrActions.setColumnVisibility({columnVisibility:newModel , table:"RRSOLines"}));
					}
				}}
				/>
			</CustomAccordionDetails>
		</CustomAccordion>
	
	)
}

export default memo(RRSOLinesTable)
