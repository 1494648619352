// React
import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubsetAction } from "../../../store/Subset/Subset-slice";
import { infoAction } from "../../../store/Info/info-slice";
import { noteAction } from "../../../store/Note/Note-slice";
import { wuBomAction } from "../../../store/WuBom/WuBom-slice";
import { RoHSAction } from "../../../store/RoHS/RoHS-slice";
import { cmActions } from "../../../store/CM/CM-slice";
import { rrActions } from "../../../store/RR/RR-slice";
import { useLocation } from "react-router-dom";

// UI
import { Box, Button, Modal, Stack, Typography, Grid, Paper, List, ListItemText, AppBar, Toolbar, ListItem, Tooltip } from "@mui/material";
import ColoredTextField from "../../UI/ColoredTextField";
import CreateNewSubsetName from "./CreateNewSubsetName";
import useHttp from "../../../Hooks/use-http";
import ErrorBox from "../../Util/ErrorBox";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";

// Package Import
import { storePartHistories, APIURL, OSAltKey } from "../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "85%", lg: "60%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

export const subsetReloadPage = (urlPathName, dispatch) => {
	switch (urlPathName) {
		case "/Info":
			dispatch(infoAction.setReloadPartNumber());
			break;
		case "/Note":
			dispatch(noteAction.setReloadPartNumber());
			break;
		case "/WUBOM":
			dispatch(wuBomAction.setReloadPartNumber());
			break;
		case "/RoHS":
			dispatch(RoHSAction.setReloadPartNumber());
			break;
		case "/CM":
			dispatch(cmActions.setReloadPartNumber());
			break;
		case "/RR":
			dispatch(rrActions.setReloadPartNumber());
			break;
		default:
			break;
	}
};

const CreateSubset = (props) => {
	const { open, onClose } = props;
	const dispatch = useDispatch();
	const { pathname: urlPathName } = useLocation();

	// on load
	const { sendRequest: fetchData, isLoading } = useHttp();
	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const createRef = useRef(null);

	// Open CreateNewSubsetName
	const [openSetName, setOpenSetName] = useState(false);

	const selectedSubset = useSelector((state) => state.SubsetState.selectedSubset);
	const [inputValue, setInputValue] = useState("");
	const [inputIsEmpty, setInputIsEmpty] = useState(false);

	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	useEffect(() => {
		if (open) {
			fetchData(
				{ url: `${APIURL}subset/subsetList/${userNTID}`, headers },
				(data) => {
					console.log(data);
					createRef.current.focus();
					if (data.Subset_Info.length > 0) {
						dispatch(SubsetAction.setSubsetInfo({ data }));
						const subsetName = JSON.parse(window.sessionStorage.getItem("subsetName"));
						const allSubsetName = data.Subset_Info.map((data) => data.subsetid);
						if (subsetName && allSubsetName.includes(subsetName)) {
							dispatch(SubsetAction.setSelectedSubset(subsetName));
						} else {
							dispatch(SubsetAction.setSelectedSubset(allSubsetName[0]));
						}
					}
				},
				(error) => {
					console.log(`ERROR: Unable to Retrieve subset Information, ${error}`);
					setErrorMsg(`ERROR: Unable to Retrieve subset Information, please try refresh the page ${error}`);
					setErrorState(true);
				}
			);
		}
	}, [dispatch, fetchData, headers, open, userNTID]);

	const handleInputChanged = (event) => {
		if (event.target.value.trim() === "") {
			setInputIsEmpty(true);
		} else {
			setInputIsEmpty(false);
		}
		const inputValue = event.target.value
			.split(/\n|[,]/g)
			.map((element) => element.trim())
			.filter((element) => element !== "");
		const uniqueValue = inputValue.filter((e, i, a) => a.indexOf(e) === i);
		// const duplicatedValue = inputValue.filter((e, i, a) => a.indexOf(e) !== i);
		dispatch(SubsetAction.setInputPartNumbers(uniqueValue));
		setInputValue(event.target.value);
	};

	const handleCreateClicked = () => {
		// Open a dialog box to enter Subset name
		if (inputValue.trim() === "") {
			setInputIsEmpty(true);
		} else {
			setOpenSetName(true);
		}
	};

	const closeSetName = () => {
		setOpenSetName(false);
	};

	const handleRedirect = () => {
		setOpenSetName(false);
		// Reset inputs
		dispatch(SubsetAction.reset());
		dispatch(SubsetAction.setSubsetLoaded(true));
		setInputValue("");
		onClose();
		// Reload the part number for current page
		subsetReloadPage(urlPathName, dispatch);
	};

	const closeMessageBox = () => {
		// Store part numbers as Subset
		storePartHistories(selectedSubset.partnumber[0]);
		window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(selectedSubset.partnumber));
		window.sessionStorage.setItem("subsetName", JSON.stringify(`${selectedSubset.subsetid}`));
		window.sessionStorage.setItem("subsetLoaded", true);

		handleRedirect();
	};

	const handleOnKeyDown = (e) => {
		if (e.code === "KeyC" && e.altKey === true) {
			e.preventDefault();
			handleCreateClicked();
		} else if (e.code === "KeyS" && e.altKey === true && selectedSubset?.partnumber.length !== 0) {
			e.preventDefault();
			closeMessageBox();
		} else if (e.code === "KeyX" && e.altKey === true) {
			e.preventDefault();
			onClose();
		}
	};

	return (
		<Box>
			<CreateNewSubsetName open={openSetName} onClose={closeSetName} handleRedirect={handleRedirect} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<FullScreenLoadingIndicator loading={isLoading} />

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						handleOnKeyDown(e);
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Create a Subset"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Grid mt={5} mb={2} container direction="row" justifyContent="center" alignItems="center" rowSpacing={1.5} columnSpacing={2} wrap={"wrap"}>
						<Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
							<Stack>
								<Typography>{"Enter part numbers seperated by [ , ] or by carriage return [enter]."}</Typography>
								<ColoredTextField
									inputRef={createRef}
									error={inputIsEmpty}
									helperText={inputIsEmpty && "No part number detected, please try again"}
									focused
									multiline
									rows={10}
									value={inputValue}
									onChange={handleInputChanged}
								/>
							</Stack>
						</Grid>
						<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
							<Stack spacing={2}>
								<Typography>{"Example of correct entries"}</Typography>
								<ColoredTextField
									focused={false}
									inputProps={{ readOnly: true }}
									InputLabelProps={{ style: { color: "#111111" } }}
									label="Carriage return delimited"
									value={`1818-1234\n1820-1234\n1818-2345`}
									multiline
									rows={4}
								/>
								<ColoredTextField
									focused={false}
									inputProps={{ readOnly: true }}
									InputLabelProps={{ style: { color: "#111111" } }}
									label="Comma delimited"
									value={["1818-1234", "1820-1234", "1818-2345"]}
									multiline
									rows={4}
								/>
							</Stack>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
							<Stack>
								<Typography>{"Subset parts list"}</Typography>
								<Paper sx={{ height: 300, overflow: "auto" }}>
									<List disablePadding component="div" role="list">
										{selectedSubset?.partnumber?.map((value, index) => {
											const labelId = `transfer-list-item-${value}-label`;
											return (
												<ListItem key={index} role="listitem" divider>
													<ListItemText id={labelId} primary={value} sx={{ m: 0 }} />
												</ListItem>
											);
										})}
									</List>
								</Paper>
							</Stack>
						</Grid>
					</Grid>
					<Box>
						<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
							<Button onClick={handleCreateClicked}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>reate
							</Button>
						</Tooltip>
						<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
							<span>
								<Button onClick={closeMessageBox} disabled={selectedSubset?.partnumber.length === 0}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>elect
								</Button>
							</span>
						</Tooltip>
						<Tooltip title={`For shortcut press ${OSAltKey} + E`}>
							<Button onClick={onClose}>
								E<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>X</u>IT
							</Button>
						</Tooltip>
					</Box>
					<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
				</Box>
			</Modal>
		</Box>
	);
};

export default CreateSubset;
