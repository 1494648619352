// React
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";

// UI
import { Box, Button, Modal, Typography } from "@mui/material";
import ErrorBox from "../../../Util/ErrorBox";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";

// Projects
import useHttp from "../../../../Hooks/use-http";
import { APIURL } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const DeleteMpn = (props) => {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const { sendRequest: fetchData } = useHttp();
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };

	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [deleteSuccessMsg, setDeleteSuccessMsg] = useState("");
	const [RoHSErrorState, setRoHSErrorState] = useState(false);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const itemPartNumber = useSelector((state) => state.rohsStates.partInfo.partNumber);

	const deleteMpnHandler = () => {
		setIsLoading(true);
		const deleteDetails = { item: itemPartNumber, userid: userNTID, ...props.mpnDetails };

		fetchData(
			{ url: `${APIURL}Rohs/deleteMPN`, method: "POST", headers, body: deleteDetails },
			(data) => {
				setDeleteSuccessMsg(`MPN deleted Successfully`);
				setIsLoading(false);
				dispatch(RoHSAction.retrievePart(data));
				dispatch(RoHSAction.retrieveMpn({ mpn: "first_load" }));
				dispatch(RoHSAction.setEditingTable(false));
				setTimeout(() => {
					props.setShowDeletePopUp(false);
					setDeleteSuccessMsg("");
				}, 1500);
			},
			(error) => {
				setRoHSErrorState(true);
				console.log(`ERROR: ${error}, Unexpected Error occured when deleting MPN, please refresh the page and try again`);
				setErrorMsg(`ERROR: ${error}, Unexpected Error occured when deleting MPN, please refresh the page and try again`);
				props.setShowDeletePopUp(false);
				setIsLoading(false);
			}
		);
	};

	const cancelHandler = () => {
		props.setShowDeletePopUp(false);
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setRoHSErrorState} ErrorState={RoHSErrorState} />

			<Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyY") {
							deleteMpnHandler();
						} else if (e.code === "KeyN") {
							cancelHandler();
						}
					}}
				>
					{deleteSuccessMsg ? (
						<Typography mb={2} ml={0.4} fontSize={20}>
							{deleteSuccessMsg}
						</Typography>
					) : (
						<>
							<Typography mb={2} fontSize={20}>
								Do you want to delete <i style={{ color: "rgb(0,0,128)" }}>{props.selectedMfgName}</i>:{" "}
								<i style={{ color: "rgb(0,0,128)" }}>{props.selectedMpn}</i>
							</Typography>
							<Button onClick={deleteMpnHandler}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>Y</u>es
							</Button>
							<Button onClick={cancelHandler}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>N</u>o
							</Button>
						</>
					)}
				</Box>
			</Modal>
		</Box>
	);
};

export default DeleteMpn;
