// React
import { useState } from "react";

// UI
import { Autocomplete, Typography, Box } from "@mui/material";
import ColoredTextField from "./ColoredTextField";
import CircularProgress from "@mui/material/CircularProgress";

const AutoCompleteEmployee = (props) => {
	// Required
	const { onChange, value, handleEmployeeInputChange, options, isLoading, name } = props;
	// Optional
	const { label, error, helperText, onClose } = props;

	const [inputValue, setInputValue] = useState("");

	const handleInputChange = (event, newValue, reason) => {
		setInputValue(newValue);

		handleEmployeeInputChange(newValue, name, reason);
	};

	return (
		<>
			<Autocomplete
				fullWidth
				onClose={onClose}
				autoHighlight
				id="AutoComplete Employee"
				options={!isLoading ? options : []}
				loading={isLoading}
				onChange={onChange}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				filterOptions={(options) => options}
				loadingText={
					<Box display={"flex"} sx={{ mx: "auto" }}>
						<CircularProgress color="inherit" size={80} />
					</Box>
				}
				value={value === "" ? null : value}
				isOptionEqualToValue={(option, value) => {
					if (options.includes(value)) {
						return option === value;
					} else {
						// Required when the text input is empty or when the user queried different name
						return value === null || value;
					}
				}}
				renderInput={(params) => <ColoredTextField error={error ? true : false} focused {...params} label={label} />}
			/>
			<Typography variant="caption" color={error && "secondary"}>
				{error || helperText}
			</Typography>
		</>
	);
};

export default AutoCompleteEmployee;
