import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";
import { useState } from "react";

import { Box, Button, Modal, Stack, Typography, Tooltip } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";

import { OSAltKey } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const ChangeRequestMCC = (props) => {
	const dispatch = useDispatch();

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const { primaryOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);

	const open = useSelector((state) => state.rohsStates.showChangeMCCPopUp);
	const itemPartNumber = useSelector((state) => state.rohsStates.partInfo.partNumber);
	const selectedMpn = useSelector((state) => state.rohsStates.selectedMpn);
	const selectedMfgId = useSelector((state) => state.rohsStates.selectedMfgId);
	const selectedMfgName = useSelector((state) => state.rohsStates.selectedMfgName);
	const editedMpnNote = useSelector((state) => state.rohsStates.editedMpnNote);
	const mccChangeRequest = useSelector((state) => state.rohsStates.mccChangeRequest);
	const showSuccessMessage = useSelector((state) => state.rohsStates.showSuccessMessage);

	const [noteEdited, setNoteEdited] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("false");
	const ischangeRequest = true;

	const saveNoteHandler = () => {
		const MCCJSON = {
			item: itemPartNumber,
			userid: userNTID,
			userorg: primaryOrg,
			mfg_pn: selectedMpn,
			mfgid: selectedMfgId,
			mfg_name: selectedMfgName,
			note: editedMpnNote.note,
			change_request_to_mcc: "True",
			mccreq: mccChangeRequest.mccreq === "T" ? "F" : "T",
			mccreqsend: "F",
		};

		if (editedMpnNote.note?.trim() !== "") {
			const mpnInfo = `${selectedMfgName}: ${selectedMpn}`;
			props.updateMpnNote(MCCJSON, mpnInfo, ischangeRequest);
			setNoteEdited(false);
		} else if (editedMpnNote.note?.trim() === "") {
			setBlankError();
		}
	};

	const handleEditNote = (event) => {
		if (!noteEdited) setNoteEdited(true);
		dispatch(RoHSAction.handleEditMpnNote(event.target.value));
		if (event.target.value?.trim() === "") {
			setBlankError();
		} else if (error) setError(false);
	};

	const setBlankError = () => {
		setErrorMessage("Note cannot be blank");
		setError(true);
	};

	const cancelEditNoteHandler = () => {
		dispatch(RoHSAction.cancelUpdateMpnNote());
		dispatch(RoHSAction.setShowChangeMCC(false));
		setError(false);
	};

	const handleOnKeyDown = (e) => {
		if (e.code === "KeyS" && e.altKey === true && !error) {
			e.preventDefault();
			saveNoteHandler();
		} else if (e.code === "KeyC" && e.altKey === true) {
			e.preventDefault();
			cancelEditNoteHandler();
		}
	};

	return (
		<Box>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						handleOnKeyDown(e);
					}}
				>
					{showSuccessMessage ? (
						<Typography fontSize={20}>Saved change for MCC request.</Typography>
					) : (
						<>
							<Stack mb={2} spacing={1}>
								<Typography fontSize={14} mr={2} mb={2}>
									Requesting MCC review for MPN <i style={{ color: "rgb(0,0,128)" }}>{selectedMfgName}</i>:{" "}
									<i style={{ color: "rgb(0,0,128)" }}>{selectedMpn}</i>
								</Typography>
								<ColoredTextField
									fullWidth
									label={"Reason for this request"}
									minRows={3}
									maxRows={3}
									multiline
									focused
									onChange={handleEditNote}
									value={editedMpnNote.note}
									error={error}
									helperText={error && errorMessage}
								/>
							</Stack>
							<Box>
								<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
									<span>
										<Button disabled={error} onClick={saveNoteHandler}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ave
										</Button>
									</span>
								</Tooltip>
								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<Button onClick={cancelEditNoteHandler}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</Tooltip>
								<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
							</Box>
						</>
					)}
				</Box>
			</Modal>
		</Box>
	);
};

export default ChangeRequestMCC;
