// React
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authenticationAction } from "./store/common/Authentication-slice";

// UI
import Layout from "./Components/Layout/Layout";
import FullScreenLoadingIndicator from "./Components/UI/FullScreenLoadingIndicator";
import ErrorBox from "./Components/Util/ErrorBox";
import UserNotAuthorized from "./SSO/UserNotAuthorized";
import InvalidPage from "./SSO/InvalidPage";
import LogOut from "./SSO/LogOut";
import SessionExpired from "./SSO/SessionExpired";
import ProtectedRoute from "./SSO/ProtectedRoute";
import PublicRoute from "./SSO/PublicRoute";
import Info from "./Components/Tabs/Info/Info";
import Note from "./Components/Tabs/Note/Note";
import WuBom from "./Components/Tabs/WuBom/WuBom";
import UserProfile from "./Components/Menu/UserProfile";
import RoHS from "./Components/Tabs/RoHS/RoHS";
import Mtl from "./Components/Tabs/Mtl_Eng_Info/MtlEng";
import Projects from "./Components/Tabs/Projects/Projects";
import UploadBom from "./Components/Menu/UploadBom/UploadBom";
import CM from "./Components/Tabs/CM/CM";
import RR from "./Components/Tabs/RR/RR";

// MUI
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./Theme";

// SSO related
import jwt_decode from "jwt-decode";
import useHttp from "./Hooks/use-http";
import { TOKEN_REFRESH_API, TOKEN_VERIFY_API, SSO_RELOGIN_URL, clearLoginLocalStorage, APIURL } from "./Components/Util/Util";
import { Decrypt_Local_Storage, Encrypt_Local_Storage } from "./SSO/AESJSONFormatter";

function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { sendRequest: fetchData } = useHttp();
	const { isLoading, sendRequest: fetchUserOrg } = useHttp();
	const { pathname: urlPathName } = useLocation();
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [isFirstRun, setIsFirstRun] = useState(true);

	// SSO related
	const token = useSelector((state) => state.authenticationState.accessToken);
	const sessionExpired = useSelector((state) => state.authenticationState.sessionExpired);
	const partNumber = new URLSearchParams(window.location.search).get("partNumber") || "";
	const goto = new URLSearchParams(window.location.search).get("goto") || "";
	const currentTab = goto ? `/${goto}` : localStorage.getItem("currentTab");
	const paramAccess = new URLSearchParams(window.location.search).get("token") || "";
	const userRole = new URLSearchParams(window.location.search).get("userRole") || Decrypt_Local_Storage("userRole");
	const userNTID = new URLSearchParams(window.location.search).get("userNTID") || Decrypt_Local_Storage("userNTID");
	const userName = new URLSearchParams(window.location.search).get("userName") || localStorage.getItem("userName");
	const userOrg = new URLSearchParams(window.location.search).get("userOrg") || Decrypt_Local_Storage("userOrg");
	const userNotAuthorized =
		new URLSearchParams(window.location.search).get("userNotAuthorized") ||
		new URLSearchParams(window.location.search).get("UserNotAuthorized") ||
		"";
	const protectedPathNames = ["/UserProfile", "/Info", "/Note", "/WUBOM", "/MTLEngInfo", "/RoHS", "/Projects", "/UploadBom", "/CM", "/RR"];

	if (partNumber) {
		window.sessionStorage.setItem("PartNumber", partNumber);
	}
	if (goto) {
		window.localStorage.setItem("currentTab", `/${goto}`);
	}
	if (paramAccess) {
		window.localStorage.setItem("loggedOut", false);
	}
	if (paramAccess && userRole) {
		window.localStorage.setItem("userRole", Encrypt_Local_Storage(userRole));
	}
	if (paramAccess && userNTID) {
		window.localStorage.setItem("userNTID", Encrypt_Local_Storage(userNTID));
	}
	if (paramAccess && userName) {
		window.localStorage.setItem("userName", userName);
	}
	if (paramAccess && userOrg) {
		window.localStorage.setItem("userOrg", Encrypt_Local_Storage(userOrg));
	}

	// Save protected url pathname into local storage for future redirect
	if (protectedPathNames.includes(urlPathName)) {
		window.localStorage.setItem("currentTab", urlPathName);
	}

	const Expired = () => {
		// Clear all the local storage related to login and bring the user to Session Expired page
		clearLoginLocalStorage();
		navigate("/SessionExpired");
	};

	useEffect(() => {
		console.log(`build stage = ${process.env.REACT_APP_STAGE}`);
		dispatch(authenticationAction.setUserInfo({ userRole, userNTID, userName, userOrg }));

		const getUserOrg = (devToken) => {
			if (userNTID) {
				const headers = { "Content-Type": "application/json", Authorization: "Bearer " + devToken };
				fetchUserOrg(
					{
						url: `${APIURL}MtlEngInfo/assingedUserOrgs/${userNTID}/`,
						headers,
					},
					(data) => {
						window.localStorage.setItem("allUserOrg", Encrypt_Local_Storage(JSON.stringify(data)));
						dispatch(authenticationAction.setUser_Assigned_Orgs(data));
					},
					(error) => {
						setErrorState(true);
						setErrorMsg(`ERROR: Unable to Retrieve user orgs, please try refreshing the page. ${error}`);
						console.log("ERROR: Unable to Retrieve user orgs", error);
					}
				);
			}
		};

		const allUserOrg = Decrypt_Local_Storage("allUserOrg");
		if (allUserOrg) {
			dispatch(authenticationAction.setUser_Assigned_Orgs(JSON.parse(allUserOrg)));
		}

		const verify = (paramAccess) => {
			let headers = { "Content-Type": "application/json" };
			fetchData(
				{
					url: TOKEN_VERIFY_API,
					method: "POST",
					headers,
					body: { token: paramAccess },
				},
				(data) => {
					// The API returning empty data indicate the token is still valid
					if (Object.keys(data).length === 0) {
						dispatch(authenticationAction.setAccessToken(paramAccess));
						dispatch(authenticationAction.setSessionExpired(false));
						console.log(`Token Verified ${new Date()}`);
						getUserOrg(paramAccess);
						setIsFirstRun(false);
					} else {
						// Token Expired
						silentRefresh();
					}
					// console.log("verified token here");
					// console.log(data);
					// console.log(paramAccess);
				},
				(error) => {
					console.log("ERROR: Token Verification", error);
					window.location.replace(SSO_RELOGIN_URL);
				}
			);
		};

		const silentRefresh = () => {
			let decoded;
			let expiration;
			try {
				decoded = jwt_decode(token);
				// time until expiration = ((jwt unix time - 60s) * 1000) - current time
				expiration = new Date((decoded.exp - 60) * 1000) - new Date();
			} catch (error) {
				console.log("ERROR: JWT Decode", error);
			}

			setTimeout(() => {
				console.log("Attempting to refresh Token");
				let headers = { "Content-Type": "application/json" };
				fetchData(
					{
						url: TOKEN_REFRESH_API,
						// To pass HTTPONLY cookie to backend
						credentials: "include",
						method: "POST",
						headers,
						body: {},
					},
					(data) => {
						dispatch(authenticationAction.setAccessToken(data.access));
						// Unhide the navigation header and disable public route
						dispatch(authenticationAction.setSessionExpired(false));
						console.log(`Token Refreshed ${new Date()}`);
						// console.log(data.access);
						setIsFirstRun(false);
					},
					(error) => {
						console.log("ERROR: JWT Refresh", error);
						// Hide the navigation header and enable public route
						dispatch(authenticationAction.setSessionExpired(true));
						console.log(`isFirstRun: ${isFirstRun}`);
						if (isFirstRun) {
							window.location.replace(SSO_RELOGIN_URL);
						} else {
							Expired();
						}
					}
				);
			}, expiration);

			console.log(expiration);

			// return a function that clear setTimeout
			return () => clearTimeout(expiration);
		};

		// Only continue if it is not a local environment
		if (!process.env.REACT_APP_LOCAL) {
			// Only continue if user is authorized
			if (!userNotAuthorized) {
				if (!paramAccess && !token && !userNTID && (protectedPathNames.includes(urlPathName) || urlPathName === "/")) {
					// Try to relogin with SSO if the user had logged out
					window.location.href = SSO_RELOGIN_URL;
				} else if (paramAccess) {
					verify(paramAccess);
				} else if (token || userNTID) {
					// Try to grab new access token if there is record of user logged in
					silentRefresh();
				}
			} else {
				navigate("/NotAuthorized");
			}
		} else {
			// Insert access token here for Dev environment
			const devToken = "here";
			dispatch(authenticationAction.setAccessToken(devToken));
			getUserOrg(devToken);
			dispatch(authenticationAction.setSessionExpired(false));
		}
	}, [paramAccess, token]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Layout sessionExpired={sessionExpired}>
				<FullScreenLoadingIndicator loading={isLoading} />
				<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState}></ErrorBox>
				<Routes>
					{/* Protected Route */}
					{/* Please update the protectedPathNames list declared above if you add a new path */}
					<Route path="/" element={<ProtectedRoute token={token} />}>
						<Route path="/" element={<Navigate to={currentTab || "/Info"} />} />
						<Route path="/UploadBom" element={<UploadBom />} />
						<Route path="/Info" element={<Info />} />
						<Route path="/Note" element={<Note />} />
						<Route path="/WUBOM" element={<WuBom />} />
						<Route path="/RoHS" element={<RoHS />} />
						<Route path="/MTLEngInfo" element={<Mtl />} />
						<Route path="/UserProfile" element={<UserProfile />} />
						<Route path="/Projects" element={<Projects />} />
						<Route path="/CM" element={<CM />} />
						<Route path="/RR" element={<RR />} />
					</Route>
					{/* Public Route */}
					<Route path="/" element={<PublicRoute sessionExpired={sessionExpired} />}>
						<Route path="/LogOut" element={<LogOut />}></Route>
						<Route path="/SessionExpired" element={<SessionExpired />} />
						<Route path="/NotAuthorized" element={<UserNotAuthorized />} />
					</Route>
					{/* Invalid Page handling */}
					<Route path="/InvalidPage" element={<InvalidPage />} />
					<Route path="*" element={<Navigate to="/InvalidPage" replace />} />
				</Routes>
			</Layout>
		</ThemeProvider>
	);
}

export default App;
