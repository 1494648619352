import { useState, useCallback } from "react";
import { API_TOKEN_ERROR_MSG, API_GENERAL_ERROR_MSG } from "../Components/Util/Util";

const useDownloadFileWithRename = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const downloadExcelFile = useCallback(async (requestConfig, renameFunction, error, cleanUp = () => {}) => {
		const retries = typeof requestConfig.retries === "number" ? requestConfig.retries : 2;
		setIsLoading(true);
		setError(null);

		try {
			const response = await fetch(requestConfig.url, {
				method: "POST",
				headers: requestConfig.headers ? requestConfig.headers : {},
				body:
					requestConfig.body && requestConfig.dataType === "formData"
						? requestConfig.body
						: // else
						requestConfig.body
						? JSON.stringify(requestConfig.body)
						: null,
			});

			if (!response.ok) {
				const error = await response.json();
				if (response.status === 401) {
					if (error.code && error.code === "token_not_valid") {
						throw new Error(API_TOKEN_ERROR_MSG);
					}
				} else if (response.status === 400) {
					throw new Error(error.Error ? error.Error : API_GENERAL_ERROR_MSG);
				} else if (response.status === 404) {
					throw new Error(error?.No_Record ? error.No_Record : API_GENERAL_ERROR_MSG);
				}
				// Default retry up to twice if API call failed
				if (retries > 0) {
					console.log(`api call ${retries} retries left`);
					const newRequestConfig = { ...requestConfig, retries: retries - 1 };
					return downloadExcelFile(newRequestConfig, error);
				} else {
					throw new Error(API_GENERAL_ERROR_MSG);
				}
			}

			const disposition = response.headers.get("content-disposition");
			const filename = disposition.match(/filename=(.+)/)[1] ?? "excel";
			const blob = await response.blob();
			if (typeof renameFunction === "function") {
				renameFunction(blob, filename);
			} else {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (err) {
			error && error(err.message || "Something went wrong!");
			setError(err.message || "Something went wrong!");
		} finally {
			cleanUp();
		}

		setIsLoading(false);
	}, []);

	return {
		isLoading,
		error,
		downloadExcelFile,
	};
};

export default useDownloadFileWithRename;
