import { Box, Button, Modal, Typography, Stack, Tooltip } from "@mui/material";
import { useDispatch , useSelector } from "react-redux";
import { OSAltKey , isNumber } from "../Util/Util";
import { daysBackAction } from "../../store/common/DaysBack-slice";
import ColoredTextField from "../UI/ColoredTextField";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: 400 },
	maxHeight: "80%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "auto",
	p: 4,
	pl: { xs: 1, sm: 4 },
	pr: { xs: 1, sm: 4 },
};

const DaysBack = (props) => {
    const dispatch = useDispatch();
    const popUpState = useSelector((state) => state.daysBackState.popUpState)
    const val = useSelector((state) => state.daysBackState.val);

    const handleDaysBackChange = (event) => {
        const value = event.target.value
        if (isNumber(value) || value === ""){
            dispatch(daysBackAction.setDaysBack(value))
        }
    }

	const closeHandler = () => {
		dispatch(daysBackAction.setPopUpState(false))
	};

	return (
		<Box>
			<Modal open={popUpState} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC") {
							closeHandler();
						}
					}}
				>
					<Stack mb={2} spacing={1}>
                        <ColoredTextField
                            label="Days Back"
                            fullWidth
                            focused
                            onChange={handleDaysBackChange}
                            value={val}
                            InputLabelProps={{ 
                                shrink: true,
                                sx:{
                                    fontWeight:700
                                }
                            }}
                            inputProps={{ 
                                sx: {textAlign: 'center'} 
                            }}
                        />
						<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
							<Button onClick={closeHandler}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
							</Button>
						</Tooltip>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default DaysBack;
