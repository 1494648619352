// Packages Import
import React, { Fragment } from "react";
import { Box, Paper, Tooltip, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

// Project Component
import ColoredTextField from "../UI/ColoredTextField";
import { clearLoginLocalStorage, MLAR_API } from "../Util/Util";

const UserProfile = () => {
	const userInfo = {
		username: useSelector((state) => state.authenticationState.userName),
		role: useSelector((state) => state.authenticationState.userRole),
		org: useSelector((state) => state.authenticationState.userOrg),
	};
	const handleLogoutButton = () => {
		clearLoginLocalStorage();
		window.localStorage.removeItem("currentTab");
		window.location.replace(`${MLAR_API}accounts/logout/`);
	};
	return (
		<React.StrictMode>
			<Fragment>
				<Box maxWidth={{ xl: "45%", lg: "45%", md: "60%", sm: "80%", xs: "100%" }} mx={"auto"} mt={2}>
					<Paper elevation={2} sx={{ p: 3, pb: 5, height: "100%" }}>
						<Typography variant="h3" align="center">
							User Profile
						</Typography>
						<Tooltip title={userInfo.username}>
							<ColoredTextField
								label="Username"
								value={userInfo.username}
								fullWidth
								focused
								sx={{ mt: 3 }}
								inputProps={{
									readOnly: true,
								}}
								InputLabelProps={{
									shrink: true,
									style: {
										fontWeight: "bold",
									},
								}}
							/>
						</Tooltip>
						<Tooltip title={userInfo.role}>
							<ColoredTextField
								label="Role"
								value={userInfo.role}
								fullWidth
								focused
								sx={{ mt: 3 }}
								inputProps={{
									readOnly: true,
								}}
								InputLabelProps={{
									shrink: true,
									style: {
										fontWeight: "bold",
									},
								}}
							/>
						</Tooltip>
						<Tooltip title={userInfo.org}>
							<ColoredTextField
								label="Organization"
								value={userInfo.org}
								fullWidth
								focused
								sx={{ mt: 3 }}
								inputProps={{
									readOnly: true,
								}}
								InputLabelProps={{
									shrink: true,
									style: {
										fontWeight: "bold",
									},
								}}
							/>
						</Tooltip>
						<Button variant="contained" onClick={handleLogoutButton} fullWidth sx={{ mt: 3 }}>
							Logout
						</Button>
					</Paper>
				</Box>
			</Fragment>
		</React.StrictMode>
	);
};

export default UserProfile;
