// React
import { infoAction } from "../../../../store/Info/info-slice";
import { useDispatch, useSelector } from "react-redux";
// UI
import InfoTable from "../../../UI/Table";
import { cx } from "@emotion/css";
import { Typography } from "@mui/material";
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";
import { useState, useEffect, memo } from "react";
import { GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
// Packages Import
import { formatDate } from "../../../Util/Util";

const columnsSettings = [
	{ field: "org_code", headerName: "Org Code", type: "text", minWidth: 80, flex: 0.1 },
	{ field: "org_desc", headerName: "Org Description", type: "text", minWidth: 280, flex: 1 },
];

const customCellClass = (params) => {
	switch (params.field) {
		case "org_code":
			//Oracle Supplier item"
			return cx("super-app", {
				pointerCursor: true,
				green: true,
			});
		case "org_desc":
			//Oracle Supplier item"
			return cx("super-app", {
				green: true,
			});
		default:
			return params;
	}
};

const OrgTable = () => {
	const dispatch = useDispatch();
	const [InfoAccordionORG, setInfoAccordionORG] = useState(false);
	const orgRows = useSelector((state) => state.infoStates.orgRows);
	const partNum = useSelector((state) => state.infoStates.info.itemname);
	// Retrive AML table accordion state from local storage when first rendering this page
	useEffect(() => {
		setInfoAccordionORG(JSON.parse(localStorage.getItem("InfoAccordionORG")));
	}, []);

	// Store Accordion state into browser's local storage
	const ORGAccordionClicked = () => {
		window.localStorage.setItem("InfoAccordionORG", !InfoAccordionORG);
		setInfoAccordionORG(!InfoAccordionORG);
	};
	const handleOnCellClick = (params) => {
		params.field === "org_code" && params.value && dispatch(infoAction.changeOrg(params.value));
	};
	const customToolBar = () => (
		<>
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `ORG_ACTIVATIONS_KPN${partNum}_${formatDate(new Date(), "")}`,
				}}
			/>
		</>
	);
	return (
		<CustomAccordion onChange={ORGAccordionClicked} expanded={InfoAccordionORG}>
			<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Typography sx={{ fontWeight: "bold" }}>Org Activations</Typography>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
				<InfoTable
					columnsSettings={columnsSettings}
					rows={orgRows}
					handleOnCellClick={handleOnCellClick}
					customCellClass={customCellClass}
					customToolBar={customToolBar}
				/>
			</CustomAccordionDetails>
		</CustomAccordion>
	);
};

export default memo(OrgTable);
