// Packages Import
import { useState } from "react";
import {
	Box,
	Paper,
	Tooltip,
	Typography,
	Button,
	Modal,
	Stack,
	Grid,
	FormControlLabel,
	Radio,
	RadioGroup,
	Checkbox,
	Collapse,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Project Component
import ColoredTextField from "../../../../UI/ColoredTextField";
import DropdownFilter from "../../../../UI/DropdownFilter";
import useHttp from "../../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../../UI/FullScreenLoadingIndicator";
import { RoHSAction } from "../../../../../store/RoHS/RoHS-slice";
import { APIURL, OSAltKey, convertTrueFalse } from "../../../../Util/Util";

const currentBatch = "rohsNote";

const RohsNote = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const onError = typeof props.onError === "function" ? props.onError : () => {};
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const recordCount = useSelector((state) => state.rohsStates.runReport.recordCount);
	const transactionId = useSelector((state) => state.rohsStates.runReport.transactionId);
	const data = useSelector((state) => state.rohsStates.runReport.batchData[currentBatch]);
	const showPopup = useSelector((state) => state.rohsStates.runReport.batchType[currentBatch]);
	const orgList = useSelector((state) => state.rohsStates.runReport.orgList);
	const selectedOrg = useSelector((state) => state.rohsStates.runReport.userOrg);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 800 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
		org: useSelector((state) => state.authenticationState.userOrg),
	};

	const handleOrgChange = (value) => {
		dispatch(
			RoHSAction.updateData({
				data: {
					userOrg: value,
				},
			})
		);
	};

	const handleDropdown = (type) => (value) => {
		dispatch(
			RoHSAction.updateData({
				data: {
					batchData: {
						[currentBatch]: {
							[type]: value,
						},
					},
				},
			})
		);
	};
	const handleCheckboxOptions = (type) => (event) => {
		const value = event.target.checked;
		let noteNewError = false;
		let noteExistingError = false;
		if (type === "noteEdit") {
			if (data.noteNew === "") {
				noteNewError = true;
				if (data.noteExisting === "" && data.noteRadioGroup === "different") {
					noteExistingError = true;
				}
			}
		}
		dispatch(
			RoHSAction.updateData({
				data: {
					batchData: {
						[currentBatch]: {
							[type]: value,
							error: {
								noteNew: noteNewError,
								noteExisting: noteExistingError,
							},
						},
					},
				},
			})
		);
	};
	const handleRadioChange = (type) => (event, value) => {
		dispatch(
			RoHSAction.updateData({
				data: {
					batchData: {
						[currentBatch]: {
							[type]: value,
						},
					},
				},
			})
		);
	};

	const handleInputChange = (type) => (event) => {
		const value = event.target.value;
		let error = false;
		if (value.trim() === "") {
			error = true;
		}
		dispatch(
			RoHSAction.updateData({
				data: {
					batchData: {
						[currentBatch]: {
							[type]: value,
							error: {
								[type]: error,
							},
						},
					},
				},
			})
		);
	};
	const closeHandler = () => {
		dispatch(RoHSAction.setBatchUpdatePopUp({ batchType: currentBatch }));
		dispatch(RoHSAction.resetBatchData());
	};
	const massUpdate = () => {
		closeHandler();
		setLoading(true);
		const jsonReq = {
			userid: userInfo.id,
			userOrg: userInfo.org,
			userOrgNote: selectedOrg,
			checkNoteReq: convertTrueFalse(data.noteEdit),
			notetext1: data.noteNew.trim(),
			notetext2: data.noteExisting.trim(),
			checkDiffNote: data.noteRadioGroup === "different" ? "1": "0",
			checkSameNote: data.noteRadioGroup === "same" ? "1" : "0",
			updateAction: data.noteAction,
			checkt260degReq: convertTrueFalse(data.checkMassUpdate),
			new_t260deg: convertTrueFalse(data.massUpdate260C),
			transaction_id: transactionId,
		};

		fetchDataWithoutLoad(
			{
				url: `${APIURL}Rohs/batchEditROHSNoteT260`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(returnedData) => {
				if (returnedData?.Result) {
					onError(returnedData.Result.toString()); // Temporarily using onError , Should change to infobox later on
				} else {
					throw Error("No Success Message Returned");
				}
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				onError(error.toString());
			}
		);
	};

	const cancelRequest = () => {
		const jsonReq = {
			userid: userInfo.id,
			userOrg: userInfo.org,
			transaction_id: transactionId,
		};

		fetchDataWithoutLoad(
			{
				url: `${APIURL}Rohs/cancelBatchUpdate`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(returnedData) => {
				if (returnedData?.Result) {
					console.log(returnedData.Result.toString());
					// onError(returnedData.Result.toString()); // Temporarily using onError , Should change to infobox later on
				} else {
					throw Error("No Success Message Returned");
				}
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				onError(error.toString());
			}
		);
		closeHandler();
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								cancelRequest();
							}
							if (e.code === "KeyG") {
								if (
									(data.checkMassUpdate || data.noteEdit) &&
									(data.noteEdit &&
										(data.error.noteNew || (data.error.noteExisting && data.noteRadioGroup === "different")))
								) {
									massUpdate();
								}
							}
						}
					}}
				>
					<Stack mb={2} spacing={1}>
						<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
							<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
								Batch Add RoHS Notes
							</Typography>
							<DropdownFilter
								width={90}
								selections={orgList}
								selectedData={selectedOrg}
								handleSelection={handleOrgChange}
								label={"User Org"}
								InputLabelProps={{
									shrink: true,
									sx: {
										fontWeight: 700,
									},
								}}
							/>
						</Box>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								pt: 2,
							}}
						>
							<Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<Typography variant="subtitle" sx={{ textAlign: "center", width: "100%" }}>
										No of Records : <b>{recordCount}</b>
									</Typography>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<FormControlLabel
										control={
											<Checkbox checked={data.checkMassUpdate} onChange={handleCheckboxOptions("checkMassUpdate")} />
										}
										label="Run Mass Update for 260°C "
									/>
									<Collapse in={data.checkMassUpdate} {...(data.checkMassUpdate ? { timeout: 400 } : {})}>
										<FormControlLabel
											control={
												<Checkbox checked={data.massUpdate260C} onChange={handleCheckboxOptions("massUpdate260C")} />
											}
											sx={{
												ml: 3,
											}}
											label="260°C"
										/>
									</Collapse>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<FormControlLabel
										control={<Checkbox checked={data.noteEdit} onChange={handleCheckboxOptions("noteEdit")} />}
										label="Edit Notes"
									/>
								</Grid>
							</Grid>

							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Collapse in={data.noteEdit} {...(data.noteEdit ? { timeout: 400 } : {})}>
									<Paper sx={{ p: 2 }} elevation={5}>
										<Grid container direction="row" spacing={0} columnSpacing={1} alignItems="center">
											<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
												<RadioGroup
													defaultChecked={"different"}
													value={data.noteRadioGroup}
													onChange={handleRadioChange("noteRadioGroup")}
												>
													<FormControlLabel
														value={"different"}
														control={<Radio />}
														label="Different notes for existing and new notes"
													/>
													<FormControlLabel
														value={"same"}
														control={<Radio />}
														label="Same note for parts with/without existing note"
													/>
												</RadioGroup>
											</Grid>
											<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
												<DropdownFilter
													fullwidth
													selections={data.noteActionList}
													selectedData={data.noteAction}
													handleSelection={handleDropdown("noteAction")}
													label={<b>Action for existing Notes</b>}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
										</Grid>
										<Grid
											container
											direction="row"
											spacing={0}
											columnSpacing={1}
											rowSpacing={1}
											sx={{
												pt: 2,
											}}
										>
											{data.noteRadioGroup === "same" ? (
												<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
													<ColoredTextField
														focused
														fullWidth
														multiline
														rows={5}
														label={<b>For New and Existing Notes</b>}
														value={data.noteNew}
														onChange={handleInputChange("noteNew")}
													/>
												</Grid>
											) : (
												<>
													<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
														<ColoredTextField
															focused
															fullWidth
															multiline
															rows={5}
															label={<b>For New Notes</b>}
															value={data.noteNew}
															onChange={handleInputChange("noteNew")}
														/>
													</Grid>
													<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
														<ColoredTextField
															focused
															fullWidth
															multiline
															rows={5}
															label={<b>For Existing Notes</b>}
															value={data.noteExisting}
															onChange={handleInputChange("noteExisting")}
														/>
													</Grid>
												</>
											)}
										</Grid>
									</Paper>
								</Collapse>
							</Grid>
						</Grid>

						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								p: 0,
							}}
						>
							<Grid
								item
								xl={6.1}
								lg={6.1}
								md={6.1}
								sm={6.1}
								xs={6.1}
								sx={{
									pr: 1,
								}}
							>
								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={cancelRequest}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
								<Tooltip title={`For shortcut press ${OSAltKey} + G`}>
									<span>
										<Button
											sx={{ width: "100%", ml: 0 }}
											onClick={massUpdate}
											disabled={
												!(data.checkMassUpdate || data.noteEdit) ||
												(data.noteEdit &&
													(data.error.noteNew || (data.error.noteExisting && data.noteRadioGroup === "different")))
											}
										>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>G</u>O
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
						<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default RohsNote;
