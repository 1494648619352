// React
import { useState } from "react";
import { useSelector } from "react-redux";

// UI
import { Grid, Button, Typography } from "@mui/material";
import ErrorBox from "../../../Util/ErrorBox";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";

// Projects
import { s3BucketName, s3ppDocsName, APIURL, downloadBlob } from "../../../Util/Util";
import { S3Settings } from "../S3Settings";

const RoHSReferenceDocs = () => {
	const accessToken = useSelector((state) => state.authenticationState.accessToken);

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const [isLoading, setIsLoading] = useState(false);

	const [errorMsg, setErrorMsg] = useState("");
	const [RoHSDocErrorState, setRoHSDocErrorState] = useState(false);

	const downloadDocument = async (docName) => {
		setIsLoading(true);
		const S3_Params = {
			Bucket: s3BucketName,
			Key: `${s3ppDocsName}/${docName}`,
		};

		const getS3Config = await S3Settings({ url: `${APIURL}Rohs/getS3ConnectionDetails`, headers });

		if (getS3Config.error) {
			console.log(getS3Config.errorMsg);
			setIsLoading(false);
			setRoHSDocErrorState(true);
			setErrorMsg(`${getS3Config.errorMsg}, please try again`);
		} else {
			getS3Config.getObject(S3_Params, (err, data) => {
				if (err) {
					setIsLoading(false);
					console.log(err, err.stack);
					setRoHSDocErrorState(true);
					setErrorMsg(`ERROR: ${err} Please try again`);
				} else {
					setIsLoading(false);
					const newBlob = new Blob([data.Body], { type: "application/octet-stream;charset=utf-8;" });
					downloadBlob(newBlob, `${docName}`);
				}
			});
		}
	};

	return (
		<>
			<ErrorBox msg={errorMsg} setErrorState={setRoHSDocErrorState} ErrorState={RoHSDocErrorState} />
			<FullScreenLoadingIndicator loading={isLoading} />
			<Typography mt={2} fontSize={14}>
				Reference Documents:
			</Typography>
			<Grid container direction="row" justifyContent="flex-start" alignItems="center" columnSpacing={0.8} wrap={"wrap"}>
				<Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
					<Button
						fullWidth
						variant="contained"
						onClick={() =>
							window.open("https://keysighttech.sharepoint.com/sites/Labs-DS-PLC/Documents/KP/Everest%20Oracle/008694.pptx", "_blank")
						}
					>
						TERMINATION FINISH GUIDELINES
					</Button>
				</Grid>
				<Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
					<Button fullWidth variant="contained" onClick={() => downloadDocument("JP002.pdf")}>
						JEDEC 201
					</Button>
				</Grid>
				<Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
					<Button
						fullWidth
						variant="contained"
						onClick={() => window.open("https://keysighttech.sharepoint.com/sites/Labs-DS-PLC/Documents/KP/Everest%20Oracle/020188.xlsx", "_blank")}
					>
						RoHS Questionnaire
					</Button>
				</Grid>
				<Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
					<Button
						fullWidth
						variant="contained"
						onClick={() => window.open("http://www.srs.is.keysight.com/org/matleng/counterfeit_avoidance.shtml", "_blank")}
					>
						COUNTERFEIT AVOIDANCE PROCESS
					</Button>
				</Grid>
				{/* <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
					<Button fullWidth variant="contained" onClick={() => downloadDocument("EUExemtions.xls")}>
						(Ref) EU RoHS Exemtions
					</Button>
				</Grid> */}
			</Grid>
		</>
	);
};

export default RoHSReferenceDocs;
