import { useState, useCallback } from "react";
import { API_TOKEN_ERROR_MSG, API_GENERAL_ERROR_MSG } from "../Components/Util/Util";

const useHttp = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [response, setResponse] = useState(null);

	const sendRequest = useCallback(async (requestConfig, applyData, error) => {
		const retries = typeof requestConfig.retries === "number" ? requestConfig.retries : 2;
		setIsLoading(true);
		setError(null);
		try {
			const response = await fetch(requestConfig.url, {
				method: requestConfig.method ? requestConfig.method : "GET",
				credentials: requestConfig.credentials ? requestConfig.credentials : "omit",
				headers: requestConfig.headers ? requestConfig.headers : {},
				body:
					requestConfig.body && requestConfig.dataType === "formData"
						? requestConfig.body
						: // else
						requestConfig.body
						? JSON.stringify(requestConfig.body)
						: null,
			});

			if (!response.ok) {
				if (response.status === 401) {
					const errorInfo = await response.json();
					if (errorInfo.code && errorInfo.code === "token_not_valid") {
						throw new Error(API_TOKEN_ERROR_MSG);
					}
				} else if (response.status === 400) {
					const error = await response.json();
					throw new Error(error.Error ? error.Error : API_GENERAL_ERROR_MSG);
				}else if (response.status === 404) {
					const error = await response.json()
					throw new Error(error.No_Record ? error.No_Record : API_GENERAL_ERROR_MSG);
				}

				// Default retry up to twice if API call failed
				if (retries > 0) {
					console.log(`api call ${retries} retries left`);
					const newRequestConfig = { ...requestConfig, retries: retries - 1 };
					return sendRequest(newRequestConfig, applyData, error);
				} else {
					throw new Error(API_GENERAL_ERROR_MSG);
				}
			}

			const data = await response.json();
			applyData(data);
			setResponse(response);
		} catch (err) {
			error && error(err.message || "Something went wrong!");
			setError(err.message || "Something went wrong!");
		}
		setIsLoading(false);
	}, []);
	return {
		response,
		isLoading,
		error,
		sendRequest,
	};
};

export default useHttp;
