// React
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadBomAction } from "../../../../store/UploadBom/UploadBom-slice";
import { useNavigate } from "react-router-dom";

// UI
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid, Button } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import ConfirmationBox from "../../../Util/ConfirmationBox";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../../Util/ErrorBox";
import MessageBox from "../../../Util/MessageBox";
import UserOrgDropdown from "../../../UI/UserOrgDropdown";

// Project Packages
import useHttp from "../../../../Hooks/use-http";
import { APIURL, storePartHistories } from "../../../Util/Util";

const BOMloadingChoices = { analysis: "Load BOM for analysis", subset: "Load BOM to subset" };

const UploadBomFile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const uploadFileHeaders = { Authorization: "Bearer " + accessToken };

	const [openMessageBox, setOpenMessageBox] = useState(false);
	const [openMessageBox2, setOpenMessageBox2] = useState(false);
	const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
	const [uploadedFile, setUploadedFile] = useState("");
	const [message, setMessage] = useState("");
	const [bomLoadChoice, setBomLoadChoice] = useState(BOMloadingChoices.analysis);
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const transactionID = useSelector((state) => state.uploadBomState.transactionID);
	const selectedOrg = useSelector((state) => state.uploadBomState.selectedOrg);

	const fileInput = useRef(null);

	const handleRadioButtonChange = (event) => {
		setBomLoadChoice(event.target.value);
	};

	const handleFileAttached = (event) => {
		// exit function on cancel file upload
		if (!event.target.files) {
			return;
		}

		setUploadedFile(event.target.files[0]);

		// Return a cleanup function to reset input file
		return () => (event.target.value = "");
	};

	const handleFileUpload = () => {
		// File need to be sent in FormData
		let data = new FormData();
		data.append("file", uploadedFile);
		data.append("userid", userNTID);

		if (bomLoadChoice === BOMloadingChoices.analysis) {
			data.append("userorg", selectedOrg);
			fetchData(
				// Do not pass "Content-type" into header, the file upload will not work, default browser generated boundary is required
				{ url: `${APIURL}bom_upload/uploadFile`, headers: uploadFileHeaders, method: "POST", body: data, dataType: "formData" },
				(data) => {
					console.log(data);
					setOpenConfirmationBox(true);
					setMessage(data.Result);
					dispatch(uploadBomAction.setTransacitonID(data.transactionId));
				},
				(error) => {
					console.log("ERROR: Unexpected Error occured when Uploading BOM", error);
					setMessage(`ERROR: ${error}. Unexpected Error occured when Uploading BOM, please try again`);
					setOpenMessageBox2(true);
					fileInput.current.value = null;
				}
			);
		} else if (bomLoadChoice === BOMloadingChoices.subset) {
			fetchData(
				// Do not pass "Content-type" into header, the file upload will not work, default browser generated boundary is required
				{ url: `${APIURL}bom_upload/uploadBOMSubset`, headers: uploadFileHeaders, method: "POST", body: data, dataType: "formData" },
				(data) => {
					console.log(data);
					setOpenMessageBox(true);
					setMessage(`${data.Result} Subset Loaded Successfully.`);
					storePartHistories(data.Subset_Info.partnumber[0]);
					window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(data.Subset_Info.partnumber));
					window.sessionStorage.setItem("subsetLoaded", true);
					window.sessionStorage.setItem("subsetName", JSON.stringify("Subset"));
				},
				(error) => {
					console.log("ERROR: Unexpected Error occured when Uploading BOM", error);
					setErrorMsg(`ERROR: ${error}. Unexpected Error occured when Uploading BOM, please try again`);
					setErrorState(true);
					fileInput.current.value = null;
				}
			);
		}
	};

	// After BOM is uploaded and a message is prompted to user for confirming
	const handleYes = () => {
		const data = { userid: userNTID, transactionId: transactionID, userorg: selectedOrg };
		console.log(data);
		fetchData(
			{ url: `${APIURL}bom_upload/editCompDetails`, method: "POST", headers, body: data },
			(data) => {
				console.log(data);
				dispatch(uploadBomAction.setEditCompDetails(data));
				setOpenConfirmationBox(false);
				// Add some buffer time to avoid memory leak caused by react losing state
				setTimeout(() => {
					dispatch(uploadBomAction.setCurrentPage(2));
				}, 50);
			},
			(error) => {
				console.log("ERROR: Unexpected Error occured when updating BOM", error);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when updating BOM, please try again`);
				setErrorState(true);
			}
		);
	};

	const handleNo = () => {
		const data = { userid: userNTID, transactionId: transactionID, action: "Cancel", userorg: selectedOrg };
		console.log(data);
		fetchData(
			{ url: `${APIURL}bom_upload/cancelUpload`, method: "POST", headers, body: data },
			(data) => {
				console.log(data);
				setOpenConfirmationBox(false);
				setErrorMsg(`${data.Result}`);
				setErrorState(true);
			},
			(error) => {
				console.log("ERROR: Unexpected Error occured when Cancelling BOM upload", error);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when Cancelling BOM upload, please try again`);
				setErrorState(true);
			}
		);
	};

	const messageBoxHandler = () => {
		setOpenMessageBox(false);
		navigate("/Info");
	};

	const messageBoxHandler2 = () => {
		setOpenMessageBox2(false);
		fileInput.current.click();
	};

	const handleOrgSelected = (value) => {
		dispatch(uploadBomAction.handleSelectOrg(value));
	};

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<MessageBox open={openMessageBox} message={message} messageBoxHandler={messageBoxHandler} />
			<MessageBox open={openMessageBox2} message={message} messageBoxHandler={messageBoxHandler2} />
			<ConfirmationBox message={message} open={openConfirmationBox} handleYes={handleYes} handleNo={handleNo} />

			<Grid mt={2} container direction="column" justifyContent="center" alignItems="center" rowSpacing={1.5} columnSpacing={0.8} wrap={"wrap"}>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<ColoredTextField
						inputRef={fileInput}
						type="file"
						id="uploadFileInput"
						hidden
						onChange={handleFileAttached}
						inputProps={{ accept: ".csv,.xls,.xlsx", id: "id_docfile", name: "docfile" }}
					/>
					{/* <input type="file" id="uploadFileInput" accept=".csv, .xls, .xlsx" /> */}
				</Grid>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<FormControl>
						<FormLabel id="radio-buttons-group-label">Choose BOM loading destination</FormLabel>
						<RadioGroup
							aria-labelledby="radio-buttons-group-label"
							onChange={handleRadioButtonChange}
							value={bomLoadChoice}
							name="radio-buttons-group"
						>
							<FormControlLabel value={BOMloadingChoices.analysis} control={<Radio />} label={BOMloadingChoices.analysis} />
							<FormControlLabel value={BOMloadingChoices.subset} control={<Radio />} label={BOMloadingChoices.subset} />
						</RadioGroup>
					</FormControl>
				</Grid>
				{bomLoadChoice === BOMloadingChoices.analysis && (
					<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
						<UserOrgDropdown label={"Choose Org to be loaded"} width={331} selectedOrg={selectedOrg} handleOrgSelected={handleOrgSelected} />
					</Grid>
				)}
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<Button disabled={uploadedFile ? false : true} onClick={handleFileUpload}>
						Ok
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default UploadBomFile;
