import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const userOrg = "F01";
const dayNum = 28;
const endDate = moment().format("YYYY-MM-DD")
const startDate = moment(endDate).subtract(dayNum, "day").format("YYYY-MM-DD")

// These 2 functions are to calculate the number of days for 6 months from the end date. Both have a minor difference in output. Test out first to see which 
// is better to be used.
// const maxDay = dateDiff(new Date(new Date().setMonth(new Date().getMonth() - 6)));
// const maxDay = moment(endDate).diff(moment().subtract(6 , 'months'), "days")
const maxDay = 184 // Currently using a fixed 184 days as discussed in (27/01/2023 - SJ , DS)

const defaultStatusMlarReport = {
	dayNum: dayNum,
	startDate: startDate,
	endDate: endDate,
	userOrg: userOrg,
	maxDay: maxDay,
	error: {
		startDate: false,
	},
};

const defaultNotesActionStatusMlarReport = {
	dayNum: dayNum,
	startDate: startDate,
	endDate: endDate,
	userOrg: userOrg,
	maxDay: maxDay,
	export: "pdf",
	checkName: true, //----- value of this field will be 0 or 1 based on user selection
	mtlNameTyped: "",
	mtlName: {
		id: "",
		label: "",
	}, //------- value of this field will be user "ntid" if the above field is 1 and "" if the above field is 0
	originalUserList: {
		id: "",
		label: "",
	},
	mtlNameList: {
		id: "",
		label: "",
	},
	error: {
		mtlName: true,
		startDate:false,
	},
	checkNoteAction: false, //----- value of this field will be 0 or 1 based on user selection
	checkDetailReport: false, //----- value of this field will be 0 or 1 based on user selection
	createSubset: false, //------ value of this field will be 0 or 1 based on user selection
};

const defaultResponsibilityMlarReport = {
	userOrg: userOrg,
	daysBack: 0,
	selectedBom: null,
	error: {
		mlarBom: true,
	},
	errorText: {
		mlarBom: "",
	},
};

const defaultPreliminaryMlarReport = {
	userOrg: userOrg,
	daysBack: 0,
	selectedBom: null,
	mlarBomOrSubset: "mlarbom",
	subsetID: "",
	subsetNameList: [],
	bom: "BomNo",
	prefCode: 1,
	sortBy: 1,
	sortByList: ["MTL_ENG", "Part Number", "Pref Code"],
	lookForward: true,
	checkNoteAction: true,
	error: {
		prefCode: false,
		mlarBom: true,
		subset: false,
	},
	errorText: {
		prefCode: "",
		mlarBom: "",
		subset: "",
	},
};

const defaultMyRoHSActionsRequired = {
	mtlNameTyped: "",
	mtlName: {
		id: "",
		label: "",
	}, //------- value of this field will be user "ntid" if the above field is 1 and "" if the above field is 0
	originalUserList: [{
		id: "",
		label: "",
	}],
	mtlNameList: [
		{
			id: "",
			label: "",
		},
	],
	error: {
		mtlName: false,
	},
	mtlEngChecked: false,
	checkName: "0", //----- value of this field will be 0 or 1 based on user selection
	userOrg: userOrg,
	startDate: startDate,
	endDate: endDate,
	maxDay: maxDay,
	userid: "",
	optionGroupInput: "1",
	checkInclUnassignedComm: "1", // ----- 1 = my part, 0 = all parts or choose mtl eng
	sortBy: "2", //----- 1 = MTL_Name, 2 = Item, 3 = BOM
	createSubsetOrPdf: "0", //----- 1 = create subset, 0 = print pdf
}

const defaultMCCMERoHSReport = {
	daysBack: 0,
	subsetNameList: [],
	selectedBom: null,
	bom: "BomNo",
	userOrg: userOrg,
	mlarBomOrSubset: "mlarbom",
	subsetID: "",

	excelPdfReport: "pdf",

	hideMfgMpnInfo: false,
	printNotes: true, //----- will not be passed into API
	checkItemNote: true,
	checkMpnNote: true,

	checkHideInactiveMPNS: true,
	checkHideMpnWorstCase: false,
	checkHideItemsRoHS: true,

	firstPrintMCCWorstCase: false,
	thanMtlUnreported: false,

	firstPrintMtlUnreported: true,
	thanMCCWorstCase: true,

	sortBy: "2", //----- 1 = MTL_Name, 2 = Item
}

const defaultMERoHSReport = {
	daysBack: 0,
	subsetNameList: [],
	selectedBom: null,
	bom: "BomNo",
	userOrg: userOrg,
	mlarBomOrSubset: "mlarbom",
	subsetID: "",

	excelPdfReport: "pdf",

	hideMfgMpnInfo: false,
	printNotes: true, //----- will not be passed into API
	checkItemNote: true,
	checkMpnNote: true,

	checkHideInactiveMPNS: true,
	checkShowUnreportedFirst: true,
	checkHideMpnWorstCase: false,
	checkHideItemsRoHS: true,

	sortBy: "2", //----- 1 = MTL_Name, 2 = Item
}

const defaultOpTempReport = {
	daysBack: 0,
	subsetNameList: [],
	selectedBom: null,
	bom: "BomNo",
	userOrg: userOrg,
	mlarBomOrSubset: "mlarbom",
	subsetID: "",
	printSummaryOrDetail: "1",
	checkHideInactiveMPNS: "1",
	printNotes: false, // shortcut for checking both print option, will not be passed into API
	checkItemNote: "0",
	checkMfgNote: "0",
	excelPdfReport: "pdf",
}

const defaultFinalMlarReport = {
	userOrg: userOrg,
	daysBack: 0,
	selectedBom: null,
	mlarBomOrSubset: "mlarbom",
	subsetID: "",
	subsetNameList: [],
	bom: "BomNo",
	sortBy: 1,
	sortByList: ["MTL_ENG", "Part Number", "Pref Code"],
	checkHideNAMPNs: true,
	excelPdfReport: false,
	uniqueValues: false,
	error: {
		mlarBom: true,
		subset: false,
	},
	errorText: {
		mlarBom: "",
		subset: "",
	},
};

const defaultPc4MlarReport = {
	userOrg: userOrg,
	daysBack: 0,
	selectedBom: null,
	mlarBomOrSubset: "mlarbom",
	subsetID: "",
	subsetNameList: [],
	bom: "BomNo",
	sortBy: 1,
	sortByList: ["Pref_code", "Item"],
	checkNewPCReq: true,
	excelPdfReport: false,
	uniqueValues: false,
	error: {
		mlarBom: true,
		subset: false,

	},
	errorText: {
		mlarBom: "",
		subset: "",
	},
};

const defaultPcRequestMlarReport = {
	userOrg: userOrg,
	checkName: true, //----- value of this field will be 0 or 1 based on user selection
	export: "excel",
	mtlNameTyped: "",
	mtlName: {
		id: "",
		label: "",
	}, //------- value of this field will be user "ntid" if the above field is 1 and "" if the above field is 0
	originalUserList: [{
		id: "",
		label: "",
	}],
	mtlNameList: [{
		id: "",
		label: "",
	}],
	error: {
		mtlName: true,
	},

};

const defaultFinalLiaisonReport = {
	userOrg: userOrg,
	daysBack: 0,
	selectedBom: null,
	liaison: "",
	pcdfm: "",
	designer: "",
	a_group: "",
	project: "",
	pr_mgr: "",
	npieng: "",
	acceptedDomains: ["keysight.com", "non.keysight.com"],
	emailTo: [],
	emailCc: [],
	emailToCheck: true,
	emailCcCheck: true,
	attachFinal: true,
	attachDetailsRohs: true,
	attachSummaryRohs: true,
	attachMaxOpTemp: true,
	includePc4: true,
	error: {
		emailTo: false,
		emailCC: false,
		mlarBom: true,
	},
	errorText: {
		emailTo: "One or more email address is required",
		emailCc: "",
		mlarBom: "",
	},
};

const defaultMtlResponsibilityOtherReport = {
	userOrg: userOrg,
	sortBy: 1,
	sortByList: ["Commodity Code", "MTL Eng name"],
	excelPdfReport: false,
};
const defaultPopUpState = {
	mlar: {
		status: false,
		notesActionStatus: false,
		responsibility: false,
		preliminary: false,
		final: false,
		pc4: false,
		pcRequest: false,
	},
	liaison: {
		final: false,
	},
	rohs: {
		myRoHSActionsRequired: false,
		mccme: false,
		me: false,
	},
	temperature: {
		opTemp: false,
	},
	other: {
		mtlResponsibility: false,
	},
};
	
	


const initialReportState = {
	reports: {
		mlar: {
			status: defaultStatusMlarReport,
			notesActionStatus: defaultNotesActionStatusMlarReport,
			responsibility: defaultResponsibilityMlarReport,
			preliminary: defaultPreliminaryMlarReport,
			final: defaultFinalMlarReport,
			pc4: defaultPc4MlarReport,
			pcRequest: defaultPcRequestMlarReport,

		},
		liaison: {
			final: defaultFinalLiaisonReport,
		},
		other: {
			mtlResponsibility: defaultMtlResponsibilityOtherReport,
		},
		rohs: {
			myRoHSActionsRequired: defaultMyRoHSActionsRequired,
			mccme: defaultMCCMERoHSReport,
			me: defaultMERoHSReport,
		},
		temperature: {
			opTemp: defaultOpTempReport,
		},
		
	},
	popUpStates: defaultPopUpState,
	currentReportType: "",
	currentCategory: "",
	orgList: [],
	bomList: [],
};

const reportsSlice = createSlice({
	name: "Reports",
	initialState: initialReportState,
	reducers: {
		updatePopUpState(state, action) {
			let reportType = action.payload?.reportType ?? state.currentReportType;
			let category = action.payload?.category ?? state.currentCategory;
			state.currentReportType = reportType;
			state.currentCategory = category;
			state.popUpStates[category] = {
				...defaultPopUpState[category],
				[reportType]: !state.popUpStates[category][reportType],
			};
			state.reports[category][reportType] = {
				...state.reports[category][reportType],
				...(action.payload?.initVal ?? undefined),
			};
		},
		updateData(state, action) {
			for (let key in action.payload.data) {
				state.reports[state.currentCategory][state.currentReportType][key] = action.payload.data[key];
			}
		},
		setError(state, action) {
			for (let key in action.payload.data) {
				state.reports[state.currentCategory][state.currentReportType].error[key] = action.payload.data[key];
			}
		},
		updateOrg(state, action) {
			if (Array.isArray(action.payload.orgList)) {
				state.orgList = action.payload.orgList;
			}
		},
		updateMtlNameList(state, action) {
			state.reports[state.currentCategory][state.currentReportType].originalUserList = action.payload.data;
			const orgUserList = action.payload.data.filter((data) => data.user_org_c === state.reports[state.currentCategory][state.currentReportType].userOrg);
			const mtlNameList = orgUserList.map((data) => {
				const retItem = {
					id: data.userid,
					label: data.username,
				};
				return retItem;
			});
			state.reports[state.currentCategory][state.currentReportType].mtlNameList = mtlNameList;
		},
		setBomList(state, action) {
			const data = action.payload.BOM_LIST;
			state.bomList = data.map((data) => {
				return { date: data.dadd, ppn: data.ppn, prev: data.prev, org: data.user_org_c };
			});
		},
		reset(state, action) {
			const ignore = action.payload?.ignore;
			let ignoreReset = {};
			if (Array.isArray(ignore) && ignore.length > 0) {
				ignore.forEach((ignoreKey) => {
					const exist = Object.keys(state.reports[state.currentCategory][state.currentReportType]).findIndex(
						(reportKey) => {
							return reportKey === ignoreKey;
						}
					);
					if (exist >= 0) {
						ignoreReset[ignoreKey] = state.reports[state.currentCategory][state.currentReportType][ignoreKey];
					}
				});
			}
			state.reports[state.currentCategory][state.currentReportType] = {
				...initialReportState.reports[state.currentCategory][state.currentReportType],
				...ignoreReset,
			};
			state.currentReportType = initialReportState.currentReportType;
			state.currentCategory = initialReportState.currentCategory;
		},
	},
});

export const reportsActions = reportsSlice.actions;

export default reportsSlice;
