import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";
import { useState } from "react";

import { Box, Button, Modal, Stack, Typography, Tooltip } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import UserOrgDropdown from "../../../UI/UserOrgDropdown";

import { OSAltKey } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	pb: 2,
};

const EditRoHSNote = (props) => {
	const dispatch = useDispatch();

	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const open = useSelector((state) => state.rohsStates.showRoHSNotePopUp);
	const itemPartNumber = useSelector((state) => state.rohsStates.partInfo.partNumber);
	const editedRoHSNote = useSelector((state) => state.rohsStates.editedRoHSNote);
	const editingRoHSNoteOrg = useSelector((state) => state.rohsStates.editingRoHSNoteOrg);

	const [noteEdited, setNoteEdited] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("false");
	const [showErrorMessage, setShowErrorMessage] = useState(false);

	const saveNoteHandler = () => {
		const RoHSNoteJSON = {
			item: itemPartNumber,
			userid: userNTID,
			userorg: editingRoHSNoteOrg,
			note: editedRoHSNote,
		};
		if (noteEdited && editedRoHSNote?.trim() !== "") {
			props.updateRoHSNote(RoHSNoteJSON);
			setNoteEdited(false);
		} else if (editedRoHSNote?.trim() === "") {
			setBlankError();
		} else {
			setErrorMessage("No changes detected, cancelling save");
			setShowErrorMessage(true);
			setTimeout(() => {
				dispatch(RoHSAction.setEditingRoHSNote(false));
				setShowErrorMessage(false);
				setErrorMessage("");
			}, 1000);
		}
	};

	const handleEditNote = (event) => {
		if (!noteEdited) setNoteEdited(true);
		dispatch(RoHSAction.handleEditRoHSNote(event.target.value));
		if (event.target.value?.trim() === "") {
			setBlankError();
		} else if (error) setError(false);
	};

	const setBlankError = () => {
		setErrorMessage("Note cannot be blank");
		setError(true);
	};

	const cancelEditNoteHandler = () => {
		dispatch(RoHSAction.cancelUpdateRoHSNote());
		dispatch(RoHSAction.setEditingRoHSNote(false));
		setNoteEdited(false);
		setError(false);
	};

	const handleOrgSelected = (value) => {
		dispatch(RoHSAction.setEditingRoHSNoteOrg(value));
	};

	const handleOnKeyDown = (e) => {
		if (e.code === "KeyS" && e.altKey === true && !error) {
			e.preventDefault();
			saveNoteHandler();
		} else if (e.code === "KeyC" && e.altKey === true) {
			e.preventDefault();
			cancelEditNoteHandler();
		}
	};

	return (
		<Box>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						handleOnKeyDown(e);
					}}
				>
					{showErrorMessage ? (
						<Typography fontSize={20}>{errorMessage}</Typography>
					) : (
						<>
							<Stack mb={2} spacing={2}>
								<Typography fontSize={14} mr={2}>
									Note Associated with Keysight Part Number
								</Typography>
								<UserOrgDropdown label={"Select Org"} selectedOrg={editingRoHSNoteOrg} handleOrgSelected={handleOrgSelected} />
								<ColoredTextField
									fullWidth
									autoFocus
									minRows={3}
									maxRows={3}
									multiline
									focused
									onChange={handleEditNote}
									value={editedRoHSNote}
									error={error}
									helperText={error && errorMessage}
								/>
							</Stack>

							<Box>
								<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
									<span>
										<Button disabled={error} onClick={saveNoteHandler}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ave
										</Button>
									</span>
								</Tooltip>
								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<Button onClick={cancelEditNoteHandler}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</Tooltip>
							</Box>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</>
					)}
				</Box>
			</Modal>
		</Box>
	);
};

export default EditRoHSNote;
