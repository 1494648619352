import { UNSAFE_NavigationContext as NavigationContext, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";

function beforeUnloadHandler(e) {
    e.preventDefault();
    e.returnValue = "";
    return "Are you sure you want to leave?\n\nChanges that you made may not be saved";
}

function usePreventPageLoad(pageIsDirty) {
    const { navigator } = useContext(NavigationContext);
    const unblockRef = useRef(function () {
        [].forEach((e) => e);
    });
    const navigate = useNavigate();
    useEffect(() => {
        if (!pageIsDirty) {
            return;
        }
        // To prevent window / tab from closing
        window.addEventListener("beforeunload", beforeUnloadHandler);
        // To prevent react-router-dom from navigating to another page
        const { block } = navigator;
        unblockRef.current = block((transition) => {
            const { location: { pathname: targetPathname }, action, } = transition;
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            if (window.confirm("Change page?\n\nChanges that you made may not be saved.")) {
                unblockRef.current();
                // We use this to make it work with navigations without react-router (ie. Browser's go back button)
                if (action.toLowerCase() === "pop") {
                    navigate(targetPathname);
                }
                else {
                    // We use this to make it work with navigations via react-router (ie. navLink)
                    transition.retry();
                }
            }
        });
        return () => window.removeEventListener("beforeunload", beforeUnloadHandler);
    }, [navigator, navigate, pageIsDirty]);
    return unblockRef;
}
export default usePreventPageLoad;
