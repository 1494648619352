import { createSlice } from "@reduxjs/toolkit";
import { checkUndefined  } from "../../Components/Util/Util";
import { storePartHistories } from "../../Components/Util/Util";

const defaultRRWOLinesTable = [{
    id : 0,
    partNum : "",
    orderId : "",
    orderType : "",
    line : "",
	cmSite : "",
    orderSite : "",
    status : "",
	quantity : "",
	startDate : "",
    dueDate : "",
}]

const defaultRRPOLinesTable = [{
    id : 0,
    partNum : "",
    orderId : "",
    orderType : "",
    line : "",
	cmSite : "",
    orderSite : "",
    status : "",
	quantity : "",
    dueDate : "",
}]

const defaultRRSOLinesTable = [{
    id : 0,
    partNum : "",
    orderId : "",
    orderType : "",
    line : "",
	cmSite : "",
    orderSite : "",
    status : "",
	quantity : "",
    dueDate : "",
}]

const defaultPartsInfo = {
	partNum:"",
	desc:"",
}
const defaultColumnVisibilityRRWOLines = {
	partNum : true,
	orderId : true,
	orderType : true,
	line : true,
	cmSite : true,
	orderSite : true,
	status : true,
	quantity : true,
	startDate : true,
	dueDate : true,
}

const defaultColumnVisibilityRRPOLines = {
    partNum : true,
	orderId : true,
	orderType : true,
	line : true,
	cmSite : true,
	orderSite : true,
	status : true,
	quantity : true,
	dueDate : true,
}

const defaultColumnVisibilityRRSOLines = {
    partNum : true,
	orderId : true,
	orderType : true,
	line : true,
	cmSite : true,
	orderSite : true,
	status : true,
	quantity : true,
	dueDate : true,
}



// window.localStorage.removeItem("mtlEngColumnVisibility") // Incase of problems due to Item
const columnVisibilityStorageRRWOLines = JSON.parse(window.localStorage.getItem("RR_RRWOLinesColumnVisibility"))
const columnVisibilityStorageRRPOLines = JSON.parse(window.localStorage.getItem("RR_RRPOLinesColumnVisibility"))
const columnVisibilityStorageRRSOLines = JSON.parse(window.localStorage.getItem("RR_RRSOLinesColumnVisibility"))

const initialRRState = {
	PART_INFO: defaultPartsInfo,
	RR_WO_LINES_TABLE: [],
	RR_PO_LINES_TABLE: [],
	RR_SO_LINES_TABLE: [],
	tableColumnVisibilityRRWOLines: columnVisibilityStorageRRWOLines ?? defaultColumnVisibilityRRWOLines,
	tableColumnVisibilityRRPOLines: columnVisibilityStorageRRPOLines ?? defaultColumnVisibilityRRPOLines,
	tableColumnVisibilityRRSOLines: columnVisibilityStorageRRSOLines ?? defaultColumnVisibilityRRSOLines,
	reloadPartNumber: 0, // A useEffect dependency to trigger change part after user loaded subset from info tab itself
};

const rrSlice = createSlice({
	name: "RR",
	initialState: initialRRState,
	reducers: {
		updateAll(state, action) {
			const invalidDate = "Invalid Date"
			const RRWOLines = () => {
                if(action.payload.data.RR_WO_LINES.length > 0){
                    
                    return action.payload.data.RR_WO_LINES.map( (singleData , i) =>{ 

                        Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
						const startDate = singleData.start_date && new Date (singleData.start_date) !== invalidDate ? new Date(new Date (singleData.start_date).toISOString().replace("Z", "")).toLocaleDateString("en-US") : ""
						const dueDate = singleData.due_date && new Date (singleData.due_date) !== invalidDate ? new Date(new Date (singleData.due_date).toISOString().replace("Z", "")).toLocaleDateString("en-US") : ""

                        const retItem = {
                            id:i,
							partNum : singleData.part_number,
							orderId : singleData.order_id,
							orderType : singleData.order_type,
							line : singleData.line,
							cmSite : singleData.cm_site,
							orderSite : singleData.order_site,
							status : singleData.status,
							quantity : singleData.quantity,
							startDate : startDate,
							dueDate : dueDate,
                        }
                        return retItem
                    })
                }

                return defaultRRWOLinesTable
            }
			const RRPOLines = () => {
                if(action.payload.data.RR_PO_LINES.length > 0){
                    
                    return action.payload.data.RR_PO_LINES.map( (singleData , i) =>{ 

						Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
						const dueDate = singleData.due_date && new Date (singleData.due_date) !== invalidDate ? new Date(new Date (singleData.due_date).toISOString().replace("Z", "")).toLocaleDateString("en-US") : ""

                        const retItem = {
                            id:i,
							partNum : singleData.part_number,
							orderId : singleData.order_id,
							orderType : singleData.order_type,
							line : singleData.line,
							cmSite : singleData.cm_site,
							orderSite : singleData.order_site,
							status : singleData.status,
							quantity : singleData.quantity,
							dueDate : dueDate,
                        }
                        return retItem
                    })
                }

                return defaultRRPOLinesTable
            }

			const RRSOLines = () => {
                if(action.payload.data.RR_SO_LINES.length > 0){
                    
                    return action.payload.data.RR_SO_LINES.map( (singleData , i) =>{ 

						Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
						const dueDate = singleData.due_date && new Date (singleData.due_date) !== invalidDate ? new Date(new Date (singleData.due_date).toISOString().replace("Z", "")).toLocaleDateString("en-US") : ""

                        const retItem = {
                            id:i,
							partNum : singleData.part_number,
							orderId : singleData.order_id,
							orderType : singleData.order_type,
							line : singleData.line,
							cmSite : singleData.cm_site,
							orderSite : singleData.order_site,
							status : singleData.status,
							quantity : singleData.quantity,
							dueDate : dueDate,
                        }
                        return retItem
                    })
                }

                return defaultRRSOLinesTable
            }

			const partInfo = () => {
				const part = action.payload.data.PART_INFO
				if(Object.keys(part).length > 0){
					storePartHistories(part.itemname)

					Object.keys(part).forEach(key => {
						part[key] = checkUndefined(part[key])
					})
					const retItem = {
						partNum: part.itemname,
						desc: part.item_desc,
					}
					return retItem
                }
				return defaultPartsInfo
			}
			const RR_WO_LINES_TABLE = RRWOLines()
			const RR_PO_LINES_TABLE = RRPOLines()
			const RR_SO_LINES_TABLE = RRSOLines()
			const PART_INFO = partInfo()
			// console.log({RR_WO_LINES_TABLE , RR_PO_LINES_TABLE , RR_SO_LINES_TABLE})
			// console.table({PART_INFO})
			state.RR_WO_LINES_TABLE = RR_WO_LINES_TABLE
			state.RR_PO_LINES_TABLE = RR_PO_LINES_TABLE
			state.RR_SO_LINES_TABLE = RR_SO_LINES_TABLE
			state.PART_INFO = PART_INFO
        },
		setColumnVisibility(state, action){
			switch(action.payload.table){
				case "RRWOLines":
					state.tableColumnVisibilityRRWOLines = action.payload.columnVisibility
					window.localStorage.setItem("RR_RRWOLinesColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "RRPOLines":
					state.tableColumnVisibilityRRPOLines = action.payload.columnVisibility
					window.localStorage.setItem("RR_RRPOLinesColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "RRSOLines":
					state.tableColumnVisibilityRRSOLines = action.payload.columnVisibility
					window.localStorage.setItem("RR_RRSOLinesColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				default:
					break
				
			}
        },
		
		reset: (state) => {
			return {
				...initialRRState,
				tableColumnVisibilityRRWOLines: state.columnVisibilityStorageCMForecast,
				tableColumnVisibilityRRPOLines: state.columnVisibilityStorageCMQuantity,
				tableColumnVisibilityRRSOLines: state.columnVisibilityStorageMacPriceInfo,
			};
		},

		setPartNumberForSubset(state, action) {
			state.PART_INFO.partNum = action.payload;
		},

		setReloadPartNumber(state) {
			state.reloadPartNumber = state.reloadPartNumber + 1;
		},

	},
});

export const rrActions = rrSlice.actions;

export default rrSlice;
