export const checkUndefined = (val) => (val ? val : "");
export const MLAR_API =
	process.env.REACT_APP_STAGE === "production"
		? "https://mlarservices.it.keysight.com/"
		: process.env.REACT_APP_STAGE === "test"
		? "https://mlarservicestest.it.keysight.com/"
		: "https://mlarservicesdev.it.keysight.com/";
export const APIURL = `${MLAR_API}api/`;
export const TOKEN_REFRESH_API = `${MLAR_API}api/token/refresh/`;
export const TOKEN_VERIFY_API = `${MLAR_API}api/token/verify/`;
export const SSO_RELOGIN_URL = `${MLAR_API}saml2_auth/welcome/`;
export const SSO_LOGOUT_URL = `${MLAR_API}accounts/logout/`;

export const API_TOKEN_ERROR_MSG = "Something went wrong, your authorization is not valid. Please try refreshing the page.";
export const API_GENERAL_ERROR_MSG = "Request failed!";

export const MCIMSURL =
	process.env.REACT_APP_STAGE === "test" || process.env.REACT_APP_STAGE === "production"
		? "https://mci-microservices.it.keysight.com/"
		: "https://mci-microservices-dev.it.keysight.com/";
export const PDF_GEN_URL = `${MCIMSURL}pdfgenerator/api/v1/generation/generatePDF`;
export const PDF_GEN_JWT_TOKEN_URL = `${MCIMSURL}pdfgenerator/api/v1/tokens/jwt`;

// S3
export const s3BucketName = process.env.REACT_APP_STAGE === "production" ? "mlar-prod" : "mlar-dev";
export const s3ppDocsName = "ppdocs";

export const orgList = [
	"GDO",
	"IM1",
	"F01",
	"F03",
	"F06",
	"F07",
	"F08",
	"F09",
	"F11",
	"F14",
	"F35",
	"F36",
	"F48",
	"F49",
	"F50",
	"F51",
	"F52",
	"F55",
	"F56",
	"F65",
	"X08",
];

export const storePartHistories = (partNumber) => {
	window.sessionStorage.setItem("PartNumber", partNumber);
	const partHistories = window.sessionStorage.getItem("PartHistories");
	const shortcutNavigation = window.sessionStorage.getItem("ShortcutNavigation");
	if (partHistories) {
		if (!JSON.parse(partHistories).includes(partNumber)) {
			const historiesArray = JSON.parse(partHistories);
			historiesArray.push(partNumber);
			window.sessionStorage.setItem("PartHistories", JSON.stringify(historiesArray));
		} else {
			if (shortcutNavigation === "false") {
				const historiesArray = JSON.parse(partHistories);
				historiesArray.push(historiesArray.splice(historiesArray.indexOf(partNumber), 1)[0]);
				window.sessionStorage.setItem("PartHistories", JSON.stringify(historiesArray));
			}
		}
	} else {
		window.sessionStorage.setItem("PartHistories", JSON.stringify([partNumber]));
	}
	window.sessionStorage.setItem("ShortcutNavigation", false);
};

export const getCookie = (name) => {
	let match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
	if (match) {
		return match[2];
	}
	return null;
};

export const clearLoginLocalStorage = () => {
	window.localStorage.setItem("loggedOut", true);
	window.localStorage.removeItem("userRole");
	window.localStorage.removeItem("userNTID");
	window.localStorage.removeItem("userName");
	window.localStorage.removeItem("userDivision");
	window.localStorage.removeItem("userOrg");
	window.sessionStorage.clear();
};

export const saveOrgIntoSessionStorage = (org) => {
	// Prevent saving incorrect value into session storage
	if (org) {
		window.sessionStorage.setItem("org", org);
	}
};

export const dateDiff = (prevDate, currentDate = new Date()) => {
	if (!(prevDate instanceof Date) && isNaN(prevDate)) {
		throw Error(`${prevDate} is not passed as a date format. Please pass prevDate as a date format`);
	}
	if (!(currentDate instanceof Date) && isNaN(currentDate)) {
		throw Error(`${currentDate} is not passed as a date format. Please pass currentDate as a date format`);
	}
	let difference = currentDate.getTime() - prevDate.getTime();
	return difference / (1000 * 60 * 60 * 24);
};

export const isEmptyObject = (object) => {
	let isEmpty = true;
	for (let key in object) {
		if (object[key]) {
			isEmpty = false;
		}
	}
	return isEmpty;
};

export const formatDate = (val, specialChar = "-", includeTime = false) => {
	if (val) {
		let day = "" + val.getDate();
		let month = "" + (val.getMonth() + 1);
		let year = val.getFullYear();

		if (month.length < 2) {
			month = "0" + month;
		}
		if (day.length < 2) {
			day = "0" + day;
		}
		if (includeTime === true) {
			let hour = "" + val.getHours();
			let minute = "" + val.getMinutes();
			let second = "" + val.getSeconds();
			if (hour.length < 2) {
				hour = "0" + hour;
			}
			if (minute.length < 2) {
				minute = "0" + minute;
			}
			if (second.length < 2) {
				second = "0" + second;
			}
			return [year, month, day, hour, minute, second].join(specialChar.toString());
		}
		return [year, month, day].join(specialChar.toString());
	} else {
		return "";
	}
};

// Convert binary data into downloadable file.
export const downloadBlob = (blob, name) => {
	// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
	const blobUrl = URL.createObjectURL(blob);
	// Create a link element
	const link = document.createElement("a");
	// Set link's href to point to the Blob URL
	link.href = blobUrl;
	link.download = name;
	// Append link to the body
	document.body.appendChild(link);
	// Dispatch click event on the link
	// This is necessary as link.click() does not work on the latest firefox
	link.dispatchEvent(
		new MouseEvent("click", {
			bubbles: true,
			cancelable: true,
			view: window,
		})
	);

	// Remove link from body
	document.body.removeChild(link);
};

export const createFillerData = (keys, amount, altVal = " ") => {
	let retData = [];
	for (let i = 0; i < amount; i++) {
		let tempObj = { id: i };
		for (let ind in keys) {
			tempObj[keys[ind]] = altVal;
		}
		retData.push(tempObj);
	}
	return retData;
};

export const extractKeys = (data, key) => {
	let keyArray = [];
	for (let i in data) {
		keyArray.push(data[i][key]);
	}
	return keyArray;
};
// Subset Order
export const itemOrderLabel = { loadOrder: `'LOAD' order`, item: "item (accending order)" };

// Custom Date Sorting for Datagrid
export const DateSortingComparator = (a, b) => {
	// const newA = a.split("/").reverse().join("-");
	// const newB = b.split("/").reverse().join("-");

	// +new Date will convert date into timestamp
	return +new Date(a) - +new Date(b);
};

export const OSAltKey = navigator.userAgent.indexOf("Mac") !== -1 ? "OPT" : "ALT";

// used in textfield to only accept numbers in the input
export const isNumber = (value) => /^[1-9]\d*$/.test(value);

// Basic conversion from boolean to 1 as true or 0 as false as string by default or a number if specify the format parameter to num
export const convertTrueFalse = (num, format = "str") => {
	let converted = num === true ? 1 : 0;
	if (format !== "num") {
		converted = converted.toString();
	}
	return converted;
};

export const convertArrayStringToSingleString = (arrayValue, seperator = " ") => {
	let returnedText = "";
	arrayValue.forEach((text, index) => {
		if (index === arrayValue.length - 1) {
			returnedText += text;
		} else {
			returnedText += text + seperator;
		}
	});
	return returnedText;
};

export const OpenCDS = (selectedHistory) => {
	const url = `http://cds.srs.is.keysight.com/perl/PPR.pl?hppn=${selectedHistory}`;
	// const width = 700;
	// const leftWidth = window.screen.width - width;
	window.open(url, "cds", `popup=no`);
};

export const Mtl_Eng_User = "MLAR_MTL-ENG";
export const Non_Mtl_Eng_User = "MLAR_NON-MTL-ENG";
export const Admin_User = "MLAR_ADMIN";

export const saveEmailToLocalStorage = (val) => {
	// Saving last 10 emails to local storage for autocomplete
	let savedEmailList = JSON.parse(window.localStorage.getItem("emailAutoComplete")) || [];
	if (!savedEmailList.includes(val)) {
		if (savedEmailList.length > 10) {
			savedEmailList = savedEmailList.slice(0, -1);
		}
		window.localStorage.setItem("emailAutoComplete", JSON.stringify([val, ...savedEmailList]));
	}
}