// React
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Mousetrap from "mousetrap";

// UI
import { Box, FormControl, InputLabel, Select, MenuItem, Tooltip, Typography, Stack } from "@mui/material";
import SkipPreviousSharpIcon from "@mui/icons-material/SkipPreviousSharp";
import SkipNextSharpIcon from "@mui/icons-material/SkipNextSharp";
import ArrowLeftSharpIcon from "@mui/icons-material/ArrowLeftSharp";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import BottomBarIconButton from "../UI/BottomBarIconButton";
import RedoIcon from "@mui/icons-material/Redo";
import ColoredTextField from "../UI/ColoredTextField";
import BOMFilterMenu from "./BOMFIlterMenu";
// packaged import
import { isNumber } from "../Util/Util";

const BottomBarNavigation = (props) => {
	const selectedHistory = props.selectedHistory;
	const Histories = window.sessionStorage.getItem("PartHistories") ? JSON.parse(window.sessionStorage.getItem("PartHistories")) : [""];
	const subsetPartNumbers = window.sessionStorage.getItem("subsetPartNumbers") ? JSON.parse(window.sessionStorage.getItem("subsetPartNumbers")) : [];
	const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded"));
	const subsetName = JSON.parse(window.sessionStorage.getItem("subsetName"));
	const useItemOrder = JSON.parse(window.sessionStorage.getItem("subsetUseItemOrder"));

	const [isFirstPartNumber, setIsFirstPartNumber] = useState(true);
	const [isLastPartNumber, setIsLastPartNumber] = useState(true);
	const PartNumbers = subsetLoaded ? (subsetPartNumbers.length > 0 && useItemOrder ? subsetPartNumbers.sort() : subsetPartNumbers) : Histories;
	const reloadNavigation = useSelector((state) => state.SubsetState.reloadNavigation);
	const subsetPosition = PartNumbers && `(${PartNumbers.findIndex((part) => part === selectedHistory) + 1} of ${PartNumbers.length})`;

	// Jump to specified number
	const [openJump, setOpenJump] = useState(false);
	const [jumpInput, setJumpInput] = useState("");
	const [jumpOutOfRange, setJumpOutOfRange] = useState(false);

	const handleHistoryChange = (event) => {
		window.sessionStorage.setItem("ShortcutNavigation", true);
		props.changePart(event.target.value);
	};

	const handleNextButtonClick = useCallback(() => {
		if (PartNumbers.indexOf(selectedHistory) < PartNumbers.length - 1) {
			if (!subsetLoaded) {
				window.sessionStorage.setItem("ShortcutNavigation", true);
			}
			props.changePart(PartNumbers[PartNumbers.indexOf(selectedHistory) + 1]);
		}
	}, [PartNumbers, props, selectedHistory, subsetLoaded]);

	const handleLastButtonClick = useCallback(() => {
		if (PartNumbers.indexOf(selectedHistory) < PartNumbers.length - 1) {
			if (!subsetLoaded) {
				window.sessionStorage.setItem("ShortcutNavigation", true);
			}
			props.changePart(PartNumbers[PartNumbers.length - 1]);
		}
	}, [PartNumbers, props, selectedHistory, subsetLoaded]);

	const handleBackButtonClick = useCallback(() => {
		if (PartNumbers.indexOf(selectedHistory) - 1 > -1) {
			if (!subsetLoaded) {
				window.sessionStorage.setItem("ShortcutNavigation", true);
			}
			props.changePart(PartNumbers[PartNumbers.indexOf(selectedHistory) - 1]);
		}
	}, [PartNumbers, props, selectedHistory, subsetLoaded]);

	const handleFirstButtonClick = useCallback(() => {
		if (PartNumbers.indexOf(selectedHistory) - 1 > -1) {
			if (!subsetLoaded) {
				window.sessionStorage.setItem("ShortcutNavigation", true);
			}
			props.changePart(PartNumbers[0]);
		}
	}, [PartNumbers, props, selectedHistory, subsetLoaded]);

	const handleJumpChange = (event) => {
		setJumpOutOfRange(false);
		if (isNumber(event.target.value) || event.target.value === "") {
			setJumpInput(event.target.value);
		}
		if (PartNumbers.length < event.target.value) {
			setJumpOutOfRange(true);
		}
	};

	const handleJumpEntered = () => {
		if (!jumpOutOfRange) {
			props.changePart(PartNumbers[jumpInput - 1]);
			setJumpInput("");
			setOpenJump(false);
		}
	};

	// useEffect(() => {
	// 	Mousetrap.bind("shift+backspace", (e) => {
	// 		e.preventDefault();
	// 		if (selectedHistory !== Histories[Histories.length - 1]) {
	// 			window.sessionStorage.setItem("ShortcutNavigation", true);
	// 			props.changePart(Histories[Histories.indexOf(selectedHistory) + 1]);
	// 		}
	// 	});
	// 	return () => Mousetrap.unbind("shift+backspace");
	// }, [Histories, props, selectedHistory]);

	// useEffect(() => {
	// 	Mousetrap.bind("ctrl+shift+backspace", (e) => {
	// 		e.preventDefault();
	// 		if (selectedHistory !== Histories[0]) {
	// 			window.sessionStorage.setItem("ShortcutNavigation", true);
	// 			props.changePart(Histories[Histories.indexOf(selectedHistory) - 1]);
	// 		}
	// 	});
	// 	return () => Mousetrap.unbind("ctrl+shift+backspace");
	// }, [Histories, props, selectedHistory]);

	// Shortcut keys declaration starts here
	useEffect(() => {
		Mousetrap.bind("ctrl+shift+left", (e) => {
			e.preventDefault();
			handleFirstButtonClick();
		});
		return () => Mousetrap.unbind("ctrl+shift+left");
	}, [handleFirstButtonClick]);

	useEffect(() => {
		Mousetrap.bind("shift+left", (e) => {
			e.preventDefault();
			console.log("shift+left");
			handleBackButtonClick();
		});
		return () => Mousetrap.unbind("shift+left");
	}, [handleBackButtonClick]);

	useEffect(() => {
		Mousetrap.bind("shift+right", (e) => {
			e.preventDefault();
			handleNextButtonClick();
		});
		return () => Mousetrap.unbind("shift+right");
	}, [handleNextButtonClick]);

	useEffect(() => {
		Mousetrap.bind("ctrl+shift+right", (e) => {
			e.preventDefault();
			handleLastButtonClick();
		});
		return () => Mousetrap.unbind("ctrl+shift+right");
	}, [handleLastButtonClick]);

	useEffect(() => {
		Mousetrap.bind("ctrl+shift+up", (e) => {
			e.preventDefault();
			setOpenJump(!openJump);
		});
		return () => Mousetrap.unbind("ctrl+shift+up");
	}, [openJump]);

	useEffect(() => {
		setIsFirstPartNumber(selectedHistory === PartNumbers[0] || !PartNumbers.includes(selectedHistory));
		setIsLastPartNumber(PartNumbers.length === 0 || selectedHistory === PartNumbers[PartNumbers.length - 1]);
	}, [PartNumbers, selectedHistory, reloadNavigation]);

	return (
		<>
			{typeof subsetLoaded === "string" && <BOMFilterMenu />}

			<Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
				<FormControl sx={{ marginRight: 1, minWidth: { xs: 100, sm: 150, md: 150, lg: 150, xl: 150 } }} focused>
					<InputLabel size="small" id="history-select-label">
						Recent Accessed Parts
					</InputLabel>
					<Select
						size="small"
						labelId="history-select-label"
						id="history-select"
						value={Histories.includes(selectedHistory) ? selectedHistory : ""}
						label="Recent Accessed parts"
						onChange={handleHistoryChange}
						MenuProps={{
							disableScrollLock: true,
							sx: { maxHeight: "60%" },
						}}
					>
						{Histories.map((history, index) => (
							<MenuItem key={index} value={history}>
								{history}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Stack sx={{ height: "40px" }} alignItems="center">
					<Typography sx={{ mt: -1.8, ml: 1, fontSize: "0.7rem" }}>
						{subsetLoaded && subsetName
							? typeof subsetLoaded === "string"
								? `${subsetLoaded}: ${subsetName} ${subsetPosition}`
								: `Subset: ${subsetName} ${subsetPosition}`
							: "Histories"}
					</Typography>
					<Box>
						{openJump ? (
							<ColoredTextField
								autoFocus
								sx={{
									width: "160px",
									"& input::placeholder": {
										fontSize: "13px",
									},
									"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
										display: "none",
									},
									mr: 1,
								}}
								autoComplete="off"
								inputProps={{
									sx: {
										height: "20px",
										backgroundColor: "#FFF",
									},
									min: "1",
									max: PartNumbers.length,
								}}
								error={jumpOutOfRange}
								FormHelperTextProps={{ sx: { mt: -0.3, fontSize: "10px" } }}
								helperText={jumpOutOfRange && "Out Of Range"}
								placeholder={"Enter=Apply, ESC=exit"}
								onChange={handleJumpChange}
								value={jumpInput}
								onKeyDown={(e) => {
									if (e.key === "Escape") {
										setJumpInput("");
										setOpenJump(!openJump);
										setJumpOutOfRange(false);
									} else if (e.key === "Enter") {
										handleJumpEntered();
									}
								}}
							/>
						) : (
							<>
								<Tooltip title="First record (CTRL + Shift + LeftArrow)">
									<span>
										<BottomBarIconButton disabled={isFirstPartNumber} onClick={handleFirstButtonClick}>
											<SkipPreviousSharpIcon />
										</BottomBarIconButton>
									</span>
								</Tooltip>

								<Tooltip title="Previous record (Shift + LeftArrow)">
									<span>
										<BottomBarIconButton disabled={isFirstPartNumber} onClick={handleBackButtonClick}>
											<ArrowLeftSharpIcon fontSize="large" />
										</BottomBarIconButton>
									</span>
								</Tooltip>

								<Tooltip title="Next record (Shift + RightArrow)">
									<span>
										<BottomBarIconButton disabled={isLastPartNumber} onClick={handleNextButtonClick}>
											<ArrowRightSharpIcon fontSize="large" />
										</BottomBarIconButton>
									</span>
								</Tooltip>

								<Tooltip title="Last record (CTRL + Shift + RightArrow)">
									<span>
										<BottomBarIconButton disabled={isLastPartNumber} onClick={handleLastButtonClick}>
											<SkipNextSharpIcon />
										</BottomBarIconButton>
									</span>
								</Tooltip>
								{subsetLoaded && (
									<Tooltip title="Jump to number (CTRL + Shift + UpArrow)">
										<span>
											<BottomBarIconButton onClick={() => setOpenJump(!openJump)}>
												<RedoIcon />
											</BottomBarIconButton>
										</span>
									</Tooltip>
								)}
							</>
						)}
					</Box>
				</Stack>
			</Box>
		</>
	);
};

export default BottomBarNavigation;
