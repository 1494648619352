// React
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AdminAction } from "../../../store/Admin/Admin-slice";
// UI
import { Box, Button, Modal, Tooltip, AppBar, Toolbar, Typography } from "@mui/material";
import InfoTable from "../../UI/Table";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../Util/ErrorBox";
// Package Import
import useHttp from "../../../Hooks/use-http";
import { APIURL } from "../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: "80%", md: "80%", lg: "80%", xl: "70%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	maxHeight: "80%",
	overflowY: "auto",
	p: 4,
};

const SeeUserOrg = (props) => {
	const dispatch = useDispatch();

	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	const open = useSelector((state) => state.adminState.openSeeUserOrgs);
	const rows = useSelector((state) => state.adminState.allUserOrgsTable);

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const columnsSettings = [
		{ field: "user", headerName: "User", type: "text", flex: 0.7 },
		{ field: "primary", headerName: "Primary Org", type: "text", flex: 0.3 },
		{ field: "secondary", headerName: "Secondary Org", type: "text", flex: 0.5 },
		{ field: "role", headerName: "Role", type: "text", flex: 0.4 },
	];

	useEffect(() => {
		if (open) {
			fetchData(
				{ url: `${APIURL}admin/assignedMultiUserOrgs/`, headers },
				(data) => {
					dispatch(AdminAction.setAllUserOrgsTable(data));
				},
				(error) => {
					console.log(`Unable to retrieve User Information, Error: ${error}`);
					setErrorMsg(`ERROR: Unable to retrieve User Information, please try refresh the page. Error: ${error}`);
					setErrorState(true);
				}
			);
		}
	}, [dispatch, fetchData, headers, open]);

	return (
		<Box>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />

			<Modal open={open} aria-labelledby="See-All-User-Org-title" aria-describedby="See-All-User-Org-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC") {
							e.preventDefault();
							dispatch(AdminAction.setOpenSeeAllOrg(false));
						} else if (e.code === "KeyA") {
                            e.preventDefault();
							dispatch(AdminAction.setOpenSeeAllOrg(false));
							dispatch(AdminAction.setOpenChangeOrg(true));
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"All User Orgs"}
							</Typography>
						</Toolbar>
					</AppBar>

					<Box mt={5} mb={2}>
						<InfoTable
							columnsSettings={columnsSettings}
							rows={rows}
							checkboxSelection={false}
							customToolBar={() => <></>}
							ExtraProps={{
								initialState: {
									sorting: {
										sortModel: [{ field: "user", sort: "asc" }],
									},
								},
							}}
							autoTableHeight={false}
							pageSize={100}
						></InfoTable>
					</Box>

					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Tooltip title={"Press A to open Change User Org"}>
							<Button
								onClick={() => {
									dispatch(AdminAction.setOpenSeeAllOrg(false));
									dispatch(AdminAction.setOpenChangeOrg(true));
								}}
							>
								Ch<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>a</u>nge User Org
							</Button>
						</Tooltip>

						<Tooltip title={"Press C to close"}>
							<Button onClick={() => dispatch(AdminAction.setOpenSeeAllOrg(false))}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
							</Button>
						</Tooltip>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default SeeUserOrg;
