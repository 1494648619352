// React
import { useSelector } from "react-redux";

import { Tooltip, Grid, Box, useScrollTrigger } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";

const RoHSHeader = (props) => {
	const partInfo = useSelector((state) => state.rohsStates.partInfo);

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 10,
		target: props.window ? window() : undefined,
	});

	return (
		<Box
			position={"fixed"}
			zIndex={1000}
			width={"100%"}
			bgcolor={trigger ? "#EBEBEB" : "#fff"}
			sx={{ top: { xs: "62px", sm: "62px", lg: "65px" }, height: { xs: "175px", sm: "175px", md: "120px", lg: "75px" } }}
		>
			<Box sx={{ mx: "auto" }} width={{ xs: "95%", sm: "85%" }} marginLeft={2} marginRight={4} marginTop={2}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" rowSpacing={2} columnSpacing={0.8} wrap={"wrap"}>
					<Grid item xl={1.6} lg={2} md={10} sm={9} xs={7.5}>
						<Tooltip title={partInfo.partNumber}>
							<ColoredTextField fullWidth label="item" focused value={partInfo.partNumber} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>

					<Grid item xl={0.8} lg={1} md={2} sm={3} xs={4.5}>
						<ColoredTextField fullWidth label="Pref Code" focused value={partInfo.prefCode} inputProps={{ readOnly: true }} />
					</Grid>

					<Grid item xl={3.8} lg={3.8} md={6} sm={12} xs={12}>
						<Tooltip title={`${partInfo.catCode} icat_id=${partInfo.icatID}`}>
							<ColoredTextField
								fullWidth
								label="iCategory"
								focused
								value={`${partInfo.catCode} icat_id=${partInfo.icatID}`}
								inputProps={{ readOnly: true }}
							/>
						</Tooltip>
					</Grid>

					<Grid item xl={5.8} lg={5.2} md={6} sm={12} xs={12}>
						<Tooltip title={partInfo.partDescription}>
							<ColoredTextField fullWidth label="Description" focused value={partInfo.partDescription} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default RoHSHeader;
