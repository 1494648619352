// React
import { useSelector } from "react-redux";
import { memo } from "react";

// UI
import { Box, Modal, AppBar, Toolbar, Typography, Button, Tooltip } from "@mui/material";
import InfoTable from "../../../UI/Table";
import { DateSortingComparator, formatDate } from "../../../Util/Util";
import { GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";

import { OSAltKey } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: "80%", md: "80%", lg: "80%", xl: "70%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	maxHeight: "90%",
	overflowY: "auto",
	p: 4,
};

const CountryHTS = (props) => {
	const itemName = useSelector((state) => state.infoStates.info.itemname) || "";
	const rows = useSelector((state) => state.infoStates.countryHTS);

	const columnsSettings = [
		{ field: "hts", headerName: "HTS Code", type: "text", flex: 1 },
		{ field: "ccode", headerName: "Cntry_Code", type: "text", flex: 0.5 },
		{ field: "classDate", headerName: "Class_date", type: "text", flex: 0.7, sortComparator: DateSortingComparator },
		{ field: "createdBy", headerName: "Created_by", type: "text", flex: 1 },
	];

	const customToolBar = () => (
		<>
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `HTS_KPN${itemName}_${formatDate(new Date(), "")}`,
				}}
			/>
		</>
	);

	return (
		<Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
			<Box
				sx={style}
				onKeyDown={(e) => {
					if (e.code === "KeyC" && e.altKey === true) {
						e.preventDefault();
						props.setOpenCountryHTS(false);
					}
				}}
			>
				<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
					<Toolbar>
						<Typography variant="h6" component="div" color="#fff">
							{"Classification Info - Main Page"}
						</Typography>
					</Toolbar>
				</AppBar>

				<Typography mt={5}>{`Harmonized Tarrif Schedule info from GTM for item: ${itemName}`}</Typography>

				<InfoTable
					columnsSettings={columnsSettings}
					rows={rows}
					checkboxSelection={false}
					customToolBar={customToolBar}
					rowHeight={45}
					height={400}
				></InfoTable>

				<Box>
					<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
						<Button sx={{ mt: 2 }} onClick={() => props.setOpenCountryHTS(false)}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
						</Button>
					</Tooltip>
					<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
				</Box>
			</Box>
		</Modal>
	);
};

export default memo(CountryHTS);
