// React
import { useEffect, useState } from "react";
// UI
import { Modal, Box, Stack, Tooltip, Button, Typography } from "@mui/material";
import ColoredTextField from "../UI/ColoredTextField";
// Packages Import
import { OSAltKey } from "./Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const SetFileName = (props) => {
	const { openSetFileName, setOpenSetFileName, blob, fileName } = props;

	const [inputValue, setInputValue] = useState("");
	const [error, setError] = useState("");

	const handleInputChange = (event) => {
		const value = event.target.value;
		setInputValue(value);
		if (value?.trim()) {
			setError("");
		} else {
			setError("Subset name cannot be blank");
		}
	};

	const handleOkClicked = () => {
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", inputValue);
		document.body.appendChild(link);
		link.click();
		link.remove();
		setOpenSetFileName(false);
	};

	useEffect(() => {
		if (openSetFileName) {
			setInputValue(fileName);
		}
	}, [fileName, openSetFileName]);

	return (
		<Modal open={openSetFileName} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
			<Box
				sx={style}
				onKeyDown={(e) => {
					if (e.code === "KeyO" && e.altKey === true && inputValue.length !== 0) {
						e.preventDefault();
						handleOkClicked();
					}
				}}
			>
				<Stack mb={2} spacing={2}>
					<ColoredTextField
						fullWidth
						label="Enter new name"
						focused
						autoFocus
						onChange={handleInputChange}
						value={inputValue}
						error={error ? true : false}
						helperText={error}
						inputProps={{ spellCheck: false }}
					/>
				</Stack>
				<Box>
					<Tooltip title={`For shortcut press ${OSAltKey} + O`}>
						<span>
							<Button disabled={inputValue.length === 0 ? true : false} onClick={handleOkClicked}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>O</u>K
							</Button>
						</span>
					</Tooltip>
				</Box>
				<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
			</Box>
		</Modal>
	);
};

export default SetFileName;
