// Packages Import
import React, { memo, useState, useEffect } from "react";
import { Box, Grid, Button, Typography, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
import moment from "moment";

// Project Component
import ColoredTextField from "../../UI/ColoredTextField";
import Datepicker from "../../UI/Datepicker";
import { projectsAction } from "../../../store/Projects/Projects-slice";
import { isEmptyObject, APIURL } from "../../Util/Util";
import AutocompleteDropdown from "../../UI/AutocompleteDropdown";
import useHttp from "../../../Hooks/use-http";

const ProjectInfo = (props) => {
	const { resetData = () => {}, handleDetailUpdate = () => {}, handleNameUpdate = () => {} } = props;
	const dispatch = useDispatch();
	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const location = useLocation();
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const [pdfisLoading, setPdfisLoading] = useState(false);
	const [excelIsLoading, setExcelisLoading] = useState(false);
	const projErrorState = useSelector((state) => state.projectsStates.error);
	const projectInfoData = useSelector((state) => state.projectsStates.PROJECT_INFO);
	const projectLogData = useSelector((state) => state.projectsStates.PROJECT_LOGS);
	const projectUID = useSelector((state) => state.projectsStates.projectIndex);
	const projects = useSelector((state) => state.projectsStates.PROJECT_LIST_INFO);
	const asyncInputDetails = useSelector((state) => state.projectsStates.asyncInputDetails);
	const editDetailState = useSelector((state) => state.projectsStates.editDetailState);
	const editNameState = useSelector((state) => state.projectsStates.editNameState);
	const prevFilteredData = useSelector((state) => state.projectsStates.prevFilteredData);
	const detailsErrorState = Object.values(projErrorState).every((value) => value === false);
    const findProjectIndex = projectInfoData.findIndex((singleProjectData) => {
        return projectUID === singleProjectData.uid;
    })
	const projectIndex = findProjectIndex >= 0 ? findProjectIndex : 0

	const userInfo = {
		org: useSelector((state) => state.authenticationState.User_Assigned_Orgs),
	};
	const rptDeliveryDueDate = moment(projectInfoData[projectIndex].rptDeliveryDueDate , "YYYY-MM-DD").isValid() ? projectInfoData[projectIndex].rptDeliveryDueDate : "";
	const dateCompleted =  moment(projectInfoData[projectIndex].dateCompleted, "YYYY-MM-DD").isValid() ? projectInfoData[projectIndex].dateCompleted : "";
	const inputDueDate = moment(projectInfoData[projectIndex].inputDueDate, "YYYY-MM-DD").isValid() ? projectInfoData[projectIndex].inputDueDate : "";
	const completeDateEntry = moment(projectLogData[projectIndex].cmpltDate, "YYYY-MM-DD").isValid() ? moment(projectLogData[projectIndex].cmpltDate).format("MM/DD/YYYY") : "";
	const updatedDate = moment(projectLogData[projectIndex].dateUpdated, "YYYY-MM-DD").isValid() ? moment(projectLogData[projectIndex].dateUpdated).format("MM/DD/YYYY") : "";
	const defaultAutocompleteVal = {
		label: "",
		id: "",
	};
	const projectSelected =
		projectInfoData[projectIndex].project && projectInfoData[projectIndex].proId
			? {
					label: projectInfoData[projectIndex].projectTyped,
					id: projectInfoData[projectIndex].proId,
			  }
			: defaultAutocompleteVal;

	const projectManager = {
		label: projectInfoData[projectIndex].projectManagerTyped,
		id: projectInfoData[projectIndex].projectManagerTyped,
	};

	const npiEng = {
		label: projectInfoData[projectIndex].npiEngTyped,
		id: projectInfoData[projectIndex].npiEngTyped,
	};

	const pcdfm = {
		label: projectInfoData[projectIndex].pcdfmTyped,
		id: projectInfoData[projectIndex].pcdfmTyped,
	};

	const designer = {
		label: projectInfoData[projectIndex].designerTyped,
		id: projectInfoData[projectIndex].designerTyped,
	};

	const liaison = {
		label: projectInfoData[projectIndex].liaisonTyped,
		id: projectInfoData[projectIndex].liaisonTyped,
	};

	const disablePastInpDueDate = inputDueDate === "" ? true : false;
	const disablePastRptInpDueDate = rptDeliveryDueDate === "" ? true : false;
	const nonMtlEngAccess = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);
	const isInOrg = userInfo.org.allOrg.includes(prevFilteredData.userOrg);
	let inputTimeOut;
	const toggleEditDetailState = () => {
		if (editDetailState.state) {
			handleDetailUpdate();
		}
		dispatch(projectsAction.updateEditDetailState({ data: { state: !editDetailState.state } }));
	};

	const toggleEditNameState = () => {
		if (editNameState.state) {
			handleNameUpdate();
		}
		dispatch(projectsAction.updateEditNameState({ data: { state: !editNameState.state } }));
	};

	const handleCancel = () => {
		resetData();
		dispatch(projectsAction.updateEditDetailState({ data: { state: false } }));
		dispatch(projectsAction.updateEditNameState({ data: { state: false } }));
		dispatch(
			projectsAction.setError({
				data: {
					projectName: false,
					projectManager: false,
					npiEng: false,
					pcdfm: false,
					designer: false,
					liaison: false,
				},
			})
		);
	};

	const fileDownloadExcel = (event) => {
		setExcelisLoading(true);
		if (typeof props.fileDownloadExcel === "function") {
			props.fileDownloadExcel(setExcelisLoading);
		}
	};

	const fileDownloadPdf = (event) => {
		setPdfisLoading(true);
		if (typeof props.fileDownloadPdf === "function") {
			props.fileDownloadPdf(setPdfisLoading);
		}
	};

	const handleTextChange = (event) => {
		const val = event.target.value;
		switch (event.target.name) {
			case "instrument":
				dispatch(projectsAction.updateProjectInfo({ data: { instrument: val }, id: projectIndex }));
				break;
			case "division":
				dispatch(projectsAction.updateProjectInfo({ data: { division: val }, id: projectIndex }));
				break;
			case "note":
				dispatch(projectsAction.updateProjectInfo({ data: { note: val }, id: projectIndex }));
				break;
			default:
				break;
		}
	};

	const handleSelection = (val, type) => {
		switch (type) {
			case "name":
				if (val === null) {
					// dispatch(projectsAction.setError({data:{projectName:true}}))
				} else {
					dispatch(projectsAction.setError({ data: { projectName: false } }));
					dispatch(
						projectsAction.updateProjectInfo({
							data: { projectTyped: val.label, project: val.label, proId: val.id },
							id: projectIndex,
						})
					);
				}
				break;
			case "manager":
				if (val === null) {
					// dispatch(projectsAction.setError({data:{projectManager:true}}))
				} else {
					dispatch(projectsAction.setError({ data: { projectManager: false } }));
					dispatch(
						projectsAction.updateProjectInfo({
							data: { projectManagerTyped: val.label, projectManager: val.label },
							id: projectIndex,
						})
					);
				}
				break;
			case "npi":
				if (val === null) {
					// dispatch(projectsAction.setError({data:{npiEng:true}}))
				} else {
					dispatch(projectsAction.setError({ data: { npiEng: false } }));
					dispatch(
						projectsAction.updateProjectInfo({ data: { npiEngTyped: val.label, npiEng: val.label }, id: projectIndex })
					);
				}
				break;
			case "pcdfm":
				if (val === null) {
					// dispatch(projectsAction.setError({data:{pcdfm:true}}))
				} else {
					dispatch(projectsAction.setError({ data: { pcdfm: false } }));
					dispatch(
						projectsAction.updateProjectInfo({ data: { pcdfmTyped: val.label, pcdfm: val.label }, id: projectIndex })
					);
				}
				break;
			case "designer":
				if (val === null) {
					// dispatch(projectsAction.setError({data:{designer:true}}))
				} else {
					dispatch(projectsAction.setError({ data: { designer: false } }));
					dispatch(
						projectsAction.updateProjectInfo({
							data: { designerTyped: val.label, designer: val.label },
							id: projectIndex,
						})
					);
				}
				break;
			case "liaison":
				if (val === null) {
					// dispatch(projectsAction.setError({data:{liaison:true}}))
				} else {
					dispatch(projectsAction.setError({ data: { liaison: false } }));
					dispatch(
						projectsAction.updateProjectInfo({
							data: { liaisonTyped: val.label, liaison: val.label },
							id: projectIndex,
						})
					);
				}
				break;

			default:
				break;
		}
	};

	const handleInputChange = (val, type) => {
		const timeoutSec = 250;
		let isExist;
		const apiParam = "Projects/employeelist/?empStatus=B&filterOn=";
		switch (type) {
			case "name":
				if (projects.length > 0 && !isEmptyObject(projects[0])) {
					let proId = "";
					isExist = projects.findIndex((singleProject) => {
						if (singleProject.label === val) {
							proId = singleProject.id;
							return true;
						}
						return false;
					});
					if (isExist > -1) {
						dispatch(
							projectsAction.updateProjectInfo({
								data: { projectTyped: val, project: val, proId: proId },
								id: projectIndex,
							})
						);
						dispatch(projectsAction.setError({ data: { projectName: false } }));
					} else {
						dispatch(projectsAction.updateProjectInfo({ data: { projectTyped: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { projectName: true } }));
					}
				}

				break;
			case "manager":
				if (val.length > 0) {
					isExist = asyncInputDetails.projectManager.findIndex((singleData) => {
						if (singleData.label === val) {
							return true;
						}
						return false;
					});
					if (isExist > -1) {
						dispatch(
							projectsAction.updateProjectInfo({
								data: { projectManagerTyped: val, projectManager: val },
								id: projectIndex,
							})
						);
						dispatch(projectsAction.setError({ data: { projectManager: false } }));
					} else {
						dispatch(projectsAction.updateProjectInfo({ data: { projectManagerTyped: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { projectManager: true } }));
					}
					clearTimeout(inputTimeOut);
					inputTimeOut = setTimeout(() => {
						if (val.length > 2) {
							fetchData(
								{ url: `${APIURL}${apiParam}${val}`, headers },
								(data) => handleDetailsListUpdate(data, type),
								(error) => {
									console.log("ERROR: ", error);
									// setErrorMsg(`ERROR: Unable to retrieve name, ${error}`)
									// setErrorState(true);
								}
							);
						}
					}, timeoutSec);
				} else {
					dispatch(
						projectsAction.updateProjectInfo({
							data: { projectManagerTyped: val, projectManager: val },
							id: projectIndex,
						})
					);
					dispatch(projectsAction.setError({ data: { projectManager: false } }));
				}
				break;
			case "npi":
				if (val.length > 0) {
					isExist = asyncInputDetails.npiEng.findIndex((singleData) => {
						if (singleData.label === val) {
							return true;
						}
						return false;
					});
					if (isExist > -1) {
						dispatch(projectsAction.updateProjectInfo({ data: { npiEngTyped: val, npiEng: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { npiEng: false } }));
					} else {
						dispatch(projectsAction.updateProjectInfo({ data: { npiEngTyped: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { npiEng: true } }));
					}
					clearTimeout(inputTimeOut);
					inputTimeOut = setTimeout(() => {
						if (val.length > 2) {
							fetchData(
								{ url: `${APIURL}${apiParam}${val}`, headers },
								(data) => handleDetailsListUpdate(data, type),
								(error) => {
									console.log("ERROR: ", error);
									// setErrorMsg(`ERROR: Unable to retrieve name, ${error}`)
									// setErrorState(true);
								}
							);
						}
					}, timeoutSec);
				} else {
					dispatch(projectsAction.updateProjectInfo({ data: { npiEngTyped: val, npiEng: val }, id: projectIndex }));
					dispatch(projectsAction.setError({ data: { npiEng: false } }));
				}
				break;
			case "pcdfm":
				if (val.length > 0) {
					isExist = asyncInputDetails.pcdfm.findIndex((singleData) => {
						if (singleData.label === val) {
							return true;
						}
						return false;
					});
					if (isExist > -1) {
						dispatch(projectsAction.updateProjectInfo({ data: { pcdfmTyped: val, pcdfm: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { pcdfm: false } }));
					} else {
						dispatch(projectsAction.updateProjectInfo({ data: { pcdfmTyped: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { pcdfm: true } }));
					}
					clearTimeout(inputTimeOut);
					inputTimeOut = setTimeout(() => {
						if (val.length > 2) {
							fetchData(
								{ url: `${APIURL}${apiParam}${val}`, headers },
								(data) => handleDetailsListUpdate(data, type),
								(error) => {
									console.log("ERROR: ", error);
									// setErrorMsg(`ERROR: Unable to retrieve name, ${error}`)
									// setErrorState(true);
								}
							);
						}
					}, timeoutSec);
				} else {
					dispatch(projectsAction.updateProjectInfo({ data: { pcdfmTyped: val, pcdfm: val }, id: projectIndex }));
					dispatch(projectsAction.setError({ data: { pcdfm: false } }));
				}
				break;
			case "designer":
				if (val.length > 0) {
					isExist = asyncInputDetails.designer.findIndex((singleData) => {
						if (singleData.label === val) {
							return true;
						}
						return false;
					});
					if (isExist > -1) {
						dispatch(
							projectsAction.updateProjectInfo({ data: { designerTyped: val, designer: val }, id: projectIndex })
						);
						dispatch(projectsAction.setError({ data: { designer: false } }));
					} else {
						dispatch(projectsAction.updateProjectInfo({ data: { designerTyped: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { designer: true } }));
					}
					clearTimeout(inputTimeOut);
					inputTimeOut = setTimeout(() => {
						if (val.length > 2) {
							fetchData(
								{ url: `${APIURL}${apiParam}${val}`, headers },
								(data) => handleDetailsListUpdate(data, type),
								(error) => {
									console.log("ERROR: ", error);
									// setErrorMsg(`ERROR: Unable to retrieve name, ${error}`)
									// setErrorState(true);
								}
							);
						}
					}, timeoutSec);
				} else {
					dispatch(projectsAction.updateProjectInfo({ data: { designerTyped: val, designer: val }, id: projectIndex }));
					dispatch(projectsAction.setError({ data: { designer: false } }));
				}
				break;
			case "liaison":
				if (val.length > 0) {
					isExist = asyncInputDetails.liaison.findIndex((singleData) => {
						if (singleData.label === val) {
							return true;
						}
						return false;
					});
					if (isExist > -1) {
						dispatch(projectsAction.updateProjectInfo({ data: { liaisonTyped: val, liaison: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { liaison: false } }));
					} else {
						dispatch(projectsAction.updateProjectInfo({ data: { liaisonTyped: val }, id: projectIndex }));
						dispatch(projectsAction.setError({ data: { liaison: true } }));
					}
					clearTimeout(inputTimeOut);
					inputTimeOut = setTimeout(() => {
						if (val.length > 2) {
							fetchData(
								{ url: `${APIURL}${apiParam}${val}`, headers },
								(data) => handleDetailsListUpdate(data, type),
								(error) => {
									console.log("ERROR: ", error);
									// setErrorMsg(`ERROR: Unable to retrieve name, ${error}`)
									// setErrorState(true);
								}
							);
						}
					}, timeoutSec);
				} else {
					dispatch(projectsAction.updateProjectInfo({ data: { liaisonTyped: val, liaison: val }, id: projectIndex }));
					dispatch(projectsAction.setError({ data: { liaison: false } }));
				}
				break;
			default:
				break;
		}
	};

	const handleDetailsListUpdate = (data, type) => {
		const empList = data?.EMPLOYEE_LIST.sort() ?? "";
		switch (type) {
			case "manager":
				dispatch(projectsAction.updateAsyncInputDetails({ data: { projectManager: empList } }));
				break;
			case "npi":
				dispatch(projectsAction.updateAsyncInputDetails({ data: { npiEng: empList } }));
				break;
			case "pcdfm":
				dispatch(projectsAction.updateAsyncInputDetails({ data: { pcdfm: empList } }));
				break;
			case "designer":
				dispatch(projectsAction.updateAsyncInputDetails({ data: { designer: empList } }));
				break;
			case "liaison":
				dispatch(projectsAction.updateAsyncInputDetails({ data: { liaison: empList } }));
				break;
			case "all":
				dispatch(
					projectsAction.updateAsyncInputDetails({
						data: { projectManager: empList, npiEng: empList, pcdfm: empList, designer: empList, liaison: empList },
					})
				);
				break;
			default:
				break;
		}
	};

	const handleDateSelection = (val, type) => {
		switch (type) {
			case "completed":
				dispatch(
					projectsAction.updateProjectInfo({
						data: {
							dateCompleted: moment(val, "YYYY-MM-DD").format("YYYY-MM-DD"),
							prevDateCompleted: dateCompleted,
						},
						id: projectIndex,
					})
				);
				break;
			case "due":
				dispatch(
					projectsAction.updateProjectInfo({
						data: {
							rptDeliveryDueDate: moment(val, "YYYY-MM-DD").format("YYYY-MM-DD"),
							prevRptDeliveryDueDate: rptDeliveryDueDate,
						},
						id: projectIndex,
					})
				);
				break;
			case "input":
				dispatch(
					projectsAction.updateProjectInfo({
						data: {
							inputDueDate: moment(val, "YYYY-MM-DD").format("YYYY-MM-DD"),
							prevInputDueDate: inputDueDate,
						},
						id: projectIndex,
					})
				);
				break;
			case "all":
				dispatch(projectsAction.updateProjectInfo({ data: val }));
				break;
			default:
				break;
		}
	};
	const handleDisableDate = (val, type) => {
		switch (type) {
			case "due":
				if (rptDeliveryDueDate !== "") {
					const prevRptDeliveryDueDate = moment(
						projectInfoData[projectIndex].prevRptDeliveryDueDate,
						"YYYY-MM-DD"
					).isValid()
						? moment(projectInfoData[projectIndex].prevRptDeliveryDueDate, "YYYY-MM-DD")
						: "";

					const today = moment();
					const curDateCopy = moment(val, "YYYY-MM-DD");
					const inpDateCopy = moment(rptDeliveryDueDate, "YYYY-MM-DD");
					const prevInpDateCopy = moment(prevRptDeliveryDueDate, "YYYY-MM-DD");
					if (
						curDateCopy.isSame(inpDateCopy, "day") ||
						curDateCopy.isSameOrAfter(today, "day") ||
						curDateCopy.isSame(prevInpDateCopy, "day")
					) {
						return false;
					} else {
						return true;
					}
				}
				return false;
			case "input":
				if (inputDueDate !== "") {
					const prevInpDate = moment(projectInfoData[projectIndex].prevInputDueDate, "YYYY-MM-DD").isValid()
						? moment(projectInfoData[projectIndex].prevInputDueDate, "YYYY-MM-DD")
						: "";

					const today = moment();
					const curDateCopy = moment(val, "YYYY-MM-DD");
					const inpDateCopy = moment(inputDueDate, "YYYY-MM-DD");
					const prevInpDateCopy = moment(prevInpDate, "YYYY-MM-DD");
					if (
						curDateCopy.isSame(inpDateCopy, "day") ||
						curDateCopy.isSameOrAfter(today, "day") ||
						curDateCopy.isSame(prevInpDateCopy, "day")
					) {
						return false;
					} else {
						return true;
					}
				}
				return false;
			default:
				return false;
		}
	};
	useEffect(() => {
		if (location.pathname === "/Projects" && projectUID !== "-") {
			handleDetailsListUpdate(undefined, "all");
			handleCancel();
		}
	}, [location, projectUID]);
	return (
		<Box>
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={1}
				wrap="wrap"
				sx={{ my: 1.5 }}
			>
				<Grid item xl={8} lg={8} md={8} sm={8} xs={12} p={1}>
					<Grid container direction="row" spacing={1} wrap="wrap">
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign="center">
							<Grid
								container
								direction="row"
								spacing={0.5}
								wrap="wrap"
								sx={{
									mb: 1.5,
								}}
							>
								<Grid item xl={4.7} lg={4.7} md={4.7} sm={12} xs={12} textAlign="center">
									<Typography variant="h6">{projectInfoData[projectIndex].title || "-"}</Typography>
									<Typography variant="caption">Projects Responsibility Info</Typography>
								</Grid>
								{isInOrg ? (
									<Grid item xl={1.7} lg={1.7} md={1.7} sm={6} xs={12} textAlign="center">
										<Button
											variant="contained"
											disabled={
												nonMtlEngAccess ||
												(!editNameState.state
													? projectInfoData[projectIndex].proId
														? detailsErrorState === true
															? false
															: true
														: true
													: true)
											}
											onClick={toggleEditDetailState}
											sx={{ px: 1, width: "100%" }}
										>
											{editDetailState.state ? "Save Details" : "Edit Details"}
										</Button>
									</Grid>
								) : undefined}
								{isInOrg ? (
									<Grid item xl={1.4} lg={1.4} md={1.4} sm={6} xs={12} textAlign="center">
										<Button
											variant="contained"
											disabled={
												nonMtlEngAccess ||
												(!editDetailState.state
													? projectInfoData[projectIndex].proId
														? projErrorState.projectName === false
															? false
															: true
														: true
													: true)
											}
											onClick={toggleEditNameState}
											sx={{ px: 1, width: "100%" }}
										>
											{editNameState.state ? "Save Name" : "Edit Name"}
										</Button>
									</Grid>
								) : undefined}
								{isInOrg ? undefined : (
									<Grid item xl={4.5} lg={4.5} md={4.5} sm={12} xs={12} container justify="center" alignItems="center">
										<Typography variant="subtitle" color="red">
											User is not within {prevFilteredData.userOrg} to edit this information{" "}
										</Typography>
									</Grid>
								)}
								<Grid item xl={1.4} lg={1.4} md={1.4} sm={6} xs={12} textAlign="center">
									<LoadingButton
										loading={pdfisLoading}
										variant="contained"
										disabled={projectInfoData[projectIndex].proId ? false : true}
										onClick={fileDownloadPdf}
										sx={{ px: 1, width: "100%" }}
									>
										<FileDownloadIcon sx={{ pr: 0.3, pt: 0.3 }} fontSize={"small"} />
										PDF
									</LoadingButton>
								</Grid>
								<Grid item xl={1.4} lg={1.4} md={1.4} sm={6} xs={12} textAlign="center">
									<LoadingButton
										loading={excelIsLoading}
										variant="contained"
										disabled={projectInfoData[projectIndex].proId ? false : true}
										onClick={fileDownloadExcel}
										sx={{ px: 1, width: "100%" }}
									>
										<FileDownloadIcon sx={{ pr: 0.3, pt: 0.3 }} fontSize={"small"} />
										EXCEL
									</LoadingButton>
								</Grid>
								{isInOrg ? (
									<Grid item xl={1.4} lg={1.4} md={1.4} sm={12} xs={12} textAlign="center">
										<Button
											variant="contained"
											disabled={editDetailState.state || editNameState.state ? false : true}
											sx={{ px: 1, width: "100%" }}
											onClick={handleCancel}
										>
											Cancel
										</Button>
									</Grid>
								) : undefined}
							</Grid>
						</Grid>

						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Project ID"
									value={projectInfoData[projectIndex].proId}
									fullWidth
									focused
									inputProps={{
										readOnly: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									disabled={editDetailState.state}
								/>
							</Tooltip>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<AutocompleteDropdown
								label="Project Name"
								selections={projects}
								value={projectSelected}
								handleSelection={handleSelection}
								handleInputChange={handleInputChange}
								name="name"
								readOnly={!editNameState.state}
								disabled={editDetailState.state}
								freeSolo={true}
								error={projErrorState.projectName}
								extraProps={{
									ListboxProps: {
										sx: {
											"& .MuiAutocomplete-option.Mui-focused": {
												backgroundColor: "rgba(230, 230, 250, 0.8)",
											},
										},
									},
								}}
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<AutocompleteDropdown
								label="Project Manager"
								selections={asyncInputDetails.projectManager}
								value={projectManager}
								handleSelection={handleSelection}
								handleInputChange={handleInputChange}
								name="manager"
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								freeSolo={true}
								isLoading={isLoading}
								error={projErrorState.projectManager}
								extraProps={{
									filterOptions: (options) => options,
									ListboxProps: {
										sx: {
											"& .MuiAutocomplete-option.Mui-focused": {
												backgroundColor: "rgba(230, 230, 250, 0.8)",
											},
										},
									},
								}}
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<AutocompleteDropdown
								label="NPI OF Engineer"
								selections={asyncInputDetails.npiEng}
								value={npiEng}
								handleSelection={handleSelection}
								handleInputChange={handleInputChange}
								name="npi"
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								freeSolo={true}
								isLoading={isLoading}
								error={projErrorState.npiEng}
								extraProps={{
									filterOptions: (options) => options,
									ListboxProps: {
										sx: {
											"& .MuiAutocomplete-option.Mui-focused": {
												backgroundColor: "rgba(230, 230, 250, 0.8)",
											},
										},
									},
								}}
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<AutocompleteDropdown
								label="PCDFM"
								selections={asyncInputDetails.pcdfm}
								value={pcdfm}
								handleSelection={handleSelection}
								handleInputChange={handleInputChange}
								name="pcdfm"
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								freeSolo={true}
								isLoading={isLoading}
								error={projErrorState.pcdfm}
								extraProps={{
									filterOptions: (options) => options,
									ListboxProps: {
										sx: {
											"& .MuiAutocomplete-option.Mui-focused": {
												backgroundColor: "rgba(230, 230, 250, 0.8)",
											},
										},
									},
								}}
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<AutocompleteDropdown
								label="Designer"
								selections={asyncInputDetails.designer}
								value={designer}
								handleSelection={handleSelection}
								handleInputChange={handleInputChange}
								name="designer"
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								freeSolo={true}
								isLoading={isLoading}
								error={projErrorState.designer}
								extraProps={{
									filterOptions: (options) => options,
									ListboxProps: {
										sx: {
											"& .MuiAutocomplete-option.Mui-focused": {
												backgroundColor: "rgba(230, 230, 250, 0.8)",
											},
										},
									},
								}}
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Instrument Application"
									value={projectInfoData[projectIndex].instrument}
									fullWidth
									focused
									onChange={handleTextChange}
									name={"instrument"}
									disabled={editNameState.state}
									inputProps={{
										readOnly: !editDetailState.state,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<AutocompleteDropdown
								label="Liaison Name"
								selections={asyncInputDetails.liaison}
								value={liaison}
								handleSelection={handleSelection}
								handleInputChange={handleInputChange}
								name="liaison"
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								freeSolo={true}
								isLoading={isLoading}
								error={projErrorState.liaison}
								extraProps={{
									filterOptions: (options) => options,
									ListboxProps: {
										sx: {
											"& .MuiAutocomplete-option.Mui-focused": {
												backgroundColor: "rgba(230, 230, 250, 0.8)",
											},
										},
									},
								}}
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Division"
									value={projectInfoData[projectIndex].division}
									fullWidth
									focused
									onChange={handleTextChange}
									name={"division"}
									disabled={editNameState.state}
									inputProps={{
										readOnly: !editDetailState.state,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<Datepicker
								label="Rpt Delivery Due Date"
								handleSelection={handleDateSelection}
								val={rptDeliveryDueDate}
								name="due"
								disableFuture={false}
								disablePast={disablePastRptInpDueDate}
								shouldDisableDate={handleDisableDate}
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								preventCut={true}
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<Datepicker
								label="Date Completed"
								handleSelection={handleDateSelection}
								val={dateCompleted}
								name="completed"
								disableFuture={false}
								disablePast={false}
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								preventCut={true}

							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} textAlign="center">
							<Datepicker
								label="Input Due Date"
								handleSelection={handleDateSelection}
								val={inputDueDate}
								name="input"
								disableFuture={false}
								disablePast={disablePastInpDueDate}
								shouldDisableDate={handleDisableDate}
								readOnly={!editDetailState.state}
								disabled={editNameState.state}
								preventCut={true}

							/>
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Notes"
									value={projectInfoData[projectIndex].note}
									fullWidth
									multiline
									focused
									onChange={handleTextChange}
									name={"note"}
									rows={2}
									disabled={editNameState.state}
									inputProps={{
										readOnly: !editDetailState.state,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
					<Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={3.9} mt={5.7}>
						<Grid item xl={12} lg={12} md={4} sm={6} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Completed Info Updated By"
									value={projectLogData[projectIndex].completedInfoUpdatedBy}
									fullWidth
									focused
									inputProps={{
										readOnly: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Tooltip>
						</Grid>

						<Grid item xl={12} lg={12} md={4} sm={6} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Complete Date Entry"
									value={completeDateEntry}
									fullWidth
									focused
									inputProps={{
										readOnly: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xl={12} lg={12} md={4} sm={6} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Info Updated By"
									value={projectLogData[projectIndex].infoUpdatedBy}
									fullWidth
									focused
									inputProps={{
										readOnly: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xl={12} lg={12} md={4} sm={6} xs={12} textAlign="center">
							<Tooltip title={""}>
								<ColoredTextField
									label="Date Updated"
									value={updatedDate}
									fullWidth
									focused
									inputProps={{
										readOnly: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default memo(ProjectInfo);
