// Packages Import
import React, { Fragment , useEffect , useReducer, useState  } from "react"
import { Box , Grid, Typography, Paper, Button} from "@mui/material"
import { useDispatch, useSelector } from "react-redux";

// Project Component
import CommoditiesTable from "./CommoditiesTable"
import ItemTable from "./ItemTable"
import MtlEngTable from "./MtlEngTable"
import PartSelectionTable from "./PartSelectionTable"
import useHttp from "../../../Hooks/use-http"
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator"
import BottomNavigation from "./MtlEngBottomBar"
import { APIURL } from "../../Util/Util"
import { reducer, ACTION , defaultCommoditiesTable, defaultPartSelectionTable, defaultMtlEngTable, defaultItemTable} from "./MtlEngReducer"
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DropdownFilter from "../../UI/DropdownFilter"
import { mtlEngActions} from "../../../store/MtlEng/MtlEng-slice";


let orgList = []
let curOrg = ''

const Mtl = () => {
	const dispatch = useDispatch();
	const { isLoading, sendRequest: fetchData } = useHttp()
	const { sendRequest: fetchData2 } = useHttp()
	const prevFilteredData = useSelector((state) => state.projectsStates.prevFilteredData)
	const [table , dispatchTable] = useReducer( reducer , {
		COMMODITIES_TABLE: defaultCommoditiesTable,
		PART_SELECTION_TABLE: defaultPartSelectionTable,
		MTL_ENG_TABLE: defaultMtlEngTable,
		ITEM_TABLE: defaultItemTable
	})
	const maxtableHeight= 1
	const height= 319
	const autoTableHeight= false
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	// const [curOrg , setCurOrg] = useState(' ') 
	const userInfo = {
		org : useSelector((state) => state.authenticationState.User_Assigned_Orgs),
	}
	const updateData = (data) => {
		// console.log(data)
		dispatch(mtlEngActions.updateAll({ data: data}))
	}
	const updateOrg = (data) => {
		orgList = data.USER_ORGS_LIST.map((org) =>{
			return org.user_org_c
		})
		curOrg = userInfo.org.primaryOrg
	}
	const gridOrder = {
		CommoditiesTable : {
            xl: 1,
            lg: 1,
            md: 1, 
            sm: 1, 
            xs: 1 
        },
        MtlEngTable : {
            xl: 2,
            lg: 2,
            md: 2, 
            sm: 2, 
            xs: 2 
        },
        PartSelectionTable : {
            xl: 4,
            lg: 4,
            md: 4, 
            sm: 4, 
            xs: 4
        },
        ItemTable : {
            xl: 3,
            lg: 3,
            md: 3, 
            sm: 3, 
            xs: 3 
		},
		ButtonGroup : {
            xl: 5,
            lg: 5,
            md: 5, 
            sm: 5, 
            xs: 5 
		},

    }
	const handleOrgChange = (value) => {
		curOrg = value
		fetchData({ 
			url: `${APIURL}MtlEngInfo/mtlEngineersInfoList/${curOrg}`, headers: headers
		}, updateData)
	}
	const handleReset = () => {
		dispatch(mtlEngActions.resetTableData())

	}
	useEffect(() => {
		fetchData({ 
			url: `${APIURL}MtlEngInfo/mtlEngineersInfoList/${userInfo.org.primaryOrg}`, headers: headers
		}, updateData)
		fetchData2({ 
			url: `${APIURL}MtlEngInfo/userOrgsList`, headers: headers
		}, updateOrg)
	}, [])
	return (
		<React.StrictMode>
			<Fragment>
				<FullScreenLoadingIndicator loading={isLoading} />
				<Box 
					maxWidth={"95%"} 
					mx={"auto"} 
					mt={0}>
					
				</Box>
				<Box 
					maxWidth={"85%"} 
					mx={"auto"} 
					mt={0}>
					<Grid container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
						wrap="wrap"
						sx={{mb:0}}>
						<Grid 
							item 
							xl={7} lg={7} md={12} sm={12} xs={12}
							order={gridOrder.CommoditiesTable}>
								<Paper elevation = {1} sx={{ p: 0.7, height: "100%" }}>
									<Box  sx = {{alignItems:"center" , display : "flex"}}>
									<Typography variant="h6">Commodities</Typography>
									<Button sx={{mx:1, my:0 , minHeight: "15px" , maxHeight:"29px"}} onClick = {handleReset}><RestartAltIcon fontSize = {"small"} />Reset</Button>
									<DropdownFilter 
										width={80}
										height={30}
										selections={orgList}
										selectedData={curOrg}
										handleSelection={handleOrgChange}
										highlightItemList={userInfo.org.primaryOrg}
										highlightColor="green"				
									/>
									
									</Box>
									<CommoditiesTable 
										isCommodityRowClicked = {true}
										maxtableHeight = {maxtableHeight}
										height = {height}
										autoTableHeight = {autoTableHeight}
										org={curOrg}
										/>
								</Paper>
								
						</Grid>
						<Grid 
							item 
							xl={5} lg={5} md={12} sm={12} xs={12}
							order={gridOrder.MtlEngTable}>
								<Paper elevation = {1} sx={{ p: 0.7, height: "100%" }}>
									<Typography variant="h6">Material Engineer</Typography>
									<MtlEngTable 
										isCommodityRowClicked = {true}
										maxtableHeight = {maxtableHeight}
										height = {height}
										autoTableHeight = {autoTableHeight}
										org={curOrg}

										/>
								</Paper>
								
						</Grid>
						<Grid 
							item 
							xl={7} lg={7} md={12} sm={12} xs={12}
							order={gridOrder.ItemTable}>
								
								<Paper elevation = {1} sx={{ p: 0.7, height: "100%" , pt : 0 }}>
									<Typography variant="h6">Items</Typography>
									<ItemTable 
										isCommodityRowClicked = {true}
										maxtableHeight = {maxtableHeight}
										height = {height}
										autoTableHeight = {autoTableHeight}
										org={curOrg}

										/>
								</Paper>
						</Grid>
						<Grid 
							item 
							xl={5} lg={5} md={12} sm={12} xs={12}
							order={gridOrder.PartSelectionTable}>
								<Paper elevation = {1} sx={{ p: 0.7, height: "100%" , pt : 0}}>
									<Typography variant="h6" >Part Selection Guide</Typography>
									<PartSelectionTable 
										isCommodityRowClicked = {true}
										maxtableHeight = {maxtableHeight}
										height = {height}
										autoTableHeight = {autoTableHeight}
										org={curOrg}

										/>
								</Paper>
								
						</Grid>
					</Grid>
	
				</Box>
				<BottomNavigation />
			</Fragment>
		</React.StrictMode>
		
	)
}

export default Mtl