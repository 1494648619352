import cryptoJs from "crypto-js";

export var JsonFormatter = {
	stringify: function (cipherParams) {
		// create json object with ciphertext
		var jsonObj = { ct: cipherParams.ciphertext.toString(cryptoJs.enc.Base64) };

		// optionally add iv or salt
		if (cipherParams.iv) {
			jsonObj.iv = cipherParams.iv.toString();
		}

		if (cipherParams.salt) {
			jsonObj.s = cipherParams.salt.toString();
		}

		// stringify json object
		return JSON.stringify(jsonObj);
	},
	parse: function (jsonStr) {
		// parse json string
		var jsonObj = JSON.parse(jsonStr);

		// extract ciphertext from json object, and create cipher params object
		var cipherParams = cryptoJs.lib.CipherParams.create({
			ciphertext: cryptoJs.enc.Base64.parse(jsonObj.ct),
		});

		// optionally extract iv or salt

		if (jsonObj.iv) {
			cipherParams.iv = cryptoJs.enc.Hex.parse(jsonObj.iv);
		}

		if (jsonObj.s) {
			cipherParams.salt = cryptoJs.enc.Hex.parse(jsonObj.s);
		}

		return cipherParams;
	},
};

export const Decrypt_Local_Storage = (param) => {
	try {
		return cryptoJs.AES.decrypt(localStorage.getItem(param), MLAR_SECRET, {
			format: JsonFormatter,
		}).toString(cryptoJs.enc.Utf8);
	} catch (error) {
		console.log(`Error when decrypting ${param}`);
	}
};

export const Decrypt_Value = (param) => {
	try {
		return cryptoJs.AES.decrypt(param, MLAR_SECRET, {
			format: JsonFormatter,
		}).toString(cryptoJs.enc.Utf8);
	} catch (error) {
		console.log(`Error when decrypting ${param}`);
	}
};

export const Encrypt_Local_Storage = (param) => {
	return cryptoJs.AES.encrypt(param, MLAR_SECRET, {
		format: JsonFormatter,
	});
};

export const MLAR_SECRET = "MLAR_SSO_ENCRYPTION";
