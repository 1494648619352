// Packages Import
import { useState } from "react";
import {
	Box,
	Paper,
	Tooltip,
	Typography,
	Button,
	Modal,
	Stack,
	Grid,
	FormControlLabel,
	Checkbox,
	FormControl,
	RadioGroup,
	Radio,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Project Component
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
import { APIURL, OSAltKey, isEmptyObject, storePartHistories } from "../../../Util/Util";
import SetFileName from "../../../Util/SetFileName";
import AutocompleteDropdown from "../../../UI/AutocompleteDropdown";
import { subsetReloadPage } from "../../Subset/CreateSubset";
import { useLocation } from "react-router-dom";
import useDownloadFileWithRename from "../../../../Hooks/use-http-excel-r";

const PcRequestMlarReport = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.reportsState.reports.mlar.pcRequest);
	const showPopup = useSelector((state) => state.reportsState.popUpStates.mlar.pcRequest);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const handleError = typeof props.onError === "function" ? props.onError : () => {};
	const handleSuccess = typeof props.onSuccess === "function" ? props.onSuccess : () => {};
	const { downloadExcelFile } = useDownloadFileWithRename();

	const { pathname: urlPathName } = useLocation();

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const generateReport = () => {
		setLoading(true);
		closeHandler();
		const jsonReq = {
			userid: userInfo.id,
			excelSubsetReport: data.export,
			mtlName: data.checkName === true ? data.mtlName.id : "",
		};
		downloadExcelFile(
			{
				url: `${APIURL}reports/nonF01PcReqReport`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(blob, filename) => {
				setBlob(blob);
				setFileName(filename);
				setOpenSetFileName(true);
			},
			(error) => {
				handleError(error.toString());
			},
			() => {
				setLoading(false);
			}
		);
	};

	const loadSubset = (subsetData) => {
		window.sessionStorage.setItem("subsetLoaded", JSON.stringify("SUBSET"));
		const loadedSubset = subsetData[0];
		storePartHistories(loadedSubset.partnumber[0]);
		window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(loadedSubset.partnumber));
		window.sessionStorage.setItem("subsetUseItemOrder", true);
		window.sessionStorage.setItem("subsetLoaded", true);
		window.sessionStorage.setItem("subsetName", JSON.stringify(`${loadedSubset.subsetid}`));
		subsetReloadPage(urlPathName, dispatch);
	};

	const createSubset = (partNums, setLoading) => {
		const today = moment();
		const subsetId = `subset_nonf01pcrequest_${today.format("YYYYMMDDHHmmss")}`;
		const jsonReq = {
			userid: userInfo.id,
			subsetid: subsetId,
			partnumber: partNums,
			createdby: "REPORTS",
			updatedby: "REPORTS",
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}subset/editSubset`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(retData) => {
				handleSuccess(`Successfully Exported to ${subsetId}`, () => {
					loadSubset(retData.Subset_Info);
				});
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				handleError(error.toString());
			}
		);
	};

	const handleSubset = () => {
		setLoading(true);
		closeHandler(true);
		const mtlName = data.mtlName.label ?? "";
		const jsonReq = {
			userid: userInfo.id,
			excelSubsetReport: data.export,
			mtlName: data.checkName === true ? data.mtlName.id : "",
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}reports/nonF01PcReqReport`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(data) => {
				if (data?.No_Record) {
					throw Error(data.No_Record);
				}
				if (Array.isArray(data.partnumber) && data.partnumber.length < 1) {
					throw Error(`No Parts found with non-F01 Pref Code change request that require F01 ME action`);
					// throw Error(`No Part Number found for ${mtlName}`);
				}
				createSubset(data.partnumber, setLoading);
			},
			(error) => {
				setLoading(false);
				handleError(error.toString());
			}
		);
	};

	const handleCheckbox = (event) => {
		dispatch(reportsActions.setError({ data: { mtlName: event.target.checked } }));
		dispatch(
			reportsActions.updateData({
				data: {
					checkName: event.target.checked,
					mtlName: {
						id: "",
						label: "",
					},
				},
			})
		);
	};
	const handleSelection = (val, type) => {
		if (val === null) {
			dispatch(reportsActions.setError({ data: { mtlName: true } }));
		} else {
			dispatch(reportsActions.updateData({ data: { mtlNameTyped: val, mtlName: val } }));
			dispatch(reportsActions.setError({ data: { mtlName: false } }));
		}
	};
	const handleInputChange = (val, type) => {
		if (data.mtlNameList.length > 0 && !isEmptyObject(data.mtlNameList[0])) {
			let userid = "";
			let isExist;
			isExist = data.mtlNameList.findIndex((singleData) => {
				if (singleData.label === val) {
					userid = singleData.id;
					return true;
				}
				return false;
			});
			if (isExist > -1) {
				dispatch(reportsActions.updateData({ data: { mtlNameTyped: val, mtlName: { id: userid, label: val } } }));
				dispatch(reportsActions.setError({ data: { mtlName: false } }));
			} else {
				dispatch(reportsActions.updateData({ data: { mtlNameTyped: val } }));
				dispatch(reportsActions.setError({ data: { mtlName: true } }));
			}
		}
	};

	const handleRadioPdfSubset = (event) => {
		const val = event.target.value;
		let createSubset = false;
		if (val === "subset") {
			createSubset = true;
			dispatch(
				reportsActions.updateData({
					data: {
						checkNoteAction: true,
					},
				})
			);
		}
		dispatch(
			reportsActions.updateData({
				data: {
					export: val,
					createSubset: createSubset,
				},
			})
		);
	};
	const closeHandler = () => {
		dispatch(reportsActions.updatePopUpState());
		dispatch(reportsActions.reset());
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName
				blob={blob}
				setOpenSetFileName={setOpenSetFileName}
				openSetFileName={openSetFileName}
				fileName={fileName}
			/>
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								closeHandler();
							}
							if (!data.error.mtlName) {
								if (e.code === "KeyP" && data.export === "excel") {
									generateReport();
								}
								if (e.code === "KeyS" && data.export === "subset") {
									handleSubset();
								}
							}
						}
					}}
				>
					<Stack mb={2} spacing={1}>
						<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
							<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
								PC Requests
							</Typography>
						</Box>
						<Paper sx={{ p: 2 }} elevation={5}>
							<Typography variant="subtitle">
								{`Select a material engineer to export their PC request or uncheck to export all PC request to excel. Check the "Export to Subset" circle to 
								create a subset for it.`}
							</Typography>
						</Paper>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								p: 0,
								pt: 1,
							}}
						>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControl>
									<RadioGroup row onChange={handleRadioPdfSubset} value={data.export}>
										<FormControlLabel value="excel" control={<Radio />} label="Export to Excel" />
										<FormControlLabel value="subset" control={<Radio />} label="Export to Subset" />
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
								<FormControlLabel
									label="Choose MTL Name or uncheck for ALL MTL Name"
									control={<Checkbox checked={data.checkName} />}
									onChange={handleCheckbox}
								/>
							</Grid>
							<Grid
								item
								xl={7}
								lg={7}
								md={12}
								sm={12}
								xs={12}
								sx={{
									pt: 1,
								}}
							>
								<AutocompleteDropdown
									label="Choose MTL Name"
									selections={data.mtlNameList}
									value={data.mtlName}
									handleSelection={handleSelection}
									handleInputChange={handleInputChange}
									name="name"
									disabled={!data.checkName}
									freeSolo={true}
									error={data.error.mtlName}
									extraProps={{
										ListboxProps: {
											sx: {
												"& .MuiAutocomplete-option.Mui-focused": {
													backgroundColor: "rgba(230, 230, 250, 0.8)",
												},
											},
										},
									}}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								pt: 2,
							}}
						>
							<Grid
								item
								xl={6.1}
								lg={6.1}
								md={6.1}
								sm={6.1}
								xs={6.1}
								sx={{
									pr: 1,
								}}
							>
								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
								{data.export === "excel" ? (
									<Tooltip title={`For shortcut press ${OSAltKey} + P`}>
										<span>
											<Button sx={{ width: "100%", ml: 0 }} onClick={generateReport} disabled={data.error.mtlName}>
												<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>P</u>rint Excel
											</Button>
										</span>
									</Tooltip>
								) : (
									<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
										<span>
											<Button sx={{ width: "100%", ml: 0 }} onClick={handleSubset} disabled={data.error.mtlName}>
												Create&nbsp;<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ubset
											</Button>
										</span>
									</Tooltip>
								)}
							</Grid>
						</Grid>
						<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>{" "}
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default PcRequestMlarReport;
