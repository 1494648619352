import { Box, Modal, Stack, Button } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const ConfirmationBox = (props) => {
	return (
		<Box
			onKeyDown={(e) => {
				if (e.code === "KeyY") {
					props.handleYes();
				} else if (e.code === "KeyN") {
					props.handleNo();
				}
			}}
		>
			<Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box sx={style}>
					<Stack mb={2} spacing={2}>
						<div style={{ whiteSpace: "pre-wrap" }}>{props.message}</div>
					</Stack>
					<Button onClick={props.handleYes}>
						<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>Y</u>es
					</Button>
					<Button onClick={props.handleNo}>
						<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>N</u>o
					</Button>
				</Box>
			</Modal>
		</Box>
	);
};

export default ConfirmationBox;
