// React
import { useSelector, useDispatch } from "react-redux";
import { infoAction } from "../../../../store/Info/info-slice";

// UI
import { Modal, Box, AppBar, Toolbar, Typography, Button, Stack, Tooltip } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import UserOrgDropdown from "../../../UI/UserOrgDropdown";

import { OSAltKey } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: "80%", md: "80%", lg: "80%", xl: "70%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const AcceptAndQupdatePart = (props) => {
	const dispatch = useDispatch();
	const { note, acceptPartDisabled, qUpdateDisabled, noteBackgroundColor, prefCodeStartsWith4 } = useSelector((state) => state.infoStates.orgNote);

	const disableAcceptButton = acceptPartDisabled || prefCodeStartsWith4;
	const disableQUpdateButton = qUpdateDisabled || prefCodeStartsWith4;

	const selectedNoteMultiOrg = useSelector((state) => state.infoStates.selectedNoteMultiOrg);

	const handleOrgSelected = (org) => {
		dispatch(infoAction.changeOrgNote(org));
	};

	return (
		<Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
			<Box
				sx={style}
				onKeyDown={(e) => {
					if (e.code === "KeyA" && e.altKey === true && !disableAcceptButton) {
						e.preventDefault();
						props.acceptPart();
					} else if (e.code === "KeyQ" && e.altKey === true && !disableQUpdateButton) {
						e.preventDefault();
						props.qUpdate();
					} else if (e.code === "KeyC" && e.altKey === true) {
						e.preventDefault();
						props.handleShowAcceptQupdate();
					}
				}}
			>
				<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
					<Toolbar>
						<Typography variant="h6" component="div" color="#fff">
							{"Accept / Qupdate Part Note"}
						</Typography>
					</Toolbar>
				</AppBar>

				<Stack mt={7} mb={3} spacing={2}>
					<UserOrgDropdown label={"Select Org"} selectedOrg={selectedNoteMultiOrg} handleOrgSelected={handleOrgSelected} />
					<ColoredTextField
						backgroundColor={noteBackgroundColor}
						focused
						id="outlined-multiline-static"
						label="Part Note"
						fullWidth
						minRows={2}
						multiline
						inputProps={{ readOnly: true }}
						InputProps={{ sx: { marginTop: "2px" } }}
						value={note}
					/>
				</Stack>

				<Box>
					<Tooltip title={`For shortcut press ${OSAltKey} + A`}>
						<span>
							<Button disabled={disableAcceptButton} onClick={props.acceptPart}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>A</u>ccept Part
							</Button>
						</span>
					</Tooltip>
					<Tooltip title={`For shortcut press ${OSAltKey} + Q`}>
						<span>
							<Button disabled={disableQUpdateButton} onClick={props.qUpdate}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>Q</u>update
							</Button>
						</span>
					</Tooltip>
					<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
						<Button onClick={() => props.handleShowAcceptQupdate()}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
						</Button>
					</Tooltip>
					<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
				</Box>
			</Box>
		</Modal>
	);
};

export default AcceptAndQupdatePart;
