// React
import { Fragment, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findPartAction } from "../../../../store/common/findPart-slice";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";

// UI
import { Button, Box, MenuItem, Tooltip, IconButton, Typography, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import FindPart from "../../../Util/FindPart";
import Icat from "../../../Layout/icat/icat";

// Project
import BottomBarNavigation from "../../../Layout/BottomBarNavigation";
import Mousetrap from "mousetrap";
import { OpenCDS } from "../../../Util/Util";

const RoHSBottomNavigation = (props) => {
	const dispatch = useDispatch();
	const selectedHistory = useSelector((state) => state.rohsStates.partInfo.partNumber) || "";
	const prefCode = useSelector((state) => state.rohsStates.partInfo.prefCode);
	const runReportAutocomplete = useSelector((state) => state.rohsStates.runReport.autoComplete)
	const { allOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);

	const userIsF01 = allOrg.includes("F01");
	const disableICat = props.nonMtlEngAccess || !selectedHistory || !userIsF01;

	const [anchorElNav, setAnchorElNav] = useState(null);
	const [openIcat, setOpenIcat] = useState(false);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const HandleCdsClicked = () => {
		handleCloseNavMenu();
		OpenCDS(selectedHistory);
	};

	const HandleFindClicked = useCallback(() => {
		handleCloseNavMenu();
		dispatch(findPartAction.setPartFound(true));
		dispatch(findPartAction.setShowFindPart(true));
	}, [dispatch]);

	const HandleAddDocClicked = () => {
		handleCloseNavMenu();
		// Trigger file input from Add Document button in Link Document table
		props.hiddenFileInputRef.current.click();
	};

	const handleOpenIcat = () => {
		setOpenIcat(!openIcat);
	};

	const handleRunReportClicked = () => {
		// Get the input from localstorage so users does not need to retype the values in the future
		let storageAutocomplete = {}
		Object.keys(runReportAutocomplete).forEach((value) => {
			const localStorageKey = `RohsRunReport.${value}`
			let localStorageValue = []
			try{
				const parsedArray = JSON.parse(window.localStorage.getItem(localStorageKey))
				localStorageValue = Array.isArray(parsedArray) ? parsedArray : [];
			}catch(e){
				localStorageValue = [];
			}
			const unique = [...new Set(localStorageValue)]
			storageAutocomplete[value] = unique
		});
		dispatch(RoHSAction.setAutoComplete(storageAutocomplete))
		dispatch(RoHSAction.setOpenRunReport());
	};

	useEffect(() => {
		Mousetrap.bind("ctrl+shift+f", (e) => {
			e.preventDefault();
			HandleFindClicked();
		});
		return () => Mousetrap.unbind("ctrl+shift+f");
	}, [HandleFindClicked]);

	return (
		<Fragment>
			<Icat open={openIcat} handleOpenIcat={handleOpenIcat} partNumber={selectedHistory} prefCode={prefCode} />

			<Box
				position={"fixed"}
				bottom={0}
				width={"100%"}
				display="flex"
				alignItems={"center"}
				bgcolor={"#EBEBEB"}
				zIndex={1100}
			>
				<Box m={2} sx={{ mx: "auto" }}>
					<Box sx={{ display: "flex" }}>
						<BottomBarNavigation changePart={props.changePart} selectedHistory={selectedHistory} />

						<Box pl={3} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}>
							<Button variant="contained" onClick={HandleCdsClicked}>
								CDS...
							</Button>
							<Tooltip title="Open Find Window (CTRL + Shift + F)">
								<span>
									<Button variant="contained" onClick={HandleFindClicked}>
										Find
									</Button>
								</span>
							</Tooltip>
							<Button variant="contained" disabled={props.nonMtlEngAccess} onClick={handleRunReportClicked}>
								Run report/update
							</Button>
							<Tooltip title={!selectedHistory ? "Find a part to set new iCat Code" : ""}>
								<span>
									<Button variant="contained" onClick={handleOpenIcat} disabled={disableICat}>
										New iCAT req
									</Button>
								</span>
							</Tooltip>
							<Button variant="contained" disabled={props.nonMtlEngAccess} onClick={HandleAddDocClicked}>
								Add document
							</Button>
						</Box>

						<Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" } }}>
							<IconButton
								size="Medium"
								aria-label="Footer buttons"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								{anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								disableScrollLock
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
								}}
							>
								<MenuItem key={"CDS..."} onClick={HandleCdsClicked}>
									<Typography textAlign="center">{"CDS..."}</Typography>
								</MenuItem>
								<MenuItem key={"Find"} onClick={HandleFindClicked}>
									<Typography textAlign="center">{"Find"}</Typography>
								</MenuItem>
								<MenuItem key={"Run report/update"} disabled={props.nonMtlEngAccess} onClick={handleRunReportClicked}>
									<Typography textAlign="center">{"Run report/update"}</Typography>
								</MenuItem>
								<MenuItem key={"New iCAT req"} onClick={handleOpenIcat} disabled={disableICat}>
									<Typography textAlign="center">{"New iCAT req"}</Typography>
								</MenuItem>
								<MenuItem key={"Add document"} onClick={HandleAddDocClicked} disabled={props.nonMtlEngAccess}>
									<Typography textAlign="center">{"Add document"}</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
			<FindPart changePart={props.changePart} />
		</Fragment>
	);
};

export default RoHSBottomNavigation;
