// React
import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// UI
import {
	Box,
	Modal,
	Typography,
	Grid,
	FormControlLabel,
	Checkbox,
	Tooltip,
	Button,
	Paper,
	Stack,
	Autocomplete,
	IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SetFileName from "../../../Util/SetFileName";
import DropdownFilter from "../../../UI/DropdownFilter";
import ErrorBox from "../../../Util/ErrorBox";
import ControlledMessageBox from "../../../Util/ControlledMessagebox";
// Packages Import
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";
import useHttp from "../../../../Hooks/use-http";
import useDownloadFileWithRename from "../../../../Hooks/use-http-excel-r";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import {
	OSAltKey,
	APIURL,
	PDF_GEN_JWT_TOKEN_URL,
	PDF_GEN_URL,
	convertTrueFalse,
	formatDate,
	storePartHistories,
} from "../../../Util/Util";
import ColoredTextField from "../../../UI/ColoredTextField";
import MtlNote from "./BatchPopUp/MtlNote";
import RohsNote from "./BatchPopUp/RohsNote";
import OpTemp from "./BatchPopUp/OpTemp";
import SetSubsetName from "../../../Util/SetSubsetName";
import MessageBox from "../../../Util/MessageBox";
import { subsetReloadPage } from "../../../Menu/Subset/CreateSubset";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: 650 },
	maxHeight: "98%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "auto",
	pt: 2,
	pb: 1,
	pl: { xs: 1, sm: 4 },
	pr: { xs: 1, sm: 4 },
};
let infoHandler = () => {};
const MAX_AUTOCOMPLETE_LIST = 50;
const RunReport = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchData, isLoading } = useHttp();
	const { sendRequest: fetchData2, isLoading: isLoading2 } = useHttp();
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const { downloadExcelFile } = useDownloadFileWithRename();
	const { pathname: urlPathName } = useLocation();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(
		() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }),
		[accessToken]
	);

	const [loading, setLoading] = useState(false);

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [openMessageBox, setOpenMessageBox] = useState(false);
	const [message, setMessage] = useState("");
	const [infoMsg, setInfoMsg] = useState("");
	const [infoState, setInfoState] = useState(false);

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	// Set Open Subset Rename
	const [openSubsetRename, setOpenSubsetRename] = useState(false);
	const [subsetValue, setSubsetValue] = useState(`myrohs_${formatDate(new Date(), "", true)}`);

	const showPopup = useSelector((state) => state.rohsStates.openRunReport);
	const data = useSelector((state) => state.rohsStates.runReport);
	const mfgNameRef = useRef(null);
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
		org: useSelector((state) => state.authenticationState.userOrg),
	};
	const disableMfg_pn_contain =
		data.prefix?.trim()?.length === 0 && data.contains?.trim()?.length === 0 && data.sufix?.trim()?.length === 0;
	const title = data.whereUsed
		? "Where used report"
		: data.batchAddMTL
		? "Batch change notes/PC codes"
		: data.batchAddUpdateRoHS
		? "Batch change RoHS notes"
		: data.batchUpdateOpTemp
		? "Batch change Operating Tempreature"
		: data.createMLARSubset
		? "Create SUBSET"
		: data.excelPdfReport === false
		? "Export PDF RoHS report"
		: data.excelPdfReport === true
		? "Export Excel RoHS report"
		: "";

	useEffect(() => {
		if (showPopup) {
			fetchData(
				{ url: `${APIURL}chgRequest/categoryList`, headers },
				(data) => {
					const icatList = data?.CATEGORIES_LIST
						? data.CATEGORIES_LIST.map((data) => {
								return {
									icat: data.icat,
									icat_id: data.icat_id,
								};
						  })
						: [];
					dispatch(RoHSAction.setIcatOptions(icatList.sort((a, b) => a.icat.localeCompare(b.icat))));
				},
				(error) => {
					console.log(`ERROR: Unable to Retrieve iCat list, ${error}`);
					setErrorMsg(`ERROR: Unable to Retrieve iCategory list, please re-open this popup and try again. ${error}`);
					setErrorState(true);
				}
			);
			fetchData2(
				{
					url: `${APIURL}MtlEngInfo/userOrgsList`,
					headers: headers,
				},
				(data) => {
					const orgList = data.USER_ORGS_LIST.map((org) => {
						return org.user_org_c;
					});
					dispatch(
						RoHSAction.updateData({
							data: {
								orgList: orgList,
							},
						})
					);
				}
			);
		}
	}, [dispatch, fetchData, fetchData2, headers, showPopup]);

	const onError = (errMsg) => {
		setErrorMsg(errMsg);
		setErrorState(true);
	};
	const infoBoxHandler = () => {
		setInfoState(false);
		setInfoMsg("");
		infoHandler();
		infoHandler = () => {};
	};
	const handleInfoBox = (info, handler) => {
		setInfoState(true);
		setInfoMsg(info);
		if (typeof handler === "function") {
			infoHandler = handler;
		}
	};
	const closeHandler = (ignoreReset = []) => {
		dispatch(RoHSAction.setOpenRunReport());
		dispatch(RoHSAction.resetReportData({ ignore: ignoreReset }));
	};

	const handleIcatLookupChanged = (event, newValue) => {
		dispatch(
			RoHSAction.updateData({
				data: {
					iCatLookup: newValue,
				},
			})
		);
	};

	const mfgNameOnBlur = () => {
		if (data.mfgName?.trim() && data.mfgName?.trim()?.length < 3) {
			setMessage(`Please enter minimum 3 characters`);
			setOpenMessageBox(true);

			setTimeout(() => {
				setMessage("");
				setOpenMessageBox(false);
				mfgNameRef.current.focus();
			}, 1000);
		}
	};

	const handleInputChange = (option, value) => {
		let update = {};
		switch (option) {
			case "mfgName":
				update = { mfgName: value };
				break;
			case "prefix":
				update = { prefix: value };
				break;
			case "contains":
				update = { contains: value };
				break;
			case "sufix":
				update = { sufix: value };
				break;
			case "mfg_pn_contain":
				update = { mfg_pn_contain: value };
				break;
			case "partPrefix":
				update = { partPrefix: value };
				break;
			default:
				break;
		}

		dispatch(RoHSAction.updateData({ data: { ...update } }));
	};

	const handleRemoveAutocompleteFromDropdown = (field, value) => {
		const localStorageKey = `RohsRunReport.${field}`;
		const filteredData = data.autoComplete[field].filter((val) => !(val === value));
		window.localStorage.setItem(localStorageKey, JSON.stringify(filteredData));
		dispatch(RoHSAction.removeAutoComplete({ key: field, valueToRemove: value }));
	};
	const handleSortChange = (value) => {
		const indexVal = data.sortByList.findIndex((sortBy) => {
			return sortBy === value;
		});
		dispatch(
			RoHSAction.updateData({
				data: {
					sortBy: indexVal + 1,
				},
			})
		);
	};

	const handleReportScopeChanged = (value) => {
		const indexVal = data.sortByList.findIndex((sortBy) => {
			return sortBy === value;
		});
		dispatch(
			RoHSAction.updateData({
				data: {
					sortBy: indexVal + 1,
				},
			})
		);
	};

	const handleCheckboxOptions = (option) => {
		let update = {};
		switch (option) {
			case "excelPdfReport":
				if (data.excelPdfReport) {
					update = { excelPdfReport: false };
				} else {
					update = {
						excelPdfReport: true,
						batchAddMTL: false,
						batchAddUpdateRoHS: false,
						batchUpdateOpTemp: false,
						createMLARSubset: false,
					};
				}
				break;

			case "whereUsed":
				if (data.whereUsed) {
					update = { whereUsed: false, excelPdfReport: true };
				} else {
					update = {
						whereUsed: true,
						batchAddMTL: false,
						batchAddUpdateRoHS: false,
						batchUpdateOpTemp: false,
						createMLARSubset: false,
					};
				}
				break;
			case "batchAddMTL":
				if (data.batchAddMTL) {
					update = { batchAddMTL: false, excelPdfReport: false };
				} else {
					update = {
						batchAddMTL: true,
						excelPdfReport: false,
						batchAddUpdateRoHS: false,
						batchUpdateOpTemp: false,
						createMLARSubset: false,
					};
				}
				break;
			case "batchAddUpdateRoHS":
				if (data.batchAddUpdateRoHS) {
					update = { batchAddUpdateRoHS: false, excelPdfReport: false };
				} else {
					update = {
						batchAddUpdateRoHS: true,
						excelPdfReport: false,
						batchAddMTL: false,
						batchUpdateOpTemp: false,
						createMLARSubset: false,
					};
				}
				break;
			case "batchUpdateOpTemp":
				if (data.batchUpdateOpTemp) {
					update = { batchUpdateOpTemp: false, excelPdfReport: false };
				} else {
					update = {
						batchUpdateOpTemp: true,
						excelPdfReport: false,
						batchAddMTL: false,
						batchAddUpdateRoHS: false,
						createMLARSubset: false,
					};
				}
				break;
			case "createMLARSubset":
				if (data.createMLARSubset) {
					update = { createMLARSubset: false, excelPdfReport: false };
				} else {
					update = {
						createMLARSubset: true,
						excelPdfReport: false,
						batchAddMTL: false,
						batchAddUpdateRoHS: false,
						batchUpdateOpTemp: false,
					};
				}
				break;
			default:
				break;
		}
		dispatch(RoHSAction.updateData({ data: { ...update } }));
	};

	const handleJWTToken = (token, data) => {
		let filename = data.filename;
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				return response.blob();
			})
			.then((data) => {
				const url = window.URL.createObjectURL(data);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename);
				document.body.appendChild(link);
				link.click();
				link.remove();
				setLoading(false);
			})
			.catch((error) => {
				onError(error.toString());
				setLoading(false);
			});
	};
	// PDF and Excel
	const generateReport = () => {
		// Save the input from the fields so users does not need to retype the values in the future
		Object.keys(data.autoComplete).forEach((value) => {
			const localStorageKey = `RohsRunReport.${value}`;
			// try {
			// 	const parsedArray = JSON.parse(window.localStorage.getItem(localStorageKey));
			// 	localStorageValue = Array.isArray(parsedArray) ? parsedArray : [];
			// } catch (e) {
			// 	localStorageValue = [];
			// }
			// if (data[value] !== "") {
			// 	localStorageValue.unshift(data[value]);
			// }
			const localStorageValue =
				data[value] !== "" ? [data[value], ...data.autoComplete[value]] : data.autoComplete[value];

			// localStorageValue = localStorageValue.filter(e =>  e)
			const unique = [...new Set(localStorageValue)];
			if (unique.length > MAX_AUTOCOMPLETE_LIST) {
				unique.pop();
			}
			window.localStorage.setItem(localStorageKey, JSON.stringify(unique));
		});

		if (data.batchAddUpdateRoHS === true || data.batchAddMTL === true || data.batchUpdateOpTemp === true) {
			closeHandler(["orgList", "userOrg"]);
		} else {
			closeHandler();
		}
		setLoading(true);
		const jsonReq = {
			userid: userInfo.id,
			userOrg: userInfo.org,
			strmfgname: data.mfgName,
			strprefix: data.prefix,
			strsufix: data.sufix,
			strcontain: data.contains,
			strnocontain: data.mfg_pn_contain,
			stritemprefix: data.partPrefix,
			nicat_id: !data.iCatLookup.icat_id ? "" : data.iCatLookup.icat_id.toString(),
			checkWhereUsed: convertTrueFalse(data.whereUsed),
			batchnoterohs_addupd: convertTrueFalse(data.batchAddUpdateRoHS),
			batchnotemtl_addupd: convertTrueFalse(data.batchAddMTL),
			batchoptemp_addupd: convertTrueFalse(data.batchUpdateOpTemp),
			reportscope: data.reportScope.toString(),
			sortby: data.sortBy.toString(),
			excelPdfReport: data.createMLARSubset === true ? "" : data.excelPdfReport === true ? "excel" : "pdf",
			createsubset: convertTrueFalse(data.createMLARSubset),
		};

		if (data.createMLARSubset === true) {
			fetchData(
				{
					url: `${APIURL}Rohs/rohsmfgreport`,
					headers: headers,
					method: "POST",
					body: jsonReq,
				},
				(returnedData) => {
					if (returnedData?.No_Record) {
						throw Error(returnedData.No_Record);
					}
					if (returnedData?.partnumber) {
						dispatch(
							RoHSAction.updateData({
								data: {
									subsetPartNumber: returnedData.partnumber,
								},
							})
						);
						setOpenSubsetRename(true);
					} else {
						throw Error("Error in retrieving records");
					}

					setLoading(false);
				},
				(error) => {
					setLoading(false);
					onError(error.toString());
				}
			);
		} else {
			if (jsonReq.excelPdfReport === "excel") {
				downloadExcelFile(
					{
						url: `${APIURL}Rohs/rohsmfgreport`,
						headers: headers,
						method: "POST",
						body: jsonReq,
					},
					(blob, filename) => {
						setBlob(blob);
						setFileName(filename);
						setOpenSetFileName(true);
					},
					(error) => {
						onError(error.toString());
					},
					() => {
						setLoading(false);
					}
				);
			} else {
				fetchData(
					{
						url: `${APIURL}Rohs/rohsmfgreport`,
						headers: headers,
						method: "POST",
						body: jsonReq,
					},
					(returnedData) => {
						if (returnedData?.No_Record) {
							throw Error(returnedData.No_Record);
						}
						if (data.batchAddUpdateRoHS === true || data.batchAddMTL === true || data.batchUpdateOpTemp === true) {
							if (data.batchAddMTL === true) {
								fetchDataWithoutLoad(
									{ url: `${APIURL}chgRequest/prefCodeList`, headers },
									(prefCodeData) => {
										const prefCodeList = prefCodeData.PREF_CODE_LIST.map((prefCode) => {
											return {
												prefCode: prefCode.pref_code,
												description: prefCode.description,
											};
										});
										dispatch(
											RoHSAction.updateData({
												data: {
													batchData: {
														mtlNote: {
															prefCodeList: prefCodeList,
														},
													},
												},
											})
										);
									},
									(error) => {
										onError(error.toString());
									}
								);
							}
							dispatch(
								RoHSAction.updateData({
									data: {
										recordCount: returnedData.rec_count,
										transactionId: returnedData.transaction_id,
										batchType: {
											mtlNote: data.batchAddMTL,
											opTemp: data.batchUpdateOpTemp,
											rohsNote: data.batchAddUpdateRoHS,
										},
									},
								})
							);

							setLoading(false);
						} else {
							if (Array.isArray(returnedData)) {
								returnedData.forEach((singleData) => {
									fetchData(
										{
											url: PDF_GEN_JWT_TOKEN_URL,
											headers: headers,
											method: "POST",
											body: {
												username: "admin",
												password: "pass",
											},
										},
										(token) => handleJWTToken(token, singleData)
									);
								});
							} else {
								throw Error("Something Went Wrong");
							}
						}
					},
					(error) => {
						setLoading(false);
						onError(error.toString());
					}
				);
			}
		}
	};
	const loadSubset = (subsetData) => {
		window.sessionStorage.setItem("subsetLoaded", JSON.stringify("SUBSET"));
		const loadedSubset = subsetData[0];
		storePartHistories(loadedSubset.partnumber[0]);
		window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(loadedSubset.partnumber));
		window.sessionStorage.setItem("subsetUseItemOrder", true);
		window.sessionStorage.setItem("subsetLoaded", true);
		window.sessionStorage.setItem("subsetName", JSON.stringify(`${loadedSubset.subsetid}`));
		subsetReloadPage(urlPathName, dispatch);
	};
	const createSubset = () => {
		const jsonReq = {
			userid: userInfo.id,
			subsetid: subsetValue,
			partnumber: data.subsetPartNumber,
			createdby: "REPORTS",
			updatedby: "REPORTS",
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}subset/editSubset`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(retData) => {
				handleInfoBox(`Successfully Exported to ${subsetValue}`, () => {
					loadSubset(retData.Subset_Info);
				});
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				onError(error.toString);
			}
		);
	};

	const onSubsetRenameSubmit = (value) => {
		setLoading(true);
		setSubsetValue(value);
		createSubset();
	};
	const renderOptionAutocomplete = type => (props, dropdownOption) => {
		const { onClick, "data-option-index" : dataOptionIndex ,  ...otherProps } = props;
		return (
			<li
				{...otherProps}
				data-option-index={dataOptionIndex}
				onClick={undefined}
			>
				<Box
					sx={{
						display: "flex",
						width: "95%",
					}}
					onClick={onClick}
					data-option-index={dataOptionIndex}
				>
					<Typography variant={"caption"}>{`${dropdownOption}`}</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						width: "5%",
					}}
				>
					<IconButton
						size="small"
						onClick={() => handleRemoveAutocompleteFromDropdown(type, dropdownOption)}
					>
						<ClearIcon sx={{ fontSize: "15px" }} />
					</IconButton>
				</Box>
			</li>
		);
	}

	return (
		<>
			<FullScreenLoadingIndicator loading={loading || isLoading || isLoading2} />
			<SetFileName
				blob={blob}
				setOpenSetFileName={setOpenSetFileName}
				openSetFileName={openSetFileName}
				fileName={fileName}
			/>
			<SetSubsetName
				onSubmit={onSubsetRenameSubmit}
				setOpen={setOpenSubsetRename}
				open={openSubsetRename}
				defaultValue={subsetValue}
			/>
			<MtlNote onError={onError} onSuccess={handleInfoBox} />
			<RohsNote onError={onError} onSuccess={handleInfoBox} />
			<OpTemp onError={onError} onSuccess={handleInfoBox} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<ControlledMessageBox open={openMessageBox} message={message} />
			<MessageBox open={infoState} message={infoMsg} messageBoxHandler={infoBoxHandler} />
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey === true) {
							if (e.code === "KeyC") {
								closeHandler();
							}
							if (data.mfgName || data.prefix || data.contains || data.sufix || data.partPrefix || data.iCatLookup) {
								if (e.code === "KeyP") {
									generateReport();
								}
								if (e.code === "KeyC") {
									generateReport();
								}
							}
						}
					}}
				>
					<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
						<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
							MFG/MEP Info Report
						</Typography>
					</Box>
					<Paper sx={{ p: 2, mt: 2, mb: 2 }} elevation={5}>
						<Typography variant="subtitle">{title}</Typography>
					</Paper>

					<Box mb={2} sx={{ alignItems: "center", display: "flex", width: "97%", flexDirection: "column" }}>
						<Grid container direction="row" spacing={1.5} alignItems="center">
							<Grid item xl={7.5} lg={7.5} md={7.5} sm={7.5} xs={7}>
								<Autocomplete
									options={data.autoComplete.mfgName}
									value={data.mfgName}
									freeSolo
									fullWidth
									disablePortal
									onInputChange={(event, value) => {
										handleInputChange("mfgName", value?.toUpperCase());
									}}
									renderInput={(params) => (
										<ColoredTextField
											label={<b>Manufacturer Name</b>}
											autoFocus
											ref={mfgNameRef}
											onBlur={mfgNameOnBlur}
											focused
											{...params}
										/>
									)}
									renderOption={renderOptionAutocomplete("mfgName")}
								/>
							</Grid>
							<Grid item xl={4.5} lg={4.5} md={4.5} sm={4.5} xs={5}>
								<Typography sx={{ color: "#0f0fff" }}>Partial entry is acceptable</Typography>
							</Grid>

							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Stack direction={"row"} alignItems="center" spacing={1}>
									<Typography>{<b>MFG_PN</b>}</Typography>
									<Autocomplete
										options={data.autoComplete.prefix}
										value={data.prefix}
										freeSolo
										fullWidth
										disablePortal
										renderOption={renderOptionAutocomplete("prefix")}
										onInputChange={(event, value) => {
											handleInputChange("prefix", value);
										}}
										renderInput={(params) => <ColoredTextField label={"Prefix"} focused {...params} />}
									/>
									<Autocomplete
										options={data.autoComplete.contains}
										value={data.contains}
										freeSolo
										fullWidth
										disablePortal
										renderOption={renderOptionAutocomplete("contains")}
										onInputChange={(event, value) => {
											handleInputChange("contains", value);
										}}
										renderInput={(params) => <ColoredTextField label={"Contains"} focused {...params} />}
									/>
									<Autocomplete
										options={data.autoComplete.sufix}
										value={data.sufix}
										freeSolo
										fullWidth
										disablePortal
										renderOption={renderOptionAutocomplete("sufix")}
										onInputChange={(event, value) => {
											handleInputChange("sufix", value);
										}}
										renderInput={(params) => <ColoredTextField label={"Sufix"} focused {...params} />}
									/>
								</Stack>
							</Grid>

							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Box sx={{ display: "flex", justifyContent: "center" }}>
									<Typography>Additional exclusions/inclusions</Typography>
								</Box>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Autocomplete
									options={data.autoComplete.mfg_pn_contain}
									value={data.mfg_pn_contain}
									freeSolo
									fullWidth
									disabled={disableMfg_pn_contain}
									disablePortal
									renderOption={renderOptionAutocomplete("mfg_pn_contain")}
									onInputChange={(event, value) => {
										handleInputChange("mfg_pn_contain", value);
									}}
									renderInput={(params) => <ColoredTextField label={"MFG_PN Non - contain text"} focused {...params} />}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Autocomplete
									options={data.autoComplete.partPrefix}
									value={data.partPrefix}
									freeSolo
									fullWidth
									disablePortal
									renderOption={renderOptionAutocomplete("partPrefix")}
									onInputChange={(event, value) => {
										handleInputChange("partPrefix", value);
									}}
									renderInput={(params) => <ColoredTextField label={"Keysight Part prefix"} focused {...params} />}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Autocomplete
									options={data.icatOptions}
									value={data.iCatLookup}
									getOptionLabel={(option) => `${option.icat_id} | ${option.icat}`}
									renderOption={(props, option) => {
										return (
											<li {...props}>
												<Box sx={{ display: "flex" }}>
													<Typography align="right" width={"50px"}>{`${option.icat_id}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={2}>{`${option.icat}`}</Typography>
												</Box>
											</li>
										);
									}}
									renderInput={(params) => <ColoredTextField label={"Oracle Category id"} focused {...params} />}
									isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
									onChange={handleIcatLookupChanged}
								/>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<DropdownFilter
									selections={data.sortByList}
									selectedData={data.sortByList[data.sortBy - 1]}
									handleSelection={handleSortChange}
									label={"Sort"}
									fullWidth
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
									disabled={
										data.whereUsed ||
										data.batchAddMTL ||
										data.batchAddUpdateRoHS ||
										data.batchUpdateOpTemp ||
										data.createMLARSubset
									}
								/>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<DropdownFilter
									selections={data.reportScopeList}
									selectedData={data.reportScopeList[data.reportScope - 1]}
									handleSelection={handleReportScopeChanged}
									label={"Report Scope"}
									fullWidth
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
									disabled={
										data.whereUsed ||
										data.batchAddMTL ||
										data.batchAddUpdateRoHS ||
										data.batchUpdateOpTemp ||
										data.createMLARSubset
									}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" sx={{ mt: 1.5 }} alignItems="center">
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<FormControlLabel
									value="excelPdfReport"
									control={
										<Checkbox checked={data.excelPdfReport} onChange={() => handleCheckboxOptions("excelPdfReport")} />
									}
									label="Export to Excel file"
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="whereUsed"
									control={<Checkbox checked={data.whereUsed} onChange={() => handleCheckboxOptions("whereUsed")} />}
									label="Where Used (MFG MPN)"
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="batchAddMTL"
									disabled={data.whereUsed}
									control={
										<Checkbox checked={data.batchAddMTL} onChange={() => handleCheckboxOptions("batchAddMTL")} />
									}
									label={
										<Typography sx={{ color: `rgba(15, 15, 255, ${data.whereUsed === false ? "1" : "0.4"})` }}>
											Batch add MTLs notes/Pref Code request...
										</Typography>
									}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="batchAddUpdateRoHS"
									disabled={data.whereUsed}
									control={
										<Checkbox
											checked={data.batchAddUpdateRoHS}
											onChange={() => handleCheckboxOptions("batchAddUpdateRoHS")}
										/>
									}
									label={
										<Typography sx={{ color: `rgba(0, 102, 0, ${data.whereUsed === false ? "1" : "0.4"})` }}>
											Batch add/update RoHS notes...
										</Typography>
									}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="batchUpdateOpTemp"
									disabled={data.whereUsed}
									control={
										<Checkbox
											checked={data.batchUpdateOpTemp}
											onChange={() => handleCheckboxOptions("batchUpdateOpTemp")}
										/>
									}
									label={
										<Typography
											sx={{ color: `rgba(0, 0, 0, ${data.whereUsed === false ? "1" : "0.4"})`, fontWeight: 700 }}
										>
											Batch Update to Operating Temperature...
										</Typography>
									}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="createMLARSubset"
									disabled={data.whereUsed}
									control={
										<Checkbox
											checked={data.createMLARSubset}
											onChange={() => handleCheckboxOptions("createMLARSubset")}
										/>
									}
									label={
										<Typography sx={{ color: `rgba(147, 119, 10, ${data.whereUsed === false ? "1" : "0.4"})` }}>
											Create MLAR Subset file
										</Typography>
									}
								/>
							</Grid>
						</Grid>
					</Box>

					<Grid
						container
						direction="row"
						spacing={1}
						sx={{
							p: 0,
						}}
					>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<Tooltip title={`For shortcut ${OSAltKey} + C`}>
								<Button
									sx={{ width: "100%", ml: 0 }}
									onClick={() => {
										closeHandler();
									}}
								>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
								</Button>
							</Tooltip>
						</Grid>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							{!data.batchAddMTL && !data.batchAddUpdateRoHS && !data.batchUpdateOpTemp && !data.createMLARSubset ? (
								<Tooltip title={`For shortcut ${OSAltKey} + P`}>
									<span>
										<Button
											sx={{ width: "100%", ml: 0 }}
											onClick={generateReport}
											disabled={
												!data.mfgName &&
												!data.prefix &&
												!data.contains &&
												!data.sufix &&
												!data.partPrefix &&
												!data.iCatLookup
											}
										>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>P</u>rint{" "}
											{data.excelPdfReport === true ? "Excel" : "PDF"}
										</Button>
									</span>
								</Tooltip>
							) : (
								<Tooltip title={`For shortcut ${OSAltKey} + C`}>
									<span>
										<Button
											sx={{ width: "100%", ml: 0 }}
											onClick={generateReport}
											disabled={
												!data.mfgName &&
												!data.prefix &&
												!data.contains &&
												!data.sufix &&
												!data.partPrefix &&
												!data.iCatLookup
											}
										>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ontinue
										</Button>
									</span>
								</Tooltip>
							)}
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
};

export default RunReport;
