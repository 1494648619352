// React
import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { findPartAction } from "../../../store/common/findPart-slice";
import { wuBomAction } from "../../../store/WuBom/WuBom-slice";
// UI
import { FormControl, InputLabel, Select, Tooltip, Grid, MenuItem } from "@mui/material";
import ColoredTextField from "../../UI/ColoredTextField";
import CardWrapper from "../../UI/Card";
import ParentTable from "./Table/ParentTable";
import ComponentTable from "./Table/ComponentTable";
import WuBomBottomNavigation from "./Footer/WuBomBottomBar";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../Util/ErrorBox";
import PrintBom from "./Print/PrintBOM";
import PrintWhereUse from "./Print/PrintWhereUse";
import PrintWhereUsePTO from "./Print/PrintWhereUsePTO";
// Projects
import { orgList } from "../../Util/Util";
import { APIURL, API_GENERAL_ERROR_MSG } from "../../Util/Util";
import useHttp from "../../../Hooks/use-http";

const WuBom = () => {
	const dispatch = useDispatch();
	const org = useSelector((state) => state.wuBomState.org);
	const availableOrgList = useSelector((state) => state.wuBomState.availableOrgList);
	const partNumber = useSelector((state) => state.wuBomState.partNumber);
	const partDesc = useSelector((state) => state.wuBomState.partDescription);
	const reloadPartNumber = useSelector((state) => state.wuBomState.reloadPartNumber);
	const allOrgList = [...new Set([...availableOrgList, org, ...orgList])];
	const [errorMsg, setErrorMsg] = useState("");
	const [infoErrorState, setInfoErrorState] = useState(false);

	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const { isLoading, sendRequest: fetchData } = useHttp();

	const showFindPart = useSelector((state) => state.findPartState.showFindPart);

	const handleOrgChange = (event) => {
		dispatch(wuBomAction.changeOrg(event.target.value));
	};

	const dataDispatcher = (data) => {
		if (data?.PART_NUMBER) {
			// Retrieve org from session storage if there is only and default to F01
			let org = window.sessionStorage.getItem("org");
			org = org && org !== "null" && org !== "undefined" ? org : "F01";
			dispatch(wuBomAction.retrievePart(data));
			dispatch(wuBomAction.changeOrg(org));
			// Remove find part pop up
			dispatch(findPartAction.setPartFound(true));
			dispatch(findPartAction.setShowFindPart(false));
		} else {
			dispatch(findPartAction.setPartFound(false));
		}

		// dispatch(wuBomAction.reset());
	};

	// Retrieve part info from API
	const changePart = (partNumber) => {
		fetchData({ url: `${APIURL}Wu_Bom/bomlist/${partNumber}/`, headers }, dataDispatcher, (error) => {
			// dispatch(wuBomAction.reset());
			console.log("ERROR: Unable to retrieve BOM", error);

			if (error === API_GENERAL_ERROR_MSG) {
				setErrorMsg(`ERROR: ${error} It could be part number not available`);
			} else {
				setErrorMsg(`ERROR: ${error}`);
				if (!showFindPart) {
					const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded"));
					if (subsetLoaded) {
						dispatch(wuBomAction.reset());
						dispatch(wuBomAction.setPartNumberForSubset(partNumber));
					}
				} else {
					dispatch(findPartAction.setPartFound(false));
				}
			}
			setInfoErrorState(true);
		});
	};

	useEffect(() => {
		const partNumber = window.sessionStorage.getItem("PartNumber");
		if (partNumber) {
			changePart(partNumber);
		} else {
			dispatch(findPartAction.setShowFindPart(true));
		}
	}, [reloadPartNumber]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<PrintBom />
			<PrintWhereUse />
			<PrintWhereUsePTO />
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setInfoErrorState} ErrorState={infoErrorState} />
			<CardWrapper sx={{ maxWidth: { xs: "100%", sm: "85%" } }}>
				<Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center" rowSpacing={2} columnSpacing={0.8} wrap={"wrap"}>
					<Grid item xl={1} lg={1.2} md={1.5} sm={2.2} xs={4}>
						<FormControl fullWidth focused>
							<InputLabel size="small" id="org-select-label">
								Org
							</InputLabel>
							<Select
								size="small"
								labelId="org-select-label"
								id="org-select"
								value={org}
								label="Org"
								onChange={handleOrgChange}
								MenuProps={{
									disableScrollLock: true,
									sx: { maxHeight: "60%" },
								}}
							>
								{allOrgList.map((org) => (
									<MenuItem sx={{ color: availableOrgList && availableOrgList.includes(org) && "green" }} key={org} value={org}>
										{org}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xl={3} lg={3} md={3} sm={3.9} xs={12}>
						<Tooltip title={partNumber}>
							<ColoredTextField fullWidth label="Parent Parts of" focused value={partNumber} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>

					<Grid item xl={8} lg={7.8} md={7.5} sm={5.9} xs={12}>
						<Tooltip title={partDesc}>
							<ColoredTextField fullWidth label="Part Description" focused value={partDesc} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>
				</Grid>

				<ParentTable changePart={changePart} />

				<Grid
					mb={2}
					mt={2}
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					rowSpacing={2}
					columnSpacing={0.8}
					wrap={"wrap"}
				>
					<Grid item xl={3} lg={3} md={3} sm={3.9} xs={12}>
						<Tooltip title={partNumber}>
							<ColoredTextField fullWidth label="Component Parts of" focused value={partNumber} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>

					<Grid item xl={9} lg={9} md={9} sm={8.1} xs={12}>
						<Tooltip title={partDesc}>
							<ColoredTextField fullWidth label="Part Description" focused value={partDesc} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>
				</Grid>

				<ComponentTable changePart={changePart} />
			</CardWrapper>

			<WuBomBottomNavigation changePart={changePart} />
		</Fragment>
	);
};

export default WuBom;
