// React
import { useSelector } from "react-redux";

// UI
import { Typography, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CardWrapper from "../../UI/Card";
import UploadBomFile from "./Steps/UploadBomFile";
import ProjectInfoEntry from "./Steps/ProjectInfoEntry";

const UploadBom = () => {
	const page = useSelector((state) => state.uploadBomState.currentPage);
	const selectedBreadcrumbStyle = (key) => {
		if (key === page) {
			return { color: "text.primary", fontWeight: "bold" };
		}
	};

	const breadcrumbs = [
		<Typography key="1" {...selectedBreadcrumbStyle(1)}>
			Upload File
		</Typography>,
		<Typography key="2" {...selectedBreadcrumbStyle(2)}>
			Project Info Entry
		</Typography>,
	];

	return (
		// <Box mt={{ xs: "165px", sm: "165px", md: "110px", lg: "65px" }}>
		<CardWrapper sx={{ maxWidth: { xs: "100%", sm: "85%" } }}>
			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				{breadcrumbs}
			</Breadcrumbs>

			{page === 1 && <UploadBomFile />}
			{page === 2 && <ProjectInfoEntry />}
		</CardWrapper>
		// </Box>
	);
};

export default UploadBom;
