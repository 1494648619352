// Packages Import
import { Fragment, useState, useEffect } from "react";
import { Box, Tooltip, Typography, Button, Modal, Stack, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Project Component
import DropdownFilter from "../../../UI/DropdownFilter";
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
import { APIURL, convertTrueFalse, PDF_GEN_JWT_TOKEN_URL, PDF_GEN_URL, OSAltKey } from "../../../Util/Util";
import SetFileName from "../../../Util/SetFileName";
import useDownloadFileWithRename from "../../../../Hooks/use-http-excel-r";

const MTLResponsibilityOtherReport = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const { downloadExcelFile } = useDownloadFileWithRename();
	const [loading, setLoading] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.reportsState.reports.other.mtlResponsibility);
	const showPopup = useSelector((state) => state.reportsState.popUpStates.other.mtlResponsibility);
	const orgList = useSelector((state) => state.reportsState.orgList);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const handleOrgChange = (value) => {
		dispatch(
			reportsActions.updateData({
				data: {
					userOrg: value,
				},
			})
		);
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
				setLoading(false);
			});
	};
	const generateReport = () => {
		setLoading(true);
		closeHandler();
		const jsonReq = {
			sortBy: data.sortBy.toString(),
			excelPdfReport: convertTrueFalse(data.excelPdfReport) === "1" ? "excel" : "pdf",
			userid: userInfo.id,
			userOrg: data.userOrg,
		};
		if (jsonReq.excelPdfReport === "excel") {
			downloadExcelFile(
				{
					url: `${APIURL}reports/mtlResponsibilityReport`,
					headers: headers,
					method: "POST",
					body: jsonReq,
				},
				(blob, filename) => {
					setBlob(blob);
					setFileName(filename);
					setOpenSetFileName(true);
				},
				(error) => {
					if (typeof props.onError === "function") {
						props.onError(error.toString());
					}
				},
				() => {
					setLoading(false);
				}
			)
		} else {
			fetchDataWithoutLoad(
				{
					url: `${APIURL}reports/mtlResponsibilityReport`,
					headers: headers,
					method: "POST",
					body: jsonReq,
				},
				(data) => {
					if (data?.No_Record) {
						throw Error(data.No_Record);
					}
					fetchDataWithoutLoad(
						{
							url: PDF_GEN_JWT_TOKEN_URL,
							headers: headers,
							method: "POST",
							body: {
								username: "admin",
								password: "pass",
							},
						},
						(token) => handleJWTToken(token, data),
						(error) => {
							setLoading(false);
							if (typeof props.onError === "function") {
								props.onError(error.toString());
							}
						}
					);
				},
				(error) => {
					setLoading(false);
					if (typeof props.onError === "function") {
						props.onError(error.toString());
					}
				}
			);
		}
	};
	const handleSortBy = (value) => {
		const indexVal = data.sortByList.findIndex((sortBy) => {
			return sortBy === value;
		});
		dispatch(
			reportsActions.updateData({
				data: {
					sortBy: indexVal + 1,
				},
			})
		);
	};
	const handleCheckbox = (type) => (event) => {
		dispatch(
			reportsActions.updateData({
				data: {
					[type]: event.target.checked,
				},
			})
		);
	};
	const closeHandler = () => {
		dispatch(reportsActions.updatePopUpState());
		dispatch(reportsActions.reset());
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName
				blob={blob}
				setOpenSetFileName={setOpenSetFileName}
				openSetFileName={openSetFileName}
				fileName={fileName}
			/>
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								closeHandler();
							}
							if (e.code === "KeyP") {
								generateReport();
							}
						}
					}}
				>
					<Stack mb={2} spacing={1}>
						<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
							<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
								MTL Responsibility Report
							</Typography>
						</Box>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								pt: 3,
							}}
						>
							<Grid
								item
								xl={2}
								lg={2}
								md={2}
								sm={12}
								xs={12}
								sx={{
									pr: { xs: 0, sm: 0, md: 1, lg: 1, xl: 1 },
								}}
							>
								<DropdownFilter
								fullWidth
									selections={orgList}
									selectedData={data.userOrg}
									handleSelection={handleOrgChange}
									label={"User Org"}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Grid>
							<Grid
								container
								item
								xl={6}
								lg={6}
								md={6}
								sm={12}
								xs={12}
								alignItems={"flex-start"}
								sx={{
									pr: { xs: 0, sm: 0, md: 2, lg: 2, xl: 2 },
									pt: { xs: 1.5, sm: 1.5, md: 0, lg: 0, xl: 0 },
								}}
							>
								<DropdownFilter
									fullWidth
									selections={data.sortByList}
									selectedData={data.sortByList[data.sortBy - 1]}
									handleSelection={handleSortBy}
									label={"Sort By"}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Grid>
							<Grid container item xl={4} lg={4} md={4} sm={12} xs={12} alignItems={"center"}>
								<FormControlLabel
									label="Export to excel file"
									control={<Checkbox checked={data.excelPdfReport} />}
									onChange={handleCheckbox("excelPdfReport")}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								p: 0,
							}}
						>
							<Grid
								item
								xl={6.1}
								lg={6.1}
								md={6.1}
								sm={6.1}
								xs={6.1}
								sx={{
									pr: 1,
								}}
							>
								<Tooltip title={`For shortcut ${OSAltKey} + C`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
								<Tooltip title={`For shortcut press ${OSAltKey} + P`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={generateReport}>
											<u
												style={{
													textDecorationThickness: "2.5px",
													fontWeight: "bold",
												}}
											>
												P
											</u>
											{convertTrueFalse(data.excelPdfReport) === "1" ? "rint Excel" : "rint PDF"}
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
						<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default MTLResponsibilityOtherReport;
