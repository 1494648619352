import { FormControl, InputLabel, Autocomplete, Box} from "@mui/material"
import ColoredTextField from './ColoredTextField'
import { useState , useEffect , memo} from "react"
import CircularProgress from "@mui/material/CircularProgress";


const AutocompleteDropdown = (props) => {
    const selections = props.selections instanceof Array ? props.selections : ['']
    const {
        label = undefined,
        size = "small",
        width = "100%",
        height = "100%",
        name = undefined,
        forcePopupIcon = false,
        isLoading = false,
        required = false,
    } = props
    const [value, setValue] = useState("")
    const [selectedData, setSelectedData] = useState(null)


    const handleSelection = (event, val) => {
        event.preventDefault()   
        if(val){
            setSelectedData(val)
        }else{
            setSelectedData(null)
        }
        if(typeof props.handleSelection === "function"){
                props.handleSelection(val,name)
        }
    }

    const handleInputChange = (event , val) => {
        if(val){
            setValue(val)
            
        }else{
            setValue("")
        }
        if(typeof props.handleInputChange === "function"){
                props.handleInputChange(val,name)
        }
    }
    
    const customComparator = (option , val) => {
        if(val.id === option.id || val.label === option.label){
            return true
        }
        return false
    }

    const handleOpen = (event) => {
        if(typeof props.onOpen === "function"){
            props.onOpen(value,name)
        }
    }
    useEffect(()=>{
        if(props.value){
            if(props.value.label === "" && props.value.id === ""){
                setSelectedData(null)
            }else{
                setSelectedData(props.value)
            }
            setValue(props.value.label)
        }

    },[props.value])
        return (
        <FormControl  
            focused
            sx={{ 
                width: width 
            }} 
        >
            <Autocomplete
                label={label}
                options={!isLoading ? selections : []}
                sx={{ 
                    width: width ,
                    height: height,
                }}
                forcePopupIcon={forcePopupIcon}
                autoHighlight
                autoComplete
                readOnly = {props.readOnly || false}
                disabled = {props.disabled || false}
                freeSolo = {props.freeSolo || false}
                onOpen = {handleOpen}
                inputValue = {value}
                value = {selectedData}
                isOptionEqualToValue = {customComparator}
				loading={isLoading}
                onInputChange={handleInputChange}
                loadingText={
					<Box 
                        sx={{ 
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center"
                        }}
                    >
						<CircularProgress color="inherit" size={"20%"} />
					</Box>
				}
                onChange={typeof props.handleSelection === "function" ? handleSelection : undefined}
                renderInput={(params) => {
                        return (<ColoredTextField 
                            {...params}
                            label={label} 
                            focused 
                            error = {props.error || false}
                            required
                            InputLabelProps={{ 
                                shrink: true,
                            }}
                        />)
                    }
                }
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.label}
                        </li>
                    );
                  }}
                {...props.extraProps}
                disablePortal = {false}
            />
        </FormControl>  
    )
}

export default memo(AutocompleteDropdown)