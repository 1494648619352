// Packages Import
import { memo } from "react";
import { Grid, Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

// Project Component
import ColoredTextField from "../../UI/ColoredTextField";
import { noteAction } from "../../../store/Note/Note-slice";
import DropdownFilter from "../../UI/DropdownFilter";

const NoteInfo = (props) => {
	const gridOrder = {
		org: {
			xl: 2,
			lg: 2,
			md: 2,
			sm: 2,
			xs: 2,
		},
		dateUpdated: {
			xl: 7,
			lg: 7,
			md: 7,
			sm: 7,
			xs: 7,
		},
		dateAdded: {
			xl: 6,
			lg: 6,
			md: 6,
			sm: 6,
			xs: 6,
		},
		updatedBy: {
			xl: 5,
			lg: 5,
			md: 5,
			sm: 5,
			xs: 5,
		},
		originator: {
			xl: 3,
			lg: 3,
			md: 3,
			sm: 3,
			xs: 3,
		},
		note: {
			xl: 4,
			lg: 4,
			md: 4,
			sm: 4,
			xs: 4,
		},
		prstat: {
			xl: 8,
			lg: 8,
			md: 8,
			sm: 8,
			xs: 8,
		},
		lrepl01: {
			xl: 9,
			lg: 9,
			md: 9,
			sm: 9,
			xs: 9,
		},
		lrepl02: {
			xl: 10,
			lg: 10,
			md: 10,
			sm: 10,
			xs: 10,
		},
	};
	const dispatch = useDispatch();
	const userNote = useSelector((state) => state.noteStates.USER_NOTE);
	const primaryOrg = [useSelector((state) => state.authenticationState.User_Assigned_Orgs.primaryOrg)];
	const postAction = useSelector((state) => state.noteStates.POST_ACTION);
	const orgs = useSelector((state) => state.noteStates.ORGS);
	const NOTE_INFO = useSelector((state) => state.noteStates.NOTE_INFO);
	const PART_INFO = useSelector((state) => state.noteStates.PART_INFO);

	const dateAdded = userNote.dateAdded;
	const dateUpdated = userNote.dateUpdated;

	const handleNoteData = (event) => {
		const val = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		dispatch(noteAction.updateNoteInfo({ name: event.target.name, val: val, postAction: postAction }));
	};
	const handleSelection = (val, type) => {
		switch (type) {
			case "org":
				dispatch(noteAction.updateOrgSelected({ val: val, NOTE_INFO: NOTE_INFO, PART_INFO: PART_INFO }));
				break;
			default:
				break;
		}
	};
	return (
		<Box>
			{PART_INFO.reviewStatus === "Pending" ? (
				<Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
				<Typography>Awaiting Review for PC:</Typography>
				<ColoredTextField
					value={PART_INFO.newPc}
					focused
					inputProps={{
						readOnly: true,
						sx: {
							textAlign: "center",
						},
					}}
					InputLabelProps={{
						shrink: true,
					}}
					sx={{
						ml: 2,
						width: "80px",
						backgroundColor: "rgba(255,165,0, 0.8)",
					}}
				/>
			</Box>
			) : PART_INFO.newPc ? (
				<Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
					<Typography>Open PC change request to:</Typography>
					<ColoredTextField
						value={PART_INFO.newPc}
						focused
						inputProps={{
							readOnly: true,
							sx: {
								textAlign: "center",
							},
						}}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{
							ml: 2,
							width: "80px",
							backgroundColor: "rgba(128, 255, 128, 0.8)",
						}}
					/>
				</Box>
			) : (
				<></>
			)}
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={1}
				wrap="wrap"
				sx={{ mb: 1.5 }}
			>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={gridOrder.org}>
					<DropdownFilter
						selections={orgs}
						handleSelection={handleSelection}
						label="Notes For Org"
						name="org"
						selectedData={userNote.selectedOrg}
						highlightItemList={primaryOrg}
						highlightColor="green"
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={gridOrder.originator}>
					<Tooltip title={userNote.originator}>
						<ColoredTextField
							label="Note Originator"
							value={userNote.originator}
							fullWidth
							focused
							inputProps={{
								readOnly: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={6} xs={12} order={gridOrder.updatedBy}>
					<Tooltip title={userNote.updatedBy}>
						<ColoredTextField
							label="Updated By"
							value={userNote.updatedBy}
							fullWidth
							focused
							inputProps={{
								readOnly: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={6} xs={12} order={gridOrder.dateAdded}>
					<Tooltip title={dateAdded}>
						<ColoredTextField
							label="Date Added"
							value={dateAdded}
							fullWidth
							focused
							inputProps={{
								readOnly: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={6} xs={12} order={gridOrder.dateUpdated}>
					<Tooltip title={dateUpdated}>
						<ColoredTextField
							label="Date Updated"
							value={dateUpdated}
							fullWidth
							focused
							inputProps={{
								readOnly: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Tooltip>
				</Grid>

				<Grid item xl={12} lg={12} md={12} sm={12} xs={12} order={gridOrder.note}>
					<Tooltip title={userNote.note}>
						<ColoredTextField
							onKeyDown={(e) => {
								if (e.code === "KeyS" && e.altKey === true && e.shiftKey === true) {
									e.preventDefault();
									if (props.editNoteState && props.editNoteStateHandler) props.editNoteStateHandler();
								}
							}}
							inputRef={props.focusRef}
							value={userNote.note}
							onChange={handleNoteData}
							name="note"
							type="textfield"
							fullWidth
							multiline
							focused
							// border={4}
							// color = {props.editNoteState ? "#FCE205" : isOutdated ? "#FF0000" : "#008000"}
							backgroundColor={props.editNoteState ? "rgba(225, 255, 0, 0.5)" : userNote.backgroundColor}
							rows={6}
							inputProps={{
								readOnly: !props.editNoteState,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Tooltip>
				</Grid>

				<Grid item xl={4} lg={4} md={4} sm={6} xs={12} order={gridOrder.prstat}>
					<FormControlLabel
						control={
							<Checkbox
								checked={userNote.prstat}
								onChange={handleNoteData}
								name={"prstat"}
								inputProps={{
									disabled: !props.editNoteState,
								}}
							/>
						}
						label="Print in Final Report"
						sx={{ ml: 0 }}
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={6} xs={12} order={gridOrder.lrepl01}>
					<FormControlLabel
						control={
							<Checkbox
								checked={userNote.lrepl01}
								onChange={handleNoteData}
								name={"lrepl01"}
								inputProps={{
									disabled: !props.editNoteState,
								}}
							/>
						}
						label="Note Contains Replacement Suggestions"
						sx={{ ml: 0 }}
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={6} xs={12} order={gridOrder.lrepl02}>
					<FormControlLabel
						control={
							<Checkbox
								checked={userNote.lrepl02}
								onChange={handleNoteData}
								name={"lrepl02"}
								inputProps={{
									disabled: !props.editNoteState,
								}}
							/>
						}
						label="Include Keysight Part Number"
						sx={{ ml: 0 }}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
export default memo(NoteInfo);
