// Packages Import
import {  useState } from "react";
import {
	Box,
	Paper,
	Tooltip,
	Typography,
	Button,
	Modal,
	Stack,
	Grid,
	Autocomplete,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Project Component
import ColoredTextField from "../../../UI/ColoredTextField";
import DropdownFilter from "../../../UI/DropdownFilter";
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
import {
	APIURL,
	OSAltKey,
	isNumber,
	convertTrueFalse,
	convertArrayStringToSingleString,
	saveEmailToLocalStorage
} from "../../../Util/Util";
import ChippedInput from "../../../UI/ChippedInput";

let inputTimeOut;

const FinalLiaisonReport = (props) => {
	const dispatch = useDispatch();
	const getBomList =
		typeof props.getBomList === "function"
			? props.getBomList
			: () => {
					return;
			  };
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const [bomListLoad, setBomListLoad] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.reportsState.reports.liaison.final);
	const showPopup = useSelector((state) => state.reportsState.popUpStates.liaison.final);
	const orgList = useSelector((state) => state.reportsState.orgList);
	const bomList = useSelector((state) => state.reportsState.bomList);
	const onError = typeof props.onError === "function" ? props.onError : () => {}
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};
	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + accessToken,
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "90%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};

	const emailAutoCompleteOptions = JSON.parse(window.localStorage.getItem("emailAutoComplete"));

	const handleOrgChange = (value) => {
		dispatch(
			reportsActions.updateData({
				data: {
					userOrg: value,
				},
			})
		);
		getBomList(data.daysBack, value, setBomListLoad);
	};

	const generateReport = () => {
		closeHandler();
		let jsonReq = {
			userid: userInfo.id,
			userOrg: data.userOrg,
			mlar_ppn: data.selectedBom?.ppn ?? "",
			mlar_prev: data.selectedBom?.prev ?? "",
			final_report: convertTrueFalse(data.attachFinal),
			rohs_detail: convertTrueFalse(data.attachDetailsRohs),
			rohs_summary: convertTrueFalse(data.attachSummaryRohs),
			max_optemp: convertTrueFalse(data.attachMaxOpTemp),
			notes_pc4: convertTrueFalse(data.includePc4),
			designer: data.designer ?? "",
			a_group: data.a_group ?? "",
			project: data.project ?? "",
			pr_mgr: data.pr_mgr ?? "",
			npieng: data.npieng ?? "",
			pcdfm: data.pcdfm ?? "",
			liaison: data.liaison ?? "",
			to_email: data.emailToCheck === true ? data.emailTo : "",
			cc_email: data.emailCcCheck === true ? data.emailCc : "",
		};

		console.log(jsonReq);
		fetchDataWithoutLoad(
			{
				url: `${APIURL}reports/liaisonReport`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(data) => {
				if (data?.No_Record) {
					throw Error(data.No_Record);
				}
				console.log(data);
				onError(data.Success.toString())
			},
			(error) => {
				onError(error.toString())
			}
		);
	};
	const closeHandler = () => {
		dispatch(reportsActions.updatePopUpState());
		dispatch(reportsActions.reset());
	};

	const handleDayNum = (event) => {
		const inpVal = event.target.value;
		if (isNumber(inpVal) || inpVal === "") {
			dispatch(
				reportsActions.updateData({
					data: {
						daysBack: inpVal,
					},
				})
			);

			if (inpVal !== "") {
				clearTimeout(inputTimeOut);
				const timeoutSec = 500;
				inputTimeOut = setTimeout(() => {
					getBomList(inpVal, data.userOrg, setBomListLoad);
				}, timeoutSec);
			}
		}
	};

	const handleMLARBOMChanged = (newValue) => {
		dispatch(
			reportsActions.updateData({
				data: {
					selectedBom: newValue,
				},
			})
		);
		if (newValue === null) {
			dispatch(
				reportsActions.updateData({
					data: {
						emailTo: [],
						emailCc: [],
						liaison: "",
						pcdfm: "",
						designer: "",
						a_group: "",
						project: "",
						pr_mgr: "",
						npieng: "",
						error: {
							...data.error,
							mlarBom: true,
						},
						errorText: {
							...data.errorText,
							mlarBom: "",
						},
					},
				})
			);
		} else {
			const jsonReq = {
				userid: userInfo.id,
				userOrg: data.userOrg,
				mlarBom: newValue.ppn,
				rev: newValue.prev,
			};
			fetchDataWithoutLoad(
				{
					url: `${APIURL}reports/getProjectDetails`,
					headers: headers,
					method: "POST",
					body: jsonReq,
				},
				(retObj) => {
					if (retObj?.No_Record) {
						throw Error(retObj.No_Record);
					}
					const details = retObj.PROJECTS_DETAILS[0];
					const filteredEmailTo = retObj.TO_EMAIL.trim() ? [retObj.TO_EMAIL.trim()] : []
					const filteredEmailCC = retObj.CC_EMAIL.filter((email) => email.trim())
					dispatch(
						reportsActions.updateData({
							data: {
								emailTo: filteredEmailTo,
								emailCc: filteredEmailCC,
								liaison: details.liaison ?? "",
								pcdfm: details.pcdfm ?? "",
								designer: details.designer ?? "",
								a_group: details.a_group ?? "",
								project: details.project ?? "",
								pr_mgr: details.pr_mgr ?? "",
								npieng: details.npieng ?? "",
								error: {
									...data.error,
									mlarBom: false,
								},
								errorText: {
									...data.errorText,
									mlarBom: "",
								},
							},
						})
					);
				},
				(error) => {
					setLoading(false);
					onError(error.toString())
				}
			);
		}
	};

	const handleCheckbox = (type) => (event) => {
		dispatch(
			reportsActions.updateData({
				data: {
					[type]: event.target.checked,
				},
			})
		);
	};

	const handleChipError = (type, error) => {
		dispatch(
			reportsActions.updateData({
				data: {
					error: {
						...data.error,
						[type]: true,
					},
					errorText: {
						...data.errorText,
						[type]: error,
					},
				},
			})
		);
	};
	const handleAddChip = (type) => (input, setChip, setInput) => {
		let matchedDomain = false;

		for (let domain of data.acceptedDomains) {
			matchedDomain = new RegExp(`@${domain}`).test(input.val);
			if (matchedDomain === true) {
				break;
			}
		}
		if (matchedDomain === false) {
			handleChipError(type, `Domain must be ${convertArrayStringToSingleString(data.acceptedDomains, " or ")}`);
			return;
		}

		let existInEmailCc = data.emailCc.findIndex((email) => email === input.val);
		let existInEmailTo = data.emailTo.findIndex((email) => email === input.val);
		let tempEmailArray = [];

		// Saving last 10 emails to local storage for autocomplete
		saveEmailToLocalStorage(input.val);

		if (type === "emailTo") {
			if (existInEmailTo >= 0) {
				handleChipError(type, `${input.val} has already been added`);
				return;
			} else if (existInEmailCc >= 0) {
				setInput("");
				tempEmailArray = [...data.emailCc];
				tempEmailArray.splice(existInEmailTo, 1);
				dispatch(
					reportsActions.updateData({
						data: {
							emailCc: tempEmailArray,
							[type]: input.all,
						},
					})
				);
			} else {
				setInput("");
				dispatch(
					reportsActions.updateData({
						data: {
							[type]: input.all,
						},
					})
				);
			}
		}
		if (type === "emailCc") {
			if (existInEmailCc >= 0) {
				handleChipError(type, `${input.val} has already been added`);
				return;
			} else if (existInEmailTo >= 0) {
				setInput("");
				tempEmailArray = [...data.emailTo];
				tempEmailArray.splice(existInEmailTo, 1);
				dispatch(
					reportsActions.updateData({
						data: {
							emailTo: tempEmailArray,
							[type]: input.all,
						},
					})
				);
			} else {
				setInput("");
				dispatch(
					reportsActions.updateData({
						data: {
							[type]: input.all,
						},
					})
				);
			}
		}
	};
	const handleInputChip = (type) => (val) => {
		if (type === "emailTo" && data.emailTo.length < 1) {
			dispatch(
				reportsActions.updateData({
					data: {
						error: {
							...data.error,
							[type]: true,
						},
						errorText: {
							...data.errorText,
							[type]: "One or more email address is required",
						},
					},
				})
			);
		}
		dispatch(
			reportsActions.updateData({
				data: {
					error: {
						...data.error,
						[type]: false,
					},
				},
			})
		);
	};
	const handleDeleteChip = (type) => (data, setChip) => {
		dispatch(
			reportsActions.updateData({
				data: {
					[type]: data.all,
				},
			})
		);
	};
	return (
		<>
			<FullScreenLoadingIndicator loading={loading} />
			<Box>
				<Modal
					open={showPopup}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					disableScrollLock
				>
					<Box
						sx={style}
						onKeyDown={(e) => {
							if (e.altKey) {
								if (e.code === "KeyC") {
									closeHandler();
								}
								if (e.code === "KeyS") {
									if (data.emailTo.length < 1 || data.error.mlarBom) {
										generateReport();
									}
								}
							}
						}}
					>
						<Stack mb={2} spacing={1}>
							<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
								<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
									Final Report
								</Typography>
								<DropdownFilter
									width={90}
									selections={orgList}
									selectedData={data.userOrg}
									handleSelection={handleOrgChange}
									label={"User Org"}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Box>
							<Paper sx={{ p: 2 }} elevation={5}>
								<Typography variant="subtitle">Select MLAR Board number in the dropdown below</Typography>
							</Paper>
							<Grid
								container
								direction="row"
								spacing={0}
								sx={{
									pt: 1,
								}}
							>
								<Grid
									item
									xl={2}
									lg={2}
									md={2}
									sm={2}
									xs={12}
									sx={{
										pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
									}}
								>
									<ColoredTextField
										label="Days Back"
										fullWidth
										focused
										onChange={handleDayNum}
										value={data.daysBack}
										InputLabelProps={{
											shrink: true,
											sx: {
												fontWeight: 700,
											},
										}}
									/>
								</Grid>
								<Grid
									item
									xl={10}
									lg={10}
									md={10}
									sm={10}
									xs={12}
									sx={{
										pt: { xs: 1.5, sm: 0, md: 0, lg: 0, xl: 0 },
									}}
								>
									<Autocomplete
										loading={bomListLoad}
										loadingText={
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<CircularProgress color="inherit" size={"20%"} />
											</Box>
										}
										options={!bomListLoad ? bomList : []}
										value={data.selectedBom}
										getOptionLabel={(option) => `${option.ppn}`}
										renderOption={(props, option) => {
											return (
												<li {...props}>
													<Box>
														<Typography
															align="center"
															width={{ xs: "60px", sm: "130px" }}
															variant="body2"
														>{`${option.ppn}`}</Typography>
													</Box>
													<Box borderLeft={"1px Solid"} ml={2}>
														<Typography pl={1.5} minWidth={"35px"} variant="body2">{`${option.prev}`}</Typography>
													</Box>
													<Box borderLeft={"1px Solid"} ml={2}>
														<Typography pl={1.5} variant="body2">{`${option.date}`}</Typography>
													</Box>
													<Box borderLeft={"1px Solid"} ml={2}>
														<Typography pl={1.5} variant="body2">{`${option.org}`}</Typography>
													</Box>
												</li>
											);
										}}
										renderInput={(params) => (
											<ColoredTextField
												label={<b>choose MLAR/BOM #</b>}
												focused
												error={data.error.mlarBom}
												helperText={data.errorText.mlarBom}
												{...params}
											/>
										)}
										isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
										onChange={(event, newValue) => handleMLARBOMChanged(newValue)}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={0}>
								<Paper
									elevation={4}
									sx={{
										px: 2,
									}}
								>
									<Grid
										container
										item
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										sx={{
											pt: 1,
										}}
									>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<FormControlLabel
												label="Attach Final report"
												control={<Checkbox checked={data.attachFinal} />}
												onChange={handleCheckbox("attachFinal")}
											/>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<FormControlLabel
												label="Attach Details RoHS report"
												control={<Checkbox checked={data.attachDetailsRohs} />}
												onChange={handleCheckbox("attachDetailsRohs")}
											/>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<FormControlLabel
												label="Attach Summary RoHS report"
												control={<Checkbox checked={data.attachSummaryRohs} />}
												onChange={handleCheckbox("attachSummaryRohs")}
											/>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<FormControlLabel
												label="Attach Max Op Temperature Report"
												control={<Checkbox checked={data.attachMaxOpTemp} />}
												onChange={handleCheckbox("attachMaxOpTemp")}
											/>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<FormControlLabel
												label="Include notes for parts with PC=4 in email body"
												control={<Checkbox checked={data.includePc4} />}
												onChange={handleCheckbox("includePc4")}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Grid container direction="row" rowSpacing={2}>
								<Grid container item xl={12} lg={12} md={12} sm={12} xs={12} columnSpacing={1}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<ColoredTextField
											label="Liaison Name"
											fullWidth
											focused
											onChange={handleDayNum}
											value={data.liaison}
											InputLabelProps={{
												shrink: true,
												sx: {
													fontWeight: 700,
												},
											}}
											InputProps={{
												readOnly: true,
											}}
										/>
									</Grid>
									<Grid
										item
										xl={6}
										lg={6}
										md={6}
										sm={12}
										xs={12}
										sx={{ pt: { xs: 1.5, sm: 1.5, md: 0, lg: 0, xl: 0 } }}
									>
										<ColoredTextField
											label="PCDFM"
											fullWidth
											focused
											onChange={handleDayNum}
											value={data.pcdfm}
											InputLabelProps={{
												shrink: true,
												sx: {
													fontWeight: 700,
												},
											}}
											InputProps={{
												readOnly: true,
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ChippedInput
										label="Email To"
										chipValue={data.emailTo}
										onEnter={handleAddChip("emailTo")}
										onChipDelete={handleDeleteChip("emailTo")}
										onInputChange={handleInputChip("emailTo")}
										error={data.emailTo.length < 1 ? true : data.error.emailTo}
										errorText={data.errorText.emailTo}
										placeholder={"Type an email address and press Enter"}
										autoCompleteOption={emailAutoCompleteOptions}
									/>
									{/* <Typography sx={{ fontSize: "0.7rem" }}>Press Enter to add values</Typography> */}
								</Grid>

								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ChippedInput
										label="Email CC"
										chipValue={data.emailCc}
										onEnter={handleAddChip("emailCc")}
										onInputChange={handleInputChip("emailCc")}
										onChipDelete={handleDeleteChip("emailCc")}
										error={data.error.emailCc}
										errorText={data.errorText.emailCc}
										placeholder={"Type an email address and press Enter"}
										autoCompleteOption={emailAutoCompleteOptions}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={0}
								sx={{
									p: 0,
								}}
							>
								<Grid
									item
									xl={6.1}
									lg={6.1}
									md={6.1}
									sm={6.1}
									xs={6.1}
									sx={{
										pr: 1,
									}}
								>
									<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
										<span>
											<Button sx={{ width: "100%", ml: 0 }} onClick={() => closeHandler()}>
												<u
													style={{
														textDecorationThickness: "2.5px",
														fontWeight: "bold",
													}}
												>
													C
												</u>
												ancel
											</Button>
										</span>
									</Tooltip>
								</Grid>
								<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
									<Tooltip title={`For shortcut press ${OSAltKey} + P`}>
										<span>
											<Button
												sx={{ width: "100%", ml: 0 }}
												onClick={generateReport}
												disabled={data.emailTo.length < 1 || data.error.mlarBom}
											>
												<u
													style={{
														textDecorationThickness: "2.5px",
														fontWeight: "bold",
													}}
												>
													S
												</u>
												end Report
											</Button>
										</span>
									</Tooltip>
								</Grid>
							</Grid>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Stack>
					</Box>
				</Modal>
			</Box>
		</>
	);
};

export default FinalLiaisonReport;
