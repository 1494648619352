// React
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
import { useLocation } from "react-router-dom";
import moment from "moment";

// UI
import {
	Box,
	Modal,
	Stack,
	Typography,
	Paper,
	Tooltip,
	Button,
	Grid,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox,
	Select,
	MenuItem,
	InputLabel,
} from "@mui/material";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import DropdownFilter from "../../../UI/DropdownFilter";
import Datepicker from "../../../UI/Datepicker";
import AutocompleteDropdown from "../../../UI/AutocompleteDropdown";
import SetFileName from "../../../Util/SetFileName";
// Packages Import
import {
	OSAltKey,
	isEmptyObject,
	PDF_GEN_URL,
	APIURL,
	PDF_GEN_JWT_TOKEN_URL,
	storePartHistories,
} from "../../../Util/Util";
import useHttp from "../../../../Hooks/use-http";
import { subsetReloadPage } from "../../Subset/CreateSubset";

const MyRoHSActionsRequired = (props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { sendRequest: fetchDataWithoutLoad } = useHttp();

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const userName = useSelector((state) => state.authenticationState.userName);
	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const showPopup = useSelector((state) => state.reportsState.popUpStates.rohs.myRoHSActionsRequired);
	const orgList = useSelector((state) => state.reportsState.orgList);
	const data = useSelector((state) => state.reportsState.reports.rohs.myRoHSActionsRequired);

	const handleError = typeof props.onError === "function" ? props.onError : () => {};
	const handleSuccess = typeof props.onSuccess === "function" ? props.onSuccess : () => {};
	const { pathname: urlPathName } = useLocation();

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};

	const handleOrgChange = (value) => {
		dispatch(
			reportsActions.updateData({
				data: {
					userOrg: value,
					mtlName: {
						id: "",
						label: "",
					},
				},
			})
		);
		dispatch(reportsActions.updateMtlNameList({ data: data.originalUserList }));
	};

	const handleRadioPdfSubset = (event) => {
		const val = event.target.value;
		dispatch(reportsActions.updateData({ data: { createSubsetOrPdf: val } }));
	};

	const handlePartsOptionChanged = (event) => {
		const val = event.target.value;
		// 0 is for All Parts and 1 is (my) part
		dispatch(
			reportsActions.updateData({ data: { optionGroupInput: val, checkInclUnassignedComm: "0", checkName: "0" } })
		);
	};

	const handleUnassignedCommChanged = (event) => {
		if (event.target.checked) {
			dispatch(reportsActions.updateData({ data: { checkInclUnassignedComm: "1" } }));
		} else {
			dispatch(reportsActions.updateData({ data: { checkInclUnassignedComm: "0" } }));
		}
	};

	const handleMtlEngCheck = (event) => {
		if (event.target.checked) {
			dispatch(
				reportsActions.updateData({
					data: { mtlEngChecked: true, optionGroupInput: "0", checkInclUnassignedComm: "0" },
				})
			);
		} else {
			dispatch(
				reportsActions.updateData({ data: { mtlEngChecked: false, mtlName: { id: "", label: "" }, checkName: "0" } })
			);
		}
	};

	const handleDateSelection = (val, type) => {
		let startDate = moment(data.startDate, "YYYY-MM-DD");
		let endDate = moment(data.endDate, "YYYY-MM-DD");
		const dateVal = moment(val, "YYYY-MM-DD");
		switch (type) {
			case "start":
				startDate = dateVal;
				break;
			case "end":
				if(endDate.isAfter(startDate)){
					startDate = dateVal.subtract(1, "day");
				}
				endDate = dateVal;
				break;
			default:
				break;
		}
		dispatch(
			reportsActions.updateData({
				data: {
					startDate: startDate.format("YYYY-MM-DD"),
					endDate: endDate.format("YYYY-MM-DD"),
				},
			})
		);
	};

	
	const handleDisableDate = (val, type) => {
		const today = moment();
		const curDateCopy = moment(val, "YYYY-MM-DD");
		const endDt = moment(data.endDate, "YYYY-MM-DD");
		switch (type) {
			case "start":
				if (endDt.diff(curDateCopy, "days") > data.maxDay || curDateCopy.isSameOrAfter(endDt)) {
					return true;
				} else {
					return false;
				}
			case "end":
				if (curDateCopy.isAfter(today)) {
					return true;
				} else {
					return false;
				}
			default:
				return true;
		}
	};

	const closeHandler = () => {
		dispatch(reportsActions.updatePopUpState());
		dispatch(reportsActions.reset());
	};

	const handleMtlSelection = (val) => {
		if (val === null) {
			dispatch(reportsActions.updateData({ data: { mtlName: { id: "", label: "", checkName: "0" } } }));
		} else {
			dispatch(reportsActions.updateData({ data: { mtlName: val, checkName: "1" } }));
		}
	};

	const handleMTLInputChange = (val, type) => {
		if (data.mtlNameList.length > 0 && !isEmptyObject(data.mtlNameList[0])) {
			let userid = "";
			let isExist;
			isExist = data.mtlNameList.findIndex((singleData) => {
				if (singleData.label === val) {
					userid = singleData.id;
					return true;
				}
				return false;
			});
			if (isExist > -1) {
				dispatch(reportsActions.updateData({ data: { mtlName: { id: userid, label: val } } }));
				dispatch(reportsActions.setError({ data: { mtlName: false } }));
			} else if (val === "") {
				dispatch(reportsActions.updateData({ data: { mtlName: { id: "", label: "" }, checkName: "0" } }));
				dispatch(reportsActions.setError({ data: { mtlName: false } }));
			} else {
				// This will highlight the checkbox to read and disable print/create button
				dispatch(reportsActions.setError({ data: { mtlName: true } }));
			}
		}
	};

	const handleSortChange = (event) => {
		dispatch(reportsActions.updateData({ data: { sortBy: event.target.value } }));
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
				setLoading(false);
			});
	};

	const generateReport = () => {
		// Original Logics
		// 1.) Value of “startDate” field will be the date selected by user in the “From” date field. Date format (YYYY-MM-DD).
		// 2.) Value of “endDate” field will be the date selected by user in the “to” date field. Date format (YYYY-MM-DD).
		// 3.) Value of “userOrg” field will be the userorg selected by the user from the dop down containing the available user org list.
		// 4.) Value of “userid” field will be the userNTID of the logged in user who is trying to generate the report.
		// 5.) Value of “checkName” field will be
		//     • “1” if user selects the checkbox “Choose MTL name” and chooses a MTL Engineer name from the drop down.
		//     • “0” if user
		// 	a.) selects the checkbox “Choose MTL name” but from the drop down selects the option “ALL” or doesn’t selects any MTL Engineer name from the drop down or
		// 	b.) selects the checkbox “Include my parts” or
		// 	c.) selects the checkbox “All parts (for all material engineers)”
		// 6.) Value of “optionGroupInput” field will be “1” if user selects the checkbox “Include my parts” and “0” if user selects either of the checkboxes “Choose MTL name” or “All parts (for all material Engineers)”.
		// 7.) Value of “mtlName” field will be
		//     • userNTID of the MTL Engineer selected from the drop down when the checkbox “Choose MTL name” is checked.
		//     • else value will be “” if user selects either of the checkboxes “Include my parts”, “All parts (for all material Engineers)” or if user selects the option “ALL” from the drop down or doesn’t selects any MTL Engineer name from the drop down.
		// 8.) Value of “checkInclUnassignedComm” field will be “1” if user selects the checkbox “Include unassigned commodity” else will be “0”.
		// 9.) Value of “sortBy” field will be
		//       • “1” if Sort by is MTL_Name
		//       • “2” if Sort by is Item
		//       • “3” if Sort by is BOM
		// 10.) Value of “createSubsetOrPdf” field will be “1” if user selects the checkbox “Create Subset” else will be “0” if user selects the checkbox Print to PDF file”.

		setLoading(true);
		closeHandler();

		const body = {
			startDate: data.startDate,
			endDate: data.endDate,
			userOrg: data.userOrg,
			userid: userNTID,
			checkName: data.checkName,
			optionGroupInput: data.optionGroupInput,
			mtlName: data.mtlName.id,
			checkInclUnassignedComm: data.checkInclUnassignedComm,
			sortBy: data.sortBy,
			createSubsetOrPdf: data.createSubsetOrPdf,
		};

		fetchDataWithoutLoad(
			{
				url: `${APIURL}reports/rohsActionRequiredReport`,
				headers: headers,
				method: "POST",
				body,
			},
			(data) => {
				if (data?.No_Record) {
					throw Error(data.No_Record);
				}
				fetchDataWithoutLoad(
					{
						url: PDF_GEN_JWT_TOKEN_URL,
						headers: headers,
						method: "POST",
						body: {
							username: "admin",
							password: "pass",
						},
					},
					(token) => handleJWTToken(token, data),
					(error) => {
						setLoading(false);
						if (typeof props.onError === "function") {
							props.onError(error.toString());
						}
					}
				);
			},
			(error) => {
				setLoading(false);
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
			}
		);
	};
	const loadSubset = (subsetData) => {
		window.sessionStorage.setItem("subsetLoaded", JSON.stringify("SUBSET"));
		const loadedSubset = subsetData[0];
		storePartHistories(loadedSubset.partnumber[0]);
		window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(loadedSubset.partnumber));
		window.sessionStorage.setItem("subsetUseItemOrder", true);
		window.sessionStorage.setItem("subsetLoaded", true);
		window.sessionStorage.setItem("subsetName", JSON.stringify(`${loadedSubset.subsetid}`));
		subsetReloadPage(urlPathName, dispatch);
	};
	const createSubset = (partNums, setLoading) => {
		const today = moment();
		const subsetId = `Subset_MyRoHS_${today.format("YYYYMMDDHHmmss")}`;
		const jsonReq = {
			userid: userNTID,
			subsetid: subsetId,
			partnumber: partNums,
			createdby: "REPORTS",
			updatedby: "REPORTS",
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}subset/editSubset`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(retData) => {
				handleSuccess(`Successfully Exported to ${subsetId}`, () => {
					loadSubset(retData.Subset_Info);
				});
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				handleError(error.toString());
			}
		);
	};
	const handleSubset = () => {
		setLoading(true);
		closeHandler(true);
		const body = {
			startDate: data.startDate,
			endDate: data.endDate,
			userOrg: data.userOrg,
			userid: userNTID,
			checkName: data.checkName,
			optionGroupInput: data.optionGroupInput,
			mtlName: data.mtlName.id,
			checkInclUnassignedComm: data.checkInclUnassignedComm,
			sortBy: data.sortBy,
			createSubsetOrPdf: data.createSubsetOrPdf,
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}reports/rohsActionRequiredReport`,
				headers: headers,
				method: "POST",
				body,
			},
			(data) => {
				if (data?.No_Record) {
					throw Error(data.No_Record);
				}
				if (Array.isArray(data.partnumber) && data.partnumber.length < 1) {
					throw Error(`No Part Number found. Change spec and re-run...`);
				}
				createSubset(data.partnumber, setLoading);
			},
			(error) => {
				setLoading(false);
				handleError(error.toString());
			}
		);
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName
				blob={blob}
				setOpenSetFileName={setOpenSetFileName}
				openSetFileName={openSetFileName}
				fileName={fileName}
			/>
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC" && e.altKey === true) {
							closeHandler();
						}
						if (e.code === "KeyP" && e.altKey === true && !data.error.mtlName) {
							generateReport();
						}
						if (e.code === "KeyS" && e.altKey === true && !data.error.mtlName) {
							handleSubset();
						}
					}}
				>
					<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
						<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
							My RoHS Actions Required
						</Typography>
						<DropdownFilter
							width={90}
							selections={orgList}
							selectedData={data.userOrg}
							handleSelection={handleOrgChange}
							label={"User Org"}
							InputLabelProps={{
								shrink: true,
								sx: {
									fontWeight: 700,
								},
							}}
						/>
					</Box>
					<Paper sx={{ p: 2, mt: 2 }} elevation={5}>
						<Typography variant="subtitle">Can be downloaded as PDF or exported as subset</Typography>
					</Paper>

					<Grid container direction="row" sx={{ mt: 2 }}>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<FormControl>
								<RadioGroup row onChange={handlePartsOptionChanged} value={data.optionGroupInput}>
									<FormControlLabel
										disabled={data.mtlEngChecked}
										value={"1"}
										control={<Radio />}
										label={`${userName} parts`}
									/>
									<FormControlLabel
										disabled={data.mtlEngChecked}
										value={"0"}
										control={<Radio />}
										label="All parts (for all material engineers)"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<FormControlLabel
								value="unassignedCommodity"
								disabled={data.optionGroupInput === "0"}
								control={
									<Checkbox checked={data.checkInclUnassignedComm === "1"} onChange={handleUnassignedCommChanged} />
								}
								label="Include unassigned commodity"
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
							<FormControlLabel
								value="chooseMtlName"
								control={<Checkbox checked={data.mtlEngChecked} onChange={handleMtlEngCheck} />}
								label="Choose MTL name"
							/>
						</Grid>
						<Grid
							item
							xl={8}
							lg={8}
							md={8}
							sm={12}
							xs={12}
							sx={{
								pt: 1,
							}}
						>
							<AutocompleteDropdown
								label="Choose MTL Name"
								selections={data.mtlNameList}
								value={data.mtlName}
								handleSelection={handleMtlSelection}
								handleInputChange={handleMTLInputChange}
								name="name"
								disabled={!data.mtlEngChecked}
								freeSolo={true}
								error={data.error.mtlName}
								extraProps={{
									ListboxProps: {
										sx: {
											"& .MuiAutocomplete-option.Mui-focused": {
												backgroundColor: "rgba(230, 230, 250, 0.8)",
											},
										},
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{
								pt: 2,
							}}
						>
							<FormControl focused sx={{ width: 150 }}>
								<InputLabel size="small" id="org-select-label">
									Sort
								</InputLabel>
								<Select
									size="small"
									value={data.sortBy}
									label="Sort"
									onChange={handleSortChange}
									MenuProps={{
										disableScrollLock: true,
										sx: { maxHeight: "60%" },
									}}
								>
									<MenuItem value={"1"}>{"MTL_Eng"}</MenuItem>
									<MenuItem value={"2"}>{"item"}</MenuItem>
									<MenuItem value={"3"}>{"BOM"}</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container direction="row" spacing={1} sx={{ mt: 1 }}>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Stack>
								<Typography variant="caption" sx={{ fontWeight: 700 }}>
									Pick report dates for loaded - MLAR (Default period is set to MLAR loaded up to 28 days ago)
								</Typography>
								{/* <Typography variant="caption" sx={{ fontWeight: 700 }}>
									Default period is set to MLAR loaded up to 28 days ago...
								</Typography> */}
							</Stack>
						</Grid>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<Datepicker
								label="Start Date"
								handleSelection={handleDateSelection}
								val={data.startDate}
								name="start"
								disableFuture={false}
								disablePast={false}
								shouldDisableDate={handleDisableDate}
								InputLabelProps={{
									shrink: true,
									sx: {
										fontWeight: 700,
									},
								}}
							/>
						</Grid>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<Datepicker
								label="End Date"
								handleSelection={handleDateSelection}
								val={data.endDate}
								name="end"
								disableFuture={false}
								disablePast={false}
								shouldDisableDate={handleDisableDate}
								InputLabelProps={{
									shrink: true,
									sx: {
										fontWeight: 700,
									},
								}}
							/>
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Typography variant="caption" sx={{ fontWeight: 700 }}>
								Start Date cannot be more than 6 months from End Date
							</Typography>
						</Grid>
					</Grid>

					<Grid container direction="row" spacing={1}>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<FormControl>
								<RadioGroup row onChange={handleRadioPdfSubset} value={data.createSubsetOrPdf}>
									<FormControlLabel value={"0"} control={<Radio />} label="Print to PDF" />
									<FormControlLabel value={"1"} control={<Radio />} label="Create Subset" />
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>

					<Grid
						container
						direction="row"
						spacing={1}
						sx={{
							p: 0,
							mt: 1,
						}}
					>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<Tooltip title={`For shortcut ${OSAltKey} + C`}>
								<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
								</Button>
							</Tooltip>
						</Grid>
						{data.createSubsetOrPdf === "1" ? (
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Tooltip title={`For shortcut ${OSAltKey} + S`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} disabled={data.error.mtlName} onClick={handleSubset}>
											Create&nbsp;<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ubset
										</Button>
									</span>
								</Tooltip>
							</Grid>
						) : (
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Tooltip title={`For shortcut ${OSAltKey} + P`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} disabled={data.error.mtlName} onClick={generateReport}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>P</u>rint PDF
										</Button>
									</span>
								</Tooltip>
							</Grid>
						)}
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</Box>
	);
};

export default MyRoHSActionsRequired;
