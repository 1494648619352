import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Box, Button, Modal, Typography, Stack, Tooltip } from "@mui/material";
import { OSAltKey } from "../Util/Util";

const createData = (name, Description) => {
	return { name, Description };
};

const rows = [
	createData("CTRL + Shift + LeftArrow", "Top/First Record"),
	createData("Shift + LeftArrow", "Prior Record"),
	createData("Shift + RightArrow", "Next Record"),
	createData("CTRL + Shift + RightArrow", "Last/Bottom Record"),
	createData(`${OSAltKey} + pageup`, "Next TAB"),
	createData(`${OSAltKey} + pagedown`, "Previous TAB"),
	createData("CTRL + Shift + F", "Open Find Window"),
	createData(`${OSAltKey} + Shift + E`, "Edit Note"),
	createData(`${OSAltKey}+ Shift + S`, "Save Edited Note"),
	createData("CTRL + Shift + Q", "Quick Update action on Notes Tab"),
];

const DenseTable = () => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
				<TableBody>
					{rows.map((row, index) => (
						<TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
							<TableCell style={{ minWidth: 180, backgroundColor: index % 2 === 0 && "rgba(128,128,128,0.2)" }}>{row.name}</TableCell>
							<TableCell style={{ backgroundColor: index % 2 === 0 && "rgba(128,128,128,0.2)" }}>{row.Description}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: 510 },
	maxHeight: "80%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "auto",
	p: 4,
	pl: { xs: 1, sm: 4 },
	pr: { xs: 1, sm: 4 },
};

const ShortcutManual = (props) => {
	const closeHandler = () => {
		props.setShortcutManualState(false);
	};

	return (
		<Box>
			<Modal open={props.ShortcutManualState} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC") {
							closeHandler();
						}
					}}
				>
					<Stack mb={2} spacing={1}>
						<Typography>Keyboard Shortcuts Info</Typography>
						<DenseTable />
						<Typography variant="caption">*Alternatively you can mouse hover to buttons for shortcut tooltip</Typography>
						<Tooltip title={`For shortcut press C`}>
							<Button onClick={closeHandler}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
							</Button>
						</Tooltip>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default ShortcutManual;
