import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
	requestedNewPC: "",
	newPC: "",
	oraclePC: "",
	note: "",
	reqStat: "",
	prefCodeList: [],
	reasonforChange: "",
	isReqNonF01: "No",
	isF01Approval: false,
	reasonNonF01: "",
	requestor: "",
	requestorName: "",
	f01Response: "",
	sameRequestor: false,
};

const prefCodeSlice = createSlice({
	name: "prefCode",
	initialState: InitialState,
	reducers: {
		reset() {
			return InitialState;
		},
		setPrefCodeList(state, action) {
			state.prefCodeList = action.payload;
		},

		setPrefCodeInfo(state, action) {
			const data = action.payload.data;
			const userNTID = action.payload.userNTID;
			const userIsF01 = action.payload.userIsF01;
			state.note = data.part_note;
			state.newPC = data.new_pc;
			state.requestedNewPC = data.new_pc;
			state.oraclePC = data.oracle_pc;
			state.reqStat = data.req_stat;
			state.isReqNonF01 = data.is_req_nonf01;
			state.reasonNonF01 = data.reason_nonf01;
			state.requestor = data.requestor;
			state.requestorName = data.requestor_username;
			state.reviewStatus = data.review_status;
			state.sameRequestor = userNTID === data.requestor;
			state.f01Response = "";

			if (data.review_status === "Approved") {
				state.f01Response = data.prefcode_response;
			}

			// TODO: is data.req_stat required to be checked?
			if (data.review_status === "Pending" && data.is_req_nonf01 === "Yes" && userIsF01) {
				state.isF01Approval = true;
			}
		},

		setNewPC(state, action) {
			state.newPC = action.payload;
		},

		setIsF01Approval(state, action) {
			state.isF01Approval = action.payload;
		},

		setCopyNote(state, action) {
			state.reasonforChange = action.payload;
		},

		setReasonChanged(state, action) {
			state.reasonforChange = action.payload;
		},

		setResponseChanged(state, action) {
			state.f01Response = action.payload;
		},
	},
});

export const prefCodeAction = prefCodeSlice.actions;

export default prefCodeSlice;
