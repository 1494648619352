// React
import { useSelector, useDispatch } from "react-redux";
import { memo, useState } from "react";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";

// UI
import { Button, Box, Tooltip, Menu, MenuItem, Modal, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import InfoTable from "../../../UI/Table";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import UploadFile from "../PopUp/UploadFile";
import ErrorBox from "../../../Util/ErrorBox";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import EditDescription from "../PopUp/EditDescription";
// Project
import { S3Settings } from "../S3Settings";
import { s3ppDocsName, s3BucketName, APIURL, downloadBlob, formatDate, DateSortingComparator } from "../../../Util/Util";
import useHttp from "../../../../Hooks/use-http";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const RoHSDocumentTable = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchData } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };

	const rows = useSelector((state) => state.rohsStates.linkInfo);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadedFile, setUploadedFile] = useState("");
	const [uploadedFileType, setUploadedFileType] = useState("");

	const [successMsg, setSuccessMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [RoHSDocErrorState, setRoHSDocErrorState] = useState(false);

	// Right click on the table
	const [contextMenu, setContextMenu] = useState(null);
	const [selectedRow, setSelectedRow] = useState();

	const selectedMpn = useSelector((state) => state.rohsStates.selectedMpn);
	const fileDetails = useSelector((state) => state.rohsStates.fileDetails);
	const itemPartNumber = useSelector((state) => state.rohsStates.partInfo.partNumber);
	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const Non_Mlt_Eng_Access = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);

	const columnVisibility = useSelector((state) => state.rohsStates.tableDocumentColumnVisibility);

	const columnsSettings = [
		{
			field: "fileName",
			headerName: "File Name",
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Tooltip title={Non_Mlt_Eng_Access ? "Click to Download" : "Click to Download, Right click to delete"} placement={"bottom-start"}>
							<Box sx={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="center">
								<span
									className="table-cell-trucate"
									style={{ textDecoration: "underline", textDecorationThickness: 1, fontWeight: "bold", textUnderlinePosition: "under" }}
								>
									{params.value}
								</span>
								{"\u00A0"}
								{params.value && <DownloadIcon />}
							</Box>
						</Tooltip>
					</>
				);
			},
		},
		{ field: "fileExt", headerName: "Type", type: "text", minWidth: 50, flex: 0.25 },
		{ field: "description", headerName: "Document Description", type: "text", minWidth: 160, flex: 1 },
		{ field: "addBy", headerName: "Added by", type: "text", minWidth: 160, flex: 1 },
		{ field: "dataAdded", headerName: "Date Added", type: "text", minWidth: 160, flex: 1, sortComparator: DateSortingComparator },
	];

	const handleFileUpload = async (event) => {
		// exit function on cancel file upload
		if (!event.target.files) {
			return;
		}

		const file = event.target.files[0];
		if (!selectedMpn) {
			dispatch(RoHSAction.setUploadErrorMsg("No MPN associated with keysight Part. Datasheet can only be added to active MPN"));
		} else if (event.target.files[0].size > 200000000) {
			dispatch(RoHSAction.setUploadErrorMsg("File size cannot exceed 200MB"));
		} else {
			setUploadedFile(file);
			if (event.target.files[0]?.name?.indexOf(".") === -1) {
				setUploadedFileType("");
			} else {
				setUploadedFileType(event.target.files[0]?.name?.split(".")?.pop());
			}
		}

		dispatch(RoHSAction.setShowUploadFilePopUp(true));

		// Reset input file
		event.target.value = "";
	};

	const handleOnCellClick = async (params) => {
		// Download Selected file
		if (params.field === "fileName" && params.value) {
			const fullFileName = params.row.filePath.split(`${s3ppDocsName}/`).pop();
			setIsLoading(true);
			const S3_Params = {
				Bucket: s3BucketName,
				Key: `${s3ppDocsName}/${fullFileName}`,
			};

			const getS3Config = await S3Settings({ url: `${APIURL}Rohs/getS3ConnectionDetails`, headers });

			if (getS3Config.error) {
				console.log(getS3Config.errorMsg);
				setIsLoading(false);
				setRoHSDocErrorState(true);
				setErrorMsg(`${getS3Config.errorMsg}, please try again`);
			} else {
				getS3Config.getObject(S3_Params, (err, data) => {
					if (err) {
						setIsLoading(false);
						console.log(err, err.stack);
						setRoHSDocErrorState(true);
						setErrorMsg(`ERROR: ${err} Please try again`);
					} else {
						setIsLoading(false);
						const newBlob = new Blob([data.Body], { type: "application/octet-stream;charset=utf-8;" });
						downloadBlob(newBlob, `${fullFileName}`);
					}
				});
			}
		}
	};

	const handleClose = () => {
		setContextMenu(null);
	};

	const deleteFile = async () => {
		handleClose();
		setIsLoading(true);
		const selectedFileDetails = rows.find((data) => data.id === selectedRow);

		const fileMetaData = {
			...fileDetails,
			userid: userNTID,
			doc_id: selectedFileDetails.docId,
			upload_delete_link_docs: "Delete",
		};

		await fetchData(
			{ url: `${APIURL}Rohs/editLinkDoc`, method: "POST", headers, body: fileMetaData },
			(data) => {
				dispatch(RoHSAction.updateLinksDoc(data));
				dispatch(RoHSAction.retrieveMpn({ mpn: "reload" }));
			},
			(error) => {
				console.log("ERROR: Unexpected Error occured when deleting Link Info", error);
				setRoHSDocErrorState(true);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when deleting Document, please try again`);
			}
		);

		const selectedFileName = selectedFileDetails.filePath.split(`ppdocs/`).pop();

		const s3_Params = { Bucket: s3BucketName, Key: `${s3ppDocsName}/${selectedFileName}` };

		const getS3Config = await S3Settings({ url: `${APIURL}Rohs/getS3ConnectionDetails`, headers });

		if (getS3Config.error) {
			console.log(getS3Config.errorMsg);
			setIsLoading(false);
			setRoHSDocErrorState(true);
			setErrorMsg(`${getS3Config.errorMsg}, please try again`);
		} else {
			await getS3Config.deleteObject(s3_Params, (err, data) => {
				if (err) {
					// error
					setIsLoading(false);
					console.log(err, err.stack);
				} else {
					// deleted
					setIsLoading(false);
					setSuccessMsg("File Deleted Successfully");
					setTimeout(() => {
						setSuccessMsg("");
					}, 1500);
				}
			});
		}
	};

	const handleContextMenu = (event) => {
		event.preventDefault();
		if (rows.length > 0 && !Non_Mlt_Eng_Access) {
			setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
			setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
		}
	};

	const handleOnCellDoubleClick = (params) => {
		console.log(params);
		if (params.field === "description") {
			dispatch(RoHSAction.setShowEditDescriptionPopUp(true));
			dispatch(RoHSAction.AddEditDescriptionFileDetails(params.row));
		}
	};

	const rightClickMenu = (
		<Menu
			open={contextMenu !== null}
			onClose={handleClose}
			anchorReference="anchorPosition"
			disableScrollLock
			anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
			componentsProps={{
				root: {
					onContextMenu: (e) => {
						e.preventDefault();
						handleClose();
					},
				},
			}}
		>
			<MenuItem onClick={deleteFile}>Delete</MenuItem>
		</Menu>
	);

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `RoHSDocument_${itemPartNumber}_${formatDate(new Date(), "")}`,
				}}
			/>
			{props.nonMtlEngAccess ? (
				""
			) : (
				<>
					<Button component="label" startIcon={<AttachFileIcon />}>
						Add document
						<input type="file" id="uploadFileInput" ref={props.hiddenFileInputRef} hidden onChange={handleFileUpload} />
					</Button>
					<Typography sx={{ ml: 2 }} variant="caption">
						*Double click description to edit
					</Typography>
				</>
			)}
		</>
	);
	return (
		<>
			{successMsg && (
				<Modal open={successMsg ? true : false} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
					<Box sx={style}>
						<Typography mb={2} ml={0.4} fontSize={20}>
							{successMsg}
						</Typography>
					</Box>
				</Modal>
			)}
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setRoHSDocErrorState} ErrorState={RoHSDocErrorState} />
			<UploadFile
				uploadedFile={uploadedFile}
				setUploadedFile={setUploadedFile}
				uploadedFileType={uploadedFileType}
				setUploadedFileType={setUploadedFileType}
			/>
			<EditDescription />
			<InfoTable
				columnsSettings={columnsSettings}
				headerHeight={45}
				customToolBar={customToolBar}
				rows={rows.length > 0 ? rows : [{ id: 0 }]}
				checkboxSelection={false}
				handleOnCellClick={handleOnCellClick}
				rightClickMenu={rightClickMenu}
				handleContextMenu={handleContextMenu}
				handleOnCellDoubleClick={handleOnCellDoubleClick}
				ExtraProps={{
					columnVisibilityModel: columnVisibility,
					onColumnVisibilityModelChange: (newModel) => {
						dispatch(RoHSAction.setDocumentColumnVisibility({ columnVisibility: newModel }));
					},
				}}
			></InfoTable>
		</>
	);
};

export default memo(RoHSDocumentTable);
