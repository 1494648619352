// UI
import { Autocomplete, createFilterOptions, Checkbox, Typography } from "@mui/material";
import ColoredTextField from "./ColoredTextField";

const AutocompleteCheckBox = (props) => {
	const { options, handleOnChange, optionLabel, hasSelectAll, error, errorMsg, label, ExtraProps, extraTextfieldProps } = props;

	const value = props.value || null;
	const selectAllLabel = props.selectAllLabel || "Select All";

	const allSelected = options.length === value.length;

	const handleToggleSelectAll = () => {
		if (allSelected) {
			handleOnChange([]);
		} else {
			handleOnChange(options);
		}
	};

	const onChange = (event, newValue) => {
		if (newValue.find((option) => option.value === "select-all")) {
			handleToggleSelectAll();
		} else {
			handleOnChange(newValue);
		}
	};

	const filter = createFilterOptions();

	return (
		<>
			<Autocomplete
				multiple
				fullWidth
				id="AutoComplete CheckBox"
				options={options}
				{...ExtraProps}
				disableCloseOnSelect
				getOptionLabel={(option) => (option.value === "select-all" ? selectAllLabel : optionLabel(option))}
				onChange={onChange}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);
					if (hasSelectAll) {
						return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
					} else {
						return filtered;
					}
				}}
				value={value}
				isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
				renderOption={(props, option, { selected }) => {
					const selectAllProps = option.value === "select-all" ? { checked: allSelected } : {};
					return (
						<li {...props}>
							<Checkbox style={{ marginRight: 8 }} checked={selected} {...selectAllProps} />
							{option.value === "select-all" ? selectAllLabel : optionLabel(option)}
						</li>
					);
				}}
				renderInput={(params) => <ColoredTextField {...extraTextfieldProps} error={error} focused {...params} label={label} />}
			/>
			{error && (
				<Typography variant="caption" color={"secondary"}>
					{errorMsg ? errorMsg : "Please choose at least one"}
				</Typography>
			)}
		</>
	);
};

export default AutocompleteCheckBox;
