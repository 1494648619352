// Packages Import
import { useState } from "react";
import {
	Box,
	Tooltip,
	Button,
	Modal,
	Grid,
	AppBar,
	Toolbar,
	Autocomplete,
	Typography,
	Link,
	IconButton,
	Stack,
	Paper,
	List,
	ListItem,
	ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// Project Component
import ColoredTextField from "../../UI/ColoredTextField";
import useHttp from "../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import { AdminAction } from "../../../store/Admin/Admin-slice";
import { APIURL, OSAltKey, downloadBlob, s3BucketName, s3ppDocsName } from "../../Util/Util";
import { S3Settings } from "../../Tabs/RoHS/S3Settings";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const RemoveUser = (props) => {
	const { onSuccess = () => {}, onError = () => {} } = props;
	const dispatch = useDispatch();
	const { sendRequest: fetchData } = useHttp();
	const [loading, setLoading] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.adminState.removeUser);
	const showPopup = useSelector((state) => state.adminState.openRemoveUser);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" },
		maxHeight: "85%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};
	let containsRow = false;

	if (data.ntUsers.length > 0) {
		data.ntUsers.forEach((user) => {
			if (user.ntid) {
				containsRow = true;
			}
		});
	}

	const closeHandler = () => {
		dispatch(AdminAction.setOpenRemoveUser(false));
		dispatch(AdminAction.resetRemoveUser());
	};

	const handleAddRow = () => {
		dispatch(AdminAction.addNewUser());
	};
	const handleRemoveRow = (id) => () => {
		dispatch(AdminAction.removeUser(id));
	};

	const handleDeleteUser = () => {
		closeHandler();
		const mappedUserNTID = data.ntUsers.map((user) => {
			return user.ntid;
		});
		const jsonReq = {
			userid: userInfo.id,
			userNTIds: mappedUserNTID,
		};
		setLoading(true);
		fetchData(
			{
				url: `${APIURL}admin/deleteUser`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(retData) => {
				const resultMessage = data.ntUsers.map((user) => {
					const result = retData.Result.find((result) => {
						return result.userid === user.ntid;
					});
					if (result) {
						return <Typography>{`${user.ntUsername} <${user.ntid}> \u2192 Status: ${result.status}`}</Typography>;
					}

					return <Typography>{`${user.ntUsername} <${user.ntid}> \u2192 Unknown error`}</Typography>;
				});
				const Message = () => {
					return (
						<Stack>
							<Typography>{"Summary"}</Typography>
							<Paper sx={{ height: 300, overflow: "auto" }}>
								<List disablePadding component="div" role="list">
									{resultMessage.map((value, index) => {
										const labelId = `transfer-list-item-${value}-label`;
										return (
											<ListItem key={index} role="listitem" divider>
												<ListItemText id={labelId} primary={value} sx={{ m: 0 }} />
											</ListItem>
										);
									})}
								</List>
							</Paper>
						</Stack>
					);
				};
				onSuccess(Message);
				setLoading(false);
			},
			(error) => {
				onError(error.toString());
				setLoading(false);
			}
		);
	};

	const downloadDocument = async () => {
		setLoading(true);
		const docName = "Add_Remove_User_ActiveRoles.pdf";
		const S3_Params = {
			Bucket: s3BucketName,
			Key: `${s3ppDocsName}/${docName}`,
		};
		const getS3Config = await S3Settings({ url: `${APIURL}Rohs/getS3ConnectionDetails`, headers });
		if (getS3Config.error) {
			console.log(getS3Config.errorMsg);
			setLoading(false);
			onError(`${getS3Config.errorMsg}, please try again`);
		} else {
			getS3Config.getObject(S3_Params, (err, data) => {
				if (err) {
					console.log(err, err.stack);
					setLoading(false);
					onError(`ERROR: ${err} Please try again`);
				} else {
					const newBlob = new Blob([data.Body], { type: "application/octet-stream;charset=utf-8;" });
					downloadBlob(newBlob, `${docName}`);
					setLoading(false);
				}
			});
		}
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								closeHandler();
							}
							if (e.code === "KeyD") {
								e.preventDefault();
								if (containsRow) {
									handleDeleteUser();
								}
							}
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Admin - Delete User"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Grid
						container
						direction="row"
						spacing={0}
						rowSpacing={2}
						sx={{
							py: 2,
							mt: 3,
						}}
					>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Typography variant="subtitle">
								Removing User in MLAR AD Global Group is mandatory for removing MLAR access for Active Keysight
								employee. Terminated keysight employees will be auto removed from the AD Global Group. Refer document{" "}
								<Link
									onClick={downloadDocument}
									color={"secondary"}
									sx={{
										cursor: "pointer",
									}}
								>
									Add_Remove_User_ActiveRoles.pdf
								</Link>{" "}
								for step-by-step process on removing user
							</Typography>
						</Grid>
						{data?.ntUsers.length > 0
							? data.ntUsers.map((row) => {
									const handleNtUsernameSelection = (event, value) => {
										dispatch(
											AdminAction.updateRemoveUsers({
												id: row.id,
												ntid: value?.ntid,
												ntUsername: value?.ntUsername,
												primaryOrg: value?.primaryOrg,
												secondaryOrg: value?.secondaryOrg,
											})
										);
									};
									return (
										<Grid container item direction="row" spacing={0} rowSpacing={2} key={row.id}>
											<Grid item xl={5} lg={5} md={5} sm={5} xs={12} sx={{ pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 } }}>
												{/* <ColoredTextField
													label={<b>NTUsername</b>}
													fullWidth
													focused
													// onChange={handleInputChange("ntUsername")}
													value={data.ntUsers}
													InputLabelProps={{
														shrink: true,
													}}
												/> */}
												<Autocomplete
													autoHighlight
													onChange={handleNtUsernameSelection}
													options={data.ntUsernameList}
													getOptionLabel={(option) =>
														option.ntUsername ? `${option.ntid} <${option.ntUsername}>` : ""
													}
													isOptionEqualToValue={(option, value) => {
														// console.log(value)
														return option.ntid === value.ntid;
													}}
													size="small"
													value={!row.ntid ? null : row}
													renderInput={(params) => (
														<ColoredTextField
															focused
															label={<b>NTUsername</b>}
															{...params}
															InputProps={{
																...params.InputProps,
																sx: { textOverflow: "ellipsis", backgroundColor: "#fff" },
															}}
														/>
													)}
												/>
											</Grid>
											<Grid
												item
												xl={2}
												lg={2}
												md={2}
												sm={2}
												xs={12}
												sx={{
													pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
												}}
											>
												<ColoredTextField
													value={row.primaryOrg}
													label={<b>Primary Org</b>}
													fullWidth
													focused
													disabled={true}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
												<ColoredTextField
													value={row.secondaryOrg}
													label={<b>Secondary Org</b>}
													fullWidth
													focused
													disabled={true}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
												<Box
													display={"flex"}
													ml={1}
													justifyContent={"center"}
													sx={{
														backgroundColor: "#FF0000",
														// borderTopRightRadius: "5px",
														// borderBottomRightRadius: "5px",
														"&:hover": {
															backgroundColor: "#fc6b6b",
														},
													}}
												>
													<IconButton size="medium" onClick={handleRemoveRow(row.id)}>
														<HighlightOffIcon style={{ fill: "white" }} />
													</IconButton>
												</Box>
											</Grid>
										</Grid>
									);
							  })
							: undefined}
					</Grid>
					<Box sx={{ display: "flex" }}>
						<Box flex={"1"}>
							<Button
								startIcon={<AddCircleOutlineIcon />}
								onClick={handleAddRow}
								onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
								sx={{
									py: 0,
									borderRadius: 0,
									backgroundColor: "transparent",
									textTransform: "none",
									color: "#000000",
									fontSize: "1 rem",
									"& .MuiButton-endIcon": {
										marginLeft: 0,
									},
									"&:hover": {
										color: "#0f0fff",
										textTransform: "none",
										backgroundColor: "transparent",
									},
								}}
							>
								Add more user
							</Button>
						</Box>
						<Box>
							<Tooltip title={`For shortcut press ${OSAltKey} + D`}>
								<span>
									<Button disabled={!containsRow} onClick={handleDeleteUser}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>D</u>elete User
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
								<span>
									<Button onClick={closeHandler}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</span>
							</Tooltip>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default RemoveUser;
