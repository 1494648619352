import { Typography, Box, Link, Stack } from "@mui/material";
import logo from "../Static/keysight-logo.svg";
import { SSO_RELOGIN_URL } from "../Components/Util/Util";

const LogOut = () => {
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Stack spacing={2}>
				<Box ml={-6} component="img" height={"8rem"} mb={15} alt="Keysight Logo" src={logo} />
				<Typography variant="h4" color={"secondary"} sx={{ fontWeight: "bold" }}>
					Logged Out
				</Typography>
				<Typography variant="h5">You have successfully logged out</Typography>
				<Link color={"secondary"} underline={"hover"} variant="h6" sx={{ fontWeight: "bold" }} href={SSO_RELOGIN_URL}>
					Click here to Relogin
				</Link>
			</Stack>
		</Box>
	);
};

export default LogOut;
