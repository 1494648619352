// React
import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";
import { useState } from "react";

// UI
import { Box, Button, Modal, Stack, Typography, Tooltip } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import ErrorBox from "../../../Util/ErrorBox";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";

// Project
import useHttp from "../../../../Hooks/use-http";
import { S3Settings } from "../S3Settings";
import { s3BucketName, s3ppDocsName, APIURL, OSAltKey } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	pb: 2,
};

const UploadFile = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchData } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const { primaryOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };

	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [uploadSuccessMsg, setUploadSuccessMsg] = useState("");
	const [RoHSDocErrorState, setRoHSDocErrorState] = useState(false);

	const open = useSelector((state) => state.rohsStates.showUploadFilePopUp);
	const uploadErrorMsg = useSelector((state) => state.rohsStates.uploadErrorMsg);
	const uploadDescription = useSelector((state) => state.rohsStates.uploadDescription);
	// const lastLinkFilesName = useSelector((state) => state.rohsStates.lastLinkFilesName);
	const fileDetails = useSelector((state) => state.rohsStates.fileDetails);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const handleEdit = (event) => {
		if (event.target.value?.slice(-1) !== "¨") {
			dispatch(RoHSAction.handleUploadMsg(event.target.value));
		}
	};

	const uploadFileHandler = async () => {
		setIsLoading(true);
		let fileUploadFailed = false;

		const formattedPartNumber = fileDetails.item.replace("-", "_");
		// const newFileNumber = ("000" + (parseInt(lastLinkFilesName.slice(-3)) + 1)).slice(-3);
		const timestamp_seconds = Math.floor(Date.now() / 1000);
		// const timestamp = Date.now();
		const newFileName = `${formattedPartNumber}_${timestamp_seconds}`;
		const newFullFileName = `${newFileName}.${props.uploadedFileType}`;
		console.log(newFullFileName);

		const params = {
			Bucket: s3BucketName,
			Key: `${s3ppDocsName}/${newFullFileName}`,
			Expires: 2600,
			Body: props.uploadedFile,
		};

		// Get S3 secret from Jango
		const getS3Config = await S3Settings({ url: `${APIURL}Rohs/getS3ConnectionDetails`, headers });

		if (!getS3Config.error) {
			await getS3Config
				.upload(params)
				.promise()
				.then(
					(data) => {
						console.log("Success");
						setErrorMsg("");
					},
					(err) => {
						setIsLoading(false);
						fileUploadFailed = true;
						setRoHSDocErrorState(true);
						dispatch(RoHSAction.setShowUploadFilePopUp(false));
						console.log(`ERROR: Error when uploading your file, ${err.message} please try again`);
						setErrorMsg(`ERROR: Error when uploading your file, ${err.message} please try again`);
					}
				);
		} else {
			setIsLoading(false);
			fileUploadFailed = true;
			setRoHSDocErrorState(true);
			dispatch(RoHSAction.setShowUploadFilePopUp(false));
			console.log(`ERROR: ${getS3Config.errorMsg}, please refresh the page and try again`);
			setErrorMsg(`ERROR: ${getS3Config.errorMsg}, please refresh the page and try again`);
		}

		if (!fileUploadFailed) {
			const fileMetaData = {
				...fileDetails,
				userid: userNTID,
				userorg: primaryOrg,
				filename: newFileName,
				description: uploadDescription,
				filepath: `s3://${s3BucketName}/${s3ppDocsName}/${newFullFileName}`,
				fileext: props.uploadedFileType.toUpperCase(),
				upload_delete_link_docs: "Upload",
			};

			await fetchData(
				{ url: `${APIURL}Rohs/editLinkDoc`, method: "POST", headers, body: fileMetaData },
				(data) => {
					dispatch(RoHSAction.updateLinksDoc(data));
					dispatch(RoHSAction.retrieveMpn({ mpn: "reload" }));
					setUploadSuccessMsg("File Uploaded Successfully");
					setErrorMsg("");
					setTimeout(() => {
						dispatch(RoHSAction.setShowUploadFilePopUp(false));
						setUploadSuccessMsg("");
					}, 1500);
				},
				(error) => {
					// Delete the uploaded file from S3
					const s3_Params = { Bucket: s3BucketName, Key: `${s3ppDocsName}/${newFullFileName}` };

					getS3Config.deleteObject(s3_Params, (err, data) => {
						if (err) {
							// error
							console.log(err, err.stack);
						} else {
							// deleted
							console.log(data);
						}
					});

					console.log("ERROR: Unexpected Error occured when updating Link Info", error);
					setErrorMsg(`ERROR: ${error}. Unexpected Error occured when updating Document, please try again`);
					setRoHSDocErrorState(true);

					dispatch(RoHSAction.setShowUploadFilePopUp(false));
				}
			);
		}

		props.setUploadedFile("");
		props.setUploadedFileType("");
		setIsLoading(false);
	};

	const cancelUploadFileHandler = () => {
		dispatch(RoHSAction.setShowUploadFilePopUp(false));
		dispatch(RoHSAction.setUploadErrorMsg(""));
		dispatch(RoHSAction.handleUploadMsg("DataSheet"));
		props.setUploadedFile("");
		props.setUploadedFileType("");
	};

	const handleOnKeyDown = (e) => {
		if (e.code === "KeyU" && e.altKey === true) {
			e.preventDefault();
			uploadFileHandler();
		} else if (e.code === "KeyC" && e.altKey === true) {
			e.preventDefault();
			cancelUploadFileHandler();
		} else if (e.code === "KeyC" && uploadErrorMsg) {
			e.preventDefault();
			cancelUploadFileHandler();
		}
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setRoHSDocErrorState} ErrorState={RoHSDocErrorState} />

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						handleOnKeyDown(e);
					}}
				>
					{uploadErrorMsg ? (
						<>
							<Typography mb={2} ml={0.4} fontSize={20}>
								{uploadErrorMsg}
							</Typography>
							<Tooltip title={"Press C to close"}>
								<Button onClick={cancelUploadFileHandler}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
								</Button>
							</Tooltip>
						</>
					) : uploadSuccessMsg ? (
						<Typography mb={2} ml={0.4} fontSize={20}>
							{uploadSuccessMsg}
						</Typography>
					) : (
						<>
							<Stack mb={2} spacing={2}>
								<ColoredTextField
									autoFocus
									onFocus={(e) => {
										// Target the last character of existing value on focus
										// e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
										e.target.select();
									}}
									fullWidth
									label={`Enter description of the file you want to load`}
									minRows={3}
									maxRows={3}
									multiline
									focused
									onChange={handleEdit}
									value={uploadDescription}
								/>
							</Stack>

							<Box>
								<Tooltip title={`For shortcut press ${OSAltKey} + U`}>
									<Button onClick={uploadFileHandler} disabled={isLoading}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>U</u>pload
									</Button>
								</Tooltip>

								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<Button onClick={cancelUploadFileHandler}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</Tooltip>
							</Box>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</>
					)}
				</Box>
			</Modal>
		</Box>
	);
};

export default UploadFile;
