import { createSlice } from "@reduxjs/toolkit";
import { isEmptyObject} from "../../Components/Util/Util";
import moment from "moment";

const defaultProjectsList = [{
	id: "",
	label: ""
}]

const defaultAsyncList = {
	projectManager:[{
		id: "",
		label: ""
	}],
	npiEng:[{
		id: "",
		label: ""
	}],
	pcdfm:[{
		id: "",
		label: ""
	}],
	designer:[{
		id: "",
		label: ""
	}],
	liaison:[{
		id: "",
		label: ""
	}]
}



const defaultProjectInfo = [{
	proId: "",
	project: "",
	oldProject: "",
	oldProId: "",
	title: "",
	liaison: "",
	division: "",
	projectManager: "",
	npiEng: "",
	instrument: "",
	pcdfm: "",
	designer: "" ,
	dateCompleted:"",
	rptDeliveryDueDate:"",
	inputDueDate: "",
	comment: "",
	projectTyped: "",
	projectManagerTyped: "",
	npiEngTyped: "",
	pcdfmTyped: "",
	designerTyped: "",
	liaisonTyped: "",
	prevInputDueDate:"",
	prevDateCompleted:"",
	prevRptDeliveryDueDate:"",
	uid:"",
}]

const defaultProjectInfoTable = [{
	id:0,
	bom: "",
	rev: "",
	bomPartsNum: "",
	bomDateLoad: "",
	inputDueDate: "",
	dateCompleted: "",
	rptDeliveryDueDate: "",
	liaison: "",
	project: "",
	division: "",
	uid:"",
}]

const defaultProjectsLogData = [{
    completedInfoUpdatedBy: "",
	cmpltDate: "",
	infoUpdatedBy: "",
	dateUpdated: "",
	uid:"",
}]
const defaultFilteredData = {
    filterOn: "",
    startDate: "",
    endDate: "",
    filterOnProject: "",
    filterOnBOM: "",
	userOrg:"",
}

const editDetailState = {
	state : false,
}

const editNameState = {
	state : false,
}

const errorState = {
	projectName : false,
	projectManager : false,
	npiEng : false,
	pcdfm : false,
	designer : false,
	liaison : false,
}

const defaultColumnVisibility = {
    bom: true,
    rev: true,
    bomPartsNum: true,
    bomDateLoad: true,
    inputDueDate: true,
    rptDeliveryDueDate: true,
    dateCompleted: true,
    liaison: true,
    project: true,
    division: true,
  }

// window.localStorage.removeItem("projectsColumnVisibility") // Incase of problems due to Item
const columnVisibilityStorage = JSON.parse(window.localStorage.getItem("PROJECT_projectsColumnVisibility"))

const initiialProjectsState = {
    PROJECT_LIST_FILTER: defaultProjectsList,
    PROJECT_LIST_INFO: defaultProjectsList,
	PROJECT_INFO: defaultProjectInfo,
	PROJECT_INFO_TABLE: defaultProjectInfoTable,
	PROJECT_LOGS: defaultProjectsLogData,
	filteredData: defaultFilteredData,
	asyncInputDetails:defaultAsyncList,
	prevFilteredData: defaultFilteredData,
	projectIndex: "-",
	orgList: [],
	editDetailState: editDetailState,
	editNameState: editNameState,
	initialPageLoad: true,
	error: errorState,
	tableColumnVisibility: columnVisibilityStorage ? columnVisibilityStorage : defaultColumnVisibility,

};

export const ACTION = {
	RESET: "reset",
	UP_ALL : "Update_All",
};


const projectsSlice = createSlice({
	name: "projects",
	initialState: initiialProjectsState,
	reducers: {
		updateAll(state, action) {
			if(!isEmptyObject(action.payload.data.Projects_Info)){
				let projectInfo = []
				let projectInfoTable = []
				let projectLogs = []
				action.payload.data.Projects_Info.forEach( (singleProjectData , i) =>{ 
					const tableData = singleProjectData.Grid_Section
					const infoData = singleProjectData.Edit_Section
					const logData = singleProjectData.Log_Section
					const dateCompleted = moment(tableData.dcompl , "YYYY-MM-DD").isValid() ? moment(tableData.dcompl , "YYYY-MM-DD").format("YYYY-MM-DD") : ""
					const rptDeliveryDueDate = moment(tableData.dduedate , "YYYY-MM-DD").isValid() ? moment(tableData.dduedate , "YYYY-MM-DD").format("YYYY-MM-DD") : ""
					const inputDueDate = moment(tableData.dinpdate , "YYYY-MM-DD").isValid() ? moment(tableData.dinpdate , "YYYY-MM-DD").format("YYYY-MM-DD") : ""
					const compltDate = moment(logData.tcmplt , "YYYY-MM-DD").isValid() ? moment(logData.tcmplt , "YYYY-MM-DD").format("YYYY-MM-DD") : ""
					const tdateUpdated = moment(logData.tmod , "YYYY-MM-DD").isValid() ? moment(logData.tmod , "YYYY-MM-DD").format("YYYY-MM-DD") : ""
					const retProjectInfo = {
						proId: singleProjectData.pro_id || "",
						project: tableData.project || "",
						projectTyped: tableData.project || "",
						oldProject: tableData.project,
						oldProId: singleProjectData.pro_id,
						title: infoData.label_above || "",
						liaison: infoData.liaison || "",
						liaisonTyped: infoData.liaison || "",
						division: infoData.a_group || "",
						projectManager: infoData.pr_mgr || "",
						projectManagerTyped: infoData.pr_mgr || "",
						npiEng: infoData.npieng || "",
						npiEngTyped: infoData.npieng || "",
						instrument: infoData.instrument || "",
						dateCompleted: dateCompleted,
						rptDeliveryDueDate: rptDeliveryDueDate,
						inputDueDate: inputDueDate,
						pcdfm: infoData.pcdfm || "",
						pcdfmTyped: infoData.pcdfm || "",
						designer: infoData.designer || "" ,
						designerTyped: infoData.designer || "" ,
						note: infoData.comment || "",
						prevInputDueDate:tableData.dinpdate || "",
						prevDateCompleted:tableData.dcompl || "",
						prevRptDeliveryDueDate:tableData.dduedate || "",
						uid:singleProjectData.id || "",
					}
					const retProjectInfoTable = {
						proId: singleProjectData.pro_id || "",
						id:i,
						bom: tableData.bomno || "",
						rev: tableData.rev || "",
						bomPartsNum: tableData.bomcount || "",
						bomDateLoad: tableData.dlastadd || "",
						inputDueDate: tableData.dinpdate || "",
						dateCompleted: tableData.dcompl || "",
						rptDeliveryDueDate: tableData.dduedate || "",
						liaison: tableData.liaison || "",
						project: tableData.project || "",
						division: tableData.a_group || "",
						uid:singleProjectData.id || "",
					}
					const retProjectLogs = {
						proId: singleProjectData.pro_id || "",
						completedInfoUpdatedBy: logData.tcmpltby || "",
						cmpltDate: compltDate,
						infoUpdatedBy: logData.modby || "",
						dateUpdated: tdateUpdated,
						uid:singleProjectData.id || "",
					}
					projectInfo.push(retProjectInfo)
					projectInfoTable.push(retProjectInfoTable)
					projectLogs.push(retProjectLogs)
				})
				state.PROJECT_INFO = projectInfo
				state.PROJECT_INFO_TABLE = projectInfoTable
				state.PROJECT_LOGS = projectLogs
				if(state.projectIndex === "-"){
					state.projectIndex = projectInfoTable[0].uid
				}
			}else{
				state.PROJECT_INFO = defaultProjectInfo
				state.PROJECT_INFO_TABLE = defaultProjectInfoTable
				state.PROJECT_LOGS = defaultProjectsLogData
			}
        },
		updateProjectsList(state, action) {
			const updateInFilter = () => {
				if(!isEmptyObject(action.payload.data.Projects_List)){
					let projects = []
					action.payload.data.Projects_List.forEach( (singleProjectData , i) =>{ 
						const retProject = {
							id: singleProjectData.pro_id,
							label: singleProjectData.project,
						}
						projects.push(retProject)
					})
					state.PROJECT_LIST_FILTER = projects
				}else{
					state.PROJECT_LIST_FILTER = defaultProjectsList
				}
			}
			const updateInInfo = () => {
				if(!isEmptyObject(action.payload.data.Projects_List)){
					let projects = []
					action.payload.data.Projects_List.forEach( (singleProjectData , i) =>{ 
						const retProject = {
							id: singleProjectData.pro_id,
							label: singleProjectData.project,
						}
						projects.push(retProject)
					})
					state.PROJECT_LIST_INFO = projects
				}else{
					state.PROJECT_LIST_INFO = defaultProjectsList
				}
			}

			switch(action.payload.area){
				case "filter":
					updateInFilter()
					break
				case "info":
					updateInInfo()
					break
				case "all":
					updateInFilter()
					updateInInfo()
					break
				default:
					break
			}
			
		},

		updateFilteredData(state, action) {
			
			for(let key in action.payload.data){
				state.filteredData[key] = action.payload.data[key]
				sessionStorage.setItem(`SearchFilterArea.${key}` ,action.payload.data[key] )
			}	
		},
		updateProjectIndex(state, action){
			state.projectIndex = action.payload.val
		},
		updateProjectInfo(state, action){
			for(let key in action.payload.data){
				state.PROJECT_INFO[action.payload.id][key] = action.payload.data[key]
			}
		},
		updateOrg(state, action){
			state.orgList.length = 0
			state.orgList.push(action.payload.userOrg)
			for(let i in action.payload.data.USER_ORGS_LIST){
				let org = action.payload.data.USER_ORGS_LIST[i].user_org_c
				if(org === action.payload.userOrg){
					continue
				}else{
					state.orgList.push(org)
				}
			}
		},
		updateEditDetailState(state , action){
			for(let key in action.payload.data){
				state.editDetailState[key] = action.payload.data[key]
			}
		},
		updateEditNameState(state , action){
			for(let key in action.payload.data){
				state.editNameState[key] = action.payload.data[key]
			}
		},
		setPageLoadState(state , action){
			state.initialPageLoad = action.payload.initialPageLoad
		},
		savePrevFilterData(state , action){	
			for(let key in action.payload.data){
				state.prevFilteredData[key] = action.payload.data[key]
			}	

		},
		setError(state,action){
			for(let key in action.payload.data){
				state.error[key] = action.payload.data[key]
			}	
		},
		updateAsyncInputDetails(state, action){
			for(let key in action.payload.data){
				const employeeList = action.payload.data[key];
				if(!isEmptyObject(employeeList)){
					let tempArray = []
					employeeList.forEach( (singleData , i) =>{ 
						const retProject = {
							id: singleData,
							label: singleData,
						}
						tempArray.push(retProject)
					})
					state.asyncInputDetails[key] = tempArray
				}else{
					state.asyncInputDetails[key] = defaultAsyncList[key]
				}
			}
		},
		setColumnVisibility(state, action){
            state.tableColumnVisibility = action.payload.columnVisibility
            window.localStorage.setItem("PROJECT_projectsColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
        },

	},
});

export const projectsAction = projectsSlice.actions;

export default projectsSlice;
