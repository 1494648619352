// React
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
// UI
import {
	Box,
	Modal,
	Typography,
	Paper,
	Grid,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox,
	Select,
	MenuItem,
	Tooltip,
	Button,
	Autocomplete,
	CircularProgress,
	AppBar,
	Toolbar,
	Stack,
	List,
	ListItemButton,
	ListItemText,
	listItemButtonClasses,
} from "@mui/material";
import DropdownFilter from "../../../UI/DropdownFilter";
import ColoredTextField from "../../../UI/ColoredTextField";
// Packages Import
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import { OSAltKey, APIURL, isNumber, PDF_GEN_JWT_TOKEN_URL, PDF_GEN_URL } from "../../../Util/Util";
import SetFileName from "../../../Util/SetFileName";
import useDownloadFileWithRename from "../../../../Hooks/use-http-excel-r";

let inputTimeOut;

const OpTempReport = (props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [openSubsetList, setOpenSubsetList] = useState(false);
	const [bomListLoad, setBomListLoad] = useState(false);

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const getBomList =
		typeof props.getBomList === "function"
			? props.getBomList
			: () => {
					return;
			  };

	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const { downloadExcelFile } = useDownloadFileWithRename();

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const showPopup = useSelector((state) => state.reportsState.popUpStates.temperature.opTemp);
	const orgList = useSelector((state) => state.reportsState.orgList);
	const bomList = useSelector((state) => state.reportsState.bomList);
	const data = useSelector((state) => state.reportsState.reports.temperature.opTemp);
	const disableExportButton =
		(data.mlarBomOrSubset === "mlarbom" && data.selectedBom === null) || (data.mlarBomOrSubset === "subset" && data.subsetID === "");

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};

	const closeHandler = () => {
		dispatch(reportsActions.updatePopUpState());
		dispatch(reportsActions.reset());
	};

	const handleOrgChange = (value) => {
		let update = {};
		update = { selectedBom: null, subsetID: "", mlarBomOrSubset: "mlarbom" };

		dispatch(
			reportsActions.updateData({
				data: {
					userOrg: value,
					...update,
				},
			})
		);
		getBomList(data.daysBack, value, setBomListLoad);
	};

	const handleMLARBOMChanged = (newValue) => {
		dispatch(
			reportsActions.updateData({
				data: {
					selectedBom: newValue,
				},
			})
		);
	};

	const handleDayNum = (event) => {
		const inpVal = event.target.value;
		if (isNumber(inpVal) || inpVal === "") {
			dispatch(
				reportsActions.updateData({
					data: {
						daysBack: inpVal,
					},
				})
			);

			if (inpVal !== "") {
				clearTimeout(inputTimeOut);
				const timeoutSec = 500;
				inputTimeOut = setTimeout(() => {
					getBomList(inpVal, data.userOrg, setBomListLoad);
				}, timeoutSec);
			}
		}
	};

	const handleReportTypeChanged = (event) => {
		dispatch(
			reportsActions.updateData({
				data: {
					printSummaryOrDetail: event.target.value,
					checkItemNote: "0",
					checkMfgNote: "0",
					printNotes: false,
				},
			})
		);
	};

	const handleCheckboxOptions = (option) => {
		let update = {};
		switch (option) {
			case "printToPDF":
				update = { excelPdfReport: "pdf", checkHideInactiveMPNS: "1", checkItemNote: "0", checkMfgNote: "0", printNotes: false };
				break;
			case "exportToExcel":
				update = { excelPdfReport: "excel", checkItemNote: "0", checkMfgNote: "0", printNotes: false };
				break;
			case "printNotes":
				if (data.printNotes) {
					update = { checkItemNote: "0", checkMfgNote: "0", printNotes: false };
				} else {
					if (data.printSummaryOrDetail === "2" && data.excelPdfReport === "pdf") {
						// is details Report or exporting as PDF
						update = { checkItemNote: "1", checkMfgNote: "1", printNotes: true };
					} else {
						update = { checkItemNote: "1", checkMfgNote: "0", printNotes: true };
					}
				}
				break;
			case "printItemNotes":
				if (data.checkItemNote === "0") {
					update = { checkItemNote: "1", printNotes: true };
				} else {
					update = { checkItemNote: "0" };
					if (data.checkMfgNote === "0") {
						// If all print option is unchecked, Print notes will be unchecked
						update = { ...update, printNotes: false };
					}
				}
				break;
			case "printMfgNotes":
				if (data.checkMfgNote === "0") {
					update = { checkMfgNote: "1", printNotes: true };
				} else {
					update = { checkMfgNote: "0" };
					if (data.checkItemNote === "0") {
						// If all print option is unchecked, Print notes will be unchecked
						update = { ...update, printNotes: false };
					}
				}
				break;
			case "hideInactiveMPN":
				update = { checkHideInactiveMPNS: data.checkHideInactiveMPNS === "1" ? "0" : "1" };
				break;
			default:
				break;
		}

		dispatch(
			reportsActions.updateData({
				data: {
					...update,
				},
			})
		);
	};

	const getSubsetList = (isLoading) => {
		fetchDataWithoutLoad(
			{
				url: `${APIURL}subset/subsetList/${userNTID}`,
				headers: headers,
				method: "GET",
			},
			(data) => {
				if (data.Subset_Info.length > 0) {
					const filteredData = data.Subset_Info.map(({ subsetid, ...rest }) => {
						return subsetid;
					});
					dispatch(
						reportsActions.updateData({
							data: {
								subsetNameList: filteredData,
							},
						})
					);
				}
				isLoading(false);
			},
			(error) => {
				isLoading(false);
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
			}
		);
	};

	const handleMLARSubsetChanged = (event) => {
		const value = event.target.value;
		dispatch(reportsActions.updateData({ data: { mlarBomOrSubset: value } }));
		if (value === "subset") {
			setOpenSubsetList(true);
			setLoading(true);
			getSubsetList(setLoading);
		}
	};

	const handleListItemClick = (val) => {
		dispatch(
			reportsActions.updateData({
				data: {
					subsetID: val,
				},
			})
		);
		setOpenSubsetList(false);
	};

	const handleBomNumberChanged = (event) => {
		const val = event.target.value;
		dispatch(
			reportsActions.updateData({
				data: {
					bom: val,
				},
			})
		);
	};

	const closeSubset = () => {
		setOpenSubsetList(false);
		dispatch(reportsActions.updateData({ data: { mlarBomOrSubset: "mlarbom" } }));
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
				setLoading(false);
			});
	};
	const generateReport = () => {
		setLoading(true);
		closeHandler();
		let filename = "";

		let body = {
			userid: userNTID,
			userOrg: data.userOrg,
			mlarBomOrSubset: data.mlarBomOrSubset,
			printSummaryOrDetail: data.printSummaryOrDetail,
			checkHideInactiveMPNS: data.checkHideInactiveMPNS,
			checkItemNote: data.checkItemNote,
			checkMfgNote: data.checkMfgNote,
			excelPdfReport: data.excelPdfReport,
		};

		if (data.mlarBomOrSubset === "subset") {
			body = {
				...body,
				mlar_ppn: data.bom,
				subsetID: data.subsetID,
			};
		}
		if (data.mlarBomOrSubset === "mlarbom") {
			body = {
				...body,
				mlar_ppn: data.selectedBom?.ppn ?? "",
				mlar_prev: data.selectedBom?.prev ?? "",
			};
		}

		if (body.excelPdfReport === "excel") {
				downloadExcelFile(
					{
						url: `${APIURL}reports/maxOpTempReport`,
						headers: headers,
						method: "POST",
						body: body,
					},
					(blob, filename) => {
						setBlob(blob);
						setFileName(filename);
						setOpenSetFileName(true);
					},
					(error) => {
						if (typeof props.onError === "function") {
							props.onError(error.toString());
						}
					},
					() => {
						setLoading(false);
					}
				)
		} else {
			fetchDataWithoutLoad(
				{
					url: `${APIURL}reports/maxOpTempReport`,
					headers: headers,
					method: "POST",
					body: body,
				},
				(data) => {
					if (data?.No_Record) {
						throw Error(data.No_Record);
					}
					fetchDataWithoutLoad(
						{
							url: PDF_GEN_JWT_TOKEN_URL,
							headers: headers,
							method: "POST",
							body: {
								username: "admin",
								password: "pass",
							},
						},
						(token) => handleJWTToken(token, data),
						(error) => {
							setLoading(false);
							if (typeof props.onError === "function") {
								props.onError(error.toString());
							}
						}
					);
				},
				(error) => {
					setLoading(false);
					if (typeof props.onError === "function") {
						props.onError(error.toString());
					}
				}
			);
		}
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName blob={blob} setOpenSetFileName={setOpenSetFileName} openSetFileName={openSetFileName} fileName={fileName} />

			<Modal open={openSubsetList} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC") {
							closeSubset();
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Load Subset"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Stack mb={2} mt={7} spacing={2}>
						{!loading && data.subsetNameList.length > 0 && openSubsetList ? (
							<List component="nav" aria-label="main mailbox folders" sx={{ border: 2 }}>
								{data.subsetNameList.map((subset, index) => (
									<ListItemButton
										sx={{ [`&.${listItemButtonClasses.selected}`]: { backgroundColor: "#D9D9D6" } }}
										key={index}
										onClick={() => handleListItemClick(subset)}
									>
										<ListItemText primary={subset} />
									</ListItemButton>
								))}
							</List>
						) : loading ? (
							<CircularProgress color="inherit" size={"20%"} />
						) : (
							<Typography>No Subset Available</Typography>
						)}
					</Stack>
					<Tooltip title={`For shortcut press C`}>
						<span>
							<Button onClick={closeSubset}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
						</span>
					</Tooltip>
				</Box>
			</Modal>

			<Modal open={showPopup} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC" && e.altKey === true) {
							closeHandler();
						}
						if (e.code === "KeyE" && e.altKey === true && !disableExportButton) {
							generateReport();
						}
					}}
				>
					<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
						<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
							Max. Operating Temperature Report
						</Typography>
						<DropdownFilter
							width={90}
							selections={orgList}
							selectedData={data.userOrg}
							handleSelection={handleOrgChange}
							label={"User Org"}
							InputLabelProps={{
								shrink: true,
								sx: {
									fontWeight: 700,
								},
							}}
						/>
					</Box>
					<Paper sx={{ p: 2, mt: 2, mb: 2 }} elevation={5}>
						<Typography variant="subtitle">Select MLAR part number in the dropdown below or choose the subset button to select a subset</Typography>
					</Paper>

					<Grid container direction="row" spacing={0} alignItems={"center"} sx={{ mb: 1 }}>
						<Grid item xl={data.mlarBomOrSubset === "subset" ? 4.5 : 12} lg={data.mlarBomOrSubset === "subset" ? 4.5 : 12} md={12} sm={12} xs={12}>
							<FormControl>
								<RadioGroup row onChange={handleMLARSubsetChanged} value={data.mlarBomOrSubset}>
									<FormControlLabel value={"mlarbom"} control={<Radio />} label={"MLAR list"} />
									<FormControlLabel value={"subset"} control={<Radio />} label="Subset" />
								</RadioGroup>
							</FormControl>
						</Grid>
						{data.mlarBomOrSubset === "subset" && (
							<Grid item xl={7.5} lg={7.5} md={12} sm={12} xs={12}>
								<Typography variant="subtitle">SubsetID: </Typography>
								<Typography variant="subtitle" color={"rgba(35, 109, 255, 1)"}>
									{data.subsetID}
								</Typography>
							</Grid>
						)}
					</Grid>

					<Grid container direction="row">
						<Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
							{data.mlarBomOrSubset === "mlarbom" ? (
								<ColoredTextField
									label="Days Back"
									fullWidth
									focused
									onChange={handleDayNum}
									value={data.daysBack}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							) : (
								<ColoredTextField
									label="REV"
									fullWidth
									focused
									value={"001"}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
									InputProps={{
										readOnly: true,
									}}
								/>
							)}
						</Grid>

						<Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
							{data.mlarBomOrSubset === "mlarbom" ? (
								<Autocomplete
									sx={{ ml: { sm: 1, xs: 0 }, mt: { sm: 0, xs: 2 } }}
									loading={bomListLoad}
									loadingText={
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<CircularProgress color="inherit" size={"20%"} />
										</Box>
									}
									options={!bomListLoad ? bomList : []}
									value={data.selectedBom}
									getOptionLabel={(option) => `${option.ppn}`}
									renderOption={(props, option) => {
										return (
											<li {...props}>
												<Box>
													<Typography align="center" width={{ xs: "60px", sm: "130px" }} variant="body2">{`${option.ppn}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={1.5} minWidth={"35px"} variant="body2">{`${option.prev}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={1.5} variant="body2">{`${option.date}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={1.5} variant="body2">{`${option.org}`}</Typography>
												</Box>
											</li>
										);
									}}
									renderInput={(params) => <ColoredTextField label={<b>choose MLAR/BOM #</b>} focused {...params} />}
									isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
									onChange={(event, newValue) => handleMLARBOMChanged(newValue)}
								/>
							) : (
								<ColoredTextField
									label={<b>Bom Number to change</b>}
									fullWidth
									focused
									onChange={handleBomNumberChanged}
									value={data.bom}
									sx={{ ml: 1 }}
									inputRef={(input) => data.mlarBomOrSubset === "subset" && input?.focus()}
								/>
							)}
						</Grid>

						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{
								pt: 2,
								mb: 1,
							}}
						>
							<FormControl focused sx={{ width: 300 }}>
								<Select
									size="small"
									value={data.printSummaryOrDetail}
									onChange={handleReportTypeChanged}
									MenuProps={{
										disableScrollLock: true,
										sx: { maxHeight: "60%" },
									}}
								>
									<MenuItem value={"1"}>{"Summary Report"}</MenuItem>
									<MenuItem value={"2"}>{"Detail Report"}</MenuItem>
								</Select>
							</FormControl>
						</Grid>

						<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
							<FormControlLabel
								value="printToPDF"
								control={<Checkbox checked={data.excelPdfReport === "pdf"} onChange={() => handleCheckboxOptions("printToPDF")} />}
								label="Print to PDF file"
							/>
						</Grid>
						<Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
							<FormControlLabel
								sx={{ ml: 0 }}
								value="exportToExcel"
								control={<Checkbox checked={data.excelPdfReport === "excel"} onChange={() => handleCheckboxOptions("exportToExcel")} />}
								label="Export to Excel file"
							/>
						</Grid>

						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<FormControlLabel
								sx={{ mb: -2 }}
								value="printNotes"
								control={<Checkbox checked={data.printNotes} onChange={() => handleCheckboxOptions("printNotes")} />}
								label="Print notes"
							/>
						</Grid>
						<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
							<FormControlLabel
								sx={{ ml: 2 }}
								value="printItemNotes"
								control={<Checkbox checked={data.checkItemNote === "1"} onChange={() => handleCheckboxOptions("printItemNotes")} />}
								label="Print item notes"
							/>
						</Grid>
						<Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
							<FormControlLabel
								sx={{ ml: { sm: 0, xs: 2 } }}
								disabled={data.printSummaryOrDetail === "1" || data.excelPdfReport === "excel"}
								value="printMfgNotes"
								control={<Checkbox checked={data.checkMfgNote === "1"} onChange={() => handleCheckboxOptions("printMfgNotes")} />}
								label="Print MFG notes"
							/>
						</Grid>

						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<FormControlLabel
								value="hideInactiveMPN"
								disabled={data.printSummaryOrDetail === "1"}
								control={<Checkbox checked={data.checkHideInactiveMPNS === "1"} onChange={() => handleCheckboxOptions("hideInactiveMPN")} />}
								label="Hide Inactive MPN's"
							/>
						</Grid>
					</Grid>

					<Grid
						container
						direction="row"
						spacing={1}
						sx={{
							p: 0,
							mt: 1,
						}}
					>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<Tooltip title={`For shortcut ${OSAltKey} + C`}>
								<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
								</Button>
							</Tooltip>
						</Grid>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<Tooltip title={`For shortcut ${OSAltKey} + E`}>
								<span>
									<Button sx={{ width: "100%", ml: 0 }} onClick={generateReport} disabled={disableExportButton}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>E</u>xport {data.excelPdfReport === "pdf" ? "PDF" : "Excel"}
									</Button>
								</span>
							</Tooltip>
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</Box>
	);
};

export default OpTempReport;
