import { Box, Button, Modal, Stack, Tooltip } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const ErrorBox = (props) => {
	const closeErrorBoxHandler = () => {
		props.setErrorState(false);
	};

	return (
		<Box
			onKeyDown={(e) => {
				if (e.code === "KeyC") {
					closeErrorBoxHandler();
				}
			}}
		>
			<Modal
				open={props.ErrorState}
				onClose={closeErrorBoxHandler}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box sx={style}>
					<Stack mb={2} spacing={2}>
						{props.msg}
					</Stack>
					<Tooltip title={"Press C to close"}>
						<Button onClick={closeErrorBoxHandler}>
							<u style={{textDecorationThickness: "2.5px", fontWeight: "bold"}}>C</u>lose
						</Button>
					</Tooltip>
				</Box>
			</Modal>
		</Box>
	);
};

export default ErrorBox;
