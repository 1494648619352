// Packages Import
import { memo, useState, useEffect} from "react"
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
// Project Components
import InfoTable from "../../../UI/Table"
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";
import {formatDate , DateSortingComparator} from "../../../Util/Util"
import { cmActions } from "../../../../store/CM/CM-slice"

const columnsSettings = [
	{ field: "agreeNo", headerName: "Agree NO", type: "text", minWidth: 50, flex: 0.5 },
	{ field: "leadTime", headerName: "Lead Time", type: "text", minWidth: 70, flex: 0.5 },
	{ field: "minQty", headerName: "Min Quantity", type: "text", minWidth: 50, flex: 0.5 },
    { field: "price", headerName: "Price", type: "text", minWidth: 50, flex: 0.5 },
    { field: "currency", headerName: "Currency", type: "text", minWidth: 50, flex: 0.5 },
    { field: "priceUpt", headerName: "Price Updated", type: "text", minWidth: 50, flex: 0.5 },
    { field: "supplier", headerName: "Supplier", type: "text", minWidth: 50, flex: 0.5 },
    { field: "um", headerName: "UM", type: "text", minWidth: 50, flex: 0.5 },
    { field: "recUpt", headerName: "Rec Update", type: "text", minWidth: 50, flex: 0.5 , sortComparator: DateSortingComparator},
    { field: "expDate", headerName: "Exp Date", type: "text", minWidth: 50, flex: 0.5, sortComparator: DateSortingComparator },

]

const MacsPriceInfoTable = (props) => {
	const dispatch = useDispatch();
	const [MacsPriceInfoAccordian, setMacsPriceInfoAccordian] = useState(false);
	const rows = useSelector((state) => state.cmState.MAC_PRICE_INFO_TABLE)
	const partNum = useSelector((state) => state.cmState.PART_INFO.partNum)
	const columnVisibility = useSelector((state) => state.cmState.tableColumnVisibilityMacPriceInfo)

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport 
				printOptions={{
					disableToolbarButton: true,
				}} 
				csvOptions={{
					fileName: `CM_MacsPriceInfo_KPN${partNum}_${formatDate(new Date(), "")}`
				}}
			/>
		</>
	)
	useEffect(() => {
		setMacsPriceInfoAccordian(JSON.parse(localStorage.getItem("MacsPriceInfoAccordian")));
	}, []);

	// Store Accordion state into browser's local storage
	const accordionClicked = () => {
		window.localStorage.setItem("MacsPriceInfoAccordian", !MacsPriceInfoAccordian);
		setMacsPriceInfoAccordian(!MacsPriceInfoAccordian);
	};
	return (
		<CustomAccordion onChange={accordionClicked} expanded={MacsPriceInfoAccordian}>
			<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Typography sx={{ fontWeight: "bold" }}>Macs Price Information</Typography>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
				<InfoTable 
					columnsSettings={columnsSettings} 
					rows={rows}
					checkboxSelection = {true}
					customToolBar={customToolBar}
					ExtraProps = {{
						columnVisibilityModel:columnVisibility,
						onColumnVisibilityModelChange:(newModel) =>{
							dispatch(cmActions.setColumnVisibility({columnVisibility:newModel , table:"MacsPriceInfo"}));
						}
					}}
				/>
			</CustomAccordionDetails>
		</CustomAccordion>
	
	)
}

export default memo(MacsPriceInfoTable)
