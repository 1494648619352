// Packages Import
import { memo, useState, useEffect} from "react"
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
// Project Components
import InfoTable from "../../../UI/Table"
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";
import {formatDate} from "../../../Util/Util"
import { cmActions } from "../../../../store/CM/CM-slice"


const columnsSettings = [
	{ field: "cmName", headerName: "CM Name", type: "text", minWidth: 50, flex: 0.5 },
	{ field: "cmLoc", headerName: "Loc Name", type: "text", minWidth: 70, flex: 0.5 },
	{ field: "commodity", headerName: "Commodity", type: "text", minWidth: 50, flex: 0.5 },
    { field: "onHand", headerName: "On Hand", type: "text", minWidth: 50, flex: 0.5 },
]

const CMQuantityTable = (props) => {
	const dispatch = useDispatch();
	const [CMQuantityAccordian, setCMQuantityAccordian] = useState(false);
	const rows = useSelector((state) => state.cmState.CM_QUANTITY_TABLE)
	const partNum = useSelector((state) => state.cmState.PART_INFO.partNum)
	const columnVisibility = useSelector((state) => state.cmState.tableColumnVisibilityCMQuantity)

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport 
				printOptions={{
					disableToolbarButton: true,
				}} 
				csvOptions={{
					fileName: `CM_Quantity_KPN${partNum}_${formatDate(new Date(), "")}`
				}}
			/>
		</>
	)
	useEffect(() => {
		setCMQuantityAccordian(JSON.parse(localStorage.getItem("CMQuantityAccordian")));
	}, []);

	// Store Accordion state into browser's local storage
	const accordionClicked = () => {
		window.localStorage.setItem("CMQuantityAccordian", !CMQuantityAccordian);
		setCMQuantityAccordian(!CMQuantityAccordian);
	};
	return (
		<CustomAccordion onChange={accordionClicked} expanded={CMQuantityAccordian}>
			<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Typography sx={{ fontWeight: "bold" }}>CM Quantity On Hand</Typography>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
			<InfoTable 
				columnsSettings={columnsSettings} 
				rows={rows}
				checkboxSelection = {true}
				customToolBar={customToolBar}
				ExtraProps = {{
					columnVisibilityModel:columnVisibility,
					onColumnVisibilityModelChange:(newModel) =>{
						dispatch(cmActions.setColumnVisibility({columnVisibility:newModel , table:"CMQuantity"}));
					}
				}}
			/>
			</CustomAccordionDetails>
		</CustomAccordion>
	)
}

export default memo(CMQuantityTable)
