// React
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { uploadBomAction } from "../../../../store/UploadBom/UploadBom-slice";
import { useNavigate } from "react-router-dom";
import { format, addBusinessDays } from "date-fns";

//UI
import ColoredTextField from "../../../UI/ColoredTextField";
import { Grid, Tooltip, FormControlLabel, Checkbox, Button, FormControl, Autocomplete, Typography } from "@mui/material";
import Datepicker from "../../../UI/Datepicker";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import AutocompleteCheckBox from "../../../UI/AutocompleteCheckBox";
import ErrorBox from "../../../Util/ErrorBox";
import MessageBox from "../../../Util/MessageBox";
import AutoCompleteEmployee from "../../../UI/AutoCompleteEmployee";

// Project Packages
import useHttp from "../../../../Hooks/use-http";
import { APIURL } from "../../../Util/Util";

const ProjectInfoEntry = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { sendRequest: fetchData, isLoading } = useHttp();
	const { sendRequest: fetchData2, isLoading: isLoadingEmployee } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	const projectArray = useSelector((state) => state.uploadBomState.projectArray);
	const loadedBom = useSelector((state) => state.uploadBomState.loadedBom);
	const bomNo = useSelector((state) => state.uploadBomState.bomNo);
	const rev = useSelector((state) => state.uploadBomState.rev);
	const BOMCount = useSelector((state) => state.uploadBomState.BOMCount);
	const projectID = useSelector((state) => state.uploadBomState.projectID);
	const projectName = useSelector((state) => state.uploadBomState.projectName);
	const mlarLoadDate = useSelector((state) => state.uploadBomState.mlarLoadDate);
	const completionDueDate = useSelector((state) => state.uploadBomState.completionDueDate);
	const mtlDueDate = useSelector((state) => state.uploadBomState.mtlDueDate);
	const notesRequiredCheckbox = useSelector((state) => state.uploadBomState.notesRequiredCheckbox);
	const rohsRequiredCheckbox = useSelector((state) => state.uploadBomState.rohsRequiredCheckbox);
	const optTempRequiredCheckbox = useSelector((state) => state.uploadBomState.optTempRequiredCheckbox);
	const projectManager = useSelector((state) => state.uploadBomState.projectManager);
	const projectManagerOptions = useSelector((state) => state.uploadBomState.projectManagerOptions);
	const designer = useSelector((state) => state.uploadBomState.designer);
	const designerOptions = useSelector((state) => state.uploadBomState.designerOptions);
	const npiEngineer = useSelector((state) => state.uploadBomState.npiEngineer);
	const npiEngineerOptions = useSelector((state) => state.uploadBomState.npiEngineerOptions);
	const mtlEngineer = useSelector((state) => state.uploadBomState.mtlEngineer);
	const liaison = useSelector((state) => state.uploadBomState.liaison);
	const liaisonOptions = useSelector((state) => state.uploadBomState.liaisonOptions);
	const pcdfm = useSelector((state) => state.uploadBomState.pcdfm);
	const pcdfmOptions = useSelector((state) => state.uploadBomState.pcdfmOptions);
	const division = useSelector((state) => state.uploadBomState.division);

	const checkedOptions = useSelector((state) => state.uploadBomState.checkedOptions);
	const sendEmail = useSelector((state) => state.uploadBomState.sendEmail);
	const emailSubject = useSelector((state) => state.uploadBomState.emailSubject);
	const emailText = useSelector((state) => state.uploadBomState.emailText);

	const usersList = useSelector((state) => state.uploadBomState.usersList);
	const selectedUserEmail = useSelector((state) => state.uploadBomState.selectedUserEmail);

	const userName = useSelector((state) => state.authenticationState.userName);
	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const transactionID = useSelector((state) => state.uploadBomState.transactionID);
	const selectedOrg = useSelector((state) => state.uploadBomState.selectedOrg);

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [openMessageBox, setOpenMessageBox] = useState(false);
	const [message, setMessage] = useState("");
	const [showEmailError, setShowEmailError] = useState(false);

	const checkboxRequired = !notesRequiredCheckbox && !rohsRequiredCheckbox && !optTempRequiredCheckbox;
	const disableSave = checkboxRequired || (projectName ? false : true);

	// For email
	useEffect(() => {
		fetchData(
			{
				url: `${APIURL}bom_upload/usersList`,
				headers,
				method: "POST",
				body: {
					userOrg: selectedOrg,
				},
			},
			(data) => {
				dispatch(uploadBomAction.setUserList(data));
			},
			(error) => {
				console.log(error);
			}
		);
		dispatch(uploadBomAction.retrieveProjectInfo(userName));
	}, [dispatch, fetchData, headers, selectedOrg, userName]);

	// Update date info
	useEffect(() => {
		// MLAR Load Date – This is the current date as per User’s timezone. (Today the date is being shown correct but yesterday this date was always coming one day prior to current date, so needs to be tested  to ensure its always current date)
		// Completion Due Date – This is 5 working Days i.e exclude Saturday’s and Sundays. Do not include the Date the BOM was loaded.
		// MTL Due Date – One working day prior to Completion Due Date or in other words 4 working Days after the BOM is loaded.
		dispatch(uploadBomAction.handleUserInput({ field: "mlarLoadDate", value: format(new Date(), "yyyy-MM-dd") }));
		dispatch(uploadBomAction.handleUserInput({ field: "completionDueDate", value: format(addBusinessDays(new Date(), 10), "yyyy-MM-dd") }));
		dispatch(uploadBomAction.handleUserInput({ field: "mtlDueDate", value: format(addBusinessDays(new Date(), 9), "yyyy-MM-dd") }));
	}, [dispatch]);

	const handleInputChange = (field) => (event) => {
		dispatch(uploadBomAction.handleUserInput({ field, value: event.target.value }));
	};

	const handleNameInputChange = (field) => (event, newValue) => {
		dispatch(uploadBomAction.handleUserInput({ field, value: newValue }));
	};

	const handleDateSelection = (value, field) => {
		dispatch(uploadBomAction.handleUserInput({ field, value: format(value, "yyyy-MM-dd") }));
	};

	const handleProjectChange = (value) => {
		dispatch(uploadBomAction.handleProjectChange(value));
	};

	const handleEmailListChange = (value) => {
		setShowEmailError(false);
		dispatch(uploadBomAction.handleSelectedUserEmail(value));
	};

	const handleCancelClicked = () => {
		const data = { userid: userNTID, transactionId: transactionID, action: "Delete", userorg: selectedOrg };
		console.log(data);
		fetchData(
			{ url: `${APIURL}bom_upload/cancelUpload`, method: "POST", headers, body: data },
			(data) => {
				console.log(data);
				setMessage(`${data.Result}`);
				setOpenMessageBox(true);
			},
			(error) => {
				console.log("ERROR: Unexpected Error occured when Cancelling BOM upload", error);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when Cancelling BOM upload, please try again`);
				setErrorState(true);
			}
		);
	};

	const handleSaveClicked = () => {
		const toEmail = selectedUserEmail.map((user) => user.useremail);

		if (sendEmail && toEmail.length === 0) {
			setShowEmailError(true);
		} else {
			setShowEmailError(false);
			const data = {
				transactionId: transactionID,
				Projects_Info: {
					userid: userNTID,
					userorg: selectedOrg,
					parentbom: bomNo,
					parentbomrev: rev,
					bomcount: BOMCount,
					pro_id: projectID,
					project: projectName.toUpperCase().trim(),
					liaison: liaison,
					a_group: division,
					pr_mgr: projectManager,
					npieng: npiEngineer,
					mtl_eng: mtlEngineer || null,
					designer: designer,
					pcdfm: pcdfm,
					dlastadd: mlarLoadDate,
					dduedate: completionDueDate,
					dinpdate: mtlDueDate,
					sendemail: sendEmail ? "Y" : "N",
					checkedOptions: checkedOptions,
					toEmails: sendEmail ? toEmail : [],
				},
			};
			console.log(data);

			fetchData(
				{ url: `${APIURL}bom_upload/editProjectDetails`, method: "POST", headers, body: data },
				(data) => {
					console.log(data);
					setMessage(`${data.Result}`);
					setOpenMessageBox(true);
				},
				(error) => {
					console.log("ERROR: Unexpected Error occured when Saving BOM", error);
					setErrorMsg(`ERROR: ${error}. Unexpected Error occured when Saving BOM, please try again`);
					setErrorState(true);
				}
			);
		}
	};

	const messageBoxHandler = () => {
		setOpenMessageBox(false);
		dispatch(uploadBomAction.reset());
		navigate("/Projects");
	};

	let inputTimeOut;

	// console.log("rerendered");

	const handleEmployeeInputChange = (newValue, name, reason) => {
		clearTimeout(inputTimeOut);

		inputTimeOut = setTimeout(() => {
			if (newValue.length > 2 && reason === "input") {
				console.log("queried " + newValue);
				fetchData2(
					{ url: `${APIURL}Projects/employeelist/?empStatus=A&filterOn=${newValue}`, headers },
					(data) => {
						const employeeList = data.EMPLOYEE_LIST.sort();
						switch (name) {
							case "projectManager":
								dispatch(uploadBomAction.setProjectManagerOptions(employeeList));
								break;
							case "designer":
								dispatch(uploadBomAction.setDesignerOptions(employeeList));
								break;
							case "npiEngineer":
								dispatch(uploadBomAction.setNpiEngineerOptions(employeeList));
								break;
							case "liaison":
								dispatch(uploadBomAction.setLiaisonOptions(employeeList));
								break;
							case "pcdfm":
								dispatch(uploadBomAction.setpcdfmOptions(employeeList));
								break;
							default:
								break;
						}
					},
					(error) => {
						console.log("ERROR: ", error);
						setErrorMsg(`ERROR: Unable to retrieve name, ${error}`);
						setErrorState(true);
					}
				);
			}
		}, 500);
	};

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<MessageBox open={openMessageBox} message={message} messageBoxHandler={messageBoxHandler} />

			<Grid mt={2} container direction="row" justifyContent="center" alignItems="top" rowSpacing={2} columnSpacing={0.8} wrap={"wrap"}>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Tooltip title={loadedBom}>
						<ColoredTextField fullWidth label="Loaded BOM/MLAR number" focused value={loadedBom} inputProps={{ readOnly: true }} />
					</Tooltip>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<FormControl fullWidth>
						<Autocomplete
							freeSolo
							forcePopupIcon={true}
							autoHighlight
							onChange={(event, value) => {
								handleProjectChange(value || "");
							}}
							inputValue={projectName}
							onInputChange={(event, value) => {
								handleProjectChange(value || "");
							}}
							options={projectArray}
							renderInput={(params) => (
								<ColoredTextField
									error={projectName ? false : true}
									backgroundColor={"#fff"}
									{...params}
									focused
									inputProps={{ sx: { textTransform: "uppercase" }, ...params.inputProps }}
									label={"Choose from dropdown list or type new"}
								/>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Datepicker label="MLAR load date" handleSelection={handleDateSelection} val={mlarLoadDate} name="mlarLoadDate" disablePast={true} />
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Datepicker
						label="Completion Due Date"
						handleSelection={handleDateSelection}
						val={completionDueDate}
						name="completionDueDate"
						disablePast={true}
						backgroundColor="rgba(225, 255, 0, 0.5)"
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Tooltip
						placement={"top"}
						title={
							"MTL input Due date is date when materials engineers should finish input data for a BOM. (Input Due Date = Due Date -1) working day - Laison have a one work day to finish the MLAR and send it to NPI (Due Date)"
						}
					>
						<div>
							<Datepicker
								label="MTL Due Date"
								handleSelection={handleDateSelection}
								val={mtlDueDate}
								name="mtlDueDate"
								disablePast={true}
								backgroundColor="rgba(225, 255, 0, 0.5)"
							/>
						</div>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<FormControlLabel
						control={<Checkbox checked={notesRequiredCheckbox} onChange={handleInputChange("notesRequiredCheckbox")} />}
						label="Notes Review Required"
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<FormControlLabel
						control={<Checkbox checked={rohsRequiredCheckbox} onChange={handleInputChange("rohsRequiredCheckbox")} />}
						label="RoHS Review Required"
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<FormControlLabel
						control={<Checkbox checked={optTempRequiredCheckbox} onChange={handleInputChange("optTempRequiredCheckbox")} />}
						label="Operating Temperature Review Required"
					/>
				</Grid>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={-2}>
					<Typography variant="caption" color={"secondary"}>
						{checkboxRequired && "Require at least one type of review"}
					</Typography>
				</Grid>

				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Tooltip title={projectManager || ""}>
						<div>
							<AutoCompleteEmployee
								value={projectManager}
								label="Project Manager Name"
								onChange={handleNameInputChange("projectManager")}
								handleEmployeeInputChange={handleEmployeeInputChange}
								options={projectManagerOptions}
								isLoading={isLoadingEmployee}
								name={"projectManager"}
								onClose={() => {
									dispatch(uploadBomAction.setProjectManagerOptions([]));
								}}
								helperText={"*Insert more than 3 characters to search"}
							/>
						</div>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Tooltip title={designer || ""}>
						<div>
							<AutoCompleteEmployee
								value={designer}
								label="Designer"
								onChange={handleNameInputChange("designer")}
								handleEmployeeInputChange={handleEmployeeInputChange}
								options={designerOptions}
								isLoading={isLoadingEmployee}
								name={"designer"}
								helperText={"*Insert more than 3 characters to search"}
								onClose={() => {
									dispatch(uploadBomAction.setDesignerOptions([]));
								}}
							/>
						</div>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Tooltip title={npiEngineer || ""}>
						<div>
							<AutoCompleteEmployee
								value={npiEngineer}
								label="NPI of Engineer"
								onChange={handleNameInputChange("npiEngineer")}
								handleEmployeeInputChange={handleEmployeeInputChange}
								options={npiEngineerOptions}
								isLoading={isLoadingEmployee}
								name={"npiEngineer"}
								helperText={"*Insert more than 3 characters to search"}
								onClose={() => {
									dispatch(uploadBomAction.setNpiEngineerOptions([]));
								}}
							/>
						</div>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Tooltip title={liaison || ""}>
						<div>
							<AutoCompleteEmployee
								value={liaison}
								label="Liaison Name"
								onChange={handleNameInputChange("liaison")}
								handleEmployeeInputChange={handleEmployeeInputChange}
								options={liaisonOptions}
								isLoading={isLoadingEmployee}
								name={"liaison"}
								helperText={"*Insert more than 3 characters to search"}
								onClose={() => {
									dispatch(uploadBomAction.setLiaisonOptions([]));
								}}
							/>
						</div>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Tooltip title={pcdfm || ""}>
						<div>
							<AutoCompleteEmployee
								value={pcdfm}
								label="PCDFM"
								onChange={handleNameInputChange("pcdfm")}
								handleEmployeeInputChange={handleEmployeeInputChange}
								options={pcdfmOptions}
								isLoading={isLoadingEmployee}
								name={"pcdfm"}
								helperText={"*Insert more than 3 characters to search"}
								onClose={() => {
									dispatch(uploadBomAction.setpcdfmOptions([]));
								}}
							/>
						</div>
					</Tooltip>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<Tooltip title={division || ""}>
						<ColoredTextField fullWidth label="Division" focused onChange={handleInputChange("division")} value={division} autoComplete="off" />
					</Tooltip>
				</Grid>

				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<FormControlLabel control={<Checkbox checked={sendEmail} onChange={handleInputChange("sendEmail")} />} label="Send Email" />
				</Grid>

				{sendEmail && (
					<>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<AutocompleteCheckBox
								errorMsg={"Please choose at least one, uncheck 'Send Email' above if not required"}
								options={usersList}
								handleOnChange={handleEmailListChange}
								value={selectedUserEmail}
								hasSelectAll
								error={showEmailError}
								label={"Send to"}
								optionLabel={(option) => `${option.username} <${option.useremail}>`}
							/>
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<ColoredTextField label={"Email Subject Line"} focused multiline fullWidth value={emailSubject} inputProps={{ readOnly: true }} />
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<ColoredTextField label={"Email Text Message Contents"} focused multiline fullWidth value={emailText} inputProps={{ readOnly: true }} />
						</Grid>
					</>
				)}

				<Grid mt={2}>
					<Button onClick={handleSaveClicked} disabled={disableSave}>
						{sendEmail ? "save and send email" : "save"}
					</Button>
					<Button onClick={handleCancelClicked}>Cancel</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default ProjectInfoEntry;
