// React
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";
// UI
import ErrorBox from "../../../Util/ErrorBox";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import { Box, Modal, Stack, Typography, Tooltip, Button } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
// Packages import
import { OSAltKey, APIURL } from "../../../Util/Util";
import useHttp from "../../../../Hooks/use-http";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	pb: 2,
};

const EditDescription = (props) => {
	const dispatch = useDispatch();
	const [errorMsg, setErrorMsg] = useState("");
	const { isLoading, sendRequest: fetchData } = useHttp();
	const [RoHSDocErrorState, setRoHSDocErrorState] = useState(false);
	const [updateSuccessMsg, setUpdateSuccessMsg] = useState("");

	const open = useSelector((state) => state.rohsStates.showEditDescriptionPopUp);
	const editFileDetails = useSelector((state) => state.rohsStates.editFileDetails);
	const selectedMpn = useSelector((state) => state.rohsStates.selectedMpn);
	const selectedMfgId = useSelector((state) => state.rohsStates.selectedMfgId);
	const selectedMfgName = useSelector((state) => state.rohsStates.selectedMfgName);
	const partNumber = useSelector((state) => state.rohsStates.partInfo.partNumber);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };

	const handleEdit = (event) => {
		dispatch(RoHSAction.handleEditDescription(event.target.value));
	};

	const editDescriptionHandler = () => {
		const fileMetaData = {
			item: partNumber,
			mfg_pn: selectedMpn,
			mfgid: selectedMfgId,
			mfg_name: selectedMfgName,
			doc_id: editFileDetails.docId,
			userid: userNTID,
			description: editFileDetails.description,
			upload_delete_link_docs: "Update",
		};

		console.log(fileMetaData);
		fetchData(
			{ url: `${APIURL}Rohs/editLinkDoc`, method: "POST", headers, body: fileMetaData },
			(data) => {
				dispatch(RoHSAction.updateLinksDoc(data));
				dispatch(RoHSAction.retrieveMpn({ mpn: "reload" }));
				setUpdateSuccessMsg("Description updated successfully");
				setTimeout(() => {
					dispatch(RoHSAction.setShowEditDescriptionPopUp(false));
					setUpdateSuccessMsg("");
				}, 1500);
			},
			(error) => {
				console.log("ERROR: Unexpected Error occured when updating description", error);
				setRoHSDocErrorState(true);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when updating description, please try again`);
			}
		);
	};

	const cancelEditDescription = () => {
		dispatch(RoHSAction.setShowEditDescriptionPopUp(false));
	};

	const handleOnKeyDown = (e) => {
		if (e.code === "KeyS" && e.altKey === true) {
			editDescriptionHandler();
			e.preventDefault();
		} else if (e.code === "KeyC" && e.altKey === true) {
			e.preventDefault();
			cancelEditDescription();
		}
	};

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setRoHSDocErrorState} ErrorState={RoHSDocErrorState} />

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						handleOnKeyDown(e);
					}}
				>
					{updateSuccessMsg ? (
						<Typography mb={2} ml={0.4} fontSize={20}>
							{updateSuccessMsg}
						</Typography>
					) : (
						<>
							<Stack mb={2} spacing={2}>
								<ColoredTextField
									autoFocus
									onFocus={(e) => {
										// Target the last character of existing value on focus
										// e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
										e.target.select();
									}}
									fullWidth
									label={`Enter new description for file ${editFileDetails.fileName}`}
									minRows={3}
									maxRows={3}
									multiline
									focused
									onChange={handleEdit}
									value={editFileDetails.description}
								/>
							</Stack>

							<Box>
								<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
									<Button onClick={editDescriptionHandler} disabled={isLoading}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ave
									</Button>
								</Tooltip>

								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<Button onClick={cancelEditDescription}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</Tooltip>
							</Box>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default EditDescription;
