import { Fragment, useState } from "react"
import { Button, Box,  MenuItem, Tooltip, IconButton, Typography, Menu } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import { memo } from "react"


const BottomNavigation = (props) => {

	const [anchorElNav, setAnchorElNav] = useState(null)

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget)
	}

	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}

	return (
		<Fragment>
			<Box position={"fixed"} bottom={0} width={"100%"} display="flex" alignItems={"center"} bgcolor={"#EBEBEB"} zIndex={1100}>
				<Box m={1} sx={{ mx: "auto" }}>
					<Box sx={{ display: "flex" }}>
						<Box pl={3} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}>
							<Button variant="contained" onClick={() => {window.open('https://apawebvm.cos.is.keysight.com/materialsWordpress/','_blank')}}>
								PSG Main Page
							</Button>
							<Button variant="contained" onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/','_blank')}}>
								MTL Web Page 
							</Button>
							<Button variant="contained" onClick={() => {window.open('http://cds.srs.is.keysight.com/help/pref_cd.html','_blank')}}>
								Pref Code Standard
							</Button>
							<Button variant="contained" onClick={() => {window.open('https://keysighttech.sharepoint.com/sites/Labs-DS-PLC/Documents/KP/MatrixOne/007184.docx','_blank')}}>
								ICat Standard
							</Button>
							<Button variant="contained" onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/commodity_responsibility_lookup.php','_blank')}}>
								MTL Eng Resp Lookup
							</Button>
							<Button variant="contained" onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/Visio-Mateng.pdf','_blank')}}	>
								MTL Eng Resp Chart
							</Button>
							<Button variant="contained" onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/NPI%20Commodity%20Mgmt%20and%20Mtls%20Eng%20%20Matrix.xlsx','_blank')}}>
								NPI and TMC Support Matrix
							</Button>	
						</Box>

						<Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" } }}>
							<IconButton
								size="Medium"
								aria-label="Footer buttons"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								{anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								disableScrollLock
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
								}}
							>
								<MenuItem key={"PSG Main Page"} onClick={() => {window.open('https://apawebvm.cos.is.keysight.com/materialsWordpress/','_blank')}}>
									<Typography textAlign="center">{"PSG Main Page"}</Typography>
								</MenuItem>
								<MenuItem key={"MTL Web Page"} onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/','_blank')}}>
									<Typography textAlign="center">{"MTL Web Page"}</Typography>
								</MenuItem>
								<MenuItem key={"Pref Code Standard"} onClick={() => {window.open('http://cds.srs.is.keysight.com/help/pref_cd.html','_blank')}}>
									<Typography textAlign="center">{"Pref Code Standard"}</Typography>
								</MenuItem>
								<MenuItem key={"ICat Standard"}>
									<Typography textAlign="center">{"ICat Standard"}</Typography>
								</MenuItem>
								<MenuItem key={"MTL Eng Resp Lookup"} onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/commodity_responsibility_lookup.php','_blank')}}>
									<Typography textAlign="center">{"MTL Eng Resp Lookup"}</Typography>
								</MenuItem>
								<MenuItem key={"MTL Eng Resp Chart"} onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/Visio-Mateng.pdf','_blank')}}>
									<Typography textAlign="center">{"MTL Eng Resp Chart"}</Typography>
								</MenuItem>
								<MenuItem key={"NPI and TMC Support Matrix"} onClick={() => {window.open('http://www.srs.is.keysight.com/org/matleng/NPI%20Commodity%20Mgmt%20and%20Mtls%20Eng%20%20Matrix.xlsx','_blank')}}>
									<Typography textAlign="center">{"NPI and TMC Support Matrix"}</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
		</Fragment>
	)
}

export default memo(BottomNavigation)
