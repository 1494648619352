// Packages Import
import { useMemo, useState } from "react";
import {
	Box,
	Paper,
	Tooltip,
	Typography,
	Button,
	Modal,
	Stack,
	Grid,
	Autocomplete,
	AppBar,
	Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// Project Component
import ColoredTextField from "../../UI/ColoredTextField";
import DropdownFilter from "../../UI/DropdownFilter";
import useHttp from "../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import { AdminAction } from "../../../store/Admin/Admin-slice";
import { APIURL, OSAltKey, isEmptyObject } from "../../Util/Util";
import Datepicker from "../../UI/Datepicker";

let containsData = false;

const ChangeProjectDate = (props) => {
	const dispatch = useDispatch();
	const { getBomList = () => {}, onSuccess = () => {}, onError = () => {} } = props;
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const [fetchProjectLoader, setFetchProjectLoader] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.adminState.changeProjectDate);
	const projectList = useSelector((state) => state.adminState.changeProjectDate.projectList);
	const selectedProject = useSelector((state) => state.adminState.changeProjectDate.selectedProject);
	const showPopup = useSelector((state) => state.adminState.openChangeProjectDate);
	const orgList = useSelector((state) => state.adminState.orgOptions);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 700 },
		maxHeight: "85%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};
	const dateCompleted = moment(selectedProject.dateCompleted, "YYYY-MM-DD").isValid()
		? selectedProject.dateCompleted
		: "";
	const inputDueDate = moment(selectedProject.inputDueDate, "YYYY-MM-DD").isValid() ? selectedProject.inputDueDate : "";

	useMemo(() => {
		Object.keys(projectList).every((key) => {
			if (projectList[key]) {
				containsData = true;
				return false;
			}
			return true;
		});
	}, [projectList]);

	const handleOrgChange = (value) => {
		dispatch(AdminAction.resetChangeProjectDate({ ignore: ["startDate", "endDate"] }));
		dispatch(
			AdminAction.updateChangeProjectDate({
				data: {
					userOrg: value,
				},
			})
		);
		containsData = false;
		getBomList(data.startDate, data.endDate, value, setFetchProjectLoader);
	};
	const updateProjectDate = () => {
		closeHandler();
		setLoading(true);
		const jsonReq = {
			project: selectedProject?.project ?? "",
			bomno: selectedProject?.bomNo ?? "",
			userid: userInfo.id,
			userOrg: data.userOrg,
			dcompl: selectedProject?.dateCompleted ?? "",
			pro_id: selectedProject?.proId ?? "",
		};

		fetchDataWithoutLoad(
			{
				url: `${APIURL}admin/updateBomProjectDetails`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(data) => {
				if (data?.No_Record) {
					throw Error(data.No_Record);
				}
				setLoading(false);
				onSuccess(data.Success.toString());
			},
			(error) => {
				setLoading(false);
				onError(error.toString());
			}
		);
	};

	const closeHandler = () => {
		dispatch(AdminAction.setOpenChangeProjectDate(false));
		dispatch(AdminAction.reset());
	};

	const handleProjectSelection = (newValue) => {
		if (newValue === null) {
			dispatch(
				AdminAction.updateChangeProjectDate({
					data: {
						error: {
							...data.error,
							projectList: true,
						},
						errorText: {
							...data.errorText,
							projectList: "",
						},
					},
				})
			);
			dispatch(AdminAction.resetSelectedProject());
		} else {
			dispatch(
				AdminAction.updateChangeProjectDate({
					data: {
						selectedProject: newValue,
						error: {
							...data.error,
							projectList: false,
						},
						errorText: {
							...data.errorText,
							projectList: "",
						},
					},
				})
			);
		}
	};
	const handleQueryDateSelection = (val, type) => {
		switch (type) {
			case "start":
				dispatch(
					AdminAction.updateChangeProjectDate({
						data: { startDate: moment(val).isValid() ? moment(val).format("YYYY-MM-DD") : "" },
					})
				);
				getBomList(val, data.endDate, data.userOrg, setFetchProjectLoader);
				break;
			case "end":
				dispatch(
					AdminAction.updateChangeProjectDate({
						data: { endDate: moment(val).isValid() ? moment(val).format("YYYY-MM-DD") : "" },
					})
				);
				getBomList(data.startDate, val, data.userOrg, setFetchProjectLoader);
				break;

			default:
				break;
		}
	};
	const handleDateSelection = (val, type) => {
		switch (type) {
			case "completed":
				dispatch(
					AdminAction.updateProjectDateCompleted(
						moment(val, "YYYY-MM-DD").isValid() ? moment(val, "YYYY-MM-DD").format("YYYY-MM-DD") : ""
					)
				);
				break;
			default:
				break;
		}
	};
	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								closeHandler();
							}
							if (e.code === "KeyU") {
								if (!data.error.projectList && containsData) {
									updateProjectDate();
								}
							}
						}
					}}
				>
					<Stack my={2} spacing={1}>
						<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
							<Toolbar>
								<Typography variant="h6" component="div" color="#fff">
									{"Admin - Edit Project Date Completed"}
								</Typography>
							</Toolbar>
						</AppBar>
						<Grid
							container
							direction="row"
							spacing={0}
							rowSpacing={2}
							sx={{
								pt: 3,
							}}
						>
							<Grid
								item
								xl={2}
								lg={2}
								md={2}
								sm={2}
								xs={12}
								textAlign="center"
								sx={{
									pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
								}}
							>
								<DropdownFilter
									selections={orgList}
									selectedData={data.userOrg}
									handleSelection={handleOrgChange}
									label={<b>User Org</b>}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>

							<Grid
								item
								xl={5}
								lg={5}
								md={5}
								sm={5}
								xs={12}
								textAlign="center"
								sx={{
									pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
								}}
							>
								<Datepicker
									label={<b>Start Date</b>}
									handleSelection={handleQueryDateSelection}
									val={data.startDate}
									name="start"
									disableFuture={true}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xl={5} lg={5} md={5} sm={5} xs={12} textAlign="center">
								<Datepicker
									label={<b>End Date</b>}
									handleSelection={handleQueryDateSelection}
									name="end"
									val={data.endDate}
									disableFuture={true}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={{
									pt: { xs: 1.5, sm: 0, md: 0, lg: 0, xl: 0 },
								}}
							>
								<Autocomplete
									loading={fetchProjectLoader}
									value={isEmptyObject(data.selectedProject) ? null : data.selectedProject}
									loadingText={
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<CircularProgress color="inherit" size={"20%"} />
										</Box>
									}
									options={!fetchProjectLoader ? projectList : []}
									getOptionLabel={(option) => `${option.bomNo}  |  ${option.project}`}
									renderOption={(props, option) => {
										return (
											<li {...props}>
												<Box>
													<Typography
														align="center"
														width={{ xs: "60px", sm: "130px" }}
														variant="body2"
													>{`${option.bomNo}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={1.5} minWidth={"35px"} variant="body2">{`${option.project}`}</Typography>
												</Box>
											</li>
										);
									}}
									renderInput={(params) => (
										<ColoredTextField
											label={<b>Choose MLAR Project/BOM </b>}
											focused
											error={data.error.projectList}
											helperText={data.errorText.projectList}
											{...params}
										/>
									)}
									isOptionEqualToValue={(option, value) => {
										return JSON.stringify(option.proId) === JSON.stringify(value.proId);
									}}
									onChange={(event, newValue) => handleProjectSelection(newValue)}
								/>
							</Grid>
						</Grid>
						<Paper sx={{ p: 2 }} elevation={5}>
							<Grid
								container
								direction="row"
								spacing={0}
								rowSpacing={2}
								sx={{
									pt: 3,
								}}
							>
								<Grid
									item
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xs={12}
									sx={{
										pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
									}}
								>
									<Datepicker
										label={<b>Input Due Date</b>}
										val={inputDueDate}
										name="inp"
										readOnly={true}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
									<Datepicker
										label={<b>Date Completed</b>}
										handleSelection={handleDateSelection}
										val={dateCompleted}
										name="completed"
										disableFuture={false}
										disablePast={false}
										disabled={data.error.projectList || !containsData ? true : false}
										ExtraProps={{
											componentsProps: {
												actionBar: {
													actions: ["clear"],
												},
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Project</b>}
										fullWidth
										focused
										onChange={() => {}}
										value={selectedProject.project}
										inputProps={{
											readOnly: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Project Manager</b>}
										fullWidth
										focused
										onChange={() => {}}
										value={selectedProject.projectManager}
										inputProps={{
											readOnly: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Designer</b>}
										fullWidth
										focused
										onChange={() => {}}
										value={selectedProject.designer}
										inputProps={{
											readOnly: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>NPI OF Engineer</b>}
										fullWidth
										focused
										onChange={() => {}}
										value={selectedProject.npiEng}
										inputProps={{
											readOnly: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Liaison</b>}
										fullWidth
										focused
										onChange={() => {}}
										value={selectedProject.liaison}
										inputProps={{
											readOnly: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
							</Grid>
						</Paper>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<Box>
								<Tooltip title={`For shortcut press ${OSAltKey} + U`}>
									<span>
										<Button
											onClick={updateProjectDate}
											disabled={data.error.projectList || !containsData ? true : false}
										>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>U</u>pdate
										</Button>
									</span>
								</Tooltip>
								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<span>
										<Button onClick={closeHandler}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
										</Button>
									</span>
								</Tooltip>
								<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
							</Box>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default ChangeProjectDate;
