// React
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubsetAction } from "../../../store/Subset/Subset-slice";

// UI
import {
	Box,
	Button,
	Modal,
	Grid,
	List,
	Paper,
	ListItem,
	ListItemText,
	ListItemIcon,
	Checkbox,
	Typography,
	AppBar,
	Toolbar,
	Tooltip,
} from "@mui/material";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import AutocompleteCheckBox from "../../UI/AutocompleteCheckBox";

// Project Packages
import useDownloadFile from "../../../Hooks/use-http-excel";
import ErrorBox from "../../Util/ErrorBox";
import { APIURL } from "../../Util/Util";

const not = (a, b) => {
	return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
	return a.filter((value) => b.indexOf(value) !== -1);
};

const style = {
	position: "absolute",
	top: { xs: "30%", sm: "30%", md: "50%", lg: "50%", xl: "50%" },
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const ExportSubset = (props) => {
	const { open, onClose } = props;
	const dispatch = useDispatch();
	const { isLoading, downloadExcelFile } = useDownloadFile();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const close = () => {
		dispatch(SubsetAction.resetExcel());
		onClose();
	};

	const titleLabel = { available: "Available Fields", chosen: "Chosen Fields" };

	const left = useSelector((state) => state.SubsetState.left);
	const right = useSelector((state) => state.SubsetState.right);
	const selectedSubset = useSelector((state) => state.SubsetState.selectedSubset);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const [checked, setChecked] = useState([]);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	useEffect(() => {
		if (open) {
			setChecked([]);
		}
	}, [open]);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			// Add
			newChecked.push(value);
		} else {
			// Remove
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		dispatch(SubsetAction.setRight(right.concat(left).sort()));
		dispatch(SubsetAction.setLeft([]));
	};

	const handleCheckedRight = () => {
		dispatch(SubsetAction.setRight(right.concat(leftChecked).sort()));
		dispatch(SubsetAction.setLeft(not(left, leftChecked)));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		dispatch(SubsetAction.setLeft(left.concat(rightChecked).sort()));
		dispatch(SubsetAction.setRight(not(right, rightChecked)));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		dispatch(SubsetAction.setLeft(left.concat(right).sort()));
		dispatch(SubsetAction.setRight([]));
	};

	const handleExportClicked = async () => {
		const body = { userid: userNTID, subsetid: selectedSubset.subsetid, attributes: right };

		downloadExcelFile({ url: `${APIURL}subset/exportSubset`, headers, body }, (error) => {
			setErrorState(true);
			setErrorMsg(`ERROR: Unable to Export Subset, please try refreshing the page and try again. ${error}`);
			console.log("ERROR: Unable to Export Subset, please try refreshing the page and try again", error);
		});
	};

	// Mobile
	const handleMobileSelection = (value) => {
		const leftRemains = not(left, value);
		const rightRemoved = not(right, value);

		dispatch(SubsetAction.setLeft([...leftRemains, ...rightRemoved].sort()));
		dispatch(SubsetAction.setRight(value.sort()));
	};

	const customList = (items, Title) => {
		const error = Title === titleLabel.chosen && right.length === 0;
		return (
			<>
				<Typography>{Title}</Typography>
				<Paper sx={{ width: 200, height: 300, overflow: "auto", border: error && 1, borderColor: error && "#FF0000" }}>
					<List dense disablePadding component="div" role="list">
						{items.map((value) => {
							const labelId = `transfer-list-item-${value}-label`;

							return (
								<ListItem key={value} role="listitem" button onClick={handleToggle(value)} sx={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
									<ListItemIcon>
										<Checkbox
											checked={checked.indexOf(value) !== -1}
											tabIndex={-1}
											disableRipple
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={value} sx={{ m: 0 }} />
								</ListItem>
							);
						})}
						<ListItem />
					</List>
				</Paper>
				<Typography variant="caption" color={"secondary"}>
					{error && "At least one field required"}
				</Typography>
			</>
		);
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState}></ErrorBox>

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyE" && right.length !== 0) {
							handleExportClicked();
						} else if (e.code === "KeyC") {
							close();
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Export Subset"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Grid
						sx={{ display: { xl: "flex", lg: "flex", md: "flex", sm: "none", xs: "none" } }}
						container
						spacing={2}
						mb={2}
						mt={4}
						justifyContent="center"
						alignItems="center"
					>
						<Grid item>{customList(left, titleLabel.available)}</Grid>
						<Grid item>
							<Grid container direction="column" alignItems="center">
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleAllRight}
									disabled={left.length === 0}
									aria-label="move all right"
								>
									<Typography>≫</Typography>
								</Button>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleCheckedRight}
									disabled={leftChecked.length === 0}
									aria-label="move selected right"
								>
									&gt;
								</Button>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleCheckedLeft}
									disabled={rightChecked.length === 0}
									aria-label="move selected left"
								>
									&lt;
								</Button>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleAllLeft}
									disabled={right.length === 0}
									aria-label="move all left"
								>
									<Typography>≪</Typography>
								</Button>
							</Grid>
						</Grid>
						<Grid item>{customList(right, titleLabel.chosen)}</Grid>
					</Grid>

					{/* Mobile Screen */}
					<Box mb={2} mt={7} sx={{ display: { xl: "none", lg: "none", md: "none", sm: "block", xs: "block" } }}>
						<AutocompleteCheckBox
							options={[...right, ...left].sort()}
							handleOnChange={handleMobileSelection}
							value={right}
							hasSelectAll
							error={right.length === 0}
							label={"Select Fields to Export"}
							optionLabel={(option) => option}
						/>
					</Box>
					<Tooltip title={`For shortcut press E`}>
						<span>
							<Button onClick={handleExportClicked} disabled={right.length === 0}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>E</u>xport
							</Button>
						</span>
					</Tooltip>
					<Tooltip title={`For shortcut press C`}>
						<span>
							<Button onClick={close}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
						</span>
					</Tooltip>
				</Box>
			</Modal>
		</Box>
	);
};

export default ExportSubset;
