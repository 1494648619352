// Packages Import
import { Fragment, useState, memo , useCallback} from "react";
import { Button, Box, MenuItem, Tooltip, IconButton, Typography, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useDispatch, useSelector } from "react-redux";
import Hotkeys from "react-hot-keys";

// Project Components
import BottomBarNavigation from "../../Layout/BottomBarNavigation";
import FindPart from "../../Util/FindPart";
import { findPartAction } from "../../../store/common/findPart-slice";
import { OpenCDS } from "../../Util/Util";

const BottomNavigation = (props) => {
	const dispatch = useDispatch();
	const selectedHistory = useSelector((state) => state.cmState.PART_INFO.partNum) || "";
	const nonMtlEngAccess = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);

	const [anchorElNav, setAnchorElNav] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};
	
	const handleCdsClicked = () => {
		handleCloseNavMenu();
		OpenCDS(selectedHistory);
	};
	const handleFindClicked = useCallback(() => {
		handleCloseNavMenu();
		dispatch(findPartAction.setPartFound(true));
		dispatch(findPartAction.setShowFindPart(true));
	}, [dispatch]);

	return (
		<Fragment>
			<Box position={"fixed"} bottom={0} width={"100%"} display="flex" alignItems={"center"} bgcolor={"#EBEBEB"} zIndex={1100}>
				<Box m={2} sx={{ mx: "auto" }}>
					<Box sx={{ display: "flex" }}>
						<BottomBarNavigation changePart={props.changePart} selectedHistory={selectedHistory} />

						<Box pl={3} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}>
							<Button variant="contained" onClick={handleCdsClicked}>
								CDS...
							</Button>
							<Tooltip title="Open Find Window (CTRL + Shift + F)">
								<span>
									<Hotkeys keyName="control+shift+f" onKeyUp={handleFindClicked} />
									<Button variant="contained" onClick={handleFindClicked}>
										Find
									</Button>
								</span>
							</Tooltip>
						</Box>

						<Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" } }}>
							<IconButton
								size="Medium"
								aria-label="Footer buttons"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								{anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								disableScrollLock
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
								}}
							>
								<MenuItem key={"Find"} onClick={handleFindClicked}>
									<Typography textAlign="center">{"Find"}</Typography>
								</MenuItem>
								<MenuItem key={"CDS"} disabled={nonMtlEngAccess} >
									<Typography textAlign="center">{"CDS"}</Typography>
								</MenuItem>

							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
			<FindPart
				showFindPart={props.showFindPart}
				setShowFindPart={props.setShowFindPart}
				changePart={props.changePart}
				partFound={props.partFound}
				setPartFound={props.setPartFound}
			/>
		</Fragment>
	);
};

export default memo(BottomNavigation);
