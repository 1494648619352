// React
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

// UI
import { Grid, Typography } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";

const RoHSMCC = () => {
	const mccComment = useSelector((state) => state.rohsStates.mccComment);
	const logInfo = useSelector((state) => state.rohsStates.logInfo);

	const [AccordionExpanded, setAccordionExpanded] = useState(false);

	const logInfoTableValue = [
		{
			title: "ME RoHS info updated by",
			info: `${logInfo.RoHS_By} on ${logInfo.RoHS_ModDate ? new Date(logInfo.RoHS_ModDate?.replace("Z", "")).toLocaleString("en-US") : ""}`,
		},
		{
			title: "ME APBftF info updated by",
			info: `${logInfo.APBftF_By} on ${logInfo.APBftF_ModDate ? new Date(logInfo.APBftF_ModDate?.replace("Z", "")).toLocaleString("en-US") : ""}`,
		},
		{
			title: "ME ME260C info updated by",
			info: `${logInfo.ME260C_By} on ${logInfo.ME260C_ModDate ? new Date(logInfo.ME260C_ModDate?.replace("Z", "")).toLocaleString("en-US") : ""}`,
		},
		{
			title: "ME MEMaxOpTemp info updated by",
			info: `${logInfo.MEMaxOpTemp_By} on ${
				logInfo.MEMaxOpTemp_ModDate ? new Date(logInfo.MEMaxOpTemp_ModDate?.replace("Z", "")).toLocaleString("en-US") : ""
			}`,
		},
		{
			title: "RoHS info updated by",
			info: `${logInfo.RoHSinfo_By} on ${
				logInfo.RoHSinfo_ModDate ? new Date(logInfo.RoHSinfo_ModDate?.replace("Z", "")).toLocaleString("en-US") : ""
			}`,
		},
	];

	// Retrive Accordion state from local storage when first rendering this page
	useEffect(() => {
		setAccordionExpanded(JSON.parse(localStorage.getItem("RoHSMCCAccordion")));
	}, []);

	const AccordionClicked = () => {
		window.localStorage.setItem("RoHSMCCAccordion", !AccordionExpanded);
		setAccordionExpanded(!AccordionExpanded);
	};

	const helperTextStyle = {
		"& .MuiFormHelperText-root": {
			marginLeft: 0.5,
		},
	};

	return (
		<CustomAccordion onChange={AccordionClicked} expanded={AccordionExpanded}>
			<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Typography sx={{ fontWeight: "bold" }}>MCC Comments and Log Info</Typography>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
				<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={3} columnSpacing={0.8} wrap={"wrap"}>
					<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
						<ColoredTextField
							fullWidth
							label="MCC MDR comment"
							focused
							multiline
							minRows={3}
							maxRows={3}
							inputProps={{ readOnly: true }}
							value={mccComment.mdrComm}
							helperText={`MD_last update: ${mccComment.mdrUpdate}`}
							sx={{
								...helperTextStyle,
							}}
						/>
					</Grid>
					<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
						<ColoredTextField
							fullWidth
							label="MCC Requestor comment"
							focused
							multiline
							minRows={3}
							maxRows={3}
							inputProps={{ readOnly: true }}
							value={mccComment.reqComm}
							helperText={`MD due update: ${mccComment.reqDue}`}
							sx={{
								...helperTextStyle,
							}}
						/>
					</Grid>
					<Grid item mt={-1} xl={6} lg={6} md={6} sm={6} xs={6}>
						<ColoredTextField
							fullWidth
							label="MDR (Material Declaration Request) ID"
							focused
							inputProps={{ readOnly: true }}
							value={mccComment.mdrId}
						/>
					</Grid>
					<Grid item mt={-1} xl={12} lg={12} md={12} sm={12} xs={12}>
						{logInfoTableValue.map((row, index) => (
							<Typography fontSize={"1rem"} key={index}>
								{<strong>{row.title}</strong>}: {row.info}
							</Typography>
						))}
					</Grid>
				</Grid>
			</CustomAccordionDetails>
		</CustomAccordion>
	);
};

export default RoHSMCC;
