// React
import { React, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadBomAction } from "../../store/UploadBom/UploadBom-slice";
import { SubsetAction } from "../../store/Subset/Subset-slice";
import { keyframes } from "@mui/system";
import { AdminAction } from "../../store/Admin/Admin-slice";
import { reportsActions } from "../../store/Reports/Reports-slice";
import { daysBackAction } from "../../store/common/DaysBack-slice";
import moment from "moment";
// MUI
import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Typography,
	Menu,
	Container,
	ListItemIcon,
	ListItemText,
	Button,
	Tooltip,
	MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import { NestedMenuItem } from "../UI/NestedMenuItem";
import ErrorBox from "../Util/ErrorBox";
import EventIcon from "@mui/icons-material/Event";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FullScreenLoadingIndicator from "../UI/FullScreenLoadingIndicator";

// Project components
import logo from "../../Static/keysight-logo.svg";
import { clearLoginLocalStorage, SSO_LOGOUT_URL, APIURL, s3BucketName, s3ppDocsName, downloadBlob } from "../Util/Util";
import { S3Settings } from "../Tabs/RoHS/S3Settings";
import ShortcutManual from "../Menu/ShortcutManual";
import Subset from "../Menu/Subset/Subset";
import Mousetrap from "mousetrap";
import AddEditUser from "../Menu/Admin/AddEditUser";
import RemoveUser from "../Menu/Admin/RemoveUser";
import ChangeUserOrg from "../Menu/Admin/ChangeUserOrg";
import SeeUserOrg from "../Menu/Admin/SeeUserOrg";
import ChangeProjectDate from "../Menu/Admin/ChangeProjectDate";
import useHttp from "../../Hooks/use-http";
import ResponsibilityMlarReport from "../Menu/Reports/Mlar/Responsibility";
import PreliminaryMlarReport from "../Menu/Reports/Mlar/Preliminary";
import StatusMlarReport from "../Menu/Reports/Mlar/Status";
import NotesActionStatusMlarReport from "../Menu/Reports/Mlar/NotesActionStatus";
import FinalMlarReport from "../Menu/Reports/Mlar/Final";
import Pc4MlarReport from "../Menu/Reports/Mlar/Pc4";
import FinalLiaisonReport from "../Menu/Reports/Liaison/Final";
import MTLResponsibilityOtherReport from "../Menu/Reports/Other/MtlResponsibility";
import DaysBack from "../Menu/DaysBack";
import MCCME from "../Menu/Reports/RoHS/MCCME";
import ME from "../Menu/Reports/RoHS/ME";
import OpTempReport from "../Menu/Reports/Temperature/OpTempReport";
import MyRoHSActionsRequired from "../Menu/Reports/RoHS/MyRoHSActionsRequired";
import PcRequestMlarReport from "../Menu/Reports/Mlar/PcRequest";
import MessageBox from "../Util/MessageBox";

const pages = ["Info", "Note", "WUBOM", "RoHS", "MTLEngInfo", "Projects", "CM", "RR"];
export const settings = {
	profile: "Profile",
	Reports: {
		mlar: {
			status: "Status",
			notesActionStatus: "Notes Action Status",
			responsibility: "Responsibility",
			preliminary: "Preliminary Status",
			final: "Final",
			pc4: "PC=4",
			pcReq: "PC Requests"
		},
		rohs: {
			myRoHSActionsRequired: "My RoHS Actions Required",
			mccme: "MCCME Summary/Det RoHS",
			me: "ME Summary/Det RoHS",
		},
		Temperature: {
			opTemp: "Operating Temperature",
		},
		liaison: {
			final: "Final",
		},
		other: {
			mtlResponsibility: "MTL Responsibility",
		},
	},
	daysBack: "Set Days Back",
	uploadBom: "Upload BOM",
	ResetAllSettings: "Reset All Settings",
	UserManual: "User Manual",
	ItSupport: "IT Support",
	Logout: "Logout",
	ShortcutManual: "Keyboard Shortcut",
	SubsetLoadAndExport: "Load/Export/Delete",
	SubsetCreate: "Create",
	SubsetSetOrder: "Set Order",
	AdminChangeOrg: "Change Users Org",
	AdminSeeUserOrgs: "See Users Org",
	AdminChangeProjectDate: "Change Completion Date",
	AdminAddEditUser: "Add/Edit User",
	AdminRemoveUser: "Delete User",
};

let infoHandler = () => {};

const NavBar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [ShortcutManualState, setShortcutManualState] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [infoMsg, setInfoMsg] = useState("");
	const [infoState, setInfoState] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { pathname } = useLocation();
	const userName = useSelector((state) => state.authenticationState.userName);
	const nonMtlEngAccess = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const daysBack = useSelector((state) => state.daysBackState.val);
	const reports = useSelector((state) => state.reportsState.reports);
	const adminProjectChangeData = useSelector((state) => state.adminState.changeProjectDate);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const reportOpenIconAnimation = keyframes`
		from {
			transform: rotate(-90deg);
		}
		to {
			transform: rotate(0deg);
		}
	`;
	const MLAR_ADMIN = useSelector((state) => state.authenticationState.Mlar_Admin);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleUrlClick = (url) => {
		handleCloseNavMenu();
		navigate(url);
		console.log(`${url} page`);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleSettingClick = (setting) => {
		switch (setting) {
			case "Logout":
				clearLoginLocalStorage();
				window.localStorage.removeItem("currentTab");
				window.location.replace(SSO_LOGOUT_URL);
				break;
			case "ResetAllSettings":
				window.localStorage.clear();
				window.sessionStorage.clear();
				window.location.reload();
				break;
			case "profile":
				navigate("/UserProfile");
				break;
			case "ShortcutManual":
				setShortcutManualState(true);
				break;
			case "uploadBom":
				dispatch(uploadBomAction.reset());
				navigate("/UploadBom");
				break;
			case "SubsetLoadAndExport":
				dispatch(SubsetAction.setCurrentTab(settings.SubsetLoadAndExport));
				dispatch(SubsetAction.setIsFilter(false));
				break;
			case "SubsetCreate":
				dispatch(SubsetAction.setCurrentTab(settings.SubsetCreate));
				break;
			case "SubsetSetOrder":
				dispatch(SubsetAction.setCurrentTab(settings.SubsetSetOrder));
				break;
			case "daysBack":
				dispatch(daysBackAction.setPopUpState(true));
				break;
			case "Reports.mlar.status":
				getOrgList((orgList) => updateOrg(orgList));
				dispatch(reportsActions.updatePopUpState({ reportType: "status", category: "mlar" }));
				break;
			case "Reports.mlar.notesActionStatus":
				getOrgList((orgList) => updateOrg(orgList));
				fetchDataWithoutLoad(
					{
						url: `${APIURL}bom_upload/usersList`,
						headers: headers,
						method: "POST",
						body: {
							userOrg: "",
						},
					},
					(data) => {
						dispatch(reportsActions.updateMtlNameList({ data: data.USERS_LIST }));
					},
					(error) => {
						handleError(error.toString());
					}
				);
				dispatch(reportsActions.updatePopUpState({ reportType: "notesActionStatus", category: "mlar" }));
				break;
			case "Reports.mlar.responsibility":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.mlar.responsibility.userOrg);
				dispatch(
					reportsActions.updatePopUpState({
						reportType: "responsibility",
						category: "mlar",
						initVal: { daysBack: daysBack },
					})
				);
				break;
			case "Reports.mlar.preliminary":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.mlar.preliminary.userOrg);
				dispatch(
					reportsActions.updatePopUpState({
						reportType: "preliminary",
						category: "mlar",
						initVal: { daysBack: daysBack },
					})
				);
				break;
			case "Reports.mlar.final":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.mlar.final.userOrg);
				dispatch(
					reportsActions.updatePopUpState({ reportType: "final", category: "mlar", initVal: { daysBack: daysBack } })
				);
				break;
			case "Reports.mlar.pc4":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.mlar.pc4.userOrg);
				dispatch(
					reportsActions.updatePopUpState({ reportType: "pc4", category: "mlar", initVal: { daysBack: daysBack } })
				);
				break;
			case "Reports.mlar.pcReq":
				fetchDataWithoutLoad(
					{
						url: `${APIURL}bom_upload/usersList`,
						headers: headers,
						method: "POST",
						body: {
							userOrg: "",
						},
					},
					(data) => {
						dispatch(reportsActions.updateMtlNameList({ data: data.USERS_LIST }));
					},
					(error) => {
						handleError(error.toString());
					}
				);
				dispatch(
					reportsActions.updatePopUpState({ reportType: "pcRequest", category: "mlar"})
				);
				break;
			case "Reports.rohs.myRoHSActionsRequired":
				getOrgList((orgList) => updateOrg(orgList));
				fetchDataWithoutLoad(
					{
						url: `${APIURL}bom_upload/usersList`,
						headers: headers,
						method: "POST",
						body: {
							userOrg: "",
						},
					},
					(data) => {
						dispatch(reportsActions.updateMtlNameList({ data: data.USERS_LIST }));
					},
					(error) => {
						handleError(error.toString());
					}
				);
				dispatch(reportsActions.updatePopUpState({ reportType: "myRoHSActionsRequired", category: "rohs" }));
				break;
			case "Reports.rohs.mccme":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.rohs.mccme.userOrg);
				dispatch(
					reportsActions.updatePopUpState({ reportType: "mccme", category: "rohs", initVal: { daysBack: daysBack } })
				);
				break;
			case "Reports.rohs.me":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.rohs.me.userOrg);
				dispatch(
					reportsActions.updatePopUpState({ reportType: "me", category: "rohs", initVal: { daysBack: daysBack } })
				);
				break;
			case "Reports.Temperature.opTemp":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.temperature.opTemp.userOrg);
				dispatch(
					reportsActions.updatePopUpState({
						reportType: "opTemp",
						category: "temperature",
						initVal: { daysBack: daysBack },
					})
				);
				break;
			case "Reports.liaison.final":
				getOrgList((orgList) => updateOrg(orgList));
				getBomList(daysBack, reports.liaison.final.userOrg);
				dispatch(
					reportsActions.updatePopUpState({ reportType: "final", category: "liaison", initVal: { daysBack: daysBack } })
				);
				break;
			case "Reports.other.mtlResponsibility":
				getOrgList((orgList) => updateOrg(orgList));
				dispatch(reportsActions.updatePopUpState({ reportType: "mtlResponsibility", category: "other" }));
				break;

			case "AdminChangeOrg":
				dispatch(AdminAction.setOpenChangeOrg(true));
				break;
			case "AdminSeeUserOrgs":
				dispatch(AdminAction.setOpenSeeAllOrg(true));
				break;
			case "AdminChangeProjectDate":
				getOrgList((orgList) => dispatch(AdminAction.setOrgSelection(orgList)));
				getBomListAdmin(
					adminProjectChangeData.startDate,
					adminProjectChangeData.endDate,
					adminProjectChangeData.userOrg
				);
				dispatch(AdminAction.setOpenChangeProjectDate(true));
				dispatch(AdminAction.updateChangeProjectDate({ data: { daysBack: daysBack } }));
				break;
			case "AdminAddEditUser":
				getUsersList((usersList) => {
					const filteredUsersList = usersList.USER_ORGS_INFO.map((user) => {
						return {
							ntUsername: user.userid,
							primaryOrg: user.primary,
							secondaryOrg: user.secondary,
							fullName: user.username,
							email: user.useremail,
							role: user.role,
						};
					});
					dispatch(
						AdminAction.updateEditUser({
							data: {
								ntUsernameList: filteredUsersList.sort((a, b) => a.fullName.localeCompare(b.fullName)),
							},
						})
					);
				});
				getOrgList((orgList) => {
					dispatch(AdminAction.updateOrgOptionWithAddUser(orgList));
				});
				dispatch(AdminAction.setOpenAddEditUser(true));
				break;
			case "AdminRemoveUser":
				getUsersList((usersList) => {
					const filteredUsersList = usersList.USER_ORGS_INFO.map((user) => {
						return {
							ntid: user.userid,
							ntUsername: user.username,
							primaryOrg: user.primary,
							secondaryOrg: user.secondary,
						};
					});
					dispatch(AdminAction.updateRemoveUserList(filteredUsersList.sort((a, b) => a.ntid.localeCompare(b.ntid))));
				});
				dispatch(AdminAction.setOpenRemoveUser(true));
				break;
			case "UserManual":
				downloadDocument("MLAR_Manual.pdf");
				break;
			case "ItSupport":
				downloadDocument("MLAR-Prod-Support-Model.pptx");
				break;
			default:
				break;
		}
		handleCloseUserMenu();
	};
	const updateOrg = (data) => {
		const orgList = data.USER_ORGS_LIST.map((org) => {
			return org.user_org_c;
		});
		dispatch(
			reportsActions.updateOrg({
				orgList: orgList,
			})
		);
	};
	const handleError = (error) => {
		setErrorState(true);
		setErrorMsg(error);
	};

	const infoBoxHandler = () => {
		setInfoState(false);
		setInfoMsg("");
		infoHandler();
		infoHandler = () => {};
	};
	const handleInfoBox = (info, handler) => {
		setInfoState(true);
		setInfoMsg(info);
		if (typeof handler === "function") {
			infoHandler = handler;
		}
	};
	const getBomList = (
		daysBack,
		userOrg,
		isLoading = () => {
			return;
		}
	) => {
		isLoading(true);
		const jsonData = {
			numberOfBackDays: daysBack,
			userOrg: userOrg,
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}bom_upload/mlarBomList`,
				headers: headers,
				method: "POST",
				body: jsonData,
			},
			(data) => {
				dispatch(reportsActions.setBomList(data));
				isLoading(false);
			},
			(error) => {
				isLoading(false);
				handleError(error.toString());
			}
		);
	};
	const getBomListAdmin = (
		startDate,
		endDate,
		userOrg,
		isLoading = () => {
			return;
		},
		inputDateFormat = "YYYY-MM-DD"
	) => {
		isLoading(true);
		const jsonData = {
			filterOn: "Date Added",
			startDate: moment(startDate, inputDateFormat).isValid()
				? moment(startDate, inputDateFormat).format("YYYY-MM-DD")
				: "",
			endDate: moment(endDate, inputDateFormat).isValid() ? moment(endDate, inputDateFormat).format("YYYY-MM-DD") : "",
			filterOnProject: "",
			filterOnBOM: "",
			userOrg: userOrg,
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}Projects/projectsDetails`,
				headers: headers,
				method: "POST",
				body: jsonData,
			},
			(retData) => {
				if (retData.Projects_Info.length > 0) {
					dispatch(AdminAction.updateProjectList(retData.Projects_Info));
				} else {
					dispatch(AdminAction.resetProjectList());
					handleError(`There is no projects from ${startDate} to ${endDate}`);
				}
				isLoading(false);
			},
			(error) => {
				isLoading(false);
				handleError(error.toString());
			}
		);
	};

	const getOrgList = (callback) => {
		fetchDataWithoutLoad(
			{
				url: `${APIURL}MtlEngInfo/userOrgsList`,
				headers: headers,
			},
			callback,
			(error) => {
				console.log(`Unable to retrieve User Information, Error: ${error}`);
				setErrorMsg(`ERROR: Unable to retrieve User Information, please try refresh the page. Error: ${error}`);
				setErrorState(true);
			}
		);
	};

	const getUsersList = (callback) => {
		fetchDataWithoutLoad({ url: `${APIURL}admin/assignedMultiUserOrgs/`, headers }, callback, (error) => {
			console.error(`Unable to retrieve User Information, Error: ${error}`);
			setErrorMsg(`ERROR: Unable to retrieve User Information, please try refresh the page. Error: ${error}`);
			setErrorState(true);
		});
	};
	useEffect(() => {
		Mousetrap.bind("alt+pagedown", (e) => {
			e.preventDefault();
			const path = pathname.replace("/", "");
			if (path !== pages[pages.length - 1]) {
				navigate(pages[pages.indexOf(path) + 1]);
			}
		});
		return () => Mousetrap.unbind("alt+pagedown");
	}, [navigate, pathname]);

	useEffect(() => {
		Mousetrap.bind("alt+pageup", (e) => {
			e.preventDefault();
			const path = pathname.replace("/", "");
			if (path !== pages[0]) {
				navigate(pages[pages.indexOf(path) - 1]);
			}
		});
		return () => Mousetrap.unbind("alt+pageup");
	}, [navigate, pathname]);

	const downloadDocument = async (docName) => {
		setIsLoading(true);
		const S3_Params = {
			Bucket: s3BucketName,
			Key: `${s3ppDocsName}/${docName}`,
		};

		const getS3Config = await S3Settings({ url: `${APIURL}Rohs/getS3ConnectionDetails`, headers });

		if (getS3Config.error) {
			console.log(getS3Config.errorMsg);
			setIsLoading(false);
			setErrorState(true);
			setErrorMsg(`${getS3Config.errorMsg}, please try again`);
		} else {
			getS3Config.getObject(S3_Params, (err, data) => {
				if (err) {
					setIsLoading(false);
					console.log(err, err.stack);
					setErrorState(true);
					setErrorMsg(`ERROR: ${err} Please try again`);
				} else {
					setIsLoading(false);
					const newBlob = new Blob([data.Body], { type: "application/octet-stream;charset=utf-8;" });
					downloadBlob(newBlob, `${docName}`);
				}
			});
		}
	};

	const menuProps = { sx: { height: 40, minWidth: 100 } };

	return (
		<>
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState}></ErrorBox>
			<MessageBox open={infoState} message={infoMsg} messageBoxHandler={infoBoxHandler} />
			<Subset settings={settings} />
			<AddEditUser onError={handleError} onSuccess={handleInfoBox} />
			<RemoveUser onError={handleError} onSuccess={handleInfoBox} />
			<SeeUserOrg />
			<ChangeUserOrg />
			<ChangeProjectDate onError={handleError} onSuccess={handleInfoBox} getBomList={getBomListAdmin} />
			<FullScreenLoadingIndicator loading={isLoading} />

			<AppBar
				sx={{
					boxShadow: 1,
				}}
			>
				<DaysBack />
				<ShortcutManual ShortcutManualState={ShortcutManualState} setShortcutManualState={setShortcutManualState} />
				<StatusMlarReport onError={handleError} onSuccess={handleInfoBox} />
				<NotesActionStatusMlarReport onError={handleError} onSuccess={handleInfoBox} />
				<MTLResponsibilityOtherReport onError={handleError} onSuccess={handleInfoBox} />
				<ResponsibilityMlarReport onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<PreliminaryMlarReport onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<FinalMlarReport onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<Pc4MlarReport onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<PcRequestMlarReport onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<MyRoHSActionsRequired onError={handleError} onSuccess={handleInfoBox} />
				<OpTempReport onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<MCCME onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<ME onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />
				<FinalLiaisonReport onError={handleError} getBomList={getBomList} onSuccess={handleInfoBox} />

				<Container maxWidth="xl" disableGutters>
					<Toolbar disableGutters sx={{ paddingLeft: 0.8, paddingRight: 0.8 }}>
						<Box
							component="img"
							height={"64px"}
							sx={{ mr: 2, display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}
							alt="Keysight Logo"
							src={logo}
						/>
						<Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								{anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								disableScrollLock
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
								}}
							>
								{pages.map((page) => (
									<MenuItem
										sx={{ borderLeft: pathname.replace("/", "") === page ? 3 : 0 }}
										key={page}
										onClick={() => {
											handleUrlClick(`/${page.replace(/\s+/g, "")}`);
										}}
									>
										<Typography textAlign="center">{page === "WUBOM" ? "WU / BOM" : page}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>

						<Box
							height={"64px"}
							sx={{
								flexGrow: 10,
								display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" },
							}}
							component="img"
							alt="Keysight Logo"
							src={logo}
						/>
						<Box sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}>
							{pages.map((page) => (
								<Button
									key={page}
									onClick={() => {
										handleUrlClick(`/${page.replace(/\s+/g, "")}`);
									}}
									sx={{
										minWidth: 45,
										my: 2,
										color: "#000",
										borderRadius: 0,
										fontWeight: pathname.replace("/", "") === page ? "bold" : "normal",
										borderBottom: pathname.replace("/", "") === page ? 2 : 0,
										backgroundColor: "white",
										display: "block",
										"&:hover": {
											backgroundColor: pathname.replace("/", "") === page ? "#ffffff00" : "#dbd9dd",
										},
									}}
								>
									{page === "WUBOM" ? "WU / BOM" : page === "MTLEngInfo" ? "MTL Eng Info" : page}
								</Button>
							))}
						</Box>

						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Open settings">
								<Button
									startIcon={<PersonIcon />}
									id="menu-appbar"
									aria-haspopup="true"
									variant="contained"
									disableElevation
									onClick={handleOpenUserMenu}
									size="medium"
									sx={{
										paddingLeft: 1,
										paddingRight: 1,
										color: "#000",
										fontSize: "10.5px",
										borderRadius: 0,
										backgroundColor: "#FFFFFF",
										"&:hover": {
											backgroundColor: "#dbd9dd",
										},
									}}
								>
									{userName || "Not Logged In"}
								</Button>
							</Tooltip>
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								transitionDuration={0}
								disableScrollLock
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<MenuItem
									sx={{ minWidth: "250px", height: 40 }}
									key={settings.profile}
									onClick={() => handleSettingClick("profile")}
								>
									<ListItemIcon>
										<AccountCircleIcon sx={{ color: "#000" }} fontSize="small" />
									</ListItemIcon>
									<ListItemText>{settings.profile}</ListItemText>
								</MenuItem>
								<NestedMenuItem
									leftIcon={<AssessmentIcon sx={{ color: "#000" }} fontSize="small" />}
									openIcon={
										<ArrowBackIosNewIcon
											sx={{
												color: "#000",
												animation: `${reportOpenIconAnimation} 0.2s`,
											}}
											fontSize="small"
										/>
									}
									fontSize="small"
									label="Reports"
									parentMenuOpen={Boolean(anchorElUser)}
								>
									<NestedMenuItem fontSize="small" label="Mlar" parentMenuOpen={Boolean(anchorElUser)}>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.mlar.status}
											onClick={() => handleSettingClick("Reports.mlar.status")}
										>
											{settings.Reports.mlar.status}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.mlar.notesActionStatus}
											onClick={() => handleSettingClick("Reports.mlar.notesActionStatus")}
										>
											{settings.Reports.mlar.notesActionStatus}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.mlar.responsibility}
											onClick={() => handleSettingClick("Reports.mlar.responsibility")}
										>
											{settings.Reports.mlar.responsibility}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.mlar.preliminary}
											onClick={() => handleSettingClick("Reports.mlar.preliminary")}
										>
											{settings.Reports.mlar.preliminary}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.mlar.final}
											onClick={() => handleSettingClick("Reports.mlar.final")}
										>
											{settings.Reports.mlar.final}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.mlar.pc4}
											onClick={() => handleSettingClick("Reports.mlar.pc4")}
										>
											{settings.Reports.mlar.pc4}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.mlar.pcReq}
											onClick={() => handleSettingClick("Reports.mlar.pcReq")}
										>
											{settings.Reports.mlar.pcReq}
										</MenuItem>
									</NestedMenuItem>
									<NestedMenuItem fontSize="small" label="Liaison" parentMenuOpen={Boolean(anchorElUser)}>
										<MenuItem
											sx={{ height: 55, minWidth: 120 }}
											component="label"
											key={settings.Reports.liaison.final}
											onClick={() => handleSettingClick("Reports.liaison.final")}
										>
											{settings.Reports.liaison.final}
										</MenuItem>
									</NestedMenuItem>
									<NestedMenuItem fontSize="small" label="RoHS" parentMenuOpen={Boolean(anchorElUser)}>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.rohs.myRoHSActionsRequired}
											onClick={() => handleSettingClick("Reports.rohs.myRoHSActionsRequired")}
										>
											{settings.Reports.rohs.myRoHSActionsRequired}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.rohs.mccme}
											onClick={() => handleSettingClick("Reports.rohs.mccme")}
										>
											{settings.Reports.rohs.mccme}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.Reports.rohs.me}
											onClick={() => handleSettingClick("Reports.rohs.me")}
										>
											{settings.Reports.rohs.me}
										</MenuItem>
									</NestedMenuItem>
									<NestedMenuItem fontSize="small" label="Temperature" parentMenuOpen={Boolean(anchorElUser)}>
										<MenuItem
											sx={{ height: 55, minWidth: 120 }}
											component="label"
											key={settings.Reports.Temperature.opTemp}
											onClick={() => handleSettingClick("Reports.Temperature.opTemp")}
										>
											{settings.Reports.Temperature.opTemp}
										</MenuItem>
									</NestedMenuItem>

									<NestedMenuItem fontSize="small" label="Other" parentMenuOpen={Boolean(anchorElUser)}>
										<MenuItem
											sx={{ height: 55, minWidth: 120 }}
											component="label"
											key={settings.Reports.other.mtlResponsibility}
											onClick={() => handleSettingClick("Reports.other.mtlResponsibility")}
										>
											{settings.Reports.other.mtlResponsibility}
										</MenuItem>
									</NestedMenuItem>
								</NestedMenuItem>

								<NestedMenuItem disabled={!MLAR_ADMIN} label="Admin" parentMenuOpen={Boolean(anchorElUser)}>
									<MenuItem
										{...menuProps}
										component="label"
										key={settings.AdminAddEditUser}
										onClick={() => handleSettingClick("AdminAddEditUser")}
									>
										{settings.AdminAddEditUser}
									</MenuItem>
									<MenuItem
										{...menuProps}
										component="label"
										key={settings.AdminRemoveUser}
										onClick={() => handleSettingClick("AdminRemoveUser")}
									>
										{settings.AdminRemoveUser}
									</MenuItem>
									<MenuItem
										{...menuProps}
										component="label"
										key={settings.AdminSeeUserOrgs}
										onClick={() => handleSettingClick("AdminSeeUserOrgs")}
									>
										{settings.AdminSeeUserOrgs}
									</MenuItem>
									<MenuItem
										{...menuProps}
										component="label"
										key={settings.AdminChangeOrg}
										onClick={() => handleSettingClick("AdminChangeOrg")}
									>
										{settings.AdminChangeOrg}
									</MenuItem>
									<MenuItem
										{...menuProps}
										component="label"
										key={settings.AdminChangeProjectDate}
										onClick={() => handleSettingClick("AdminChangeProjectDate")}
									>
										{settings.AdminChangeProjectDate}
									</MenuItem>
								</NestedMenuItem>
								{/* Custom made nested menu item */}
								<NestedMenuItem label="Tools" parentMenuOpen={Boolean(anchorElUser)}>
									<NestedMenuItem label="Subset" parentMenuOpen={Boolean(anchorElUser)}>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.SubsetLoadAndExport}
											onClick={() => handleSettingClick("SubsetLoadAndExport")}
										>
											{settings.SubsetLoadAndExport}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.SubsetCreate}
											onClick={() => handleSettingClick("SubsetCreate")}
										>
											{settings.SubsetCreate}
										</MenuItem>
										<MenuItem
											{...menuProps}
											component="label"
											key={settings.SubsetSetOrder}
											onClick={() => handleSettingClick("SubsetSetOrder")}
										>
											{settings.SubsetSetOrder}
										</MenuItem>
									</NestedMenuItem>
									<MenuItem
										{...menuProps}
										component="label"
										key={settings.uploadBom}
										disabled={nonMtlEngAccess}
										onClick={() => handleSettingClick("uploadBom")}
									>
										{settings.uploadBom}
									</MenuItem>
								</NestedMenuItem>
								<MenuItem {...menuProps} key={settings.daysBack} onClick={() => handleSettingClick("daysBack")}>
									<ListItemIcon>
										<EventIcon sx={{ color: "#000" }} fontSize="small" />
									</ListItemIcon>
									<ListItemText>{settings.daysBack}</ListItemText>
								</MenuItem>
								<MenuItem
									{...menuProps}
									key={settings.ShortcutManual}
									onClick={() => handleSettingClick("ShortcutManual")}
								>
									<ListItemIcon>
										<KeyboardIcon sx={{ color: "#000" }} fontSize="small" />
									</ListItemIcon>
									<ListItemText>{settings.ShortcutManual}</ListItemText>
								</MenuItem>
								<MenuItem {...menuProps} key={settings.UserManual} onClick={() => handleSettingClick("UserManual")}>
									<ListItemIcon>
										<ArticleOutlinedIcon sx={{ color: "#000" }} fontSize="small" />
									</ListItemIcon>
									<ListItemText>{settings.UserManual}</ListItemText>
								</MenuItem>
								<MenuItem {...menuProps} key={settings.ItSupport} onClick={() => handleSettingClick("ItSupport")}>
									<ListItemIcon>
										<InfoOutlinedIcon sx={{ color: "#000" }} fontSize="small" />
									</ListItemIcon>
									<ListItemText>{settings.ItSupport}</ListItemText>
								</MenuItem>
								<MenuItem
									{...menuProps}
									key={settings.ResetAllSettings}
									onClick={() => handleSettingClick("ResetAllSettings")}
								>
									<ListItemIcon>
										<RestartAltIcon sx={{ color: "#000" }} fontSize="small" />
									</ListItemIcon>
									<ListItemText>{settings.ResetAllSettings}</ListItemText>
								</MenuItem>
								<MenuItem {...menuProps} key={settings.Logout} onClick={() => handleSettingClick("Logout")}>
									<ListItemIcon>
										<LogoutIcon sx={{ color: "#000" }} fontSize="small" />
									</ListItemIcon>
									<ListItemText>{settings.Logout}</ListItemText>
								</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</>
	);
};
export default NavBar;
