// Packages Import
import { memo } from "react"
import { cx } from "@emotion/css";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

// Project Components
import InfoTable from "../../UI/Table"
import { mtlEngActions} from "../../../store/MtlEng/MtlEng-slice";


const columnsSettings = [
	{ field: "psgTitle", headerName: "PSG Chapter", type: "text", minWidth: 50, flex: 0.5 },
	// { field: "tMod", headerName: "Last Reviewed", type: "text", minWidth: 50, flex: 0.3 },
	// { field: "lName", headerName: "Last Name", type: "text", minWidth: 50, flex: 0.2 },
    // { field: "name", headerName: "Name", type: "text", minWidth: 50, flex: 0.2 },
	// { field: "link", headerName: "Link", type: "text", minWidth: 50, flex: 0.2 },
]

const PartSelectionTable = (props) => {
	const dispatch = useDispatch();
	const row = useSelector((state) => state.mtlEngState.PART_SELECTION_TABLE)
	const columnVisibility = useSelector((state) => state.mtlEngState.tableColumnVisibilityPartSelection)

	const handleOnRowClick = (params) => {
		if(params.row.link && params.row.link !== ""){
			window.open(params.row.link ,'_blank')
		}
		
	}
	
	const customCellClass = (params) => {
		if (params.row.link && params.row.link !== "" && params.field === "psgTitle") {
			return cx("super-app", {
				underline: true,
				pointerCursor: true,
			});
		}
	}
	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />

		</>
	)
	return <InfoTable 
        columnsSettings={columnsSettings} 
        rows={row}
		maxtableHeight={props.maxtableHeight || 1}
		height={props.height || 319}
		autoTableHeight={typeof props.autoTableHeight === "boolean" ? props.autoTableHeight : true }
		handleOnRowClick = {handleOnRowClick}
		customCellClass = {customCellClass}
		checkboxSelection = {false}
		customToolBar={customToolBar}
		ExtraProps = {{
			columnVisibilityModel:columnVisibility,
			onColumnVisibilityModelChange:(newModel) =>{
				dispatch(mtlEngActions.setColumnVisibility({columnVisibility:newModel , table:"PartSelection"}));
			}
		}}
		/>
}

export default memo(PartSelectionTable)
