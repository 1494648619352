// Packages Import
import { memo , useState } from "react"
import { useSelector , useDispatch } from "react-redux";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
// Project Components
import InfoTable from "../../UI/Table"
import { noteAction } from "../../../store/Note/Note-slice";
import {formatDate} from "../../Util/Util"



const columnsSettings = [
	{ field: "org", headerName: "Org", type: "number", minWidth: 50, flex: 0.2 },
	{ field: "note", headerName: "Note", type: "text", minWidth: 300, flex: 2 },
	{ field: "originator", headerName: "Originator", type: "text", minWidth: 60, flex: 0.4 },
    { field: "dateAdded", headerName: "Date Added", type: "text", minWidth: 60, flex: 0.4 },
	{ field: "updatedBy", headerName: "Updated By", type: "text", minWidth: 60, flex: 0.4 },
	{ field: "dateUpdated", headerName: "Date Updated", type: "text", minWidth: 60, flex: 0.4 },
]

const HistoryTable = (props) => {
	const dispatch = useDispatch();

	const rows = useSelector((state) => state.noteStates.NOTE_INFO_TABLE)
	const partNum = useSelector((state) => state.noteStates.PART_INFO.part)
	const columnVisibility = useSelector((state) => state.noteStates.tableColumnVisibility)
	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport 
				printOptions={{
					disableToolbarButton: true,
				}} 
				csvOptions={{
					fileName: `Notes_KPN${partNum}_${formatDate(new Date(), "")}`
				}}
			/>
		</>
	)
	return <InfoTable 
        columnsSettings={columnsSettings} 
        rows={rows}
		customToolBar={customToolBar}
		ExtraProps = {{
			columnVisibilityModel:columnVisibility,
			onColumnVisibilityModelChange:(newModel) =>{
				dispatch(noteAction.setColumnVisibility({columnVisibility:newModel}));
			}
		}}
		
	/>
}

export default memo(HistoryTable)
