import { createSlice, current } from "@reduxjs/toolkit";
import { isEmptyObject } from "../../Components/Util/Util";
import moment from "moment";

const userInfo = { id: 0, UserName: "", userid: "", primary: "", secondary: [], remove: [], error: "" };
const monthDifference = 6;
const endDate = moment().format("YYYY-MM-DD");
const startDate = moment(endDate).subtract(monthDifference, "months").format("YYYY-MM-DD");

const defaultChangeProjectDate = {
	userOrg: "F01",
	startDate: startDate,
	endDate: endDate,
	selectedProject: {
		proId: "",
		project: "",
		projectManager: "",
		designer: "",
		npiEng: "",
		liaison: "",
		bomNo: "",
		dateCompleted: "",
		inputDueDate: "",
	},
	projectList: [
		{
			proId: "",
			project: "",
			projectManager: "",
			designer: "",
			npiEng: "",
			liaison: "",
			bomNo: "",
			dateCompleted: "",
			inputDueDate: "",
		},
	],
	error: {
		projectList: true,
	},
	errorText: {
		projectList: "",
	},
};

const defaultAddUser = {
	ntUsername: "",
	ntUsernameList: [],
	fullName: "",
	email: "",
	primaryOrg: "",
	secondaryOrg: [],
};

const defaultEditUser = {
	selectedUser: "",
	ntUsernameList: [],
	fullName: "",
	email: "",
	primaryOrg: "",
	secondaryOrg: [],
};

const defaultRemoveUser = {
	ntUsers: [{ id: 1, ntUsername: "", ntid: "", primaryOrg: "", secondaryOrg: [] }],
	ntUsernameList: [],
};

const InitialState = {
	openChangeProjectDate: false,
	openSeeUserOrgs: false,
	openChangeOrg: false,
	openAddEditUser: false,
	openRemoveUser: false,
	allUserOrgsTable: [{ id: 0, user: "", primary: "", secondary: "" }],
	defaultUserInfo: userInfo,
	changeProjectDate: defaultChangeProjectDate,
	addUser: defaultAddUser,
	editUser: defaultEditUser,
	removeUser: defaultRemoveUser,
	currentUserInfo: [userInfo],
	allUserInfo: [userInfo],
	orgOptions: [],
};

const AdminSlice = createSlice({
	name: "Admin",
	initialState: InitialState,
	reducers: {
		reset: (state) => {
			return { ...InitialState, orgOptions: state.orgOptions, openChangeOrg: state.openChangeOrg };
		},

		setOpenSeeAllOrg(state, action) {
			state.openSeeUserOrgs = action.payload;
		},

		setAllUserOrgsTable(state, action) {
			state.allUserOrgsTable =
				action.payload?.USER_ORGS_INFO?.map((data, index) => {
					return {
						id: index,
						user: `${data.username} <${data.userid}>`,
						primary: data.primary,
						secondary: data.secondary && data.secondary.length > 0 ? data.secondary.join(", ") : "",
						role: data.role && data.role.length > 0 ? data.role.join(", ") : "",
					};
				}) || InitialState.allUserOrgsTable;
		},

		setOpenChangeOrg(state, action) {
			state.openChangeOrg = action.payload;
		},

		setSelectedPrimaryOrg(state, action) {
			state.selectedPrimaryOrg = action.payload;
		},

		setUserInfo(state, action) {
			let copyFields = JSON.parse(JSON.stringify(state.currentUserInfo));
			const { row, value, type } = action.payload;
			const index = state.currentUserInfo.findIndex((info) => info.id === row.id);
			const originalUserInfo = current(state.allUserInfo).find((data) => {
				return data.id === row.id;
			});

			switch (type) {
				case "user":
					if (value) {
						copyFields[index] = value;
					} else {
						copyFields[index] = state.defaultUserInfo;
					}
					break;
				case "primaryOrg":
					copyFields[index].secondary = [...copyFields[index].secondary, copyFields[index].primary];
					copyFields[index].secondary = copyFields[index].secondary.filter((org) => org !== value);
					copyFields[index].primary = value;
					break;
				case "secondaryOrg":
					copyFields[index].secondary = value;
					break;
				default:
					break;
			}

			if (type !== "user") {
				const allOrg = [originalUserInfo.primary, ...originalUserInfo.secondary];
				const currentAllOrg = [copyFields[index].primary, ...copyFields[index].secondary];
				copyFields[index].remove = allOrg.filter((org) => !currentAllOrg.includes(org));
			}

			state.currentUserInfo = copyFields;
		},

		addUserInfo(state, action) {
			state.currentUserInfo = action.payload;
		},

		removeInputBox(state, action) {
			const { row } = action.payload;
			const addUserInfo = state.currentUserInfo.filter((field) => field.id !== row.id);
			const newUserInfo = addUserInfo.map((field, index) => {
				// Reset id
				const { id, ...rest } = field;
				return { id: index, ...rest };
			});

			state.currentUserInfo = newUserInfo;
			state.selectedFields = newUserInfo.map((data) => data.selection);
		},

		setAllUserInfo(state, action) {
			state.allUserInfo =
				action.payload?.USER_ORGS_INFO?.map((data, index) => {
					return {
						...userInfo,
						id: index,
						UserName: data.username,
						userid: data.userid,
						primary: data.primary,
						secondary: data.secondary ?? [],
					};
				}) || InitialState.allUserInfo;
		},

		setOrgSelection(state, action) {
			state.orgOptions =
				action.payload?.USER_ORGS_LIST?.map((org) => {
					return org.user_org_c;
				}) || InitialState.orgOptions;
		},
		setOpenChangeProjectDate(state, action) {
			state.openChangeProjectDate = action.payload;
		},
		updateChangeProjectDate(state, action) {
			for (let key in action.payload.data) {
				state.changeProjectDate[key] = action.payload.data[key];
			}
		},
		updateProjectList(state, action) {
			const projects = action.payload;
			if (!isEmptyObject(projects)) {
				let projectsData = [];
				projects.forEach((singleProjectData) => {
					const grid = singleProjectData.Grid_Section;
					const edit = singleProjectData.Edit_Section;
					const dateCompleted = moment(grid.dcompl, "YYYY-MM-DD").isValid()
						? moment(grid.dcompl, "YYYY-MM-DD").format("YYYY-MM-DD")
						: "";
					const inputDueDate = moment(grid.dinpdate, "YYYY-MM-DD").isValid()
						? moment(grid.dinpdate, "YYYY-MM-DD").format("YYYY-MM-DD")
						: "";

					const projectData = {
						proId: singleProjectData.pro_id ?? "",
						project: grid.project ?? "",
						projectManager: edit.pr_mgr ?? "",
						designer: edit.designer ?? "",
						npiEng: edit.npieng ?? "",
						liaison: edit.liaison ?? "",
						bomNo: grid.bomno ?? "",
						dateCompleted: dateCompleted,
						inputDueDate: inputDueDate,
					};
					projectsData.push(projectData);
				});
				state.changeProjectDate.projectList = projectsData;
			} else {
				state.changeProjectDate.projectList = defaultChangeProjectDate.projectList;
			}
		},
		updateProjectDateCompleted(state, action) {
			state.changeProjectDate.selectedProject.dateCompleted = action.payload;
		},
		resetChangeProjectDate(state, action) {
			const ignore = action.payload?.ignore;
			let ignoreReset = {};
			if (Array.isArray(ignore) && ignore.length > 0) {
				ignore.forEach((ignoreKey) => {
					const exist = Object.keys(state.changeProjectDate).findIndex((key) => {
						return key === ignoreKey;
					});
					if (exist >= 0) {
						ignoreReset[ignoreKey] = state.changeProjectDate[ignoreKey];
					}
				});
			}
			state.changeProjectDate = {
				...InitialState.changeProjectDate,
				...ignoreReset,
			};
		},
		resetProjectList(state, action) {
			state.changeProjectDate.projectList = defaultChangeProjectDate.projectList;
		},
		resetSelectedProject(state, action) {
			state.changeProjectDate.selectedProject = defaultChangeProjectDate.selectedProject;
		},
		setOpenAddEditUser(state, action) {
			state.openAddEditUser = action.payload ?? !state.openAddEditUser;
		},
		updateOrgOptionWithAddUser(state, action) {
			const orgList =
				action.payload?.USER_ORGS_LIST?.map((org) => {
					return org.user_org_c;
				}) ?? InitialState.orgOptions;
			const defaultOrgVal = orgList === [] ? "" : orgList[0];
			state.addUser.primaryOrg = defaultOrgVal;
			state.orgOptions = orgList;
		},
		updateAddUser(state, action) {
			for (let key in action.payload.data) {
				state.addUser[key] = action.payload.data[key];
			}
		},
		updateEditUser(state, action) {
			for (let key in action.payload.data) {
				state.editUser[key] = action.payload.data[key];
			}
		},
		resetAddUser(state, action) {
			const ignore = action.payload?.ignore;
			let ignoreReset = {};
			if (Array.isArray(ignore) && ignore.length > 0) {
				ignore.forEach((ignoreKey) => {
					const exist = Object.keys(state.addUser).findIndex((key) => {
						return key === ignoreKey;
					});
					if (exist >= 0) {
						ignoreReset[ignoreKey] = state.addUser[ignoreKey];
					}
				});
			}
			state.addUser = {
				...InitialState.addUser,
				...ignoreReset,
			};
		},
		resetEditUser(state, action) {
			const ignore = action.payload?.ignore;
			let ignoreReset = {};
			if (Array.isArray(ignore) && ignore.length > 0) {
				ignore.forEach((ignoreKey) => {
					const exist = Object.keys(state.editUser).findIndex((key) => {
						return key === ignoreKey;
					});
					if (exist >= 0) {
						ignoreReset[ignoreKey] = state.editUser[ignoreKey];
					}
				});
			}
			state.editUser = {
				...InitialState.editUser,
				...ignoreReset,
			};
		},
		setOpenRemoveUser(state, action) {
			state.openRemoveUser = action.payload ?? !state.openRemoveUser;
		},
		updateRemoveUserList(state, action) {
			state.removeUser.ntUsernameList = action.payload;
		},
		updateRemoveUsers(state, action) {
			const ntUsers = state.removeUser.ntUsers.map((user) => {
				const newUser = action.payload;
				if (user.id === newUser.id) {
					return {
						...user,
						ntUsername: newUser.ntUsername || "",
						ntid: newUser.ntid || "",
						primaryOrg: newUser.primaryOrg || "",
						secondaryOrg: newUser.secondaryOrg || [],
					};
				}

				return user;
			});
			state.removeUser.ntUsers = ntUsers;
			// const newUser = action.payload;
			// let ntUsers = []
			// let ntUsersList = []
			// let replaceNtUsers = {}
			// if(newUser.ntid === null){
			// 	ntUsers = state.removeUser.ntUsers.map((user) => {
			// 		if (user.id === newUser.id) {
			// 			const { id , ...userCopy } = user
			// 			replaceNtUsers = userCopy
			// 			return { ...user, ntUsername: newUser.ntUsername || "", ntid: newUser.ntid || "" , primaryOrg: newUser.primaryOrg || "" , secondaryOrg : newUser.secondaryOrg || []};
			// 		}

			// 		return user;
			// 	});
			// 	ntUsersList = state.removeUser.ntUsernameList.push(replaceNtUsers)
			// }else{
			// 	ntUsers = state.removeUser.ntUsers.map((user) => {
			// 		if (user.id === newUser.id) {
			// 			const { id , ...userCopy } = newUser
			// 			replaceNtUsers = userCopy
			// 			return { ...user, ntUsername: newUser.ntUsername || "", ntid: newUser.ntid || "" , primaryOrg: newUser.primaryOrg || "" , secondaryOrg : newUser.secondaryOrg || []};
			// 		}

			// 		return user;
			// 	});
			// 	ntUsersList = state.removeUser.ntUsernameList.filter((user) => {
			// 		return user.ntid !== replaceNtUsers.ntid
			// 	})
			// }
			// state.removeUser.ntUsers = ntUsers;
			// state.removeUser.ntUsernameList = ntUsersList
		},
		addNewUser(state) {
			const currentId = state.removeUser.ntUsers.length > 0 ? state.removeUser.ntUsers.at(-1).id + 1 : 1;
			state.removeUser.ntUsers = [
				...state.removeUser.ntUsers,
				{ ...InitialState.removeUser.ntUsers[0], id: currentId },
			];
		},
		removeUser(state, action) {
			state.removeUser.ntUsers = state.removeUser.ntUsers.filter((user) => {
				return user.id !== action.payload;
			});
		},
		resetRemoveUser(state, action) {
			const ignore = action.payload?.ignore;
			let ignoreReset = {};
			if (Array.isArray(ignore) && ignore.length > 0) {
				ignore.forEach((ignoreKey) => {
					const exist = Object.keys(state.removeUser).findIndex((key) => {
						return key === ignoreKey;
					});
					if (exist >= 0) {
						ignoreReset[ignoreKey] = state.removeUser[ignoreKey];
					}
				});
			}
			state.removeUser = {
				...InitialState.removeUser,
				...ignoreReset,
			};
		},
	},
});

export const AdminAction = AdminSlice.actions;

export default AdminSlice;
