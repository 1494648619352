// Packages Import
import { useState } from "react";
import { Box, Tooltip, Button, Modal, Grid, Tab, Autocomplete, Typography, Link, AppBar, Toolbar } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
// Project Component
import ColoredTextField from "../../UI/ColoredTextField";
import DropdownFilter from "../../UI/DropdownFilter";
import useHttp from "../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import AutocompleteCheckBox from "../../UI/AutocompleteCheckBox";
import { AdminAction } from "../../../store/Admin/Admin-slice";
import { APIURL, OSAltKey, s3BucketName, s3ppDocsName, downloadBlob } from "../../Util/Util";
import { S3Settings } from "../../Tabs/RoHS/S3Settings";
const AddEditUser = (props) => {
	const { onSuccess = () => {}, onError = () => {} } = props;
	const dispatch = useDispatch();
	const { sendRequest: fetchData } = useHttp();
	const [loading, setLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState("add");
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const addUserData = useSelector((state) => state.adminState.addUser);
	const editUserData = useSelector((state) => state.adminState.editUser);
	const showPopup = useSelector((state) => state.adminState.openAddEditUser);
	const orgList = useSelector((state) => state.adminState.orgOptions);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "85%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};

	const handlePrimaryOrgChange = (value) => {
		const secondaryOrg = addUserData.secondaryOrg.filter((el) => el !== value);

		dispatch(
			AdminAction.updateAddUser({
				data: {
					primaryOrg: value,
					secondaryOrg: secondaryOrg,
				},
			})
		);
	};

	const handleSecondaryOrgChange = (value) => {
		dispatch(
			AdminAction.updateAddUser({
				data: {
					secondaryOrg: value,
				},
			})
		);
	};

	const handleInputChange = (field) => (event) => {
		const val = event.target.value;
		const updateVal = (val) => {
			if (currentTab === "add") {
				dispatch(AdminAction.updateAddUser(val));
			}
			if (currentTab === "edit") {
				dispatch(AdminAction.updateEditUser(val));
			}
		};
		switch (field) {
			case "ntUsername":
				updateVal({
					data: {
						ntUsername: val,
					},
				});
				break;
			case "fullname":
				updateVal({
					data: {
						fullName: val,
					},
				});
				break;
			case "email":
				updateVal({
					data: {
						email: val,
					},
				});
				break;
			default:
				break;
		}
	};

	const handleNtUsernameSelection = (event, newValue) => {
		dispatch(
			AdminAction.updateEditUser({
				data: {
					selectedUser:{
						ntUsername: newValue?.ntUsername ? newValue?.ntUsername : "",
						fullName: newValue?.fullName ? newValue.fullName : "",
					},
					primaryOrg: newValue?.primaryOrg ? newValue.primaryOrg : "",
					secondaryOrg: newValue?.secondaryOrg ? newValue.secondaryOrg : [],
					fullName: newValue?.fullName ? newValue.fullName : "",
					email: newValue?.email ? newValue.email : "",
				},
			})
		);
	};

	const handleAddUser = () => {
		closeHandler();
		const jsonReq = {
			userid: userInfo.id,
			userNTId: addUserData.ntUsername,
			fullName: addUserData.fullName,
			emailAddress: addUserData.email || "",
			primaryOrg: addUserData.primaryOrg,
			secondaryOrg: addUserData.secondaryOrg || [],
			action: "Add",
		};
		setLoading(true);
		fetchData(
			{
				url: `${APIURL}admin/addOrEditUser`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(retData) => {
				onSuccess(retData.Success);
				setLoading(false);
			},
			(error) => {
				onError(error.toString());
				setLoading(false);
			}
		);
	};

	const handleEditUser = () => {
		closeHandler();
		const jsonReq = {
			userid: userInfo.id,
			userNTId: editUserData.selectedUser.ntUsername,
			fullName: editUserData.fullName,
			emailAddress: editUserData.email || "",
			action: "Edit",
		};
		setLoading(true);
		fetchData(
			{
				url: `${APIURL}admin/addOrEditUser`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(retData) => {
				onSuccess(retData.Success);
				setLoading(false);
			},
			(error) => {
				onError(error.toString());
				setLoading(false);
			}
		);
	};

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const downloadDocument = async () => {
		setLoading(true);
		const docName = "Add_Remove_User_ActiveRoles.pdf";
		const S3_Params = {
			Bucket: s3BucketName,
			Key: `${s3ppDocsName}/${docName}`,
		};
		const getS3Config = await S3Settings({ url: `${APIURL}Rohs/getS3ConnectionDetails`, headers });
		if (getS3Config.error) {
			console.log(getS3Config.errorMsg);
			setLoading(false);
			onError(`${getS3Config.errorMsg}, please try again`);
		} else {
			getS3Config.getObject(S3_Params, (err, data) => {
				if (err) {
					console.log(err, err.stack);
					setLoading(false);

					onError(`ERROR: ${err} Please try again`);
				} else {
					const newBlob = new Blob([data.Body], { type: "application/octet-stream;charset=utf-8;" });
					downloadBlob(newBlob, `${docName}`);
					setLoading(false);
				}
			});
		}
	};

	const closeHandler = () => {
		dispatch(AdminAction.setOpenAddEditUser(false));
		dispatch(AdminAction.resetAddUser());
		dispatch(AdminAction.resetEditUser());
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								closeHandler();
							}
							if (currentTab === "add" && e.code === "KeyA") {
								e.preventDefault()
								if (addUserData.fullName && addUserData.ntUsername) {
									handleAddUser()
								}
							}
							if (currentTab === "edit" && e.code === "KeyE") {
								e.preventDefault()
								if (editUserData.fullName && editUserData.selectedUser.ntUsername) {
									handleEditUser()
								}
							}
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Admin - Add/Edit User"}
							</Typography>
						</Toolbar>
					</AppBar>
					<TabContext value={currentTab}>
						<TabList
							centered
							variant="fullWidth"
							sx={{
								mt: 5,
							}}
							onChange={handleTabChange}
						>
							<Tab
								label="Add User"
								value="add"
								sx={{
									"&:hover": {
										bgcolor: currentTab === "add" ? "#ffffff00" : "#dbd9dd",
									},
									"&.Mui-selected": { fontWeight: "bold" },
								}}
							/>
							<Tab
								label="Edit User"
								value="edit"
								sx={{
									"&:hover": {
										bgcolor: currentTab === "edit" ? "#ffffff00" : "#dbd9dd",
									},
									"&.Mui-selected": { fontWeight: "bold" },
								}}
							/>
						</TabList>
						<TabPanel value="add" sx={{ p: 0, py: 2 }}>
							<Grid
								container
								direction="row"
								spacing={0}
								rowSpacing={2}
								sx={{
									pt: 1,
								}}
							>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<Typography variant="subtitle">
										Adding User in MLAR AD Global Group is mandatory for completing MLAR access setup. Refer document{" "}
										<Link
											color={"secondary"}
											sx={{
												cursor: "pointer",
											}}
											onClick={downloadDocument}
										>
											Add_Remove_User_ActiveRoles.pdf
										</Link>{" "}
										for step-by-step process on adding users.
									</Typography>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>NTUsername</b>}
										fullWidth
										focused
										required
										onChange={handleInputChange("ntUsername")}
										value={addUserData.ntUsername}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Full Name</b>}
										fullWidth
										focused
										required
										onChange={handleInputChange("fullname")}
										value={addUserData.fullName}
										InputLabelProps={{
											shrink: true,
										}}
										helperText={"First Name Last Name"}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Email Address</b>}
										fullWidth
										focused
										onChange={handleInputChange("email")}
										value={addUserData.email}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid
									item
									xl={2.5}
									lg={2.5}
									md={2.5}
									sm={2.5}
									xs={12}
									sx={{
										pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
									}}
								>
									<DropdownFilter
										selections={orgList}
										selectedData={addUserData.primaryOrg}
										handleSelection={handlePrimaryOrgChange}
										label={<b>Primary Org</b>}
										required={true}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={9.5} lg={9.5} md={9.5} sm={9.5} xs={12}>
									<AutocompleteCheckBox
										ExtraProps={{
											size: "small",
											getOptionDisabled: (option) => addUserData.primaryOrg === option,
										}}
										label={<b>Secondary Org</b>}
										options={orgList}
										handleOnChange={handleSecondaryOrgChange}
										value={addUserData.secondaryOrg}
										optionLabel={(option) => `${option}`}
									/>
								</Grid>
								<Grid container item direction="row" spacing={0} rowSpacing={0} xl={12} lg={12} md={12} sm={12} xs={12}>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Typography variant="caption">
											&#x2022; Fields marked with (<span style={{ color: "red" }}>*</span>) are mandatory
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value="edit" sx={{ p: 0, py: 2 }}>
							<Grid
								container
								direction="row"
								spacing={0}
								rowSpacing={2}
								sx={{
									pt: 1,
								}}
							>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<Autocomplete
										autoHighlight
										onChange={handleNtUsernameSelection}
										options={editUserData.ntUsernameList}
										getOptionLabel={(option) => {
											return option.ntUsername ? `${option.fullName} <${option.ntUsername}>` : "";
										}}
										isOptionEqualToValue={(option, value) => option.ntUsername === value.ntUsername}
										size="small"
										value={editUserData.selectedUser === "" ? null : editUserData.selectedUser}
										renderInput={(params) => {
											return (
												<ColoredTextField
													focused
													label={<b>NTUsername</b>}
													{...params}
													InputProps={{
														...params.InputProps,
														sx: { textOverflow: "ellipsis" },
													}}
												/>
											);
										}}
									/>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Full Name</b>}
										fullWidth
										focused
										required
										onChange={handleInputChange("fullname")}
										value={editUserData.fullName}
										InputLabelProps={{
											shrink: true,
										}}
										helperText={"First Name Last Name"}
									/>
								</Grid>

								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										label={<b>Email Address</b>}
										fullWidth
										focused
										onChange={handleInputChange("email")}
										value={editUserData.email}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid
									item
									xl={2.5}
									lg={2.5}
									md={2.5}
									sm={2.5}
									xs={12}
									sx={{
										pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
									}}
								>
									<ColoredTextField
										value={editUserData.primaryOrg}
										label={<b>Primary Org</b>}
										fullWidth
										focused
										disabled={true}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xl={9.5} lg={9.5} md={9.5} sm={9.5} xs={12}>
									<ColoredTextField
										value={editUserData.secondaryOrg}
										label={<b>Secondary Org</b>}
										fullWidth
										focused
										disabled={true}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid container item direction="row" spacing={0} rowSpacing={0} xl={12} lg={12} md={12} sm={12} xs={12}>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Typography variant="caption">
											&#x2022; Fields marked with (<span style={{ color: "red" }}>*</span>) are mandatory
										</Typography>
									</Grid>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Typography variant="caption">&#x2022; Full Name and Email Address are editable fields</Typography>
									</Grid>
								</Grid>
							</Grid>
						</TabPanel>
					</TabContext>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Box>
							{currentTab === "add" ? (
								<Tooltip title={`For shortcut press ${OSAltKey} + A`}>
									<span>
										<Button onClick={handleAddUser} disabled={!addUserData.ntUsername || !addUserData.fullName}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>A</u>dd User
										</Button>
									</span>
								</Tooltip>
							) : (
								<Tooltip title={`For shortcut press ${OSAltKey} + E`}>
									<span>
										<Button onClick={handleEditUser} disabled={!editUserData.selectedUser || !editUserData.fullName}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>E</u>dit User
										</Button>
									</span>
								</Tooltip>
							)}
							<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
								<span>
									<Button onClick={closeHandler}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</span>
							</Tooltip>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default AddEditUser;
