// React
import { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";

// UI
import InfoTable from "../../../UI/Table";
import DeleteMpn from "../PopUp/DeleteMpn";
import { cx } from "@emotion/css";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Menu, MenuItem } from "@mui/material";
import { formatDate } from "../../../Util/Util";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
const selectOptionsChoice = ["Yes", "No", "N/A", "Not reviewed yet", "Under review", "See note"];
const select260OptionsChoice = ["Yes", "No", "N/A", "May need special Processing", "Not reviewed yet", "Under review", "See note"];
const selectOptionsTemps = ["Not reviewed yet", "<70 degC", "=70 degC", ">70 and <85 deg C", ">=85 degC", "N/A"];

const columnsSettings = [
	{
		field: "mpn",
		headerName: "Oracle MFG Part Number (MPN) MEP",
		type: "text",
		minWidth: 180,
		flex: 0.4,
	},
	{
		field: "mfgName",
		headerName: "Oracle Manufacturer Name",
		type: "text",
		minWidth: 150,
		flex: 0.4,
	},
	{
		field: "actionRequest",
		headerName: "Action req.",
		description: "Action Required!",
		type: "checkbox",
		minWidth: 60,
		flex: 0.2,
		defaultCursor: true,
	},
	{
		field: "rohs66",
		headerName: "RoHS",
		description: "RoHS compliance",
		type: "checkbox",
		minWidth: 60,
		flex: 0.2,
	},
	{
		field: "rohs66Input",
		headerName: "ROHS input",
		type: "select",
		selectOptions: selectOptionsChoice,
		minWidth: 100,
		flex: 0.4,
	},
	{
		field: "appPBFF",
		headerName: "App PbF Term Fnsh",
		description: "Approved PB Free Termination Finish",
		type: "checkbox",
		minWidth: 80,
		flex: 0.25,
	},
	{
		field: "aBPFTFT",
		headerName: "APBF Termination Finish",
		description: "Approved PB Free Termination Finish Info",
		type: "select",
		selectOptions: selectOptionsChoice,
		minWidth: 100,
		flex: 0.4,
	},
	{
		field: "t260deg",
		headerName: "260 \u2103",
		description: "Compliance with 260 deg c requirement",
		type: "checkbox",
		minWidth: 70,
		flex: 0.2,
		sortable: false,
	},
	{
		field: "t260degt",
		headerName: "260\u2103 Info",
		description: "Solder temperature compliance Info",
		type: "select",
		selectOptions: select260OptionsChoice,
		minWidth: 100,
		flex: 0.4,
	},
	{
		field: "maxOTemp",
		headerName: "Max Operating temp. (\u2103)",
		description: "Maximum Operating temperature (deg C)",
		type: "select",
		selectOptions: selectOptionsTemps,
		minWidth: 120,
		flex: 0.5,
	},
	{
		field: "mcc66",
		headerName: "MCC RoHs",
		type: "checkbox",
		description: "MCC data RoHS information",
		minWidth: 60,
		flex: 0.2,
		defaultCursor: true,
	},
	{
		field: "mccMdrState",
		headerName: "MCC MDR State",
		type: "text",
		description: "MCC Material Declaration Request status-state",
		minWidth: 100,
		flex: 0.5,
	},
	{
		field: "Pbftf",
		headerName: "Approved PbFree Termination Finish",
		type: "text",
		minWidth: 130,
		flex: 0.5,
	},
	{
		field: "rohsSolderP",
		headerName: "RoHS solder process",
		description: "Oracle 260 deg c info",
		type: "text",
		minWidth: 100,
		flex: 0.5,
	},
	{
		field: "rcr",
		headerName: "RCR",
		description: "RoHS Compliance Required",
		type: "checkbox",
		maxWidth: 60,
		flex: 0.2,
	},
];

const RoHsOracleTable = (props) => {
	const dispatch = useDispatch();

	const rows = useSelector((state) => state.rohsStates.oracleTable);
	const itemPartNumber = useSelector((state) => state.rohsStates.partInfo.partNumber);
	const selectedMpn = useSelector((state) => state.rohsStates.selectedMpn);
	const selectedMfgId = useSelector((state) => state.rohsStates.selectedMfgId);
	const selectedMfgName = useSelector((state) => state.rohsStates.selectedMfgName);
	const userID = useSelector((state) => state.authenticationState.userNTID);
	const oracleTableEditStates = useSelector((state) => state.rohsStates.oracleTableEditStates);
	const editedTable = useSelector((state) => state.rohsStates.editedTable);
	const fullData = useSelector((state) => state.rohsStates.fullData);
	const [showDeletePopUp, setShowDeletePopUp] = useState(false);
	const [mpnDetails, setMpnDetails] = useState({});

	// Right click on the table
	const [contextMenu, setContextMenu] = useState(null);

	const editing = oracleTableEditStates.editing;
	const borderSetting = oracleTableEditStates.borderSetting;
	const buttonText = oracleTableEditStates.buttonText;

	const columnVisibility = useSelector((state) => state.rohsStates.tableRohsColumnVisibility);

	const ExtraProps = {
		sx: {
			...borderSetting,
			"& .MuiDataGrid-columnHeaderTitle": {
				textOverflow: "clip",
				whiteSpace: "break-spaces",
				lineHeight: 1.3,
			},
			height: 330,
		},
		headerHeight: 105,
		columnVisibilityModel: columnVisibility,
		onColumnVisibilityModelChange: (newModel) => {
			dispatch(RoHSAction.setRohsColumnVisibility({ columnVisibility: newModel }));
		},
	};

	const customCellClass = (params) => {
		const selectedRow =
			!params.hasFocus && params.row.mpn === selectedMpn && params.row.mfgId.toString() === selectedMfgId && params.row.mfgName === selectedMfgName;
		switch (params.field) {
			case "mpn":
				const mpnColor = () => {
					if (selectedRow) return { selectedRow: true };
					if (params.row.aslStCd === "History") return { grey: true };
					if (params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete") return { red: true };
					if (params.row.aslStCd === "To be") return { green: true };
					return { blue: true };
				};
				return cx("super-app", {
					...mpnColor(),
					pointerCursor: true,
					italic: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
					strikeThrough: params.row.aslDisab === "T" || (params.row.aslStCd !== "Approved" && params.row.aslStCd !== "To Be Discontinued"),
				});
			case "mfgName":
				const manuColor = () => {
					if (selectedRow) return { selectedRow: true };
					if (params.row.aslStCd === "History") return { grey: true };
					if (params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete") return { red: true };
					if (params.row.aslStCd === "To be") return { green: true };
					return { blue: true };
				};
				return cx("super-app", {
					...manuColor(),
					bold: params.row.aslStCd === "Preferred",
					italic: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
					strikeThrough: params.row.aslDisab === "T" || (params.row.aslStCd !== "Approved" && params.row.aslStCd !== "To Be Discontinued"),
					fontBlue: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
				});
			case "actionRequest":
				return cx("super-app", {
					yellow: params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete",
					grey: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
				});
			case "rohs66":
				return cx("super-app", {
					grey: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete" || !params.row.rohs66Input,
					green: params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete",
				});
			case "rohs66Input":
				return cx("super-app", {
					yellow: params.value !== "Yes" && !params.value?.startsWith("No") && params.value !== "N/A",
					green: params.value === "Yes" || params.value === "N/A",
					orange: params.value?.startsWith("No"),
				});
			case "appPBFF":
				return cx("super-app", {
					green: params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete",
					grey: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
				});
			case "aBPFTFT":
				return cx("super-app", {
					yellow: params.value !== "Yes" && !params.value?.startsWith("No") && params.value !== "N/A",
					green: params.value === "Yes" || params.value === "N/A",
					orange: params.value?.startsWith("No"),
				});
			case "t260deg":
				return cx("super-app", {
					green: params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete",
					grey: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
				});
			case "t260degt":
				return cx("super-app", {
					yellow: params.value !== "Yes" && !params.value?.startsWith("No") && params.value !== "N/A",
					green: params.value === "Yes" || params.value === "N/A",
					orange: params.value?.startsWith("No"),
				});
			case "maxOTemp":
				return cx("super-app", {
					orange: params.value?.startsWith("Not"),
					yellow: !params.value?.startsWith("Not"),
				});
			case "mcc66":
				return cx("super-app", {
					grey: params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete",
					red: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
				});
			case "mccMdrState":
				const mccMdrColor = () => {
					if (selectedRow) {
						return { selectedRow: true };
					} else {
						return { yellow: true };
					}
				};
				return cx("super-app", {
					...mccMdrColor(),
				});
			case "Pbftf":
				const PbftfColor = () => {
					if (selectedRow) return { selectedRow: true };
					if (params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete") return { grey: true };
					if (params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete") return { red: true };
					return { blue: true };
				};
				return cx("super-app", {
					...PbftfColor(),
				});
			case "rohsSolderP":
				const rohsSolderPColor = () => {
					if (selectedRow) return { selectedRow: true };
					if (params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete") return { grey: true };
					if (params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete") return { red: true };
					return { blue: true };
				};
				return cx("super-app", {
					...rohsSolderPColor(),
				});
			case "rcr":
				return cx("super-app", {
					grey: params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete",
					red: params.row.aslDisab === "T" || params.row.aslStCd === "Obsolete",
				});
			default:
				return params;
		}
	};

	const handleSelectChange = (params) => (event) => {
		dispatch(
			RoHSAction.handleEditTable({
				mpn: params.row.mpn,
				mfgId: params.row.mfgId,
				field: params.field,
				value: event.target.value,
			})
		);
	};

	const handleCheckBoxChange = (params) => {
		if (editing) {
			if (params.row.aslDisab !== "T" && params.row.aslStCd !== "Obsolete") {
				if (params.field === "rohs66" || params.field === "appPBFF" || params.field === "t260deg") {
					dispatch(
						RoHSAction.handleEditTable({
							mpn: params.row.mpn,
							mfgId: params.row.mfgId,
							field: params.field,
							value: params.value,
						})
					);
				}
			}
		}
	};

	const handleOnRowClick = (params) => {
		dispatch(
			RoHSAction.retrieveMpn({
				mpn: params.row.mpn,
				mfgId: params.row.mfgId.toString(),
				mfgName: params.row.mfgName,
				selectedMpnDisabled: params.row.editDisabled,
			})
		);
	};

	const handleEditMode = () => {
		props.unblockRef.current();
		if (editing) {
			if (editedTable.length > 0) {
				const requestJSON = {
					item: itemPartNumber,
					userid: userID,
					updated_data: editedTable,
				};
				props.updateTable(requestJSON);
			} else {
				dispatch(RoHSAction.setEditingTable(false));
			}
		} else {
			dispatch(RoHSAction.setEditingTable(true));
		}
	};

	const handleCancelEdit = () => {
		props.unblockRef.current();
		dispatch(RoHSAction.clearEditedTable());
		dispatch(RoHSAction.retrievePart(fullData));
		dispatch(RoHSAction.setEditingTable(false));
	};

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `RoHS_${itemPartNumber}_${formatDate(new Date(), "")}`,
				}}
			/>
			{props.nonMtlEngAccess ? (
				""
			) : (
				<>
					<Button disabled={rows.length === 0} startIcon={editing ? <SaveIcon /> : <EditIcon />} sx={{ minWidth: 0 }} onClick={handleEditMode}>
						{buttonText}
					</Button>
					{editing && (
						<Button startIcon={<CloseIcon />} onClick={handleCancelEdit}>
							Cancel
						</Button>
					)}
				</>
			)}
		</>
	);
	const handleClose = () => {
		setContextMenu(null);
	};

	const handleContextMenu = (event) => {
		const selectedFileDetails = rows.find((data) => data.id === Number(event.currentTarget.getAttribute("data-id")));
		if (selectedFileDetails.mfgName === "TEMPORARY" && !editing && !props.nonMtlEngAccess) {
			event.preventDefault();
			if (rows.length > 0) {
				setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
				setMpnDetails({
					mfg_pn: selectedFileDetails.mpn,
					mfgid: selectedFileDetails.mfgId,
					mfg_name: selectedFileDetails.mfgName,
				});
			}
		}
	};

	const deleteTempMpn = () => {
		handleClose();
		setShowDeletePopUp(true);
	};

	const rightClickMenu = (
		<Menu
			open={contextMenu !== null}
			onClose={handleClose}
			anchorReference="anchorPosition"
			disableScrollLock
			anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
			componentsProps={{
				root: {
					onContextMenu: (e) => {
						e.preventDefault();
						handleClose();
					},
				},
			}}
		>
			<MenuItem onClick={deleteTempMpn}>Delete</MenuItem>
		</Menu>
	);

	return (
		<>
			<DeleteMpn
				open={showDeletePopUp}
				setShowDeletePopUp={setShowDeletePopUp}
				selectedMpn={selectedMpn}
				selectedMfgName={selectedMfgName}
				mpnDetails={mpnDetails}
			/>
			<InfoTable
				columnsSettings={columnsSettings}
				rows={rows}
				handleOnRowClick={handleOnRowClick}
				customCellClass={customCellClass}
				ExtraProps={ExtraProps}
				customToolBar={customToolBar}
				checkboxSelection={false}
				editing={editing}
				handleCheckBoxChange={handleCheckBoxChange}
				handleSelectChange={handleSelectChange}
				rowHeight={42}
				rightClickMenu={rightClickMenu}
				handleContextMenu={handleContextMenu}
				autoTableHeight={false}
				pageSize={100}
				haveBorder={true}
			></InfoTable>
		</>
	);
};

export default memo(RoHsOracleTable);
