import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentPage: 1,
	transactionID: "",
	editCompDetails: {},
	projectArray: [],
	bomNo: "",
	rev: "",
	BOMCount: "",
	projectID: "",
	loadedBom: "",
	projectName: "",
	mlarLoadDate: null,
	completionDueDate: null,
	mtlDueDate: null,
	notesRequiredCheckbox: true,
	rohsRequiredCheckbox: true,
	optTempRequiredCheckbox: true,
	projectManager: "",
	projectManagerOptions: [],
	designer: "",
	designerOptions: [],
	npiEngineer: "",
	npiEngineerOptions: [],
	mtlEngineer: null,
	liaison: "",
	liaisonOptions: [],
	pcdfm: "",
	pcdfmOptions: [],
	division: "",

	checkedOptions: "",
	emailTextOptions: "",
	sendEmail: true,
	emailSubject: "",
	emailText: "",

	usersList: [],
	selectedUserEmail: [],
	userName: "",
	selectedOrg: "",
};

const uploadBomSlice = createSlice({
	name: "uploadBom",
	initialState: initialState,
	reducers: {
		reset: () => initialState,
		setCurrentPage(state, action) {
			state.currentPage = action.payload;
		},

		setTransacitonID(state, action) {
			state.transactionID = action.payload;
		},

		setEditCompDetails(state, action) {
			state.editCompDetails = action.payload;
		},

		setUserList(state, action) {
			// Remove all the user with empty email address
			state.usersList = action.payload.USERS_LIST.filter((user) => user.useremail);
		},

		retrieveProjectInfo(state, action) {
			const projectArray = state.editCompDetails.Projects_Info ? state.editCompDetails.Projects_Info.map((projectInfo) => projectInfo.project) : [];
			state.projectArray = projectArray;
			state.loadedBom = `${state.editCompDetails.parentBOM || ""} rev: ${state.editCompDetails.parentBOMRev || ""}`;
			state.bomNo = state.editCompDetails.parentBOM;
			state.rev = state.editCompDetails.parentBOMRev;
			state.BOMCount = state.editCompDetails.BOMCount;

			if (state.editCompDetails.ProjectsBOM_Info && state.editCompDetails.ProjectsBOM_Info.length > 0) {
				const bomInfo = state.editCompDetails.ProjectsBOM_Info[0];
				state.projectName = bomInfo.project || "";
				state.projectManager = bomInfo.pr_mgr || "";
				state.designer = bomInfo.designer || "";
				state.npiEngineer = bomInfo.npieng || "";
				state.liaison = bomInfo.liaison || "";
				state.pcdfm = bomInfo.pcdfm || "";
				state.division = bomInfo.a_group || "";
				state.projectID = bomInfo.pro_id;
			}

			state.userName = action.payload;
		},

		handleProjectChange(state, action) {
			let bomInfo =
				state.editCompDetails?.ProjectsBOM_Info?.find((bom) => bom.project === action.payload) ||
				state.editCompDetails?.Projects_Info?.find((bom) => bom.project === action.payload);
			if (bomInfo) {
				state.projectManager = bomInfo.pr_mgr || "";
				state.designer = bomInfo.designer || "";
				state.npiEngineer = bomInfo.npieng || "";
				state.liaison = bomInfo.liaison || "";
				state.pcdfm = bomInfo.pcdfm || "";
				state.division = bomInfo.a_group || "";
				state.projectID = bomInfo.pro_id || "";
				state.mtlEngineer = bomInfo.mtl_eng || null;
			}
			state.projectName = action.payload;

			const emailTextPara1 = `Please complete ${state.emailTextOptions}\nanalysis for MLAR: ${state.loadedBom}`;
			const emailTextPara2 = `Report Due DATE(delivery to NPI by liaison): ${state.completionDueDate}\nReport Input Due Date(material engineer input): ${state.mtlDueDate}`;
			const emailTextPara3 = `- BOM: ${state.loadedBom}\n- Designer: ${state.designer}\n- Project Name: ${state.projectName}\n- Project Manager: ${state.projectManager}\n- NPI Of Engineer: ${state.npiEngineer}\n- PCDFM: ${state.pcdfm}\n- Liaison: ${state.liaison}\n- Division: ${state.division}`;
			const emailTextPara4 = `Thanks,\n\n${state.userName}`;
			state.emailText = `${emailTextPara1}\n\n${emailTextPara2}\n\n${emailTextPara3}\n\n${emailTextPara4}`;
		},

		handleUserInput(state, action) {
			switch (action.payload.field) {
				case "mlarLoadDate":
					state.mlarLoadDate = action.payload.value;
					break;
				case "completionDueDate":
					state.completionDueDate = action.payload.value;
					break;
				case "mtlDueDate":
					state.mtlDueDate = action.payload.value;
					break;
				case "notesRequiredCheckbox":
					state.notesRequiredCheckbox = !state.notesRequiredCheckbox;
					break;
				case "rohsRequiredCheckbox":
					state.rohsRequiredCheckbox = !state.rohsRequiredCheckbox;
					break;
				case "optTempRequiredCheckbox":
					state.optTempRequiredCheckbox = !state.optTempRequiredCheckbox;
					break;
				case "projectManager":
					state.projectManager = action.payload.value;
					break;
				case "designer":
					state.designer = action.payload.value;
					break;
				case "npiEngineer":
					state.npiEngineer = action.payload.value;
					break;
				case "liaison":
					state.liaison = action.payload.value;
					break;
				case "pcdfm":
					state.pcdfm = action.payload.value;
					break;
				case "division":
					state.division = action.payload.value;
					break;
				case "sendEmail":
					state.sendEmail = !state.sendEmail;
					break;
				default:
					break;
			}

			if (!state.notesRequiredCheckbox && state.rohsRequiredCheckbox && !state.optTempRequiredCheckbox) {
				state.checkedOptions = "RoHS";
				state.emailTextOptions = "RoHS";
			} else if (!state.notesRequiredCheckbox && !state.rohsRequiredCheckbox && state.optTempRequiredCheckbox) {
				state.checkedOptions = "Operating Temp";
				state.emailTextOptions = "MAX Operating Temp";
			} else if (!state.notesRequiredCheckbox && state.rohsRequiredCheckbox && state.optTempRequiredCheckbox) {
				state.checkedOptions = "RoHS/260/OpTemp";
				state.emailTextOptions = "RoHS and MAX Operating Temp";
			} else if (state.notesRequiredCheckbox && !state.rohsRequiredCheckbox && !state.optTempRequiredCheckbox) {
				state.checkedOptions = "MLAR Notes";
				state.emailTextOptions = "MLAR Notes";
			} else if (state.notesRequiredCheckbox && state.rohsRequiredCheckbox && !state.optTempRequiredCheckbox) {
				state.checkedOptions = "MLAR Notes/RoHS/260";
				state.emailTextOptions = "MLAR Notes and RoHS";
			} else if (state.notesRequiredCheckbox && !state.rohsRequiredCheckbox && state.optTempRequiredCheckbox) {
				state.checkedOptions = "MLAR Notes/Op Temp";
				state.emailTextOptions = "MLAR Notes and MAX Operating Temp";
			} else if (state.notesRequiredCheckbox && state.rohsRequiredCheckbox && state.optTempRequiredCheckbox) {
				state.checkedOptions = "MLAR Notes/RoHS/260/Op Temp";
				state.emailTextOptions = "MLAR Notes and RoHS and MAX Operating Temp";
			} else {
				state.checkedOptions = "";
				state.emailTextOptions = "";
			}

			state.emailSubject = `Input Due Date: ${state.mtlDueDate} - ${state.checkedOptions} rewiew request for ${state.loadedBom}`;

			const emailTextPara1 = `Please complete ${state.emailTextOptions}\nanalysis for MLAR: ${state.loadedBom}`;
			const emailTextPara2 = `Report Due DATE(delivery to NPI by liaison): ${state.completionDueDate}\nReport Input Due Date(material engineer input): ${state.mtlDueDate}`;
			const emailTextPara3 = `- BOM: ${state.loadedBom}\n- Designer: ${state.designer}\n- Project Name: ${state.projectName}\n- Project Manager: ${state.projectManager}\n- NPI Of Engineer: ${state.npiEngineer}\n- PCDFM: ${state.pcdfm}\n- Liaison: ${state.liaison}\n- Division: ${state.division}`;
			const emailTextPara4 = `Thanks,\n\n${state.userName}`;
			state.emailText = `${emailTextPara1}\n\n${emailTextPara2}\n\n${emailTextPara3}\n\n${emailTextPara4}`;
		},

		handleSelectedUserEmail(state, action) {
			state.selectedUserEmail = action.payload;
		},

		handleSelectOrg(state, action) {
			state.selectedOrg = action.payload;
		},

		setProjectManagerOptions(state, action) {
			state.projectManagerOptions = action.payload;
		},

		setDesignerOptions(state, action) {
			state.designerOptions = action.payload;
		},

		setNpiEngineerOptions(state, action) {
			state.npiEngineerOptions = action.payload;
		},

		setLiaisonOptions(state, action) {
			state.liaisonOptions = action.payload;
		},

		setpcdfmOptions(state, action) {
			state.pcdfmOptions = action.payload;
		},
	},
});

export const uploadBomAction = uploadBomSlice.actions;

export default uploadBomSlice;
