// React
import { useSelector, useDispatch } from "react-redux";
import { wuBomAction } from "../../../../store/WuBom/WuBom-slice";
// UI
import InfoTable from "../../../UI/Table";
import { cx } from "@emotion/css";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
// package import
import { formatDate, DateSortingComparator } from "../../../Util/Util";

const columnsSettings = [
	{ field: "LN", headerName: "LN", type: "text", minWidth: 50, flex: 0.2 },
	{ field: "CompPN", headerName: "Component PN", type: "text", minWidth: 150, flex: 0.6, cursor: "pointer" },
	{ field: "Rev", headerName: "Rev", type: "text", minWidth: 55, flex: 0.25 },
	{ field: "Qty", headerName: "Qty Per", type: "text", minWidth: 70, flex: 0.3 },
	{ field: "ER", headerName: "ER", type: "text", minWidth: 65, flex: 0.25 },
	{ field: "RoHS", headerName: "ROHS", type: "checkbox", minWidth: 55, flex: 0.2 },
	{ field: "MCC", headerName: "MCC", type: "checkbox", minWidth: 55, flex: 0.2 },
	{ field: "Description", headerName: "Description", type: "text", minWidth: 250, flex: 1.2 },
	{ field: "Status", headerName: "Status", type: "text", minWidth: 100, flex: 0.5 },
	{ field: "Type", headerName: "Type", type: "text", minWidth: 100, flex: 0.5 },
	{ field: "PC", headerName: "PC", type: "number", minWidth: 55, flex: 0.2 },
	{ field: "std_cost", headerName: "Std cost", type: "number", minWidth: 100, flex: 0.5 },
	{ field: "Eff_date", headerName: "Eff date", type: "text", minWidth: 160, flex: 0.5, sortComparator: DateSortingComparator },
	{ field: "LastECO", headerName: "Last ECO", type: "text", minWidth: 110, flex: 0.5 },
	{ field: "disDate", headerName: "Dis date", type: "text", minWidth: 100, flex: 0.5, sortComparator: DateSortingComparator },
];

const customCellClass = (params) => {
	switch (params.field) {
		case "CompPN":
			return cx("super-app", {
				blue: true,
				pointerCursor: true,
			});
		case "RoHS":
			return cx("super-app", {
				red: params.value === "F",
			});
		case "MCC":
			return cx("super-app", {
				yellow: params.value === "T",
				red: params.value === "F",
			});
		case "Description":
			return cx("super-app", {
				yellow: true,
			});
		case "Status":
			return cx("super-app", {
				green:
					params.value?.toUpperCase() !== "OBSOLETE" ||
					params.value?.toUpperCase() !== "LTB" ||
					params.value?.toUpperCase() !== "DISCO" ||
					params.value?.toUpperCase() !== "DISCO-PROD",
				orange: params.value?.toUpperCase() === "DISCO-PROD",
				red: params.value?.toUpperCase() === "OBSOLETE" || params.value?.toUpperCase() === "LTB" || params.value?.toUpperCase() === "DISCO",
			});
		default:
			return params;
	}
};

const ComponentTable = (props) => {
	const dispatch = useDispatch();
	const rows = useSelector((state) => state.wuBomState.componentTableRows);
	const partNum = useSelector((state) => state.wuBomState.partNumber);
	const org = useSelector((state) => state.wuBomState.org);
	const columnVisibility = useSelector((state) => state.wuBomState.tableComponentColumnVisibility);

	const handleOnCellClick = (params) => {
		params.field === "CompPN" && params.value && props.changePart(params.value);
	};

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `WuBom_Components_${org}_KPN${partNum}_${formatDate(new Date(), "")}`,
				}}
			/>
		</>
	);
	return (
		<InfoTable
			columnsSettings={columnsSettings}
			rows={rows}
			handleOnCellClick={handleOnCellClick}
			customCellClass={customCellClass}
			customToolBar={customToolBar}
			ExtraProps={{
				columnVisibilityModel: columnVisibility,
				onColumnVisibilityModelChange: (newModel) => {
					dispatch(wuBomAction.setComponentColumnVisibility({ columnVisibility: newModel }));
				},
			}}
		></InfoTable>
	);
};

export default ComponentTable;
