import AWS from "aws-sdk";
import { API_GENERAL_ERROR_MSG, API_TOKEN_ERROR_MSG } from "../../Util/Util";

export const S3Settings = async (requestConfig, retries = 2) => {
	try {
		const response = await fetch(requestConfig.url, {
			method: requestConfig.method ? requestConfig.method : "GET",
			headers: requestConfig.headers ? requestConfig.headers : {},
		});

		if (!response.ok) {
			if (response.status === 401) {
				const errorInfo = await response.json();
				if (errorInfo.code && errorInfo.code === "token_not_valid") {
					throw new Error(API_TOKEN_ERROR_MSG);
				}
			}

			if (retries > 0) {
				console.log(`api call ${retries} retries left`);
				return S3Settings(requestConfig, retries - 1);
			} else {
				throw new Error(API_GENERAL_ERROR_MSG);
			}
		}

		const data = await response.json();

		return new AWS.S3({ accessKeyId: data.access_key, secretAccessKey: data.secret_key, sessionToken: data.security_token, region: "us-west-2" });
	} catch (err) {
		return { errorMsg: err.message || "Something went wrong!", error: true };
	}
};
