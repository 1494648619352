import { CircularProgress, Backdrop } from "@mui/material";

const FullScreenLoadingIndicator = (props) => {
	return (
		<Backdrop sx={{ zIndex: 20000 }} open={props.loading}>
			<CircularProgress color="secondary" size={props.size ? props.size : "120px"} />
		</Backdrop>
	);
};

export default FullScreenLoadingIndicator;
