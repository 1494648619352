// React
import { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";

import InfoTable from "../../../UI/Table";
import { Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DateSortingComparator } from "../../../Util/Util";

const columnsSettings = [
	{ field: "orgCode", headerName: "Org", type: "text", minWidth: 50, maxWidth: 60, flex: 0.15 },
	{ field: "note", headerName: "Note", type: "text", minWidth: 250, flex: 1 },
	{ field: "updatedBy", headerName: "Modified By", type: "text", minWidth: 110, flex: 0.2},
	{ field: "updatedDate", headerName: "Last Update", type: "text", minWidth: 170, flex: 0.3, sortComparator: DateSortingComparator },
	{ field: "originator", headerName: "Originator", type: "text", minWidth: 110, flex: 0.2 },
	{ field: "dateAdded", headerName: "Date Added", type: "text", minWidth: 170, flex: 0.3, sortComparator: DateSortingComparator },
];

const RoHSPartNoteTable = (props) => {
	const dispatch = useDispatch();

	const handleEditMode = () => {
		dispatch(RoHSAction.setEditingRoHSNote(true));
	};

	const nonMtlEngAccess = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);
	const customToolBar = () => (
		<>
			<Typography fontSize={14} mr={2}>
				{props.title}
			</Typography>
			<Button disabled={nonMtlEngAccess} startIcon={<EditIcon />} onClick={handleEditMode}>
				{"Edit"}
			</Button>
		</>
	);

	return (
		<InfoTable
			columnsSettings={columnsSettings}
			rows={props.rows}
			headerHeight={45}
			rowHeight={45}
			checkboxSelection={false}
			customToolBar={customToolBar}
		></InfoTable>
	);
};

export default memo(RoHSPartNoteTable);
