import { createSlice, current } from "@reduxjs/toolkit";

const InitialState = {
	// Select and Load Subset
	currentTab: "",
	subsetInfo: "",
	subsetNameList: [],
	selectedSubset: { partnumber: [], subsetid: "" },

	// Export
	left: [
		"bdm",
		"buyer",
		"buyer_id",
		"cat_code",
		"cur_cost",
		"curmcost",
		"e_impldate",
		"ec_indate",
		"econo",
		"er",
		"ibo",
		"icat_id",
		"ipo_ip_owner",
		"itemname",
		"msmcode",
		"oh_n_net",
		"oh_net",
		"oh_qty",
		"plan_id",
		"planner",
		"prod_line",
		"rcr",
		"rev",
		"rev_edate",
		"serv_part",
		"stat_code",
		"std_cost",
		"stdmcost",
		"type_desc",
		"um",
	],
	right: ["item_desc", "pref_code", "orgcode"],

	// Create Subset
	inputPartNumbers: [],
	subsetLoaded: false,

	// Set Order
	reloadNavigation: 0,

	// Reload
	selectedIndex: 0,

	// Find Pop Up
	isFilter: false,
};

const SubsetSlice = createSlice({
	name: "findPart",
	initialState: InitialState,
	reducers: {
		reset: () => InitialState,
		// Load/Export first screen
		setCurrentTab(state, action) {
			state.currentTab = action.payload;
		},
		setSubsetInfo(state, action) {
			state.subsetInfo = action.payload.data.Subset_Info;
			state.subsetNameList = state.subsetInfo.map((subset) => subset.subsetid);
			const useIndex = action.payload.useIndex;
			state.selectedSubset = useIndex ? state.subsetInfo[state.selectedIndex] : state.subsetInfo[0];
		},
		resetSubsetNameList(state) {
			state.subsetNameList = [];
		},
		setSelectedSubset(state, action) {
			const selectedSubset = current(state.subsetInfo).find((subset) => subset.subsetid === action.payload);
			console.log(selectedSubset);
			state.selectedSubset = selectedSubset;
		},

		setSubsetLoaded(state, action) {
			state.subsetLoaded = action.payload;
		},

		// Export Screen
		setLeft(state, action) {
			state.left = action.payload;
		},

		setRight(state, action) {
			state.right = action.payload;
		},

		resetExcel(state) {
			state.left = InitialState.left;
			state.right = InitialState.right;
		},

		setInputPartNumbers(state, action) {
			state.inputPartNumbers = action.payload;
		},

		setReloadNavigation(state, action) {
			state.reloadNavigation = state.reloadNavigation + 1;
		},

		setSelectedIndex(state, action) {
			state.selectedIndex = action.payload;
		},

		setIsFilter(state, action) {
			state.isFilter = action.payload;
		},
	},
});

export const SubsetAction = SubsetSlice.actions;

export default SubsetSlice;
