// React
import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubsetAction } from "../../../store/Subset/Subset-slice";
import { findPartAction } from "../../../store/common/findPart-slice";
import { useLocation } from "react-router-dom";

// UI
import {
	Box,
	Button,
	Modal,
	Stack,
	List,
	ListItemButton,
	ListItemText,
	listItemButtonClasses,
	AppBar,
	Toolbar,
	Typography,
	Tooltip,
} from "@mui/material";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ConfirmationBox from "../../Util/ConfirmationBox";
import ErrorBox from "../../Util/ErrorBox";
import MessageBox from "../../Util/MessageBox";

// Project Packages
import { APIURL, storePartHistories } from "../../Util/Util";
import useHttp from "../../../Hooks/use-http";
import { subsetReloadPage } from "./CreateSubset";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	maxHeight: "90%",
	overflowY: "auto",
	p: 4,
};

const LoadSubset = (props) => {
	const dispatch = useDispatch();
	const { pathname: urlPathName } = useLocation();
	const { sendRequest: fetchData, isLoading } = useHttp();
	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const selectedSubsetOrg = useSelector((state) => state.findPartState.selectedSubsetOrg);
	const subsetNameList = useSelector((state) => state.SubsetState.subsetNameList);
	const selectedSubset = useSelector((state) => state.SubsetState.selectedSubset);
	const selectedIndex = useSelector((state) => state.SubsetState.selectedIndex);
	const isFilter = useSelector((state) => state.SubsetState.isFilter);

	const [openSortMessageBox, setOpenSortMessageBox] = useState(false);
	const viewOrderMessage = `Do you want to set SUBSET order to [ITEM](Unique)\nif not, the order will be set to an original LOAD order = no sort...\n\nThere can be multiple entries of the same item number`;
	const [openDeleteMessageBox, setOpenDeleteMessageBox] = useState(false);
	const deleteSubset = `Delete subset ${selectedSubset.subsetid} ?`;
	const [openMessageBox, setOpenMessageBox] = useState(false);
	const [message, setMessage] = useState("");
	const [reload, setReload] = useState(0);
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const { open, onClose } = props;

	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);
	// const headersNoToken = useMemo(() => ({ "Content-Type": "application/json" }), []);

	useEffect(() => {
		dispatch(SubsetAction.resetSubsetNameList());
		console.log(`isFilter: ${isFilter}`);
		if (open) {
			fetchData(
				{ url: `${APIURL}subset/subsetList/${userNTID}`, headers },
				(data) => {
					console.log(data);
					if (data.Subset_Info.length > 0) {
						dispatch(SubsetAction.setSubsetInfo({ data, useIndex: true }));
					}
				},
				(error) => {
					console.log(`ERROR: Unable to Retrieve subset Information, ${error}`);
					setErrorMsg(`ERROR: Unable to Retrieve subset Information, please try refresh the page ${error}`);
					setErrorState(true);
				}
			);
		}
	}, [dispatch, fetchData, open, userNTID, reload, headers]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleListItemClick = (index) => {
		dispatch(SubsetAction.setSelectedIndex(index));
		dispatch(SubsetAction.setSelectedSubset(subsetNameList[index]));
	};

	const handleLoadClicked = () => {
		if (isFilter) {
			window.sessionStorage.setItem("subsetLoaded", JSON.stringify("SUBSET"));
			const body = { userid: userNTID, subsetid: selectedSubset.subsetid, userorg: selectedSubsetOrg };
			fetchData(
				{ url: `${APIURL}admin/subsetFilterRecords`, headers: headers, method: "POST", body },
				(data) => {
					// dispatch(findPartAction.setSelectedBom(newValue));
					console.log(data);
					dispatch(findPartAction.setFilterRecords({ data, ppn: selectedSubset.subsetid }));
					dispatch(findPartAction.resetFilter());
					onClose();
					// Reload the part number for current page
					subsetReloadPage(urlPathName, dispatch);
				},
				(error) => {
					console.log(error);
					setErrorMsg(`ERROR: Unable to Retrieve MLAR ${selectedSubset.subsetid}, please try again. ${error}`);
				}
			);
		} else {
			setOpenSortMessageBox(true);
		}
	};

	const handleLoad = () => {
		setOpenSortMessageBox(false);
		onClose();
		console.log(selectedSubset);
		dispatch(SubsetAction.setSubsetLoaded(true));
		// Reload the part number for current page
		subsetReloadPage(urlPathName, dispatch);
	};

	const handleSortYes = () => {
		const partNumbers = [...selectedSubset.partnumber].sort();
		console.log(partNumbers);
		storePartHistories(partNumbers[0]);
		window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(partNumbers));
		window.sessionStorage.setItem("subsetUseItemOrder", true);
		window.sessionStorage.setItem("subsetLoaded", true);
		window.sessionStorage.setItem("subsetName", JSON.stringify(`${selectedSubset.subsetid}`));
		handleLoad();
	};

	const handleSortNo = () => {
		storePartHistories(selectedSubset.partnumber[0]);
		window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(selectedSubset.partnumber));
		window.sessionStorage.setItem("subsetUseItemOrder", false);
		window.sessionStorage.setItem("subsetLoaded", true);
		window.sessionStorage.setItem("subsetName", JSON.stringify(`${selectedSubset.subsetid}`));
		handleLoad();
	};

	const handleExportClicked = () => {
		dispatch(SubsetAction.setCurrentTab("Export"));
	};

	const handleDeleteClicked = () => {
		setOpenDeleteMessageBox(true);
	};

	const handleDeleteYes = () => {
		const body = { userid: userNTID, subsetid: selectedSubset.subsetid };
		console.log(body);
		fetchData(
			{ url: `${APIURL}subset/deleteSubset`, headers, method: "POST", body: body },
			(data) => {
				console.log(data);
				setMessage(data.Result);
				setOpenMessageBox(true);
				setOpenDeleteMessageBox(false);
				window.sessionStorage.setItem("subsetLoaded", false);
				dispatch(SubsetAction.setSelectedIndex(0));
				setReload(reload + 1);
			},
			(error) => {
				console.log(`ERROR: Unable to Retrieve subset Information, ${error}`);
				setErrorMsg(`ERROR: Unable to Retrieve subset Information, please try refresh the page ${error}`);
				setErrorState(true);
			}
		);
	};

	const handleDeleteNo = () => {
		setOpenDeleteMessageBox(false);
	};

	const closeMessageBox = () => {
		setMessage("");
		setOpenMessageBox(false);
		setReload(reload + 1);
	};

	const handleOnKeyDown = (e) => {
		if (e.code === "KeyL" && subsetNameList.length !== 0) {
			handleLoadClicked();
		} else if (e.code === "KeyE" && subsetNameList.length !== 0) {
			handleExportClicked();
		} else if (e.code === "KeyD" && subsetNameList.length !== 0) {
			handleDeleteClicked();
		} else if (e.code === "KeyC") {
			onClose();
		}
	};

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ConfirmationBox open={openSortMessageBox} message={viewOrderMessage} handleYes={handleSortYes} handleNo={handleSortNo} />
			<ConfirmationBox open={openDeleteMessageBox} message={deleteSubset} handleYes={handleDeleteYes} handleNo={handleDeleteNo} />
			<MessageBox open={openMessageBox} message={message} messageBoxHandler={closeMessageBox} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						handleOnKeyDown(e);
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Load Subset or Export Subset"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Stack mb={2} mt={7} spacing={2}>
						{subsetNameList.length !== 0 || isLoading ? (
							<List component="nav" aria-label="main mailbox folders" sx={{ border: 2 }}>
								{subsetNameList.map((subset, index) => (
									<ListItemButton
										sx={{ [`&.${listItemButtonClasses.selected}`]: { backgroundColor: "#D9D9D6" } }}
										key={index}
										selected={selectedIndex === index}
										onClick={() => handleListItemClick(index)}
									>
										<ListItemText primary={subset} />
									</ListItemButton>
								))}
							</List>
						) : (
							<Typography>No Subset Available</Typography>
						)}
					</Stack>

					<Tooltip title={`For shortcut press L`}>
						<span>
							<Button disabled={subsetNameList.length === 0 ? true : false} onClick={handleLoadClicked}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>L</u>oad
							</Button>
						</span>
					</Tooltip>
					<Tooltip title={`For shortcut press E`}>
						<span>
							<Button disabled={subsetNameList.length === 0 ? true : false} onClick={handleExportClicked}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>E</u>xport
							</Button>
						</span>
					</Tooltip>
					<Tooltip title={`For shortcut press D`}>
						<span>
							<Button disabled={subsetNameList.length === 0 ? true : false} onClick={handleDeleteClicked}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>D</u>elete
							</Button>
						</span>
					</Tooltip>
					<Tooltip title={`For shortcut press C`}>
						<span>
							<Button onClick={onClose}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
						</span>
					</Tooltip>
				</Box>
			</Modal>
		</>
	);
};

export default LoadSubset;
