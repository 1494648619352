import { Box, FormControl, InputLabel, Select, Tooltip, MenuItem, Grid, useScrollTrigger } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import { useSelector, useDispatch } from "react-redux";
import { infoAction } from "../../../../store/Info/info-slice";
import { orgList } from "../../../Util/Util";

const InfoHeader = (props) => {
	const dispatch = useDispatch();
	const itemName = useSelector((state) => state.infoStates.info.itemname) || "";
	const itemDesc = useSelector((state) => state.infoStates.info.item_desc) || "";
	const org = useSelector((state) => state.infoStates.org) || "";
	const availableOrgList = useSelector((state) => state.infoStates.OrgList) || [];

	const allOrgList = [...new Set([...availableOrgList, ...orgList ])];

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 10,
		target: props.window ? window() : undefined,
	});

	const handleOrgChange = (event) => {
		dispatch(infoAction.changeOrg(event.target.value));
	};

	return (
		<Box
			position={"fixed"}
			zIndex={1000}
			width={"100%"}
			bgcolor={trigger ? "#EBEBEB" : "#fff"}
			sx={{ top: { xs: "62px", sm: "62px", lg: "68px" }, height: { xs: "175px", sm: "175px", md: "120px", lg: "75px" } }}
		>
			<Box sx={{ mx: "auto" }} width={{ xs: "95%", sm: "85%" }} marginLeft={2} marginRight={4} marginTop={2}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" rowSpacing={2} columnSpacing={0.8} wrap={"wrap"}>
					<Grid item xl={2.2} lg={2.4} md={4} sm={9} xs={8}>
						<Tooltip title={itemName}>
							<ColoredTextField
								fullWidth
								label="Keysight Part&nbsp;&nbsp;"
								focused
								value={itemName}
								InputLabelProps={{ style: { fontWeight: "bold" } }}
								inputProps={{ readOnly: true, style: { fontWeight: "bold" } }}
							/>
						</Tooltip>
					</Grid>

					<Grid item xl={0.8} lg={1.2} md={2} sm={3} xs={4}>
						<FormControl fullWidth focused>
							<InputLabel size="small" id="org-select-label">
								Org
							</InputLabel>
							<Select
								size="small"
								labelId="org-select-label"
								id="org-select"
								value={org}
								label="Org"
								onChange={handleOrgChange}
								MenuProps={{
									disableScrollLock: true,
									sx: { maxHeight: "60%" },
								}}
							>
								{allOrgList.map((org) => (
									<MenuItem sx={{ color: availableOrgList && availableOrgList.includes(org) && "green" }} key={org} value={org}>
										{org}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xl={3} lg={2.8} md={6} sm={12} xs={12}>
						<Tooltip title={itemDesc}>
							<ColoredTextField fullWidth label="Keysight Part Description" focused value={itemDesc} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>

					<Grid item xl={3} lg={2.8} md={6} sm={6} xs={6}>
						<Tooltip title={""}>
							<ColoredTextField fullWidth label="Last Oracle data update" focused value={""} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>

					<Grid item xl={3} lg={2.8} md={6} sm={6} xs={6}>
						<Tooltip title={""}>
							<ColoredTextField fullWidth label="Last Enovia data update" focused value={""} inputProps={{ readOnly: true }} />
						</Tooltip>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default InfoHeader;
