// React
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { wuBomAction } from "../../../../store/WuBom/WuBom-slice";
// UI
import { Box, Modal, Typography, Grid, FormControlLabel, Checkbox, Tooltip, Button, Paper } from "@mui/material";
import SetFileName from "../../../Util/SetFileName";
import DropdownFilter from "../../../UI/DropdownFilter";
import ErrorBox from "../../../Util/ErrorBox";
// Packages Import
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import { OSAltKey, APIURL, PDF_GEN_JWT_TOKEN_URL, PDF_GEN_URL, convertTrueFalse } from "../../../Util/Util";
import ColoredTextField from "../../../UI/ColoredTextField";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: 650 },
	maxHeight: "90%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "auto",
	pt: 2,
	pb: 1,
	pl: { xs: 1, sm: 4 },
	pr: { xs: 1, sm: 4 },
};

const PrintWhereUsePTO = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [orgList, setOrgList] = useState([]);
	const [userOrg, setUserOrg] = useState("F01");

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const showPopup = useSelector((state) => state.wuBomState.openPrintPTO);
	const printType = useSelector((state) => state.wuBomState.printType);
	const partNumber = useSelector((state) => state.wuBomState.partNumber);
	const org = useSelector((state) => state.wuBomState.org);

	const allOrg = useSelector((state) => state.wuBomState.allOrg);
	const productionOnly = useSelector((state) => state.wuBomState.productionOnly);
	const includeObsolete = useSelector((state) => state.wuBomState.includeObsolete);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const closeHandler = () => {
		dispatch(wuBomAction.handleOpenPrintPTO());
		dispatch(wuBomAction.resetPrint());
	};

	const handleCheckboxOptions = (option) => {
		switch (option) {
			case "exportToExcel":
				dispatch(wuBomAction.handlePrintTypeUpdate("excel"));
				break;
			case "printToPDF":
				dispatch(wuBomAction.handlePrintTypeUpdate("pdf"));
				break;
			case "allOrg":
				dispatch(wuBomAction.handleAllOrgChecked());
				break;
			case "productionOnly":
				dispatch(wuBomAction.handleProdOnlyChecked());
				break;
			case "includeObsolete":
				dispatch(wuBomAction.handleIncludeObsoleteChecked());
				break;
			default:
				break;
		}
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				setErrorMsg(error.toString());
				setErrorState(true);
				setLoading(false);
			});
	};

	const generateReport = () => {
		let filename = "";
		closeHandler();
		setLoading(true);
		const body = {
			userid: userNTID,
			userOrg: userOrg,
			itemname: partNumber,
			orgcode: allOrg ? "ALL" : org,
			checkInclOBS: convertTrueFalse(includeObsolete),
			checkShowProductionOnly: convertTrueFalse(productionOnly),
			excelPdfReport: printType,
		};

		console.log(body);

		if (body.excelPdfReport === "excel") {
			fetch(`${APIURL}Wu_Bom/wuptorecord`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: headers,
			})
				.then((response) => {
					const disposition = response.headers.get("content-disposition");
					filename = disposition.match(/filename=(.+)/)[1];
					return response.blob();
				})
				.then((data) => {
					setBlob(data);
					setFileName(filename);
					setOpenSetFileName(true);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					setErrorMsg(error.toString());
					setErrorState(true);
				});
		} else {
			fetchDataWithoutLoad(
				{
					url: `${APIURL}Wu_Bom/wuptorecord`,
					headers: headers,
					method: "POST",
					body: body,
				},
				(data) => {
					if (data?.No_Record) {
						throw Error(data.No_Record);
					}
					fetchDataWithoutLoad(
						{
							url: PDF_GEN_JWT_TOKEN_URL,
							headers: headers,
							method: "POST",
							body: {
								username: "admin",
								password: "pass",
							},
						},
						(token) => handleJWTToken(token, data),
						(error) => {
							setLoading(false);
							setErrorMsg(error.toString());
							setErrorState(true);
						}
					);
				},
				(error) => {
					setLoading(false);
					setErrorMsg(error.toString());
					setErrorState(true);
				}
			);
		}
	};

	useEffect(() => {
		if (showPopup) {
			fetchDataWithoutLoad(
				{
					url: `${APIURL}MtlEngInfo/userOrgsList`,
					headers: headers,
				},
				(data) => {
					const orgList = data.USER_ORGS_LIST.map((org) => {
						return org.user_org_c;
					});
					setOrgList(orgList);
				}
			);
		}
	}, [fetchDataWithoutLoad, headers, showPopup]);

	return (
		<>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName blob={blob} setOpenSetFileName={setOpenSetFileName} openSetFileName={openSetFileName} fileName={fileName} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState}></ErrorBox>

			<Modal open={showPopup} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC" && e.altKey === true) {
							closeHandler();
						}
						if (e.code === "KeyE" && e.altKey === true) {
							generateReport();
						}
					}}
				>
					<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
						<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
							Print Where Used PTO level
						</Typography>
						<DropdownFilter
							width={90}
							selections={orgList}
							selectedData={userOrg}
							handleSelection={(value) => {
								setUserOrg(value);
							}}
							label={"User Org"}
							InputLabelProps={{
								shrink: true,
								sx: {
									fontWeight: 700,
								},
							}}
						/>
					</Box>
					<Paper sx={{ p: 2, mt: 2, mb: 2 }} elevation={5}>
						<Typography variant="subtitle">PTO=Pick To Order</Typography>
					</Paper>

					<Box mb={2} sx={{ alignItems: "center", display: "flex", width: "97%", flexDirection: "column" }}>
						<Grid container direction="row" spacing={1}>
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<FormControlLabel
									value="exportToExcel"
									control={<Checkbox checked={printType === "excel"} onChange={() => handleCheckboxOptions("exportToExcel")} />}
									label="Export to Excel file"
								/>
							</Grid>
							<Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
								<FormControlLabel
									sx={{ ml: 0 }}
									value="printToPDF"
									control={<Checkbox checked={printType === "pdf"} onChange={() => handleCheckboxOptions("printToPDF")} />}
									label="Print to PDF file"
								/>
							</Grid>
							<Grid item xl={2} lg={2} md={2} sm={2} xs={3}>
								<ColoredTextField focused fullWidth label="Org" inputProps={{ readOnly: true }} value={allOrg ? "All" : org} />
							</Grid>
							<Grid item xl={10} lg={10} md={10} sm={10} xs={9}>
								<ColoredTextField focused fullWidth label="Part Number" inputProps={{ readOnly: true }} value={partNumber} />
							</Grid>

							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="allOrg"
									control={<Checkbox checked={allOrg} onChange={() => handleCheckboxOptions("allOrg")} />}
									label="All Organizations"
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="productionOnly"
									control={<Checkbox checked={productionOnly} onChange={() => handleCheckboxOptions("productionOnly")} />}
									label="Show PRODUCTION parts only"
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="includeObsolete"
									control={<Checkbox checked={includeObsolete} disabled={productionOnly} onChange={() => handleCheckboxOptions("includeObsolete")} />}
									label="Include Obsolete Assemblies"
								/>
							</Grid>
						</Grid>

						<Grid
							container
							direction="row"
							spacing={1}
							sx={{
								p: 0,
								mt: 1,
							}}
						>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Tooltip title={`For shortcut ${OSAltKey} + C`}>
									<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</Tooltip>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Tooltip title={`For shortcut ${OSAltKey} + E`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={generateReport}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>E</u>xport {printType === "pdf" ? "PDF" : "Excel"}
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default PrintWhereUsePTO;
