// React
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { SubsetAction } from "../../store/Subset/Subset-slice";
import { findPartAction } from "../../store/common/findPart-slice";
// UI
import { Box, FormControlLabel, Stack, Checkbox, Select, Collapse, Typography, MenuItem, FormControl } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ColoredTextField from "../UI/ColoredTextField";
import ControlledMessageBox from "../Util/ControlledMessagebox";
// Packages import
import { storePartHistories } from "../Util/Util";
import { subsetReloadPage } from "../Menu/Subset/CreateSubset";

let typingTimeout;
let clickTimeout;

const BOMFilterMenu = () => {
	const dispatch = useDispatch();
	const { pathname: urlPathName } = useLocation();
	const subsetName = JSON.parse(window.sessionStorage.getItem("subsetName")) || "";

	const [openMenu, setOpenMenu] = useState(false);
	const [openMessageBox, setOpenMessageBox] = useState(false);
	const [message, setMessage] = useState("");

	const userName = useSelector((state) => state.authenticationState.userName);

	const partNumberInput = useSelector((state) => state.findPartState.partNumberInput);
	const myPartsChecked = useSelector((state) => state.findPartState.myPartsChecked);
	const code4Checked = useSelector((state) => state.findPartState.code4Checked);
	const noteRequiredUpdate = useSelector((state) => state.findPartState.noteRequiredUpdate);
	const RoHSRequiredUpdate = useSelector((state) => state.findPartState.RoHSRequiredUpdate);
	const RoHSNotesLogicSelection = useSelector((state) => state.findPartState.RoHSNotesLogicSelection);
	const OpTempChecked = useSelector((state) => state.findPartState.OpTempChecked);

	const handleReload = ({ input, myParts, code4, noteReq, RoHSReq, RoHSNoteSelection, OpTemp }) => {
		let subsetPartNumbers = window.sessionStorage.getItem("subsetPartNumbers");
		subsetPartNumbers = subsetPartNumbers ? JSON.parse(subsetPartNumbers) : [];
		const PartNumber = window.sessionStorage.getItem("PartNumber");

		if (subsetPartNumbers && subsetPartNumbers.length > 0 && subsetPartNumbers[0] !== PartNumber) {
			storePartHistories(subsetPartNumbers[0]);
			subsetReloadPage(urlPathName, dispatch);
		} else if (subsetPartNumbers && subsetPartNumbers.length === 0) {
			console.log("not Found");
			dispatch(findPartAction.resetFilter());

			setOpenMessageBox(true);
			if (input) {
				setMessage(`No record for prefix = ${input}\nSwitching view to all records all levels for current BOM`);
			} else if (myParts) {
				setMessage(`No record with my name...\nSwitching view to all records all levels for current BOM`);
			} else if (code4) {
				setMessage(`No record with pref code = 4...\nSwitching view to all records all levels for current BOM`);
			} else if (noteReq) {
				setMessage(`No records for parts that need Notes update.\nSwitching view to all records all levels for current BOM`);
			} else if (RoHSReq) {
				setMessage(`No records for parts that need RoHS TAB update.\nSwitching view to all records all levels for current BOM`);
			} else if (RoHSNoteSelection) {
				setMessage(`No records for parts that need RoHS and/or Notes update.\nSwitching view to all records all levels for current BOM`);
			} else if (OpTemp) {
				setMessage(`No records for parts require op temp review.\nTurning off filter...`);
			}

			setTimeout(() => {
				setMessage("");
				setOpenMessageBox(false);
				let subsetPartNumbers = window.sessionStorage.getItem("subsetPartNumbers");
				subsetPartNumbers = subsetPartNumbers ? JSON.parse(subsetPartNumbers) : [];
				if (subsetPartNumbers && subsetPartNumbers.length > 0 && subsetPartNumbers[0] !== PartNumber) {
					storePartHistories(subsetPartNumbers[0]);
					subsetReloadPage(urlPathName, dispatch);
				}
			}, 2000);
		}
		dispatch(SubsetAction.setReloadNavigation());
		setOpenMenu(true);
		setOpenMenu(false);
	};

	const handlePartNumberChange = (event) => {
		clearTimeout(typingTimeout);
		const input = event.target.value.trim();
		dispatch(findPartAction.setPartNumberInput(input));

		typingTimeout = setTimeout(() => {
			dispatch(findPartAction.handleFilterPartNumber());
			handleReload({ input });
		}, 1000);
	};

	const handleMyPartsChanged = () => {
		clearTimeout(clickTimeout);
		dispatch(findPartAction.setMyPartChecked());

		clickTimeout = setTimeout(() => {
			handleReload({ myParts: true });
		}, 1000);
	};

	const handleCode4Changed = () => {
		clearTimeout(clickTimeout);
		dispatch(findPartAction.setCode4Checked());

		clickTimeout = setTimeout(() => {
			handleReload({ code4: true });
		}, 1000);
	};

	const handleNoteReqChanged = () => {
		clearTimeout(clickTimeout);
		dispatch(findPartAction.setNoteRequiredUpdate());

		clickTimeout = setTimeout(() => {
			handleReload({ noteReq: true });
		}, 1000);
	};

	const handleRoHSReqChanged = () => {
		clearTimeout(clickTimeout);
		dispatch(findPartAction.setRoHSRequireAction());

		clickTimeout = setTimeout(() => {
			handleReload({ RoHSReq: true });
		}, 1000);
	};

	const handleRoHSNoteSelectionChanged = (event) => {
		clearTimeout(clickTimeout);
		console.log(event.target.value);
		dispatch(findPartAction.setRoHSNotesLogic(event.target.value));

		clickTimeout = setTimeout(() => {
			handleReload({ RoHSNoteSelection: true });
		}, 1000);
	};

	const handleOpTempChecked = () => {
		clearTimeout(clickTimeout);
		dispatch(findPartAction.setOpTempChecked());

		clickTimeout = setTimeout(() => {
			handleReload({ OpTemp: true });
		}, 1000);
	};

	return (
		<>
			<ControlledMessageBox open={openMessageBox} message={message} />
			<Box
				sx={{
					position: "fixed",
					top: "20%",
					maxHeight: "60%",
					overflowY: "auto",
					right: 2,
					bgcolor: "background.paper",
					zIndex: 1200,
					px: 2,
					boxShadow: 5,
				}}
			>
				<Collapse
					orientation="horizontal"
					in={openMenu}
					collapsedSize={40}
					onMouseLeave={(event) => {
						if (event.relatedTarget || event.toElement) {
							setOpenMenu(false);
						}
					}}
				>
					<Stack width="320px" spacing={1}>
						<Box
							onMouseEnter={() => {
								setOpenMenu(true);
							}}
							sx={{ width: "100%" }}
						>
							<KeyboardDoubleArrowLeftIcon sx={{ fontSize: 40 }} />
						</Box>

						<ColoredTextField focused label={"MLAR No:"} fullWidth value={subsetName} inputProps={{ readOnly: true }} />
						<ColoredTextField
							autoComplete="off"
							focused
							inputProps={{ sx: { textTransform: "uppercase" } }}
							label={"Part number starts with"}
							fullWidth
							value={partNumberInput}
							onChange={handlePartNumberChange}
						/>
						<FormControlLabel
							control={<Checkbox name="myPart" checked={myPartsChecked} onChange={handleMyPartsChanged} />}
							label={`My parts only - (${userName})`}
						/>
						<FormControlLabel
							control={<Checkbox name="showPref4" checked={code4Checked} onChange={handleCode4Changed} />}
							label="show parts with code 4 only"
						/>
						<FormControlLabel
							control={<Checkbox name="PNNoteUpdate" checked={noteRequiredUpdate} onChange={handleNoteReqChanged} />}
							label="PN's that require a Note update"
						/>
						<Stack direction={"row"} alignItems="center" spacing={1}>
							<FormControl sx={{ minWidth: "84px" }} focused>
								<Select
									id="LogicUsed"
									value={RoHSNotesLogicSelection}
									size="small"
									MenuProps={{
										disableScrollLock: true,
									}}
									width="40px"
									sx={{ maxHeight: "30px" }}
									onChange={handleRoHSNoteSelectionChanged}
								>
									<MenuItem value={"And"}>{".And."}</MenuItem>
									<MenuItem value={"Or"}>{".Or."}</MenuItem>
								</Select>
							</FormControl>
							<Typography>Logic used for RoHS/Notes</Typography>
						</Stack>
						<FormControlLabel
							control={<Checkbox name="PNRohsTab" checked={RoHSRequiredUpdate} onChange={handleRoHSReqChanged} />}
							label="PN's that require RoHS tab action"
						/>
						<FormControlLabel
							control={<Checkbox name="maxOpTemp" checked={OpTempChecked} onChange={handleOpTempChecked} />}
							label="show parts for MAX op.temp. review"
						/>
					</Stack>
				</Collapse>
			</Box>
		</>
	);
};

export default BOMFilterMenu;
