import { configureStore } from "@reduxjs/toolkit";

import infoSlice from "./Info/info-slice";
import findPartSlice from "./common/findPart-slice";
import wuBomSlice from "./WuBom/WuBom-slice";
import authenticationSlice from "./common/Authentication-slice";
import noteSlice from "./Note/Note-slice";
import mtlEngSlice from "./MtlEng/MtlEng-slice";
import cmSlice from "./CM/CM-slice";
import rrSlice from "./RR/RR-slice";
import RoHSSlice from "./RoHS/RoHS-slice";
import projectsSlice from "./Projects/Projects-slice";
import uploadBomSlice from "./UploadBom/UploadBom-slice";
import SubsetSlice from "./Subset/Subset-slice";
import prefCodeSlice from "./common/PrefCode-slice";
import iCatSlice from "./common/ICat-slice";
import reportsSlice from "./Reports/Reports-slice";
import AdminSlice from "./Admin/Admin-slice";
import daysBackSlice from "./common/DaysBack-slice";


// NEVER NEVER MAKE MULTIPLE STORE IN REDUX
const store = configureStore({
	reducer: {
		authenticationState: authenticationSlice.reducer,
		infoStates: infoSlice.reducer,
		findPartState: findPartSlice.reducer,
		wuBomState: wuBomSlice.reducer,
		noteStates: noteSlice.reducer,
		mtlEngState: mtlEngSlice.reducer,
		rohsStates: RoHSSlice.reducer,
		projectsStates: projectsSlice.reducer,
		cmState: cmSlice.reducer,
		rrState: rrSlice.reducer,
		uploadBomState: uploadBomSlice.reducer,
		SubsetState: SubsetSlice.reducer,
		prefCodeState: prefCodeSlice.reducer,
		iCatState: iCatSlice.reducer,
		reportsState: reportsSlice.reducer,
		adminState: AdminSlice.reducer,
		daysBackState: daysBackSlice.reducer,
	},
});

export default store;
