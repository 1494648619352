import { useState, useCallback } from "react";
import { API_TOKEN_ERROR_MSG, API_GENERAL_ERROR_MSG } from "../Components/Util/Util";

const useDownloadFile = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const downloadExcelFile = useCallback(async (requestConfig, error) => {
		const retries = typeof requestConfig.retries === "number" ? requestConfig.retries : 2;
		let filename = "excel";
		setIsLoading(true);
		setError(null);

		try {
			const response = await fetch(requestConfig.url, {
				method: "POST",
				headers: requestConfig.headers ? requestConfig.headers : {},
				body:
					requestConfig.body && requestConfig.dataType === "formData"
						? requestConfig.body
						: // else
						requestConfig.body
						? JSON.stringify(requestConfig.body)
						: null,
			});

			if (!response.ok) {
				if (response.status === 401) {
					const errorInfo = await response.json();
					if (errorInfo.code && errorInfo.code === "token_not_valid") {
						throw new Error(API_TOKEN_ERROR_MSG);
					}
				} else if (response.status === 400) {
					const error = await response.json();
					throw new Error(error.Error ? error.Error : API_GENERAL_ERROR_MSG);
				}

				// Default retry up to twice if API call failed
				if (retries > 0) {
					console.log(`api call ${retries} retries left`);
					const newRequestConfig = { ...requestConfig, retries: retries - 1 };
					return downloadExcelFile(newRequestConfig, error);
				} else {
					throw new Error(API_GENERAL_ERROR_MSG);
				}
			}

			const disposition = response.headers.get("content-disposition");
			filename = disposition.match(/filename=(.+)/)[1];

			const url = window.URL.createObjectURL(await response.blob());
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			error && error(err.message || "Something went wrong!");
			setError(err.message || "Something went wrong!");
		}
		setIsLoading(false);
	}, []);

	return {
		isLoading,
		error,
		downloadExcelFile,
	};
};

export default useDownloadFile;
