import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
	icatOptions: [],
	newIcat: "",
	newIcatID: "",
	reqStat: "",
	oracleiCat: "",
	oracleiCatID: "",
	iCatLookup: null,
};

const iCatSlice = createSlice({
	name: "ICat",
	initialState: InitialState,
	reducers: {
		reset() {
			return InitialState;
		},
		setIcatOptions(state, action) {
			state.icatOptions = action.payload;
		},
		setIcatInfo(state, action) {
			const data = action.payload;
			state.newIcat = data.new_icat;
			state.newIcatID = data.new_icatid;
			state.reqStat = data.req_stat;
			state.oracleiCat = data.oracle_icat;
			state.oracleiCatID = data.oracle_icatid;
		},
		setHandleICatLookup(state, action) {
			state.iCatLookup = action.payload;
		},
	},
});

export const iCatAction = iCatSlice.actions;

export default iCatSlice;
