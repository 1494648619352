import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const CardWrapper = (props) => {
	const defaultSxProps = { mx: "auto" };
	return (
		<Card display="flex" sx={{...defaultSxProps, ...props.sx}}>
			<CardContent>{props.children}</CardContent>
		</Card>
	);
};

export default CardWrapper;
