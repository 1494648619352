// React
import { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RoHSAction } from "../../../store/RoHS/RoHS-slice";
import { findPartAction } from "../../../store/common/findPart-slice";

// UI
import CardWrapper from "../../UI/Card";
import { Box } from "@mui/material";
import RoHSHeader from "./Body/RoHSHeader";
import RoHsOracleTable from "./Table/RoHSOracleTable";
import RoHSBody from "./Body/RoHSBody";
import RoHSReferenceDocs from "./Body/RoHSReferenceDocs";
import RoHSBottomNavigation from "./Footer/RoHSBottomBar";
import EditRoHSNote from "./PopUp/EditRoHSNote";
import EditMpnNote from "./PopUp/EditMpnNote";
import ChangeRequestMCC from "./PopUp/ChangeRequestMCC";
import RunReport from "./PopUp/RunReport";

// Project
import useHttp from "../../../Hooks/use-http";
import { APIURL } from "../../Util/Util";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../Util/ErrorBox";
import usePreventPageLoad from "../../Util/usePreventPageLoad";

const RoHS = () => {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);

	const nonMtlEngAccess = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);
	const showFindPart = useSelector((state) => state.findPartState.showFindPart);
	const oracleTableEditing = useSelector((state) => state.rohsStates.oracleTableEditStates.editing);
	const reloadPartNumber = useSelector((state) => state.rohsStates.reloadPartNumber);

	const hiddenFileInputRef = useRef();

	const unblockRef = usePreventPageLoad(oracleTableEditing);

	const [errorMsg, setErrorMsg] = useState("");
	const [RoHSErrorState, setRoHSErrorState] = useState(false);

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const { isLoading, sendRequest: fetchData } = useHttp();

	// Retrieve part info from API
	const changePart = (partNumber, firstLoad) => {
		if (oracleTableEditing && !firstLoad) {
			// Prevent changing part number from navigation buttons or find part button if RoHS table is currently editing
			if (!window.confirm("Are you sure you want to Change part?\n\nChanges that you made may not be saved.")) {
				return;
			}
		}
		fetchData(
			{ url: `${APIURL}Rohs/rohslist/${partNumber}/`, headers: headers },
			(data) => {
				console.log(data);
				if (Object.keys(data.PART_INFO).length > 0) {
					dispatch(RoHSAction.reset());

					dispatch(findPartAction.setPartFound(true));
					dispatch(findPartAction.setShowFindPart(false));
					dispatch(RoHSAction.setEditingTable(false));
					dispatch(RoHSAction.retrievePart(data));
					dispatch(RoHSAction.retrieveMpn({ mpn: "first_load" }));
					unblockRef.current();
				} else {
					if (!showFindPart) {
						setErrorMsg(`Part Number ${partNumber} not available`);
						setRoHSErrorState(true);
						const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded"));
						if (subsetLoaded) {
							console.log("yes");
							dispatch(RoHSAction.reset());
							dispatch(RoHSAction.setPartNumberForSubset(partNumber));
						}
					} else {
						dispatch(findPartAction.setPartFound(false));
					}
				}
			},
			(error) => {
				console.log("ERROR: Unable to retrieve RoHS data", error);
				setErrorMsg(`ERROR: ${error}`);
				setRoHSErrorState(true);
			}
		);
	};

	// Submit updated Table details
	const updateTable = (data) => {
		fetchData(
			{ url: `${APIURL}Rohs/editSoCoMtlEngInput`, method: "POST", headers, body: data },
			(data) => {
				dispatch(RoHSAction.clearEditedTable());
				dispatch(RoHSAction.retrievePart(data));
				dispatch(RoHSAction.retrieveMpn({ mpn: "reload" }));
				dispatch(RoHSAction.setEditingTable(false));
			},
			(error) => {
				console.log("ERROR: Unexpected Error occured when updating Table", error);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when updating Table, Please try saving again`);
				setRoHSErrorState(true);
			}
		);
	};

	const updateRoHSNote = (data) => {
		fetchData(
			{ url: `${APIURL}Rohs/editROHSNote`, method: "POST", headers, body: data },
			(data) => {
				dispatch(RoHSAction.setEditingRoHSNote(false));
				dispatch(RoHSAction.updateRoHSNote(data));
				dispatch(RoHSAction.retrieveMpn({ mpn: "reload" }));
			},
			(error) => {
				console.log("ERROR: Unexpected Error occured when updating Part Number Note", error);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when updating Part Number Note, Please try saving again`);
				setRoHSErrorState(true);
			}
		);
	};

	const updateMpnNote = (updatedNote, mpnInfo, ischangeRequest) => {
		fetchData(
			{ url: `${APIURL}Rohs/editMpnNote`, method: "POST", headers, body: updatedNote },
			(data) => {
				dispatch(RoHSAction.updateMpnNote(data));
				dispatch(RoHSAction.retrieveMpn({ mpn: "reload" }));
				if (ischangeRequest) {
					dispatch(RoHSAction.setShowSuccessMessage(true));
					setTimeout(() => {
						dispatch(RoHSAction.setShowSuccessMessage(false));
						dispatch(RoHSAction.setShowChangeMCC(false));
					}, 1500);
				} else {
					dispatch(RoHSAction.setEditingMpnNote(false));
				}
			},
			(error) => {
				console.log(`ERROR: Unexpected Error occured when updating Note associated with ${mpnInfo}`, error);
				setErrorMsg(`ERROR: ${error}. Unexpected Error occured when updating Note associated with ${mpnInfo}, Please try saving again`);
				setRoHSErrorState(true);
			}
		);
	};

	useEffect(() => {
		dispatch(RoHSAction.setEditingTable(false));
		const partNumber = window.sessionStorage.getItem("PartNumber");
		if (partNumber) {
			changePart(partNumber, true);
		} else {
			dispatch(findPartAction.setShowFindPart(true));
		}
	}, [reloadPartNumber]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setRoHSErrorState} ErrorState={RoHSErrorState} />
			<EditRoHSNote updateRoHSNote={updateRoHSNote} />
			<EditMpnNote updateMpnNote={updateMpnNote} />
			<ChangeRequestMCC updateMpnNote={updateMpnNote} />
			<RunReport />

			<RoHSHeader />
			<Box mt={{ xs: "165px", sm: "165px", md: "110px", lg: "65px" }}>
				<CardWrapper sx={{ maxWidth: { xs: "100%", sm: "90%" } }}>
					<RoHsOracleTable nonMtlEngAccess={nonMtlEngAccess} updateTable={updateTable} unblockRef={unblockRef} />
					<RoHSBody nonMtlEngAccess={nonMtlEngAccess} hiddenFileInputRef={hiddenFileInputRef} />
					<RoHSReferenceDocs />
				</CardWrapper>
			</Box>
			<RoHSBottomNavigation changePart={changePart} nonMtlEngAccess={nonMtlEngAccess} hiddenFileInputRef={hiddenFileInputRef} />
		</Fragment>
	);
};

export default RoHS;
