// Packages Import

// Project Components
import {checkUndefined} from "../../Util/Util"

export const ACTION = {
    UP_CTABLE : "Update_CommoditiesTable",
    UP_PSTABLE : "Update_PartSelectionTable",
    UP_METABLE : "Update_MaterialEngineerTable",
    UP_ITABLE : "Update_ItemTable",
    UP_ALL : "Update_All",
    FILTER : "Filter_When_Clicked",
    RESET : "Reset"
}

export const defaultCommoditiesTable = [{
    id : 0,
    iCat : "",
    lCat : 0,
    iCat_id : 0,
    unspsc : 0,
}]

export const defaultItemTable = [{
	id:0,
	item: "",
	desc: "",
	lName: "",
	modBy: "",
	tMod: "",
}]

export const defaultMtlEngTable = [{
    id:0,
    lName: "",
	FullName: "",
	telnet: "",
	iCat_id: 0,
	orgCode: "",
}]

export const defaultPartSelectionTable = [{
    id:0,
    psgTitle: "",
	tMod: "",
	lName: "",
	name: "",
	link: "",
}]


export const reducer = (state , action) =>{
    
    
    switch(action.type){
        
        case ACTION.UP_ALL:
            // console.log(action.payload.data)
            const invalidDate = "Invalid Date"
            const commoditiesData = () => {
                if(Object.keys(action.payload.data.CATEGORIES_INFO).length > 0){
                    
                    return action.payload.data.CATEGORIES_INFO.map( (commoditiy , i) =>{ 

                        Object.keys(commoditiy).forEach(key => {
                            commoditiy[key] = checkUndefined(commoditiy[key])
                        })
                        const retItem = {
                            id:i,
                            iCat: commoditiy.icat,
                            lCat: commoditiy.lcat,
                            iCat_id: commoditiy.icat_id,
                            unspsc: commoditiy.unspsc,
                        }
                        return retItem
                    })
                }

                return defaultCommoditiesTable
            }

            const itemData = () => {
                if(Object.keys(action.payload.data.CHANGED_PARTS_INFO).length > 0){

                    return action.payload.data.CHANGED_PARTS_INFO.map( (part , i) =>{ 

                        Object.keys(part).forEach(key => {
                            part[key] = checkUndefined(part[key])
                        })
                        const tMod = new Date(part.tmod?.replace("Z", "")).toLocaleDateString('en-US')
                        const retItem = {
                            id:i,
                            item: part.item,
                            desc: part.item_desc,
                            lName: part.lname,
                            modBy: part.modby,
                            tMod: tMod !== invalidDate ? tMod : "",
                        }
                        return retItem
                    })
                }

                return defaultItemTable
            }

            const MtlEngData = () => {
                if(Object.keys(action.payload.data.MTL_RESP_INFO).length > 0){

                    return action.payload.data.MTL_RESP_INFO.map( (MtlEng , i) =>{ 

                        Object.keys(MtlEng).forEach(key => {
                            MtlEng[key] = checkUndefined(MtlEng[key])
                        })
                        const retItem = {
                            id:i,
                            lName: MtlEng.lname,
                            FullName: MtlEng.fullname,
                            telnet: MtlEng.telnet,
                            iCat_id: MtlEng.icat_id,
                            orgCode: MtlEng.org_code,
                        }
                        return retItem
                    })
                }

                return defaultMtlEngTable
            }

            const partSelectionData = () => {
                if(Object.keys(action.payload.data.PSG_INFO).length > 0){

                    return action.payload.data.PSG_INFO.map( (item , i) =>{ 

                        Object.keys(item).forEach(key => {
                            item[key] = checkUndefined(item[key])
                        })
                        const tMod = new Date(item.tmod?.replace("Z", "")).toLocaleDateString('en-US')
                        const retItem = {
                            id:i,
                            psgTitle: item.psg_title,
                            tMod:  tMod !== invalidDate ? tMod : "",
                            lName: item.lname,
                            name: item.name,
                            link: item.link,
                        }        
                        return retItem
                    })
                }

                return defaultItemTable
            }

            const COMMODITIES_TABLE = commoditiesData()
            const PART_SELECTION_TABLE = partSelectionData()
            const MTL_ENG_TABLE = MtlEngData()
            const ITEM_TABLE = itemData()
            return {
                originalData:{
                    COMMODITIES_TABLE: COMMODITIES_TABLE,
                    PART_SELECTION_TABLE: PART_SELECTION_TABLE,
                    MTL_ENG_TABLE: MTL_ENG_TABLE,
                    ITEM_TABLE: ITEM_TABLE
                },  
                COMMODITIES_TABLE: COMMODITIES_TABLE,
                PART_SELECTION_TABLE: PART_SELECTION_TABLE,
                MTL_ENG_TABLE: MTL_ENG_TABLE,
                ITEM_TABLE: ITEM_TABLE
            }

        case ACTION.FILTER:
            if(!action.payload.icat_id && !action.payload.lname){
                return state
            }
            const icat_id = action.payload.icat_id || state.originalData.MTL_ENG_TABLE.find(item => item.lName === action.payload.lname).iCat_id
            const temp_mtl = state.originalData.MTL_ENG_TABLE.find(item => item.iCat_id === icat_id)
            const lname = action.payload.lname || temp_mtl 

            let commodities_table = state.originalData.COMMODITIES_TABLE
            let mtl_eng_table = state.originalData.MTL_ENG_TABLE
            let part_selection_table = state.originalData.PART_SELECTION_TABLE
            let item_table = state.originalData.ITEM_TABLE
            if(action.payload.table){
                if(action.payload.table === "CommoditiesTable"){
                    mtl_eng_table = state.originalData.MTL_ENG_TABLE.filter(item => item.iCat_id === icat_id)
                    part_selection_table = mtl_eng_table.length > 0 ? state.originalData.PART_SELECTION_TABLE.filter(item => item.lName === mtl_eng_table[0].lName) : []
                    item_table = mtl_eng_table.length > 0  ? state.originalData.ITEM_TABLE.filter(item => item.lName === mtl_eng_table[0].lName) : []
                }
                if(action.payload.table === "MTLEngTable"){
                    const filtered_mtl_eng_table = state.originalData.MTL_ENG_TABLE.filter(item => item.lName  === lname)
                    commodities_table = state.originalData.COMMODITIES_TABLE.filter(item =>{
                        for(var i in filtered_mtl_eng_table){
                            if(item.iCat_id === filtered_mtl_eng_table[i].iCat_id){
                                return true
                            }
                        }
                        return false
                        
                    } )
                    part_selection_table = state.originalData.PART_SELECTION_TABLE.filter(item => item.lName === lname) 
                    item_table = state.originalData.ITEM_TABLE.filter(item => item.lName === lname) 

                }
            }else{
                return state
            }

            return {
                originalData:{
                    ...state.originalData
                },
                COMMODITIES_TABLE: commodities_table.length > 0 ? commodities_table : defaultCommoditiesTable,
                PART_SELECTION_TABLE: part_selection_table.length > 0 ? part_selection_table : defaultPartSelectionTable,
                MTL_ENG_TABLE: mtl_eng_table.length > 0 ? mtl_eng_table: defaultMtlEngTable,
                ITEM_TABLE: item_table.length > 0 ? item_table : defaultItemTable 
            }
        case ACTION.RESET:
            return {
                originalData:{
                    ...state.originalData
                },
                ...state.originalData
            }

        default:
            return state
    }
    
}