import { createSlice } from "@reduxjs/toolkit";
import { storePartHistories, saveOrgIntoSessionStorage } from "../../Components/Util/Util";

const defaultParentColumnVisibility = {
	Description: true,
	ER: true,
	Eff_date: true,
	LastECO: true,
	MCC: true,
	PC: true,
	ParentPN: true,
	Qty: true,
	Rev: true,
	RoHS: true,
	Status: true,
	Type: true,
	disDate: true,
	std_cost: true,
};

const defaultComponentColumnVisibility = {
	CompPN: true,
	Description: true,
	ER: true,
	Eff_date: true,
	LN: true,
	LastECO: true,
	MCC: true,
	PC: true,
	Qty: true,
	Rev: true,
	RoHS: true,
	Status: true,
	Type: true,
	disDate: true,
	std_cost: true,
};

const columnParentVisibilityStorage = JSON.parse(window.localStorage.getItem("WuBomParentColumnVisibility"));
const columnComponentVisibilityStorage = JSON.parse(window.localStorage.getItem("WuBomComponentColumnVisibility"));

export const defaultParentTableRows = [
	{
		id: 0,
		ParentPN: "",
		Rev: "",
		Qty: "",
		ER: "",
		RoHS: "",
		MCC: "",
		Description: "",
		Status: "",
		Type: "",
		PC: "",
		std_cost: "0.00",
		Eff_date: "",
		LastECO: "",
		disDate: "",
		cellColor: "Yellow",
	},
];

export const defaultComponentTableRows = [
	{
		id: 0,
		LN: "",
		CompPN: "",
		Rev: "",
		Qty: "",
		ER: "",
		RoHS: "",
		MCC: "",
		Description: "",
		Status: "",
		Type: "",
		PC: "",
		std_cost: "0.00",
		Eff_date: "",
		LastECO: "",
		disDate: "",
	},
];

const initialWuBomState = {
	fullData: [],
	org: "",
	availableOrgList: [],
	partNumber: "",
	partDescription: "",
	parentTableRows: defaultParentTableRows,
	componentTableRows: defaultComponentTableRows,
	CAT_CODE: "",
	PART_REV: "",
	PART_DESC: "",
	tableParentColumnVisibility: columnParentVisibilityStorage ? columnParentVisibilityStorage : defaultParentColumnVisibility,
	tableComponentColumnVisibility: columnComponentVisibilityStorage ? columnComponentVisibilityStorage : defaultComponentColumnVisibility,
	reloadPartNumber: 0, // A useEffect dependency to trigger change part after user loaded subset from info tab itself

	// Print and Export
	printType: "pdf",
	openPrintBom: false,
	openWhereUse: false,
	openPrintPTO: false,
	allOrg: true,
	productionOnly: true,
	includeObsolete: false,
};

const wuBomSlice = createSlice({
	name: "wuBom",
	initialState: initialWuBomState,
	reducers: {
		retrievePart(state, action) {
			state.fullData = action.payload;
			state.partNumber = state.fullData.PART_NUMBER;
			state.partDescription = state.fullData.PART_DESC;
			state.CAT_CODE = state.fullData.CAT_CODE || "";
			state.PART_REV = state.fullData.PART_REV || "";
			const parentOrgList = state.fullData.PARENT_PARTS.map((Data) => Data.orgcode);
			const componentOrgList = state.fullData.COMPONENT_PARTS.map((Data) => Data.orgcode);
			state.availableOrgList = [...new Set([...parentOrgList, ...componentOrgList])].sort();
			storePartHistories(state.partNumber);
		},

		changeOrg(state, action) {
			state.org = action.payload;
			saveOrgIntoSessionStorage(action.payload);
			// Store all the part for selected org
			const orgParentRows = state.fullData.PARENT_PARTS.filter((tableData) => tableData.orgcode === state.org);
			// Collect all available parent part numbers into an array
			const orgParentPartNumbers = orgParentRows.map((tableData) => tableData.assembly_part_number);
			// Filter out all the GDO part that has the same parent part numbers of selected org
			const RemainingParentGDORows = state.fullData.PARENT_PARTS.filter(
				(tableData) => tableData.orgcode === "GDO" && !orgParentPartNumbers.includes(tableData.assembly_part_number)
			);
			const parentTableRows = [...orgParentRows, ...RemainingParentGDORows].map((tableData, index) => {
				if (tableData.orgcode === state.org) {
					return {
						id: index,
						LN: tableData.itemno || "",
						ParentPN: tableData.assembly_part_number || "",
						CompPN: tableData.component_part_number || "",
						Rev: tableData.rev || "",
						Qty: tableData.component_quantity || "0",
						ER: tableData.er || "",
						RoHS: tableData.wcrohs === 1 ? "T" : "F",
						MCC: tableData.wcmcc === 1 ? "T" : "F",
						Description: tableData.item_desc || "",
						Status: tableData.stat_code || "",
						Type: tableData.type_desc || "",
						PC: tableData.pref_code || "",
						std_cost: tableData.std_cost || "0.00",
						Eff_date: tableData.rev_edate ? new Date(tableData.rev_edate?.replace("Z", "")).toLocaleString("en-US") : "",
						LastECO: tableData.econo || "",
						disDate: "",
						cellColor: "Yellow",
					};
				} else {
					return {
						id: index,
						LN: tableData.itemno || "",
						ParentPN: tableData.assembly_part_number || "",
						CompPN: tableData.component_part_number || "",
						Rev: "",
						Qty: tableData.component_quantity || "0",
						ER: "",
						RoHS: tableData.wcrohs === 1 ? "T" : "F",
						MCC: tableData.wcmcc === 1 ? "T" : "F",
						Description: "",
						Status: "",
						Type: "",
						PC: "",
						std_cost: "0.00",
						Eff_date: tableData.rev_edate ? new Date(tableData.rev_edate?.replace("Z", "")).toLocaleString("en-US") : "",
						LastECO: "",
						disDate: "",
						cellColor: "Orange",
					};
				}
			});

			state.parentTableRows = parentTableRows?.length > 0 ? parentTableRows.sort((a, b) => a.ParentPN.localeCompare(b.ParentPN)) : defaultParentTableRows;

			const orgComponentRows = state.fullData.COMPONENT_PARTS.filter((tableData) => tableData.orgcode === state.org);
			const orgComponentPartNumbers = orgComponentRows?.map((tableData) => tableData.component_part_number);
			const RemainingGDORows = state.fullData.COMPONENT_PARTS.filter(
				(tableData) => tableData.orgcode === "GDO" && !orgComponentPartNumbers.includes(tableData.component_part_number)
			);

			const componentTableRows = [...orgComponentRows, ...RemainingGDORows].map((tableData, index) => {
				if (tableData.orgcode === state.org) {
					return {
						id: index,
						LN: tableData.itemno || "",
						ParentPN: tableData.assembly_part_number || "",
						CompPN: tableData.component_part_number || "",
						Rev: tableData.rev || "",
						Qty: tableData.component_quantity || "0",
						ER: tableData.er || "",
						RoHS: tableData.wcrohs === 1 ? "T" : "F",
						MCC: tableData.wcmcc === 1 ? "T" : "F",
						Description: tableData.item_desc || "",
						Status: tableData.stat_code || "",
						Type: tableData.type_desc || "",
						PC: tableData.pref_code || "",
						std_cost: tableData.std_cost || "0.00",
						Eff_date: tableData.rev_edate ? new Date(tableData.rev_edate?.replace("Z", "")).toLocaleString("en-US") : "",
						LastECO: tableData.econo || "",
						disDate: "",
					};
				} else {
					return {
						id: index,
						LN: tableData.itemno || "",
						ParentPN: tableData.assembly_part_number || "",
						CompPN: tableData.component_part_number || "",
						Rev: "",
						Qty: tableData.component_quantity || "0",
						ER: "",
						RoHS: tableData.wcrohs === 1 ? "T" : "F",
						MCC: tableData.wcmcc === 1 ? "T" : "F",
						Description: "",
						Status: "",
						Type: "",
						PC: "",
						std_cost: tableData.std_cost || "0.00",
						Eff_date: "",
						LastECO: "",
						disDate: "",
					};
				}
			});

			state.componentTableRows = componentTableRows?.length > 0 ? componentTableRows.sort((a, b) => a.CompPN.localeCompare(b.CompPN)) : defaultComponentTableRows;
		},

		reset: (state) => {
			return {
				...initialWuBomState,
				tableParentColumnVisibility: state.tableParentColumnVisibility,
				tableComponentColumnVisibility: state.tableComponentColumnVisibility,
			};
		},

		setPartNumberForSubset(state, action) {
			state.partNumber = action.payload;
		},

		setParentColumnVisibility(state, action) {
			state.tableParentColumnVisibility = action.payload.columnVisibility;
			window.localStorage.setItem("WuBomParentColumnVisibility", JSON.stringify(action.payload.columnVisibility));
		},

		setComponentColumnVisibility(state, action) {
			state.tableComponentColumnVisibility = action.payload.columnVisibility;
			window.localStorage.setItem("WuBomComponentColumnVisibility", JSON.stringify(action.payload.columnVisibility));
		},

		setReloadPartNumber(state) {
			state.reloadPartNumber = state.reloadPartNumber + 1;
		},

		resetPrint(state) {
			state.printType = initialWuBomState.printType;
		},

		handleOpenPrintBOM(state) {
			state.openPrintBom = !state.openPrintBom;
		},

		handleOpenWhereUse(state) {
			state.openWhereUse = !state.openWhereUse;
		},

		handlePrintTypeUpdate(state, action) {
			state.printType = action.payload;
		},

		handleOpenPrintPTO(state) {
			state.openPrintPTO = !state.openPrintPTO;
		},

		handleAllOrgChecked(state) {
			state.allOrg = !state.allOrg;
		},

		handleProdOnlyChecked(state) {
			state.productionOnly = !state.productionOnly;
			if (state.productionOnly) {
				state.includeObsolete = false;
			}
		},

		handleIncludeObsoleteChecked(state) {
			state.includeObsolete = !state.includeObsolete;
		},
	},
});

export const wuBomAction = wuBomSlice.actions;

export default wuBomSlice;
