// React
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
// UI
import { Box, Button, Modal, Stack, AppBar, Toolbar, Typography, Tooltip } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../../Util/ErrorBox";
import InfoTable from "../../../UI/Table";
import MessageBox from "../../../Util/MessageBox";
// Packages Import
import { APIURL, formatDate, OSAltKey, DateSortingComparator } from "../../../Util/Util";
import useHttp from "../../../../Hooks/use-http";
import moment from "moment";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "80%", lg: "80%", xl: "80%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	maxHeight: "90%",
	overflowY: "auto",
	p: 4,
	pb: 0,
};

const defaultSummaryColumnVisibility = {
	org: true,
	quantity: true,
};

const defaultDetailsColumnVisibility = {
	org: true,
	quantity: true,
	TransDate: true,
	PONumber: true,
	VendorName: true,
};

const PoHistory = (props) => {
	const { open, setOpen } = props;
	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [openInfoError, setOpenInfoError] = useState(false);
	const [infoError, setInfoError] = useState(false);

	const [isSummaryTable, setIsSummaryTable] = useState(true);
	const [openInfo, setOpenInfo] = useState(false);
	const [summaryColumnVisibility, setSummaryColumnVisibility] = useState(
		JSON.parse(window.localStorage.getItem("infoPoHistorySummaryColumnVisibility")) || defaultSummaryColumnVisibility
	);
	const [detailsColumnVisibility, setDetailsColumnVisibility] = useState(
		JSON.parse(window.localStorage.getItem("infoPoHistoryDetailsColumnVisibility")) || defaultDetailsColumnVisibility
	);
	const [summaryRows, setSummaryRows] = useState([]);
	const [detailRows, setDetailRows] = useState([]);

	const partNumber = useSelector((state) => state.infoStates.info.itemname) || "";
	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const columnsSettingsSummary = [
		{ field: "org", headerName: "Org", type: "text", minWidth: 130, flex: 0.3 },
		{ field: "quantity", headerName: "Quantity", type: "text", minWidth: 450, flex: 1 },
	];

	const columnsSettingsDetail = [
		{ field: "org", headerName: "Org", type: "text", minWidth: 80, flex: 0.3 },
		{ field: "quantity", headerName: "Quantity", type: "text", minWidth: 120, flex: 1 },
		{ field: "TransDate", headerName: "TransactionDate", type: "text", minWidth: 120, flex: 1, sortComparator: DateSortingComparator },
		{ field: "PONumber", headerName: "PO Number", type: "text", minWidth: 120, flex: 1 },
		{ field: "VendorName", headerName: "VendorName", type: "text", minWidth: 300, flex: 1 },
	];

	const infoErrorHeader = (value1, value2) => {
		return (
			<>
				<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
					<Toolbar>
						<Typography variant="h6" component="div" color="#fff">
							{value1}
						</Typography>
					</Toolbar>
				</AppBar>
				<Stack spacing={0.5} pt={5}>
					{value2}
				</Stack>
			</>
		);
	};

	useEffect(() => {
		const defaultSummaryRows = [{ id: 0, org: "", quantity: "" }];
		const defaultDetailRows = [{ id: 0, org: "", quantity: "", TransDate: "", PONumber: "", VendorName: "" }];

		const body = {
			userid: userNTID,
			partnumber: partNumber,
		};

		if (open) {
			setSummaryRows(defaultSummaryRows);
			setDetailRows(defaultDetailRows);
			fetchData(
				{ url: `${APIURL}Info/fetchPoHistory`, headers, method: "POST", body },
				(data) => {
					const newDetailRows = data?.PO_HISTORY.map((val, i) => {
						return {
							org: val.TO_ORGANIZATION_CODE,
							quantity: val.PRIMARY_QUANTITY,
							TransDate: moment(val.TRANSACTION_DATE, "YYYY-MM-DD, HH:mm:ss").format("MM/DD/YYYY"),
							PONumber: val.PO_NUMBER,
							VendorName: val.VENDOR_NAME,
						};
					});
					if (newDetailRows) {
						const sortedByDate = newDetailRows.sort((a, b) => DateSortingComparator(b.TransDate, a.TransDate));
						setDetailRows(
							sortedByDate?.map((val, i) => {
								return { ...val, id: i };
							})
						);
					}

					const newSummaryRows = data?.PO_HISTORY.reduce((acc, cur) => {
						const index = acc.findIndex((item) => item.org === cur.TO_ORGANIZATION_CODE);
						if (index === -1) {
							const qty = parseFloat(cur.PRIMARY_QUANTITY);
							if (!isNaN(qty)) {
								acc.push({ org: cur.TO_ORGANIZATION_CODE, quantity: qty });
							}
						} else {
							const qty = parseFloat(cur.PRIMARY_QUANTITY);
							if (!isNaN(qty)) {
								acc[index].quantity += qty;
							}
						}
						return acc;
					}, []);
					if (newSummaryRows) {
						setSummaryRows(
							newSummaryRows
								.sort((a, b) => a.org.localeCompare(b.org))
								.map((val, i) => {
									return {
										...val,
										id: i,
									};
								})
						);
					}
				},
				(error) => {
					console.log(`${error}`);
					try {
						if (error.slice(0, 5).toLowerCase() === "info:") {
							setInfoError(infoErrorHeader("Info...", error.slice(5)));
							setOpen(false);
							setOpenInfoError(true);
						} else {
							setErrorMsg(`${error}`);
							setOpen(false);
							setErrorState(true);
						}
					} catch {
						setErrorMsg(`${error}`);
						setOpen(false);
						setErrorState(true);
					}
				}
			);
		}
	}, [fetchData, headers, open, partNumber, setOpen, userNTID]);

	const InfoParagraph = (
		<>
			<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
				<Toolbar>
					<Typography variant="h6" component="div" color="#fff">
						{"Info"}
					</Typography>
				</Toolbar>
			</AppBar>
			<Stack spacing={0.5} pt={5}>
				<Typography>{"This list was generated using QUERY in Oracle"}</Typography>
				<Typography>{"Following logic was used to retrieve records form RCV_TRANSACTIONS and RCV_SHIPMENT_LINES tables"}</Typography>
				<Typography sx={{ pt: 4 }}>{"transaction_date > today - 1095 days"}</Typography>
				<Typography>{"transaction dates <= today"}</Typography>
				<Typography>{"destination type = 'RECEIVING'"}</Typography>
				<Typography>{"transaction_type = 'RECEIVE'"}</Typography>
				<Typography>{"po_unit_price > 0"}</Typography>
			</Stack>
		</>
	);

	const handleShowTableClicked = () => {
		setIsSummaryTable(!isSummaryTable);
	};

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `PO_History_${formatDate(new Date(), "")}`,
				}}
			/>
		</>
	);

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<MessageBox open={openInfo} message={InfoParagraph} messageBoxHandler={() => setOpenInfo(false)} />
			<MessageBox open={openInfoError} message={infoError} messageBoxHandler={() => setOpenInfoError(false)} />

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyI" && e.altKey === true) {
							setOpenInfo(true);
						}
						if (e.code === "KeyS" && e.altKey === true) {
							handleShowTableClicked();
						}
						if (e.code === "KeyC" && e.altKey === true) {
							setOpen(false);
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Transaction List"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Stack mt={5} mb={2} spacing={2}>
						<Typography variant="h5">
							{isSummaryTable ? "Summary" : "Detail"} PO Transaction from Oracle Bridge for item: {partNumber}
						</Typography>
						<InfoTable
							customToolBar={customToolBar}
							columnsSettings={isSummaryTable ? columnsSettingsSummary : columnsSettingsDetail}
							rows={isSummaryTable ? summaryRows : detailRows}
							checkboxSelection={false}
							pageSize={100}
							autoTableHeight={false}
							rowHeight={45}
							height={400}
							ExtraProps={{
								columnVisibilityModel: isSummaryTable ? summaryColumnVisibility : detailsColumnVisibility,
								onColumnVisibilityModelChange: (newModel) => {
									if (isSummaryTable) {
										window.localStorage.setItem("infoPoHistorySummaryColumnVisibility", JSON.stringify(newModel));
										setSummaryColumnVisibility(newModel);
									} else {
										window.localStorage.setItem("infoPoHistoryDetailsColumnVisibility", JSON.stringify(newModel));
										setDetailsColumnVisibility(newModel);
									}
								},
							}}
						/>
						<Box display={"flex"} mt={2} mb={2}>
							<Box flex={1}>
								<Tooltip title={"Press S to Show"}>
									<Button onClick={handleShowTableClicked}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>how{" "}
										{isSummaryTable ? "detail records" : "summary records"}
									</Button>
								</Tooltip>
								{/* <Tooltip title={"Press E to Export to Excel"}>
									<Button onClick={() => {}}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>E</u>xport to Excel
									</Button>
								</Tooltip> */}
								<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
							</Box>
							<Box>
								<Tooltip title={"Press I to open Info"}>
									<Button
										onClick={() => {
											setOpenInfo(true);
										}}
									>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>I</u>nfo - How was this list generated?
									</Button>
								</Tooltip>
								<Tooltip title={"Press C to close"}>
									<Button
										onClick={() => {
											setOpen(false);
										}}
									>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
									</Button>
								</Tooltip>
								<Typography sx={{ fontSize: "0.7rem", display: "flex", justifyContent: "flex-end" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
							</Box>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	);
};

export default PoHistory;
