import { Tooltip, Grid } from "@mui/material";
import { Fragment } from "react";
import ColoredTextField from "../../../UI/ColoredTextField";
import { useSelector } from "react-redux";

const InfoPartDetails = () => {
	const info = useSelector((state) => state.infoStates.info);
	const selectedOrg = useSelector((state) => state.infoStates.org);
	const MtlEngInfo = useSelector((state) => state.infoStates.MtlEngInfo);
	const orgMtlEngDetails = MtlEngInfo?.find((data) => data.org_code === selectedOrg);
	const MTL_ENG_NAME = info.mtl_eng_name || (orgMtlEngDetails?.fullname ? orgMtlEngDetails.fullname : "_To be determined");
	const REV = info.rev || "";
	const REV_EDATE = info.rev_edate ? new Date(info.rev_edate.replace("Z", "")).toLocaleDateString("en-US") : "";
	const ECONO = info.econo || "";
	const EC_INDATE = info.ec_indate ? new Date(info.ec_indate.replace("Z", "")).toLocaleDateString("en-US") : "";
	const PROD_LINE = info.prod_line || "";
	const PROD_LINE_DESC = info.prod_line_desc || "";
	const stat_code = info.stat_code || "";
	const er = info.er || "";
	const type_desc = info.type_desc || "";
	const cat_code = orgMtlEngDetails?.oracle_cat || info.cat_code || "";
	const icat_id = orgMtlEngDetails?.icat_id || info.icat_id || "";
	const pref_code = info.pref_code || "";
	const pref_desc = info.pref_desc || "";
	const planner = info.planner || "";
	const plan_id = info.plan_id || "";
	const UM = info.um || "";

	const xl = 3;
	const lg = 3;
	const md = 4;
	const sm = 4;
	const xs = 12;

	const defaultProps = {
		fullWidth: true,
		focused: true,
		inputProps: { readOnly: true },
	};

	return (
		<Fragment>
			<Grid container pb={3} direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={3} columnSpacing={0.8} wrap={"wrap"}>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={MTL_ENG_NAME}>
						<ColoredTextField {...defaultProps} label="MTL Engineer" value={MTL_ENG_NAME} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={REV}>
						<ColoredTextField {...defaultProps} label="Current Rev" value={REV} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={REV_EDATE}>
						<ColoredTextField {...defaultProps} label="Effectivity date" value={REV_EDATE} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={ECONO}>
						<ColoredTextField {...defaultProps} label="Last ECO No" value={ECONO} />
					</Tooltip>
				</Grid>

				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={EC_INDATE}>
						<ColoredTextField {...defaultProps} label="Change date" value={EC_INDATE} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={PROD_LINE}>
						<ColoredTextField {...defaultProps} label="Product" value={PROD_LINE} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={PROD_LINE_DESC}>
						<ColoredTextField {...defaultProps} label="Line" value={PROD_LINE_DESC} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={stat_code}>
						<ColoredTextField {...defaultProps} label="Item status" value={stat_code} />
					</Tooltip>
				</Grid>

				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={er}>
						<ColoredTextField {...defaultProps} label="ER" value={er} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={type_desc}>
						<ColoredTextField {...defaultProps} label="Item type" value={type_desc} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={`${cat_code}; ora_id=${icat_id}`}>
						<ColoredTextField {...defaultProps} label="iCat &#38; iCat ID" value={`${cat_code}; ora_id=${icat_id}`} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={`${pref_code} - ${pref_desc}`}>
						<ColoredTextField {...defaultProps} label="PRef Code &#38; Code Description" value={`${pref_code} - ${pref_desc}`} />
					</Tooltip>
				</Grid>

				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={planner}>
						<ColoredTextField {...defaultProps} label="Planner buyer name" value={planner} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={plan_id}>
						<ColoredTextField {...defaultProps} label="P/B Code" value={plan_id} />
					</Tooltip>
				</Grid>
				<Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
					<Tooltip title={UM}>
						<ColoredTextField {...defaultProps} label="UM" value={UM} />
					</Tooltip>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default InfoPartDetails;
