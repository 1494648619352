// React
import { useSelector } from "react-redux";
import { useState, useRef, useEffect, useMemo } from "react";
// UI
import { Modal, Box, Stack, Tooltip, Typography, Button } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../../Util/ErrorBox";
import ControlledMessageBox from "../../../Util/ControlledMessagebox";
// Packages import
import useHttp from "../../../../Hooks/use-http";
import { APIURL, OSAltKey } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const CreateBOMSubset = (props) => {
	const { open, onClose, mlar_pn, org, partNumbers } = props;
	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	const [inputValue, setInputValue] = useState("");
	const [error, setError] = useState("");

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [message, setMessage] = useState("");
	const [openMessageBox, setOpenMessageBox] = useState(false);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const createRef = useRef(null);

	useEffect(() => {
		setInputValue(`subset_wu_${mlar_pn}_${org}`);
		setError("");
	}, [org, mlar_pn, partNumbers]);

	const handleInputChange = (event) => {
		const value = event.target.value;
		setInputValue(value);
		if (value?.trim()) {
			setError("");
		} else {
			setError("Subset name cannot be blank");
		}
	};

	const handleOkClicked = () => {
		const body = { userid: userNTID, subsetid: inputValue, partnumber: partNumbers };
		fetchData(
			{ url: `${APIURL}subset/editSubset`, headers, method: "POST", body: body },
			(data) => {
				console.log(data);
				setOpenMessageBox(true);
				setMessage(`Subset ${inputValue} created successfully`);
				setTimeout(() => {
					setMessage("");
					setOpenMessageBox(false);
					onClose();
				}, 2000);
			},
			(error) => {
				console.log(`ERROR: Unable to Create subset Information, ${error}`);
				setErrorMsg(`Unable to Create subset, please try again. ERROR: ${error}`);
				setErrorState(true);
			}
		);
	};

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<ControlledMessageBox open={openMessageBox} message={message} />

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyO" && e.altKey === true && inputValue.length !== 0) {
							e.preventDefault();
							handleOkClicked();
						} else if (e.code === "KeyC" && e.altKey === true) {
							e.preventDefault();
							onClose();
						}
					}}
				>
					<Stack mb={2} spacing={2}>
						<ColoredTextField
							fullWidth
							label="Enter new name"
							focused
							autoFocus
							inputRef={createRef}
							onChange={handleInputChange}
							value={inputValue}
							error={error ? true : false}
							helperText={error}
						/>
					</Stack>
					<Box>
						<Tooltip title={`For shortcut press ${OSAltKey} + O`}>
							<span>
								<Button disabled={inputValue.length === 0 ? true : false} onClick={handleOkClicked}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>O</u>K
								</Button>
							</span>
						</Tooltip>
						<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
							<Button onClick={onClose}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
						</Tooltip>
					</Box>
					<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
				</Box>
			</Modal>
		</>
	);
};

export default CreateBOMSubset;
