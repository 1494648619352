// React
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubsetAction } from "../../../store/Subset/Subset-slice";

// UI
import { Modal, Box, Stack, Button, Tooltip, Typography } from "@mui/material";
import ColoredTextField from "../../UI/ColoredTextField";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../Util/ErrorBox";
import ConfirmationBox from "../../Util/ConfirmationBox";

// Project packages
import { APIURL, OSAltKey, storePartHistories } from "../../Util/Util";
import useHttp from "../../../Hooks/use-http";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: { xs: 420, sm: 420, md: 500, lg: 650, xl: 800 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const CreateNewSubsetName = (props) => {
	const dispatch = useDispatch();
	const { open, onClose, handleRedirect } = props;
	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	const [inputValue, setInputValue] = useState("");
	const [error, setError] = useState("");

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const subsetNameList = useSelector((state) => state.SubsetState.subsetNameList);
	const inputPartNumbers = useSelector((state) => state.SubsetState.inputPartNumbers);

	const createRef = useRef(null);

	useEffect(() => {
		setInputValue("subset");
		if (open) {
			fetchData(
				{ url: `${APIURL}subset/subsetList/${userNTID}`, headers },
				(data) => {
					console.log(data);
					if (data.Subset_Info.length > 0) {
						dispatch(SubsetAction.setSubsetInfo({ data }));
					}
					createRef.current.focus();
				},
				(error) => {
					console.log(`ERROR: Unable to Retrieve subset Information, ${error}`);
					setErrorMsg(`Unable to Retrieve subset Information, please close the window and select create again. ERROR: ${error}`);
					setErrorState(true);
				}
			);
		}
	}, [dispatch, fetchData, headers, open, userNTID]);

	const handleInputChange = (event) => {
		const value = event.target.value;
		setInputValue(value);
		if (value?.trim()) {
			setError("");
		} else {
			setError("Subset name cannot be blank");
		}
	};

	const handleSubmitCreateSubset = async () => {
		const body = { userid: userNTID, subsetid: inputValue, partnumber: inputPartNumbers };
		await fetchData(
			{ url: `${APIURL}subset/editSubset`, headers, method: "POST", body: body },
			(data) => {
				console.log(data);
				dispatch(SubsetAction.setSubsetInfo({ data }));
				dispatch(SubsetAction.setSelectedSubset(inputValue));
				storePartHistories(inputPartNumbers[0]);
				window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(inputPartNumbers));
				window.sessionStorage.setItem("subsetName", JSON.stringify(inputValue));
				window.sessionStorage.setItem("subsetLoaded", true);
				handleRedirect();
			},
			(error) => {
				console.log(`ERROR: Unable to Create subset Information, ${error}`);
				setErrorMsg(`Unable to Create subset Information, please try again. ERROR: ${error}`);
				setErrorState(true);
			}
		);
	};

	const handleOkClicked = () => {
		if (subsetNameList.includes(inputValue?.trim()) && inputValue?.trim() !== "subset") {
			console.log("duplicated");
			setOpenConfirmationBox(true);
		} else {
			handleSubmitCreateSubset();
		}
	};

	const handleDuplicateOK = async () => {
		await handleSubmitCreateSubset();
		setOpenConfirmationBox(false);
	};

	const handleDuplicateCancel = () => {
		setOpenConfirmationBox(false);
	};

	const handleOnKeyDown = (e) => {
		if (e.code === "KeyO" && e.altKey === true && inputValue.length !== 0) {
			e.preventDefault();
			handleOkClicked();
		} else if (e.code === "KeyC" && e.altKey === true) {
			e.preventDefault();
			onClose();
		}
	};

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<ConfirmationBox
				open={openConfirmationBox}
				handleYes={handleDuplicateOK}
				handleNo={handleDuplicateCancel}
				message={`Subset ${inputValue} already exists, this action will replace the existing ${inputValue} subset. Do you want to continue?`}
			/>

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						handleOnKeyDown(e);
					}}
				>
					<Stack mb={2} spacing={2}>
						<ColoredTextField
							fullWidth
							label="Enter new name"
							focused
							inputRef={createRef}
							onChange={handleInputChange}
							value={inputValue}
							error={error ? true : false}
							helperText={error}
						/>
					</Stack>
					<Box>
						<Tooltip title={`For shortcut press ${OSAltKey} + O`}>
							<span>
								<Button disabled={inputValue.length === 0 ? true : false} onClick={handleOkClicked}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>O</u>K
								</Button>
							</span>
						</Tooltip>
						<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
							<Button onClick={onClose}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
						</Tooltip>
					</Box>
					<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
				</Box>
			</Modal>
		</>
	);
};

export default CreateNewSubsetName;
