import { createSlice } from "@reduxjs/toolkit";
import { storePartHistories, dateDiff } from "../../Components/Util/Util";

const defaultNoteHeaderData = {
	part: "",
	prefCode: "",
	iCat: "",
	desc: "",
}

const defaultNoteInfoData = [{
	id:0,
	org: "",
	dateUpdated: "",
	updatedBy: "",
	dateAdded: "",
	originator: "",
	note: "",
	prstat: false ,
	lrepl01: false,
	lrepl02: false
}]

const defaultNoteInfoTableData = [{
	id:0,
	org: "",
	dateUpdated: "",
	updatedBy: "",
	dateAdded: "",
	originator: "",
	note: "",}]

const defaultUserNote = {
    orgs:[],
    selectedOrg: "",
	dateUpdated: "",
	updatedBy: "",
	dateAdded: "",
	originator: "",
	note: "",
	prstat: false ,
	lrepl01: false,
	lrepl02: false
}

const defaultColumnVisibility = {
    org: true,
    note: true,
    originator: true,
    dateAdded: true,
    updatedBy: true,
    dateUpdated: true,
  }
//   window.localStorage.removeItem("noteColumnVisibility") // Incase of problems due to Item
const columnVisibilityStorage = JSON.parse(window.localStorage.getItem("NOTE_notesColumnVisibility"))
const initiialNoteState = {
    PART_INFO : defaultNoteHeaderData,
    NOTE_INFO : defaultNoteInfoData,
    NOTE_INFO_TABLE : [],
    USER_NOTE : defaultUserNote,
    ORGS : [],
    tableColumnVisibility: columnVisibilityStorage ? columnVisibilityStorage : defaultColumnVisibility,
    reloadPartNumber: 0, // A useEffect dependency to trigger change part after user loaded subset from info tab itself
};

export const ACTION = {
	RESET: "reset",
	CREATE: "create",
	UPDATE: "update",
};

const noteSlice = createSlice({
	name: "note",
	initialState: initiialNoteState,
	reducers: {
        reset: (state) => {
            state.PART_INFO = initiialNoteState.PART_INFO;
            state.NOTE_INFO = initiialNoteState.NOTE_INFO;
            state.NOTE_INFO_TABLE = initiialNoteState.NOTE_INFO_TABLE;
            state.USER_NOTE = initiialNoteState.USER_NOTE;
        },
		updateAll(state, action) {
			// let displayDate = action.payload.data.NOTE_INFO.find((singleNoteInfo) => singleNoteInfo.division === action.payload.userInfo.div) || undefined
            // if(displayDate){
            //     // console.log("Date Added = " + new Date(displayDate.dadd))
            //     // console.log("Date Updated = " + new Date(displayDate.dupt))
            // }
            const noteHeaderData = () =>{
                
                let retItem = defaultNoteHeaderData
                const item = action.payload.data.PART_INFO
                const status = action.payload.data?.PREF_CODE_CHANGE_REQUEST ? action.payload.data.PREF_CODE_CHANGE_REQUEST : undefined
                if(Object.keys(item).length>0){

                    Object.keys(item).forEach(key => {
                        item[key] = item[key] ? item[key] : ""
                    })

                    storePartHistories(item.itemname)
                    retItem = {
                        part: item.itemname,
                        prefCode: item.pref_code,
                        iCat: item.cat_code + "; oracl_id=" + item.icat_id,
                        desc: item.item_desc,
                        icatId: item.icat_id,
                        icatCode: item.cat_code,
                        newPc: status?.new_pc || "",
                        reviewStatus: status?.review_status || "",
                        nonF01Req: status?.is_req_nonf01 || "",
                    }
                }

                return retItem
            }

            const noteInfoData = () =>{
                
                if(Object.keys(action.payload.data.NOTE_INFO).length > 0){
                    
                    return action.payload.data.NOTE_INFO.map( (singleNoteInfo) =>{ 

                        Object.keys(singleNoteInfo).forEach(key => {
                            singleNoteInfo[key] = singleNoteInfo[key] ? singleNoteInfo[key] : ""
                        })
                        const invalidDate = "Invalid Date"
                        const dateUpdated = new Date(singleNoteInfo.dupt?.replace("Z", "")).toLocaleDateString("en-US")
                        const dateAdded = new Date(singleNoteInfo.dadd?.replace("Z", "")).toLocaleDateString("en-US")
                        
                        const retItem = {
                            org: singleNoteInfo.user_org_c,
                            dateUpdated: dateUpdated !== invalidDate ? dateUpdated.toString() : "",
                            updatedBy: singleNoteInfo.name1,
                            dateAdded: dateAdded !== invalidDate ? dateAdded.toString() : "",
                            originator: singleNoteInfo.username,
                            note: singleNoteInfo.note,
                            prstat: singleNoteInfo.prstat === 1 ? true : false ,
                            lrepl01: singleNoteInfo.lrepl01 === "True" ? true : false,
                            lrepl02: singleNoteInfo.lrepl02 === "True" ? true : false
                        }
                        return retItem
                    })
                }

                return defaultNoteInfoData
                
            }

            const noteInfoTable = (noteInfo) => {
                return noteInfo.map( (singleNoteInfo , i) =>{ 
                    const retTable = {
                        id:i,
                        org: singleNoteInfo.org,
                        dateUpdated: singleNoteInfo.dateUpdated && new Date(singleNoteInfo.dateUpdated?.replace("Z", "")).toLocaleDateString("en-US"),
                        updatedBy: singleNoteInfo.updatedBy,
                        dateAdded: singleNoteInfo.dateUpdated && new Date(singleNoteInfo.dateAdded?.replace("Z", "")).toLocaleDateString("en-US"),
                        originator: singleNoteInfo.originator,
                        note: singleNoteInfo.note,
                    }
                    return retTable
                })
            }

            const userNote =(noteInfo, partInfo) => {
                const isNoteExist = noteInfo.find((singleNoteInfo) => singleNoteInfo.org === action.payload.userInfo.org.primaryOrg)
                let usernote = isNoteExist ? JSON.parse(JSON.stringify(isNoteExist)) : undefined
                let postAction = ACTION.UPDATE
                let prefCodeStartsWith4 = false
                if(!usernote){
                    prefCodeStartsWith4 = partInfo.prefCode.startsWith("4")
                    postAction = ACTION.CREATE
                    usernote = JSON.parse(JSON.stringify(defaultUserNote))
                    usernote.backgroundColor = "rgba(255, 0, 0, 0.3)"
                    // usernote.originator = action.payload.userInfo.username
                }else{
                    // console.log(usernote.dateUpdated && `note is ${dateDiff(new Date(usernote.dateUpdated))} days old`);
                    if (PART_INFO.prefCode === "1" || PART_INFO.prefCode === "2") {
                        if(usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 365) {
                            usernote.backgroundColor  = "rgba(255, 0, 0, 0.3)"
                        } else if (usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 351){
                            usernote.backgroundColor  = "rgba(255,165,0, 0.8)"
                        } else {
                            usernote.backgroundColor  = "rgba(128, 255, 128, 0.8)"
                        }
                    } else {
                        if(usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 182) {
                            usernote.backgroundColor  = "rgba(255, 0, 0, 0.3)"
                        } else if (usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 168){
                            usernote.backgroundColor  = "rgba(255,165,0, 0.8)"
                        } else {
                            usernote.backgroundColor  = "rgba(128, 255, 128, 0.8)"
                        }
                    }
                }
                usernote.selectedOrg = action.payload.userInfo.org.primaryOrg
                return {usernote , postAction, prefCodeStartsWith4}
            }
            const PART_INFO = noteHeaderData()
            const NOTE_INFO = noteInfoData()
            const NOTE_INFO_TABLE = noteInfoTable(NOTE_INFO)
            const USER_NOTE = userNote(NOTE_INFO, PART_INFO)
            state.PART_INFO = PART_INFO
            state.NOTE_INFO = NOTE_INFO
            state.NOTE_INFO_TABLE = NOTE_INFO_TABLE?.length > 0 ? NOTE_INFO_TABLE : defaultNoteInfoTableData;
            state.USER_NOTE = USER_NOTE.usernote
            state.POST_ACTION = USER_NOTE.postAction
            state.PREF_CODE_STARTS_WITH_4 = USER_NOTE.prefCodeStartsWith4
            state.ORGS = action.payload.userInfo.org.allOrg
		},

		updateNoteInfo(state, action) {
			const userNoteInfo = Object.assign({}, state.USER_NOTE)
            if(action.payload.name === "note" && action.payload.postAction === ACTION.CREATE){
                userNoteInfo.prstat = true
            }
            userNoteInfo[action.payload.name] = action.payload.val
            state.USER_NOTE =  userNoteInfo
		},
        updateOrgSelected(state , action){
            const isNoteExist = action.payload.NOTE_INFO.find((singleNoteInfo) => singleNoteInfo.org === action.payload.val)
            let usernote = isNoteExist ? JSON.parse(JSON.stringify(isNoteExist)) : undefined
            let postAction = ACTION.UPDATE
            let prefCodeStartsWith4 = false
            if(!usernote){
                prefCodeStartsWith4 = action.payload.PART_INFO.prefCode.startsWith("4")
                postAction = ACTION.CREATE
                usernote = JSON.parse(JSON.stringify(defaultUserNote))
                usernote.backgroundColor = "rgba(255, 0, 0, 0.3)"
                // usernote.originator = action.payload.userInfo.username
            }else{
                console.log(usernote.dateUpdated && `note is ${dateDiff(new Date(usernote.dateUpdated))} days old`);
                if (state.PART_INFO.prefCode === "1" || state.PART_INFO.prefCode === "2") {
                    if(usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 365) {
                        usernote.backgroundColor = "rgba(255, 0, 0, 0.3)"
                    } else if (usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 351){
                        usernote.backgroundColor  = "rgba(255,165,0, 0.8)"
                    } else {
                        usernote.backgroundColor  = "rgba(128, 255, 128, 0.8)"
                    }
                } else {
                     if(usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 182) {
                        usernote.backgroundColor  = "rgba(255, 0, 0, 0.3)"
                    } else if (usernote.dateUpdated && dateDiff(new Date(usernote.dateUpdated)) >= 168){
                        usernote.backgroundColor  = "rgba(255,165,0, 0.8)"
                    } else {
                        usernote.backgroundColor  = "rgba(128, 255, 128, 0.8)"
                    }
                }
            }
            usernote.selectedOrg = action.payload.val
            console.log(usernote.selectedOrg)
            state.USER_NOTE = usernote
            state.POST_ACTION = postAction
            state.PREF_CODE_STARTS_WITH_4 = prefCodeStartsWith4
        },
        setColumnVisibility(state, action){
            state.tableColumnVisibility = action.payload.columnVisibility
            window.localStorage.setItem("NOTE_notesColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
        },

        setPartNumberForSubset(state, action) {
            state.PART_INFO.part = action.payload
        },

        setReloadPartNumber(state) {
			state.reloadPartNumber = state.reloadPartNumber + 1;
		},
	},
});

export const noteAction = noteSlice.actions;

export default noteSlice;
