// Packages Import
import { Fragment, useState, memo, useEffect, useCallback } from "react";
import { Button, Box, MenuItem, Tooltip, IconButton, Typography, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useDispatch, useSelector } from "react-redux";
import Mousetrap from "mousetrap";

// Project Components
import BottomBarNavigation from "../../Layout/BottomBarNavigation";
import FindPart from "../../Util/FindPart";
import { findPartAction } from "../../../store/common/findPart-slice";
import PrefCode from "../../Layout/PrefCode";
import Icat from "../../Layout/icat/icat";
import { OSAltKey, OpenCDS } from "../../Util/Util";

const BottomNavigation = (props) => {
	const { editNoteState } = props;
	const dispatch = useDispatch();
	const postAction = useSelector((state) => state.noteStates.POST_ACTION) || "";
	const selectedHistory = useSelector((state) => state.noteStates.PART_INFO.part) || "";
	const prefCodeStartsWith4 = useSelector((state) => state.noteStates.PREF_CODE_STARTS_WITH_4) || false;
	const nonMtlEngAccess = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);
	const { allOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);

	const userIsF01 = allOrg.includes("F01");
	const disableICat = nonMtlEngAccess || !selectedHistory || !userIsF01;
	const disablePrefCode = nonMtlEngAccess || !selectedHistory;

	const [openPrefCode, setOpenPrefCode] = useState(false);
	const [openIcat, setOpenIcat] = useState(false);

	const prefCode = useSelector((state) => state.noteStates.PART_INFO.prefCode);

	const [anchorElNav, setAnchorElNav] = useState(null);

	const disableAcceptButton = nonMtlEngAccess || (postAction === props.action.CREATE ? false : true) || prefCodeStartsWith4;
	const disableQUpdateButton = nonMtlEngAccess || (postAction === props.action.UPDATE ? false : true) || prefCodeStartsWith4;

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleFindClicked = useCallback(() => {
		handleCloseNavMenu();
		dispatch(findPartAction.setPartFound(true));
		dispatch(findPartAction.setShowFindPart(true));
	}, [dispatch]);

	const handleEditNoteState = useCallback(() => {
		handleCloseNavMenu();
		props.editNoteStateHandler();
	}, [props]);

	const handleQuickUpdate = useCallback(() => {
		handleCloseNavMenu();
		props.quickUpdateHandler();
	}, [props]);

	const handleAcceptPart = () => {
		handleCloseNavMenu();
		props.acceptPartHandler();
	};

	const handleOpenPrefCode = () => {
		setOpenPrefCode(!openPrefCode);
	};

	const handleOpenIcat = () => {
		setOpenIcat(!openIcat);
	};

	const handleCdsClicked = () => {
		handleCloseNavMenu();
		OpenCDS(selectedHistory);
	};

	useEffect(() => {
		Mousetrap.bind("ctrl+shift+f", (e) => {
			e.preventDefault();
			handleFindClicked();
		});
		return () => Mousetrap.unbind("ctrl+shift+f");
	}, [handleFindClicked]);

	useEffect(() => {
		Mousetrap.bind("option+shift+e", (e) => {
			e.preventDefault();
			if (!editNoteState && !nonMtlEngAccess) handleEditNoteState();
		});
		return () => Mousetrap.unbind("option+shift+e");
	}, [handleEditNoteState, editNoteState, nonMtlEngAccess]);

	useEffect(() => {
		Mousetrap.bind(["option+shift+s"], (e) => {
			e.preventDefault();
			if (editNoteState && !nonMtlEngAccess) handleEditNoteState();
		});
		return () => Mousetrap.unbind("option+shift+s");
	}, [handleEditNoteState, editNoteState, nonMtlEngAccess]);

	useEffect(() => {
		Mousetrap.bind("ctrl+shift+q", (e) => {
			e.preventDefault();
			if (!disableQUpdateButton && !nonMtlEngAccess) {
				handleQuickUpdate();
			}
		});
		return () => Mousetrap.unbind("ctrl+shift+q");
	}, [disableQUpdateButton, handleQuickUpdate, nonMtlEngAccess]);

	return (
		<Fragment>
			<PrefCode open={openPrefCode} handleOpenPrefCode={handleOpenPrefCode} partNumber={selectedHistory} />
			<Icat open={openIcat} handleOpenIcat={handleOpenIcat} partNumber={selectedHistory} prefCode={prefCode} />

			<Box position={"fixed"} bottom={0} width={"100%"} display="flex" alignItems={"center"} bgcolor={"#EBEBEB"} zIndex={1100}>
				<Box m={2} sx={{ mx: "auto" }}>
					<Box sx={{ display: "flex" }}>
						<BottomBarNavigation changePart={props.changePart} selectedHistory={selectedHistory} />

						<Box pl={3} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}>
							<Button variant="contained" onClick={handleCdsClicked}>
								CDS...
							</Button>
							<Tooltip title="Open Find Window (CTRL + Shift + F)">
								<span>
									<Button variant="contained" onClick={handleFindClicked}>
										Find
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={!editNoteState ? `Edit Note (${OSAltKey} + Shift + E)` : `Save Note (${OSAltKey} + Shift + S)`}>
								<span>
									<Button disabled={nonMtlEngAccess} variant="contained" onClick={handleEditNoteState}>
										{!editNoteState ? "Edit Note" : "Save Note"}
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={!selectedHistory ? "Find a part to set new iCat Code" : ""}>
								<span>
									<Button variant="contained" onClick={handleOpenIcat} disabled={disableICat}>
										New iCAT req
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={!selectedHistory ? "Find a part to set new Pref Code" : ""}>
								<span>
									<Button variant="contained" onClick={handleOpenPrefCode} disabled={disablePrefCode}>
										New Pref.C
									</Button>
								</span>
							</Tooltip>
							<Button variant="contained" disabled={disableAcceptButton} onClick={handleAcceptPart}>
								Accpt part
							</Button>
							<Tooltip title="Quick update (CTRL + Shift + Q)">
								<span>
									<Button variant="contained" disabled={disableQUpdateButton} onClick={handleQuickUpdate}>
										Q_Updt
									</Button>
								</span>
							</Tooltip>
						</Box>

						<Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" } }}>
							<IconButton
								size="Medium"
								aria-label="Footer buttons"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								{anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								disableScrollLock
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
								}}
							>
								<MenuItem key={"CDS..."} onClick={handleCdsClicked}>
									<Typography textAlign="center">{"CDS..."}</Typography>
								</MenuItem>
								<MenuItem key={"Find"} onClick={handleFindClicked}>
									<Typography textAlign="center">{"Find"}</Typography>
								</MenuItem>
								<MenuItem key={"Edit Note"} disabled={nonMtlEngAccess} onClick={handleEditNoteState}>
									<Typography textAlign="center">{!editNoteState ? "Edit Note" : "Save Note"}</Typography>
								</MenuItem>
								<MenuItem key={"New iCAT req"} onClick={handleOpenIcat} disabled={disableICat}>
									<Typography textAlign="center">{"New iCAT req"}</Typography>
								</MenuItem>
								<MenuItem key={"New Pref.C"} onClick={handleOpenPrefCode} disabled={disablePrefCode}>
									<Typography textAlign="center">{"New Pref.C"}</Typography>
								</MenuItem>
								<MenuItem key={"Accpt part"} onClick={handleAcceptPart} disabled={disableAcceptButton}>
									<Typography textAlign="center">{"Accpt part"}</Typography>
								</MenuItem>
								<MenuItem key={"Q_Updt"} onClick={handleQuickUpdate} disabled={disableQUpdateButton}>
									<Typography textAlign="center">{"Q_Updt"}</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
			<FindPart
				showFindPart={props.showFindPart}
				setShowFindPart={props.setShowFindPart}
				changePart={props.changePart}
				partFound={props.partFound}
				setPartFound={props.setPartFound}
			/>
		</Fragment>
	);
};

export default memo(BottomNavigation);
