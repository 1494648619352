import React, { useState, useRef, useImperativeHandle } from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const NestedMenuItem = React.forwardRef(function NestedMenuItem(props, ref) {
	const {
		parentMenuOpen,
		label,
		rightIcon = null,
		leftIcon = <ArrowBackIosNewIcon sx={{ color: "#000" }} fontSize="small" />,
		openIcon = <ArrowBackIosNewIcon sx={{ color: "#000" }} fontSize="small" />,
		children,
		className,
		tabIndex: tabIndexProp,
		ContainerProps: ContainerPropsProp = {},
		MenuProps,
		disabled,
		...MenuItemProps
	} = props;

	const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;
	const menuItemRef = useRef(null);
	useImperativeHandle(ref, () => menuItemRef.current);

	const containerRef = useRef(null);
	useImperativeHandle(containerRefProp, () => containerRef.current);

	const menuContainerRef = useRef(null);

	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

	const handleMouseEnter = (e) => {
		if (disabled) {
			return;
		}
		setIsSubMenuOpen(true);

		if (ContainerProps.onMouseEnter) {
			ContainerProps.onMouseEnter(e);
		}
	};

	const handleMouseLeave = (e) => {
		if (disabled) {
			return;
		}
		setIsSubMenuOpen(false);

		if (ContainerProps.onMouseLeave) {
			ContainerProps.onMouseLeave(e);
		}
	};

	const handleFocus = (e) => {
		if (e.target === containerRef.current) {
			setIsSubMenuOpen(true);
		}

		if (ContainerProps.onFocus) {
			ContainerProps.onFocus(e);
		}
	};

	const open = isSubMenuOpen && parentMenuOpen;

	// Root element must have a `tabIndex` attribute for keyboard navigation
	let tabIndex;
	if (!props.disabled) {
		tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
	}

	return (
		<div
			{...ContainerProps}
			ref={containerRef}
			onFocus={handleFocus}
			tabIndex={tabIndex}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<MenuItem className={className} sx={{height: 45}} ref={menuItemRef} disabled={disabled} {...MenuItemProps}>
				<ListItemIcon>{isSubMenuOpen ? openIcon : leftIcon}</ListItemIcon>
				<ListItemText>{label}</ListItemText>
				<ListItemIcon>{rightIcon}</ListItemIcon>
			</MenuItem>

			<Menu
				// Set pointer events to 'none' to prevent the invisible Popover div
				// from capturing events for clicks and hovers
				sx={{ pointerEvents: "none", marginTop: -1.3}}
				anchorEl={menuItemRef.current}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={open}
				transitionDuration={200}
				autoFocus={false}
				disableAutoFocus
				disableEnforceFocus
				onClose={() => {
					setIsSubMenuOpen(false);
				}}
				{...MenuProps}
			>
				<div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
					{children}
				</div>
			</Menu>
		</div>
	);
});

NestedMenuItem.displayName = "NestedMenuItem";
export { NestedMenuItem };
