// React
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { infoAction } from "../../../store/Info/info-slice";
import { findPartAction } from "../../../store/common/findPart-slice";
// UI
import CardWrapper from "../../UI/Card";
import { Box } from "@mui/material";
import ColoredTextField from "../../UI/ColoredTextField";
import AMLASLTable from "./Table/AMLASLTable";
import OrgTable from "./Table/OrgTable";
import BottomNavigation from "./Footer/InfoBottomBar";
import InfoHeader from "./Body/InfoHeader";
import InfoPartDetails from "./Body/InfoPartDetails";
import InfoQuantityAndCost from "./Body/InfoQuantityAndCost";
import AcceptAndQupdatePart from "./Body/AcceptAndQupdatePart";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../Util/ErrorBox";
// Project
import useHttp from "../../../Hooks/use-http";
import { APIURL } from "../../Util/Util";

const Info = () => {
	const dispatch = useDispatch();
	const info = useSelector((state) => state.infoStates.info);
	const note = useSelector((state) => state.infoStates.note);
	const selectedNoteMultiOrg = useSelector((state) => state.infoStates.selectedNoteMultiOrg);
	const [showAcceptQupdate, setShowAcceptQupdate] = useState(false);

	const reloadPartNumber = useSelector((state) => state.infoStates.reloadPartNumber);

	const showFindPart = useSelector((state) => state.findPartState.showFindPart);
	const [errorMsg, setErrorMsg] = useState("");
	const [infoErrorState, setInfoErrorState] = useState(false);

	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const { primaryOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const { isLoading, sendRequest: fetchData } = useHttp();
	const { isLoading: isLoading2, sendRequest: fetchData2 } = useHttp();

	const APIError = (error) => {
		console.log("ERROR: Unable to retrieve Info data", error);
		setErrorMsg(`ERROR: ${error}`);
		setInfoErrorState(true);
	};

	// Retrieve part info from API
	const changePart = (partNumber) => {
		fetchData(
			{ url: `${APIURL}Info/infolist/${partNumber}/`, headers: headers },
			(data) => {
				console.log(data);
				if (data.ITEM_INFO.length > 0) {
					let org = window.sessionStorage.getItem("org");
					org = org && org !== "null" && org !== "undefined" ? org : "F01";
					dispatch(infoAction.retrievePart({ data, org: primaryOrg }));
					dispatch(findPartAction.setPartFound(true));
					dispatch(findPartAction.setShowFindPart(false));
					dispatch(infoAction.changeOrg(org));
					dispatch(infoAction.changeOrgNote(selectedNoteMultiOrg));
				} else {
					if (!showFindPart) {
						setErrorMsg(`Part Number ${partNumber} not available`);
						setInfoErrorState(true);
						const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded"));
						if (subsetLoaded) {
							dispatch(infoAction.reset());
							dispatch(infoAction.setPartNumberForSubset(partNumber));
						}
					} else {
						dispatch(findPartAction.setPartFound(false));
					}
				}
			},
			APIError
		);
	};

	useEffect(() => {
		const partNumber = window.sessionStorage.getItem("PartNumber");

		if (partNumber) {
			changePart(partNumber);
		} else {
			dispatch(findPartAction.setShowFindPart(true));
		}
		// Only required to run this once or when subset loaded from the same page
	}, [reloadPartNumber]); // eslint-disable-line react-hooks/exhaustive-deps

	const refreshPart = () => {
		changePart(info.itemname);
		if (info.pref_code === "?" || !info.pref_code) {
			setErrorMsg(
				"Parts with PRef code ? or no PRef code require new PRef code. Please edit the note in note tab and add appropriate code to the note. Follow up with request to the CR with change request"
			);
			setInfoErrorState(true);
		}
	};

	const acceptPart = () => {
		const data = {
			partnumber: info.itemname,
			userid: userNTID,
			userorg: selectedNoteMultiOrg,
			leg_cat_no: info.cat_code.substring(info.cat_code.indexOf("[") + 1, info.cat_code.indexOf("]")),
			icat: info.icat_id,
			note: "Acceptable part",
			prstat: 1,
			lrepl01: "False",
			lrepl02: "False",
		};

		fetchData2(
			{
				url: `${APIURL}Note/createNote`,
				method: "POST",
				headers: headers,
				body: data,
			},
			refreshPart,
			APIError
		);
	};

	const qUpdate = () => {
		const data = {
			partnumber: info.itemname,
			userid: userNTID,
			userorg: selectedNoteMultiOrg,
		};
		fetchData2(
			{
				url: `${APIURL}Note/editNote`,
				method: "POST",
				headers: headers,
				body: data,
			},
			refreshPart,
			APIError
		);
	};

	const handleShowAcceptQupdate = () => {
		setShowAcceptQupdate(!showAcceptQupdate);
	};

	return (
		<Fragment>
			<FullScreenLoadingIndicator loading={isLoading || isLoading2} />
			<ErrorBox msg={errorMsg} setErrorState={setInfoErrorState} ErrorState={infoErrorState} />
			<AcceptAndQupdatePart open={showAcceptQupdate} handleShowAcceptQupdate={handleShowAcceptQupdate} acceptPart={acceptPart} qUpdate={qUpdate} />

			<InfoHeader />

			<Box mt={{ xs: "165px", sm: "165px", md: "110px", lg: "65px" }}>
				<CardWrapper sx={{ maxWidth: { xs: "100%", sm: "85%" } }}>
					<Box mb={2}>
						<InfoPartDetails />
						<InfoQuantityAndCost />
					</Box>

					<AMLASLTable />

					<OrgTable />

					<Box mt={2}>
						<ColoredTextField
							backgroundColor={note.noteBackgroundColor}
							focused
							mt={2}
							id="outlined-multiline-static"
							label="Part Note"
							fullWidth
							multiline
							inputProps={{ readOnly: true }}
							InputProps={{ sx: { marginTop: "2px" } }}
							value={note.note}
						/>
					</Box>
				</CardWrapper>
			</Box>
			<BottomNavigation changePart={changePart} acceptPart={acceptPart} qUpdate={qUpdate} handleShowAcceptQupdate={handleShowAcceptQupdate} />
		</Fragment>
	);
};

export default Info;
