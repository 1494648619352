// Packages Import
import { Fragment, useState, useEffect } from "react";
import { Box, Paper, Tooltip, Typography, Button, Modal, Stack, Grid, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Project Component
import ColoredTextField from "../../../UI/ColoredTextField";
import DropdownFilter from "../../../UI/DropdownFilter";
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
import { APIURL, PDF_GEN_JWT_TOKEN_URL, PDF_GEN_URL, OSAltKey, isNumber } from "../../../Util/Util";
import SetFileName from "../../../Util/SetFileName";

let inputTimeOut;

const ResponsibilityMlarReport = (props) => {
	const dispatch = useDispatch();
	const getBomList =
		typeof props.getBomList === "function"
			? props.getBomList
			: () => {
					return;
			  };
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const [bomListLoad, setBomListLoad] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.reportsState.reports.mlar.responsibility);
	const showPopup = useSelector((state) => state.reportsState.popUpStates.mlar.responsibility);
	const orgList = useSelector((state) => state.reportsState.orgList);
	const bomList = useSelector((state) => state.reportsState.bomList);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};

	const handleOrgChange = (value) => {
		dispatch(
			reportsActions.updateData({
				data: {
					userOrg: value,
				},
			})
		);
		getBomList(data.daysBack, value, setBomListLoad);
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
				setLoading(false);
			});
	};
	const generateReport = () => {
		closeHandler();
		setLoading(true);
		const jsonReq = {
			mlar_ppn: data.selectedBom?.ppn ?? "",
			mlar_prev: data.selectedBom?.prev ?? "",
			userid: userInfo.id,
			userOrg: data.userOrg,
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}reports/mlarResponsibilityReport`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(data) => {
				if (data?.No_Record) {
					throw Error(data.No_Record);
				}
				fetchDataWithoutLoad(
					{
						url: PDF_GEN_JWT_TOKEN_URL,
						headers: headers,
						method: "POST",
						body: {
							username: "admin",
							password: "pass",
						},
					},
					(token) => handleJWTToken(token, data),
					(error) => {
						setLoading(false);
						if (typeof props.onError === "function") {
							props.onError(error.toString());
						}
					}
				);
			},
			(error) => {
				setLoading(false);
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
			}
		);
	};

	const closeHandler = () => {
		dispatch(reportsActions.updatePopUpState());
		dispatch(reportsActions.reset());
	};

	const handleDayNum = (event) => {
		const inpVal = event.target.value;
		if (isNumber(inpVal) || inpVal === "") {
			dispatch(
				reportsActions.updateData({
					data: {
						daysBack: inpVal,
					},
				})
			);

			if (inpVal !== "") {
				clearTimeout(inputTimeOut);
				const timeoutSec = 500;
				inputTimeOut = setTimeout(() => {
					getBomList(inpVal, data.userOrg, setBomListLoad);
				}, timeoutSec);
			}
		}
	};
	const handleMLARBOMChanged = (newValue) => {
		dispatch(
			reportsActions.updateData({
				data: {
					selectedBom: newValue,
					error: {
						...data.error,
						mlarBom: newValue === null ? true : false,
					},
					errorText: {
						...data.errorText,
						mlarBom: "",
					},
				},
			})
		);
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName
				blob={blob}
				setOpenSetFileName={setOpenSetFileName}
				openSetFileName={openSetFileName}
				fileName={fileName}
			/>
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								closeHandler();
							}
							if (e.code === "KeyP") {
								if (!data.error.mlarBom) {
									generateReport();
								}
							}
						}
					}}
				>
					<Stack mb={2} spacing={1}>
						<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
							<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
								Responsibility Report
							</Typography>
							<DropdownFilter
								width={90}
								selections={orgList}
								selectedData={data.userOrg}
								handleSelection={handleOrgChange}
								label={"User Org"}
								InputLabelProps={{
									shrink: true,
									sx: {
										fontWeight: 700,
									},
								}}
							/>
						</Box>
						<Paper sx={{ p: 2 }} elevation={5}>
							<Typography variant="subtitle">
								Pick (MLAR) board number from the drop-down list. Change the days back to refresh the board number
								according to the number of days back from today temporarily or change it under tools {">"} days back
							</Typography>
						</Paper>

						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								pt: 3,
							}}
						>
							<Grid
								item
								xl={2}
								lg={2}
								md={2}
								sm={2}
								xs={12}
								sx={{
									pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
								}}
							>
								<ColoredTextField
									label="Days Back"
									fullWidth
									focused
									onChange={handleDayNum}
									value={data.daysBack}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Grid>
							<Grid
								item
								xl={10}
								lg={10}
								md={10}
								sm={10}
								xs={12}
								sx={{
									pt: { xs: 1.5, sm: 0, md: 0, lg: 0, xl: 0 },
								}}
							>
								<Autocomplete
									loading={bomListLoad}
									loadingText={
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<CircularProgress color="inherit" size={"20%"} />
										</Box>
									}
									options={!bomListLoad ? bomList : []}
									value={data.selectedBom}
									getOptionLabel={(option) => `${option.ppn}`}
									renderOption={(props, option) => {
										return (
											<li {...props}>
												<Box>
													<Typography
														align="center"
														width={{ xs: "60px", sm: "130px" }}
														variant="body2"
													>{`${option.ppn}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={1.5} minWidth={"35px"} variant="body2">{`${option.prev}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={1.5} variant="body2">{`${option.date}`}</Typography>
												</Box>
												<Box borderLeft={"1px Solid"} ml={2}>
													<Typography pl={1.5} variant="body2">{`${option.org}`}</Typography>
												</Box>
											</li>
										);
									}}
									renderInput={(params) => (
										<ColoredTextField
											label={<b>choose MLAR/BOM #</b>}
											focused
											error={data.error.mlarBom}
											helperText={data.errorText.mlarBom}
											{...params}
										/>
									)}
									isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
									onChange={(event, newValue) => handleMLARBOMChanged(newValue)}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								p: 0,
							}}
						>
							<Grid
								item
								xl={6.1}
								lg={6.1}
								md={6.1}
								sm={6.1}
								xs={6.1}
								sx={{
									pr: 1,
								}}
							>
								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
								<Tooltip title={`For shortcut press ${OSAltKey} + P`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={generateReport} disabled={data.error.mlarBom}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>P</u>rint PDF
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
						<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default ResponsibilityMlarReport;
