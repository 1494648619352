import { createSlice } from "@reduxjs/toolkit";
import { storePartHistories } from "../../Components/Util/Util";
import { Decrypt_Local_Storage } from "../../SSO/AESJSONFormatter";

const RoHSUpdateLogic = (value) => value.wcapbftft === "No" || value.wcm66t === "No" || value.wct260degt === "No";

const partNumberFilter = (newFilterRecords, partNumberInput) =>
	newFilterRecords.filter((value) => value.cpn.startsWith(partNumberInput.toUpperCase()));

const myPartsFilter = (newFilterRecords, userNTID) =>
	newFilterRecords.filter((value) => {
		const lname = value.lname;
		const fullname = value.fullname;
		return userNTID.toUpperCase() === lname?.toUpperCase()?.trim() || !fullname || fullname?.includes("_To be") || fullname?.includes("No entry");
	});

const code4Filter = (newFilterRecords) => newFilterRecords.filter((value) => value.pref_code?.startsWith("4"));

const opTempFilter = (newFilterRecords) => newFilterRecords.filter((value) => value.wcmaxotemp === "No");

const updateRecords = ({ originalFilterRecords, filterValues }) => {
	const { partNumberInput, myPartsChecked, code4Checked, noteRequiredUpdate, RoHSRequiredUpdate, RoHSNotesLogicSelection, OpTempChecked } =
		filterValues;
	const userNTID = Decrypt_Local_Storage("userNTID");
	let newFilterRecords = originalFilterRecords;

	if (partNumberInput) {
		newFilterRecords = partNumberFilter(newFilterRecords, partNumberInput);
	}

	if (myPartsChecked) {
		newFilterRecords = myPartsFilter(newFilterRecords, userNTID);
	}

	if (code4Checked) {
		newFilterRecords = code4Filter(newFilterRecords);
	}

	if (RoHSNotesLogicSelection === "Or" && noteRequiredUpdate && RoHSRequiredUpdate) {
		newFilterRecords = newFilterRecords.filter((value) => value.note_ok === "N" || RoHSUpdateLogic(value));
	} else {
		if (noteRequiredUpdate) {
			newFilterRecords = newFilterRecords.filter((value) => value.note_ok === "N");
		}
		if (RoHSRequiredUpdate) {
			newFilterRecords = newFilterRecords.filter((value) => RoHSUpdateLogic(value));
		}
	}

	if (OpTempChecked) {
		newFilterRecords = opTempFilter(newFilterRecords);
	}

	return newFilterRecords;
};

const getOriginalFilterRecords = () => {
	return window.sessionStorage.getItem("originalFilterRecords") ? JSON.parse(window.sessionStorage.getItem("originalFilterRecords")) : [];
};

const saveDetails = (newFilterRecords) => {
	window.sessionStorage.setItem("filterRecords", JSON.stringify(newFilterRecords));
	const filteredPartNumbers = newFilterRecords.map((data) => data.cpn);
	window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(filteredPartNumbers));
};

const savedFilterRecords = JSON.parse(window.sessionStorage.getItem("filterRecords"));
const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : [];

const InitialState = {
	partFound: true,
	showFindPart: false,
	selectedSubsetOrg: "",
	bomList: [],
	selectedBom: null,
	MTLEngOrgList: [],
	selectedMTLEngOrg: null,
	originalMTLEngList: [],
	MTLEngList: [],
	selectedMTLEng: null,
	toBeDetermined: false,

	// BOMFILTERMENU
	filterRecords: savedFilterRecords || [],
	partNumberInput: filterValues.partNumberInput || "",
	myPartsChecked: filterValues.myPartsChecked || false,
	code4Checked: filterValues.code4Checked || false,
	noteRequiredUpdate: filterValues.noteRequiredUpdate || false,
	RoHSRequiredUpdate: filterValues.RoHSRequiredUpdate || false,
	RoHSNotesLogicSelection: filterValues.RoHSNotesLogicSelection || "And",
	OpTempChecked: filterValues.OpTempChecked || false,
};

const findPartSlice = createSlice({
	name: "findPart",
	initialState: InitialState,
	reducers: {
		setPartFound(state, action) {
			state.partFound = action.payload;
		},
		setShowFindPart(state, action) {
			state.showFindPart = action.payload;
		},

		// Subset
		setSelectedSubsetOrg(state, action) {
			window.sessionStorage.setItem("selectedSubsetOrg", JSON.stringify(action.payload));
			state.selectedSubsetOrg = action.payload;
		},

		// BOM
		setBomList(state, action) {
			const data = action.payload.BOM_LIST;
			state.bomList = data.map((data) => {
				return { date: data.dadd, ppn: data.ppn, prev: data.prev, org: data.user_org_c };
			});
		},
		setSelectedBom(state, action) {
			state.selectedBom = action.payload;
		},
		resetBomList(state) {
			state.bomList = InitialState.bomList;
			// state.selectedBom = InitialState.selectedBom;
		},

		// MTL ENG
		setMtlEngList(state, action) {
			const data = action.payload.USERS_LIST;
			state.originalMTLEngList = data.map((data) => {
				return {
					userid: data.userid,
					username: data.username,
					useremail: data.useremail,
					user_org_c: data.user_org_c,
				};
			});
			state.MTLEngOrgList = [...new Set(state.originalMTLEngList.map((data) => data.user_org_c))].sort();
			state.selectedMTLEngOrg = state.MTLEngOrgList[0];
			state.MTLEngList = state.originalMTLEngList.filter((data) => data.user_org_c === state.selectedMTLEngOrg);
		},
		setSelectedMTLEngOrg(state, action) {
			state.selectedMTLEngOrg = action.payload;
			if (state.selectedMTLEngOrg) {
				state.MTLEngList = state.originalMTLEngList.filter((data) => data.user_org_c === state.selectedMTLEngOrg);
			}
		},
		setSelectedMTLEng(state, action) {
			state.selectedMTLEng = action.payload;
		},
		resetMTLEngList(state) {
			state.MTLEngList = InitialState.MTLEngList;
		},
		setToBeDetermined(state, action) {
			state.toBeDetermined = !state.toBeDetermined;
		},

		// Filter Panel
		setFilterRecords(state, action) {
			const FILTER_RECORDS = action.payload.data.FILTER_RECORDS;
			const ppn = action.payload.ppn;
			const filterRecords = FILTER_RECORDS.map((data) => {
				return {
					fullname: data.fullname,
					lname: data.lname,
					mlar_no: data.mlar_no,
					prev: data.prev,
					cpn: data.cpn,
					cat_code: data.cat_code,
					icat_id: data.icat_id,
					pref_code: data.pref_code,
					note_dupt: data.note_dupt,
					note_ok: data.note_ok,
					wcapbftft: data.wcapbftft,
					wcm66t: data.wcm66t,
					wct260degt: data.wct260degt,
					wcmaxotemp: data.wcmaxotemp,
				};
			});
			state.filterRecords = filterRecords.sort((a, b) => a.cpn.localeCompare(b.cpn));
			window.sessionStorage.setItem("filterRecords", JSON.stringify(filterRecords));
			window.sessionStorage.setItem("originalFilterRecords", JSON.stringify(filterRecords));

			const partNumbers = state.filterRecords.map((data) => data.cpn);
			storePartHistories(partNumbers[0]);
			window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(partNumbers));
			window.sessionStorage.setItem("subsetName", JSON.stringify(`${ppn}`));
		},

		resetFilter(state) {
			const originalFilterRecords = getOriginalFilterRecords();
			window.sessionStorage.setItem("filterRecords", JSON.stringify(originalFilterRecords));
			const allPartNumbers = originalFilterRecords.map((data) => data.cpn);

			state.partNumberInput = "";
			state.myPartsChecked = false;
			state.code4Checked = false;
			state.noteRequiredUpdate = false;
			state.RoHSRequiredUpdate = false;
			state.RoHSNotesLogicSelection = "And";
			state.OpTempChecked = false;
			window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(allPartNumbers));
			window.sessionStorage.setItem("filterValues", JSON.stringify({}));
		},

		setPartNumberInput(state, action) {
			state.partNumberInput = action.payload;
		},

		handleFilterPartNumber(state) {
			const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : {};
			window.sessionStorage.setItem("filterValues", JSON.stringify({ ...filterValues, partNumberInput: state.partNumberInput }));

			const originalFilterRecords = getOriginalFilterRecords();

			const { partNumberInput, ...rest } = filterValues;
			let newFilterRecords = updateRecords({ originalFilterRecords, filterValues: rest });

			newFilterRecords = partNumberFilter(newFilterRecords, state.partNumberInput);

			saveDetails(newFilterRecords);
		},

		setMyPartChecked(state, action) {
			state.myPartsChecked = !state.myPartsChecked;
			const userNTID = Decrypt_Local_Storage("userNTID");

			const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : {};
			window.sessionStorage.setItem("filterValues", JSON.stringify({ ...filterValues, myPartsChecked: state.myPartsChecked }));

			const originalFilterRecords = getOriginalFilterRecords();

			const { myPartsChecked, ...rest } = filterValues;
			let newFilterRecords = updateRecords({ originalFilterRecords, filterValues: rest });

			if (state.myPartsChecked) {
				newFilterRecords = myPartsFilter(newFilterRecords, userNTID);
			}

			saveDetails(newFilterRecords);
		},

		setCode4Checked(state) {
			state.code4Checked = !state.code4Checked;
			state.OpTempChecked = false;

			const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : {};
			const newFilterValues = { ...filterValues, code4Checked: state.code4Checked, OpTempChecked: false };
			window.sessionStorage.setItem("filterValues", JSON.stringify(newFilterValues));

			const originalFilterRecords = getOriginalFilterRecords();

			const { code4Checked, ...rest } = newFilterValues;
			let newFilterRecords = updateRecords({ originalFilterRecords, filterValues: rest });

			if (state.code4Checked) {
				newFilterRecords = code4Filter(newFilterRecords);
			}

			saveDetails(newFilterRecords);
		},

		setNoteRequiredUpdate(state) {
			state.noteRequiredUpdate = !state.noteRequiredUpdate;
			state.OpTempChecked = false;

			const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : {};
			const newFilterValues = { ...filterValues, noteRequiredUpdate: state.noteRequiredUpdate, OpTempChecked: false };
			window.sessionStorage.setItem("filterValues", JSON.stringify(newFilterValues));

			const originalFilterRecords = getOriginalFilterRecords();

			let newFilterRecords = updateRecords({ originalFilterRecords, filterValues: newFilterValues });

			saveDetails(newFilterRecords);
		},

		setRoHSRequireAction(state) {
			state.RoHSRequiredUpdate = !state.RoHSRequiredUpdate;
			state.OpTempChecked = false;

			const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : {};
			const newFilterValues = { ...filterValues, RoHSRequiredUpdate: state.RoHSRequiredUpdate, OpTempChecked: false };
			window.sessionStorage.setItem("filterValues", JSON.stringify(newFilterValues));

			const originalFilterRecords = getOriginalFilterRecords();

			let newFilterRecords = updateRecords({ originalFilterRecords, filterValues: newFilterValues });

			saveDetails(newFilterRecords);
		},

		setRoHSNotesLogic(state, action) {
			state.RoHSNotesLogicSelection = action.payload;

			const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : {};
			const newFilterValues = { ...filterValues, RoHSNotesLogicSelection: state.RoHSNotesLogicSelection };
			window.sessionStorage.setItem("filterValues", JSON.stringify(newFilterValues));

			const originalFilterRecords = getOriginalFilterRecords();

			let newFilterRecords = updateRecords({ originalFilterRecords, filterValues: newFilterValues });

			saveDetails(newFilterRecords);
		},

		setOpTempChecked(state) {
			state.OpTempChecked = !state.OpTempChecked;
			if (state.OpTempChecked) {
				state.code4Checked = false;
				state.noteRequiredUpdate = false;
				state.RoHSRequiredUpdate = false;
			}

			const filterValues = window.sessionStorage.getItem("filterValues") ? JSON.parse(window.sessionStorage.getItem("filterValues")) : {};
			const newFilterValues = {
				...filterValues,
				OpTempChecked: state.OpTempChecked,
				code4Checked: false,
				noteRequiredUpdate: false,
				RoHSRequiredUpdate: false,
			};
			window.sessionStorage.setItem("filterValues", JSON.stringify(newFilterValues));

			const originalFilterRecords = getOriginalFilterRecords();

			const { OpTempChecked, ...rest } = newFilterValues;
			let newFilterRecords = updateRecords({ originalFilterRecords, filterValues: rest });

			if (state.OpTempChecked) {
				newFilterRecords = opTempFilter(newFilterRecords);
			}

			saveDetails(newFilterRecords);
		},
	},
});

export const findPartAction = findPartSlice.actions;

export default findPartSlice;
