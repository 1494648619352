// React
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { iCatAction } from "../../../store/common/ICat-slice";
// UI
import { Box, Modal, AppBar, Toolbar, Typography, Grid, Button, Autocomplete, Tooltip } from "@mui/material";
import ColoredTextField from "../../UI/ColoredTextField";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../Util/ErrorBox";
import MessageBox from "../../Util/MessageBox";
import ConfirmationBox from "../../Util/ConfirmationBox";
// Package Import
import useHttp from "../../../Hooks/use-http";
import { APIURL, OSAltKey } from "../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: "90%", md: "90%", lg: "80%", xl: "70%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const Icat = (props) => {
	const dispatch = useDispatch();
	const { open, partNumber, handleOpenIcat, prefCode } = props;

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const userName = useSelector((state) => state.authenticationState.userName);

	const icatOptions = useSelector((state) => state.iCatState.icatOptions);
	const oracleiCat = useSelector((state) => state.iCatState.oracleiCat);
	const oracleiCatID = useSelector((state) => state.iCatState.oracleiCatID);
	const newIcat = useSelector((state) => state.iCatState.newIcat);
	const newIcatID = useSelector((state) => state.iCatState.newIcatID);
	const iCatLookup = useSelector((state) => state.iCatState.iCatLookup);

	const [openHowItWorks, setOpenHowItWorks] = useState(false);
	const [openNewiCatEmpty, setOpenNewiCatEmpty] = useState(false);
	const [openSameICat, setOpenSameICat] = useState(false);
	const [openSamePendingICat, setOpenPendingSameICat] = useState(false);
	const [openConfirmOverwriteICat, setOpenConfirmOverwriteICat] = useState(false);
	const [openResult, setOpenResult] = useState(false);
	const [resultMessage, setResultMessage] = useState(false);

	const { sendRequest: fetchData, isLoading } = useHttp();
	const { sendRequest: fetchData2, isLoading: isLoading2 } = useHttp();
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json" , "Authorization": "Bearer " + accessToken }), [accessToken]);

	useEffect(() => {
		dispatch(iCatAction.reset());
		if (open) {
			fetchData(
				{ url: `${APIURL}chgRequest/categoryList`, headers },
				(data) => {
					const icatList = data?.CATEGORIES_LIST
						? data.CATEGORIES_LIST.map((data) => {
								return {
									icat: data.icat,
									icat_id: data.icat_id,
								};
						  })
						: [];
					dispatch(iCatAction.setIcatOptions(icatList.sort((a, b) => a.icat.localeCompare(b.icat))));

					fetchData2(
						{ url: `${APIURL}chgRequest/icatInfo/${partNumber}/`, headers },
						(data) => {
							console.log(data);
							dispatch(iCatAction.setIcatInfo(data));
						},
						(error) => {
							console.log(`ERROR: Unable to Retrieve iCat info, ${error}`);
							setErrorMsg(`ERROR: Unable to Retrieve iCat info, please re-open this popup and try again. ${error}`);
							setErrorState(true);
						}
					);
				},
				(error) => {
					console.log(`ERROR: Unable to Retrieve iCat list, ${error}`);
					setErrorMsg(`ERROR: Unable to Retrieve iCategory list, please re-open this popup and try again. ${error}`);
					setErrorState(true);
				}
			);
		}
	}, [dispatch, fetchData, fetchData2, headers, open, partNumber]);

	const handleIcatLookupChanged = (event, newValue) => {
		dispatch(iCatAction.setHandleICatLookup(newValue));
	};

	const HowItWorks = () => (
		<>
			<Typography>{`1) Enter new iCategory code`}</Typography>
			<Typography>{`2) Click Save...`}</Typography>
			<Typography>{`This request will be reviewed by MTL Engineering in Santa Rosa, at the end of current week`}</Typography>
			<Typography>{`this Change should be implemented with-in one of week.`}</Typography>
			<Typography>{`if you find that is not implemented please contact MTL engineering = Santa Rosa...`}</Typography>
		</>
	);

	const submitNewICat = () => {
		const body = {
			userid: userNTID,
			partnumber: partNumber,
			new_icat: iCatLookup.icat,
			new_icatid: iCatLookup.icat_id,
			oracle_icat: oracleiCat,
			oracleiCatID: oracleiCatID,
		};
		fetchData(
			{ url: `${APIURL}chgRequest/newIcatReq`, headers, method: "POST", body },
			(data) => {
				setResultMessage(data.Result);
				setOpenResult(true);
			},
			(error) => {
				console.log(`ERROR: Unable to Submit new iCat, ${error}`);
				setErrorMsg(`ERROR: Unable to Submit new iCat, please re-open this popup and try again. ${error}`);
			}
		);
	};

	const handleSaveClicked = () => {
		if (!iCatLookup && !iCatLookup?.icat_id && !iCatLookup?.icat) {
			setOpenNewiCatEmpty(true);
		} else if (iCatLookup && iCatLookup?.icat_id.toString() === oracleiCatID && iCatLookup?.icat === oracleiCat) {
			setOpenSameICat(true);
		} else if (iCatLookup && iCatLookup?.icat_id.toString() === newIcatID && iCatLookup?.icat === newIcat) {
			setOpenPendingSameICat(true);
		} else if (iCatLookup && iCatLookup?.icat_id && iCatLookup?.icat && newIcat && newIcatID) {
			setOpenConfirmOverwriteICat(true);
		} else {
			submitNewICat();
		}
	};

	return (
		<Box>
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<FullScreenLoadingIndicator loading={isLoading || isLoading2} />
			<MessageBox open={openHowItWorks} message={<HowItWorks />} messageBoxHandler={() => setOpenHowItWorks(false)} />
			<MessageBox
				open={openNewiCatEmpty}
				message={"New icat code field is empty, Please select new iCat"}
				messageBoxHandler={() => setOpenNewiCatEmpty(false)}
			/>
			<MessageBox
				open={openSameICat}
				message={
					"New - requested iCAT is the same as 'old' Oracle iCAT code. This is invalid request. Please change requested iCAT or Cancel this request."
				}
				messageBoxHandler={() => setOpenSameICat(false)}
			/>
			<MessageBox
				open={openSamePendingICat}
				message={
					"New - requested iCAT is the same as 'Pending' new iCAT code. This is invalid request. Please change requested iCAT or Cancel this request."
				}
				messageBoxHandler={() => setOpenPendingSameICat(false)}
			/>
			<ConfirmationBox
				open={openConfirmOverwriteICat}
				handleYes={() => {
					setOpenConfirmOverwriteICat(false);
					submitNewICat();
				}}
				handleNo={() => setOpenConfirmOverwriteICat(false)}
				message={`There is still open/pending change request of the iCAT code for this part. Do you want to overwrite this request with this new one?. Current request is for PC=${newIcatID} | ${newIcat}`}
			/>
			<MessageBox
				open={openResult}
				message={resultMessage}
				messageBoxHandler={() => {
					setOpenResult(false);
					handleOpenIcat();
				}}
			/>

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyS" && e.altKey === true) {
							e.preventDefault();
							handleSaveClicked();
						} else if (e.code === "KeyC" && e.altKey === true) {
							e.preventDefault();
							handleOpenIcat();
						} else if (e.code === "KeyH" && e.altKey === true) {
							e.preventDefault();
							setOpenHowItWorks(true);
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Request for new iCategory code"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Grid mb={2} mt={6} container direction="row" justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={2} wrap={"wrap"}>
						<Grid item xl={4.5} lg={4.5} md={4.5} sm={12} xs={12}>
							<ColoredTextField focused fullWidth label="for Keysight PN" value={partNumber} inputProps={{ readOnly: true }} />
						</Grid>
						<Grid item xl={2.5} lg={2.5} md={2.5} sm={12} xs={12}>
							<ColoredTextField focused fullWidth label="Pref. code" value={prefCode} inputProps={{ readOnly: true }} />
						</Grid>
						<Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
							<ColoredTextField
								focused
								fullWidth
								label="Requestor"
								value={userName}
								inputProps={{ readOnly: true }}
								InputProps={{ sx: { marginTop: "2px" } }}
								backgroundColor={"rgba(225, 255, 0, 0.5)"}
							/>
						</Grid>

						<Grid item xl={2.5} lg={2.5} md={2.5} sm={12} xs={12}>
							<ColoredTextField focused fullWidth label="Current iCat Oracle ID" value={oracleiCatID} inputProps={{ readOnly: true }} />
						</Grid>
						<Grid item xl={4.5} lg={4.5} md={4.5} sm={12} xs={12}>
							<Tooltip title={oracleiCat || ""}>
								<ColoredTextField focused fullWidth label="Current iCategory" value={oracleiCat} inputProps={{ readOnly: true }} />
							</Tooltip>
						</Grid>

						{
							<Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
								<ColoredTextField
									focused
									fullWidth
									label={"pending Request"}
									inputProps={{ readOnly: true }}
									InputProps={{ sx: { marginTop: "2px" } }}
									value={`${newIcatID} | ${newIcat}`}
									backgroundColor={"rgba(128, 255, 128, 0.8)"}
								/>
							</Grid>
						}

						<Grid item xl={2.5} lg={2.5} md={2.5} sm={12} xs={12}>
							<ColoredTextField focused fullWidth label="New iCat Oracle ID" value={iCatLookup?.icat_id || ""} inputProps={{ readOnly: true }} />
						</Grid>
						<Grid item xl={4.5} lg={4.5} md={4.5} sm={12} xs={12}>
							<Tooltip title={iCatLookup?.icat || ""}>
								<ColoredTextField focused fullWidth label="New iCat Code" value={iCatLookup?.icat || ""} inputProps={{ readOnly: true }} />
							</Tooltip>
						</Grid>
						<Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
							<Autocomplete
								options={icatOptions}
								value={iCatLookup}
								getOptionLabel={(option) => `${option.icat_id} | ${option.icat}`}
								renderOption={(props, option) => {
									return (
										<li {...props}>
											<Box sx={{ display: "flex" }}>
												<Typography align="right" width={"50px"}>{`${option.icat_id}`}</Typography>
											</Box>
											<Box borderLeft={"1px Solid"} ml={2}>
												<Typography pl={2}>{`${option.icat}`}</Typography>
											</Box>
										</li>
									);
								}}
								renderInput={(params) => <ColoredTextField label={"iCategory lookup"} focused {...params} />}
								isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
								onChange={handleIcatLookupChanged}
							/>
						</Grid>
					</Grid>

					<Box>
						<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
							<Button onClick={handleSaveClicked}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ave
							</Button>
						</Tooltip>
						<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
							<Button onClick={handleOpenIcat}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
						</Tooltip>
						<Tooltip title={`For shortcut press ${OSAltKey} + H`}>
							<Button onClick={() => setOpenHowItWorks(true)}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>H</u>elp/Info
							</Button>
						</Tooltip>
					</Box>
					<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
				</Box>
			</Modal>
		</Box>
	);
};

export default Icat;
