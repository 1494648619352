import { createSlice } from "@reduxjs/toolkit";
import { saveOrgIntoSessionStorage, storePartHistories, orgList } from "../../Components/Util/Util";

const defaultColumnVisibility = {
	asl_disab: true,
	asl_st_cd: true,
	mcc66: true,
	mfg_pn: true,
	ow_min_qty: true,
	owg_org_cd: true,
	owg_plt: true,
	rohs: true,
	us_min_qty: true,
	usg_plt: true,
	vend_bus: true,
	vend_name: true,
	vend_no: true,
};

const columnVisibilityStorage = JSON.parse(window.localStorage.getItem("infoAMLASLColumnVisibility"));

const initialInfoState = {
	fullData: [], // Storing full data to swap between different org if there is any
	OrgList: [], // For the drop down list of Org
	org: "", // Currently selected org, default to "F01" if no org found in session storage
	info: [], // ITEM_INFO of the selected org
	AML_ASL_rows: [], // AML_ASL table data
	orgRows: [], // Org table rows
	note: "", // Part Note
	orgNote: {}, // requirement with Multi Org support
	selectedNoteMultiOrg: "",
	reloadPartNumber: 0, // A useEffect dependency to trigger change part after user loaded subset from info tab itself
	classification: [],
	countryHTS: [],
	tableColumnVisibility: columnVisibilityStorage ? columnVisibilityStorage : defaultColumnVisibility,
};

const infoSlice = createSlice({
	name: "info",
	initialState: initialInfoState,
	reducers: {
		reset: (state) => {
			return { ...initialInfoState, tableColumnVisibility: state.tableColumnVisibility };
		},
		retrievePart(state, action) {
			state.fullData = action.payload.data;
			state.OrgList = state.fullData.ITEM_INFO.map((infoData) => infoData.orgcode);
			// Do not get org from session storage from here as it will causes side effect
			state.org = state.OrgList[0];
			state.info = state.fullData.ITEM_INFO.find((infoData) => infoData.orgcode === state.org);
			state.MtlEngInfo = state.fullData.MTLENG_INFO;
			state.AML_ASL_rows = [...state.fullData.AML_INFO, ...state.fullData.ASL_INFO].map((tableData, index) => {
				return {
					id: index,
					usg_plt: tableData.usg_plt ? tableData.usg_plt : "0",
					us_min_qty: tableData.us_min_qty ? tableData.us_min_qty : "0",
					owg_plt: tableData.owg_plt ? tableData.owg_plt : "0",
					ow_min_qty: tableData.ow_min_qty ? tableData.ow_min_qty : "0",
					...tableData,
				};
			});

			state.orgRows = state.fullData.ORG_INFO.map((orgs, index) => {
				return { id: index, ...orgs };
			});

			const { country_hts, ...classification } = state.fullData.CLASSIFICATION;

			state.classification = classification;
			state.countryHTS =
				country_hts?.map((data, index) => {
					return {
						id: index,
						ccode: data.ccode,
						createdBy: data.creby,
						classDate: data.hs_classification_dt && new Date(data.hs_classification_dt?.replace("Z", "")).toLocaleDateString("en-US"),
						hts: data.hts,
					};
				}) || initialInfoState.countryHTS;

			const org = action.payload.org;
			const divNote = state.info.Notes?.find((note) => note?.user_org_c === org) || "";
			const note = divNote.note || "";

			// For border Colors
			const dateUpdated = new Date(divNote.dupt);
			const dateDifference = dateUpdated !== "Invalid Date" && (new Date().getTime() - dateUpdated.getTime()) / (1000 * 60 * 60 * 24);

			let noteBackgroundColor = "rgb(255, 255, 255)";

			console.log(!isNaN(dateDifference) && `note is ${dateDifference} days old`);
			if (state.info.pref_code === "1" || state.info.pref_code === "2") {
				if (!isNaN(dateDifference) && dateDifference >= 365) {
					noteBackgroundColor = "rgba(255, 0, 0, 0.3)";
				} else if (!isNaN(dateDifference) && dateDifference >= 351) {
					noteBackgroundColor = "rgba(255,165,0, 0.8)";
				} else if (!isNaN(dateDifference) && dateDifference < 351) {
					noteBackgroundColor = "rgba(128, 255, 128, 0.8)";
				}
			} else {
				if (!isNaN(dateDifference) && dateDifference >= 182) {
					noteBackgroundColor = "rgba(255, 0, 0, 0.3)";
				} else if (!isNaN(dateDifference) && dateDifference >= 168) {
					noteBackgroundColor = "rgba(255,165,0, 0.8)";
				} else if (!isNaN(dateDifference) && dateDifference < 351) {
					noteBackgroundColor = "rgba(128, 255, 128, 0.8)";
				}
			}

			state.note = { note, noteBackgroundColor };

			storePartHistories(state.info.itemname);
		},

		changeOrgNote(state, action) {
			const selectedOrg = action.payload;
			state.selectedNoteMultiOrg = selectedOrg;
			const divNote = state.info.Notes?.find((note) => note?.user_org_c === selectedOrg) || "";
			const note = divNote.note || "";
			const acceptPartDisabled = divNote ? true : false;
			const qUpdateDisabled = divNote ? false : true;
			const prefCodeStartsWith4 = state.info.pref_code?.startsWith("4") && divNote === "";

			// For textfield background colors
			const dateUpdated = new Date(divNote.dupt);
			const dateDifference = dateUpdated !== "Invalid Date" && (new Date().getTime() - dateUpdated.getTime()) / (1000 * 60 * 60 * 24);

			let noteBackgroundColor = "rgb(255, 255, 255)";

			console.log(!isNaN(dateDifference) && `note is ${dateDifference} days old`);
			if (state.info.pref_code === "1" || state.info.pref_code === "2") {
				if (!isNaN(dateDifference) && dateDifference >= 365) {
					noteBackgroundColor = "rgba(255, 0, 0, 0.3)";
				} else if (!isNaN(dateDifference) && dateDifference >= 351) {
					noteBackgroundColor = "rgba(255,165,0, 0.8)";
				} else if (!isNaN(dateDifference) && dateDifference < 351) {
					noteBackgroundColor = "rgba(128, 255, 128, 0.8)";
				}
			} else {
				if (!isNaN(dateDifference) && dateDifference >= 182) {
					noteBackgroundColor = "rgba(255, 0, 0, 0.3)";
				} else if (!isNaN(dateDifference) && dateDifference >= 168) {
					noteBackgroundColor = "rgba(255,165,0, 0.8)";
				} else if (!isNaN(dateDifference) && dateDifference < 351) {
					noteBackgroundColor = "rgba(128, 255, 128, 0.8)";
				}
			}

			state.orgNote = { note, acceptPartDisabled, qUpdateDisabled, noteBackgroundColor, prefCodeStartsWith4 };
		},

		changeOrg(state, action) {
			const allOrgList = [...new Set([...state.OrgList, ...orgList])];
			// Prevent org from session storage begin selected when it is not available in the drop down list which
			// Will throw a warning
			state.org = allOrgList.includes(action.payload) ? action.payload : "";

			saveOrgIntoSessionStorage(action.payload);
			state.info = state.fullData.ITEM_INFO.find((infoData) => infoData.orgcode === state.org);
			if (!state.info) {
				const defaultInfo = state.fullData.ITEM_INFO[0];
				state.info = {
					itemname: defaultInfo.itemname,
					item_desc: defaultInfo.item_desc,
					cat_code: defaultInfo.cat_code,
					icat_id: defaultInfo.icat_id,
					pref_code: defaultInfo.pref_code,
					pref_desc: defaultInfo.pref_desc,
					Notes: defaultInfo.Notes,
				};
			}
		},

		setReloadPartNumber(state) {
			state.reloadPartNumber = state.reloadPartNumber + 1;
		},

		setPartNumberForSubset(state, action) {
			state.info = { itemname: action.payload };
		},

		setColumnVisibility(state, action) {
			state.tableColumnVisibility = action.payload.columnVisibility;
			window.localStorage.setItem("infoAMLASLColumnVisibility", JSON.stringify(action.payload.columnVisibility));
		},
	},
});

export const infoAction = infoSlice.actions;

export default infoSlice;
