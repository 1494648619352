// Packages Import
import React, { memo, useState , useEffect  } from "react"
import { Box, Grid, Button, Typography} from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// Project Component
import DropdownFilter from "../../UI/DropdownFilter"
import AutocompleteDropdown from "../../UI/AutocompleteDropdown"
import ColoredTextField from "../../UI/ColoredTextField";
import Datepicker from "../../UI/Datepicker"
import { projectsAction } from "../../../store/Projects/Projects-slice";

const filterSettingData = ["Date Added", "Date Completed"]



const SearchFilterArea = (props) => {
	const dispatch = useDispatch();
    const [refresh , setRefresh] = useState(false)
    const [initialPageLoad , setInitialPageLoad] = useState(false)
    const projects = useSelector((state) => state.projectsStates.PROJECT_LIST_FILTER)
    const filteredData = useSelector((state) => state.projectsStates.filteredData)
    const orgList = useSelector((state) => state.projectsStates.orgList)
    const projectSelected = filteredData.filterOnProject? {
        label: filteredData.filterOnProject,
        id: ""
    } : {
        label: "",
        id: ""
    }
    const startDate = moment(filteredData.startDate, "YYYY-MM-DD").isValid() ? filteredData.startDate : ""
    const endDate = moment(filteredData.endDate, "YYYY-MM-DD").isValid() ? filteredData.endDate : ""
    const userInfo = {
		org : useSelector((state) => state.authenticationState.User_Assigned_Orgs),
	}
    const handleSelection = (val , type) => {
        switch(type){
            case "name":
                const value = val !== null && val.label ? val.label : ""
                dispatch(projectsAction.updateFilteredData({ data: {filterOnProject: value}}))
                if(filteredData.filterOnBOM && value !== ""){
                    dispatch(projectsAction.updateFilteredData({ data: {filterOnBOM: ""}}))
                }
                break
            case "setting":
                dispatch(projectsAction.updateFilteredData({ data: {filterOn: val}}))
                break
            case "start":
                dispatch(projectsAction.updateFilteredData({ data: {startDate: moment(val).format("YYYY-MM-DD")}}))
                break
            case "end":
                dispatch(projectsAction.updateFilteredData({ data: {endDate: moment(val).format("YYYY-MM-DD")}}))
                break
            case "org":
                dispatch(projectsAction.updateFilteredData({ data: {userOrg: val}}))
                if(typeof props.getProjectsList === "function"){
                    props.getProjectsList(val , "filter")
                }
                break
            case "all":
                dispatch(projectsAction.updateFilteredData({data: val}))
                if(typeof props.getProjectsList === "function"){
                    if("userOrg" in val){
                        props.getProjectsList(val.userOrg , "filter")
                    }
                }
                break
            default:
                break

        }
    }
    const handleBom = (event) =>{
        dispatch(projectsAction.updateFilteredData({ data: {filterOnBOM: event.target.value}}))
        if(projectSelected.label){
            dispatch(projectsAction.updateFilteredData({ data: {filterOnProject: ""}}))
        }
    }

    const handleResetClick = () => {
        handleSelection({
            userOrg: userInfo.org.primaryOrg,
            filterOn: filterSettingData[0],
            startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            filterOnProject: "",
            filterOnBOM: "",
        },"all")
        setRefresh(true)
    }

    const handleApplyFilterClick = (initialPageLoad) => {
        if(typeof props.handleSearchFilterData === "function"){
            props.handleSearchFilterData(initialPageLoad)
        }
    }

    useEffect(() => {
        
        handleSelection({
            userOrg: sessionStorage.getItem("SearchFilterArea.userOrg") ?? userInfo.org.primaryOrg,
            filterOn: sessionStorage.getItem("SearchFilterArea.filterOn") ?? filterSettingData[0],
            startDate: sessionStorage.getItem("SearchFilterArea.startDate") ?? moment().subtract(1, "month").format("YYYY-MM-DD"),
            endDate: sessionStorage.getItem("SearchFilterArea.endDate") ?? moment().format("YYYY-MM-DD"),
            filterOnProject: sessionStorage.getItem("SearchFilterArea.filterOnProject") ?? "",
            filterOnBOM: sessionStorage.getItem("SearchFilterArea.filterOnBOM") ?? "",
        },"all") 
        setInitialPageLoad(true)
    },[])
    useEffect(() => {
        if(initialPageLoad){
            handleApplyFilterClick(initialPageLoad)
        }   
    },[initialPageLoad])

    useEffect(() => {
        if(refresh){
            handleApplyFilterClick(true)
            setRefresh(false)
        }
    },[refresh])
	return (
        <Box>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                wrap="wrap"
                sx={{mb:1.5}}>
                <Grid 
                    item 
                    xl={0.8} lg={1} md={1.5} sm={6} xs={12}
                    textAlign="center"
                >
                    <DropdownFilter 
                        selections={orgList}
                        handleSelection={handleSelection}
                        label="Org"
                        name="org"
                        selectedData={filteredData.userOrg}
                        highlightItemList={userInfo.org.primaryOrg}
                        highlightColor="green"				
                    />
                </Grid>
                    
                <Grid 
                    item 
                    xl={1.6} lg={1.6} md={2.5} sm={6} xs={12}
                    textAlign="center"
                >
                    <DropdownFilter 
                        label="Filter Setting"
                        selections={filterSettingData}
                        handleSelection={handleSelection}
                        name="setting"
                        selectedData={filteredData.filterOn}
                     />
                </Grid>
                <Grid 
                    item 
                    xl={1.35} lg={1.35} md={4} sm={6} xs={12}
                    textAlign="center"
                >
                    <Datepicker 
                        label="Start Date"
                        handleSelection={handleSelection}
                        val={startDate}
                        name="start"
                        disableFuture={true}
                        preventCut={true}
                     />
                     
                </Grid>
                <Grid 
                    item 
                    xl={1.35} lg={1.35} md={4} sm={6} xs={12}
                    textAlign="center"
                >
                    <Datepicker 
                        label="End Date"
                        handleSelection={handleSelection}
                        name="end"
                        val={endDate}
                        disableFuture={true}
                        preventCut={true}
                     />
                </Grid>
                <Grid 
                    item 
                    xl={2.6} lg={2.4} md={4.9} sm={9} xs={12}
                    textAlign="center"
                >
                    <AutocompleteDropdown 
                        label="Project Name"
                        selections={projects}
                        value={projectSelected}
                        handleSelection={handleSelection}
                        freeSolo = {true}
                        name="name"
                     />
                </Grid>
                <Grid 
                    item 
                    xl={0.3} lg={0.3} md={0.3} sm={3} xs={12}
                    textAlign="center"
                >
                    <Typography variant="caption">OR.</Typography>
                </Grid>
                <Grid 
                    item 
                    xl={2.5} lg={2.5} md={4.9} sm={9} xs={12}
                    textAlign="center"
                >
                    <ColoredTextField
                        label="Last Bom reviewed Search"
                        fullWidth
                        focused
                        onChange={handleBom}
                        value={filteredData.filterOnBOM}
                        InputLabelProps={{ 
                            shrink: true 
                        }}
                    />
                </Grid>
                <Grid 
                    item 
                    xl={1.5} lg={1.5} md={1.9} sm={3} xs={12}
                    textAlign="center"
                >
                    <Box>
                            <Button 
                                variant="contained"
                                onClick={handleApplyFilterClick}
                            >
                                Apply
                            </Button>
                            <Button 
                                variant="contained"
                                onClick ={handleResetClick}
                            >Reset</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
	)
}

export default memo(SearchFilterArea)