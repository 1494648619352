// React
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { wuBomAction } from "../../../../store/WuBom/WuBom-slice";
// UI
import { Box, Modal, Typography, Grid, FormControlLabel, Checkbox, Tooltip, Button, AppBar, Toolbar } from "@mui/material";
import CreateBOMSubset from "./CreateBOMSubset";
import SetFileName from "../../../Util/SetFileName";
import ErrorBox from "../../../Util/ErrorBox";
// Packages Import
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import { OSAltKey, APIURL, PDF_GEN_JWT_TOKEN_URL, PDF_GEN_URL } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: 650 },
	maxHeight: "90%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "auto",
	pt: 2,
	pb: 1,
	pl: { xs: 1, sm: 4 },
	pr: { xs: 1, sm: 4 },
};

const PrintWhereUse = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [openSubsetCreate, setOpenSubsetCreate] = useState(false);
	const [partNumbers, setPartNumbers] = useState([]);

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const showPopup = useSelector((state) => state.wuBomState.openWhereUse);
	const printType = useSelector((state) => state.wuBomState.printType);
	const parentTableRows = useSelector((state) => state.wuBomState.parentTableRows);
	const org = useSelector((state) => state.wuBomState.org);
	const partNumber = useSelector((state) => state.wuBomState.partNumber);
	const partDescription = useSelector((state) => state.wuBomState.partDescription);
	const PART_REV = useSelector((state) => state.wuBomState.PART_REV);

	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const userName = useSelector((state) => state.authenticationState.userName);
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };

	const closeHandler = () => {
		dispatch(wuBomAction.handleOpenWhereUse());
		dispatch(wuBomAction.resetPrint());
	};

	const handleCheckboxOptions = (option) => {
		switch (option) {
			case "exportToExcel":
				dispatch(wuBomAction.handlePrintTypeUpdate("excel"));
				break;
			case "printToPDF":
				dispatch(wuBomAction.handlePrintTypeUpdate("pdf"));
				break;
			case "createSubset":
				dispatch(wuBomAction.handlePrintTypeUpdate("subset"));
				break;
			default:
				break;
		}
	};

	const onError = (error) => {
		setErrorState(true);
		setErrorMsg(error);
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				if (typeof onError === "function") {
					onError(error.toString());
				}
				setLoading(false);
			});
	};

	const generateReport = () => {
		setLoading(true);
		if (printType === "pdf") {
			const data = {
				client_id: "mlar",
				template: "whereusereport",
				paper_size: "a4",
				prepared_by: userName,
				mlar_pn: partNumber,
				pn_description: partDescription,
				mlar_rev: PART_REV,
				userorg: org,
				parentpart_rpt: parentTableRows
					.map((data) => {
						return {
							item_no: data.LN,
							parent_pn: data.ParentPN,
							rev: data.Rev,
							qty_per: data.Qty,
							description: data.Description,
							pref_code: data.PC,
							er: data.ER,
							status: data.Status,
							type: data.Type,
						};
					})
					.sort((a, b) => a.parent_pn.localeCompare(b.parent_pn)),
			};
			console.log(data);
			fetchDataWithoutLoad(
				{
					url: PDF_GEN_JWT_TOKEN_URL,
					headers: headers,
					method: "POST",
					body: {
						username: "admin",
						password: "pass",
					},
				},
				(token) => handleJWTToken(token, data),
				(error) => {
					setLoading(false);
					if (typeof onError === "function") {
						onError(error.toString());
					}
				}
			);
		} else {
			let filename = "";
			const body = {
				reportType: "Print Where Use",
				wubompart_rpt: parentTableRows
					.map((data) => {
						return {
							org_code: org,
							ppn: data.ParentPN,
							prev: data.Rev,
							parentdesc: data.Description,
							item_no: data.LN,
							cpn: data.CompPN,
							crev: PART_REV,
							qty: data.Qty,
							pref_code: data.PC,
							stat_code: data.Status,
							type: data.Type,
							er: data.ER,
							std_cost: data.std_cost,
							eff_date: data.Eff_date,
							dis_date: data.disDate,
						};
					})
					.sort((a, b) => a.ppn.localeCompare(b.ppn)),
			};
			fetch(`${APIURL}Wu_Bom/exportWuBomToExcel`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: headers,
			})
				.then((response) => {
					const disposition = response.headers.get("content-disposition");
					filename = disposition.match(/filename=(.+)/)[1];
					return response.blob();
				})
				.then((data) => {
					setBlob(data);
					setFileName(filename);
					setOpenSetFileName(true);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					if (typeof onError === "function") {
						onError(error.toString());
					}
				});
		}
	};

	const createSubset = () => {
		setOpenSubsetCreate(true);
		setPartNumbers(
			parentTableRows.map((data) => {
				return data.ParentPN;
			})
		);
		closeHandler();
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState}></ErrorBox>
			<SetFileName blob={blob} setOpenSetFileName={setOpenSetFileName} openSetFileName={openSetFileName} fileName={fileName} />
			<CreateBOMSubset open={openSubsetCreate} onClose={() => setOpenSubsetCreate(false)} mlar_pn={partNumber} org={org} partNumbers={partNumbers} />
			<Modal open={showPopup} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC" && e.altKey === true) {
							closeHandler();
						}
						if (e.code === "KeyE" && e.altKey === true) {
							generateReport();
						}
						if (e.code === "KeyS" && e.altKey === true) {
							createSubset();
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Print/Export Where Use"}
							</Typography>
						</Toolbar>
					</AppBar>

					<Box mb={2} mt={7} sx={{ alignItems: "center", display: "flex", width: "97%", flexDirection: "column" }}>
						<Grid container direction="row">
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="exportToExcel"
									control={<Checkbox checked={printType === "excel"} onChange={() => handleCheckboxOptions("exportToExcel")} />}
									label="Export to Excel file"
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="printToPDF"
									control={<Checkbox checked={printType === "pdf"} onChange={() => handleCheckboxOptions("printToPDF")} />}
									label="Print to PDF file"
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControlLabel
									value="createSubset"
									control={<Checkbox checked={printType === "subset"} onChange={() => handleCheckboxOptions("createSubset")} />}
									label="Create Subset"
								/>
							</Grid>
						</Grid>

						<Grid
							container
							direction="row"
							spacing={1}
							sx={{
								p: 0,
								mt: 1,
							}}
						>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Tooltip title={`For shortcut ${OSAltKey} + C`}>
									<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
									</Button>
								</Tooltip>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Tooltip title={`For shortcut ${OSAltKey} + E`}>
									<span>
										{printType === "subset" ? (
											<Button sx={{ width: "100%", ml: 0 }} onClick={createSubset}>
												<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ubset
											</Button>
										) : (
											<Button sx={{ width: "100%", ml: 0 }} onClick={generateReport}>
												<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>E</u>xport {printType === "pdf" ? "PDF" : "Excel"}
											</Button>
										)}
									</span>
								</Tooltip>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default PrintWhereUse;
