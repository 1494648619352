// React
import { useState, useMemo } from "react";
import { SubsetAction } from "../../../../store/Subset/Subset-slice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// UI
import CreateNewSubsetName from "../../../../Components/Menu/Subset/CreateNewSubsetName";
import { subsetReloadPage } from "../../../Menu/Subset/CreateSubset";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import ErrorBox from "../../../Util/ErrorBox";
import { Box, Modal, Stack, Button } from "@mui/material";
// Packages Import
import { storePartHistories, APIURL } from "../../../Util/Util";
import useHttp from "../../../../Hooks/use-http";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const ExportBrowseSubset = (props) => {
	const dispatch = useDispatch();
	const { pathname: urlPathName } = useLocation();
	// Open CreateNewSubsetName
	const [openSetName, setOpenSetName] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const { sendRequest: fetchData, isLoading } = useHttp();
	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	const closeSetName = () => {
		setOpenSetName(false);
	};

	const handleRedirect = () => {
		setOpenSetName(false);
		// Reset inputs
		dispatch(SubsetAction.reset());
		dispatch(SubsetAction.setSubsetLoaded(true));
		props.onClose();
		// Reload the part number for current page
		subsetReloadPage(urlPathName, dispatch);
	};

	const handleCreateSubset = () => {
		dispatch(SubsetAction.setInputPartNumbers(props.partNumbers));
		setOpenSetName(true);
	};

	const handleUpdateSubset = () => {
		const inputValue = JSON.parse(window.sessionStorage.getItem("subsetName"));
		const inputPartNumbers = props.partNumbers;

		const body = { userid: userNTID, subsetid: inputValue, partnumber: inputPartNumbers };
		fetchData(
			{ url: `${APIURL}subset/editSubset`, headers, method: "POST", body: body },
			(data) => {
				console.log(data);
				dispatch(SubsetAction.setSubsetInfo({ data }));
				dispatch(SubsetAction.setSelectedSubset(inputValue));
				storePartHistories(inputPartNumbers[0]);
				window.sessionStorage.setItem("subsetPartNumbers", JSON.stringify(inputPartNumbers));
				window.sessionStorage.setItem("subsetName", JSON.stringify(inputValue));
				window.sessionStorage.setItem("subsetLoaded", true);
				props.setRefreshCount();
				handleRedirect();
			},
			(error) => {
				console.log(`ERROR: Unable to Update subset Information, ${error}`);
				setErrorMsg(`Unable to Update subset Information, please try again. ERROR: ${error}`);
				setErrorState(true);
			}
		);
	};

	return (
		<>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<CreateNewSubsetName open={openSetName} onClose={closeSetName} handleRedirect={handleRedirect} />

			<Box
				onKeyDown={(e) => {
					if (e.code === "KeyY") {
						handleCreateSubset();
					} else if (e.code === "KeyN") {
						handleUpdateSubset();
					} else if (e.code === "KeyC") {
						props.onClose()
					}
				}}
			>
				<Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
					<Box sx={style}>
						<Stack mb={2} spacing={2}>
							<div style={{ whiteSpace: "pre-wrap" }}>Do you want to creat a new subset (Yes), overwrite existing subset (No), or Cancel?</div>
						</Stack>
						<Button onClick={handleCreateSubset}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>Y</u>es
						</Button>
						<Button onClick={handleUpdateSubset}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>N</u>o
						</Button>
						<Button onClick={props.onClose}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
						</Button>
					</Box>
				</Modal>
			</Box>
		</>
	);
};

export default ExportBrowseSubset;
