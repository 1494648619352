// React
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SubsetAction } from "../../../store/Subset/Subset-slice";

// UI
import { Box, Button, Modal, Stack, AppBar, Toolbar, Typography, Select, MenuItem, Tooltip } from "@mui/material";

// Project Packages
import { itemOrderLabel } from "../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const SetOrderSubset = (props) => {
	const { open, onClose } = props;
	const dispatch = useDispatch();

	const useItemOrder = JSON.parse(window.sessionStorage.getItem("subsetUseItemOrder"));
	const [itemOrder, setItemOrder] = useState(useItemOrder ? itemOrderLabel.item : itemOrderLabel.loadOrder);

	const handleSetOrder = () => {
		switch (itemOrder) {
			case itemOrderLabel.loadOrder:
				window.sessionStorage.setItem("subsetUseItemOrder", false);
				break;
			case itemOrderLabel.item:
				window.sessionStorage.setItem("subsetUseItemOrder", true);
				break;
			default:
				break;
		}

		dispatch(SubsetAction.setReloadNavigation());

		onClose();
	};

	return (
		<Box>
			<Modal
				open={open}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyS") {
							handleSetOrder();
						}
						if (e.code === "KeyC") {
							onClose();
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Set Subset order"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Stack mb={2} mt={6} spacing={2}>
						<Typography>Subset can be view in original - LOAD order or item order</Typography>

						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={itemOrder}
							onChange={(event) => setItemOrder(event.target.value)}
						>
							{Object.values(itemOrderLabel).map((item, index) => {
								return (
									<MenuItem key={index} value={item}>
										{item}
									</MenuItem>
								);
							})}
						</Select>
					</Stack>
					<Tooltip title={"Press S to Set Order"}>
						<Button onClick={handleSetOrder}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>et Order
						</Button>
					</Tooltip>
					<Tooltip title={"Press C to Cancel"}>
						<Button onClick={onClose}>
							<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
						</Button>
					</Tooltip>
				</Box>
			</Modal>
		</Box>
	);
};

export default SetOrderSubset;
