// React
import { useSelector } from "react-redux";

// UI
import { Box, Modal, Grid, AppBar, Toolbar, Typography, Button, Tooltip } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: "80%", md: "80%", lg: "80%", xl: "70%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const defaultProps = {
	fullWidth: true,
	focused: true,
	inputProps: { readOnly: true },
};

const ECCNHTS = (props) => {
	const itemName = useSelector((state) => state.infoStates.info.itemname) || "";
	const itemDesc = useSelector((state) => state.infoStates.info.item_desc) || "";
	const classification = useSelector((state) => state.infoStates.classification);
	const eccn_usml = classification.eccn_usml?.trim();
	const us_hts_no = classification.us_hts_no?.trim();
	const eccn_class_dt = classification.eccn_class_dt ? new Date(classification.eccn_class_dt?.replace("Z", "")).toLocaleDateString("en-US") : "";
	const us_hts_class_dt = classification.us_hts_class_dt
		? new Date(classification.us_hts_class_dt?.replace("Z", "")).toLocaleDateString("en-US")
		: "";
	const createdBy = classification.created_by?.trim() || "";
	const createdDate = classification.created_date ? new Date(classification.created_date?.replace("Z", "")).toLocaleDateString("en-US") : "";
	const modifiedBy = classification.modify_by?.trim() || "";
	const modifiedDate = classification.modify_date ? new Date(classification.modify_date?.replace("Z", "")).toLocaleDateString("en-US") : "";
	const createdAndModified = `Created By ${createdBy} On ${createdDate}\nModified By ${modifiedBy} On ${modifiedDate}`;

	return (
		<Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
			<Box
				sx={style}
				onKeyDown={(e) => {
					if (e.code === "KeyC") {
						props.setOpenECCNHTS(false);
					}
				}}
			>
				<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
					<Toolbar>
						<Typography variant="h6" component="div" color="#fff">
							{"Classification Info - Main Page"}
						</Typography>
					</Toolbar>
				</AppBar>
				<Grid
					mb={2}
					mt={5}
					container
					justifyContent="space-between"
					alignItems="center"
					rowSpacing={2}
					columnSpacing={0.8}
					direction="row"
					wrap={"wrap"}
				>
					<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
						<ColoredTextField {...defaultProps} label="Part No." value={itemName} />
					</Grid>
					<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
						<ColoredTextField {...defaultProps} label="Part Desc." value={itemDesc} />
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<ColoredTextField
							{...defaultProps}
							label="ECCN/USML"
							value={eccn_usml || "No Data"}
							error={!eccn_usml}
							InputProps={{
								endAdornment: <>{!us_hts_no && <WarningOutlinedIcon color="warning" />}</>,
							}}
						/>
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<ColoredTextField
							{...defaultProps}
							label="US HTS No"
							value={us_hts_no || "No Data"}
							error={!us_hts_no}
							InputProps={{
								endAdornment: <>{!us_hts_no && <WarningOutlinedIcon color="warning" />}</>,
							}}
						/>
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<ColoredTextField {...defaultProps} label="Class Date" value={eccn_class_dt} />
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<ColoredTextField {...defaultProps} label="HS Class Dt" value={us_hts_class_dt} />
					</Grid>
					<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
						<ColoredTextField multiline rows={2} {...defaultProps} value={createdAndModified} />
					</Grid>
				</Grid>
				<Tooltip title={"Press C to close"}>
					<Button onClick={() => props.setOpenECCNHTS(false)}>
						<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
					</Button>
				</Tooltip>
			</Box>
		</Modal>
	);
};

export default ECCNHTS;
