// Packages Import
import { memo } from "react"
import { Grid  } from "@mui/material"
import { useSelector } from "react-redux";

// Project Components
import ColoredTextField from "../../UI/ColoredTextField"

const RRHeader = (props) => {
    const partInfo = useSelector((state) => state.rrState.PART_INFO)

    const gridOrder = {
        partNum : {
            xl: 1,
            lg: 1,
            md: 1, 
            sm: 1, 
            xs: 1 
        },
        partDesc : {
            xl: 2,
            lg: 2,
            md: 2, 
            sm: 2, 
            xs: 2 
        },
    }
    return (
        <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            wrap={"wrap"}
            sx={{
                mb:1,
            }}
            >
            <Grid 
                item 
                xl={2} lg={2} md={2} sm={12} xs={12}
                order={gridOrder.partNum}>
                <ColoredTextField
                    label="Part Number"
                    focused
                    fullWidth
                    value={partInfo.partNum}
                    inputProps={{ 
                        readOnly: true,
                        style: { 
                            fontWeight: "bold" 
                        } 
                    }}
                    InputLabelProps={{ 
                        shrink: true, 
                        style: { 
                            fontWeight: "bold" 
                        } 
                    }}
                />

            </Grid>
            <Grid 
                item 
                xl={10} lg={10} md={10} sm={12} xs={12}
                order={gridOrder.partDesc}>
                <ColoredTextField
                    label="Part Description"
                    focused
                    fullWidth
                    value={partInfo.desc}
                    inputProps={{ 
                        readOnly: true,
                    }}
                    InputLabelProps={{ 
                        shrink: true 
                    }}
                />
            </Grid>
        </Grid>        
    )
}
export default memo(RRHeader)