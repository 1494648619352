import { createSlice } from "@reduxjs/toolkit";
import { checkUndefined  } from "../../Components/Util/Util";

const defaultCommoditiesTable = [{
    id : 0,
    iCat : "",
    lCat : 0,
    iCat_id : 0,
    unspsc : 0,
}]

const defaultItemTable = [{
	id:0,
	item: "",
	desc: "",
	lName: "",
	modBy: "",
	tMod: "",
}]

const defaultMtlEngTable = [{
    id:0,
    lName: "",
	FullName: "",
	telnet: "",
	iCat_id: 0,
	orgCode: "",
}]

const defaultPartSelectionTable = [{
    id:0,
    psgTitle: "",
	tMod: "",
	lName: "",
	name: "",
	link: "",
}]

const defaultColumnVisibilityCommodities = {
    iCat: true,
    lCat: true,
    iCat_id: true,
    unspsc: true,
}

const defaultColumnVisibilityPartSelection = {
    psgTitle: true,
}

const defaultColumnVisibilityMtlEng = {
    FullName: true,
    telnet: true,
}

const defaultColumnVisibilityItem = {
    item: true,
    desc: true,
    tMod: true,
}

// window.localStorage.removeItem("mtlEngColumnVisibility") // Incase of problems due to Item
const columnVisibilityStorageCommodities = JSON.parse(window.localStorage.getItem("MTLENG_commoditiesColumnVisibility"))
const columnVisibilityStoragePartSelection = JSON.parse(window.localStorage.getItem("MTLENG_partSelectionColumnVisibility"))
const columnVisibilityStorageMtlEng = JSON.parse(window.localStorage.getItem("MTLENG_mtlEngColumnVisibility"))
const columnVisibilityStorageItem = JSON.parse(window.localStorage.getItem("MTLENG_itemColumnVisibility"))

const initialMaterialEngineerState = {
	originalData : {
		COMMODITIES_TABLE: defaultCommoditiesTable,
		PART_SELECTION_TABLE: defaultPartSelectionTable,
		MTL_ENG_TABLE: defaultMtlEngTable,
		ITEM_TABLE: defaultItemTable,
	},
	COMMODITIES_TABLE: defaultCommoditiesTable,
	PART_SELECTION_TABLE: defaultPartSelectionTable,
	MTL_ENG_TABLE: defaultMtlEngTable,
	ITEM_TABLE: defaultItemTable,
	tableColumnVisibilityCommodities: columnVisibilityStorageCommodities ? columnVisibilityStorageCommodities : defaultColumnVisibilityCommodities,
	tableColumnVisibilityPartSelection: columnVisibilityStoragePartSelection ? columnVisibilityStoragePartSelection : defaultColumnVisibilityPartSelection,
	tableColumnVisibilityMtlEng: columnVisibilityStorageMtlEng ? columnVisibilityStorageMtlEng : defaultColumnVisibilityMtlEng,
	tableColumnVisibilityItem: columnVisibilityStorageItem ? columnVisibilityStorageItem : defaultColumnVisibilityItem,

};


const mtlEngSlice = createSlice({
	name: "materialengineer",
	initialState: initialMaterialEngineerState,
	reducers: {
		updateAll(state, action) {
			const invalidDate = "Invalid Date"
			// console.log(action.payload.data)
            const commoditiesData = () => {
                if(Object.keys(action.payload.data.CATEGORIES_INFO).length > 0){
                    
                    return action.payload.data.CATEGORIES_INFO.map( (commoditiy , i) =>{ 

                        Object.keys(commoditiy).forEach(key => {
                            commoditiy[key] = checkUndefined(commoditiy[key])
                        })
                        const retItem = {
                            id:i,
                            iCat: commoditiy.icat,
                            lCat: commoditiy.lcat,
                            iCat_id: commoditiy.icat_id,
                            unspsc: commoditiy.unspsc,
                        }
                        return retItem
                    })
                }

                return defaultCommoditiesTable
            }

            const itemData = () => {
                if(Object.keys(action.payload.data.CHANGED_PARTS_INFO).length > 0){

                    return action.payload.data.CHANGED_PARTS_INFO.map( (part , i) =>{ 

                        Object.keys(part).forEach(key => {
                            part[key] = checkUndefined(part[key])
                        })
                        const tMod = new Date(part.tmod?.replace("Z", "")).toLocaleDateString('en-US')
                        const retItem = {
                            id:i,
                            item: part.item,
                            desc: part.item_desc,
                            lName: part.lname,
                            modBy: part.modby,
                            tMod: tMod !== invalidDate ? tMod : "",
                        }
                        return retItem
                    })
                }

                return defaultItemTable
            }

            const MtlEngData = () => {
                if(Object.keys(action.payload.data.MTL_RESP_INFO).length > 0){

                    return action.payload.data.MTL_RESP_INFO.map( (MtlEng , i) =>{ 

                        Object.keys(MtlEng).forEach(key => {
                            MtlEng[key] = checkUndefined(MtlEng[key])
                        })
                        const retItem = {
                            id:i,
                            lName: MtlEng.lname,
                            FullName: MtlEng.fullname,
                            telnet: MtlEng.telnet,
                            iCat_id: MtlEng.icat_id,
                            orgCode: MtlEng.org_code,
                        }
                        return retItem
                    })
                }

                return defaultMtlEngTable
            }

            const partSelectionData = () => {
                if(Object.keys(action.payload.data.PSG_INFO).length > 0){

                    return action.payload.data.PSG_INFO.map( (item , i) =>{ 

                        Object.keys(item).forEach(key => {
                            item[key] = checkUndefined(item[key])
                        })
                        const tMod = new Date(item.tmod?.replace("Z", "")).toLocaleDateString('en-US')
                        const retItem = {
                            id:i,
                            psgTitle: item.psg_title,
                            tMod:  tMod !== invalidDate ? tMod : "",
                            lName: item.lname,
                            name: item.name,
                            link: item.link,
                        }        
                        return retItem
                    })
                }

                return defaultItemTable
            }

            const COMMODITIES_TABLE = commoditiesData()
            const PART_SELECTION_TABLE = partSelectionData()
            const MTL_ENG_TABLE = MtlEngData()
            const ITEM_TABLE = itemData()
			state.COMMODITIES_TABLE = COMMODITIES_TABLE
			state.PART_SELECTION_TABLE = PART_SELECTION_TABLE
			state.MTL_ENG_TABLE = MTL_ENG_TABLE
			state.ITEM_TABLE = ITEM_TABLE
			state.originalData = {
				COMMODITIES_TABLE: COMMODITIES_TABLE,
				PART_SELECTION_TABLE: PART_SELECTION_TABLE,
				MTL_ENG_TABLE: MTL_ENG_TABLE,
				ITEM_TABLE: ITEM_TABLE
			}
        },
		filterTableWhenClicked(state, action) {
			if(action.payload.icat_id || action.payload.lname){
				const icat_id = action.payload.icat_id || state.originalData.MTL_ENG_TABLE.find(item => item.lName === action.payload.lname).iCat_id
				const temp_mtl = state.originalData.MTL_ENG_TABLE.find(item => item.iCat_id === icat_id)
				const lname = action.payload.lname || temp_mtl 

				let commodities_table = state.originalData.COMMODITIES_TABLE
				let mtl_eng_table = state.originalData.MTL_ENG_TABLE
				let part_selection_table = state.originalData.PART_SELECTION_TABLE
				let item_table = state.originalData.ITEM_TABLE
				if(action.payload.table){
					if(action.payload.table === "CommoditiesTable"){
						mtl_eng_table = state.originalData.MTL_ENG_TABLE.filter(item => item.iCat_id === icat_id)
						part_selection_table = mtl_eng_table.length > 0 ? state.originalData.PART_SELECTION_TABLE.filter(item => item.lName === mtl_eng_table[0].lName) : []
						item_table = mtl_eng_table.length > 0  ? state.originalData.ITEM_TABLE.filter(item => item.lName === mtl_eng_table[0].lName) : []
					}
					if(action.payload.table === "MTLEngTable"){
						const filtered_mtl_eng_table = state.originalData.MTL_ENG_TABLE.filter(item => item.lName  === lname)
						commodities_table = state.originalData.COMMODITIES_TABLE.filter(item =>{
							for(var i in filtered_mtl_eng_table){
								if(item.iCat_id === filtered_mtl_eng_table[i].iCat_id){
									return true
								}
							}
							return false
							
						} )
						part_selection_table = state.originalData.PART_SELECTION_TABLE.filter(item => item.lName === lname) 
						item_table = state.originalData.ITEM_TABLE.filter(item => item.lName === lname) 

					}
				}
				state.COMMODITIES_TABLE = commodities_table.length > 0 ? commodities_table : defaultCommoditiesTable
				state.PART_SELECTION_TABLE = part_selection_table.length > 0 ? part_selection_table : defaultPartSelectionTable
				state.MTL_ENG_TABLE = mtl_eng_table.length > 0 ? mtl_eng_table: defaultMtlEngTable
				state.ITEM_TABLE = item_table.length > 0 ? item_table : defaultItemTable
			}

		},

		resetTableData(state, action) {
			state.COMMODITIES_TABLE = state.originalData.COMMODITIES_TABLE
			state.PART_SELECTION_TABLE = state.originalData.PART_SELECTION_TABLE
			state.MTL_ENG_TABLE = state.originalData.MTL_ENG_TABLE
			state.ITEM_TABLE = state.originalData.ITEM_TABLE

		},
		setColumnVisibility(state, action){
			switch(action.payload.table){
				case "Commodities":
					state.tableColumnVisibilityCommodities = action.payload.columnVisibility
					window.localStorage.setItem("MTLENG_commoditiesColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "PartSelection":
					state.tableColumnVisibilityPartSelection = action.payload.columnVisibility
					window.localStorage.setItem("MTLENG_partSelectionColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "MtlEng":
					state.tableColumnVisibilityMtlEng = action.payload.columnVisibility
					window.localStorage.setItem("MTLENG_mtlEngColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "Item":
					state.tableColumnVisibilityItem = action.payload.columnVisibility
					window.localStorage.setItem("MTLENG_itemColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				default:
					break
				
			}
        },

	},
});

export const mtlEngActions = mtlEngSlice.actions;

export default mtlEngSlice;
