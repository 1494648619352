// Packages Import
import React, { Fragment, useState , useEffect , useCallback, useRef } from "react"
import { Box, useScrollTrigger} from "@mui/material"
import { useDispatch, useSelector } from "react-redux";

// Project Component
import CardWrapper from "../../UI/Card"
import HistoryTable from "./NoteHistoryTable"
import useHttp from "../../../Hooks/use-http"
import ErrorBox from "../../Util/ErrorBox"
import NoteInfo from "./NoteInfo"
import NoteHeader from "./NoteHeader"
import BottomNavigation from "./NoteBottomBar"
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator"
import { APIURL } from "../../Util/Util"
import { findPartAction } from "../../../store/common/findPart-slice"
import { noteAction , ACTION} from "../../../store/Note/Note-slice";

const Note = () => {
	const dispatch = useDispatch();
	const [editNoteState, setEditNoteState] = useState(false)
	const [noteErrorState , setNoteErrorState] = useState(false)
	const [errorMsg , setErrorMsg] = useState("")
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const showFindPart = useSelector((state) => state.findPartState.showFindPart);

	const focusRef = useRef(null)

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const { isLoading, sendRequest: fetchData } = useHttp()

	const isScrolling = useScrollTrigger({
		disableHysteresis: true,
		threshold: 10,
	})
	const noteData = useSelector((state) => state.noteStates);
	const reloadPartNumber = useSelector((state) => state.noteStates.reloadPartNumber);

	const userInfo = {
		username : useSelector((state) => state.authenticationState.userNTID),
		org : useSelector((state) => state.authenticationState.User_Assigned_Orgs),
	}

	const updateData = (partNumber) => (data) =>{
		// console.log(data)
		if(!Array.isArray(data.PART_INFO)){
			dispatch(findPartAction.setShowFindPart(false));
			dispatch(noteAction.updateAll({ data: data, userInfo: userInfo }));
		}else{
			if (!showFindPart) {
				setErrorMsg(`Part Number not available`);
				setNoteErrorState(true);
				const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded"));
				if (subsetLoaded) {
					dispatch(noteAction.reset());
					dispatch(noteAction.setPartNumberForSubset(partNumber));
				}
			} else {
				dispatch(findPartAction.setPartFound(false));
			}
		}
	}

	const APIError = (error) => {
		console.log("ERROR: Unable to retrieve Note:", error);
		setErrorMsg(`ERROR: ${error}`);
		setNoteErrorState(true);
	};

	const updateNoteInfo = useCallback((type , action = "default") => {
		let data = {}
		switch(type){
			case ACTION.UPDATE:
				if(action === "default"){
					console.log(noteData.USER_NOTE.selectedOrg)
					data = {
						"partnumber": noteData.PART_INFO.part,
						"userid": userInfo.username,
						"note": noteData.USER_NOTE.note,
						"prstat": noteData.USER_NOTE.prstat === true ? 1 : 0,
						"lrepl01": noteData.USER_NOTE.lrepl01 === true ? "True" : "False",
						"lrepl02": noteData.USER_NOTE.lrepl02 === true ? "True" : "False",
						"userorg": noteData.USER_NOTE.selectedOrg
					}
				}else if(action === "quick"){
					data = {
						"partnumber": noteData.PART_INFO.part,
						"userid": userInfo.username,
						"userorg": noteData.USER_NOTE.selectedOrg
					}
				}
				fetchData({ 
					url: `${APIURL}Note/editNote`,
					method: "POST",
					headers,
					body: data
				}, updateData(), APIError)
				break

			case ACTION.CREATE:
				const icat = noteData.PART_INFO.icatCode
				const legCatNo = icat.substring(icat.indexOf("[") + 1, icat.indexOf("]"))
			
				if(action === "default"){
					data = {
						"partnumber": noteData.PART_INFO.part,
						"userid": userInfo.username,
						"leg_cat_no": legCatNo,
						"icat": noteData.PART_INFO.icatId,
						"note": noteData.USER_NOTE.note,
						"prstat": noteData.USER_NOTE.prstat === true ? 1 : 0,
						"lrepl01": noteData.USER_NOTE.lrepl01 === true ? "True" : "False",
						"lrepl02": noteData.USER_NOTE.lrepl02 === true ? "True" : "False",
						"userorg": noteData.USER_NOTE.selectedOrg
					}
				}else if(action === "accept"){
					data = {
						"partnumber": noteData.PART_INFO.part,
						"userid": userInfo.username,
						"leg_cat_no": legCatNo,
						"icat": noteData.PART_INFO.icatId,
						"note": "Acceptable part",
						"prstat": 1,
						"lrepl01": "False",
						"lrepl02": "False",
						"userorg": noteData.USER_NOTE.selectedOrg

					}
				}
				
				fetchData({ 
					url: `${APIURL}Note/createNote`,
					method: "POST",
					headers,
					body: data
				}, updateData(), APIError)
				break
			default:
				break
		}
		if(noteData.PART_INFO.prefCode === "?" || !noteData.PART_INFO.prefCode){
			errorNoteHandler("Parts with code ? or no code require new code. Please edit the note and add appropriate code to the note. Follow up with request to the CR with change request")
		}
	},[fetchData , noteData])

	const errorNoteHandler = (msg) => {
		setNoteErrorState(true)
		setErrorMsg(msg)
	}

	const editNoteStateHandler = () => {
		
		if(editNoteState){
			let oriUserNote = noteData.NOTE_INFO.find((singleNoteInfo) => singleNoteInfo.div === userInfo.div) || ""
			if(noteData.USER_NOTE.note.trim()){
				if(noteData.POST_ACTION === ACTION.UPDATE){
					if(noteData.USER_NOTE.note !== oriUserNote.note || noteData.USER_NOTE.prstat !== oriUserNote.prstat 
						|| noteData.USER_NOTE.lrepl01 !== oriUserNote.lrepl01 || noteData.USER_NOTE.lrepl02 !== oriUserNote.lrepl02){
						updateNoteInfo(ACTION.UPDATE)
					}else{
						errorNoteHandler("No Changes Made.")
					}
					
				}else{
					updateNoteInfo(ACTION.CREATE)
				}
				setEditNoteState(false)
			}else{
				errorNoteHandler("Note should not be empty.")
				if(noteData.POST_ACTION !== ACTION.UPDATE){
					setEditNoteState(false)
				}
			}			
		}else{
			focusRef.current.focus()
			setEditNoteState(true)
		}

	}

	const quickUpdateHandler = () => {
		if(!editNoteState){
			updateNoteInfo(ACTION.UPDATE , "quick")
		}
	}

	const acceptPartHandler = () => {
		if(!editNoteState){
			updateNoteInfo(ACTION.CREATE , "accept")
		}
	}

	const changePart = (partNumber) => {
		setEditNoteState(false)
		fetchData({ 
			url: `${APIURL}Note/noteslist/${partNumber}/`,
			headers 
		}, updateData(partNumber), APIError)
	}

	useEffect(() => {
		const partNumber = window.sessionStorage.getItem("PartNumber")
		if (partNumber) {
			changePart(partNumber)
		}else{
			dispatch(findPartAction.setShowFindPart(true));
		}
	}, [reloadPartNumber]) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<React.StrictMode>
			<Fragment>
				<FullScreenLoadingIndicator loading={isLoading} />
				<Box 
					position={"sticky"}
					zIndex={1000}
					width={"100%"}
					bgcolor={isScrolling ? "#EBEBEB" : "#fff"}
					sx={{ top: { xs: "65px", sm: "65px", lg: "67px" }, height: { xs: "165px", sm: "165px", md: "120px", lg: "70px" } }}
				>
					<NoteHeader/>
				</Box>
				
				<Box 
					maxWidth={"85%"} 
					mx={"auto"} 
					mt={0}>
					<CardWrapper 
						sx={{ mt: 1 }}>
						<NoteInfo
							focusRef={focusRef}
							userInfo = {userInfo}
							editNoteState={editNoteState}
							editNoteStateHandler={editNoteStateHandler}
						/>
						<Box
							sx={{
								display: 'flex',
								height: '100%',
							}}
						>
							<Box
								sx={{
									flexGrow: 1
								}}
							>

								<HistoryTable/>
							</Box>
						</Box>
					</CardWrapper>

				</Box>
				<BottomNavigation 
					changePart={changePart}
					editNoteStateHandler={editNoteStateHandler}
					editNoteState={editNoteState}
					quickUpdateHandler={quickUpdateHandler}
					acceptPartHandler={acceptPartHandler}
					action = {ACTION}
				/>
				<ErrorBox 
					msg = {errorMsg}
					setErrorState = {setNoteErrorState}
					ErrorState = {noteErrorState}
				/>
			</Fragment>
		</React.StrictMode>
		
	)
}

export default Note