// UI
import { useRef, useState, useEffect } from "react";
import { Chip, FormControl, Input, Tooltip, Grid, IconButton, FormHelperText } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
const ChippedInput = (props) => {
	const {
		label = "",
		placeholder = "",
		enterCode = ["Enter", "NumpadEnter"],
		borderColor = "rgba(0,0,0)",
		errorColor = "rgba(233, 0, 41)",
		disabled = false,
		error = false,
		errorText,
		helperText,
		chipValue,
		inputValue,
		onEnter,
		onChipDelete,
		onInputChange,
		autoCompleteOption = [],
	} = props;
	const formRef = useRef(null);
	const inputRef = useRef(null);
	const [chippedDataArray, setChippedDataArray] = useState(chipValue ?? []);
	const chippedGridLength = 3;
	const calculateExcessGridLength = (length) => {
		if (length >= 12) {
			return calculateExcessGridLength(length - 12);
		} else {
			return 12 - length;
		}
	};
	const inputGridLength =
		chippedDataArray.length > 0 ? calculateExcessGridLength(chippedDataArray.length * chippedGridLength) : 12;

	const setInput = (val) => {
		inputRef.current.value = val;
	};
	const addChip = (keyPress) => {
		const val = inputRef.current?.value;
		if (!val || val === "") {
			console.log("ERROR");
			return;
		}
		const chippedArrayWithVal = [...chippedDataArray, val];
		const onEnterStateData = {
			val: val,
			all: chippedArrayWithVal,
			ref: inputRef,
		};
		if (typeof onEnter === "function") {
			onEnter(onEnterStateData, setChippedDataArray, setInput);
		} else {
			setChippedDataArray(chippedArrayWithVal);
			setInput("");
		}
	};
	const deleteChip = (index) => (e) => {
		const chippedDataArrayCopy = [...chippedDataArray];
		const poppedChip = chippedDataArrayCopy.splice(index, 1);
		const onDeleteStateData = {
			val: poppedChip[0],
			all: chippedDataArrayCopy,
		};
		if (typeof onChipDelete === "function") {
			onChipDelete(onDeleteStateData, setChippedDataArray);
		} else {
			setChippedDataArray(chippedDataArrayCopy);
		}
	};

	const handleInputChange = (e) => {
		if (typeof onInputChange === "function") {
			onInputChange(e.target.value);
		}
	};

	const removeChipWithBackSpace = () => {
		if (chippedDataArray.length > 0 && inputRef.current?.value === "") {
			const chipLastIndex = chippedDataArray.length - 1;
			inputRef.current.value = chippedDataArray[chipLastIndex];
			deleteChip(chipLastIndex)();
		}
	};

	const deleteAll = () => {
		const chippedDataArrayCopy = [...chippedDataArray];
		const onDeleteStateData = {
			val: chippedDataArrayCopy,
			all: [],
		};
		if (typeof onChipDelete === "function") {
			onChipDelete(onDeleteStateData, setChippedDataArray);
		} else {
			setChippedDataArray(chippedDataArrayCopy);
		}
	};
	useEffect(() => {
		if (Array.isArray(chipValue)) {
			setChippedDataArray(chipValue);
		}
	}, [chipValue]);

	useEffect(() => {
		if (inputValue) {
			inputRef.current.value = inputValue;
		}
	}, [inputValue]);
	return (
		<>
			<FormControl fullWidth={true} ref={formRef}>
				<style>
					{`  
                    #fieldset::-webkit-scrollbar{
                        display:none
                    }

                    #fieldset:disabled{
                        opacity: 0.32;
                        border-width: 1px !important;
                    }
                `}
				</style>
				<fieldset
					style={{
						borderRadius: 4,
						borderWidth: 2,
						borderStyle: "solid",
						borderColor: error === true ? errorColor : borderColor,
						padding: "0px 5px 5px 5px",
						overflowY: disabled === true ? "hidden" : "auto",
						height:
							chippedDataArray.length > 3 && chippedDataArray.length < 8
								? "90px"
								: chippedDataArray.length > 7
								? "110px"
								: "50px",
						display: "flex",
						flexDirection: "column-reverse",
					}}
					id={"fieldset"}
					disabled={disabled}
				>
					{label && (
						<legend
							style={{
								padding: "0px 5px",
								fontSize: "0.75em",
								fontWeight: "700",
								marginLeft: "3px",
								lineHeight: "3px",
							}}
						>
							{label}
						</legend>
					)}

					<Grid container direction="row" width={"96%"} spacing={0} rowSpacing={1.2} columnSpacing={0.3}>
						{chippedDataArray.length > 0
							? chippedDataArray.map((singleChip, index) => {
									return (
										<Grid item xs={chippedGridLength} key={index}>
											<Tooltip title={singleChip}>
												<Chip
													label={singleChip}
													sx={{
														width: "100%",
														borderColor: "rgba(124, 95, 201, 1)",
														color: "rgba(124, 95, 201, 1)",
													}}
													onDelete={deleteChip(index)}
													variant="outlined"
												/>
											</Tooltip>
										</Grid>
									);
							  })
							: undefined}
						<Grid item xs={inputGridLength}>
							<>
								<Input
									sx={{
										"&::before": {
											border: 0,
										},
										"&::after": {
											border: 0,
										},
										"&:hover:not(.Mui-disabled):before": {
											border: 0,
										},
										"[list]::-webkit-calendar-picker-indicator": { display: "none !important" },
										width: "100%",
										ml: inputGridLength === 12 ? 1 : 0.7,
									}}
									inputRef={inputRef}
									inputProps={{
										list: "emailList",
									}}
									onChange={handleInputChange}
									onKeyDown={(e) => {
										const codeEnter = typeof enterCode === "string" ? [enterCode] : enterCode;
										if (codeEnter.includes(e.code)) {
											addChip();
										}
										if (e.code === "Backspace" && e.repeat === false) {
											removeChipWithBackSpace();
										}
									}}
									placeholder={placeholder}
								/>

								{autoCompleteOption?.length > 0 && (
									<datalist id="emailList">
										{autoCompleteOption.map((option, i) => (
											<option key={i}>{option}</option>
										))}
									</datalist>
								)}
							</>
						</Grid>
					</Grid>
					{chippedDataArray.length > 0 ? (
						<IconButton
							onClick={deleteAll}
							sx={{
								position: "absolute",
								right: "0.3rem",
								top: "0.8rem",
								color: "rgba(131, 131, 131, 1)",
								backgroundColor: "transparent",
								width: "1.7rem",
								height: "1.7rem",
								"&:hover": {
									color: "rgba(188, 188, 188, 1)",
									backgroundColor: "transparent",
								},
							}}
							size={"small"}
						>
							<DeleteOutlineIcon fontSize={"medium"} />
						</IconButton>
					) : undefined}
				</fieldset>
				<FormHelperText error={error}>{error === true ? errorText : helperText}</FormHelperText>
			</FormControl>
		</>
	);
};

export default ChippedInput;
