// React
import { useSelector } from "react-redux";
import { Fragment, useState, useEffect } from "react";

// UI
import { Typography } from "@mui/material";
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";
import RoHSDocumentTable from "../Table/RoHSDocumentTable";

const RoHSLinkDocument = (props) => {
	const selectedMpn = useSelector((state) => state.rohsStates.selectedMpn);
	const selectedMfgName = useSelector((state) => state.rohsStates.selectedMfgName);
	const linkInfoLength = useSelector((state) => state.rohsStates.linkInfo);
	const [AccordionExpanded, setAccordionExpanded] = useState(false);

	// Retrive Accordion state from local storage when first rendering this page
	useEffect(() => {
		setAccordionExpanded(JSON.parse(localStorage.getItem("RoHSLinkDocAccordion")));
	}, []);

	const AccordionClicked = () => {
		window.localStorage.setItem("RoHSLinkDocAccordion", !AccordionExpanded);
		setAccordionExpanded(!AccordionExpanded);
	};

	return (
		<Fragment>
			<CustomAccordion onChange={AccordionClicked} expanded={AccordionExpanded}>
				<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
					<Typography sx={{ fontWeight: "bold" }}>
						{linkInfoLength.length || 0} Link Document for <i style={{ color: "rgb(0,0,128)" }}>{selectedMfgName}</i>:{" "}
						<i style={{ color: "rgb(0,0,128)" }}>{selectedMpn}</i>
					</Typography>
				</CustomAccordionSummary>
				<CustomAccordionDetails>
					<RoHSDocumentTable nonMtlEngAccess={props.nonMtlEngAccess} hiddenFileInputRef={props.hiddenFileInputRef} />
				</CustomAccordionDetails>
			</CustomAccordion>
		</Fragment>
	);
};

export default RoHSLinkDocument;
