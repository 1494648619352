// React
import { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { findPartAction } from "../../../../store/common/findPart-slice";
import { infoAction } from "../../../../store/Info/info-slice";
// MUI
import { Button, Box, MenuItem, Tooltip, IconButton, Typography, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// Projects
import FindPart from "../../../Util/FindPart";
import BottomBarNavigation from "../../../Layout/BottomBarNavigation";
import { storePartHistories, OpenCDS } from "../../../Util/Util";
import Browse from "./Browse";
import PrefCode from "../../../Layout/PrefCode";
import Mousetrap from "mousetrap";
import Icat from "../../../Layout/icat/icat";

const BottomNavigation = (props) => {
	const dispatch = useDispatch();
	const selectedHistory = useSelector((state) => state.infoStates.info.itemname) || "";
	const prefCode = useSelector((state) => state.infoStates.info.pref_code) || "";
	const nonMtlEngAccess = useSelector((state) => state.authenticationState.Non_Mlt_Eng_Access);
	const { allOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);

	const userIsF01 = allOrg.includes("F01");
	const disableICat = nonMtlEngAccess || !selectedHistory || !userIsF01;
	const disablePrefCode = nonMtlEngAccess || !selectedHistory;

	const [anchorElNav, setAnchorElNav] = useState(null);
	const [openBrowse, setOpenBrowse] = useState(false);
	const [openPrefCode, setOpenPrefCode] = useState(false);
	const [openIcat, setOpenIcat] = useState(false);

	const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded")) ? true : false;
	const subsetPartNumbers = window.sessionStorage.getItem("subsetPartNumbers") ? JSON.parse(window.sessionStorage.getItem("subsetPartNumbers")) : [];

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCdsClicked = () => {
		handleCloseNavMenu();
		OpenCDS(selectedHistory);
	};

	const handleFindClicked = useCallback(() => {
		handleCloseNavMenu();
		dispatch(findPartAction.setPartFound(true));
		dispatch(findPartAction.setShowFindPart(true));
	}, [dispatch]);

	const handleSubsetClicked = () => {
		window.sessionStorage.setItem("subsetLoaded", true);
		const subsetPartNumbers = window.sessionStorage.getItem("subsetPartNumbers")
			? JSON.parse(window.sessionStorage.getItem("subsetPartNumbers"))
			: [];
		if (subsetPartNumbers.length > 0) {
			storePartHistories(subsetPartNumbers[0]);
			dispatch(infoAction.setReloadPartNumber());
		}
	};

	const handleBrowseClicked = () => {
		setOpenBrowse(!openBrowse);
	};

	const handleOpenPrefCode = () => {
		setOpenPrefCode(!openPrefCode);
	};

	const handleOpenIcat = () => {
		setOpenIcat(!openIcat);
	};

	useEffect(() => {
		Mousetrap.bind("ctrl+shift+f", (e) => {
			e.preventDefault();
			handleFindClicked();
		});
		return () => Mousetrap.unbind("ctrl+shift+f");
	}, [handleFindClicked]);

	return (
		<Fragment>
			<Browse open={openBrowse} handleBrowseClicked={handleBrowseClicked} changePart={props.changePart} />
			<FindPart changePart={props.changePart} />
			<PrefCode open={openPrefCode} handleOpenPrefCode={handleOpenPrefCode} partNumber={selectedHistory} />
			<Icat open={openIcat} handleOpenIcat={handleOpenIcat} partNumber={selectedHistory} prefCode={prefCode} />

			<Box position={"fixed"} bottom={0} width={"100%"} display="flex" alignItems={"center"} bgcolor={"#EBEBEB"} zIndex={1100}>
				<Box m={2} sx={{ mx: "auto" }}>
					<Box sx={{ display: "flex" }}>
						<BottomBarNavigation changePart={props.changePart} selectedHistory={selectedHistory} />

						<Box pl={3} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}>
							<Button variant="contained" onClick={handleCdsClicked}>
								CDS...
							</Button>
							<Tooltip title="Open Find Window (CTRL + Shift + F)">
								<span>
									<Button variant="contained" onClick={handleFindClicked}>
										Find
									</Button>
								</span>
							</Tooltip>
							<Button variant="contained" disabled={!subsetLoaded} onClick={handleBrowseClicked}>
								Browse
							</Button>
							<Tooltip title={subsetPartNumbers.length === 0 ? "Load/Create a subset by clicking user Profile Icon and select from menu" : ""}>
								<span>
									<Button variant="contained" disabled={subsetLoaded || subsetPartNumbers.length === 0} onClick={handleSubsetClicked}>
										Subset
									</Button>
								</span>
							</Tooltip>
							<Button variant="contained" disabled={nonMtlEngAccess} onClick={props.handleShowAcceptQupdate}>
								Accpt / Q_Updt part
							</Button>
							<Tooltip title={!selectedHistory ? "Find a part to set new iCat Code" : ""}>
								<span>
									<Button variant="contained" onClick={handleOpenIcat} disabled={disableICat}>
										New iCAT req
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={!selectedHistory ? "Find a part to set new Pref Code" : ""}>
								<span>
									<Button variant="contained" onClick={handleOpenPrefCode} disabled={disablePrefCode}>
										New Pref.C
									</Button>
								</span>
							</Tooltip>
						</Box>

						<Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" } }}>
							<IconButton
								size="Medium"
								aria-label="Footer buttons"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								{anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								disableScrollLock
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
								}}
							>
								<MenuItem key={"CDS..."} onClick={handleCdsClicked}>
									<Typography textAlign="center">{"CDS..."}</Typography>
								</MenuItem>
								<MenuItem key={"Find"} onClick={handleFindClicked}>
									<Typography textAlign="center">{"Find"}</Typography>
								</MenuItem>
								<MenuItem key={"Browse"} onClick={handleBrowseClicked} disabled={!subsetLoaded}>
									<Typography textAlign="center">{"Browse"}</Typography>
								</MenuItem>
								<MenuItem key={"Subset"}>
									<Typography textAlign="center" disabled={subsetLoaded || subsetPartNumbers.length === 0} onClick={handleSubsetClicked}>
										{"Subset"}
									</Typography>
								</MenuItem>
								<MenuItem key={"Accpt part"} disabled={nonMtlEngAccess} onClick={props.handleShowAcceptQupdate}>
									<Typography textAlign="center">{"Accpt / Q_Updt part"}</Typography>
								</MenuItem>
								<MenuItem key={"New iCAT req"} onClick={handleOpenIcat} disabled={disableICat}>
									<Typography textAlign="center">{"New iCAT req"}</Typography>
								</MenuItem>
								<MenuItem key={"New Pref.C"} onClick={handleOpenPrefCode} disabled={disablePrefCode}>
									<Typography textAlign="center">{"New Pref.C"}</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
};

export default BottomNavigation;
