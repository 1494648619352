import { Typography, Box, Stack } from "@mui/material";
import logo from "../Static/keysight-logo.svg";

const UserNotAuthorized = () => {
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Stack spacing={2}>
				<Box ml={-6} component="img" height={"8rem"} mb={15} alt="Keysight Logo" src={logo} />
				<Typography variant="h4" color={"secondary"} sx={{ fontWeight: "bold" }}>
					Unautorized access
				</Typography>
				<Typography variant="h5">You are not given any permission for MLAR application, please contact the administrator</Typography>
			</Stack>
		</Box>
	);
};

export default UserNotAuthorized;
