import ColoredTextField from "./ColoredTextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Datepicker = (props) => {
	const label = props.label || undefined;
	const openTo = props.openTo || "day";
	const views = props.views || ["year", "month", "day"];
	const val = props.val || null;
	const dataName = props.name || undefined;

	const handleSelection = (value) => {
		if (typeof props.handleSelection === "function") {
			if (dataName) {
				props.handleSelection(value, dataName);
			}
		}
	};

	const handleOpen = (event) => {
		if (typeof props.onOpen === "function") {
			props.onOpen();
		}
	};

	const handleDisableDate = (date) => {
		if (typeof props.shouldDisableDate === "function") {
			if (dataName) {
				return props.shouldDisableDate(date, dataName);
			}
		}
	};
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DatePicker
				label={label}
				openTo={openTo}
				views={views}
				value={val}
				minDate={props.minDate instanceof Date ? props.minDate : undefined}
				maxDate={props.maxDate instanceof Date ? props.maxDate : undefined}
				disableFuture={props.disableFuture === true ? true : false}
				disablePast={props.disablePast === true ? true : false}
				readOnly={props.readOnly || false}
				disabled={props.disabled || false}
				onChange={handleSelection}
				onOpen={handleOpen}
				shouldDisableDate={handleDisableDate}
				OpenPickerButtonProps={{
					sx: {
						pl: 0,
					},
				}}
				renderInput={(params) => {
					// console.log(params)
					return (
						<ColoredTextField
							backgroundColor={props.backgroundColor}
							{...params}
							autoComplete="off"
							label={label}
							focused
							InputLabelProps={props.InputLabelProps}
							sx={{
								width: "100%",
							}}
							onKeyDown={(event) => event.preventDefault()}
							onCut={props.preventCut === true ?  (event) => {
								event.preventDefault()
								return false
							}: ()=>{}}

							{...props.textFieldProps}
						/>
					);
				}}
				{...props.ExtraProps}
			/>
		</LocalizationProvider>
	);
};

export default Datepicker;
