import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const ColoredTextField = styled(TextField, {
	shouldForwardProp: (propName) => propName !== "color" && propName !== "border" && propName !== "backgroundColor",
})(({ theme, color, border, backgroundColor }) => ({
	"& label.Mui-focused": {
		borderWidth: border && border,
		color: theme.palette.primary.main,
	},
	"& .MuiInput-underline:after": {
		borderWidth: border && border,
		borderBottomColor: color,
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: backgroundColor && backgroundColor,
		"&.Mui-focused fieldset": {
			borderWidth: border && border,
			borderColor: color,
		},
	},
}));

export default ColoredTextField;
