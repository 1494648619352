import { createSlice } from "@reduxjs/toolkit";
import { storePartHistories } from "../../Components/Util/Util";

const defaultRohsColumnVisibility = {
	mpn: true,
	mfgName: true,
	actionRequest: true,
	rohs66: true,
	rohs66Input: true,
	appPBFF: true,
	aBPFTFT: true,
	t260deg: true,
	t260degt: true,
	maxOTemp: true,
	mcc66: true,
	mccMdrState: true,
	Pbftf: true,
	rohsSolderP: true,
	rcr: true,
};

const defaultDocumentColumnVisibility = {
	fileName: true,
	fileExt: true,
	description: true,
	addBy: true,
	dataAdded: true,
};

const columnRohsVisibilityStorage = JSON.parse(window.localStorage.getItem("rohsColumnVisibility"));
const columnDocumentVisibilityStorage = JSON.parse(window.localStorage.getItem("rohsDocumentVisibility"));

const initialRoHSState = {
	fullData: [],
	org: "",
	// Mpn selection
	selectedMpn: "",
	selectedMfgId: "",
	selectedMfgName: "",
	editDisabled: true,

	// Data
	partInfo: { partNumber: "", prefCode: "", catCode: "", icatID: "", partDescription: "" },
	oracleTable: [],
	originalOracleTable: [],
	mpnNoteInfo: [{ id: 0 }],
	rohsNoteInfo: [{ id: 0 }],
	mccComment: { mdrUpdate: "", mdrComm: "", reqDue: "", reqComm: "", mdrId: "" },
	logInfo: {
		RoHS_By: "",
		RoHS_ModDate: "",
		APBftF_By: "",
		APBftF_ModDate: "",
		ME260C_By: "",
		ME260C_ModDate: "",
		MEMaxOpTemp_By: "",
		MEMaxOpTemp_ModDate: "",
		RoHSinfo_By: "",
		RoHSinfo_ModDate: "",
	},
	linkInfo: [],
	// lastLinkFilesName: [],
	mccChangeRequest: {
		visible: false,
		checkBoxDisabled: false,
		checked: false,
		labelVisibility: false,
		mccreqby: "",
		mccreqmod: "",
		color: "",
		mccreq: "",
		mccreqsend: "",
	},

	// Editing Oracle Table
	editedTable: [],
	oracleTableEditStates: { editing: false, borderSetting: "", buttonText: "Edit" },

	// EditingRoHSNote
	showRoHSNotePopUp: false,
	editedRoHSNote: "",
	editingRoHSNoteOrg: "",

	// Editing Mpn Note
	showMpnNotePopUp: false,
	editedMpnNote: { orgCode: "", note: "", originator: "", dateAdded: "", updatedBy: "", updatedDate: "" },
	editingMpnNoteOrg: "",

	// Change Request to MCC
	showChangeMCCPopUp: false,
	showSuccessMessage: false,

	// Upload File
	showUploadFilePopUp: false,
	uploadErrorMsg: "",
	fileDetails: { item: "", mfg_pn: "", mfgid: "", mfg_name: "" },
	uploadDescription: "DataSheet",
	showEditDescriptionPopUp: false,
	editDescription: "",
	editFileDetails: {
		addBy: "",
		description: "",
		fileExt: "",
		fileName: "",
		docId: "",
		dataAdded: "",
		filePath: "",
	},

	tableRohsColumnVisibility: columnRohsVisibilityStorage ? columnRohsVisibilityStorage : defaultRohsColumnVisibility,
	tableDocumentColumnVisibility: columnDocumentVisibilityStorage
		? columnDocumentVisibilityStorage
		: defaultDocumentColumnVisibility,
	reloadPartNumber: 0, // A useEffect dependency to trigger change part after user loaded subset from info tab itself

	// Run Report
	openRunReport: false,
	runReport: {
		mfgName: "",
		prefix: "",
		contains: "",
		sufix: "",
		mfg_pn_contain: "", //MFG_PN Non - contain text
		partPrefix: "", // Keysight Part prefix
		icatOptions: [],
		iCatLookup: null, // Oracle Category id
		userOrg: "F01",
		orgList: [],
		excelPdfReport: false, // export to Excel or print to PDF
		whereUsed: false, // checkbox - Where Used (MFG MPN)
		batchAddMTL: false, // checkbox - Batch add MTLs notes/Pref Code request...
		batchAddUpdateRoHS: false, // checkbox - Batch add/update RoHS notes...
		batchUpdateOpTemp: false, // checkbox - Batch Update to Operating Temperature...
		createMLARSubset: false, // checkbox - Create MLAR Subset file
		subsetPartNumber: [],
		sortByList: ["Keysight Part No", "MFG Part number", "MFG Name"],
		sortBy: 3,
		autoComplete: {
			mfgName: [],
			prefix: [],
			contains: [],
			sufix: [],
			mfg_pn_contain: [],
			partPrefix: [],
		},
		reportScopeList: ["All", "MTL_ENG RoHS approved", "MCC RoHS approved", "none RoHS unassigned/unapproved"],
		reportScope: 1,

		recordCount: 0,
		batchType: {
			mtlNote: false,
			opTemp: false,
			rohsNote: false,
		},
		batchData: {
			mtlNote: {
				noteEdit: false,
				prefCodeChangeReq: false,
				noteRadioGroup: "different",
				noteAction: "Append",
				noteActionList: ["Append", "Replace"],
				noteNew: "",
				noteExisting: "",
				newPC: "",
				prefCodeList: {
					prefCode: [],
					description: [],
				},
				error: {
					noteNew: false,
					noteExisting: false,
				},
			},
			rohsNote: {
				noteEdit: false,
				checkMassUpdate: false,
				massUpdate260C: false,
				noteRadioGroup: "different",
				noteAction: "Append",
				noteActionList: ["Append", "Replace"],
				noteNew: "",
				noteExisting: "",
				error: {
					noteNew: true,
					noteExisting: true,
				},
			},
			opTemp: {
				newMaxTemp: "",
				newMaxTempList: ["Not reviewed yet", "<70 degC", "=70 degC", ">70 and <85 deg C", ">=85 degC", "N/A"],
				error: {
					newMaxTemp: true,
				},
			},
		},
		transactionId: "",
	},
};

const RoHSSlice = createSlice({
	name: "RoHS",
	initialState: initialRoHSState,
	reducers: {
		reset: (state) => {
			return {
				...initialRoHSState,
				tableRohsColumnVisibility: state.tableRohsColumnVisibility,
				tableDocumentColumnVisibility: state.tableDocumentColumnVisibility,
			};
		},
		retrievePart(state, action) {
			const fullData = action.payload;
			state.fullData = fullData;
			state.partInfo = {
				partNumber: fullData.PART_INFO.itemname || "",
				prefCode: fullData.PART_INFO.pref_code || "",
				catCode: fullData.PART_INFO.cat_code || "",
				icatID: fullData.PART_INFO.icat_id || "",
				partDescription: fullData.PART_INFO.item_desc || "",
			};
			let oracleTable = fullData.ROHS_TAB_INFO.map((data, index) => {
				return {
					id: index,
					mpn: data.mfg_pn || "",
					mfgName: data.mfg_name || "",
					mfgId: data.mfg_id || "",
					aslDisab: data.asl_disab || "",
					aslStCd: data.asl_st_cd || "",
					actionRequest: data.SoCo_Mtl_Eng_Input.acr || "",
					rohs66: data.SoCo_Mtl_Eng_Input.m66 || "",
					rohs66Input: data.SoCo_Mtl_Eng_Input.m66t || "",
					appPBFF: data.SoCo_Mtl_Eng_Input.apbftf || "",
					aBPFTFT: data.SoCo_Mtl_Eng_Input.apbftft || "",
					t260deg: data.SoCo_Mtl_Eng_Input.t260deg || "",
					t260degt:
						(data.SoCo_Mtl_Eng_Input.t260degt?.toUpperCase()?.includes("MAY NEED SPECIAL PROCESS")
							? "May need special Processing"
							: data.SoCo_Mtl_Eng_Input.t260degt) || "",
					maxOTemp: data.SoCo_Mtl_Eng_Input.maxotemp || "",
					mcc66: data.Change_Request_To_MCC.mcc66 || "",
					mccMdrState: data.MCC_Info.mccmdrstate || "",
					Pbftf: data.Oracle_Info.pbftf || "",
					rohsSolderP: data.Oracle_Info.t260 || "",
					rcr: data.Oracle_Info.rcr === "Y" ? "T" : "",
					editDisabled: data.asl_disab === "T" || data.asl_st_cd === "Obsolete",
				};
			});
			oracleTable.sort((a, b) => a.aslStCd.localeCompare(b.aslStCd));
			const inactiveTable = oracleTable.filter((data) => data.aslDisab === "T" || data.aslStCd === "Obsolete");
			oracleTable = oracleTable.filter((data) => data.aslDisab !== "T" && data.aslStCd !== "Obsolete");
			const sortedOracleTable = [...oracleTable, ...inactiveTable];
			state.oracleTable = sortedOracleTable;
			state.originalOracleTable = sortedOracleTable;
			storePartHistories(fullData.PART_INFO.itemname);
		},

		retrieveMpn(state, action) {
			// Prevent data retrieval if ROHS_TAB_INFO is empty
			if (state.oracleTable.length === 0) {
				return;
			}
			const fullData = state.fullData;
			let filteredMpnData;
			if (action.payload.mpn === "first_load") {
				const first_Mpn = state.oracleTable[0].mpn;
				const mfgId = state.oracleTable[0].mfgId.toString();
				const mfgName = state.oracleTable[0].mfgName;
				filteredMpnData =
					fullData.ROHS_TAB_INFO.find((data) => data?.mfg_pn === first_Mpn && data?.mfg_id === mfgId) ||
					fullData.ROHS_TAB_INFO[0];
				state.selectedMpn = first_Mpn;
				state.selectedMfgId = mfgId;
				state.selectedMfgName = mfgName;
				state.editDisabled = filteredMpnData.asl_disab === "T" || filteredMpnData.asl_st_cd === "Obsolete";
				// org used for RoHS slice only
			} else if (action.payload.mpn === "reload") {
				filteredMpnData = fullData.ROHS_TAB_INFO.find(
					(data) => data?.mfg_pn === state.selectedMpn && data?.mfg_id === state.selectedMfgId
				);
			} else {
				filteredMpnData = fullData.ROHS_TAB_INFO.find(
					(data) => data?.mfg_pn === action.payload.mpn && data?.mfg_id === action.payload.mfgId
				);
				state.selectedMpn = action.payload.mpn;
				state.selectedMfgId = action.payload.mfgId;
				state.selectedMfgName = action.payload.mfgName;
				state.editDisabled = filteredMpnData.asl_disab === "T" || filteredMpnData.asl_st_cd === "Obsolete";
			}

			state.mpnNoteInfo = filteredMpnData.MPN_Note_Info.map((note, index) => {
				return {
					id: index,
					orgCode: note?.orgcode || "",
					note: note?.note || "",
					originator: note?.originator || "",
					dateAdded: note?.tadd ? new Date(note.tadd?.replace("Z", "")).toLocaleString("en-US") : "",
					updatedBy: note?.updated_by || "",
					updatedDate: note?.tupd ? new Date(note.tupd?.replace("Z", "")).toLocaleString("en-US") : "",
				};
			});

			state.rohsNoteInfo = filteredMpnData.ROHS_Note_Info.map((note, index) => {
				return {
					id: index,
					orgCode: note.orgcode || "",
					note: note.note || "",
					originator: note?.originator || "",
					dateAdded: note?.tadd ? new Date(note.tadd?.replace("Z", "")).toLocaleString("en-US") : "",
					updatedBy: note.updated_by || "",
					updatedDate: note.tupd ? new Date(note.tupd?.replace("Z", "")).toLocaleString("en-US") : "",
				};
			});

			// state.editedRoHSNote = state.rohsNoteInfo.find((data) => data.orgCode === state.org)?.note || initialRoHSState.editedRoHSNote;
			// let editedMpnNote = state.mpnNoteInfo.find((data) => data.orgCode === state.org) || initialRoHSState.editedMpnNote;
			// state.editedMpnNote = editedMpnNote;
			// state.editingMpnNoteOrg = state.org;
			// state.editingRoHSNoteOrg = state.org;

			const mdrUpdate = filteredMpnData.MCC_Comments_Info.md_statime;
			const reqDue = filteredMpnData.MCC_Comments_Info.md_dued;
			state.mccComment = {
				mdrUpdate: mdrUpdate ? new Date(mdrUpdate?.replace("Z", "")).toLocaleString("en-US") : "",
				mdrComm: filteredMpnData.MCC_Comments_Info.req_comm || "",
				reqDue: reqDue ? new Date(reqDue?.replace("Z", "")).toLocaleString("en-US") : "",
				reqComm: filteredMpnData.MCC_Comments_Info.mdrcomm || "",
				mdrId: filteredMpnData.MCC_Comments_Info.mdr_id || "",
			};
			state.logInfo = {
				RoHS_By: filteredMpnData.Log_Info.m66modby || "",
				RoHS_ModDate: filteredMpnData.Log_Info.m66tmod || "",
				APBftF_By: filteredMpnData.Log_Info.pbfmodby || "",
				APBftF_ModDate: filteredMpnData.Log_Info.pbftmod || "",
				ME260C_By: filteredMpnData.Log_Info.t260modby || "",
				ME260C_ModDate: filteredMpnData.Log_Info.t260tmod || "",
				MEMaxOpTemp_By: filteredMpnData.Log_Info.moptmby || "",
				MEMaxOpTemp_ModDate: filteredMpnData.Log_Info.moptmod || "",
				RoHSinfo_By: filteredMpnData.Log_Info.modby || "",
				RoHSinfo_ModDate: filteredMpnData.Log_Info.tmod || "",
			};
			const linkInfo = filteredMpnData.Links_Info.map((data, index) => {
				return {
					id: index,
					addBy: data.addby || "",
					description: data.description || "",
					fileExt: data.fileext || "",
					fileName: data.filename,
					docId: data.doc_id,
					dataAdded: new Date(data.dadd?.replace("Z", "")).toLocaleString("en-US"),
					filePath: data.filepath,
				};
			});
			state.linkInfo = linkInfo.reverse((a, b) => a.dataAdded.localeCompare(b.dataAdded));
			const allLinkFilesName = [];
			fullData.ROHS_TAB_INFO.map((data) => data.Links_Info.map((data) => allLinkFilesName.push(data.filename)));
			allLinkFilesName?.sort();
			// state.lastLinkFilesName = allLinkFilesName?.pop() || "000";

			state.fileDetails = {
				item: state.partInfo.partNumber,
				mfg_pn: state.selectedMpn,
				mfgid: state.selectedMfgId,
				mfg_name: state.selectedMfgName,
			};

			state.mccChangeRequest = {
				visible: filteredMpnData.Change_Request_To_MCC.mcc66 !== "T" && filteredMpnData.mfg_name !== "TEMPORARY",
				checkBoxDisabled:
					filteredMpnData.asl_disab === "T" ||
					filteredMpnData.asl_st_cd === "Obsolete" ||
					filteredMpnData.Change_Request_To_MCC.mccreqsend === "T",
				checked: filteredMpnData.Change_Request_To_MCC.mccreq === "T" || false,
				labelVisibility:
					filteredMpnData.Change_Request_To_MCC.mccreqsend === "T" || filteredMpnData.Change_Request_To_MCC.mccreqby,
				mccreqby: filteredMpnData.Change_Request_To_MCC.mccreqby || "\u00A0",
				mccreqmod: filteredMpnData.Change_Request_To_MCC.mccreqmod
					? new Date(filteredMpnData.Change_Request_To_MCC.mccreqmod?.replace("Z", "")).toLocaleString("en-US")
					: "",
				color:
					filteredMpnData.Change_Request_To_MCC.mccreqsend === "T"
						? "rgba(225, 255, 0, 0.5)"
						: "rgba(128,128,128, 0.45)",
				mccreq: filteredMpnData.Change_Request_To_MCC.mccreq,
			};
		},

		handleEditTable(state, action) {
			let oracleTable = JSON.parse(JSON.stringify(state.oracleTable));
			const index = oracleTable.findIndex((data) => {
				return data.mpn === action.payload.mpn && data.mfgId === action.payload.mfgId;
			});
			const field = action.payload.field;
			switch (field) {
				// Checkboxes
				case "rohs66":
					oracleTable[index].rohs66 = action.payload.value === "T" ? "F" : "T";
					if (oracleTable[index].rohs66Input !== "See note" || action.payload.value === "T") {
						oracleTable[index].rohs66Input = oracleTable[index].rohs66 === "T" ? "Yes" : "No";
					}
					break;
				case "appPBFF":
					oracleTable[index].appPBFF = action.payload.value === "T" ? "F" : "T";
					if (oracleTable[index].aBPFTFT !== "See note" || action.payload.value === "T") {
						oracleTable[index].aBPFTFT = oracleTable[index].appPBFF === "T" ? "Yes" : "No";
					}
					break;
				case "t260deg":
					oracleTable[index].t260deg = action.payload.value === "T" ? "F" : "T";
					if (oracleTable[index].t260degt !== "See note" || action.payload.value === "T") {
						oracleTable[index].t260degt = oracleTable[index].t260deg === "T" ? "Yes" : "No";
					}
					break;

				// Dropdown lists
				case "rohs66Input":
					if (action.payload.value !== "See note") {
						oracleTable[index].rohs66 = action.payload.value === "Yes" ? "T" : "F";
					}
					oracleTable[index].rohs66Input = action.payload.value;
					break;
				case "aBPFTFT":
					if (action.payload.value !== "See note") {
						oracleTable[index].appPBFF = action.payload.value === "Yes" ? "T" : "F";
					}
					oracleTable[index].aBPFTFT = action.payload.value;
					break;
				case "t260degt":
					if (action.payload.value !== "See note") {
						oracleTable[index].t260deg = action.payload.value === "Yes" ? "T" : "F";
					}
					oracleTable[index].t260degt = action.payload.value;
					break;
				case "maxOTemp":
					oracleTable[index].maxOTemp = action.payload.value;
					break;
				default:
					break;
			}

			if (field !== "maxOTemp") {
				const conditions = ["Yes", "N/A", "See note"];
				const rohs66Input = oracleTable[index].rohs66Input;
				const aBPFTFT = oracleTable[index].aBPFTFT;
				const t260degt = oracleTable[index].t260degt;
				if (conditions.includes(rohs66Input) && conditions.includes(aBPFTFT) && conditions.includes(t260degt)) {
					oracleTable[index].actionRequest = "F";
				} else {
					oracleTable[index].actionRequest = "T";
				}
			}

			state.oracleTable = oracleTable;

			let editedRow = state.editedTable.find(
				(data) => data.mfg_pn === action.payload.mpn && data.mfgid === action.payload.mfgId
			);
			const filteredRow = state.editedTable.filter(
				(data) => data.mfg_pn !== action.payload.mpn && data.mfg_id !== action.payload.mfgId
			);

			const actionRequiredChanged =
				oracleTable[index].actionRequest !== (state.originalOracleTable[index].actionRequest || "F");
			const acr = actionRequiredChanged ? oracleTable[index].actionRequest : "F";

			// Append common rows first if there are not available
			editedRow = editedRow
				? { ...editedRow, acr }
				: { mfg_pn: action.payload.mpn, mfgid: action.payload.mfgId, mfg_name: oracleTable[index].mfgName, acr };

			if (field === "rohs66" || field === "rohs66Input") {
				filteredRow.push({ ...editedRow, m66: oracleTable[index].rohs66, m66t: oracleTable[index].rohs66Input });
				state.editedTable = filteredRow;
			}
			if (field === "appPBFF" || field === "aBPFTFT") {
				filteredRow.push({ ...editedRow, apbftf: oracleTable[index].appPBFF, apbftft: oracleTable[index].aBPFTFT });
				state.editedTable = filteredRow;
			}
			if (field === "t260deg" || field === "t260degt") {
				filteredRow.push({ ...editedRow, t260deg: oracleTable[index].t260deg, t260degt: oracleTable[index].t260degt });
				state.editedTable = filteredRow;
			}
			if (field === "maxOTemp") {
				filteredRow.push({ ...editedRow, maxotemp: oracleTable[index].maxOTemp });
				state.editedTable = filteredRow;
			}

			console.log(state.editedTable);
		},

		setEditingTable(state, action) {
			if (action.payload === true) {
				state.oracleTableEditStates = {
					editing: true,
					borderSetting: { border: 2, borderColor: "#FF0000" },
					buttonText: "Save",
				};
			} else {
				state.oracleTableEditStates = { editing: false, borderSetting: "", buttonText: "Edit" };
			}
		},

		clearEditedTable(state) {
			state.editedTable = [];
		},

		// RoHS Note

		setEditingRoHSNote(state, action) {
			state.showRoHSNotePopUp = action.payload;
		},

		// To handle user choice on multi org RoHS note
		setEditingRoHSNoteOrg(state, action) {
			state.editingRoHSNoteOrg = action.payload;
			state.editedRoHSNote =
				state.rohsNoteInfo.find((data) => data.orgCode === action.payload)?.note || initialRoHSState.editedRoHSNote;
		},

		handleEditRoHSNote(state, action) {
			state.editedRoHSNote = action.payload;
		},

		updateRoHSNote(state, action) {
			const fullData = JSON.parse(JSON.stringify(state.fullData));
			const newNote = action.payload.ROHS_TAB_INFO.find((data) => data.orgcode === state.editingRoHSNoteOrg);
			const updatedROHS_TAB_INFO = fullData.ROHS_TAB_INFO.map((data) => {
				const index = data.ROHS_Note_Info.findIndex((data) => {
					return data.orgcode === state.editingRoHSNoteOrg;
				});
				if (index === -1) {
					data.ROHS_Note_Info.push(newNote);
				} else {
					data.ROHS_Note_Info[index] = newNote;
				}
				return { ...data };
			});
			fullData.ROHS_TAB_INFO = updatedROHS_TAB_INFO;
			state.fullData = fullData;
		},

		cancelUpdateRoHSNote(state) {
			state.editedRoHSNote = state.rohsNoteInfo.find((data) => data.orgCode === state.editingMpnNoteOrg)?.note;
		},

		// Mpn Note and Change Request to MCC Checkbox
		setEditingMpnNote(state, action) {
			state.showMpnNotePopUp = action.payload;
		},

		// To handle user choice on multi org MPN note.
		setEditingMpnNoteOrg(state, action) {
			state.editingMpnNoteOrg = action.payload;
			state.editedMpnNote =
				state.mpnNoteInfo.find((data) => data.orgCode === action.payload) || initialRoHSState.editedMpnNote;
		},

		handleEditMpnNote(state, action) {
			state.editedMpnNote = { ...state.editedMpnNote, note: action.payload };
		},

		cancelUpdateMpnNote(state) {
			let editedMpnNote =
				state.mpnNoteInfo.find((data) => data.orgCode === state.editingMpnNoteOrg) || initialRoHSState.editedMpnNote;
			state.editedMpnNote = editedMpnNote;
		},

		updateMpnNote(state, action) {
			const fullData = JSON.parse(JSON.stringify(state.fullData));
			const newMpnInfo = action.payload.ROHS_TAB_INFO.find(
				(data) => data?.mfg_pn === state.selectedMpn && data?.mfg_id === state.selectedMfgId
			);
			const newMpnNoteInfo = newMpnInfo.MPN_Note_Info.find((data) => data.orgcode === state.editingMpnNoteOrg);

			const mpnIndex = fullData.ROHS_TAB_INFO.findIndex(
				(data) => data?.mfg_pn === state.selectedMpn && data?.mfg_id === state.selectedMfgId
			);
			const mpnNoteIndex = fullData.ROHS_TAB_INFO[mpnIndex].MPN_Note_Info.findIndex(
				(data) => data.orgcode === state.editingMpnNoteOrg
			);

			if (mpnNoteIndex === -1) {
				fullData.ROHS_TAB_INFO[mpnIndex].MPN_Note_Info.push(newMpnNoteInfo);
			} else {
				fullData.ROHS_TAB_INFO[mpnIndex].MPN_Note_Info[mpnNoteIndex] = newMpnNoteInfo;
			}

			fullData.ROHS_TAB_INFO[mpnIndex].Change_Request_To_MCC = newMpnInfo.Change_Request_To_MCC;

			state.fullData = fullData;
		},

		setShowChangeMCC(state, action) {
			if (action.payload === true) {
				const editedMpnNote = state.editedMpnNote;
				const partNumber = state.partInfo.partNumber;
				let changeRequestNote = editedMpnNote.note;
				if (
					!changeRequestNote?.replace(/\s+/g, "")?.toLowerCase().includes(`requestforitem:${partNumber.toLowerCase()}`)
				) {
					changeRequestNote = `Request for Item: ${partNumber} ${changeRequestNote}`;
					state.editedMpnNote = { ...editedMpnNote, note: changeRequestNote };
				}
			}
			state.showChangeMCCPopUp = action.payload;
		},

		setShowSuccessMessage(state, action) {
			state.showSuccessMessage = action.payload;
		},

		// Upload File
		setShowUploadFilePopUp(state, action) {
			state.showUploadFilePopUp = action.payload;
		},

		setUploadErrorMsg(state, action) {
			state.uploadErrorMsg = action.payload;
		},

		handleUploadMsg(state, action) {
			state.uploadDescription = action.payload;
		},

		updateLinksDoc(state, action) {
			const fullData = JSON.parse(JSON.stringify(state.fullData));
			const newMpnLinkInfo = action.payload.ROHS_TAB_INFO.find(
				(data) => data?.mfg_pn === state.selectedMpn && data?.mfg_id === state.selectedMfgId
			).Links_Info;

			const mpnIndex = fullData.ROHS_TAB_INFO.findIndex(
				(data) => data?.mfg_pn === state.selectedMpn && data?.mfg_id === state.selectedMfgId
			);

			fullData.ROHS_TAB_INFO[mpnIndex].Links_Info = newMpnLinkInfo;

			state.fullData = fullData;
		},

		setShowEditDescriptionPopUp(state, action) {
			state.showEditDescriptionPopUp = action.payload;
		},

		AddEditDescriptionFileDetails(state, action) {
			const data = action.payload;
			state.editFileDetails = {
				addBy: data.addBy,
				description: data.description,
				fileExt: data.fileExt,
				fileName: data.fileName,
				docId: data.docId,
				dataAdded: data.dateAdded,
				filePath: data.filePath,
			};
			console.log(state.editFileDetails);
		},

		handleEditDescription(state, action) {
			state.editFileDetails.description = action.payload;
		},

		setPartNumberForSubset(state, action) {
			state.partInfo.partNumber = action.payload;
		},

		setRohsColumnVisibility(state, action) {
			state.tableRohsColumnVisibility = action.payload.columnVisibility;
			window.localStorage.setItem("rohsColumnVisibility", JSON.stringify(action.payload.columnVisibility));
		},

		setDocumentColumnVisibility(state, action) {
			state.tableDocumentColumnVisibility = action.payload.columnVisibility;
			window.localStorage.setItem("rohsDocumentVisibility", JSON.stringify(action.payload.columnVisibility));
		},

		setReloadPartNumber(state) {
			state.reloadPartNumber = state.reloadPartNumber + 1;
		},

		// Run Report
		setOpenRunReport(state) {
			state.openRunReport = !state.openRunReport;
		},

		setIcatOptions(state, action) {
			state.runReport.icatOptions = action.payload;
		},

		updateData(state, action) {
			const setData = (newData, obj) => {
				for (let key in newData) {
					if (typeof newData[key] === "object" && !Array.isArray(newData[key]) && newData[key] !== null) {
						if (obj[key] === null && typeof newData[key] === "object") {
							obj[key] = {};
						}
						setData(newData[key], obj[key]);
					} else {
						obj[key] = newData[key];
					}
				}
			};
			// const setData = (newData , obj , current) => {
			// 	if(obj[current] === null && typeof newData === "object"){
			// 		obj[current] = {}
			// 	}
			// 	for (let key in newData) {
			// 		if (typeof newData[key] === "object" && !Array.isArray(newData[key]) && newData[key] !== null) {
			// 			setData(newData[key] , obj[current] , key);
			// 		} else {
			// 			obj[current][key] = newData[key];
			// 		}
			// 	}
			// };
			setData(action.payload.data, state["runReport"]);
		},
		resetReportData(state, action) {
			const ignore = action.payload?.ignore;
			let ignoreReset = {};
			if (Array.isArray(ignore) && ignore.length > 0) {
				ignore.forEach((ignoreKey) => {
					const exist = Object.keys(state.runReport).findIndex((reportKey) => {
						return reportKey === ignoreKey;
					});
					if (exist >= 0) {
						ignoreReset[ignoreKey] = state.runReport[ignoreKey];
					}
				});
			}
			state.runReport = {
				...initialRoHSState.runReport,
				...ignoreReset,
			};
		},
		resetBatchData(state, action) {
			const ignore = action.payload?.ignore;
			let ignoreReset = {};
			if (Array.isArray(ignore) && ignore.length > 0) {
				ignore.forEach((ignoreKey) => {
					const exist = Object.keys(state.runReport.batchData).findIndex((batchKey) => {
						return batchKey === ignoreKey;
					});
					if (exist >= 0) {
						ignoreReset[ignoreKey] = state.runReport.batchData[ignoreKey];
					}
				});
			}
			state.runReport.batchData = {
				...initialRoHSState.runReport.batchData,
				...ignoreReset,
			};
			state.runReport.batchRecordInfo = initialRoHSState.runReport.batchRecordInfo;
		},
		setBatchUpdatePopUp(state, action) {
			let batchType = action.payload?.batchType ?? "";
			if (batchType) {
				state.runReport.batchType[batchType] = !state.runReport.batchType[batchType];
			}
		},
		setAutoComplete(state, action) {
			for (let key in action.payload) {
				const data = action.payload[key];
				if (Array.isArray(data)) {
					state.runReport.autoComplete[key] = data;
				}
			}
		},
		removeAutoComplete(state, action) {
			const key = action.payload.key;
			const value = action.payload.valueToRemove;
			state.runReport.autoComplete[key] = state.runReport.autoComplete[key].filter((val) => !(val === value));
		},
	},
});

export const RoHSAction = RoHSSlice.actions;

export default RoHSSlice;
