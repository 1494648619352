// Packages Import
import { useState } from "react";
import { Box, Paper, Tooltip, Typography, Button, Modal, Stack, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Project Component
import ColoredTextField from "../../../UI/ColoredTextField";
import Datepicker from "../../../UI/Datepicker";
import DropdownFilter from "../../../UI/DropdownFilter";
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
import { APIURL, PDF_GEN_JWT_TOKEN_URL, PDF_GEN_URL, OSAltKey, isNumber } from "../../../Util/Util";
import SetFileName from "../../../Util/SetFileName";

const StatusMlarReport = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.reportsState.reports.mlar.status);
	const showPopup = useSelector((state) => state.reportsState.popUpStates.mlar.status);
	const orgList = useSelector((state) => state.reportsState.orgList);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};

	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const handleOrgChange = (value) => {
		dispatch(
			reportsActions.updateData({
				data: {
					userOrg: value,
				},
			})
		);
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
				setLoading(false);
			});
	};
	const generateReport = () => {
		setLoading(true);
		closeHandler();
		const jsonReq = {
			startDate: data.startDate,
			endDate: data.endDate,
			userid: userInfo.id,
			userOrg: data.userOrg,
		};
		fetchDataWithoutLoad(
			{
				url: `${APIURL}reports/mlarStatusReport`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(data) => {
				if (data?.No_Record) {
					throw Error(data.No_Record);
				}
				fetchDataWithoutLoad(
					{
						url: PDF_GEN_JWT_TOKEN_URL,
						headers: headers,
						method: "POST",
						body: {
							username: "admin",
							password: "pass",
						},
					},
					(token) => handleJWTToken(token, data),
					(error) => {
						setLoading(false);
						if (typeof props.onError === "function") {
							props.onError(error.toString());
						}
					}
				);
			},
			(error) => {
				setLoading(false);
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
			}
		);
	};

	const closeHandler = () => {
		dispatch(reportsActions.updatePopUpState());
		dispatch(reportsActions.reset());
	};

	const handleDisableDate = (val, type) => {
		const today = moment();
		const curDateCopy = moment(val, "YYYY-MM-DD");
		const endDt = moment(data.endDate, "YYYY-MM-DD");
		switch (type) {
			case "start":
				if (endDt.diff(curDateCopy, "days") > data.maxDay || curDateCopy.isSameOrAfter(endDt)) {
					return true;
				} else {
					return false;
				}
			case "end":
				if (curDateCopy.isAfter(today)) {
					return true;
				} else {
					return false;
				}
			default:
				return true;
		}
	};

	const handleDateSelection = (val, type) => {
		let daysDifference = data.dayNum;
		let startDate = moment(data.startDate, "YYYY-MM-DD");
		let endDate = moment(data.endDate, "YYYY-MM-DD");
		const dateVal = moment(val, "YYYY-MM-DD");
		let error = false;
		switch (type) {
			case "start":
				daysDifference = endDate.diff(dateVal, "days");
				startDate = dateVal;
				break;
			case "end":
				endDate = dateVal;
				const daysDiff = dateVal.diff(startDate, "days");
				if (daysDiff > data.maxDay) {
					daysDifference = data.maxDay;
					startDate = moment(endDate, "YYYY-MM-DD").subtract(daysDifference, "day");
				} else if (daysDiff < 1) {
					daysDifference = data.dayNum;
					error = true;
				} else {
					daysDifference = daysDiff;
				}
				break;
			default:
				break;
		}
		dispatch(reportsActions.setError({ data: { startDate: error } }));
		dispatch(
			reportsActions.updateData({
				data: {
					startDate: startDate.format("YYYY-MM-DD"),
					endDate: endDate.format("YYYY-MM-DD"),
					dayNum: daysDifference,
				},
			})
		);
	};

	const handleDayNum = (event) => {
		const inpVal = event.target.value;
		if (isNumber(inpVal) || inpVal === "") {
			const val = inpVal < 1 ? 1 : inpVal > data.maxDay ? data.maxDay : inpVal;
			const endDate = moment(data.endDate, "YYYY-MM-DD");
			const startDate = endDate.subtract(val, "day").format("YYYY-MM-DD");
			if (inpVal === "") {
				dispatch(
					reportsActions.updateData({
						data: {
							dayNum: inpVal,
						},
					})
				);
			} else {
				dispatch(
					reportsActions.updateData({
						data: {
							dayNum: val,
							startDate: startDate,
						},
					})
				);
			}
		}
	};
	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName
				blob={blob}
				setOpenSetFileName={setOpenSetFileName}
				openSetFileName={openSetFileName}
				fileName={fileName}
			/>
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (!data.error.startDate) {
								if (e.code === "KeyC") {
									closeHandler();
								}
								if (e.code === "KeyP") {
									generateReport();
								}
							}
						}
					}}
				>
					<Stack mb={2} spacing={1}>
						<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
							<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
								Mlar Status Report
							</Typography>
							<DropdownFilter
								width={90}
								selections={orgList}
								selectedData={data.userOrg}
								handleSelection={handleOrgChange}
								label={"User Org"}
								InputLabelProps={{
									shrink: true,
									sx: {
										fontWeight: 700,
									},
								}}
							/>
						</Box>
						<Paper sx={{ p: 2 }} elevation={5}>
							<Typography variant="subtitle">
								{`Status report will give you count of all the parts involved into MLAR for last 4 weeks(default entry 28
								days). It will give the total number of parts per board and how many parts do need an update or a new
								note`}
							</Typography>
						</Paper>

						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								pt: 3,
							}}
						>
							<Grid
								item
								xl={5}
								lg={5}
								md={5}
								sm={5}
								xs={12}
								sx={{
									pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
								}}
							>
								<Datepicker
									label="Start Date"
									handleSelection={handleDateSelection}
									val={data.startDate}
									name="start"
									disableFuture={false}
									disablePast={false}
									shouldDisableDate={handleDisableDate}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Grid>
							<Grid
								item
								xl={5}
								lg={5}
								md={5}
								sm={5}
								xs={12}
								sx={{
									pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
									pt: { xs: 1.5, sm: 0, md: 0, lg: 0, xl: 0 },
								}}
							>
								<Datepicker
									label="End Date"
									handleSelection={handleDateSelection}
									val={data.endDate}
									name="end"
									disableFuture={false}
									disablePast={false}
									shouldDisableDate={handleDisableDate}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Grid>
							<Grid
								item
								xl={2}
								lg={2}
								md={2}
								sm={2}
								xs={12}
								sx={{
									pt: { xs: 1.5, sm: 0, md: 0, lg: 0, xl: 0 },
								}}
							>
								<ColoredTextField
									label="Days"
									fullWidth
									focused
									onChange={handleDayNum}
									value={data.dayNum}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Grid>
						</Grid>
						<Typography variant="caption" sx={{ pb: 3, fontWeight: 700 }}>
							{`Start Date cannot be more than 6 months(${data.maxDay} days) from End Date`}
						</Typography>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								p: 0,
							}}
						>
							<Grid
								item
								xl={6.1}
								lg={6.1}
								md={6.1}
								sm={6.1}
								xs={6.1}
								sx={{
									pr: 1,
								}}
							>
								<Tooltip title={`For shortcut ${OSAltKey} + C`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={closeHandler}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
								<Tooltip title={`For shortcut ${OSAltKey} + P`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={generateReport} disabled={data.error.startDate}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>P</u>rint PDF
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
						<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default StatusMlarReport;
