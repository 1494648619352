// React
import { useDispatch, useSelector } from "react-redux";
import { AdminAction } from "../../../store/Admin/Admin-slice";
import { Fragment, useEffect, useMemo, useState } from "react";
// UI
import {
	Box,
	Button,
	Modal,
	Grid,
	FormControl,
	AppBar,
	Toolbar,
	Typography,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	Autocomplete,
	FormHelperText,
	Paper,
	List,
	ListItem,
	ListItemText,
	Stack,
} from "@mui/material";
import AutocompleteCheckBox from "../../UI/AutocompleteCheckBox";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator";
import ColoredTextField from "../../UI/ColoredTextField";
import ErrorBox from "../../Util/ErrorBox";
import MessageBox from "../../Util/MessageBox";
// Packages Import
import useHttp from "../../../Hooks/use-http";
import { APIURL, OSAltKey } from "../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	maxHeight: "80%",
	overflowY: "auto",
	p: 4,
	pb: 2,
};

const ChangeUserOrg = () => {
	const dispatch = useDispatch();

	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);

	const open = useSelector((state) => state.adminState.openChangeOrg);
	const currentUserInfo = useSelector((state) => state.adminState.currentUserInfo);
	const defaultUserInfo = useSelector((state) => state.adminState.defaultUserInfo);
	const allUserInfo = useSelector((state) => state.adminState.allUserInfo);
	const orgOptions = useSelector((state) => state.adminState.orgOptions);

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);

	const [openMessageBox, setOpenMessageBox] = useState(false);
	const [resultMessage, setResultMessage] = useState("");

	const { sendRequest: fetchData, isLoading } = useHttp();
	const { sendRequest: fetchData2, isLoading: isLoading2 } = useHttp();

	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	useEffect(() => {
		if (open) {
			dispatch(AdminAction.reset());
			fetchData(
				{ url: `${APIURL}admin/assignedMultiUserOrgs/`, headers },
				(data) => {
					// console.log(data);
					dispatch(AdminAction.setAllUserInfo(data));
				},
				(error) => {
					console.log(`Unable to retrieve User Information, Error: ${error}`);
					setErrorMsg(`ERROR: Unable to retrieve User Information, please try refresh the page. Error: ${error}`);
					setErrorState(true);
				}
			);
			fetchData2(
				{ url: `${APIURL}MtlEngInfo/userOrgsList`, headers },
				(data) => {
					dispatch(AdminAction.setOrgSelection(data));
				},
				(error) => {
					console.log(`Unable to retrieve User Information, Error: ${error}`);
					setErrorMsg(`ERROR: Unable to retrieve User Information, please try refresh the page. Error: ${error}`);
					setErrorState(true);
				}
			);
		}
	}, [dispatch, fetchData, fetchData2, headers, open]);

	const handleAddMoreFields = () => {
		if (currentUserInfo.length > 0) {
			const newId = currentUserInfo[currentUserInfo.length - 1].id + 1;
			dispatch(AdminAction.addUserInfo([...currentUserInfo, { ...defaultUserInfo, id: newId }]));
		} else {
			dispatch(AdminAction.addUserInfo([defaultUserInfo]));
		}
	};

	const removeInputBox = (row) => {
		dispatch(AdminAction.removeInputBox({ row }));
	};

	const handleInputChange = (row, type) => (event, newValue) => {
		const value = type === "primaryOrg" ? event.target.value : newValue;
		dispatch(AdminAction.setUserInfo({ row, value, type }));
	};

	const handleSecondaryOrgChange = (row) => (value) => {
		const type = "secondaryOrg";
		dispatch(AdminAction.setUserInfo({ row, value, type }));
	};

	const handleApplyClicked = () => {
		let valid = true;
		const copyUserInfo = JSON.parse(JSON.stringify(currentUserInfo));
		const newUserInfo = copyUserInfo.map((data) => {
			if (!data.userid) {
				valid = false;
				data.error = "Please select a user or remove this row if not required";
			}
			return data;
		});
		if (!valid) {
			dispatch(AdminAction.addUserInfo(newUserInfo));
		} else {
			const body = {
				admin_userid: userNTID,
				updates: currentUserInfo.map((data) => {
					return {
						userid: data.userid,
						primary: data.primary,
						secondary: data.secondary,
						remove: data.remove,
					};
				}),
			};
			console.log(body);

			fetchData(
				{ url: `${APIURL}admin/editUserOrgDetails`, headers, method: "POST", body },
				(data) => {
					const resultMessage = currentUserInfo.map((user) => {
						const result = data.Result.find((result) => result.userid === user.userid);
						if (result.status === "Success") {
							return (
								<Typography>{`${user.UserName} <${user.userid}> \u2192 Primary Org: ${user.primary}, Secondary Org: ${user.secondary.join(
									", "
								)}`}</Typography>
							);
						} else if (result.status === "Error") {
							return <Typography color="error">{`${user.UserName} <${user.userid}> \u2192 ${result.error}`}</Typography>;
						}
						return <Typography>{`${user.UserName} <${user.userid}> \u2192 Unknown error`}</Typography>;
					});
					setResultMessage(resultMessage);
					setOpenMessageBox(true);
				},
				(error) => {
					console.log(`Unable to save User Information, Error: ${error}`);
					setErrorMsg(`ERROR: Unable to save, please try again after refresh the page. Error: ${error}`);
					setErrorState(true);
				}
			);
		}
	};

	const Message = () => {
		return (
			<Stack>
				<Typography>{"Summary of new changes"}</Typography>
				<Paper sx={{ height: 300, overflow: "auto" }}>
					<List disablePadding component="div" role="list">
						{resultMessage.map((value, index) => {
							const labelId = `transfer-list-item-${value}-label`;
							return (
								<ListItem key={index} role="listitem" divider>
									<ListItemText id={labelId} primary={value} sx={{ m: 0 }} />
								</ListItem>
							);
						})}
					</List>
				</Paper>
			</Stack>
		);
	};

	const messageBoxHandler = () => {
		setOpenMessageBox(false);
		dispatch(AdminAction.setOpenChangeOrg(false));
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={isLoading || isLoading2} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<MessageBox open={openMessageBox} message={<Message />} messageBoxHandler={messageBoxHandler} />

			<Modal open={open} aria-labelledby="Admin-modal-Change-Org" aria-describedby="Admin-modal-Change-user-Org" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC" && e.altKey === true) {
							e.preventDefault();
							dispatch(AdminAction.setOpenChangeOrg(false));
						} else if (e.code === "KeyA" && e.altKey === true) {
							e.preventDefault();
							handleApplyClicked();
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Admin - Change User Org"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Grid
						mb={4}
						mt={6}
						container
						direction="row"
						justifyContent="center"
						alignItems="flex-start"
						rowSpacing={1.5}
						columnSpacing={1}
						wrap={"wrap"}
					>
						{currentUserInfo.map((row) => {
							return (
								<Fragment key={row.id}>
									<Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
										<FormControl fullWidth error={row.error ? true : false}>
											<Autocomplete
												id="FieldAutoComplete"
												autoHighlight
												onChange={handleInputChange(row, "user")}
												options={JSON.parse(JSON.stringify(allUserInfo)).sort((a, b) => a.UserName.localeCompare(b.UserName))}
												getOptionLabel={(option) => (option.userid ? `${option.UserName} <${option.userid}>` : "")}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												size="small"
												value={JSON.stringify(defaultUserInfo) === JSON.stringify(row) ? null : row}
												renderInput={(params) => (
													<ColoredTextField
														focused
														error={row.error ? true : false}
														{...params}
														InputProps={{
															...params.InputProps,
															sx: { textOverflow: "ellipsis", backgroundColor: "#fff" },
														}}
													/>
												)}
											/>
											<FormHelperText>{row.error}</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xl={2} lg={2} md={2} sm={3} xs={3}>
										<FormControl focused fullWidth size="small">
											<InputLabel id="user-name">Primary Org</InputLabel>
											<Select
												sx={{ height: "40px" }}
												disabled={row.userid ? false : true}
												inputProps={{ readOnly: row.userid ? false : true }}
												labelId="primary-org-label"
												id="primary-org-select"
												label="Primary Org"
												value={row.primary}
												onChange={handleInputChange(row, "primaryOrg")}
											>
												{orgOptions.map((org, index) => (
													<MenuItem key={index} value={org}>
														{org}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xl={5} lg={5} md={5} sm={9} xs={9}>
										<Box sx={{ display: "flex" }}>
											<AutocompleteCheckBox
												ExtraProps={{ size: "small", getOptionDisabled: (option) => row.primary === option, disabled: row.userid ? false : true }}
												label={"Secondary Org"}
												options={orgOptions}
												handleOnChange={handleSecondaryOrgChange(row)}
												value={row.secondary}
												optionLabel={(option) => `${option}`}
											/>
											<Box
												display={"flex"}
												ml={1}
												justifyContent={"center"}
												sx={{
													backgroundColor: "#FF0000",
													// borderTopRightRadius: "5px",
													// borderBottomRightRadius: "5px",
													"&:hover": {
														backgroundColor: "#fc6b6b",
													},
												}}
											>
												<IconButton size="small" onClick={() => removeInputBox(row)} aria-label="clearSearch">
													<HighlightOffIcon style={{ fill: "white" }} />
												</IconButton>
											</Box>
										</Box>
									</Grid>
								</Fragment>
							);
						})}
					</Grid>

					<Box sx={{ display: "flex" }}>
						<Box flex={"1"}>
							<Button
								startIcon={<AddCircleOutlineIcon />}
								onClick={handleAddMoreFields}
								onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
								sx={{
									py: 0,
									borderRadius: 0,
									backgroundColor: "transparent",
									textTransform: "none",
									color: "#000000",
									fontSize: "1 rem",
									"& .MuiButton-endIcon": {
										marginLeft: 0,
									},
									"&:hover": {
										color: "#0f0fff",
										textTransform: "none",
										backgroundColor: "transparent",
									},
								}}
							>
								Add more user
							</Button>
						</Box>
						<Box>
							<Button onClick={handleApplyClicked}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>A</u>pply
							</Button>
							<Button onClick={() => dispatch(AdminAction.setOpenChangeOrg(false))}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default ChangeUserOrg;
