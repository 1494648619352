// Packages Import
import { useState } from "react";
import {
	Box,
	Tooltip,
	Typography,
	Button,
	Modal,
	Stack,
	Grid,
	MenuItem,
	Select,
	FormControl
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Project Component
import useHttp from "../../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../../UI/FullScreenLoadingIndicator";
import { RoHSAction } from "../../../../../store/RoHS/RoHS-slice";
import { APIURL, OSAltKey } from "../../../../Util/Util";

const currentBatch = "opTemp";

const OpTemp = (props) => {
	const dispatch = useDispatch();
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const onError = typeof props.onError === "function" ? props.onError : () => {};
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const recordCount = useSelector((state) => state.rohsStates.runReport.recordCount);
	const transactionId = useSelector((state) => state.rohsStates.runReport.transactionId);
	const data = useSelector((state) => state.rohsStates.runReport.batchData[currentBatch]);
	const showPopup = useSelector((state) => state.rohsStates.runReport.batchType[currentBatch]);
	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 800 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
		org: useSelector((state) => state.authenticationState.userOrg),
	};

	const handleDropdown = (type) => (event) => {
		const value = event.target.value
		dispatch(
			RoHSAction.updateData({
				data: {
					batchData: {
						[currentBatch]: {
							[type]: value,
						},
					},
				},
			})
		);
	};
	const closeHandler = () => {
		dispatch(RoHSAction.setBatchUpdatePopUp({ batchType: currentBatch }));
		dispatch(RoHSAction.resetBatchData());
	}
	const massUpdate = () => {
		closeHandler();
		setLoading(true);
		const jsonReq = {
			userid: userInfo.id,
			userOrg: userInfo.org,
			new_maxotemp: data.newMaxTemp,
			transaction_id: transactionId,
		};

		fetchDataWithoutLoad(
			{
				url: `${APIURL}Rohs/batchEditMaxOpTemp`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(returnedData) => {
				if(returnedData?.Result){
					onError(returnedData.Result.toString()); // Temporarily using onError , Should change to infobox later on
				}else{
					throw Error("No Success Message Returned")
				}
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				onError(error.toString());
			}
		);
	};

	const cancelRequest = () => {
		const jsonReq = {
			userid: userInfo.id,
			userOrg: userInfo.org,
			transaction_id: transactionId,
		};

		fetchDataWithoutLoad(
			{
				url: `${APIURL}Rohs/cancelBatchUpdate`,
				headers: headers,
				method: "POST",
				body: jsonReq,
			},
			(returnedData) => {
				if(returnedData?.Result){
					console.log(returnedData.Result.toString())
					// onError(returnedData.Result.toString()); // Temporarily using onError , Should change to infobox later on
				}else{
					throw Error("No Success Message Returned")
				}
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				onError(error.toString());
			}
		);
		closeHandler()
	};
	return (
		<Box>
			<FullScreenLoadingIndicator loading={loading} />
			<Modal
				open={showPopup}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								cancelRequest();
							}
							if (e.code === "KeyG" && data.newMaxTemp !== "") {
								massUpdate();
							}
						}
					}}
				>
					<Stack mb={2} spacing={1}>
						<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
							<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
								Batch Operating Temp Update
							</Typography>
						</Box>

						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								pt: 2,
							}}
						>
							<Grid container item alignItems={"center"} rowSpacing={1} xl={12} lg={12} md={12} sm={12} xs={12}>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<Typography variant="subtitle" sx={{ textAlign: "center", width: "100%" }}>
										No of Records : <b>{recordCount}</b>
									</Typography>
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
									<Typography variant="subtitle" sx={{ textAlign: "center", width: "100%" }}>
										Choose Operating Temperature for these change
									</Typography>
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
									<FormControl
										sx={{ marginRight: 1, minWidth: 150 }}
										focused
										fullWidth
									>
										<Select
											size="small"
											labelId="history-select-label"
											id="history-select"
											value={data.newMaxTemp}
											onChange={handleDropdown("newMaxTemp")}
											MenuProps={{
												disableScrollLock: true,
												sx: { maxHeight: "60%" },
											}}
										>
											{Object.values(data.newMaxTempList).map((temp, index) => (
												<MenuItem key={index} value={temp}>
													{temp}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							spacing={0}
							sx={{
								p: 0,
							}}
						>
							<Grid
								item
								xl={6.1}
								lg={6.1}
								md={6.1}
								sm={6.1}
								xs={6.1}
								sx={{
									pr: 1,
								}}
							>
								<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
									<span>
										<Button sx={{ width: "100%", ml: 0 }} onClick={cancelRequest}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
								<Tooltip title={`For shortcut press ${OSAltKey} + G`}>
									<span>
										<Button
											sx={{ width: "100%", ml: 0 }}
											onClick={massUpdate}
											disabled={data.newMaxTemp === ""}
										>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>G</u>O
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
						<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default OpTemp;
