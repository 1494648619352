// React
import { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { infoAction } from "../../../../store/Info/info-slice";
// UI
import InfoTable from "../../../UI/Table";
import { cx } from "@emotion/css";
import { Typography } from "@mui/material";
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";
import { formatDate } from "../../../Util/Util";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";

const columnsSettings = [
	{ field: "mfg_pn", headerName: "Oracle Supplier item", type: "text", minWidth: 150, flex: 0.5 },
	{ field: "vend_bus", headerName: "Business", type: "text", minWidth: 80, flex: 0.5 },
	{ field: "vend_name", headerName: "Oracle Supplier/MFG Name", type: "text", minWidth: 200, flex: 1 },
	{ field: "rohs", headerName: "RoHS", type: "checkbox", minWidth: 55, flex: 0.3 },
	{ field: "mcc66", headerName: "MCC", type: "checkbox", minWidth: 50, flex: 0.3 },
	{ field: "vend_no", headerName: "Supp No", type: "text", minWidth: 70, flex: 0.3 },
	{ field: "owg_org_cd", headerName: "Own. ORG", type: "text", minWidth: 85, flex: 0.3 },
	{ field: "asl_st_cd", headerName: "Status", type: "text", minWidth: 80, flex: 0.3 },
	{ field: "asl_disab", headerName: "Dsbld", type: "checkbox", minWidth: 60, flex: 0.3 },
	{ field: "usg_plt", headerName: "Pur.LeadTime", type: "text", minWidth: 110, flex: 0.3 },
	{ field: "us_min_qty", headerName: "Min Qty", type: "text", minWidth: 70, flex: 0.3 },
	{ field: "owg_plt", headerName: "Owng Org", type: "text", minWidth: 85, flex: 0.3 },
	{ field: "ow_min_qty", headerName: "Owng_min", type: "text", minWidth: 85, flex: 0.3 },
];

const customCellClass = (params) => {
	switch (params.field) {
		case "mfg_pn":
			//Oracle Supplier item"
			return cx("super-app", {
				strikeThrough: params.row.asl_disab === "T" || params.row.asl_st_cd === "Obsolete",
				blue: params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
				green: !params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
			});
		case "vend_bus":
			//Business
			return cx("super-app", {
				strikeThrough: params.row.asl_disab === "T" || params.row.asl_st_cd === "Obsolete",
				blue: params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
				green: !params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
			});
		case "vend_name":
			// Oracle Supplier/MFG Name
			return cx("super-app", {
				strikeThrough: params.row.asl_disab === "T" || params.row.asl_st_cd === "Obsolete" || params.row.asl_st_cd === "LTB",
				blue: params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
				green: !params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
			});
		case "rohs":
			// ROHS
			return cx("super-app", {
				red: params.value?.toUpperCase() !== "T",
				blue: params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
				green: !params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
			});
		case "mcc66":
			// Oracle Supplier/MFG Name
			return cx("super-app", {
				yellow: params.value?.toUpperCase() === "T",
				red: params.row.vend_bus?.toUpperCase().includes("MANUFACTURER") && params.value?.toUpperCase() !== "T",
				green: !params.row.vend_bus?.toUpperCase().includes("MANUFACTURER") && params.value?.toUpperCase() !== "T",
			});
		case "vend_no":
			//Supp No
			return cx("super-app", {
				strikeThrough: params.row.asl_disab === "T",
				blue: params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
				green: !params.row.vend_bus?.toUpperCase().includes("MANUFACTURER"),
			});
		case "owg_org_cd":
			// Own. ORG
			return cx("super-app", {
				blue: params.value?.toUpperCase() === "IM1",
				green: params.value?.toUpperCase() !== "IM1",
			});
		case "asl_st_cd":
			//Status
			return cx("super-app", {
				green: params.value?.toUpperCase() === "APPROVED",
				yellow: params.value === "Preferred",
				red: params.value?.toUpperCase() !== "APPROVED" && params.value !== "Preferred",
			});
		case "asl_disab":
			//Dsbld
			return cx("super-app", {
				green: true,
			});
		case "usg_plt":
			//Pur.LeadTime
			return cx("super-app", {
				yellow: true,
				strikeThrough: params.row.asl_disab === "T",
			});
		case "us_min_qty":
			//Min Qty
			return cx("super-app", {
				yellow: true,
			});
		case "owg_plt":
			// Owng Org
			return cx("super-app", {
				yellow: true,
			});
		case "ow_min_qty":
			// Owng_min
			return cx("super-app", {
				yellow: true,
			});
		default:
			return params;
	}
};

const AMLASLTable = () => {
	const dispatch = useDispatch();
	const [InfoAccordionAML, setInfoAccordionAML] = useState(false);
	const AML_ASL_rows = useSelector((state) => state.infoStates.AML_ASL_rows);
	const partNum = useSelector((state) => state.infoStates.info.itemname);
	const columnVisibility = useSelector((state) => state.infoStates.tableColumnVisibility);

	// Retrive AML and ORG accordion state from local storage when first rendering this page
	useEffect(() => {
		setInfoAccordionAML(JSON.parse(localStorage.getItem("InfoAccordionAML")));
	}, []);

	// Store Accordion state into browser's local storage
	const AMLAccordionClicked = () => {
		window.localStorage.setItem("InfoAccordionAML", !InfoAccordionAML);
		setInfoAccordionAML(!InfoAccordionAML);
	};

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `AMLASL_KPN${partNum}_${formatDate(new Date(), "")}`,
				}}
			/>
		</>
	);
	return (
		<CustomAccordion onChange={AMLAccordionClicked} expanded={InfoAccordionAML}>
			<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Typography sx={{ fontWeight: "bold" }}>AML and ASL Information</Typography>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
				<InfoTable
					columnsSettings={columnsSettings}
					rows={AML_ASL_rows}
					customCellClass={customCellClass}
					customToolBar={customToolBar}
					ExtraProps={{
						columnVisibilityModel: columnVisibility,
						onColumnVisibilityModelChange: (newModel) => {
							dispatch(infoAction.setColumnVisibility({ columnVisibility: newModel }));
						},
					}}
				></InfoTable>
			</CustomAccordionDetails>
		</CustomAccordion>
	);
};

export default memo(AMLASLTable);
