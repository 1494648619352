// Packages Import
import React, { Fragment , useEffect , useState } from "react"
import { Box} from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// Project Component
import useHttp from "../../../Hooks/use-http"
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator"
import { APIURL , PDF_GEN_URL , PDF_GEN_JWT_TOKEN_URL } from "../../Util/Util"
import SearchFilterArea from "./SearchFilterArea"
import ProjectsTable from "./ProjectsTable"
import ProjectInfo from "./ProjectInfo"
import { projectsAction} from "../../../store/Projects/Projects-slice";
import ErrorBox from "../../Util/ErrorBox";

let errorMsg = ""


const Projects = () => {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	// const headers = { "Content-Type": "application/json"};
	const { isLoading, sendRequest: fetchData } = useHttp()
	const { sendRequest: fetchDataWithoutLoad } = useHttp()
	const [toggleErrorPopUp , setToggleErrorPopUp] = useState(false)
	const filteredData = useSelector((state) => state.projectsStates.filteredData)
	const prevFilteredData = useSelector((state) => state.projectsStates.prevFilteredData)
	const projectInfo = useSelector((state) => state.projectsStates.PROJECT_INFO)
	const projectUID= useSelector((state) => state.projectsStates.projectIndex)
	const projectTableData = useSelector((state) => state.projectsStates.PROJECT_INFO_TABLE)
	const projectIndex = projectUID === "-" ? 0 : projectInfo.findIndex((singleProjectData) => {
        return projectUID === singleProjectData.uid
    })
	const userInfo = {
		username : useSelector((state) => state.authenticationState.userNTID),
		org : useSelector((state) => state.authenticationState.User_Assigned_Orgs),
		name : useSelector((state) => state.authenticationState.userName)
	}
	const updateData = (data) => {
		dispatch(projectsAction.updateAll({ data: data}))
	}

	const updateProjectsList = (data,area) => {
		dispatch(projectsAction.updateProjectsList({ data: data , area:area}))
	}

	const updateEditedData = (data) => {
		fetchData({ 
			url: `${APIURL}Projects/projectsDetails`,
			headers: headers,
			method: "POST",
			body: prevFilteredData
		}, updateData)
	}

	const getProjectsList = (org , area) => {
		fetchDataWithoutLoad({ 
			url: `${APIURL}Projects/projectslist/${org}`,
			headers: headers, 
		}, (data) => {updateProjectsList(data,area)})
	}

	const updateOrg = (data) => {
		dispatch(projectsAction.updateOrg({ data: data , userOrg: userInfo.org.primaryOrg}))
	}
	const handleProjectInfoCancel = () => {
		fetchData({ 
			url: `${APIURL}Projects/projectsDetails`,
			headers: headers,
			method: "POST",
			body: prevFilteredData
		}, updateData)
	}
	const handleSearchFilterData = (initialPageLoad) => {
		dispatch(projectsAction.savePrevFilterData({data : filteredData}))
		fetchData({ 
			url: `${APIURL}Projects/projectsDetails`,
			headers: headers,
			method: "POST",
			body: filteredData
		}, updateData)
		getProjectsList(filteredData.userOrg , "info")
		if(initialPageLoad){
			dispatch(projectsAction.updateProjectIndex({val : "-"}))
		}
		

	}

	const handleJWTToken = (token , isLoading) => {
		const projectDetailsMapped = projectTableData.map((rowData) => {
			const retItem = {
				"bomno": rowData.bom,
				"rev": rowData.rev,
				"liaison": rowData.liaison,
				"project": rowData.project,
				"bomcount": rowData.bomPartsNum,
				"dlastadd": rowData.bomDateLoad,
				"dduedate": rowData.rptDeliveryDueDate,
				"dcompl": rowData.dateCompleted
			}

			return retItem
		})
		const data = {
			"client_id": "mlar",
			"template": "projects",
			"paper_size": "a4",
			"prepared_by": userInfo.name,
			"start_date": filteredData.startDate,
			"end_date": filteredData.endDate,
			"projects_details": projectDetailsMapped,
			"userorg" : prevFilteredData.userOrg
		}
		let filename = ""
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Authorization' : `Bearer ${token.access}`
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			const disposition = response.headers.get("content-disposition");
            filename = disposition.match(/filename=(.+)/)[1];
			return response.blob()
		})
		.then((data) => {
			const url = window.URL.createObjectURL(
				data
			);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			link.remove();	
			isLoading(false)
		}).catch((error) => {
			errorMsg = error.toString()
			setToggleErrorPopUp(true)
			isLoading(false)
		})
	}
	const fileDownloadExcel = (isLoading) => {
		let filename = ""
		fetch(`${APIURL}Projects/generateExcel/${filteredData.filterOn}/${filteredData.startDate}/${filteredData.endDate}/${filteredData.userOrg}/`, {
			method: "GET",
			headers: headers,
		})
		.then((response) => {
			const disposition = response.headers.get("content-disposition");
            filename = disposition.match(/filename=(.+)/)[1];
			return response.blob()
		})
		.then((data) => {
			const url = window.URL.createObjectURL(
				data
			);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			link.remove();
			isLoading(false)

		}).catch((error) => {
			console.log(error)
			errorMsg = error.toString()
			setToggleErrorPopUp(true)
			isLoading(false)
		})
	}

	const fileDownloadPdf = (setLoading) => {
		fetchDataWithoutLoad({ 
			url: PDF_GEN_JWT_TOKEN_URL,
			headers: headers,
			method: "POST",
			body: {
				username:"admin",
				password:"pass",
			}
		}, (data) => handleJWTToken(data , setLoading))
	}

	const handleEditDetailData = () => {

		const updatedData = {
			userid: userInfo.username,
			pro_id: projectInfo[projectIndex].oldProId || projectInfo[projectIndex].proId,
			project: projectInfo[projectIndex].oldProject || projectInfo[projectIndex].project,
			bomno: projectTableData[projectIndex].bom,
			userOrg:prevFilteredData.userOrg,
			liaison: projectInfo[projectIndex].liaison,
			a_group: projectInfo[projectIndex].division,
			pr_mgr: projectInfo[projectIndex].projectManager,
			npieng: projectInfo[projectIndex].npiEng,
			instrument: projectInfo[projectIndex].instrument,
			pcdfm: projectInfo[projectIndex].pcdfm,
			designer: projectInfo[projectIndex].designer,
			comment: projectInfo[projectIndex].note,
			dduedate: moment(projectInfo[projectIndex].rptDeliveryDueDate , "YYYY-MM-DD") ? projectInfo[projectIndex].rptDeliveryDueDate : null,  
			dcompl: moment(projectInfo[projectIndex].dateCompleted, "YYYY-MM-DD") ? projectInfo[projectIndex].dateCompleted : null,
			dinpdate: moment(projectInfo[projectIndex].inputDueDate, "YYYY-MM-DD") ? projectInfo[projectIndex].inputDueDate : null,
		}
		fetchData({ 
			url: `${APIURL}Projects/editProjectDetails`,
			headers: headers,
			method: "POST",
			body: updatedData
		}, updateEditedData)
	}

	const handleNameDetailData = () => {
		if(projectInfo[projectIndex].oldProject){
			const isBomProjectExist = projectTableData.findIndex((singleProjectTableData) => {
				return (projectInfo[projectIndex].project === singleProjectTableData.project) && (projectTableData[projectIndex].bom === singleProjectTableData.bom)
			})

			if(isBomProjectExist > -1){
				errorMsg = `Entry for BOM : ${projectTableData[projectIndex].bom} already exists for Project ${projectInfo[projectIndex].project}. Please select a different project name`
				setToggleErrorPopUp(true)
				fetchDataWithoutLoad({ 
					url: `${APIURL}Projects/projectsDetails`,
					headers: headers,
					method: "POST",
					body: prevFilteredData
				}, updateData)
				return
			}
		}
		const updatedData = {
			userid: userInfo.username,
			pro_id: projectInfo[projectIndex].oldProId || projectInfo[projectIndex].proId,
			project: projectInfo[projectIndex].oldProject || projectInfo[projectIndex].project,
			newpro_id: projectInfo[projectIndex].oldProId ? projectInfo[projectIndex].proId : "",
			newProject: projectInfo[projectIndex].oldProject ? projectInfo[projectIndex].project : "",
			bomno: projectTableData[projectIndex].bom,
			userOrg:prevFilteredData.userOrg,
		}
		fetchData({ 
			url: `${APIURL}Projects/editProjectDetails`,
			headers: headers,
			method: "POST",
			body: updatedData
		}, updateEditedData)
	}
	

	useEffect(() => {
		// getProjectsList(userInfo.org.primaryOrg , "all")
		fetchData({ 
			url: `${APIURL}MtlEngInfo/userOrgsList`, headers: headers
		}, updateOrg)
	}, [])


	
	return (
		<React.StrictMode>
			<Fragment>
				<FullScreenLoadingIndicator loading={isLoading} />
				<ErrorBox msg={errorMsg} setErrorState={setToggleErrorPopUp} ErrorState={toggleErrorPopUp} />
				<Box 
					maxWidth={"85%"} 
					mx={"auto"} 
					mt={2}
				>
						<SearchFilterArea 
							handleSearchFilterData = {handleSearchFilterData}
							userInfo = {userInfo}
							getProjectsList = {getProjectsList}
						/>
						<ProjectsTable />
						<ProjectInfo 
							handleDetailUpdate = {handleEditDetailData}
							handleNameUpdate = {handleNameDetailData}
							resetData={handleProjectInfoCancel}
							fileDownloadExcel={fileDownloadExcel}
							fileDownloadPdf={fileDownloadPdf}
						/>
				</Box>
				
			</Fragment>
		</React.StrictMode>
		
	)
}

export default Projects