// React
import { Fragment, useState, useMemo } from "react";
import { useSelector } from "react-redux";

// UI
import { Button, Grid, AppBar, Toolbar, Typography, Stack, Box, Modal, Tooltip } from "@mui/material";
import ColoredTextField from "../../../UI/ColoredTextField";
import CountryHTS from "../Classification/CountryHTS";
import ECCNHTS from "../Classification/ECCNHTS";
import ErrorBox from "../../../Util/ErrorBox";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import PoHistory from "../Classification/PoHistory";

// Packages Import
import useHttp from "../../../../Hooks/use-http";
import { APIURL } from "../../../Util/Util";
import moment from "moment";

const InfoQuantityAndCost = () => {
	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);
	const userNTID = useSelector((state) => state.authenticationState.userNTID);
	const partNumber = useSelector((state) => state.infoStates.info.itemname) || "";
	const org = useSelector((state) => state.infoStates.org) || "";

	const info = useSelector((state) => state.infoStates.info);
	const OH_NET = info.oh_net || "0";
	const OH_QTY = info.oh_qty || "0";
	const STD_COST = info.std_cost || "0.0000";
	const CUR_COST = info.cur_cost || "0.0000";
	const CMCOST = info.curmcost || "0";

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [openMessageBox, setOpenMessageBox] = useState(false);
	const [message, setMessage] = useState(false);
	const [openCountryHTS, setOpenCountryHTS] = useState(false);
	const [openECCNHTS, setOpenECCNHTS] = useState(false);
	const [openPoHistory, setOpenPoHistory] = useState(false);

	const defaultProps = {
		fullWidth: true,
		focused: true,
		inputProps: { readOnly: true },
	};

	const seeUpdateQtyHeader = (value1, value2) => {
		return (
			<>
				<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
					<Toolbar>
						<Typography variant="h6" component="div" color="#fff">
							{value1}
						</Typography>
					</Toolbar>
				</AppBar>
				<Stack spacing={0.5} pt={5}>
					{value2}
				</Stack>
			</>
		);
	};

	const handleSeeUpdateQtyClicked = () => {
		const body = { userid: userNTID, partnumber: partNumber, org_code: org };
		fetchData(
			{ url: `${APIURL}Info/fetchQuantityOnHand`, headers, method: "POST", body },
			(data) => {
				console.log(data);
				setOpenMessageBox(true);

				const ON_HAND_QTY_DETAILS = data.ON_HAND_QTY_DETAILS[0];

				const ON_HAND = ON_HAND_QTY_DETAILS.ON_HAND?.toString();
				const ON_HAND_NETTABLE = ON_HAND_QTY_DETAILS.ON_HAND_NETTABLE?.toString();
				const orignalValueIsZero = OH_NET.toString() === "0" && OH_QTY.toString() === "0";

				if (ON_HAND_QTY_DETAILS.PART_NUMBER === partNumber && ON_HAND_QTY_DETAILS.ORGANIZATION_CODE === org) {
					if (orignalValueIsZero && ON_HAND === "0" && ON_HAND_NETTABLE === "0") {
						setMessage(seeUpdateQtyHeader("QTY = 0", "No change..."));
					} else if (!orignalValueIsZero && OH_NET.toString() === ON_HAND_NETTABLE && OH_QTY.toString() === ON_HAND) {
						setMessage(seeUpdateQtyHeader("Info...", `No change...`));
					} else {
						const value1 = `Updated On hand balance in stock, for organization = ${org}`;
						const value2 = (
							<>
								<Typography>Updated data for QTY on hand:</Typography>
								<Typography>as for {moment().format("MM/DD/YYYY")}</Typography>
								<Typography sx={{ pt: 3 }}>QTY on hand = {ON_HAND}</Typography>
								<Typography>NETTABLE QTY on HAND = {ON_HAND_NETTABLE}</Typography>
							</>
						);
						setMessage(seeUpdateQtyHeader(value1, value2));
					}
				} else {
					setMessage(
						`Data returned from API does not match value requested - ${partNumber} - ${org}, returned value for - ${ON_HAND_QTY_DETAILS.PART_NUMBER} - ${ON_HAND_QTY_DETAILS.ORGANIZATION_CODE}`
					);
				}
			},
			(error) => {
				console.log(`${error}`);
				try {
					if (error.slice(0, 5).toLowerCase() === "info:") {
						setMessage(seeUpdateQtyHeader("Info...", error.slice(5)));
						setOpenMessageBox(true);
					} else {
						setErrorMsg(`${error}`);
						setErrorState(true);
					}
				} catch {
					setErrorMsg(`${error}`);
					setErrorState(true);
				}
			}
		);
	};

	const handleCountryHTSClicked = () => {
		setOpenCountryHTS(true);
	};

	const handleECCNHTSClicked = () => {
		setOpenECCNHTS(true);
	};

	const handlePoHistoryClicked = () => {
		setOpenPoHistory(true);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "90%", sm: "80%", md: "63%", lg: "48%", xl: "40%" },
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
	};

	return (
		<Fragment>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />

			<Box
				onKeyDown={(e) => {
					if (e.code === "KeyO") {
						setOpenMessageBox(false);
					}
				}}
			>
				<Modal open={openMessageBox} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
					<Box sx={style}>
						<Stack mb={2} spacing={2}>
							{message}
						</Stack>
						<Tooltip title={"Press o to close"}>
							<Button onClick={() => setOpenMessageBox(false)}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>O</u>K
							</Button>
						</Tooltip>
					</Box>
				</Modal>
			</Box>

			<CountryHTS open={openCountryHTS} setOpenCountryHTS={setOpenCountryHTS} />
			<ECCNHTS open={openECCNHTS} setOpenECCNHTS={setOpenECCNHTS} />
			<PoHistory open={openPoHistory} setOpen={setOpenPoHistory} />

			<Grid container direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={3} columnSpacing={0.8} wrap={"wrap"}>
				{/* <Grid item xl={2} lg={2} md={4} sm={6} xs={6}>
					<Button fullWidth variant="contained">
						see updated qty
					</Button>
				</Grid> */}
				<Grid item xl={2.4} lg={2.4} md={4} sm={4} xs={12}>
					<ColoredTextField {...defaultProps} label="Qty. On Hand Nettable" value={OH_NET} />
				</Grid>
				<Grid item xl={2.4} lg={2.4} md={4} sm={4} xs={12}>
					<ColoredTextField {...defaultProps} label="Total Qty. On Hand" value={OH_QTY} />
				</Grid>
				<Grid item xl={2.4} lg={2.4} md={4} sm={4} xs={12}>
					<ColoredTextField {...defaultProps} label="Standard price" value={STD_COST} />
				</Grid>
				<Grid item xl={2.4} lg={2.4} md={4} sm={4} xs={12}>
					<ColoredTextField {...defaultProps} label="Current cost" value={CUR_COST} />
				</Grid>
				<Grid item xl={2.4} lg={2.4} md={4} sm={4} xs={12}>
					<ColoredTextField {...defaultProps} label="CM average Cost" value={CMCOST} />
				</Grid>
			</Grid>

			<Grid container pt={2} direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={3} columnSpacing={0.8} wrap={"wrap"}>
				<Grid item ml={-0.3} pr={0.2} xl={2.4} lg={2.4} md={4} sm={4} xs={6}>
					<Button fullWidth variant="contained" onClick={handleSeeUpdateQtyClicked}>
						see updated qty
					</Button>
				</Grid>
				<Grid item ml={-0.1} pr={0.2} xl={2.4} lg={2.4} md={4} sm={4} xs={6}>
					<Button fullWidth variant="contained" onClick={handleCountryHTSClicked}>
						Country HTS
					</Button>
				</Grid>
				<Grid item ml={-0.1} pr={0.2} xl={2.4} lg={2.4} md={4} sm={4} xs={6}>
					<Button fullWidth variant="contained" onClick={handleECCNHTSClicked}>
						ECCN/HTS - US
					</Button>
				</Grid>
				<Grid item ml={-0.1} pr={0.2} xl={2.4} lg={2.4} md={4} sm={4} xs={6}>
					<Button fullWidth variant="contained" onClick={handlePoHistoryClicked}>
						PO History (PLM)
					</Button>
				</Grid>
				{/* <Grid item xl={2} lg={2} md={4} sm={6} xs={6}>
					<Button fullWidth variant="contained">
						Demand - MSC (PLM)
					</Button>
				</Grid> */}
			</Grid>
		</Fragment>
	);
};

export default InfoQuantityAndCost;
