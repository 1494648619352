// Packages Import
import { Fragment, useState, useEffect } from "react";
import {
	Box,
	Paper,
	Tooltip,
	Typography,
	Button,
	Modal,
	Stack,
	Grid,
	Autocomplete,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
	Checkbox,
	List,
	ListItemButton,
	ListItemText,
	listItemButtonClasses,
	AppBar,
	Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Project Component
import ColoredTextField from "../../../UI/ColoredTextField";
import DropdownFilter from "../../../UI/DropdownFilter";
import useHttp from "../../../../Hooks/use-http";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import { reportsActions } from "../../../../store/Reports/Reports-slice";
import { APIURL, PDF_GEN_JWT_TOKEN_URL, PDF_GEN_URL, OSAltKey, isNumber, convertTrueFalse } from "../../../Util/Util";
import SetFileName from "../../../Util/SetFileName";
import useDownloadFileWithRename from "../../../../Hooks/use-http-excel-r";

let inputTimeOut;

const FinalMlarReport = (props) => {
	const dispatch = useDispatch();
	const getBomList =
		typeof props.getBomList === "function"
			? props.getBomList
			: () => {
					return;
			  };
	const { sendRequest: fetchDataWithoutLoad } = useHttp();
	const [loading, setLoading] = useState(false);
	const { downloadExcelFile } = useDownloadFileWithRename();
	const [bomListLoad, setBomListLoad] = useState(false);
	const [openSubsetList, setOpenSubsetList] = useState(false);
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const data = useSelector((state) => state.reportsState.reports.mlar.final);
	const showPopup = useSelector((state) => state.reportsState.popUpStates.mlar.final);
	const orgList = useSelector((state) => state.reportsState.orgList);
	const bomList = useSelector((state) => state.reportsState.bomList);
	const userInfo = {
		id: useSelector((state) => state.authenticationState.userNTID),
	};
	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + accessToken,
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "100%", sm: 650 },
		maxHeight: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		overflow: "auto",
		p: 4,
		pl: { xs: 1, sm: 4 },
		pr: { xs: 1, sm: 4 },
	};
	// Set File Name
	const [blob, setBlob] = useState(null);
	const [openSetFileName, setOpenSetFileName] = useState(false);
	const [fileName, setFileName] = useState("");

	const handleOrgChange = (value) => {
		dispatch(
			reportsActions.updateData({
				data: {
					userOrg: value,
				},
			})
		);
		getBomList(data.daysBack, value, setBomListLoad);
	};

	const handleJWTToken = (token, data) => {
		let filename = "";
		fetch(PDF_GEN_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.access}`,
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					throw Error(error.Error ? error.Error : response.statusText ? response.statusText : "Something Went Wrong");
				}
				const disposition = response.headers.get("content-disposition");
				filename = disposition.match(/filename=(.+)/)[1];
				return response.blob();
			})
			.then((data) => {
				setBlob(data);
				setFileName(filename);
				setOpenSetFileName(true);
				setLoading(false);
			})
			.catch((error) => {
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
				setLoading(false);
			});
	};
	const generateReport = () => {
		closeHandler("main");
		setLoading(true);
		let filename = "";
		let jsonReq = {
			userid: userInfo.id,
			userOrg: data.userOrg,
			sortBy: data.sortBy.toString(),
			checkHideNAMPNs: convertTrueFalse(data.checkHideNAMPNs),
			excelPdfReport: convertTrueFalse(data.excelPdfReport) === "1" ? "excel" : "pdf",
			uniqueValues: convertTrueFalse(data.uniqueValues),
			mlarBomOrSubset: data.mlarBomOrSubset,
		};

		if (data.mlarBomOrSubset === "subset") {
			jsonReq = {
				...jsonReq,
				mlar_ppn: data.bom,
				subsetID: data.subsetID,
			};
		}
		if (data.mlarBomOrSubset === "mlarbom") {
			jsonReq = {
				...jsonReq,
				mlar_ppn: data.selectedBom?.ppn ?? "",
				mlar_prev: data.selectedBom?.prev ?? "",
			};
		}

		console.log(jsonReq);
		if (jsonReq.excelPdfReport === "excel") {
			downloadExcelFile(
				{
					url: `${APIURL}reports/mlarFinalReport`,
					headers: headers,
					method: "POST",
					body: jsonReq,
				},
				(blob, filename) => {
					setBlob(blob);
					setFileName(filename);
					setOpenSetFileName(true);
				},
				(error) => {
					if (typeof props.onError === "function") {
						props.onError(error.toString());
					}
				},
				() => {
					setLoading(false);
				}
			);
		} else {
			fetchDataWithoutLoad(
				{
					url: `${APIURL}reports/mlarFinalReport`,
					headers: headers,
					method: "POST",
					body: jsonReq,
				},
				(data) => {
					if (data?.No_Record) {
						throw Error(data.No_Record);
					}
					fetchDataWithoutLoad(
						{
							url: PDF_GEN_JWT_TOKEN_URL,
							headers: headers,
							method: "POST",
							body: {
								username: "admin",
								password: "pass",
							},
						},
						(token) => handleJWTToken(token, data),
						(error) => {
							setLoading(false);
							if (typeof props.onError === "function") {
								props.onError(error.toString());
							}
						}
					);
				},
				(error) => {
					setLoading(false);
					if (typeof props.onError === "function") {
						props.onError(error.toString());
					}
				}
			);
		}
	};

	const handleRadioPdfSubset = (event) => {
		const val = event.target.value;
		let extraData = {};
		if (val === "subset") {
			setOpenSubsetList(true);
			extraData = {
				bom: "BomNo",
				error: {
					...data.error,
					subset: false,
				},
			};
		} else {
			extraData = {
				subsetID: "",
				subsetNameList: [],
			};
		}
		dispatch(
			reportsActions.updateData({
				data: {
					mlarBomOrSubset: val,
					...extraData,
				},
			})
		);
	};

	const closeHandler = (type = "main") => {
		if (type === "main") {
			dispatch(reportsActions.updatePopUpState());
			dispatch(reportsActions.reset());
		}
		if (type === "subsetList") {
			setOpenSubsetList(false);
		}
	};

	const handleDayNum = (event) => {
		const inpVal = event.target.value;
		if (isNumber(inpVal) || inpVal === "") {
			dispatch(
				reportsActions.updateData({
					data: {
						daysBack: inpVal,
					},
				})
			);

			if (inpVal !== "") {
				clearTimeout(inputTimeOut);
				const timeoutSec = 500;
				inputTimeOut = setTimeout(() => {
					getBomList(inpVal, data.userOrg, setBomListLoad);
				}, timeoutSec);
			}
		}
	};

	const handleMLARBOMChanged = (newValue) => {
		dispatch(
			reportsActions.updateData({
				data: {
					selectedBom: newValue,
					error: {
						...data.error,
						mlarBom: newValue === null ? true : false,
					},
					errorText: {
						...data.errorText,
						mlarBom: "",
					},
				},
			})
		);
	};
	const handleSortBy = (value) => {
		const indexVal = data.sortByList.findIndex((sortBy) => {
			return sortBy === value;
		});
		dispatch(
			reportsActions.updateData({
				data: {
					sortBy: indexVal + 1,
				},
			})
		);
	};

	const handleCheckbox = (type) => (event) => {
		dispatch(
			reportsActions.updateData({
				data: {
					[type]: event.target.checked,
				},
			})
		);
	};

	const handleBomNumber = (event) => {
		const val = event.target.value;
		if (val === "") {
			dispatch(
				reportsActions.updateData({
					data: {
						bom: val,
						error: {
							...data.error,
							subset: true,
						},
					},
				})
			);
		} else {
			dispatch(
				reportsActions.updateData({
					data: {
						bom: val,
						error: {
							...data.error,
							subset: false,
						},
					},
				})
			);
		}
	};

	const handleListItemClick = (val) => {
		dispatch(
			reportsActions.updateData({
				data: {
					subsetID: val,
				},
			})
		);
		closeHandler("subsetList");
	};

	const getSubsetList = (isLoading) => {
		fetchDataWithoutLoad(
			{
				url: `${APIURL}subset/subsetList/${userInfo.id}`,
				headers: headers,
				method: "GET",
			},
			(data) => {
				if (data.Subset_Info.length > 0) {
					const filteredData = data.Subset_Info.map(({ subsetid, ...rest }) => {
						return subsetid;
					});
					dispatch(
						reportsActions.updateData({
							data: {
								subsetNameList: filteredData,
							},
						})
					);
				}
				isLoading(false);
			},
			(error) => {
				isLoading(false);
				if (typeof props.onError === "function") {
					props.onError(error.toString());
				}
			}
		);
	};
	useEffect(() => {
		if (openSubsetList === true) {
			setLoading(true);
			getSubsetList(setLoading);
		}
	}, [openSubsetList]);
	return (
		<>
			<FullScreenLoadingIndicator loading={loading} />
			<SetFileName
				blob={blob}
				setOpenSetFileName={setOpenSetFileName}
				openSetFileName={openSetFileName}
				fileName={fileName}
			/>

			<Modal
				open={openSubsetList}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.altKey) {
							if (e.code === "KeyC") {
								closeHandler("subsetList");
							}
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Load Subset"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Stack mb={2} mt={7} spacing={2}>
						{(data.subsetNameList.length > 0 || loading) && openSubsetList ? (
							<List component="nav" aria-label="main mailbox folders" sx={{ border: 2 }}>
								{data.subsetNameList.map((subset, index) => (
									<ListItemButton
										sx={{ [`&.${listItemButtonClasses.selected}`]: { backgroundColor: "#D9D9D6" } }}
										key={index}
										// selected={data.s === subset}
										onClick={() => handleListItemClick(subset)}
									>
										<ListItemText primary={subset} />
									</ListItemButton>
								))}
							</List>
						) : (
							<Typography>No Subset Available</Typography>
						)}
					</Stack>
					<Tooltip title={`For shortcut press C`}>
						<span>
							<Button onClick={() => closeHandler("subsetList")}>
								<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
							</Button>
						</span>
					</Tooltip>
				</Box>
			</Modal>
			<Box>
				<Modal
					open={showPopup}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					disableScrollLock
				>
					<Box
						sx={style}
						onKeyDown={(e) => {
							if (e.altKey) {
								if (e.code === "KeyC") {
									closeHandler("main");
								}
								if (e.code === "KeyP") {
									if (
										(data.mlarBomOrSubset === "subset" && data.error.subset === false) ||
										(data.mlarBomOrSubset === "mlarbom" && data.error.mlarBom === false)
									) {
										generateReport();
									}
								}
							}
						}}
					>
						<Stack mb={2} spacing={1}>
							<Box sx={{ alignItems: "center", display: "flex", width: "97%" }}>
								<Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
									Final Report
								</Typography>
								<DropdownFilter
									width={90}
									selections={orgList}
									selectedData={data.userOrg}
									handleSelection={handleOrgChange}
									label={"User Org"}
									InputLabelProps={{
										shrink: true,
										sx: {
											fontWeight: 700,
										},
									}}
								/>
							</Box>
							<Paper sx={{ p: 2 }} elevation={5}>
								<Typography variant="subtitle">
									Select MLAR part number in the dropdown below or choose the subset button to select a subset
								</Typography>
							</Paper>
							<Grid
								container
								direction="row"
								spacing={0}
								alignItems={"center"}
								sx={{
									pt: 2,
								}}
							>
								<Grid
									item
									xl={data.mlarBomOrSubset === "subset" ? 4.5 : 12}
									lg={data.mlarBomOrSubset === "subset" ? 4.5 : 12}
									md={12}
									sm={12}
									xs={12}
								>
									<FormControl>
										<RadioGroup row onChange={handleRadioPdfSubset} value={data.mlarBomOrSubset}>
											<FormControlLabel value="mlarbom" control={<Radio />} label="Mlar List" />
											<FormControlLabel value="subset" control={<Radio />} label="Subset" />
										</RadioGroup>
									</FormControl>
								</Grid>
								{data.mlarBomOrSubset === "subset" ? (
									<Grid item xl={7.5} lg={7.5} md={12} sm={12} xs={12}>
										<Typography variant="subtitle">SubsetID: </Typography>
										<Typography variant="subtitle" color={"rgba(35, 109, 255, 1)"}>
											{data.subsetID}
										</Typography>
									</Grid>
								) : undefined}
							</Grid>
							<Grid
								container
								direction="row"
								spacing={0}
								sx={{
									pt: 1,
								}}
							>
								<Grid
									item
									xl={2}
									lg={2}
									md={2}
									sm={2}
									xs={12}
									sx={{
										pr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
									}}
								>
									{data.mlarBomOrSubset === "mlarbom" ? (
										<ColoredTextField
											label="Days Back"
											fullWidth
											focused
											onChange={handleDayNum}
											value={data.daysBack}
											InputLabelProps={{
												shrink: true,
												sx: {
													fontWeight: 700,
												},
											}}
										/>
									) : (
										<ColoredTextField
											label="REV"
											fullWidth
											focused
											value={"001"}
											InputLabelProps={{
												shrink: true,
												sx: {
													fontWeight: 700,
												},
											}}
											InputProps={{
												readOnly: true,
											}}
										/>
									)}
								</Grid>
								<Grid
									item
									xl={10}
									lg={10}
									md={10}
									sm={10}
									xs={12}
									sx={{
										pt: { xs: 1.5, sm: 0, md: 0, lg: 0, xl: 0 },
									}}
								>
									{data.mlarBomOrSubset === "mlarbom" ? (
										<Autocomplete
											loading={bomListLoad}
											loadingText={
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													<CircularProgress color="inherit" size={"20%"} />
												</Box>
											}
											options={!bomListLoad ? bomList : []}
											value={data.selectedBom}
											getOptionLabel={(option) => `${option.ppn}`}
											renderOption={(props, option) => {
												return (
													<li {...props}>
														<Box>
															<Typography
																align="center"
																width={{ xs: "60px", sm: "130px" }}
																variant="body2"
															>{`${option.ppn}`}</Typography>
														</Box>
														<Box borderLeft={"1px Solid"} ml={2}>
															<Typography pl={1.5} minWidth={"35px"} variant="body2">{`${option.prev}`}</Typography>
														</Box>
														<Box borderLeft={"1px Solid"} ml={2}>
															<Typography pl={1.5} variant="body2">{`${option.date}`}</Typography>
														</Box>
														<Box borderLeft={"1px Solid"} ml={2}>
															<Typography pl={1.5} variant="body2">{`${option.org}`}</Typography>
														</Box>
													</li>
												);
											}}
											renderInput={(params) => (
												<ColoredTextField
													label={<b>choose MLAR/BOM #</b>}
													focused
													error={data.error.mlarBom}
													helperText={data.errorText.mlarBom}
													{...params}
												/>
											)}
											isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
											onChange={(event, newValue) => handleMLARBOMChanged(newValue)}
										/>
									) : (
										<ColoredTextField
											label="Bom Number to change"
											fullWidth
											focused
											onChange={handleBomNumber}
											value={data.bom}
											InputLabelProps={{
												shrink: true,
												sx: {
													fontWeight: 700,
												},
											}}
											error={data.error.subset}
											inputRef={(input) => data.mlarBomOrSubset === "subset" && input?.focus()}
										/>
									)}
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={0}
								sx={{
									pt: 1,
								}}
							>
								<Grid
									container
									item
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xs={12}
									alignItems={"flex-start"}
									sx={{
										pr: { xs: 0, sm: 2, md: 2, lg: 2, xl: 2 },
									}}
								>
									<DropdownFilter
										fullWidth
										selections={data.sortByList}
										selectedData={data.sortByList[data.sortBy - 1]}
										handleSelection={handleSortBy}
										label={"Sort By"}
										InputLabelProps={{
											shrink: true,
											sx: {
												fontWeight: 700,
											},
										}}
									/>
								</Grid>
								<Grid container item xl={6} lg={6} md={6} sm={6} xs={12} alignItems={"center"}>
									<FormControlLabel
										label="Export to excel file"
										control={<Checkbox checked={data.excelPdfReport} />}
										onChange={handleCheckbox("excelPdfReport")}
									/>
								</Grid>
								<Grid
									container
									item
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									alignItems={"flex-start"}
									sx={{
										pt: 1,
									}}
								>
									<FormControlLabel
										label="Hide NoneApproved and disabled MPNs (except for pref_code=4)"
										control={<Checkbox checked={data.checkHideNAMPNs} />}
										onChange={handleCheckbox("checkHideNAMPNs")}
									/>

									<FormControlLabel
										label="Unique values (no MFG PN)"
										control={<Checkbox checked={data.uniqueValues} />}
										onChange={handleCheckbox("uniqueValues")}
										disabled={!data.excelPdfReport}
									/>
								</Grid>
							</Grid>

							<Grid
								container
								direction="row"
								spacing={0}
								sx={{
									p: 0,
								}}
							>
								<Grid
									item
									xl={6.1}
									lg={6.1}
									md={6.1}
									sm={6.1}
									xs={6.1}
									sx={{
										pr: 1,
									}}
								>
									<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
										<span>
											<Button sx={{ width: "100%", ml: 0 }} onClick={() => closeHandler("main")}>
												<u
													style={{
														textDecorationThickness: "2.5px",
														fontWeight: "bold",
													}}
												>
													C
												</u>
												ancel
											</Button>
										</span>
									</Tooltip>
								</Grid>
								<Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
									<Tooltip title={`For shortcut press ${OSAltKey} + P`}>
										<span>
											<Button
												sx={{ width: "100%", ml: 0 }}
												onClick={generateReport}
												disabled={data.mlarBomOrSubset === "subset" ? data.error.subset : data.error.mlarBom}
											>
												<u
													style={{
														textDecorationThickness: "2.5px",
														fontWeight: "bold",
													}}
												>
													P
												</u>
												{convertTrueFalse(data.excelPdfReport) === "1" ? "rint Excel" : "rint PDF"}
											</Button>
										</span>
									</Tooltip>
								</Grid>
							</Grid>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Stack>
					</Box>
				</Modal>
			</Box>
		</>
	);
};

export default FinalMlarReport;
