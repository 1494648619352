// Packages Import
import { memo } from "react"
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { cx } from "@emotion/css";

// Project Components
import InfoTable from "../../UI/Table"
import {formatDate} from "../../Util/Util"
import { mtlEngActions} from "../../../store/MtlEng/MtlEng-slice";



const columnsSettings = [
	// { field: "lName", headerName: "Last Name", type: "text", minWidth: 50, flex: 0.2 },
	{ field: "FullName", headerName: "Name", type: "text", minWidth: 50, flex: 0.2 },
	{ field: "telnet", headerName: "Telnet", type: "text", minWidth: 50, flex: 0.2 },
    // { field: "iCat_id", headerName: "ICat_ID", type: "number", minWidth: 50, flex: 0.2 },
	// { field: "orgCode", headerName: "Org Code", type: "text", minWidth: 50, flex: 0.2 },
]

function removeDuplicateObjectFromArray(array) {
	return array.reduce((arr, current) => {
		var duplicate = arr.find(item => item.FullName === current.FullName)
		if (!duplicate) {
		  return arr.concat([current])
		} else {
		  return arr
		}
	  }, [])
  }

const MtlEngTable = (props) => {
	if(props.filter){
		// console.log("Filter")
	}
	const dispatch = useDispatch();
	const row = useSelector((state) => state.mtlEngState.MTL_ENG_TABLE)
	const filteredData = removeDuplicateObjectFromArray(row)
	const columnVisibility = useSelector((state) => state.mtlEngState.tableColumnVisibilityMtlEng)

	const handleOnRowClick = (params) => {
		dispatch(mtlEngActions.filterTableWhenClicked({	table : "MTLEngTable",lname : params.row.lName,}))
	}

	const customCellClass = (params) => {
		return cx("super-app", { pointerCursor: true })	
   	}
	
	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport 
				printOptions={{
					disableToolbarButton: true,
				}} 
				csvOptions={{
					fileName: `MaterialEngineer_${props.org}_${formatDate(new Date(), "")}`
				}}
			/>
		</>
	)
	return <InfoTable 
        columnsSettings={columnsSettings} 
        rows={filteredData}
		maxtableHeight={props.maxtableHeight || 1}
		height={props.height || 319}
		autoTableHeight={typeof props.autoTableHeight === "boolean" ? props.autoTableHeight : true }
		handleOnRowClick = {handleOnRowClick}
		checkboxSelection = {false}
		customToolBar={customToolBar}
		customCellClass={customCellClass}
		ExtraProps = {{
			columnVisibilityModel:columnVisibility,
			onColumnVisibilityModelChange:(newModel) =>{
				dispatch(mtlEngActions.setColumnVisibility({columnVisibility:newModel , table:"MtlEng"}));
			}
		}}
		/>
}

export default memo(MtlEngTable)
