// Packages Import
import { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { cx } from "@emotion/css";
import moment from "moment";

// Project Components
import InfoTable from "../../UI/Table";
import { projectsAction } from "../../../store/Projects/Projects-slice";
import ColoredTextField from "../../UI/ColoredTextField";

const columnsSettings = [
	{ field: "bom", headerName: "BOM", type: "text", minWidth: 50, flex: 0.7 },
	{ field: "rev", headerName: "REV", type: "text", minWidth: 50, flex: 0.2 },
	{ field: "bomPartsNum", headerName: "No. of Bom Parts", type: "text", minWidth: 50, flex: 0.2 },
	{ field: "bomDateLoad", headerName: "Date Bom Loaded", type: "text", minWidth: 50, flex: 0.4 },
	{ field: "inputDueDate", headerName: "Input Due Dt", type: "text", minWidth: 50, flex: 0.4 },
	{ field: "rptDeliveryDueDate", headerName: "Rpt. Delivery Due Date", type: "text", minWidth: 50, flex: 0.4 },
	{ field: "dateCompleted", headerName: "Date Completed", type: "text", minWidth: 50, flex: 0.4 },
	{ field: "liaison", headerName: "Liaison", type: "text", minWidth: 50, flex: 0.4 },
	{ field: "project", headerName: "Project", type: "text", minWidth: 50, flex: 0.4 },
	{ field: "division", headerName: "Division", type: "text", minWidth: 50, flex: 0.2 },
];

const ProjectsTable = (props) => {
	const dispatch = useDispatch();
	const projectUID = useSelector((state) => state.projectsStates.projectIndex);
	const prevFilteredData = useSelector((state) => state.projectsStates.prevFilteredData);
	const columnVisibility = useSelector((state) => state.projectsStates.tableColumnVisibility);

	const handleOnRowClick = (params) => {
		dispatch(projectsAction.updateProjectIndex({ val: params.row.uid }));
	};

	const customRowClass = (params) => {
		let row = params.row;

		if (row.uid === projectUID) {
			return cx("super-app", {
				selectedRow: true,
			});
		}
	};
	const ExtraProps = {
		sx: {
			"& .MuiDataGrid-columnHeaderTitle": {
				textOverflow: "clip",
				whiteSpace: "break-spaces",
				lineHeight: 1.3,
			},
			height: 533,
		},
		headerHeight: 105,
		columnVisibilityModel: columnVisibility,
		onColumnVisibilityModelChange: (newModel) => {
			dispatch(projectsAction.setColumnVisibility({ columnVisibility: newModel }));
		},
	};
	const customToolBar = () => (
		<>
			<ColoredTextField
				label="Selected Org"
				value={prevFilteredData.userOrg}
				focused
				autoComplete="off"
				sx={{
					width: "100px",
					mr: "4.8px",
				}}
				inputProps={{
					readOnly: true,
					sx: {
						height: "18px",
						textAlign: "center",
					},
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				printOptions={{
					disableToolbarButton: true,
				}}
				csvOptions={{
					fileName: `Projects_${prevFilteredData.userOrg}_${moment().format("YYYYMMDD")}`,
				}}
			/>
		</>
	);
	const projectTableData = useSelector((state) => state.projectsStates.PROJECT_INFO_TABLE);
	return (
		<InfoTable
			columnsSettings={columnsSettings}
			rows={projectTableData}
			maxtableHeight={10}
			height={500}
			autoTableHeight={false}
			rowsPerPageOptions={[10, 50, 100]}
			pageSize={10}
			handleOnRowClick={handleOnRowClick}
			selectionType="row"
			checkboxSelection={false}
			customRowClass={customRowClass}
			customToolBar={customToolBar}
			ExtraProps={ExtraProps}
		/>
	);
};

export default memo(ProjectsTable);
