// Packages Import
import { memo } from "react"
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { cx } from "@emotion/css";

// Project Components
import InfoTable from "../../UI/Table"
import {storePartHistories, DateSortingComparator} from "../../Util/Util"
import { useNavigate } from "react-router-dom"
import { mtlEngActions} from "../../../store/MtlEng/MtlEng-slice";

const columnsSettings = [
	{ field: "item", headerName: "Item", type: "text", minWidth: 50, flex: 0.3 },
	{ field: "desc", headerName: "Description", type: "text", minWidth: 70, flex: 1 },
	// { field: "lName", headerName: "Last Name", type: "text", minWidth: 50, flex: 0.2 },
    // { field: "modBy", headerName: "Modified By", type: "text", minWidth: 50, flex: 0.2 },
	{ field: "tMod", headerName: "Date", type: "text", minWidth: 50, flex: 0.3, sortComparator: DateSortingComparator },
]

const ItemTable = (props) => {
	const dispatch = useDispatch();
	const row = useSelector((state) => state.mtlEngState.ITEM_TABLE)
	const columnVisibility = useSelector((state) => state.mtlEngState.tableColumnVisibilityItem)
	const navigate = useNavigate()
	const handleOnRowClick = (params) => {
		storePartHistories(params.row.item)
		navigate("/Info");
	}
	const customCellClass = (params) => {
		return cx("super-app", { pointerCursor: true })	
   	}
	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
		</>
	)
	return <InfoTable 
        columnsSettings={columnsSettings} 
        rows={row}
		maxtableHeight={props.maxtableHeight || 1}
		height={props.height || 319}
		autoTableHeight={typeof props.autoTableHeight === "boolean" ? props.autoTableHeight : true }
		handleOnRowClick={handleOnRowClick}
		checkboxSelection = {false}
		customToolBar={customToolBar}
		customCellClass={customCellClass}
		ExtraProps = {{
			columnVisibilityModel:columnVisibility,
			onColumnVisibilityModelChange:(newModel) =>{
				dispatch(mtlEngActions.setColumnVisibility({columnVisibility:newModel , table:"Item"}));
			}
		}}
		/>
}

export default memo(ItemTable)
