// Packages Import
import React, { Fragment , useEffect, useState } from "react"
import { Box} from "@mui/material"
import { useDispatch, useSelector } from "react-redux";

// Project Component
import useHttp from "../../../Hooks/use-http"
import FullScreenLoadingIndicator from "../../UI/FullScreenLoadingIndicator"
import { findPartAction } from "../../../store/common/findPart-slice"
import { rrActions } from "../../../store/RR/RR-slice"
import { APIURL } from "../../Util/Util"
import ErrorBox from "../../Util/ErrorBox"
import RRHeader from "./RRHeader";
import RRSOLinesTable from "./Tables/RRSOLinesTable";
import RRWOLinesTable from "./Tables/RRWOLinesTable";
import RRPOLinesTable from "./Tables/RRPOLinesTable";
import BottomNavigation from "./RRBottomBar";


const RR = () => {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);

	const headers = { "Content-Type": "application/json", Authorization: "Bearer " + accessToken };
	// const headers = { "Content-Type": "application/json"};
	const { isLoading, sendRequest: fetchData } = useHttp()
	const [errorMsg , setErrorMsg] = useState("")
	const [errorState , setErrorState] = useState(false)
	const showFindPart = useSelector((state) => state.findPartState.showFindPart);
	const reloadPartNumber = useSelector((state) => state.rrState.reloadPartNumber);
	const updateData = (partNumber) => (data) => {
		// Temporary work around until backend pass the correct value
		const partInfo = data.PARTS_INFO || data.PART_INFO
		if(!Array.isArray(partInfo) && Object.keys(partInfo).length !== 0){
			dispatch(findPartAction.setShowFindPart(false));
			dispatch(rrActions.updateAll({ data: data}))
		}else{
			console.log("Here")
			if (!showFindPart) {
				setErrorMsg(`Part Number not available`);
				setErrorState(true);
				const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded"));
				if (subsetLoaded) {
					dispatch(rrActions.reset());
					dispatch(rrActions.setPartNumberForSubset(partNumber));
				}
			} else {
				dispatch(findPartAction.setPartFound(false));
			}
		}
	}

	const APIError = (partNumber) => (error) => {
		console.log(`ERROR: Unable to retrieve ${partNumber} : ${error}`);
		setErrorMsg(`ERROR: ${error}`);
		setErrorState(true);

	};

	const changePart = (partNumber) => {
		fetchData({ 
			url: `${APIURL}rr/rrlist/${partNumber}/`, headers: headers
		}, updateData(partNumber) , APIError(partNumber))
	}

	useEffect(() => {
		const partNumber = window.sessionStorage.getItem("PartNumber")
		if (partNumber) {
			changePart(partNumber)
		}else{
			dispatch(findPartAction.setShowFindPart(true));
		}
		
	}, [reloadPartNumber]); // eslint-disable-line react-hooks/exhaustive-deps

	
	return (
		<React.StrictMode>
			<Fragment>
				<FullScreenLoadingIndicator loading={isLoading} />
				<Box 
					maxWidth={"85%"} 
					mx={"auto"} 
					mt={2}
				>
                    <RRHeader />
                    <RRWOLinesTable />
                    <RRPOLinesTable />
                    <RRSOLinesTable />
				</Box>
				<BottomNavigation 
					changePart={changePart}
				/>
				<ErrorBox 
					msg = {errorMsg}
					setErrorState = {setErrorState}
					ErrorState = {errorState}
				/>
			</Fragment>
		</React.StrictMode>
		
	)
}

export default RR