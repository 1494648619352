import { Box, Modal, Stack } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "40%", lg: "30%", xl: "20%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const ControlledMessageBox = (props) => {
	const { open, message } = props;

	return (
		<Box>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box sx={style}>
					<Stack mb={2} spacing={2}>
						{message}
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default ControlledMessageBox;
