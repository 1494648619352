// React
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
// UI
import { Box, Button, Modal, Stack, AppBar, Toolbar, Typography, Tooltip } from "@mui/material";
import InfoTable from "../../../UI/Table";
import ErrorBox from "../../../Util/ErrorBox";
import FullScreenLoadingIndicator from "../../../UI/FullScreenLoadingIndicator";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	useGridApiContext,
} from "@mui/x-data-grid";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ExportBrowseSubset from "./ExportBrowseSubset";
// Packages import
import useHttp from "../../../../Hooks/use-http";
import { APIURL, formatDate, OSAltKey } from "../../../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: "80%", md: "80%", lg: "80%", xl: "80%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	maxHeight: "90%",
	overflowY: "auto",
	p: 4,
	pb: 0,
};

const defaultColumnVisibility = {
	itemname: true,
	item_desc: true,
	orgcode: true,
	pref_code: true,
	stat_code: true,
	cat_code: true,
	mtl_eng_name: true,
};

const Browse = (props) => {
	const { open, handleBrowseClicked, changePart } = props;

	const { sendRequest: fetchData, isLoading } = useHttp();
	const accessToken = useSelector((state) => state.authenticationState.accessToken);
	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [openExportSubset, setOpenExportSubset] = useState(false);
	const [exportPartNumbers, setExportPartNumbers] = useState([]);
	const [refreshCount, setRefreshCount] = useState(1);

	const userNTID = useSelector((state) => state.authenticationState.userNTID);

	const subsetName = JSON.parse(window.sessionStorage.getItem("subsetName"));

	const [columnVisibility, setColumnVisibility] = useState(
		JSON.parse(window.localStorage.getItem("infoBrowseColumnVisibility")) || defaultColumnVisibility
	);

	const [rows, setRows] = useState([]);

	const columnsSettings = [
		{ field: "itemname", headerName: "Item", type: "text", minWidth: 130, flex: 0.3 },
		{ field: "item_desc", headerName: "Item_Desc", type: "text", minWidth: 450, flex: 1 },
		{ field: "orgcode", headerName: "OrgCode", type: "text", minWidth: 90, flex: 0.2 },
		{
			field: "pref_code",
			headerName: "Pref_Code",
			type: "text",
			minWidth: 90,
			flex: 0.2,
			// sortComparator: (a, b, cellParams1, cellParams2) => {
			// 	if (a === b) {
			// 		// Retain itemName arrangement
			// 		return cellParams1.rowIndex - cellParams2.rowIndex;
			// 	}
			// 	return a < b ? -1 : 1;
			// },
		},
		{ field: "stat_code", headerName: "Stat_Code", type: "text", minWidth: 100, flex: 0.3 },
		{ field: "cat_code", headerName: "Cat_Code", type: "text", minWidth: 160, flex: 0.5 },
		{ field: "mtl_eng_name", headerName: "mtl_eng_name", type: "text", minWidth: 180, flex: 0.5 },
	];

	useEffect(() => {
		const defaultRow = [
			{
				id: 0,
				itemname: "",
				item_desc: "",
				orgcode: "",
				pref_code: "",
				stat_code: "",
				cat_code: "",
				mtl_eng_name: "",
			},
		];

		if (subsetName && open) {
			setRows(defaultRow);

			const subsetLoaded = JSON.parse(window.sessionStorage.getItem("subsetLoaded"));

			if (subsetLoaded === true || subsetLoaded === "SUBSET") {
				const body = {
					userid: userNTID,
					subsetid: subsetName,
				};
				fetchData(
					{ url: `${APIURL}Info/browseSubset`, headers, method: "POST", body },
					(data) => {
						console.log(data);
						const subsetPartNumbers = JSON.parse(window.sessionStorage.getItem("subsetPartNumbers"));
						// rows will need to be sorted based on the item load order
						const newRows =
							data?.ITEM_INFO && data?.ITEM_INFO?.length > 0
								? data?.ITEM_INFO.sort((a, b) => {
										const aIndex = subsetPartNumbers.indexOf(a.itemname);
										const bIndex = subsetPartNumbers.indexOf(b.itemname);
										return aIndex - bIndex;
								  }).map((data, index) => ({ ...data, id: index }))
								: defaultRow;
						setRows(newRows.filter((a) => subsetPartNumbers.includes(a.itemname)));
					},
					(error) => {
						console.log(`Unable to retrieve browse Information, Error: ${error}`);
						setErrorMsg(`ERROR: Unable to retrieve browse Information, please try refresh the page. Error: ${error}`);
						setErrorState(true);
					}
				);
			} else if (subsetLoaded === "MLAR") {
				const BOM_Info = JSON.parse(window.sessionStorage.getItem("filterInfo"));
				const body = {
					userid: userNTID,
					bom_ppn: subsetName,
					bom_prev: BOM_Info.bom_prev,
					userorg: BOM_Info.userorg,
				};
				fetchData(
					{ url: `${APIURL}Info/browseMLARBOM`, headers, method: "POST", body },
					(data) => {
						console.log(data);
						const subsetPartNumbers = JSON.parse(window.sessionStorage.getItem("subsetPartNumbers"));
						const newRows =
							data?.ITEM_INFO && data?.ITEM_INFO?.length > 0 ? data?.ITEM_INFO.map((data, index) => ({ ...data, id: index })) : defaultRow;
						setRows(newRows.filter((a) => subsetPartNumbers.includes(a.itemname)));
					},
					(error) => {
						console.log(`Unable to retrieve browse Information, Error: ${error}`);
						setErrorMsg(`ERROR: Unable to retrieve browse Information, please try refresh the page. Error: ${error}`);
						setErrorState(true);
					}
				);
			} else if (subsetLoaded === "MTL_ENG") {
				const MTL_ENG_Info = JSON.parse(window.sessionStorage.getItem("filterInfo"));
				const subsetPartNumbers = JSON.parse(window.sessionStorage.getItem("subsetPartNumbers"));

				const body = {
					userid: userNTID,
					mtleng: MTL_ENG_Info.userid,
					partnumber: subsetPartNumbers,
				};
				fetchData(
					{ url: `${APIURL}Info/browseMTLEngPN`, headers, method: "POST", body },
					(data) => {
						console.log(data);
						const newRows =
							data?.ITEM_INFO && data?.ITEM_INFO?.length > 0 ? data?.ITEM_INFO.map((data, index) => ({ ...data, id: index })) : defaultRow;
						setRows(newRows);
					},
					(error) => {
						console.log(`Unable to retrieve browse Information, Error: ${error}`);
						setErrorMsg(`ERROR: Unable to retrieve browse Information, please try refresh the page. Error: ${error}`);
						setErrorState(true);
					}
				);
			}
		}
	}, [fetchData, headers, open, subsetName, userNTID, refreshCount]);

	const handleOnRowClick = (params) => {
		console.log(params);
		changePart(params.row.itemname);
		handleBrowseClicked();
	};

	const ExportSubset = () => {
		const apiRef = useGridApiContext();
		const partNumber = [...apiRef.current.getVisibleRowModels()].map(([key, value]) => value.itemname || []);
		return (
			<Button
				component="label"
				startIcon={<ImportExportIcon />}
				disabled={partNumber.length === 0}
				onClick={() => {
					if (partNumber && partNumber.length > 0) {
						setExportPartNumbers(partNumber);
						setOpenExportSubset(true);
					}
				}}
			>
				Update Subset
			</Button>
		);
	};

	const customToolBar = () => {
		return (
			<>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport
					printOptions={{
						disableToolbarButton: true,
					}}
					csvOptions={{
						fileName: `Browse_${subsetName}_${formatDate(new Date(), "")}`,
					}}
				/>
				<ExportSubset />
			</>
		);
	};

	return (
		<Box>
			<FullScreenLoadingIndicator loading={isLoading} />
			<ExportBrowseSubset
				partNumbers={exportPartNumbers}
				open={openExportSubset}
				onClose={() => setOpenExportSubset(false)}
				setRefreshCount={() => setRefreshCount(refreshCount + 1)}
			/>
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />

			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
				<Box
					sx={style}
					onKeyDown={(e) => {
						if (e.code === "KeyC" && e.altKey === true) {
							handleBrowseClicked();
						}
					}}
				>
					<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
						<Toolbar>
							<Typography variant="h6" component="div" color="#fff">
								{"Browse"}
							</Typography>
						</Toolbar>
					</AppBar>
					<Stack mt={5} mb={2} spacing={2}>
						<InfoTable
							customToolBar={customToolBar}
							columnsSettings={columnsSettings}
							rows={rows}
							checkboxSelection={false}
							handleOnRowClick={handleOnRowClick}
							pageSize={100}
							autoTableHeight={false}
							rowHeight={45}
							height={400}
							ExtraProps={{
								columnVisibilityModel: columnVisibility,
								onColumnVisibilityModelChange: (newModel) => {
									window.localStorage.setItem("infoBrowseColumnVisibility", JSON.stringify(newModel));
									setColumnVisibility(newModel);
								},
							}}
						/>
						<Box>
							<Tooltip title={"Press C to close"}>
								<Button onClick={handleBrowseClicked}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>lose
								</Button>
							</Tooltip>
							<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
};

export default Browse;
