// React
import { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { findPartAction } from "../../../../store/common/findPart-slice";
import { wuBomAction, defaultComponentTableRows, defaultParentTableRows } from "../../../../store/WuBom/WuBom-slice";
// MUI
import { Button, Box, MenuItem, Tooltip, IconButton, Typography, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// Projects
import FindPart from "../../../Util/FindPart";
import BottomBarNavigation from "../../../Layout/BottomBarNavigation";
import Mousetrap from "mousetrap";

const WuBomBottomNavigation = (props) => {
	const dispatch = useDispatch();
	const selectedHistory = useSelector((state) => state.wuBomState.partNumber) || "";
	const componentTableRows = useSelector((state) => state.wuBomState.componentTableRows);
	const parentTableRows = useSelector((state) => state.wuBomState.parentTableRows);
	const disablePrintBOM = JSON.stringify(componentTableRows) === JSON.stringify(defaultComponentTableRows);
	const disablePrintWhereUse = JSON.stringify(parentTableRows) === JSON.stringify(defaultParentTableRows);

	const [anchorElNav, setAnchorElNav] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const HandleFindClicked = useCallback(() => {
		handleCloseNavMenu();
		dispatch(findPartAction.setPartFound(true));
		dispatch(findPartAction.setShowFindPart(true));
	}, [dispatch]);

	useEffect(() => {
		Mousetrap.bind("ctrl+shift+f", (e) => {
			e.preventDefault();
			HandleFindClicked();
		});
		return () => Mousetrap.unbind("ctrl+shift+f");
	}, [HandleFindClicked]);

	const handlePrint = (option) => {
		switch (option) {
			case "printBom":
				dispatch(wuBomAction.handleOpenPrintBOM());
				break;
			case "printWhereUse":
				dispatch(wuBomAction.handleOpenWhereUse());
				break;
			case "printPTO":
				dispatch(wuBomAction.handleOpenPrintPTO());
				break;
			default:
				break;
		}
	};

	return (
		<Fragment>
			<Box position={"fixed"} bottom={0} width={"100%"} display="flex" alignItems={"center"} bgcolor={"#EBEBEB"} zIndex={1100}>
				<Box m={2} sx={{ mx: "auto" }}>
					<Box sx={{ display: "flex" }}>
						<BottomBarNavigation changePart={props.changePart} selectedHistory={selectedHistory} />

						<Box pl={3} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" } }}>
							<Tooltip title="Open Find Window (CTRL + Shift + F)">
								<span>
									<Button variant="contained" onClick={HandleFindClicked}>
										Find
									</Button>
								</span>
							</Tooltip>
							<Button variant="contained" onClick={() => handlePrint("printPTO")}>Print where use PTO</Button>
							<Button variant="contained" disabled={disablePrintBOM} onClick={() => handlePrint("printBom")}>
								Print BOM
							</Button>
							<Button variant="contained" disabled={disablePrintWhereUse} onClick={() => handlePrint("printWhereUse")}>
								Print where use
							</Button>
						</Box>

						<Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" } }}>
							<IconButton
								size="Medium"
								aria-label="Footer buttons"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								{anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								disableScrollLock
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
								}}
							>
								<MenuItem key={"Find"} onClick={HandleFindClicked}>
									<Typography textAlign="center">{"Find"}</Typography>
								</MenuItem>
								<MenuItem key={"Print where use PTO"}>
									<Typography textAlign="center" onClick={() => handlePrint("printPTO")}>{"Print where use PTO"}</Typography>
								</MenuItem>
								<MenuItem key={"Print BOM"} disabled={disablePrintBOM} onClick={() => handlePrint("printBom")}>
									<Typography textAlign="center">{"Print BOM"}</Typography>
								</MenuItem>
								<MenuItem key={"Print where use"} disabled={disablePrintWhereUse} onClick={() => handlePrint("printWhereUse")}>
									<Typography textAlign="center">{"Print where use"}</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
			<FindPart changePart={props.changePart} />
		</Fragment>
	);
};

export default WuBomBottomNavigation;
