// Packages Import
import { memo } from "react"
import { Grid , Box } from "@mui/material"
import { useSelector } from "react-redux";

// Project Components
import ColoredTextField from "../../UI/ColoredTextField"

const NoteHeader = (props) => {
    const gridOrder = {
        part : {
            xl: 1,
            lg: 1,
            md: 1, 
            sm: 1, 
            xs: 1 
        },
        prefCode : {
            xl: 2,
            lg: 2,
            md: 2, 
            sm: 2, 
            xs: 2 
        },
        iCat : {
            xl: 3,
            lg: 3,
            md: 3, 
            sm: 3, 
            xs: 3 
        },
        desc : {
            xl: 4,
            lg: 4,
            md: 4, 
            sm: 4, 
            xs: 4 
        }
    }
    const partInfo = useSelector((state) => state.noteStates.PART_INFO)
    return (
        <Box
            maxWidth={"85%"}
            mx={"auto"}
            pt={1.5}
        >
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                wrap={"wrap"}>
                <Grid 
                    item 
                    xl={2} lg={2} md={6} sm={9} xs={9}
                    order={gridOrder.part}>
                    <ColoredTextField
                        label="Keysight Part"
                        focused
                        fullWidth
                        value={partInfo.part}
                        inputProps={{ 
                            readOnly: true,
                            style: { 
                                fontWeight: "bold" 
                            } 
                        }}
                        InputLabelProps={{ 
                            shrink: true, 
                            style: { 
                                fontWeight: "bold" 
                            } 
                        }}
                    />

                </Grid>
                <Grid 
                    item 
                    xl={1} lg={1} md={6} sm={3} xs={3}
                    order={gridOrder.prefCode}>
                    <ColoredTextField
                        label="PrefCode"
                        focused
                        fullWidth
                        value={partInfo.prefCode}
                        inputProps={{ 
                            readOnly: true,
                        }}
                        InputLabelProps={{ 
                            shrink: true 
                        }}
                    />
                </Grid>
                <Grid 
                    item 
                    xl={4} lg={4} md={6} sm={12} xs={12}
                    order={gridOrder.iCat}>
                    <ColoredTextField
                        label="iCat"
                        focused
                        fullWidth
                        value={partInfo.iCat}
                        inputProps={{ 
                            readOnly: true,
                        }}
                        InputLabelProps={{ 
                            shrink: true 
                        }}
                    />
                </Grid>
                <Grid 
                    item 
                    xl={5} lg={5} md={6} sm={12} xs={12}
                    order={gridOrder.desc}>
                    <ColoredTextField
                        label="Description"
                        focused
                        fullWidth
                        value={partInfo.desc}
                        inputProps={{ 
                            readOnly: true,
                        }}
                        InputLabelProps={{ 
                            shrink: true 
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
        
    )
}
export default memo(NoteHeader)