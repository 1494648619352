// React
import { useSelector, useDispatch } from "react-redux";
import { RoHSAction } from "../../../../store/RoHS/RoHS-slice";

// UI
import { Box, Typography, Grid, FormGroup, FormControlLabel, Checkbox, Stack } from "@mui/material";
import RoHSMpnNotesTable from "../Table/RoHSMpnNotesTable";
import RoHSPartNoteTable from "../Table/RoHSPartNoteTable";
import RoHSLinkDocument from "./RoHSLinkDocument";
import RoHSMCC from "./RoHSMCC";

const RoHSBody = (props) => {
	const dispatch = useDispatch();
	const selectedMpn = useSelector((state) => state.rohsStates.selectedMpn);
	const selectedMfgName = useSelector((state) => state.rohsStates.selectedMfgName);
	const mpnNoteInfo = useSelector((state) => state.rohsStates.mpnNoteInfo);
	const rohsNoteInfo = useSelector((state) => state.rohsStates.rohsNoteInfo);
	const mccChangeRequest = useSelector((state) => state.rohsStates.mccChangeRequest);

	const changeMCCRequestHandler = () => {
		if (!mccChangeRequest.checkBoxDisabled && !props.nonMtlEngAccess) dispatch(RoHSAction.setShowChangeMCC(true));
	};

	return (
		<Box mt={1}>
			<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1.5} columnSpacing={0.8} wrap={"wrap"}>
				<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
					<RoHSMpnNotesTable rows={mpnNoteInfo} mfgName={selectedMfgName} mfgPN={selectedMpn} />
				</Grid>
				<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
					<RoHSPartNoteTable rows={rohsNoteInfo} title={"Note associated with Keysight Part Number"} />
				</Grid>

				<Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={-1.5}>
					<Stack direction="row" alignItems="center">
						{mccChangeRequest.visible && (
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											disabled={mccChangeRequest.checkBoxDisabled}
											checked={mccChangeRequest.checked}
											onChange={changeMCCRequestHandler}
											inputProps={{
												disabled: false,
											}}
										/>
									}
									label="Change Request to MCC"
									sx={{ marginRight: 1, "& .MuiTypography-root": { fontSize: "0.95rem" } }}
								/>
							</FormGroup>
						)}
						{mccChangeRequest.labelVisibility && (
							<Typography
								variant="caption"
								sx={{ backgroundColor: mccChangeRequest.color }}
							>{`requested by: ${mccChangeRequest.mccreqby} send to MCC on ${mccChangeRequest.mccreqmod}`}</Typography>
						)}
					</Stack>
				</Grid>

				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<RoHSLinkDocument nonMtlEngAccess={props.nonMtlEngAccess} hiddenFileInputRef={props.hiddenFileInputRef} />
				</Grid>

				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<RoHSMCC />
				</Grid>
			</Grid>
		</Box>
	);
};

export default RoHSBody;
