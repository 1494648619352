// React
import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { prefCodeAction } from "../../store/common/PrefCode-slice";
import { useLocation } from "react-router-dom";
import { noteAction } from "../../store/Note/Note-slice";
// UI
import {
	Box,
	Button,
	Modal,
	AppBar,
	Toolbar,
	Typography,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Tooltip,
	FormHelperText,
	Input,
	Stack,
} from "@mui/material";
import ColoredTextField from "../UI/ColoredTextField";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import ErrorBox from "../Util/ErrorBox";
import FullScreenLoadingIndicator from "../UI/FullScreenLoadingIndicator";
import MessageBox from "../Util/MessageBox";
import ConfirmationBox from "../Util/ConfirmationBox";
// Package Import
import useHttp from "../../Hooks/use-http";
import { APIURL, OSAltKey } from "../Util/Util";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "100%", sm: "80%", md: "80%", lg: "60%", xl: "60%" },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const PrefCode = (props) => {
	const { open, handleOpenPrefCode, partNumber } = props;
	const dispatch = useDispatch();

	const { pathname: urlPathName } = useLocation();

	const { sendRequest: fetchData, isLoading } = useHttp();
	const { sendRequest: fetchData2, isLoading: isLoading2 } = useHttp();

	const reasonforChange = useSelector((state) => state.prefCodeState.reasonforChange);
	const {
		prefCodeList,
		note,
		newPC,
		requestedNewPC,
		oraclePC,
		reqStat,
		reviewStatus,
		sameRequestor,
		isF01Approval,
		isReqNonF01,
		requestorName,
		requestor,
		reasonNonF01,
		f01Response,
	} = useSelector((state) => state.prefCodeState);
	const { primaryOrg, allOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);
	const { userNTID, userName, accessToken } = useSelector((state) => state.authenticationState);

	const [errorMsg, setErrorMsg] = useState("");
	const [ErrorState, setErrorState] = useState(false);
	const [isNotER00, setIsNotER00] = useState(false);
	const [openHowItWorks, setOpenHowItWorks] = useState(false);
	const [openGiveReason, setOpenGiveReason] = useState(false);
	const [openNewPrefCodeEmpty, setOpenNewPrefCodeEmpty] = useState(false);
	const [openSamePrefCode, setOpenSamePrefCode] = useState(false);
	const [openSamePrefCodePendingRequest, setOpenSamePrefCodePendingRequest] = useState(false);
	const [openConfirmOverwriteReq, setOpenConfirmOverwriteReq] = useState(false);
	const [openConfirmDeleteMessage, setOpenConfirmDeleteMessage] = useState(false);
	const [openApproveMessage, setOpenApproveMessage] = useState(false);
	const [openRejectMessage, setOpenRejectMessage] = useState(false);
	const [openResult, setOpenResult] = useState(false);
	const [resultMessage, setResultMessage] = useState(false);
	const [responseError, setResponseError] = useState("");

	const inputRef = useRef(null);

	const focusInput = () => {
		inputRef.current.focus();
	};

	// const reviewStatusNotApproved = reviewStatus !== "Approved";

	const headers = useMemo(() => ({ "Content-Type": "application/json", Authorization: "Bearer " + accessToken }), [accessToken]);
	const disableDeleteRequest = (requestedNewPC ? false : true) || reqStat !== "0";

	// This controls on which circumtances user can perform edit
	// F01 user change their own request - yes
	// F01 user change other F01 user request - no
	// Non F01 user change their own request - yes
	// Non F01 user change other F01 or non F01 user request - no
	const userIsF01 = allOrg.includes("F01");
	let allowEditing = false;
	if (reviewStatus !== "Approved") {
		if (userIsF01) {
			allowEditing = !isF01Approval && ((newPC ? true : false) || (userIsF01 && !isF01Approval));
		} else {
			allowEditing = (sameRequestor && isReqNonF01 === "Yes") || (!reviewStatus && !requestor);
		}
	}

	useEffect(() => {
		dispatch(prefCodeAction.reset());
		dispatch(prefCodeAction.setIsF01Approval(false));

		if (open) {
			fetchData(
				{ url: `${APIURL}chgRequest/prefCodeList`, headers },
				(data) => {
					const prefCodeList = data.PREF_CODE_LIST.map((data) => {
						return {
							pref_code: data.pref_code,
							description: data.description,
						};
					});
					dispatch(prefCodeAction.setPrefCodeList(prefCodeList));

					fetchData2(
						{ url: `${APIURL}chgRequest/prefCodeInfo/${partNumber}/${primaryOrg}/`, headers },
						(data) => {
							console.log(data);
							if (data.er !== "00" && !userIsF01) {
								// No preference Code Changes on Divisional parts
								setIsNotER00(true);
							} else {
								dispatch(prefCodeAction.setPrefCodeInfo({ data, userNTID, userIsF01 }));
								focusInput();
								setResponseError("");
							}
						},
						(error) => {
							console.log(`ERROR: Unable to Retrieve Pref code, ${error}`);
							setErrorMsg(`ERROR: Unable to Retrieve Pref Code, please re-open this popup and try again. ${error}`);
							setErrorState(true);
						}
					);
				},
				(error) => {
					console.log(`ERROR: Unable to Retrieve Pref code list, ${error}`);
					setErrorMsg(`ERROR: Unable to Retrieve Pref Code, please re-open this popup and try again. ${error}`);
					setErrorState(true);
				}
			);
		}
	}, [dispatch, fetchData, fetchData2, headers, open, partNumber, primaryOrg, userIsF01, userNTID]);

	const HowItWorks = () => (
		<>
			<Typography>{`1) Enter new preference code`}</Typography>
			<Typography>{`2) Click Save...`}</Typography>
			<Typography>{`This request will be reviewed by MTL Engineering in Santa Rosa, at the end of current week`}</Typography>
			<Typography>{`the Change should be implemented with-in couple of weeks.`}</Typography>
			<Typography>{`if you find that is not implemented please contact MTL engineering = Santa Rosa...`}</Typography>
		</>
	);

	const copyNoteClicked = () => {
		dispatch(prefCodeAction.setCopyNote(note));
	};

	const handleReasonChanged = (event) => {
		dispatch(prefCodeAction.setReasonChanged(event.target.value));
	};

	const handleResponseChanged = (event) => {
		if (responseError) {
			setResponseError("");
		}
		dispatch(prefCodeAction.setResponseChanged(event.target.value));
	};

	const submitNewPrefCode = () => {
		const body = {
			userid: userNTID,
			partnumber: partNumber,
			new_pc: newPC,
			oracle_pc: oraclePC,
			note: reasonforChange,
			is_req_nonf01: userIsF01 ? "No" : "Yes",
			user_org: primaryOrg,
			requestor_username: userName,
		};
		console.log(body);
		fetchData(
			{ url: `${APIURL}chgRequest/newPrefCodeReq`, headers, method: "POST", body },
			(data) => {
				setResultMessage(data.Result);
				setOpenResult(true);
			},
			(error) => {
				console.log(error);
				setErrorMsg(`ERROR: Unable to submit new Pref Code, please re-open this popup and try again. ${error}`);
				setErrorState(true);
			}
		);
	};

	const handleSaveClicked = () => {
		if (reasonforChange.trim() === "") {
			setOpenGiveReason(true);
		} else if (!newPC) {
			setOpenNewPrefCodeEmpty(true);
		} else if (oraclePC === newPC) {
			// Existing oracle prefcode is same as newly selected prefcode
			setOpenSamePrefCode(true);
		} else if ((requestedNewPC ? true : false) && reqStat === "0") {
			// When pending request is available
			if (requestedNewPC === newPC) {
				// Pending request is same as new PC
				setOpenSamePrefCodePendingRequest(true);
			} else {
				setOpenConfirmOverwriteReq(true);
			}
		} else if ((requestedNewPC ? true : false) && reviewStatus === "Pending") {
			if (requestedNewPC === newPC) {
				// Pending non-f01 request is same as new PC
				setOpenSamePrefCodePendingRequest(true);
			} else {
				setOpenConfirmOverwriteReq(true);
			}
		} else {
			submitNewPrefCode();
		}
	};

	const deletePrefCodeRequest = () => {
		const body = {
			userid: userNTID,
			partnumber: partNumber,
			new_pc: requestedNewPC,
		};
		fetchData(
			{ url: `${APIURL}chgRequest/deletePrefCodeReq`, headers, method: "POST", body },
			(data) => {
				setResultMessage(data.Result);
				setOpenResult(true);
			},
			(error) => {
				console.log(`ERROR: Unable to delete Pref Code, ${error}`);
				setErrorMsg(`ERROR: Unable to delete Pref Code, please re-open this popup and try again. ${error}`);
				setErrorState(true);
			}
		);
	};

	const handleDeleteYes = () => {
		setOpenConfirmDeleteMessage(false);
		deletePrefCodeRequest();
	};

	const handleApprove = () => {
		const body = {
			userid: userNTID,
			partnumber: partNumber,
			new_pc: newPC,
			oracle_pc: oraclePC,
			note: reasonNonF01,
			prefcode_response: f01Response,
		};
		console.log(body);
		setOpenApproveMessage(false);
		fetchData(
			{ url: `${APIURL}chgRequest/approvePrefCodeReq`, headers, method: "POST", body },
			(data) => {
				console.log(data);
				setResultMessage(data.Result);
				setOpenResult(true);
			},
			(error) => {
				console.log(error);
				setErrorMsg(`ERROR: Unable to approve Pref Code, ${error}`);
				setErrorState(true);
			}
		);
	};

	const handleRejectClicked = () => {
		if (f01Response?.trim()) {
			setOpenRejectMessage(true);
		} else {
			setResponseError("Please provide a reason for rejection");
			focusInput();
		}
	};

	const handleReject = () => {
		const body = {
			userid: userNTID,
			partnumber: partNumber,
			new_pc: newPC,
			oracle_pc: oraclePC,
			note: reasonNonF01,
			prefcode_response: f01Response,
		};
		console.log(body);
		setOpenRejectMessage(false);
		fetchData(
			{ url: `${APIURL}chgRequest/rejectPrefCodeReq`, headers, method: "POST", body },
			(data) => {
				console.log(data);
				setResultMessage(data.Result);
				setOpenResult(true);
			},
			(error) => {
				console.log(error);
				setErrorMsg(`ERROR: Reject failed, ${error}`);
				setErrorState(true);
			}
		);
	};

	return (
		<Box>
			<ErrorBox msg={errorMsg} setErrorState={setErrorState} ErrorState={ErrorState} />
			<FullScreenLoadingIndicator loading={isLoading || isLoading2} />
			<MessageBox
				open={isNotER00}
				message={"Cannot change Pref Code for divisional parts"}
				messageBoxHandler={() => {
					setIsNotER00(false);
					handleOpenPrefCode();
				}}
			/>
			<MessageBox open={openHowItWorks} message={<HowItWorks />} messageBoxHandler={() => setOpenHowItWorks(false)} />
			<MessageBox
				open={openGiveReason}
				message={"Must give a reason for change, Please enter a reason"}
				messageBoxHandler={() => setOpenGiveReason(false)}
			/>
			<MessageBox
				open={openNewPrefCodeEmpty}
				message={"New preference code field is empty, Please enter new PC"}
				messageBoxHandler={() => setOpenNewPrefCodeEmpty(false)}
			/>
			<MessageBox
				open={openSamePrefCode}
				message={
					"New - requested Preference Code is the same as 'old' Oracle preference code. This is invalid request. Please change requested preference code or Cancel this request"
				}
				messageBoxHandler={() => setOpenSamePrefCode(false)}
			/>
			<MessageBox
				open={openSamePrefCodePendingRequest}
				message={
					"New - requested Preference Code is the same as 'pending' new preference code. This is invalid request. Please change requested preference code or Cancel this request"
				}
				messageBoxHandler={() => setOpenSamePrefCodePendingRequest(false)}
			/>
			<ConfirmationBox
				open={openConfirmOverwriteReq}
				handleYes={() => {
					setOpenConfirmOverwriteReq(false);
					submitNewPrefCode();
				}}
				handleNo={() => setOpenConfirmOverwriteReq(false)}
				message={`There is still open/pending change request of the Preference Code for this part. Do you want to overwrite it. Current request is for PC=${requestedNewPC}`}
			/>
			<ConfirmationBox
				open={openConfirmDeleteMessage}
				handleYes={handleDeleteYes}
				handleNo={() => setOpenConfirmDeleteMessage(false)}
				message={`There is still open/pending change request of the Preference Code for this part. Do you want to CANCEL it? Current request is for PC=${requestedNewPC}`}
			/>

			<ConfirmationBox
				open={openApproveMessage}
				handleYes={handleApprove}
				handleNo={() => setOpenApproveMessage(false)}
				message={`Approve PrefCode=${requestedNewPC} requested by ${requestorName}?`}
			/>

			<ConfirmationBox
				open={openRejectMessage}
				handleYes={handleReject}
				handleNo={() => setOpenRejectMessage(false)}
				message={`Reject PrefCode=${requestedNewPC} requested by ${requestorName}?`}
			/>

			<MessageBox
				open={openResult}
				message={resultMessage}
				messageBoxHandler={() => {
					setOpenResult(false);
					handleOpenPrefCode();
					if (urlPathName === "/Note") {
						dispatch(noteAction.setReloadPartNumber());
					}
				}}
			/>
			{!isNotER00 && (
				<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disableScrollLock>
					<Box
						sx={style}
						onKeyDown={(e) => {
							if (e.code === "KeyS" && e.altKey === true && allowEditing) {
								e.preventDefault();
								handleSaveClicked();
							} else if (e.code === "KeyD" && e.altKey === true && !disableDeleteRequest && userIsF01) {
								e.preventDefault();
								setOpenConfirmDeleteMessage(true);
							} else if (e.code === "KeyC" && e.altKey === true) {
								e.preventDefault();
								handleOpenPrefCode();
							} else if (e.code === "KeyH" && e.altKey === true) {
								e.preventDefault();
								setOpenHowItWorks(true);
							} else if (e.code === "KeyA" && e.altKey === true && isF01Approval) {
								e.preventDefault();
								setOpenApproveMessage(true);
							} else if (e.code === "KeyR" && e.altKey === true && isF01Approval) {
								e.preventDefault();
								handleRejectClicked();
							}
						}}
					>
						<AppBar elevation={0} sx={{ position: "fixed", backgroundColor: "#E90029" }}>
							<Toolbar>
								<Typography variant="h6" component="div" color="#fff">
									{reviewStatus === "Approved"
										? "Approved - Request for new preference code"
										: isF01Approval
										? "Approval - Request for new preference code"
										: "Request for new preference code"}
								</Typography>
							</Toolbar>
						</AppBar>
						<Grid
							mb={2}
							mt={6}
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							rowSpacing={1.5}
							columnSpacing={2}
							wrap={"wrap"}
						>
							<Grid item xl={4.5} lg={4.5} md={4.5} sm={12} xs={12}>
								<ColoredTextField
									focused
									fullWidth
									label={isF01Approval ? "Approval" : "Requestor"}
									value={userName}
									inputProps={{ readOnly: true }}
									InputProps={{ sx: { marginTop: "2px" } }}
									backgroundColor={"rgba(128, 255, 128, 0.8)"}
								/>
							</Grid>
							<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
								<ColoredTextField
									focused
									fullWidth
									label="Oracle Preference code"
									inputProps={{ readOnly: true }}
									value={oraclePC ?? ""}
									backgroundColor={"rgba(128, 255, 128, 0.8)"}
								/>
							</Grid>
							<Grid item xl={4.5} lg={4.5} md={4.5} sm={12} xs={12}>
								<FormControl sx={{ marginRight: 1, minWidth: 150 }} focused fullWidth disabled={!allowEditing}>
									<InputLabel size="small" id="history-select-label">
										New code
									</InputLabel>

									<Select
										size="small"
										labelId="history-select-label"
										id="history-select"
										value={newPC}
										label="New code"
										onChange={(event) => {
											dispatch(prefCodeAction.setNewPC(event.target.value));
										}}
										MenuProps={{
											disableScrollLock: true,
											sx: { maxHeight: "60%" },
										}}
										inputProps={{ sx: { backgroundColor: "rgba(225, 255, 0, 0.5)" } }}
									>
										{Object.values(prefCodeList).map((code, index) => (
											<MenuItem key={index} value={code.pref_code}>
												{`${code.pref_code} | ${code.description}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							{(requestedNewPC ? true : false) && reqStat === "0" && (
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										focused
										fullWidth
										inputProps={{ readOnly: true, style: { textAlign: "center" } }}
										value={
											reviewStatus === "Approved"
												? `Approved - Request from ${requestorName} for PC = ${requestedNewPC}`
												: `Pending request for PC = ${requestedNewPC}`
										}
										backgroundColor={"rgba(128, 255, 128, 0.8)"}
									/>
								</Grid>
							)}

							{reviewStatus === "Pending" && (
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<ColoredTextField
										focused
										fullWidth
										inputProps={{ readOnly: true, style: { textAlign: "center" } }}
										value={`Pending request Approval for PC = ${requestedNewPC} by ${requestorName}`}
										backgroundColor={"rgb(255,165,0)"}
									/>
								</Grid>
							)}

							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<ColoredTextField
									focused
									fullWidth
									multiline
									rows={3}
									label="Current part note"
									inputProps={{ readOnly: true }}
									value={note || "No current note for this part"}
								/>
							</Grid>

							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Button disabled={!note.trim() || isF01Approval} endIcon={<ArrowCircleDownSharpIcon />} onClick={copyNoteClicked}>
									copy note to reason for change
								</Button>
							</Grid>

							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								{isF01Approval || reviewStatus === "Approved" ? (
									<FormControl fullWidth>
										<style>
											{`  
												#messageInputFieldSet:disabled{
													opacity: 0.32;
													border-width: 1px !important;
												}
											`}
										</style>
										<fieldset
											style={{
												borderRadius: 4,
												borderWidth: 2,
												borderStyle: "solid",
												borderColor: responseError ? "rgba(233, 0, 41)" : "rgba(0,0,0)",
												padding: "0px 5px 5px 5px",
												margin: 0,
												height: "100px",
												display: "flex",
												flexDirection: "column",
												overflowY: "auto",
												overflowX: "hidden",
												backgroundColor: "rgba(128, 255, 255, 0.8)",
											}}
											id={"messageInputFieldSet"}
											// disabled={schedule.disabled}
										>
											<legend
												style={{
													padding: "0px 5px",
													fontSize: "0.75em",
													fontWeight: "300",
													marginLeft: "3px",
													lineHeight: "5px",
													fontFamily: "Arial, Helvetica, sans-serif",
													paddingBottom: "5px",
												}}
											>
												{"Reason for change"}
											</legend>
											<Box sx={{ maxHeight: "220px" }}>
												<Typography
													sx={{
														width: "100%",
														ml: 1,
														mt: 0,
														color: "rgb(100,100,100)",
													}}
													onClick={focusInput}
												>{`Comment entered by ${requestorName}: ${reasonNonF01}`}</Typography>
												<Stack direction={"row"}>
													<Typography
														sx={{
															// width: "100%",
															ml: 1,
															mt: 0.4,
															color: "rgb(100,100,100)",
														}}
														onClick={focusInput}
													>{`Response: `}</Typography>
													<Input
														sx={{
															"&::before": {
																border: 0,
															},
															"&::after": {
																border: 0,
															},
															"&:hover:not(.Mui-disabled):before": {
																border: 0,
															},
															width: "100%",
															ml: 0.9,
															"& .MuiInputBase-input.Mui-disabled": {
																WebkitTextFillColor: "rgb(100,100,100)",
															},
														}}
														inputProps={{ spellCheck: "false" }}
														multiline
														inputRef={inputRef}
														value={f01Response}
														onChange={handleResponseChanged}
														disabled={reviewStatus === "Approved"}
													/>
												</Stack>
											</Box>
										</fieldset>
										<FormHelperText error={responseError ? true : false}>{responseError}</FormHelperText>
									</FormControl>
								) : (
									<ColoredTextField
										focused
										fullWidth
										multiline
										rows={3}
										inputRef={inputRef}
										value={reasonforChange}
										disabled={!allowEditing && !isF01Approval}
										onChange={handleReasonChanged}
										label="Reason for change"
										InputProps={{ sx: { marginTop: "2px" } }}
										backgroundColor={"rgba(128, 255, 255, 0.8)"}
									/>
								)}
							</Grid>
						</Grid>
						<Box>
							{allowEditing && (
								<Tooltip title={`For shortcut press ${OSAltKey} + S`}>
									<Button onClick={handleSaveClicked}>
										<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>S</u>ave
									</Button>
								</Tooltip>
							)}

							{isF01Approval ? (
								<>
									<Tooltip title={`For shortcut press ${OSAltKey} + A`}>
										<Button onClick={() => setOpenApproveMessage(true)}>
											<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>A</u>pprove
										</Button>
									</Tooltip>
									<Tooltip title={`For shortcut press ${OSAltKey} + R`}>
										<span>
											<Button onClick={handleRejectClicked}>
												<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>R</u>eject
											</Button>
										</span>
									</Tooltip>
								</>
							) : (
								<>
									{userIsF01 && reviewStatus !== "Approved" && (
										<Tooltip title={`For shortcut press ${OSAltKey} + D`}>
											<span>
												<Button disabled={disableDeleteRequest} onClick={() => setOpenConfirmDeleteMessage(true)}>
													<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>D</u>elete this request
												</Button>
											</span>
										</Tooltip>
									)}
								</>
							)}
							<Tooltip title={`For shortcut press ${OSAltKey} + C`}>
								<Button onClick={handleOpenPrefCode}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>C</u>ancel
								</Button>
							</Tooltip>
							<Tooltip title={`For shortcut press ${OSAltKey} + H`}>
								<Button onClick={() => setOpenHowItWorks(true)}>
									<u style={{ textDecorationThickness: "2.5px", fontWeight: "bold" }}>H</u>elp
								</Button>
							</Tooltip>
						</Box>
						<Typography sx={{ fontSize: "0.7rem" }}>{`Shortcut: ${OSAltKey} + Hotkey`}</Typography>
					</Box>
				</Modal>
			)}
		</Box>
	);
};

export default PrefCode;
