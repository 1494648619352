import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#343434",
		},
		secondary: {
			main: "#E90029",
		},
		background: {
			default: "#FFF",
		},
	},
	typography: {
		fontFamily: "Neue Helvetica Condensed, sans-serif",
		button: {
			fontFamily: "Arial, Helvetica, sans-serif",
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: "#E90029",
					color: "#FFF",
					// fontSize: "0.8rem",
					minHeight: "35px",
					margin: 3,
					"&:hover": {
						backgroundColor: "rgba(0, 0, 0, 0.87)",
					},
					"&:disabled": {
						backgroundColor: "rgba(0, 0, 0, 0.12)",
						color: "rgba(0, 0, 0, 0.36)",
					},
				},
			},
			defaultProps: {
				size: "small",
			},
		},
		MuiAppBar: {
			styleOverrides: {
				colorInherit: {
					backgroundColor: "#FFF",
					color: "#000",
				},
			},
			defaultProps: {
				color: "inherit",
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					// color: "#000"
					// margin: 3,
				},
			},
			defaultProps: {
				size: "small",
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					boxShadow:
						"0px 2px 10px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				asterisk: { color: "red" },
			},
		},
	},
});

export default theme;
