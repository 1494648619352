// React
import { useSelector, useDispatch } from "react-redux";
import { SubsetAction } from "../../../store/Subset/Subset-slice";

// UI
import CreateSubset from "./CreateSubset";
import ExportSubset from "./ExportSubset";
import LoadSubset from "./LoadSubset";
import SetOrderSubset from "./SetOrderSubset";

const Subset = (props) => {
	const dispatch = useDispatch();
	const { settings } = props;

	const currentTab = useSelector((state) => state.SubsetState.currentTab);

	const onClose = () => {
		dispatch(SubsetAction.setCurrentTab(""));
	};

	return (
		<>
			<CreateSubset open={currentTab === settings.SubsetCreate} onClose={onClose} />
			<ExportSubset open={currentTab === "Export"} onClose={onClose} />
			<LoadSubset open={currentTab === settings.SubsetLoadAndExport} onClose={onClose} />
			<SetOrderSubset open={currentTab === settings.SubsetSetOrder} onClose={onClose} />
		</>
	);
};

export default Subset;
