// React
import { useEffect } from "react";
import { useSelector } from "react-redux";

// UI
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const UserOrgDropdown = (props) => {
	const { label, width, selectedOrg, handleOrgSelected } = props;
	const { allOrg, primaryOrg } = useSelector((state) => state.authenticationState.User_Assigned_Orgs);

	useEffect(() => {
		handleOrgSelected && handleOrgSelected(primaryOrg);
	}, [primaryOrg]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSelectOrg = (event) => {
		handleOrgSelected && handleOrgSelected(event.target.value);
	};

	return (
		<FormControl focused>
			<InputLabel id="radio-buttons-group-label">{label}</InputLabel>
			<Select
				label={label}
				size="small"
				labelId="org-label"
				id="org-select"
				MenuProps={{
					disableScrollLock: true,
					sx: { maxHeight: "60%" },
				}}
				sx={{ width: width }}
				value={selectedOrg}
				onChange={onSelectOrg}
			>
				{allOrg.map((org, index) => (
					<MenuItem key={index} value={org} sx={{ color: primaryOrg && primaryOrg === org && "green" }}>
						{org}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default UserOrgDropdown;
