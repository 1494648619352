import { createSlice } from "@reduxjs/toolkit";
import { checkUndefined  } from "../../Components/Util/Util";
import { storePartHistories } from "../../Components/Util/Util";

const defaultCMForecastTable = [{
    id : 0,
    cmName : "",
    cmLoc : "",
    annPurchHist : "",
    annFor : "",
	grossReq : "",
    commodity : "",
    er : "",
	supplier : "",
    mpn : "",
}]

const defaultCMQuantityTable = [{
	id:0,
	cmName: "",
	cmLoc: "",
	commodity: "",
	onHand: "",
}]

const defaultMacsPriceInfoTable = [{
    id:0,
    agreeNo: "",
	leadTime: "",
	minQty: "",
	price: "",
	currency: "",
	priceUpt: "",
	supplier: "",
	um: "",
	recUpt: "",
	expDate: ""
}]

const defaultRRCMDemandTable = [{
    id:0,
    cmSite: "",
	safetyStock: "",
	demand: "",
	indptDemand: "",
}]

const defaultRRCMQuantityTable = [{
    id:0,
    cmSite: "",
	loc: "",
	onHand: "",
}]

const defaultPartsInfo = {
	partNum:"",
	desc:"",
	avgPrice: ""
}
const defaultColumnVisibilityCMForecast = {
	cmName : true,
	cmLoc : true,
	annPurchHist : true,
	annFor : true,
	grossReq : true,
	commodity : true,
	er : true,
	supplier : true,
	mpn : true,

}

const defaultColumnVisibilityCMQuantity = {
    cmName : true,
	cmLoc : true,
	commodity : true,
	onHand : true,
}

const defaultColumnVisibilityMacPriceInfo = {
    agreeNo: true,
	leadTime: true,
	minQty: true,
	price: true,
	currency: true,
	priceUpt: true,
	supplier: true,
	um: true,
	recUpt: true,
	expDate: true
}

const defaultColumnVisibilityRRCMDemand = {
    cmSite: true,
	safetyStock: true,
	demand: true,
	indptDemand: true,
}
const defaultColumnVisibilityRRCMQuantity = {
    cmSite: true,
	loc: true,
	onHand: true,
}

// window.localStorage.removeItem("mtlEngColumnVisibility") // Incase of problems due to Item
const columnVisibilityStorageCMForecast = JSON.parse(window.localStorage.getItem("CM_CMForecastColumnVisibility"))
const columnVisibilityStorageCMQuantity = JSON.parse(window.localStorage.getItem("CM_CMQuantityColumnVisibility"))
const columnVisibilityStorageMacPriceInfo = JSON.parse(window.localStorage.getItem("CM_MacPriceInfoColumnVisibility"))
const columnVisibilityStorageRRCMDemand = JSON.parse(window.localStorage.getItem("CM_RRCMDemandColumnVisibility"))
const columnVisibilityStorageRRCMQuantity = JSON.parse(window.localStorage.getItem("CM_RRCMQuantityolumnVisibility"))

const initialCMState = {
	PART_INFO: defaultPartsInfo,
	CM_FORECAST_TABLE: [],
	CM_QUANTITY_TABLE: [],
	MAC_PRICE_INFO_TABLE: [],
	RRCM_DEMAND_TABLE: [],
	RRCM_QUANTITY_TABLE: [],
	tableColumnVisibilityCMForecast: columnVisibilityStorageCMForecast ?? defaultColumnVisibilityCMForecast,
	tableColumnVisibilityCMQuantity: columnVisibilityStorageCMQuantity ?? defaultColumnVisibilityCMQuantity,
	tableColumnVisibilityMacPriceInfo: columnVisibilityStorageMacPriceInfo ?? defaultColumnVisibilityMacPriceInfo,
	tableColumnVisibilityRRCMDemand : columnVisibilityStorageRRCMDemand ?? defaultColumnVisibilityRRCMDemand,
	tableColumnVisibilityRRCMQuantity: columnVisibilityStorageRRCMQuantity ?? defaultColumnVisibilityRRCMQuantity,
	reloadPartNumber: 0, // A useEffect dependency to trigger change part after user loaded subset from info tab itself
};

const cmSlice = createSlice({
	name: "CM",
	initialState: initialCMState,
	reducers: {
		updateAll(state, action) {
			console.log(action.payload.data)
			const cmForecast = () => {
                if(action.payload.data.CM_FORECAST.length > 0){
                    
                    return action.payload.data.CM_FORECAST.map( (singleData , i) =>{ 

                        Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
                        const retItem = {
                            id:i,
                            cmName : singleData.cm_name,
							cmLoc : singleData.cm_loc_name,
							annPurchHist : singleData.annual_purchase_history,
							annFor : singleData.annual_forecast,
							grossReq : singleData.m12_gross_reqs,
							commodity : singleData.commodity,
							er : singleData.er_code,
							supplier : singleData.supplier_name,
							mpn : singleData.manufacturer_partnumber,
                        }
                        return retItem
                    })
                }

                return defaultCMForecastTable
            }
			const cmQuantity = () => {
                if(action.payload.data.CM_QUANTITY_ON_HAND.length > 0){
                    
                    return action.payload.data.CM_QUANTITY_ON_HAND.map( (singleData , i) =>{ 

                        Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
                        const retItem = {
                            id:i,
                            cmName : singleData.cm_name,
							cmLoc : singleData.cm_loc_name,
							commodity : singleData.commodity,
							onHand : singleData.on_hand_inventory,
                        }
                        return retItem
                    })
                }

                return defaultCMQuantityTable
            }

			const macsPriceInfo = () => {
                if(action.payload.data.MACS_PRICES_INFO.length > 0){
                    
                    return action.payload.data.MACS_PRICES_INFO.map( (singleData , i) =>{ 

                        Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
						const invalidDate = "Invalid Date"
						const priceUpdated = singleData.price_update_date && new Date (singleData.price_update_date) !== invalidDate ? new Date(new Date (singleData.price_update_date).toISOString().replace("Z", "")).toLocaleDateString("en-US") : ""
						const recUpt = singleData.update_date && new Date (singleData.update_date) !== invalidDate ? new Date(new Date (singleData.update_date).toISOString().replace("Z", "")).toLocaleDateString("en-US") : ""
						const end_date = singleData.end_date && new Date (singleData.end_date) !== invalidDate ? new Date(new Date (singleData.end_date).toISOString().replace("Z", "")).toLocaleDateString("en-US") : ""
	
                        const retItem = {
                            id:i,
                            agreeNo: singleData.agreement_number,
							leadTime: singleData.lead_time,
							minQty: singleData.min_qty,
							price: singleData.unitprice,
							currency: singleData.currency_code,
							priceUpt: priceUpdated,
							supplier: singleData.supplier_name,
							um: singleData.mult_uom,
							recUpt: recUpt,
							expDate: end_date
                        }
                        return retItem
                    })
                }

                return defaultMacsPriceInfoTable
            }

			const rrcmDemandTable = () => {
                if(action.payload.data.RRCN_DEMAND.length > 0){
                    
                    return action.payload.data.RRCN_DEMAND.map( (singleData , i) =>{ 

                        Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
                        const retItem = {
                            id:i,
                            cmSite: singleData.cm_site,
							safetyStock: singleData.safety_stock_qty,
							demand: singleData.total_demand_qty?.toString().replace(/(?:\.0+|(\.\d+?)0+)$/, "$1"),
							indptDemand: singleData.total_independent_demand_qty,
                        }
                        return retItem
                    })
                }

                return defaultRRCMDemandTable
            }

			const rrcmQuantityTable = () => {
                if(action.payload.data.RRCM_QUANTITY_ON_HAND.length > 0){
                    return action.payload.data.RRCM_QUANTITY_ON_HAND.map( (singleData , i) =>{ 

                        Object.keys(singleData).forEach(key => {
                            singleData[key] = checkUndefined(singleData[key])
                        })
                        const retItem = {
                            id:i,
							cmSite: singleData.cm_site,
							loc: singleData.location,
							onHand: singleData.quantity,
                        }
                        return retItem
                    })
                }

                return defaultRRCMQuantityTable
            }

			const partInfo = () => {
				const part = action.payload.data.PART_INFO
				if(Object.keys(part).length > 0){
					storePartHistories(part.itemname)

					Object.keys(part).forEach(key => {
						part[key] = checkUndefined(part[key])
					})
					const retItem = {
						partNum: part.itemname,
						desc: part.item_desc,
						avgPrice: action.payload.data.CM_AVERAGE_PRICE[0]?.average_cm_price_usd || ""
					}
					return retItem
                }
				return defaultPartsInfo
			}
			const CM_FORECAST_TABLE = cmForecast()
			const CM_QUANTITY_TABLE = cmQuantity()
			const MAC_PRICE_INFO_TABLE = macsPriceInfo()
			const RRCM_DEMAND_TABLE = rrcmDemandTable()
			const RRCM_QUANTITY_TABLE = rrcmQuantityTable()
			const PART_INFO = partInfo()
			// console.log({CM_FORECAST_TABLE , CM_QUANTITY_TABLE , MAC_PRICE_INFO_TABLE , RRCM_DEMAND_TABLE , RRCM_QUANTITY_TABLE })
			// console.table({PART_INFO})
			state.CM_FORECAST_TABLE = CM_FORECAST_TABLE
			state.CM_QUANTITY_TABLE = CM_QUANTITY_TABLE
			state.MAC_PRICE_INFO_TABLE = MAC_PRICE_INFO_TABLE
			state.RRCM_DEMAND_TABLE = RRCM_DEMAND_TABLE
			state.RRCM_QUANTITY_TABLE = RRCM_QUANTITY_TABLE
			state.PART_INFO = PART_INFO
        },
		setColumnVisibility(state, action){
			switch(action.payload.table){
				case "CMForecast":
					state.tableColumnVisibilityCMForecast = action.payload.columnVisibility
					window.localStorage.setItem("CM_CMForecastColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "CMQuantity":
					state.tableColumnVisibilityCMQuantity = action.payload.columnVisibility
					window.localStorage.setItem("CM_CMQuantityColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "MacsPriceInfo":
					state.tableColumnVisibilityMacPriceInfo = action.payload.columnVisibility
					window.localStorage.setItem("CM_MacPriceInfoColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "RRCMDemand":
					state.tableColumnVisibilityRRCMDemand = action.payload.columnVisibility
					window.localStorage.setItem("CM_RRCMDemandColumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				case "RRCMQuantity":
					state.tableColumnVisibilityRRCMQuantity = action.payload.columnVisibility
					window.localStorage.setItem("CM_RRCMQuantityolumnVisibility" , JSON.stringify(action.payload.columnVisibility))
					break
				default:
					break
				
			}
        },
		
		reset: (state) => {
			return {
				...initialCMState,
				tableColumnVisibilityCMForecast: state.tableColumnVisibilityCMForecast,
				tableColumnVisibilityCMQuantity: state.tableColumnVisibilityCMQuantity,
				tableColumnVisibilityMacPriceInfo: state.tableColumnVisibilityMacPriceInfo,
				tableColumnVisibilityRRCMDemand: state.tableColumnVisibilityRRCMDemand,
				tableColumnVisibilityRRCMQuantity: state.tableColumnVisibilityRRCMQuantity,
			};
		},

		setPartNumberForSubset(state, action) {
			state.PART_INFO.partNum = action.payload;
		},

		setReloadPartNumber(state) {
			state.reloadPartNumber = state.reloadPartNumber + 1;
		},

	},
});

export const cmActions = cmSlice.actions;

export default cmSlice;
