import { Fragment } from "react";
import NavBar from "./NavBar";
import { Box } from "@mui/system";

const Layout = ({ sessionExpired, ...props }) => {
	return (
		<Fragment>
			{!sessionExpired && <NavBar />}
			<Box pb={9.5}></Box>
			<main>{props.children}</main>
			<Box pb={11.5}></Box>
		</Fragment>
	);
};

export default Layout;
