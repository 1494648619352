import { Typography, Box, Stack } from "@mui/material";

const InvalidPage = () => {
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Stack spacing={2}>
				<Typography variant="h4" color={"secondary"} sx={{ fontWeight: "bold" }}>
					Invalid page
				</Typography>
				<Typography variant="h5">Please make sure the url is correct</Typography>
			</Stack>
		</Box>
	);
};

export default InvalidPage;
