import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	gridClasses,
} from "@mui/x-data-grid";
import { Checkbox, Tooltip, Box, Select, MenuItem, buttonClasses } from "@mui/material";
import { useState, useEffect } from "react";
import theme from "../../Theme";
import { cx } from "@emotion/css";

const InfoTable = (props) => {
	const rowsPerPageOptions = props.rowsPerPageOptions || [5, 10, 50, 100];
	const [pageSize, setPageSize] = useState(props.pageSize || rowsPerPageOptions[3]);
	const maxtableHeight = props.maxtableHeight || 10;
	const [autoTableHeight, setAutoTableHeight] = useState(false);
	const height = useState(props.height || 500);

	useEffect(() => {
		if (props.rows.length > 0) {
			setAutoTableHeight(typeof props.autoTableHeight === "boolean" ? props.autoTableHeight : props.rows.length > maxtableHeight ? false : true);
		}
	}, [maxtableHeight, props.autoTableHeight, props.height, props.rows.length]);

	const setTablePageSize = (newPageSize) => {
		setPageSize(newPageSize);
		if (typeof props.autoTableHeight !== "boolean") {
			newPageSize <= maxtableHeight || props.rows.length <= maxtableHeight ? setAutoTableHeight(true) : setAutoTableHeight(false);
		}
	};

	const CustomToolbar = () => {
		if (props.customToolBar) {
			return <GridToolbarContainer>{<props.customToolBar />}</GridToolbarContainer>;
		} else {
			return (
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
					<GridToolbarExport />
					{props.additionalToolbarButton}
				</GridToolbarContainer>
			);
		}
	};

	const columns = props.columnsSettings.map((items) => {
		const { type, defaultCursor, ...requiredItems } = items;
		switch (items.type) {
			case "text":
				return {
					...requiredItems,
					renderCell: (params) => (
						<Tooltip title={params.value ? params.value : ""} placement={"bottom-start"}>
							<span style={{ textOverflow: "ellipsis", overflow: "hidden" }} className="table-cell-trucate">
								{params.value}
							</span>
						</Tooltip>
					),
				};
			case "checkbox":
				return {
					...requiredItems,
					renderCell: (params) => (
						<Checkbox
							sx={{ cursor: props.editing && !defaultCursor ? "pointer" : "default" }}
							onChange={() => props.editing && props.handleCheckBoxChange && props.handleCheckBoxChange(params)}
							checked={params.value && params.value.toUpperCase() === "T" ? true : false}
							// inputProps={{ disabled: true }}
						></Checkbox>
					),
				};
			case "select":
				return {
					...requiredItems,
					renderCell: (params) => (
						<Tooltip title={!props.editing && params.value ? params.value : ""} placement={"bottom-start"}>
							<Select
								disabled={!props.editing || params.row.editDisabled}
								fullWidth
								id="select"
								variant="standard"
								disableUnderline
								value={params.value || ""}
								label="Org"
								onChange={props.handleSelectChange && props.handleSelectChange(params)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
									disableScrollLock: true,
								}}
								sx={{
									"& .MuiSelect-select.Mui-disabled": {
										WebkitTextFillColor: params.row.editDisabled ? "#606060" : "#000000",
									},
								}}
								inputProps={{
									sx: { paddingLeft: "10px", fontSize: "0.95em" },
								}}
							>
								{items.selectOptions &&
									items.selectOptions.map((selection) => (
										<MenuItem key={selection} value={selection}>
											{selection}
										</MenuItem>
									))}
							</Select>
						</Tooltip>
					),
					cellClassName: cx("super-app", {
						select: true,
					}),
				};
			default:
				return {
					...requiredItems,
				};
		}
	});
	const selectionType =
		props.selectionType === "row"
			? {
					"& .MuiDataGrid-cell:focus-within": {
						outlineStyle: "none !important",
					},
					"& .MuiDataGrid-cell:focus": {
						outlineStyle: "none !important",
					},
			  }
			: undefined;

	return (
		<Box
			sx={{
				width: props.width,
				[`& .${gridClasses.sortIcon}, & .${gridClasses.menuIconButton}`]: {
					color: "#fff",
				},
				[`& .${buttonClasses.root}`]: {
					"&:hover": {
						backgroundColor: "#E90029",
						color: "#fff",
					},
					backgroundColor: "#EBEBEB",
					color: "#000",
					marginLeft: 0,
					marginRight: 0.6,
				},
				[`& .${gridClasses.columnHeaders}`]: {
					backgroundColor: "#E90029",
					color: "#fff",
				},
				cursor: "default",
				"& .super-app": {
					fontSize: "1.05em",
				},
				"& .super-app.pointerCursor": {
					cursor: "pointer",
					fontSize: "1.0em",
				},
				"& .super-app.select": {
					padding: 0,
				},
				"& .super-app.blue": {
					backgroundColor: "rgba(128, 255, 255, 0.8)",
				},
				"& .super-app.green": {
					backgroundColor: "rgba(128, 255, 128, 0.8)",
				},
				"& .super-app.red": {
					backgroundColor: "rgb(255, 128, 128)",
				},
				"& .super-app.yellow": {
					backgroundColor: "rgba(225, 255, 0, 0.5)",
				},
				"& .super-app.grey": {
					backgroundColor: "rgba(128,128,128, 0.45)",
				},
				"& .super-app.orange": {
					backgroundColor: "rgb(255,165,0)",
				},
				"& .super-app.selectedRow": {
					backgroundColor: "rgba(230, 230, 250, 0.8)",
				},
				"& .super-app.strikeThrough": {
					textDecoration: "line-through",
				},
				"& .super-app.italic": {
					fontStyle: "italic",
				},
				"& .super-app.bold": {
					fontWeight: "bold",
				},
				"& .super-app.fontBlue": {
					color: "rgb(0,0,128)",
				},
				"& .super-app.underline": {
					textDecoration: "underline",
					textDecorationThickness: 2,
				},
				[`& .${buttonClasses.startIcon}`]: {
					[theme.breakpoints.only("xs")]: {
						display: "none",
					},
				},
				[`& .${gridClasses.cell}`]: typeof props.haveBorder === "boolean" &&
					props.haveBorder && {
						borderRight: "1px solid rgb(50, 50, 50)",
						borderTop: "1px solid rgb(50, 50, 50)",
					},
				// "& .MuiDataGrid-cell": {
				// 	borderRight: "1px solid rgb(50, 50, 50)",
				// 	borderTop: "1px solid rgb(50, 50, 50)",
				// },
				// "& .MuiDataGrid-row"
				...selectionType,
			}}
		>
			<DataGrid
				autoHeight={autoTableHeight}
				sx={{
					height: height,
				}}
				rowHeight={props.rowHeight || 52}
				headerHeight={props.headerHeight || 56}
				density="compact"
				checkboxSelection={typeof props.checkboxSelection === "boolean" ? props.checkboxSelection : true}
				columns={columns}
				rows={props.rows.length === 0 ? [{ id: 0 }] : props.rows}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
				rowsPerPageOptions={rowsPerPageOptions}
				disableSelectionOnClick
				components={{ Toolbar: CustomToolbar }}
				componentsProps={
					props.rightClickMenu && {
						row: {
							onContextMenu: props.handleContextMenu,
						},
					}
				}
				{...props.ExtraProps}
				getCellClassName={(params) => {
					return props.customCellClass && props.customCellClass(params);
				}}
				onCellClick={(params) => {
					return props.handleOnCellClick && props.handleOnCellClick(params);
				}}
				onCellDoubleClick={(params) => {
					return props.handleOnCellDoubleClick && props.handleOnCellDoubleClick(params);
				}}
				getRowClassName={(params) => {
					return props.customRowClass && props.customRowClass(params);
				}}
				onRowClick={(params, event, details) => {
					return props.handleOnRowClick && props.handleOnRowClick(params, event, details);
				}}
				onRowDoubleClick={(params) => {
					return props.handleOnRowDoubleClick && props.handleOnRowDoubleClick(params);
				}}
			/>
			{props.rightClickMenu && props.rightClickMenu}
		</Box>
	);
};

export default InfoTable;
