// Packages Import
import { memo, useState, useEffect} from "react"
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
// Project Components
import InfoTable from "../../../UI/Table"
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from "../../../UI/CustomAccordion";
import {formatDate} from "../../../Util/Util"
import { cmActions } from "../../../../store/CM/CM-slice"

const columnsSettings = [
	{ field: "cmSite", headerName: "CM Site", type: "text", minWidth: 50, flex: 0.5 },
	{ field: "safetyStock", headerName: "Safety Stock", type: "text", minWidth: 70, flex: 0.5 },
	{ field: "demand", headerName: "Demand", type: "text", minWidth: 50, flex: 0.5 },
    { field: "indptDemand", headerName: "Independant Demand", type: "text", minWidth: 50, flex: 0.5 },
]

const RRCMDemandTable = (props) => {
	const dispatch = useDispatch();
	const [RRCMDemandAccordian, setRRCMDemandAccordian] = useState(false);
	const rows = useSelector((state) => state.cmState.RRCM_DEMAND_TABLE)
	const partNum = useSelector((state) => state.cmState.PART_INFO.partNum)
	const columnVisibility = useSelector((state) => state.cmState.tableColumnVisibilityRRCMDemand)

	const customToolBar = () => (
		<>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport 
				printOptions={{
					disableToolbarButton: true,
				}} 
				csvOptions={{
					fileName: `CM_RRCMDemand_KPN${partNum}_${formatDate(new Date(), "")}`
				}}
			/>
		</>
	)
	useEffect(() => {
		setRRCMDemandAccordian(JSON.parse(localStorage.getItem("RRCMDemandAccordian")));
	}, []);

	// Store Accordion state into browser's local storage
	const accordionClicked = () => {
		window.localStorage.setItem("RRCMDemandAccordian", !RRCMDemandAccordian);
		setRRCMDemandAccordian(!RRCMDemandAccordian);
	};
	return (
		<CustomAccordion onChange={accordionClicked} expanded={RRCMDemandAccordian}>
			<CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
				<Typography sx={{ fontWeight: "bold" }}>RRCM Demand</Typography>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
			<InfoTable 
				columnsSettings={columnsSettings} 
				rows={rows}
				checkboxSelection = {true}
				customToolBar={customToolBar}
				ExtraProps = {{
					columnVisibilityModel:columnVisibility,
					onColumnVisibilityModelChange:(newModel) =>{
						dispatch(cmActions.setColumnVisibility({columnVisibility:newModel , table:"RRCMDemand"}));
					}
				}}
			/>
			</CustomAccordionDetails>
		</CustomAccordion>
	
	)
}

export default memo(RRCMDemandTable)
